import { intersection } from 'lodash/fp';

import { DataCondition, DataGranularity, DataType, Entity } from '@portal/common/types';
import { CauseOutcome, getDataTypePrimaryEntityKey } from '@portal/common/models/data-type';

import _ from '../../locale';
import { getDataTypeConfig } from '@portal/common/models/data-type-config';

const options: ConditionSmartOption[] = [
    // Cause Level 2,3 & 4 Conditions
    ...[2, 3, 4].map((smartOptionLevel) => ({
        id: `${CauseOutcome}-level-${smartOptionLevel}`,
        label: _(`smart_option_causes_level_${smartOptionLevel}`),
        getSelectedValues: (
            granularityByDataType: Record<DataType, DataGranularity>,
            hierarchy: Record<string, Entity[]>
        ): DataCondition[] => {
            const dataType = CauseOutcome;
            const primaryEntityKey = getDataTypePrimaryEntityKey(dataType);
            const hierarchyKey = getDataTypeConfig()[dataType].entityHierarchyKey;

            const granularity = granularityByDataType[dataType]?.[primaryEntityKey] || [];
            const levelIds = (hierarchy[hierarchyKey] || [])
                .filter(({ level }) => level === smartOptionLevel)
                .map(({ id }) => id);

            return intersection(granularity, levelIds).map((id) => ({
                data_type: dataType,
                primary_entity_id: id,
            }));
        },
    })),
];

export type ConditionSmartOption = {
    label: string;
    getSelectedValues: (
        granularityByDataType: Record<DataType, DataGranularity>,
        hierarchy: Record<string, Entity[]>
    ) => DataCondition[];
};

export default options;
