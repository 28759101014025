import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash/fp';

import { CauseEntity, DataCollectionResource, DataCondition } from '@portal/common/types';
import { CauseOutcome } from '@portal/common/models/data-type';
import {
    AGE_GROUP_ID_KEY,
    CAUSE_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    PRIMARY_ENTITY_ID_KEY,
    ROUND_ID_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import { Alert, Modal, ModalBody } from '@portal/common/components';
import { styled } from '@portal/common/theme';
import formatChartValue from '@portal/common/utility/formatting/formatChartValue';
import { shadeHex } from '@portal/common/utility/color';
import { TYPE_TREEMAP_CHART } from '@portal/common/models/chart-type';

import { downloadDataExportAsync } from '../../../store/data-explorer/actions';
import useGetApiData from '../hooks/use-get-api-data';
import config from '../../../config';
import theme, { textStyle } from '../../../theme/echarts';
import _ from '../../../locale';

import Container from '../../../components/DataExplorer/ChartSection/TreemapChart/Container';

const Wrapper = styled.div(({ theme }) => ({
    width: '100%',
}));

type Props = {
    locationId: number;
    dataCollection: DataCollectionResource;
    dataTool: string;
    entityHierarchies: any; //@todo: provide the type
};

const NeoplasmsTreemapChart = ({
    locationId,
    dataCollection,
    dataTool,
    entityHierarchies,
}: Props) => {
    const dispatch = useDispatch();

    const [isChartPoppedOut, setChartPoppedOut] = useState(false);

    const allNeoplasmsChildrenIds = config.neoplasmsChildrenCauseIds.split(',').map(Number);
    const allConditionIds = [config.neoplasmsCauseId, ...allNeoplasmsChildrenIds];

    const dataType = CauseOutcome;
    const conditions = allConditionIds.map((id) => ({
        data_type: dataType,
        [PRIMARY_ENTITY_ID_KEY]: Number(id),
    })) as DataCondition[];

    const filters = {
        [CAUSE_ID_KEY]: allConditionIds,
        data_type: CauseOutcome,
        [LOCATION_ID_KEY]: [locationId],
        [AGE_GROUP_ID_KEY]: [config.allAgesGroupId],
        [GENDER_ID_KEY]: [config.bothGendersId],
        [MEASURE_ID_KEY]: [config.dalyMeasureId],
        [METRIC_ID_KEY]: [config.numberMetricId],
        [ROUND_ID_KEY]: [config.gbd2019RoundId],
        [YEAR_KEY]: [2019],
    };

    const { isLoading, dataResponse, error } = useGetApiData({
        conditions,
        filters,
        mergeResponses: false,
    });

    const toggleChartPoppedOut = useCallback(() => {
        setTimeout(() => {
            setChartPoppedOut(!isChartPoppedOut);
        }, 250);
    }, [isChartPoppedOut]);

    const granularity = {
        ...(dataCollection.datasets.find(({ data_type }) => data_type === dataType)?.granularity ||
            {}),
    };

    if (!isEmpty(granularity)) {
        granularity[CAUSE_ID_KEY] = allConditionIds;
    }

    const customTheme = {
        ...theme,
        title: {
            backgroundColor: 'transparent',
            left: 'center',
            textStyle: {
                ...textStyle,
                fontSize: 18,
                fontWeight: 700,
            },
            subtextStyle: {
                ...textStyle,
                fontSize: 16,
                fontWeight: 500,
            },
        },
        treemap: {
            ...theme.treemap,
            levels: [
                {},
                {
                    upperLabel: {
                        normal: {
                            ...textStyle,
                            fontSize: 13,
                            color: '#000',
                            show: true,
                            height: 24,
                            position: 'insideLeft',
                            padding: [4, 0, 0, 0],
                        },
                        emphasis: {
                            ...textStyle,
                            fontSize: 13,
                            fontWeight: 500,
                            // color: '#fff',
                            show: true,
                            height: 24,
                            position: 'insideLeft',
                            padding: [3, 0, 0, 0],
                        },
                    },
                    label: {
                        ...textStyle,
                        fontSize: 10,
                        color: '#fff',
                        position: 'insideLeft',
                        padding: [1, 0, 0, 0],
                        emphasis: {
                            ...textStyle,
                            fontSize: 10,
                            fontWeight: 500,
                            color: '#fff',
                            padding: [0, 0, 0, 0],
                        },
                    },
                },
                {
                    label: {
                        ...textStyle,
                        fontSize: 10,
                        color: '#fff',
                        position: 'insideTopLeft',
                        padding: [3, 0, 0, 3],
                        emphasis: {
                            ...textStyle,
                            fontSize: 12,
                            fontWeight: 500,
                            color: '#fff',
                            padding: [3, 0, 0, 3],
                        },
                    },
                },
            ],
        },
    };

    const getConditionStyles = (condition: CauseEntity) => {
        const { id, level } = condition;

        if (level === 0) {
            return {
                itemStyle: {
                    gapWidth: 8,
                    borderColor: '#F0F2F1',
                    borderWidth: 10,
                },
            };
        }

        const isMM = id === config.multipleMyelomaCauseId;

        const color = isMM ? '#C5ACDB' : '#BCD4C5';
        const borderColor = isMM ? '#5B00E8' : '#32BF64';
        const textColor = isMM ? '#FFF' : '#32BF64';
        const fontSize = 14;
        // const color = theme.colorPalette[allNeoplasmsChildrenIds.indexOf(id)];

        return {
            itemStyle: {
                color,
                borderColor,
                borderWidth: isMM ? 3 : 1,
            },
            label: {
                ...textStyle,
                fontSize,
                color: textColor,
                position: 'insideTopLeft',
                padding: [5, 0, 3, 3],
            },
            emphasis: {
                itemStyle: {
                    color: shadeHex(color, 15),
                    borderColor: shadeHex(borderColor, 15),
                },
                label: {
                    ...textStyle,
                    fontSize: fontSize,
                    fontWeight: textStyle.fontWeight + 200,
                    color: '#fff',
                    padding: [4, 0, 3, 3],
                },
            },
        };
    };

    const renderTitle = ({ timelineValue }) =>
        _('simulation_tool_treemap_chart_title', {
            location: _(`location_${locationId}`),
            year: timelineValue,
        });

    const renderSubtitle = () => '';

    const handleExportData = (format) => {
        dispatch(
            downloadDataExportAsync.request({
                format,
                chart_types: [TYPE_TREEMAP_CHART],
                conditions,
                filters,
                data_types: [dataType],
            })
        );
    };

    const chartComponent = (
        <Container
            key={dataResponse ? 'data-derived' : 'no-data'}
            theme={customTheme}
            initialTimeUnitValue={2019}
            detailLevel={3}
            getConditionStyles={getConditionStyles}
            selectedConditionsDataTypes={[dataType]}
            selectedConditionsDataTypesGranularity={granularity}
            entityHierarchies={
                entityHierarchies?.causes
                    ? {
                          causes: entityHierarchies.causes
                              .filter(
                                  ({ id, level, level2_parent_id }) =>
                                      id === 410 ||
                                      (level2_parent_id === 410 && (level === 2 || level === 3))
                              )
                              .map((entity) => ({
                                  ...entity,
                                  level: entity.id === 410 ? 0 : 1,
                                  level0_parent_id: entity.id === 410 ? 0 : 410,
                                  level1_parent_id: 0,
                                  level2_parent_id: 0,
                              })),
                      }
                    : null
            }
            isLoadingData={isLoading}
            filtersValues={filters}
            dataResponses={
                dataResponse
                    ? {
                          [dataType]: dataResponse,
                      }
                    : null
            }
            timeUnitKey={YEAR_KEY}
            selectedDataTool={dataTool}
            selectedDataCollection={dataCollection}
            dataType={dataType}
            enableExportPNG
            onExportData={handleExportData}
            isChartPoppedOut={isChartPoppedOut}
            toggleChartPoppedOut={toggleChartPoppedOut}
            selectedConditionEntity={undefined}
            setConditionEntity={() => {}}
            numberFormatter={(value: number | string) => formatChartValue(value)}
            renderTitle={renderTitle}
            renderSubtitle={renderSubtitle}
        />
    );

    return (
        <Wrapper>
            {error && <Alert color="error">{error}</Alert>}
            {entityHierarchies?.causes && (
                <>
                    {chartComponent}
                    <Modal show={isChartPoppedOut} size="large" dialogClassName="modal-95w">
                        <ModalBody>{chartComponent}</ModalBody>
                    </Modal>
                </>
            )}
        </Wrapper>
    );
};

export default NeoplasmsTreemapChart;
