import React from 'react';
import { isEmpty, merge, uniq } from 'lodash/fp';

import {
    chartProviderFiltersToObject,
    getChartSubTitle,
    getChartTitle,
    localizeCondition,
    localizeConditions,
    localizeFilterValues,
    mapValueToCondition,
} from '@portal/common/utility/chart-data-helpers';

import {
    AGE_GROUP_ID_KEY,
    ANTIBIOTIC_CLASS_ID_KEY,
    CAUSE_ID_KEY,
    COUNTERFACTUAL_ID_KEY,
    DATA_TYPE_KEY,
    EDUCATION_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    INFECTIOUS_SYNDROME_ID_KEY,
    LOCATION_ID_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    PATHOGEN_ID_KEY,
    PRIMARY_ENTITY_ID_LOCALIZATION_KEY,
    PRIMARY_ENTITY_KEY,
    RACE_ID_KEY,
    RISK_EXPOSURE_ID_KEY,
    VALUE_KEY,
} from '@portal/common/models/data-key';
import { PNG } from '@portal/common/models/file-format';
import {
    DataCollectionResource,
    DataCondition,
    DataExportFormat,
    DataFilters,
    DataGranularity,
    DataGranularityKey,
    DataKey,
    DataRecord,
    DataResponse,
    DataType,
    Entity,
} from '@portal/common/types';
import getTitleAndSubtitleAdjustedToWidth from '@portal/common/utility/echarts-helpers/get-adopted-title-and-subtitle';
import { TYPE_ARROW_CHART } from '@portal/common/models/chart-type';
import { getExportFilename } from '@portal/common/utility/get-export-filename';
import { pngIcon } from '@portal/common/theme/icons';

import echarts from '../../../../theme/echarts';
import _ from '../../../../locale';

import { getConditionFromDataKeyValue } from '../../../ArrowChart/utils';
import ArrowChart from '../../../../components/ArrowChart';

type Props = {
    isLoadingData: boolean;
    dataResponse: DataResponse;
    timeUnitKey: DataGranularityKey;
    dataKey: DataGranularityKey;
    entityHierarchies: Entity[];

    selectedConditions: DataCondition[];
    forwardedRef?: any;
    selectedConditionsDataTypes: DataType[];
    selectedConditionsDataTypesGranularity: DataGranularity;
    dataTool: string;
    selectedDataTool: string;
    selectedDataCollection: DataCollectionResource;
    filtersValues: DataFilters;
    isForecastingData: boolean;
    onExportData: (format: DataExportFormat) => void;
    enableExportPNG: boolean;
    numberFormatter: (value: number | string) => string;
    chartFilters: DataGranularity;
    onConditionSelect?: (value: DataCondition) => void;

    drillableItemIds: number[];
    drillPath?: DataCondition[];
    isDrillable: boolean;
    drillIntoEntity?: DataCondition;
    drillConfig: {
        isEnabled?: boolean;
        path?: DataCondition[];
        itemIds?: number[];
        drillIntoEntity?: DataCondition;
    };
};

type State = {
    selectedLegend: object;
};

export default class Chart extends React.PureComponent<Props, State> {
    state: State = {
        selectedLegend: {},
    };

    componentDidUpdate(prevProps) {
        if (prevProps.dataKey !== this.props.dataKey) {
            this.setState({ selectedLegend: {} });
        }
    }

    chartRef = this.props.forwardedRef || React.createRef();

    getSelectedConditions = (): DataCondition[] => {
        const { chartFilters, selectedConditions } = this.props;

        return chartFilters && !isEmpty(chartFilters[PRIMARY_ENTITY_KEY])
            ? chartFilters[PRIMARY_ENTITY_KEY].map(mapValueToCondition)
            : selectedConditions;
    };

    getSaveFilename = ({ filters, sourceTimeUnit, targetTimeUnit }) => {
        const { selectedDataTool, selectedDataCollection } = this.props;

        const conditions = this.getSelectedConditions();

        return getExportFilename({
            trigger: TYPE_ARROW_CHART,
            dataTool: selectedDataTool,
            dataCollectionName: selectedDataCollection.name,
            conditions: localizeConditions(conditions),
            filters,
            selectedTimeUnit: `${sourceTimeUnit}, ${targetTimeUnit}`,
        });
    };

    saveAsImageOptions = {
        visible: true,
        enabled: this.props.enableExportPNG,
        filename: this.getSaveFilename,
        icon: pngIcon,
        onClick: () => this.props.onExportData(PNG),
    };

    configureTitles = ({ filters, chartWidth, sourceTimeUnit, targetTimeUnit }) => [
        {
            ...getTitleAndSubtitleAdjustedToWidth(
                this.renderTitle(filters, `${sourceTimeUnit}, ${targetTimeUnit}`),
                this.renderSubtitle(filters),
                chartWidth,
                document.getElementById('canvasFontSizer'),
                echarts.title,
                100
            ),
        },
    ];

    renderTitle = (filters, timeRange: string) => {
        const {
            selectedConditionsDataTypesGranularity,
            dataResponse: { records },
        } = this.props;

        const conditions = this.getSelectedConditions();
        const dataTypes = uniq(conditions.map(({ data_type }) => data_type));

        return !isEmpty(records)
            ? getChartTitle(
                  dataTypes,
                  this.getConditionsName(),
                  chartProviderFiltersToObject(filters),
                  Object.keys(selectedConditionsDataTypesGranularity),
                  [RISK_EXPOSURE_ID_KEY, CAUSE_ID_KEY, MEASURE_ID_KEY, METRIC_ID_KEY],
                  timeRange
              )
            : '';
    };

    getConditionsName = (): string => {
        const { isDrillable, drillIntoEntity } = this.props;
        const conditions = this.getSelectedConditions();
        if (!isDrillable) return localizeConditions(conditions);

        const namePart = !!drillIntoEntity?.level
            ? `${_(drillIntoEntity.data_type)} Level ${drillIntoEntity?.level}`
            : localizeCondition(drillIntoEntity);

        return `${namePart} conditions comparison`;
    };

    renderSubtitle = (filters) => {
        const {
            isForecastingData,
            selectedConditionsDataTypes,
            selectedConditionsDataTypesGranularity,
            dataResponse: { records },
        } = this.props;

        const titleFilters = [
            PATHOGEN_ID_KEY,
            INFECTIOUS_SYNDROME_ID_KEY,
            RACE_ID_KEY,
            LOCATION_ID_KEY,
            AGE_GROUP_ID_KEY,
            GENDER_ID_KEY,
            ANTIBIOTIC_CLASS_ID_KEY,
            COUNTERFACTUAL_ID_KEY,
            EDUCATION_ID_KEY,
        ];

        if (isForecastingData) {
            titleFilters.push(FORECAST_SCENARIO_ID_KEY);
        }

        return records.length > 0
            ? getChartSubTitle(
                  chartProviderFiltersToObject(filters),
                  Object.keys(selectedConditionsDataTypesGranularity),
                  titleFilters,
                  selectedConditionsDataTypes
              )
            : '';
    };

    mapDataRecordToCategory = (record: DataRecord): string => {
        return this.getCategoryName(record);
    };

    filterBySelectedCategoryCallbackFn = (record) => {
        const category = this.mapDataRecordToCategory(record);
        return this.state.selectedLegend?.[category];
    };

    filterRecordsBySelectedCategories = (records: DataRecord[]): DataRecord[] => {
        const { selectedLegend } = this.state;
        if (isEmpty(selectedLegend)) {
            return records;
        }

        return records.filter(this.filterBySelectedCategoryCallbackFn);
    };

    renderTooltip = (params, renderProps) => {
        const {
            timeUnitKey,
            numberFormatter,
            dataKey,
            dataResponse: { columns },
        } = this.props;

        const { sourceRecords, targetRecords, timeUnitValues } = renderProps;

        const {
            data: { dataKeyValue },
        } = params;

        if (!dataKeyValue) return null;

        const { targetTimeUnit } = renderProps;

        const timeUnitIdx = columns.indexOf(timeUnitKey);
        const dataKeyIdx = columns.indexOf(dataKey);
        const dataTypeIdx = columns.indexOf(DATA_TYPE_KEY);
        const valueIdx = columns.indexOf(VALUE_KEY);

        const findRecordCallbackFn = (dataRecord) => dataRecord[dataKeyIdx] === dataKeyValue;

        const hasTargetData = timeUnitValues.length > 1;

        const getColumnProps = (records) => {
            const record = records.find(findRecordCallbackFn);
            const place = records.findIndex(findRecordCallbackFn) + 1;
            const value = record[valueIdx];
            const timeUnitValue = record[timeUnitIdx];
            return {
                record,
                place,
                value,
                timeUnitValue,
                isTarget: timeUnitValue === targetTimeUnit,
            };
        };

        const sourceProps = getColumnProps(this.filterRecordsBySelectedCategories(sourceRecords));
        const targetProps = hasTargetData
            ? getColumnProps(this.filterRecordsBySelectedCategories(targetRecords))
            : null;

        const dataType = sourceProps.record[dataTypeIdx];

        const title = this.localizeFilterValue(dataKey, dataKeyValue, dataType);

        const getValueDiff = () => {
            const previousValue = sourceProps.value;
            const currentValue = targetProps?.value;
            if (previousValue === 0) {
                return currentValue > 0 ? 100 : currentValue < 0 ? -100 : 0;
            }

            return ((currentValue - previousValue) / previousValue) * 100;
        };

        const renderColumnNames = () =>
            `<div style="display: flex; flex-direction: column; row-gap: 10px; align-items: flex-start;">
                <div>Year</div>
                <div>Rank</div>
                <div>Value</div>
                ${hasTargetData ? `<div>Change</div>` : ''}
            </div>`;

        const renderColumnData = (params) => {
            if (!params) return '';

            const { place, value, timeUnitValue, isTarget } = params;

            return `<div style="display: flex; flex-direction: column; row-gap: 10px; align-items: flex-end;">
                <div><b>${timeUnitValue}</b></div>
                <div><b>#${place}</b></div>
                <div><b>${numberFormatter(value)}</b></div>
                ${
                    hasTargetData
                        ? `<div><b>${
                              isTarget ? `${numberFormatter(getValueDiff())}%` : '&nbsp;'
                          }</b></div>`
                        : ''
                }
            </div>`;
        };

        return `<div class="chart-tooltip">
                    <div style="display: flex; flex-direction: column;">
                        <div style="padding-bottom: 5px; margin-bottom: 10px; border-bottom: 1px solid #DFE0E0;">
                            <b>${title}</b>
                        </div>
                        <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: flex-end; column-gap: 20px;">
                            ${renderColumnNames()}
                            ${renderColumnData(sourceProps)}
                            ${renderColumnData(targetProps)}
                        </div>
                    </div>
                </div>`;
    };

    localizeFilterValue = (key: DataKey, value: number | string, dataType: DataType) => {
        if (key === PRIMARY_ENTITY_ID_LOCALIZATION_KEY) {
            const condition = {
                data_type: dataType,
                primary_entity_id: value.toString().split('_').slice(-1)[0],
            };

            return localizeConditions([condition]);
        }

        return localizeFilterValues(key, [value]);
    };

    getItemLabel = (record: DataRecord): string => {
        const { dataKey, dataResponse } = this.props;
        const dataKeyIdx = dataResponse.columns.indexOf(dataKey);
        const dataTypeIdx = dataResponse.columns.indexOf(DATA_TYPE_KEY);
        const value = record[dataKeyIdx];
        const dataType = record[dataTypeIdx];

        return this.localizeFilterValue(dataKey, value, dataType);
    };

    getCategoryName = (record: DataRecord) => {
        const { dataKey, dataResponse } = this.props;

        const dataKeyIdx = dataResponse.columns.indexOf(dataKey);
        const dataTypeIdx = dataResponse.columns.indexOf(DATA_TYPE_KEY);
        const value = record[dataKeyIdx];
        const dataType = record[dataTypeIdx];

        return this.localizeFilterValue(dataKey, value, dataType);
    };

    handleLegendSelectChange = ({ selected }) => {
        this.setState({ selectedLegend: selected });
    };

    handleNodeClick = (params) => {
        const {
            dataResponse: { columns },
        } = this.props;
        const dataRecord = params.data.value[3];
        const condition = getConditionFromDataKeyValue(dataRecord, columns);

        this.selectNode(condition);
    };

    handleBreadcrumbClick = (condition: DataCondition) => {
        this.selectNode(condition);
    };

    selectNode = (condition: DataCondition) => {
        this.setState({ selectedLegend: {} });
        this.props.onConditionSelect?.(condition);
    };

    render() {
        const {
            isLoadingData,
            filtersValues,
            timeUnitKey,
            dataKey,
            dataResponse,
            drillableItemIds,
            drillPath,
            isDrillable,
        } = this.props;

        const { selectedLegend } = this.state;

        if (!(dataResponse && dataResponse.columns)) {
            return null;
        }
        const onEvents = {
            legendselectchanged: this.handleLegendSelectChange,
        };

        return (
            <>
                <ArrowChart
                    key={dataKey}
                    timeUnitKey={timeUnitKey}
                    isLoadingData={isLoadingData}
                    filtersValues={filtersValues}
                    {...dataResponse}
                    ref={this.chartRef}
                    theme={merge(echarts, {
                        color: [
                            ...new Set([
                                ...echarts.arrow.seriesColorSpectrum,
                                ...echarts.colorPalette,
                            ]),
                        ],
                    })}
                    dataKey={dataKey}
                    saveAsImage={this.saveAsImageOptions}
                    onEvents={onEvents}
                    selectedCategories={selectedLegend}
                    configureTitles={this.configureTitles}
                    renderTooltip={this.renderTooltip}
                    getCategoryName={this.getCategoryName}
                    getItemLabel={this.getItemLabel}
                    mapDataRecordToCategory={this.mapDataRecordToCategory}
                    filterRecordsBySelectedCategories={this.filterRecordsBySelectedCategories}
                    onNodeClick={this.handleNodeClick}
                    isDrillable={isDrillable}
                    drillableItemIds={drillableItemIds}
                    drillPath={drillPath}
                    onBreadcrumbClick={this.handleBreadcrumbClick}
                />
                <canvas id="canvasFontSizer" width="0" height="0" />
            </>
        );
    }
}
