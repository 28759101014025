import { RootEpic } from 'MyTypes';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { MY_FAVORITIES_PATH } from '../../router/paths';
import {
    deleteOrganizationResourceListAsync,
    getFavoriteResourcesAsync,
    getGroupContentResourcesAsync,
    getPermalinkResourcesAsync,
    getPublicResourceListsAsync,
    getResourceListsAsync,
    makeResourceListDefaultAsync,
    makeResourceListNotDefaultAsync,
    makeResourceListPrivateAsync,
    makeResourceListPublicAsync,
    removePermalinkResourceAsync,
    subscribeToListAsync,
    unsubscribeFromListAsync,
} from './actions';

export const loadFavoriteResources: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getFavoriteResourcesAsync.request])),
        switchMap((action) =>
            from(
                api.organizationMemberFavoriteResource.getFavoriteResources({
                    organizationId: action.payload.organizationId,
                    memberId: action.payload.memberId,
                    page: '1',
                    per_page: '10000',
                    order_by: 'created',
                    order_direction: 'DESC',
                })
            ).pipe(
                map((res) => getFavoriteResourcesAsync.success(res.results)),
                catchError((message: string) => of(getFavoriteResourcesAsync.failure(message)))
            )
        )
    );

export const loadPermalinkResources: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getPermalinkResourcesAsync.request])),
        switchMap((action) =>
            from(
                api.permalink.getPermalinks({
                    organizationId: action.payload.organizationId,
                    memberId: action.payload.memberId,
                    page: '1',
                    per_page: '10000',
                    order_by: 'created',
                    order_direction: 'DESC',
                })
            ).pipe(
                map((res) => getPermalinkResourcesAsync.success(res.results)),
                catchError((message: string) => of(getPermalinkResourcesAsync.failure(message)))
            )
        )
    );

export const removePermalink: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([removePermalinkResourceAsync.request])),
        switchMap((action) =>
            from(
                api.permalink.removePermalink({
                    permalinkId: action.payload.permalinkId,
                    organizationId: action.payload.organizationId,
                    memberId: action.payload.memberId,
                })
            ).pipe(
                map(() => {
                    action.payload.loadItems();
                    return removePermalinkResourceAsync.success({
                        organizationId: action.payload.organizationId,
                        memberId: action.payload.memberId,
                    });
                }),
                catchError((message: string) => of(removePermalinkResourceAsync.failure(message)))
            )
        )
    );

export const loadGroupContentResources: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getGroupContentResourcesAsync.request])),
        switchMap((action) =>
            from(
                api.organizationGroupContent.getLinksAndFiles({
                    organizationId: action.payload.organizationId,
                    page: '1',
                    per_page: '10000',
                    order_by: 'created',
                    order_direction: 'DESC',
                })
            ).pipe(
                map((res) => getGroupContentResourcesAsync.success(res.results)),
                catchError((message: string) => of(getGroupContentResourcesAsync.failure(message)))
            )
        )
    );

export const loadResourceLists: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getResourceListsAsync.request])),
        switchMap((action) =>
            from(
                api.organizationMemberMyResourceList.getMyResourceLists({
                    organizationId: action.payload.organizationId,
                    memberId: action.payload.memberId,
                    page: '1',
                    per_page: '10000',
                    order_by: 'created',
                    order_direction: 'DESC',
                    fields: 'resource_ids,creator',
                })
            ).pipe(
                map((res) => getResourceListsAsync.success(res.results)),
                catchError((message: string) => of(getResourceListsAsync.failure(message)))
            )
        )
    );

// Resource Lists
export const subscribeToList: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([subscribeToListAsync.request])),
        switchMap((action) =>
            from(
                api.organizationMemberSubscribedResourceList.subscribe(
                    action.payload.organizationId,
                    action.payload.memberId,
                    action.payload.listId
                )
            ).pipe(
                map((res) =>
                    subscribeToListAsync.success({
                        prevList: action.payload.prevList,
                        list: res,
                    })
                ),
                catchError((message: string) => of(subscribeToListAsync.failure(message)))
            )
        )
    );

export const unsubscribeFromList: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([unsubscribeFromListAsync.request])),
        switchMap((action) =>
            from(
                api.organizationMemberSubscribedResourceList.unsubscribe(
                    action.payload.organizationId,
                    action.payload.memberId,
                    action.payload.listId
                )
            ).pipe(
                map((res) => unsubscribeFromListAsync.success(res)),
                catchError((message: string) => of(unsubscribeFromListAsync.failure(message)))
            )
        )
    );

// Public lists
export const loadPublicResourceLists: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getPublicResourceListsAsync.request])),
        switchMap((action) =>
            from(
                api.organizationPublicResourceList.getPublicResourceLists({
                    organizationId: action.payload.organizationId,
                    page: '1',
                    per_page: '10000',
                    order_by: 'created',
                    order_direction: 'DESC',
                    fields: 'resource_ids,creator',
                })
            ).pipe(
                map((res) => getPublicResourceListsAsync.success(res.results)),
                catchError((message: string) => of(getPublicResourceListsAsync.failure(message)))
            )
        )
    );

export const makeResourceListPublic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([makeResourceListPublicAsync.request])),
        switchMap((action) =>
            from(
                api.organizationPublicResourceList.makeResourceListPublic(
                    action.payload.organizationId,
                    action.payload.listId
                )
            ).pipe(
                map((res) => makeResourceListPublicAsync.success(res)),
                catchError((message: string) => of(makeResourceListPublicAsync.failure(message)))
            )
        )
    );

export const makeResourceListPrivate: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([makeResourceListPrivateAsync.request])),
        switchMap((action) =>
            from(
                api.organizationPublicResourceList.makeResourceListPrivate(
                    action.payload.organizationId,
                    action.payload.listId
                )
            ).pipe(
                map((res) => makeResourceListPrivateAsync.success(res)),
                catchError((message: string) => of(makeResourceListPrivateAsync.failure(message)))
            )
        )
    );

export const makeResourceListDefault: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([makeResourceListDefaultAsync.request])),
        switchMap((action) =>
            from(
                api.organizationPublicResourceList.makeResourceListDefault(
                    action.payload.organizationId,
                    action.payload.listId
                )
            ).pipe(
                map((res) => makeResourceListDefaultAsync.success(res)),
                catchError((message: string) => of(makeResourceListDefaultAsync.failure(message)))
            )
        )
    );

export const makeResourceListNotDefault: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([makeResourceListNotDefaultAsync.request])),
        switchMap((action) =>
            from(
                api.organizationPublicResourceList.makeResourceListNotDefault(
                    action.payload.organizationId,
                    action.payload.listId
                )
            ).pipe(
                map((res) => makeResourceListNotDefaultAsync.success(res)),
                catchError((message: string) =>
                    of(makeResourceListNotDefaultAsync.failure(message))
                )
            )
        )
    );

export const deleteOrganizationResourceList: RootEpic = (action$, state$, { api, history }) =>
    action$.pipe(
        filter(isActionOf([deleteOrganizationResourceListAsync.request])),
        switchMap(({ payload: { organizationId, listId } }) =>
            from(
                api.organizationResourceList.deleteOrganizationResourceList({
                    organizationId,
                    listId,
                })
            ).pipe(
                map((res) => deleteOrganizationResourceListAsync.success({ listId })),
                tap((action) => {
                    history.replace(MY_FAVORITIES_PATH);
                }),
                catchError((message: string) =>
                    of(deleteOrganizationResourceListAsync.failure(message))
                )
            )
        )
    );
