import React from 'react';
import { merge, sortBy, uniq } from 'lodash/fp';

import {
    chartProviderFiltersToObject,
    getChartSubTitle,
    getChartTitle,
    getYAxisTitle,
    localizeConditions,
    localizeFilterValues,
} from '@portal/common/utility/chart-data-helpers';
import { BarChart } from '@portal/common/components';
import { PNG } from '@portal/common/models/file-format';
import {
    AGE_GROUP_ID_KEY,
    ANTIBIOTIC_CLASS_ID_KEY,
    CAUSE_ID_KEY,
    COMORBID_CAUSE_ID_KEY,
    COUNTERFACTUAL_ID_KEY,
    EDUCATION_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    INFECTIOUS_SYNDROME_ID_KEY,
    isTimeUnitKey,
    LOCATION_ID_KEY,
    LOWER_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    PATHOGEN_ID_KEY,
    PRIMARY_ENTITY_ID_LOCALIZATION_KEY,
    RACE_ID_KEY,
    RISK_EXPOSURE_ID_KEY,
    UPPER_KEY,
    VALUE_KEY,
} from '@portal/common/models/data-key';
import {
    AgeGroupEntity,
    DataCollectionResource,
    DataCondition,
    DataExportFormat,
    DataFilters,
    DataGranularity,
    DataGranularityKey,
    DataResponse,
    DataToolConfig,
    DataType,
} from '@portal/common/types';
import getTitleAndSubtitleAdjustedToWidth from '@portal/common/utility/echarts-helpers/get-adopted-title-and-subtitle';
import formatChartLabel from '@portal/common/utility/formatting/formatChartLabel';
import { formatValue } from '@portal/common/utility/filters-helpers';
import { TYPE_BAR_CHART } from '@portal/common/models/chart-type';
import { getExportFilename } from '@portal/common/utility/get-export-filename';
import { DataProjectId } from '@portal/common/models/data-project';
import { pngIcon } from '@portal/common/theme/icons';

import animatableChart from '../../../../utility/hoc/animatable-chart';
import { breakLineAtEach } from '../../../../utility/string-utils';
import getDataResponseColumnValues from '../../../../utility/data/get-data-response-column-values';

import { MORBIDITY_EXPLORER_PATH } from '../../../../router/paths';
import _ from '../../../../locale';
import echarts from '../../../../theme/echarts';

const HORIZONTAL_LAYOUT_ALLOWED_KEYS = [
    LOCATION_ID_KEY,
    AGE_GROUP_ID_KEY,
    RACE_ID_KEY,
    EDUCATION_ID_KEY,
];

type Props = {
    initialTimeUnitValue: number | string;
    xAxisKey: DataGranularityKey;
    selectedConditions: DataCondition[];
    forwardedRef?: any;
    selectedConditionsDataTypes: DataType[];
    selectedConditionsDataTypesGranularity: DataGranularity;
    selectedConditionsPrimaryEntityFilters: DataGranularityKey[];
    stackByKey?: DataGranularityKey | null;
    dataTool: string;
    dataProject: number;
    ageGroupEntities: AgeGroupEntity[];
    selectedDataTool: string;
    selectedDataCollection: DataCollectionResource;
    mergedDataResponses: DataResponse;
    isLoadingData: boolean;
    filtersValues: DataFilters;
    timeUnitKey: DataGranularityKey;
    isForecastingData: boolean;
    onExportData: (format: DataExportFormat) => void;
    enableExportPNG: boolean;
    multipleFilterKeys: DataGranularityKey[];
    numberFormatter: (value: number | string) => string;
    displayBarLabels: boolean;
    displayUncertainties: boolean;
    onDataLabelsEnabledChange: (value: boolean) => void;
    areDataLabelsDisabled: boolean;
    dataDeliveryCounter: number;
    onUncertaintyAvailabilityChange?: (value: boolean) => void;
    dataToolConfig: DataToolConfig;
};

type State = {
    selectedCondition: string;
    dataResponse: DataResponse;
    xAxisValues: (number | string)[];
};

class Chart extends React.PureComponent<Props, State> {
    state: State = {
        selectedCondition: '',
        dataResponse: { columns: [], records: [] },
        xAxisValues: [],
    };

    chartRef = React.createRef();

    componentDidMount() {
        this.initialize();
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: any
    ): void {
        const { dataDeliveryCounter, xAxisKey } = this.props;
        if (
            (dataDeliveryCounter && dataDeliveryCounter !== prevProps.dataDeliveryCounter) ||
            xAxisKey !== prevProps.xAxisKey
        ) {
            this.initialize();
        }
    }

    initialize = () => {
        const { ageGroupsOrder, xAxisKey, mergedDataResponses } = this.props;

        let xAxisValues = getDataResponseColumnValues(mergedDataResponses, xAxisKey);

        if (!isTimeUnitKey(xAxisKey)) {
            if (xAxisKey === AGE_GROUP_ID_KEY) {
                xAxisValues = sortBy((value) => ageGroupsOrder[value], xAxisValues);
            } else {
                xAxisValues = sortBy(
                    (value) => localizeFilterValues(xAxisKey, [value as number]),
                    xAxisValues
                );
            }
        }

        this.setState({ dataResponse: mergedDataResponses, xAxisValues });
    };

    sortResponseByAgeGroups = (dataResponse: DataResponse): DataResponse => {
        const { ageGroupsOrder } = this.props;
        const { columns, records } = dataResponse;

        const ageGroupIdx = columns.indexOf(AGE_GROUP_ID_KEY);

        return {
            columns,
            records: ageGroupsOrder
                ? sortBy((record) => -ageGroupsOrder[record[ageGroupIdx]], records)
                : records,
        };
    };

    configureTitles = ({
        chartInstanceKeyValues,
        filters,
        timeRange: { range },
        timelineValue,
        chartWidth,
    }) => {
        const isMultiCharts = chartInstanceKeyValues && chartInstanceKeyValues.length > 1;
        if (!isMultiCharts) {
            return [
                getTitleAndSubtitleAdjustedToWidth(
                    this.renderTitle(filters, range, timelineValue),
                    this.renderSubtitle(filters),
                    chartWidth,
                    document.getElementById('canvasFontSizer'),
                    echarts.title
                ),
            ];
        }

        return [
            {
                ...getTitleAndSubtitleAdjustedToWidth(
                    this.renderTitle(
                        {
                            ...filters,
                            [METRIC_ID_KEY]: [chartInstanceKeyValues[0]],
                        },
                        range,
                        timelineValue
                    ),
                    this.renderSubtitle(filters),
                    chartWidth / 2,
                    document.getElementById('canvasFontSizer'),
                    echarts.title,
                    40
                ),
                left: 60,
            },
            {
                ...getTitleAndSubtitleAdjustedToWidth(
                    this.renderTitle(
                        {
                            ...filters,
                            [METRIC_ID_KEY]: [chartInstanceKeyValues[1]],
                        },
                        range,
                        timelineValue
                    ),
                    this.renderSubtitle(filters),
                    chartWidth / 2,
                    document.getElementById('canvasFontSizer'),
                    echarts.title,
                    60
                ),
                left: chartWidth / 2 + 60,
            },
        ];
    };

    renderTitle = (filters, timeRange, timelineValue) => {
        const {
            selectedConditions,
            selectedConditionsDataTypes,
            selectedConditionsDataTypesGranularity,
            xAxisKey,
            timeUnitKey,
        } = this.props;
        const { records } = this.state.dataResponse;

        return records.length > 0
            ? getChartTitle(
                  selectedConditionsDataTypes,
                  localizeConditions(selectedConditions),
                  chartProviderFiltersToObject(filters),
                  Object.keys(selectedConditionsDataTypesGranularity),
                  [RISK_EXPOSURE_ID_KEY, CAUSE_ID_KEY, MEASURE_ID_KEY, METRIC_ID_KEY],
                  xAxisKey === timeUnitKey ? timeRange : timelineValue
              )
            : '';
    };

    renderSubtitle = (filters) => {
        const {
            isForecastingData,
            selectedConditionsDataTypes,
            selectedConditionsDataTypesGranularity,
            xAxisKey,
            ageGroupEntities,
            selectedConditionsPrimaryEntityFilters,
        } = this.props;
        const { records } = this.state.dataResponse;

        const titleFilters = [
            PATHOGEN_ID_KEY,
            INFECTIOUS_SYNDROME_ID_KEY,
            RACE_ID_KEY,
            LOCATION_ID_KEY,
            AGE_GROUP_ID_KEY,
            GENDER_ID_KEY,
            ANTIBIOTIC_CLASS_ID_KEY,
            COUNTERFACTUAL_ID_KEY,
            EDUCATION_ID_KEY,
        ].filter((key) => ![xAxisKey, ...selectedConditionsPrimaryEntityFilters].includes(key));

        if (isForecastingData) {
            titleFilters.push(FORECAST_SCENARIO_ID_KEY);
        }

        return records.length > 0
            ? getChartSubTitle(
                  chartProviderFiltersToObject(filters),
                  Object.keys(selectedConditionsDataTypesGranularity),
                  titleFilters,
                  selectedConditionsDataTypes,
                  ageGroupEntities
              )
            : '';
    };

    getSaveFilename = ({ filters, timeRange: { range }, timelineValue }) => {
        const { selectedConditions, selectedDataTool, selectedDataCollection } = this.props;

        return getExportFilename({
            trigger: TYPE_BAR_CHART,
            dataTool: selectedDataTool,
            dataCollectionName: selectedDataCollection.name,
            conditions: localizeConditions(selectedConditions),
            filters,
            selectedTimeUnit: timelineValue || range,
        });
    };

    renderTooltip = (params) => {
        const {
            isForecastingData,
            timeUnitKey,
            dataTool,
            dataProject,
            numberFormatter,
            dataToolConfig,
        } = this.props;
        const { columns } = this.state.dataResponse;
        const { data: record } = params;

        const data = {};

        columns.forEach((filterKey, idx) => {
            data[filterKey] = record[idx];
        });

        const renderConditionName = () => {
            const conditionIndex = columns.indexOf(PRIMARY_ENTITY_ID_LOCALIZATION_KEY);
            const name = _(record[conditionIndex]);
            const label =
                dataToolConfig.customConditionLabel ||
                _(`tooltip_label_${PRIMARY_ENTITY_ID_LOCALIZATION_KEY}`);

            return `<div style="padding-bottom: 10px">
                    ${label}: <b>${name}</b>
                </div>`;
        };

        const renderFilter = (filterKey: DataGranularityKey, permanent: boolean = false) => {
            if (data[filterKey] === undefined) {
                // no filter value
                return '';
            }

            const name = _(`tooltip_label_${filterKey}`);
            const value = formatValue(filterKey, data[filterKey], _);

            return permanent || this.isMultipleFilter(filterKey)
                ? `<div style="padding-bottom: 10px">
                        ${name}: <b>${value}</b>
                    </div>`
                : '';
        };

        const renderValue = (
            name: string = 'Value',
            valueKey: string = VALUE_KEY,
            upperKey: string = UPPER_KEY,
            lowerKey: string = LOWER_KEY
        ) => {
            let value = numberFormatter(data[valueKey]);
            const upper = numberFormatter(data[upperKey]);
            const lower = numberFormatter(data[lowerKey]);

            const uncertainty = upper !== null && lower !== null ? `(${lower} - ${upper})` : '';

            return `<div style="text-transform: capitalize; padding-bottom: 5px;">
                    ${name}: <b>${value} ${uncertainty}</b>
                </div>`;
        };

        return `<div class="chart-tooltip">
                ${renderFilter(timeUnitKey, true)}
                ${renderConditionName()}
                ${renderFilter(INFECTIOUS_SYNDROME_ID_KEY, dataProject === DataProjectId.AMR)}
                ${renderFilter(COUNTERFACTUAL_ID_KEY, dataProject === DataProjectId.AMR)}
                ${renderFilter(ANTIBIOTIC_CLASS_ID_KEY, dataProject === DataProjectId.AMR)}
                ${renderFilter(COMORBID_CAUSE_ID_KEY, dataTool === MORBIDITY_EXPLORER_PATH)}
                ${renderFilter(FORECAST_SCENARIO_ID_KEY, isForecastingData)}
                ${renderFilter(LOCATION_ID_KEY, true)}
                ${renderFilter(RACE_ID_KEY, dataProject === DataProjectId.RACE_ETHNICITY)}
                ${renderFilter(MEASURE_ID_KEY, true)}
                ${renderFilter(AGE_GROUP_ID_KEY, true)}
                ${renderFilter(GENDER_ID_KEY, true)}
                ${renderFilter(METRIC_ID_KEY)}
                ${renderFilter(EDUCATION_ID_KEY, dataProject === DataProjectId.RACE_ETHNICITY)}
                ${renderValue()}
            </div>`;
    };

    formatLegendLabel = (
        dataKeyValue: string,
        renderProps: {},
        stackByValue: string | number,
        record
    ) => {
        const { multipleFilterKeys, stackByKey, xAxisKey } = this.props;
        const {
            dataResponse: { columns },
        } = this.state;

        return breakLineAtEach(
            uniq([PRIMARY_ENTITY_ID_LOCALIZATION_KEY, stackByKey, ...multipleFilterKeys])
                .filter((key) => {
                    const idx = columns.indexOf(key);
                    const value = record[idx];
                    return key && ![METRIC_ID_KEY, xAxisKey].includes(key) && value != null;
                })
                .map((key) => {
                    const idx = columns.indexOf(key);
                    const value = record[idx];
                    return key === PRIMARY_ENTITY_ID_LOCALIZATION_KEY
                        ? _(value)
                        : localizeFilterValues(key, [value]);
                })
                .join(', '),
            3
        );
    };

    isMultipleFilter = (key: DataGranularityKey): boolean =>
        this.props.filtersValues[key].toString().includes(',');

    getMultipleFilterKeys = (hasMultiValueFilter: boolean): DataGranularityKey[] => {
        const { multipleFilterKeys, xAxisKey } = this.props;

        if (isTimeUnitKey(xAxisKey) || hasMultiValueFilter) {
            return multipleFilterKeys.filter((key) => key !== xAxisKey);
        }

        return [...multipleFilterKeys, xAxisKey];
    };

    shouldLegendBeHidden = (): boolean => {
        const { filtersValues, xAxisKey, selectedConditions } = this.props;
        const hasMultiValueFilter = Object.entries(filtersValues).find(
            ([key, value]) => key !== xAxisKey && (value || []).length > 1
        );

        return !hasMultiValueFilter && selectedConditions.length < 2;
    };

    isPDFExport = (): boolean => {
        const chartWrapperNode = document.querySelector('#chart-wrapper');
        return chartWrapperNode?.style?.width === '1080px';
    };

    formatXAxisLabel = (id) => {
        const { xAxisKey } = this.props;

        const labelName = formatValue(xAxisKey, id, _);

        return labelName;
    };

    renderYAxisName = ({ chartInstanceKeyValues }, idx) => {
        if (chartInstanceKeyValues[idx] == null) {
            return '';
        }
        return getYAxisTitle({ [METRIC_ID_KEY]: [chartInstanceKeyValues[idx]] });
    };

    renderXAxisName = ({ filters }) => _(`chart_axis_label_${this.props.xAxisKey}`);

    render() {
        const {
            xAxisKey,
            stackByKey,
            isLoadingData,
            filtersValues,
            timeUnitKey,
            initialTimeUnitValue,
            onExportData,
            enableExportPNG,
            displayBarLabels,
            displayUncertainties,
            onUncertaintyAvailabilityChange,
            onDataLabelsEnabledChange,
        } = this.props;

        const { dataResponse, xAxisValues } = this.state;

        if (!(dataResponse && dataResponse.columns)) {
            return null;
        }

        const shouldLegendBeHidden = this.shouldLegendBeHidden();
        const legendPosition = 'bottom';

        const isXAxisTimeUnit = isTimeUnitKey(xAxisKey);

        return (
            <>
                <BarChart
                    {...this.props}
                    yAxisKey="value"
                    ref={this.chartRef}
                    chartInstanceByKey={METRIC_ID_KEY}
                    dataKey={PRIMARY_ENTITY_ID_LOCALIZATION_KEY}
                    timeUnitKey={timeUnitKey}
                    showLabels={displayBarLabels || this.isPDFExport()}
                    displayUncertainties={displayUncertainties}
                    isLoadingData={isLoadingData}
                    filtersValues={filtersValues}
                    {...dataResponse}
                    xAxisValues={xAxisValues}
                    withSlider={isXAxisTimeUnit}
                    {...(!isXAxisTimeUnit && {
                        enableTimelineWithFilterKey: timeUnitKey,
                        initialTimelineValue: initialTimeUnitValue,
                    })}
                    theme={merge(echarts, {
                        color: [
                            ...new Set([
                                ...echarts.bar.seriesColorSpectrum,
                                ...echarts.colorPalette,
                            ]),
                        ],
                        bar: {
                            series: {
                                barGap: 0,
                                itemStyle: {
                                    emphasis: {
                                        barBorderWidth: 0,
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowOffsetY: 0,
                                        shadowColor: 'rgba(0,0,0,0.5)',
                                    },
                                },
                            },
                        },
                    })}
                    xAxisKey={xAxisKey}
                    legendPosition={legendPosition}
                    formatYAxisLabel={formatChartLabel}
                    stackByKey={stackByKey}
                    hideLegend={shouldLegendBeHidden}
                    formatXAxisLabel={this.formatXAxisLabel}
                    renderYAxisName={this.renderYAxisName}
                    renderXAxisName={this.renderXAxisName}
                    configureTitles={this.configureTitles}
                    formatLegendLabel={this.formatLegendLabel}
                    renderTooltip={this.renderTooltip}
                    saveAsImage={{
                        visible: true,
                        enabled: enableExportPNG,
                        filename: this.getSaveFilename,
                        icon: pngIcon,
                        onClick: () => onExportData?.(PNG),
                    }}
                    multipleFilterKeys={this.getMultipleFilterKeys(!shouldLegendBeHidden)}
                    {...(onUncertaintyAvailabilityChange && { onUncertaintyAvailabilityChange })}
                    onDataLabelsEnabledChange={onDataLabelsEnabledChange}
                    horizontalLayoutAllowedKeys={HORIZONTAL_LAYOUT_ALLOWED_KEYS}
                />
            </>
        );
    }
}

export default animatableChart(Chart);
