import React from 'react';

import {
    DataCondition,
    DataExportFormat,
    DataFilters,
    DataGranularity,
    DataGranularityKey,
    DataResponse,
    DataType,
} from '@portal/common/types';
import { getFirstSupportedDataResponse } from '@portal/common/utility/chart-data-helpers';

import Chart from './Chart';

type Props = {
    initialTimeUnitValue: number | string;
    selectedConditionsDataTypes: DataType[];
    selectedConditionsDataTypesGranularity: DataGranularity;
    selectedConditionsPrimaryEntityFilters: DataGranularityKey[];

    dataResponses: null | Record<string, DataResponse>;
    isLoadingData: boolean;
    filtersValues: DataFilters;
    selectedConditions: DataCondition[];
    chartFilters: DataFilters;

    timeUnitKey: DataGranularityKey;
    onExportData?: (format: DataExportFormat) => void;
    enableExportPNG: boolean;
    enableExportGIF: boolean;
    numberFormatter: (value: number | string) => string;

    echartsMapKey: string;
};

class Container extends React.Component<Props> {
    // @todo: double check
    shouldComponentUpdate = (nextProps: Readonly<Props>): boolean =>
        !!nextProps.dataResponses && nextProps.isLoadingData !== this.props.isLoadingData;

    render() {
        const { dataResponses, innerRef } = this.props;
        return (
            <Chart
                ref={innerRef}
                {...this.props}
                dataResponse={getFirstSupportedDataResponse(dataResponses)}
            />
        );
    }
}

export default React.forwardRef<Props>(({ ...props }, ref) => (
    <Container innerRef={ref} {...props} />
));
