import React, { FC, useEffect, useState } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { RootState } from 'MyTypes';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash/fp';

import { Loading } from '@portal/common/components';
import { TYPE_LINE_CHART } from '@portal/common/models/chart-type';
import { AllDataTypes } from '@portal/common/models/data-type';
import { DataCondition } from '@portal/common/types';

import { getOrganizationId } from '../../store/root-reducer';
import api from '../../api';
import { DATA_EXPLORER_PATH } from '../../router/paths';
import { getEntityHierarchiesLoaded } from '../../store/entity-hierarchies/selectors';
import { initializeDataTypeSelection } from '../../store/data-explorer/actions';
import { getDataCollections } from '../../store/data-explorer/selectors';

import UnavailableDataError from './UnavailableDataError';

const mapStateToProps = (state: RootState) => ({
    organizationId: getOrganizationId(state),
    dataCollections: getDataCollections(state),
    isEntityHierarchiesLoaded: getEntityHierarchiesLoaded(state),
});

const dispatchProps = {
    initializeDataTypeSelection: initializeDataTypeSelection,
};

type Props = RouteComponentProps & ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const PermalinkScene: FC<Props> = ({
    match,
    organizationId,
    dataCollections,
    isEntityHierarchiesLoaded,
    initializeDataTypeSelection,
}) => {
    const history = useHistory();

    const [isLoading, setLoading] = useState<boolean>(true);
    const [isPermalinkAccessible, setPermalinkAccessible] = useState<boolean>(false);

    useEffect(() => {
        const { hash } = match.params;

        const fetchOrganizationResource = async () => {
            if (hash && isEntityHierarchiesLoaded && !isEmpty(dataCollections)) {
                await api.organization
                    .getOrganizationResource({
                        organizationId,
                        hash,
                    })
                    .then((data) => {
                        const isEmptyResult = isEmpty(data.results);

                        if (isEmptyResult) {
                            history.push({
                                pathname: DATA_EXPLORER_PATH,
                                state: null,
                            });
                        }

                        const {
                            conditions,
                            refinement_filters,
                            data_collection_resource_id,
                            configuration,
                        } = data.results[0];

                        const isDataCollectionResourceAccessible = !!(dataCollections || []).find(
                            ({ id }) => id === data_collection_resource_id
                        );

                        setPermalinkAccessible(isDataCollectionResourceAccessible);

                        if (!isDataCollectionResourceAccessible) {
                            setLoading(false);
                            return;
                        }

                        const {
                            activeRefinements,
                            chartType = TYPE_LINE_CHART,
                            dataType,
                            sortedByColumn,
                            sortedDescending,
                            visibleColumns,
                            dataTool,
                            chartSettings,
                        } = configuration;

                        const preparedConditionsArr: DataCondition[] = [];

                        conditions.forEach((item) => {
                            item.primary_entity_id.forEach((itemId) => {
                                preparedConditionsArr.push({
                                    data_type: item.data_type,
                                    primary_entity_id: itemId,
                                });
                            });
                        });

                        setTimeout(() => {
                            initializeDataTypeSelection({
                                conditions: preparedConditionsArr || [],
                                refinementFilters: refinement_filters || null,
                            });
                        });

                        history.push({
                            pathname: dataTool || DATA_EXPLORER_PATH,
                            state: {
                                dataLoaded: true,
                                dataCollectionId: data_collection_resource_id,
                                dataType: dataType || AllDataTypes,
                                conditions: preparedConditionsArr,
                                configuration: {
                                    sortedByColumn,
                                    sortedDescending,
                                    visibleColumns,
                                    chartSettings,
                                },
                                refinementFilters: refinement_filters,
                                activeRefinements,
                                chartType,
                            },
                        });
                    });
            }
        };
        fetchOrganizationResource();
    }, [match, isEntityHierarchiesLoaded, dataCollections]);

    if (!isPermalinkAccessible && !isLoading) {
        return <UnavailableDataError />;
    }

    return <Loading />;
};

export default compose(connect(mapStateToProps, dispatchProps))(PermalinkScene);
