import { FORECAST_SCENARIO_ID_KEY } from '@portal/common/models/data-key';
import { DataResponse, DataType } from '@portal/common/types';

import { filterDataResponse } from '../../components/DataExplorer/CustomDataTable/utils';

const deleteUselessForecastScenarioData = (responseByDataTypes: Record<DataType, DataResponse>) => {
    Object.entries(responseByDataTypes).forEach(([dataType, dataResponse]) => {
        const { columns } = dataResponse;
        if (columns.includes(FORECAST_SCENARIO_ID_KEY)) {
            dataResponse = filterDataResponse(dataResponse, [
                columns.indexOf(FORECAST_SCENARIO_ID_KEY),
            ]);
            responseByDataTypes[dataType] = dataResponse;
        }
    });
};

export default deleteUselessForecastScenarioData;
