import { createReducer } from 'typesafe-actions';

import { Poll } from '@portal/common/types';

import { getPollsAsync } from './actions';

type State = Poll[] | [];

const initialState: State = [];

export default createReducer(initialState).handleAction(
    [getPollsAsync.success],
    (state, action) => action.payload || []
);
