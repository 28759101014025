import React from 'react';
import { compose } from 'redux';

import { withGoogleAnalytics } from '@ihme/common/packages/google-analytics';
import { withStorage } from '@ihme/common/packages/storage';
import withQueryParams from '@ihme/common/utility/hoc/with-query-params';
import {
    Avatar,
    DataTableWithQueryParams as DataTable,
    EmptyLabel,
    FlexColumn,
    FlexRow,
    H2,
    InputWithDebounce,
    PageBody,
    PageBodyFooter,
    PageContainer,
    PageHeader,
    TableCell,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
    TableText,
    ActionMail,
} from '@portal/common/components';
import { styled } from '@portal/common/theme';
import { getAdminFullName } from '@portal/common/models/admin';

import api from '../../api';
import _ from '../../locale';
import locale from './locale';

const DataTableWithQueryParams = compose(
    withGoogleAnalytics({
        trackMethods: (props) => ({
            handleSearchChange: (search) => {
                if (search && search.length > 1) {
                    props.trackEvent({
                        category: 'Staff',
                        action: 'Search',
                        label: search,
                    });
                }
            },
        }),
    })
)(DataTable);

const StyledPageHeader = styled(PageHeader)({
    justifyContent: 'space-between',
});

class StaffScene extends React.PureComponent {
    onEmail(admin) {
        window.open('mailto:services@healthdata.org', '_blank');
    }

    render() {
        const { queryParams, setQueryParams } = this.props;

        return (
            <PageContainer>
                <StyledPageHeader>
                    <H2>{_(locale.sceneTitle)}</H2>
                    <InputWithDebounce
                        initialValue={queryParams.search}
                        debounceBy={250}
                        onDebounce={(value) => {
                            setQueryParams({
                                ...queryParams,
                                search: value,
                                page: 1,
                            });
                        }}
                        beforeIconSrc="icons/ic-search.svg"
                        type="text"
                        placeholder={_.get('search_placeholder')}
                    />
                </StyledPageHeader>
                <PageBody>
                    <FlexColumn grow={1}>
                        <DataTableWithQueryParams
                            queryParams={queryParams}
                            setQueryParams={setQueryParams}
                            defaultQueryParams={{
                                per_page: '10',
                            }}
                            loadItems={api.getPublicAdmins}
                            renderHeaders={(renderProps) => (
                                <TableHeaderRow>
                                    <TableHeaderCell>{_(locale.lastName)}</TableHeaderCell>
                                    <TableHeaderCell>{_(locale.title)}</TableHeaderCell>
                                    <TableHeaderCell>{_(locale.actions)}</TableHeaderCell>
                                </TableHeaderRow>
                            )}
                            renderItem={(item) => (
                                <TableRow key={item.id}>
                                    <TableCell data-private>
                                        <FlexRow align="center">
                                            <Avatar src={item.avatar_url} />

                                            <TableText type="bolder" style={{ paddingLeft: 20 }}>
                                                {getAdminFullName(item) || <EmptyLabel />}
                                            </TableText>
                                        </FlexRow>
                                    </TableCell>
                                    <TableCell>{item.title || <EmptyLabel />}</TableCell>
                                    <TableCell>
                                        <ActionMail onClick={() => this.onEmail(item)} />
                                    </TableCell>
                                </TableRow>
                            )}
                        />
                    </FlexColumn>
                    <PageBodyFooter>{_.get('footer_note')}</PageBodyFooter>
                </PageBody>
            </PageContainer>
        );
    }
}

export default compose(
    withStorage,
    withQueryParams,
    withGoogleAnalytics({
        trackMethods: (props) => ({
            onEmail: (admin) =>
                props.trackEvent({
                    category: 'Staff',
                    action: 'Email Click',
                    label: getAdminFullName(admin),
                }),
        }),
    })
)(StaffScene);
