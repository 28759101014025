import { createAction, createAsyncAction } from 'typesafe-actions';

import { Poll, PollRespond } from '@portal/common/types';

export const getPollsAsync = createAsyncAction(
    'GET_POLLS_REQUEST',
    'GET_POLLS_SUCCESS',
    'GET_POLLS_FAILURE'
)<null, Poll[], string>();

export const votePoll = createAction('POLL_VOTED')<PollRespond>();
