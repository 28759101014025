import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withGoogleAnalytics } from '@ihme/common/packages/google-analytics';

import ChangePassword from '@portal/common/components/ChangePassword';

import { getSessionAccountId } from '../../../store/root-reducer';
import { changeAccountPassword, signOut } from '../../../store/session/actions';

import locale from './locale';

const propTypes = {
    accountId: PropTypes.number.isRequired,
    changeAccountPassword: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
    accountId: getSessionAccountId(store),
});

class ChangePasswordTab extends React.PureComponent {
    handleChangePassword = (password, newPassword) =>
        this.props.changeAccountPassword(this.props.accountId, password, newPassword);

    render() {
        return (
            <ChangePassword
                changePassword={this.handleChangePassword}
                onNext={this.props.signOut}
                locale={locale}
            />
        );
    }
}

ChangePasswordTab.propTypes = propTypes;

export default compose(
    connect(mapStateToProps, { changeAccountPassword, signOut }),
    withGoogleAnalytics({
        trackMethods: (props) => ({
            handleChangePassword: (account) =>
                props.trackEvent({
                    category: 'Account',
                    action: 'Change Password',
                }),
        }),
    })
)(ChangePasswordTab);
