import {
    getSelectedConditions,
    getSelectedConditionsRefinedGranularity,
    getSelectedRefinementFilters,
} from '../../store/data-explorer/selectors';
import { RootState } from '../../store/root-reducer';
import { DataFilters, DataGranularity } from '@portal/common/types';
import { isTimeUnitKey } from '@portal/common/models/data-key';
import { intersection } from 'lodash/fp';

const getValuesAmount = (granularity, filterKey, filterValue): number =>
    intersection(granularity?.[filterKey] || [], filterValue || []).length;

export const getFiltersSelectionCount = (
    filters: DataFilters,
    granularity: DataGranularity,
    ignoreTimeUnits: boolean = false
) =>
    Object.entries(filters || {})
        .filter(([key, value]) => !(ignoreTimeUnits && isTimeUnitKey(key)))
        .map(([key, value]) => getValuesAmount(granularity, key, value))
        .reduce((total, num) => total * (num || 1), 1);

export const getTimeUnitsCount = (filters: DataFilters, granularity: DataGranularity) =>
    Object.entries(filters || {})
        .filter(([key, value]) => isTimeUnitKey(key))
        .map(([key, value]) => getValuesAmount(granularity, key, value))
        .reduce((total, num) => total * (num || 1), 1);

export const calculateCombinedFiltersAmount = (
    state: RootState,
    ignoreTimeUnits: boolean = false
) => {
    const selectedConditions = getSelectedConditions(state);
    const selectedRefinementFilters = getSelectedRefinementFilters(state);
    const selectedConditionsGranularity = getSelectedConditionsRefinedGranularity(state);

    const currentFiltersSelection = {};
    Object.entries(selectedConditionsGranularity).forEach(([key, value]) => {
        currentFiltersSelection[key] = intersection(
            value || [],
            selectedRefinementFilters?.[key] || []
        );
    });

    return (
        selectedConditions.length *
        getFiltersSelectionCount(
            currentFiltersSelection,
            selectedConditionsGranularity,
            ignoreTimeUnits
        )
    );
};
