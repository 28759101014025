import { createAction } from 'typesafe-actions';

import { TabResourceList } from '@portal/common/types';

export const dismissWelcomeMessage = createAction('WELCOME_MESSAGE_DISMISSED')<string>();
export const addDashboardTab = createAction('DASHBOARD_TAB_ADDED')<TabResourceList>();
export const updateDashboardTab = createAction('DASHBOARD_TAB_UPDATED')<{
    index: number;
    list: TabResourceList;
}>();
export const removeDashboardTab = createAction('DASHBOARD_TAB_REMOVED')<number>();

export const initializeDataCollection = createAction('DATA_COLLECTION_INITIALIZED')<{
    dataTool: string;
    dataCollectionId: number;
}>();

export const changeDataCollection = createAction('DATA_COLLECTION_CHANGED')<{
    dataTool: string;
    dataCollectionId: number;
}>();

export const toggleMinimizeSidebar = createAction('MINIMIZE_SIDEBAR_TOGGLED')<void>();
