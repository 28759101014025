import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash/fp';

import { DataKey } from '@portal/common/types';
import { HorizontalButtonGroup } from '@portal/common/components';

import { getChartDimensionFilter } from '../../store/data-explorer/selectors';
import { changeChartDimension } from '../../store/data-explorer/actions';

type Props = typeof HorizontalButtonGroup & {
    defaultValue?: DataKey;
};

const ChartDimensionSelector = (props: Props) => {
    const { options, defaultValue } = props;

    const dispatch = useDispatch();
    const chartDimension = useSelector(getChartDimensionFilter);

    if (isEmpty(options)) return null;

    const handleSelect = (value: DataKey) => {
        dispatch(changeChartDimension(value));
    };

    useEffect(() => {
        if (!chartDimension && defaultValue) {
            handleSelect(defaultValue);
        }
    }, [chartDimension, defaultValue]);

    return (
        <HorizontalButtonGroup
            value={chartDimension || props.defaultValue}
            {...props}
            onSelect={handleSelect}
        />
    );
};

export default ChartDimensionSelector;
