import { createAction, createAsyncAction } from 'typesafe-actions';

import {
    AgeGroupEntity,
    ChartType,
    DataCondition,
    ConditionDetailNotesRecord,
    CountryLocationSdi,
    DataCollectionResource,
    DataFilters,
    DataGranularityKey,
    DataResponse,
    DataToolConfig,
    DataType,
    Round,
    DataExportFormat,
    PdfReportOption,
    DataTableDisplayState,
    DataGranularity,
    DataKey,
} from '@portal/common/types';

import { LimitModal } from '../../models/data-tool';

/**
 * Action naming rules
 * use initialization prefix to indicate initialization events (should only initialize empty value, for existing value use change)
 * use change prefix to indicate user change events (should only change already existing value, use initialization for empty value)
 * TEMPORARY: use tryChange prefix to indicate user input change events, that will invoke validation that will trigger change action on success
 * use get prefix with Async suffix for actions fetching data from the API
 */

export const downloadFileAsync = createAsyncAction(
    'DOWNLOAD_FILE_REQUEST',
    'DOWNLOAD_FILE_SUCCESS',
    'DOWNLOAD_FILE_FAILURE'
)<
    { id: string; resource_id: string },
    { id: string; resource_id: string },
    { id: string; resource_id: string }
>();

export const downloadDataExportAsync = createAsyncAction(
    'DOWNLOAD_DATA_EXPORT_REQUEST',
    'DOWNLOAD_DATA_EXPORT_SUCCESS',
    'DOWNLOAD_DATA_EXPORT_FAILURE'
)<
    {
        chart_types: ChartType[];
        format: DataExportFormat;
        conditions?: DataCondition[];
        filters?: DataFilters;
        data_types?: DataType[];
    },
    void,
    void
>();

export const downloadPdfExportAsync = createAsyncAction(
    'DOWNLOAD_PDF_EXPORT_REQUEST',
    'DOWNLOAD_PDF_EXPORT_SUCCESS',
    'DOWNLOAD_PDF_EXPORT_FAILURE'
)<{ chart_types: ChartType[]; pdf_report_options: PdfReportOption[] }, void, void>();

export const getAgeGroupsAsync = createAsyncAction(
    'GET_AGE_GROUPS_REQUEST',
    'GET_AGE_GROUPS_SUCCESS',
    'GET_AGE_GROUPS_FAILURE'
)<void, AgeGroupEntity[], string>();

export const getRoundsAsync = createAsyncAction(
    'GET_ROUNDS_REQUEST',
    'GET_ROUNDS_SUCCESS',
    'GET_ROUNDS_FAILURE'
)<void, Round[], string>();

export const getCountryLocationsSdi = createAsyncAction(
    'GET_COUNTRY_LOCATIONS_SDI_REQUEST',
    'GET_COUNTRY_LOCATIONS_SDI_SUCCESS',
    'GET_COUNTRY_LOCATIONS_SDI_FAILURE'
)<void, CountryLocationSdi[], string>();

export const getDataCollectionsAsync = createAsyncAction(
    'GET_DATA_COLLECTIONS_REQUEST',
    'GET_DATA_COLLECTIONS_SUCCESS',
    'GET_DATA_COLLECTIONS_FAILURE'
)<{ organizationId: number }, DataCollectionResource[], string>();

export const queryDataAsync = createAsyncAction(
    'QUERY_DATA_REQUEST',
    'QUERY_DATA_SUCCESS',
    'QUERY_DATA_FAILURE'
)<void, [Record<DataType, DataResponse>, number], string>();
export const queryDataRendered = createAction('QUERY_DATA_RENDERED')<number>();

export const queryChartDataAsync = createAsyncAction(
    'QUERY_CHART_DATA_REQUEST',
    'QUERY_CHART_DATA_SUCCESS',
    'QUERY_CHART_DATA_FAILURE'
)<void, [Record<DataType, DataResponse>, number], string>();

export const getRefinedGranularityAsync = createAsyncAction(
    'GET_REFINED_GRANULARITY_REQUEST',
    'GET_REFINED_GRANULARITY_SUCCESS',
    'GET_REFINED_GRANULARITY_FAILURE'
)<void, Record<DataGranularityKey, number[]>, string>();

export const getConditionDetailNotes = createAsyncAction(
    'GET_CONDITION_DETAIL_NOTES_REQUEST',
    'GET_CONDITION_DETAIL_NOTES_SUCCESS',
    'GET_CONDITION_DETAIL_NOTES_FAILURE'
)<[DataCondition[], { measure_id?: number[] }], ConditionDetailNotesRecord[], string>();

export const resetDataTool = createAction('DATA_TOOL_RESET')<DataToolConfig>();

// all initialization are used from saved data selections
// or empty for new selection
export const initializeSelectedDataType = createAction(
    'SELECTED_DATA_TYPE_INITIALIZED'
)<DataType>();
export const changeSelectedDataType = createAction('SELECTED_DATA_TYPE_CHANGED')<DataType>();

export const initializeSelectedChartType = createAction(
    'SELECTED_CHART_TYPE_INITIALIZED'
)<ChartType>();
export const changeSelectedChartType = createAction('SELECTED_CHART_TYPE_CHANGED')<ChartType>();

export const setCustomChartSettings = createAction('SET_CUSTOM_CHART_SETTINGS')<
    Record<string, any>
>();

export const initializeDataTypeSelection = createAction('DATA_TYPE_SELECTION_INITIALIZED')<{
    conditions: DataCondition[];
    refinementFilters: DataFilters | null;
}>();

export const changeDataTypeSelection = createAction('DATA_TYPE_SELECTION_CHANGED')<{
    conditions: DataCondition[];
    refinementFilters: DataFilters | null;
}>();

export const changeDataTypeSelectionForSingleConditionTool = createAction(
    'DATA_TYPE_SELECTION_FOR_SINGLE_CONDITION_TOOL_CHANGED'
)<{
    conditions: DataCondition[];
    refinementFilters: DataFilters | null;
}>();

export const validateSelectedConditionsComplexityLimit = createAction(
    'SELECTED_CONDITIONS_COMPLEXITY_LIMIT_VALIDATED'
)<DataCondition[]>();
export const changeConditions = createAction('CONDITIONS_CHANGED')<DataCondition[]>();

export const dataSelectionValidation = createAsyncAction(
    'DATA_SELECTION_VALIDATION_REQUESTED',
    'DATA_SELECTION_VALIDATION_SUCCEED',
    'DATA_SELECTION_VALIDATION_FAILED'
)<{ conditions?: DataCondition[]; refinementFilters?: DataFilters | null }, string, string>();

export const validateSelectedRefinementFiltersComplexityLimit = createAction(
    'SELECTED_REFINEMENT_FILTERS_COMPLEXITY_LIMIT_VALIDATED'
)<DataFilters>();
export const changeSelectedRefinementFilters = createAction(
    'SELECTED_REFINEMENT_FILTERS_CHANGED'
)<DataFilters>();

export const initializeDefaultRefinementFilters = createAction(
    'DEFAULT_REFINEMENT_FILTERS_INITIALIZED'
)<DataFilters>();
export const fixMissingLoadedRefinementFilters = createAction(
    'MISSING_LOADED_REFINEMENT_FILTERS_FIXED'
)<DataFilters>();

export const initializeVisibleRefinementFilters = createAction(
    'VISIBLE_REFINEMENT_FILTERS_INITIALIZED'
)<string[]>();
export const validateVisibleRefinementFilters = createAction(
    'VISIBLE_REFINEMENT_FILTERS_VALIDATED'
)<string[]>();

export const validateYearRefinementFilters = createAction(
    'YEAR_REFINEMENT_FILTER_VALIDATED'
)<DataFilters>();

export const addVisibleRefinementFilters = createAction(
    'VISIBLE_REFINEMENT_FILTERS_ADDED'
)<string>();
export const removeVisibleRefinementFilters = createAction(
    'VISIBLE_REFINEMENT_FILTERS_REMOVED'
)<string>();

export const clearConditionDetailNotes = createAction('CONDITION_DETAIL_NOTES_CLEARED')();

export const toggleYearRefinementFilterMode = createAction(
    'YEAR_REFINEMENT_FILTER_TOGGLED'
)<void>();
export const toggleDayRefinementFilterMode = createAction('DAY_REFINEMENT_FILTER_TOGGLED')<void>();

export const openSelectionCountModal = createAction('TRY_MODAL_OPENED')<LimitModal>();
export const confirmSelectionCountModal = createAction('TRY_MODAL_CONFIRMED')<void>();
export const cancelSelectionCountModal = createAction('TRY_MODAL_CANCELLED')<void>();
export const setCombinedFiltersAmount = createAction('SET_COMBINED_FILTERS_AMOUNT')<
    number,
    {
        currentSelectionAmount: number;
        currentSelectionWithoutTimeUnitsAmount: number;
        nextSelectionAmount: number;
        nextSelectionWithoutTimeUnitsAmount: number;
    }
>();
export const initializeChartFilters = createAction('CHART_FILTERS_INITIALIZED')<DataFilters>();
export const changeChartFilters = createAction('CHART_FILTERS_CHANGED')<DataFilters>();
export const validateChartFilters = createAction('CHART_FILTERS_VALIDATED')<DataFilters>();

export const changeChartDimension = createAction('CHANGE_CHART_DIMENSION')<DataKey>();

export const emulateDataIsLoadingToMakeChartsRefresh = createAction(
    'EMULATED_DATA_IS_LOADING_TO_MAKE_CHARTS_REFRESH'
)<void>();

export const calculateChartDataResponsesFromDataQuery = createAction(
    'CALCULATED_CHART_DATA_RESPONSES_FROM_DATA_QUERY'
)<Record<DataType, DataResponse> | null>();

export const setDrillIntoCondition = createAction(
    'SET_DRILL_INTO_CONDITION'
)<DataCondition | null>();

export const setSamplePopulationSize = createAction('SET_SAMPLE_POPULATION_SIZE')<number>();

export const updateDataTableDisplayState = createAction('DATA_TABLE_DISPLAY_STATE_UPDATE')<
    Partial<DataTableDisplayState>
>();

export const initializeDataTableDisplayState = createAction('DATA_TABLE_DISPLAY_STATE_INITIALIZED')<
    Partial<DataTableDisplayState>
>();

export const getSingleYearAgeGranularitiesAsync = createAsyncAction(
    'GET_SINGLE_YEAR_AGE_GRANULARITIES_REQUEST',
    'GET_SINGLE_YEAR_AGE_GRANULARITIES_SUCCESS',
    'GET_SINGLE_YEAR_AGE_GRANULARITIES_FAILURE'
)<number[], null | Record<string, DataGranularity>, string>();
