import { ResourceList } from '@portal/common/types';

import _ from '../../locale';
import { MY_FAVORITES_ID, GROUP_CONTENT_ID } from '../../router/paths';

export const MY_FAVORITES_LIST: ResourceList = {
    id: MY_FAVORITES_ID,
    name: _.get('default_list_name'),
    is_default: false,
    is_public: false,
};

export const GROUP_CONTENT_LIST: ResourceList = {
    id: GROUP_CONTENT_ID,
    name: _.get('default_group_list_name'),
    is_default: true,
    is_public: true,
};
