import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { from } from 'rxjs';

import { withGoogleAnalytics } from '@ihme/common/packages/google-analytics';
import {
    BodyText3,
    H3,
    PageTile,
    PageTileBody,
    PageTileHeader,
    SvgIcon,
} from '@portal/common/components';
import formatRichText from '@portal/common/components/RichTextEditor/formatRichText';
import { WelcomeMessageResource } from '@portal/common/types';
import { styled } from '@portal/common/theme';
import { getMemberFullname } from '@portal/common/models/models-helpers';

import api from '../../api';
import _ from '../../locale';
import {
    getDismissedWelcomeMessageHash,
    getOrganization,
    getSessionAccount,
} from '../../store/root-reducer';
import { dismissWelcomeMessage } from '../../store/user-settings/actions';

const CloseIcon = styled(SvgIcon)({
    position: 'absolute',
    top: -60,
    right: 0,
});

const mapStateToProps = (state: RootState) => ({
    sessionAccount: getSessionAccount(state),
    organization: getOrganization(state),
    dismissedWelcomeMessageHash: getDismissedWelcomeMessageHash(state),
});

type Props = ReturnType<typeof mapStateToProps> & {
    dismissWelcomeMessage: typeof dismissWelcomeMessage;
};

type State = {
    resource: null | WelcomeMessageResource;
};

class Welcome extends React.PureComponent<Props, State> {
    state: State = {
        resource: null,
    };

    handleResourceSuccess = (payload) => {
        this.setState((state) => ({
            resource: payload,
        }));
    };

    handleResourceError = (error) => {
        console.error(error);
    };

    resourceSubscription = from(api.organization.getWelcomeMessage()).subscribe(
        this.handleResourceSuccess,
        this.handleResourceError
    );

    componentWillUnmount() {
        this.resourceSubscription.unsubscribe();
    }

    handleDismiss = (hash) => {
        this.props.dismissWelcomeMessage(hash);
    };

    isMessageDismissed = (hash, prevHash) => {
        return hash === prevHash;
    };

    render() {
        const { dismissedWelcomeMessageHash, organization, sessionAccount } = this.props;
        const { resource } = this.state;

        if (
            sessionAccount == null ||
            organization == null ||
            resource == null ||
            this.isMessageDismissed(resource.hash, dismissedWelcomeMessageHash)
        ) {
            return null;
        }

        const replacements = {
            organizationName: organization.name,
            userName: getMemberFullname(sessionAccount),
        };

        return (
            <PageTile>
                <PageTileBody>
                    <PageTileHeader>
                        <H3>{_.parse(formatRichText(resource.title, replacements))}</H3>
                    </PageTileHeader>
                    <div
                        style={{
                            position: 'relative',
                            textAlign: 'left',
                        }}
                    >
                        <CloseIcon
                            src="/icons/ic-cross.svg"
                            onClick={() => this.handleDismiss(resource.hash)}
                        />
                        <BodyText3>
                            {_.parse(formatRichText(resource.text, replacements))}
                        </BodyText3>
                    </div>
                </PageTileBody>
            </PageTile>
        );
    }
}

export default compose(
    connect(mapStateToProps, { dismissWelcomeMessage }),
    withGoogleAnalytics({
        trackMethods: (props) => ({
            handleDismiss: (e) =>
                props.trackEvent({
                    category: 'Settings',
                    action: 'Dismiss Welcome Message',
                }),
        }),
    })
)(Welcome);
