import { LOCATION_CHANGE } from 'connected-react-router';
import { RootEpic } from 'MyTypes';
import { concat, empty, forkJoin, of } from 'rxjs';
import {
    bufferCount,
    filter,
    ignoreElements,
    map,
    mergeMap,
    switchMap,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';
import { isEmpty } from 'lodash/fp';

import { getDataTypePrimaryEntityKey } from '@portal/common/models/data-type';
import { mergeGranularities } from '@portal/common/utility/merge-granularity';

import { getStandardTrackingParams } from './analytics-selectors';
import {
    downloadDataExportAsync,
    downloadFileAsync,
    downloadPdfExportAsync,
    getAgeGroupsAsync,
    getCountryLocationsSdi,
    getDataCollectionsAsync,
    getRoundsAsync,
    queryDataAsync,
    queryDataRendered,
} from './data-explorer/actions';
import {
    getDataToolConfig,
    getSelectedConditions,
    getSelectedConditionsDataTypes,
    getSelectedConditionsDataTypesDefaultFilters,
    getSelectedRefinementFiltersWithFallbackToDefaultFilters,
    getVisibleRefinementFilters,
    mergeFiltersWithFallbackToDefaultFilters,
} from './data-explorer/selectors';
import {
    getCausesAsync,
    getEtiologiesAsync,
    getImpairmentsAsync,
    getInjuryAsync,
    getLocationsAsync,
    getRisksAsync,
    getSequelasAsync,
    getVehssAsync,
} from './entity-hierarchies/actions';
import {
    getFavoriteResourcesAsync,
    getGroupContentResourcesAsync,
    getPermalinkResourcesAsync,
    getPublicResourceListsAsync,
    getResourceListsAsync,
    removePermalinkResourceAsync,
} from './resource-lists/actions';
import { getAnnouncementsAsync, getSidebarLinksAsync } from './resources/actions';
import { SESSION_ACCOUNT_LOADED, SESSION_SIGNED_IN } from './session/types';
import { getSelectedDataCollectionForDataTool } from './user-settings/selectors';
import { getFiltersWithConditions } from '../utility/data-loader';
import { getOrganizationAsync, organizationContacted } from './organization/actions';
import { getSession, getSessionAccount } from './root-reducer';
import { normalizeQueryParams } from './data-explorer/utils';

// triggers are modelling dependencies between store modules
export const triggerLoadDataCollections: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isOfType([SESSION_ACCOUNT_LOADED, SESSION_SIGNED_IN])),
        map((action) =>
            getDataCollectionsAsync.request({
                organizationId: action.payload.account.organization.id,
            })
        )
    );

export const triggerLoadOrganization: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isOfType([SESSION_ACCOUNT_LOADED, SESSION_SIGNED_IN])),
        map((action) =>
            getOrganizationAsync.request({
                organizationId: action.payload.account.organization.id,
            })
        )
    );

export const triggerBootUpDictionaries: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isOfType([SESSION_ACCOUNT_LOADED, SESSION_SIGNED_IN])),
        switchMap((action) =>
            of(
                getAgeGroupsAsync.request(),
                getCountryLocationsSdi.request(),
                getRoundsAsync.request()
            )
        )
    );

export const triggerLoadEntityHierarchies: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isOfType([SESSION_ACCOUNT_LOADED, SESSION_SIGNED_IN])),
        switchMap((action) =>
            of(
                getLocationsAsync.request(),
                getCausesAsync.request(),
                getSequelasAsync.request(),
                getEtiologiesAsync.request(),
                getImpairmentsAsync.request(),
                getInjuryAsync.request(),
                getRisksAsync.request(),
                getVehssAsync.request()
            )
        )
    );

export const triggerLoadResourceLists: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isOfType([SESSION_ACCOUNT_LOADED, SESSION_SIGNED_IN])),
        switchMap((action) => {
            const memberId = action.payload.account.id;
            const organizationId = action.payload.account.organization.id;

            return of(
                getFavoriteResourcesAsync.request({ organizationId, memberId }),
                getPermalinkResourcesAsync.request({ organizationId, memberId }),
                getGroupContentResourcesAsync.request({ organizationId }),
                getResourceListsAsync.request({ organizationId, memberId }),
                getPublicResourceListsAsync.request({ organizationId })
            );
        })
    );

export const triggerLoadPermalinkLists: RootEpic = (action$, state$, { api }) => {
    return action$.pipe(
        filter(isActionOf([removePermalinkResourceAsync.success])),
        switchMap((action) => {
            const memberId = action.payload.memberId;
            const organizationId = action.payload.organizationId;

            return of(
                getPermalinkResourcesAsync.request({
                    organizationId,
                    memberId,
                })
            );
        })
    );
};

export const triggerLoadResources: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isOfType([SESSION_ACCOUNT_LOADED, SESSION_SIGNED_IN])),
        switchMap((action) => of(getAnnouncementsAsync.request(), getSidebarLinksAsync.request()))
    );

// Analytics
const filterOutStealthAndImpersonatedUsers = (state$) =>
    switchMap((action: any) => {
        const state = state$.value;
        const isStealthUser = getSessionAccount(state)?.is_stealth;
        const isImpersonated = getSession(state)?.isImpersonated;

        if (isStealthUser || isImpersonated) {
            return empty();
        }

        return of(action);
    });

export const trackVisit: RootEpic = (action$, state$, { analyticsService }) =>
    action$.pipe(
        filter(isOfType([SESSION_ACCOUNT_LOADED, SESSION_SIGNED_IN])),
        filterOutStealthAndImpersonatedUsers(state$),
        tap(() => {
            analyticsService.logVisit(getStandardTrackingParams(state$.value));
        }),
        ignoreElements()
    );

export const trackPageView: RootEpic = (action$, state$, { analyticsService }) =>
    concat(of({ type: LOCATION_CHANGE, payload: { location: { pathname: null } } }), action$).pipe(
        filter(isOfType([LOCATION_CHANGE])),
        filterOutStealthAndImpersonatedUsers(state$),
        bufferCount(2, 1),
        tap(([prevAction]) => {
            const state = state$.value;
            const account = getSessionAccount(state);
            if (account) {
                analyticsService.logPageView(getStandardTrackingParams(state), {
                    prev_app_path: prevAction.payload.location.pathname,
                });
            }
        }),
        ignoreElements()
    );

export const trackDataQuery: RootEpic = (action$, state$, { analyticsService }) =>
    action$.pipe(
        filter(isActionOf([queryDataAsync.request])),
        filterOutStealthAndImpersonatedUsers(state$),
        switchMap((_) => {
            const state = state$.value;

            const dataCollection = getSelectedDataCollectionForDataTool(state);
            if (isEmpty(dataCollection)) {
                throw Error('Tracking should not be invoked with no data collection selected');
            }

            const conditions = getSelectedConditions(state);
            const filters = getSelectedRefinementFiltersWithFallbackToDefaultFilters(state);

            const queryParams = normalizeQueryParams(dataCollection, conditions, filters) || [];
            const dataTypes = queryParams.map(({ dataType }) => dataType);
            const granularities = queryParams.map(({ filters }) => filters);

            if (isEmpty(dataTypes)) {
                throw Error('Tracking should not be invoked with no data types selected');
            }

            const startTimestamp = Date.now();

            const queried_data_types = dataTypes;
            const queried_granularity = mergeGranularities(granularities);

            const resource_id = dataCollection.id;
            const visible_filters = getVisibleRefinementFilters(state);

            const successTimestamp$ = action$.pipe(
                filter(isActionOf([queryDataAsync.success])),
                take(1),
                map((action) => {
                    return action.meta;
                })
            );

            const renderedTimestamp$ = action$.pipe(
                filter(isActionOf(queryDataRendered)),
                take(1),
                map((action) => {
                    return action.payload;
                })
            );

            return forkJoin(renderedTimestamp$, successTimestamp$).pipe(
                takeUntil(
                    action$.pipe(
                        filter(isActionOf([queryDataAsync.failure, queryDataAsync.request]))
                    )
                ),
                map(([renderedTimestamp, successTimestamp]) => {
                    const api_request_time = successTimestamp - startTimestamp;
                    const render_time = renderedTimestamp - successTimestamp;

                    return {
                        api_request_time,
                        queried_data_types,
                        queried_granularity,
                        render_time,
                        resource_id,
                        visible_filters,
                    };
                })
            );
        }),
        tap((data) => {
            analyticsService.logDataQuery(getStandardTrackingParams(state$.value), data);
        }),
        ignoreElements()
    );

export const trackDataExports: RootEpic = (action$, state$, { analyticsService }) =>
    action$.pipe(
        filter(isActionOf([downloadDataExportAsync.request])),
        filterOutStealthAndImpersonatedUsers(state$),
        switchMap((action) => {
            const state = state$.value;

            const selectedConditions = action.payload.conditions || getSelectedConditions(state);
            const selectedConditionsDataTypes =
                action.payload.data_types || getSelectedConditionsDataTypes(state);
            if (selectedConditionsDataTypes == null) {
                throw Error('Tracking should not be invoked with no data types selected');
            }

            const selectedDataCollection = getSelectedDataCollectionForDataTool(state);
            const queried_data_types = selectedConditionsDataTypes;
            let filters = {};
            if (action.payload.filters) {
                const defaultFilters = getSelectedConditionsDataTypesDefaultFilters(state);
                const { filtersSkipDefaultSelection } = getDataToolConfig(state);
                filters = mergeFiltersWithFallbackToDefaultFilters(
                    defaultFilters,
                    action.payload.filters,
                    filtersSkipDefaultSelection
                );
            } else {
                filters = getSelectedRefinementFiltersWithFallbackToDefaultFilters(state);
            }

            const filtersKeys = Object.keys(filters);
            const primaryEntityKeys = selectedConditionsDataTypes.map(getDataTypePrimaryEntityKey);

            const queried_granularity = getFiltersWithConditions(selectedConditions, filters, [
                ...filtersKeys,
                ...primaryEntityKeys,
            ]);

            const resource_id = selectedDataCollection.id;
            const visible_filters = getVisibleRefinementFilters(state);

            const chart_types = action.payload.chart_types; // from data explorer scene which knows what it display
            const format = action.payload.format; // from pdf export modal as action param

            return of({
                chart_types,
                format,
                queried_data_types,
                queried_granularity,
                resource_id,
                visible_filters,
            });
        }),
        tap((data) => {
            analyticsService.logDataExport(getStandardTrackingParams(state$.value), data);
        }),
        ignoreElements()
    );

export const trackPdfExports: RootEpic = (action$, state$, { analyticsService }) =>
    action$.pipe(
        filter(isActionOf([downloadPdfExportAsync.request])),
        filterOutStealthAndImpersonatedUsers(state$),
        switchMap((action) => {
            const state = state$.value;

            const selectedConditions = getSelectedConditions(state);
            const selectedConditionsDataTypes = getSelectedConditionsDataTypes(state);
            if (selectedConditionsDataTypes == null) {
                throw Error('Tracking should not be invoked with no data types selected');
            }

            const selectedDataCollection = getSelectedDataCollectionForDataTool(state);
            const queried_data_types = selectedConditionsDataTypes;
            const filters = getSelectedRefinementFiltersWithFallbackToDefaultFilters(state);
            const filtersKeys = Object.keys(filters);
            const primaryEntityKeys = selectedConditionsDataTypes.map(getDataTypePrimaryEntityKey);

            const queried_granularity = getFiltersWithConditions(selectedConditions, filters, [
                ...filtersKeys,
                ...primaryEntityKeys,
            ]);

            const resource_id = selectedDataCollection.id;
            const visible_filters = getVisibleRefinementFilters(state);

            const chart_types = action.payload.chart_types; // from data explorer scene which knows what it display
            const pdf_report_options = action.payload.pdf_report_options; // from pdf export modal as action param

            return of({
                chart_types,
                pdf_report_options,
                queried_data_types,
                queried_granularity,
                resource_id,
                visible_filters,
            });
        }),
        tap((data) => {
            analyticsService.logPdfExport(getStandardTrackingParams(state$.value), data);
        }),
        ignoreElements()
    );

export const trackFileDownload: RootEpic = (action$, state$, { analyticsService }) =>
    action$.pipe(
        filter(isActionOf([downloadFileAsync.request])),
        filterOutStealthAndImpersonatedUsers(state$),
        mergeMap((requestAction) => {
            const startTimestamp = Date.now();

            return action$.pipe(
                takeUntil(
                    action$.pipe(
                        filter(isActionOf(downloadFileAsync.failure)),
                        filter((a) => a.payload.id === requestAction.payload.id)
                    )
                ),
                filter(isActionOf([downloadFileAsync.success])),
                filter((a) => a.payload.id === requestAction.payload.id),
                take(1),
                map((successAction) => {
                    const finishTimestamp = Date.now();
                    const download_time = finishTimestamp - startTimestamp;

                    return {
                        download_time,
                        resource_id: successAction.payload.resource_id,
                    };
                })
            );
        }),
        tap((data) => {
            analyticsService.logFileDownload(getStandardTrackingParams(state$.value), data);
        }),
        ignoreElements()
    );

export const trackTicketCreate: RootEpic = (action$, state$, { analyticsService }) =>
    action$.pipe(
        filter(isActionOf(organizationContacted)),
        filterOutStealthAndImpersonatedUsers(state$),
        switchMap((action) => {
            const reason = action.payload.reason;
            const status = action.payload.status;
            const is_manager_copied = action.payload.is_manager_copied;

            return of({
                reason,
                status,
                is_manager_copied,
            });
        }),
        tap((data) => {
            analyticsService.logTicketCreate(getStandardTrackingParams(state$.value), {
                ...data,
                status: 'open',
            });
        }),
        ignoreElements()
    );
