import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { Omit } from 'utility-types';

import { Dropdown, FieldLabel, FlexRow, FormGroup, Small } from '@portal/common/components';
import _ from '../../locale';
import { DataCondition, DataGranularityKey, DropdownOption } from '@portal/common/types';
import { styled } from '@portal/common/theme';
import {
    getLocalePrefixForGranularityKey,
    noneOfSelectedOptionsIsAllowedByGranularity,
} from '@portal/common/utility/filters-helpers';

import { validateSelectedRefinementFiltersComplexityLimit } from '../../store/data-explorer/actions';
import {
    getSelectedConditions,
    getSelectedConditionsDataTypesGranularity,
    getSelectedConditionsRefinedGranularity,
} from '../../store/data-explorer/selectors';

const FilterLabel = styled(FieldLabel)(({ theme }) => ({
    display: 'inline',
}));

const RevertFilterLabel = styled(Small)(({ theme }) => ({
    textTransform: 'none',
    fontFamily: theme.typography.fontFamily.medium,
    fontWeight: theme.typography.fontWeight.base,
    color: theme.color.gray70,
    fontSize: '1.3rem',
    paddingBottom: 4,
    opacity: 1,
}));

type OwnProps = {
    id?: string;
    label?: string;
    filterKey: DataGranularityKey;
    filterValue?: number[];
    defaultValue?: number[];
    isMulti?: boolean;
    isRevertToDefaultDisabled?: boolean;
    preprocessOptions?: (options: DropdownOption[]) => any;
    onChange?: (value: any) => void;
    granularity?: any;
    refinedGranularity?: any;
    disableStatusCheck?: boolean;
    skipRefinementFiltersUpdate?: boolean;
    options?: DropdownOption[];
    maxValuesAmount?: number | null;
    selectedConditions?: DataCondition[];
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
    granularity: ownProps.granularity || getSelectedConditionsDataTypesGranularity(state),
    refinedGranularity:
        ownProps.refinedGranularity || getSelectedConditionsRefinedGranularity(state),
    selectedConditions: ownProps.selectedConditions || getSelectedConditions(state),
});

const dispatchProps = {
    tryChangeSelectedRefinementFilters: validateSelectedRefinementFiltersComplexityLimit,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    Omit<
        React.ComponentPropsWithoutRef<typeof Dropdown>,
        'options' | 'value' | 'valueMode' | 'onChange'
    > &
    OwnProps;

// TODO: extract refinement filter specific functionality into RefinementFilterDropdown
class ChartDropdown extends React.Component<Props> {
    handleSelect = (ev: { target: { value: null | DropdownOption[] } }, innerState) => {
        this.handleChange(ev.target.value);
    };

    handleRemove = (ev: { target: { value: null | DropdownOption[] } }, innerState) => {
        this.handleChange(ev.target.value);
    };

    handleClear = (ev: { target: { value: null } }) => {
        this.handleChange(ev.target.value);
    };

    handleMultiSelect = (ev: { target: { value: DropdownOption[] } }) => {
        this.handleChange(ev.target.value);
    };

    handleChange = (value: null | DropdownOption[]) => {
        const {
            isClearable,
            filterKey,
            onChange,
            tryChangeSelectedRefinementFilters,
            skipRefinementFiltersUpdate,
            maxValuesAmount,
        } = this.props;

        // if not clearable do not allow empty selection
        if (!isClearable && (Array.isArray(value) ? !value.length : value == null)) {
            return;
        }

        const formattedValue =
            Array.isArray(value) && value.length && maxValuesAmount
                ? value.slice(-maxValuesAmount)
                : value;

        !skipRefinementFiltersUpdate &&
            tryChangeSelectedRefinementFilters({ [filterKey]: formattedValue });

        onChange && onChange(formattedValue);
    };

    revertToDefault = () => {
        const { defaultValue } = this.props;
        this.handleChange(defaultValue);
    };

    getStatus = () => {
        const { filterValue, filterKey, refinedGranularity, disableStatusCheck } = this.props;

        if (disableStatusCheck || !refinedGranularity || !refinedGranularity[filterKey]) {
            return;
        }

        if (filterValue == null) {
            return 'error';
        }

        if (!Array.isArray(filterValue)) {
            console.error(
                'Use ChartDropdown only for RefinementFilters, as a legacy workaround please use disableStatusCheck but best you should refactor it.'
            );
            return;
        }

        if (
            noneOfSelectedOptionsIsAllowedByGranularity(
                { [filterKey]: filterValue },
                refinedGranularity
            )(filterKey)
        ) {
            return 'error';
        }
    };

    render() {
        const {
            id,
            className,
            label,
            filterKey,
            filterValue,
            defaultValue,
            preprocessOptions,
            isMulti,
            labelStyles,
            revertLabelStyles,
            onChange,
            refinedGranularity,
            options,
            isRevertToDefaultDisabled,
            ...props
        } = this.props;

        const granularityOptions = options || props.granularity[filterKey] || [];
        const processedOptions = preprocessOptions
            ? preprocessOptions(granularityOptions)
            : granularityOptions;
        const enabledOptions = refinedGranularity && refinedGranularity[filterKey];

        const dropdownValue = filterValue == null ? [] : filterValue;

        const showRevertToDefault =
            !isRevertToDefaultDisabled &&
            defaultValue &&
            filterValue &&
            defaultValue.toString() !== filterValue.toString();
        const localePrefix = getLocalePrefixForGranularityKey(filterKey);

        return (
            <FormGroup className={className} style={{ marginBottom: 30 }}>
                <FlexRow justify="space-between">
                    {label && (
                        <FilterLabel style={labelStyles} htmlFor={id}>
                            {label}
                        </FilterLabel>
                    )}
                    {showRevertToDefault && (
                        <RevertFilterLabel style={revertLabelStyles}>
                            <a onClick={this.revertToDefault}>Revert</a> to Default
                        </RevertFilterLabel>
                    )}
                </FlexRow>
                <Dropdown
                    id={id}
                    dataTestid={id}
                    multiselectLabel={isMulti ? _.get('multiselect_label') : undefined}
                    valueMode={isMulti ? 'multiArray' : 'singleArray'}
                    options={processedOptions}
                    status={this.getStatus()}
                    value={dropdownValue}
                    optionLabelGetter={(o) => _.withPrefix(localePrefix, o)}
                    optionDisabledGetter={(o) => enabledOptions && !enabledOptions.includes(o)}
                    onSelect={this.handleSelect}
                    onMultiSelect={this.handleMultiSelect}
                    onRemove={this.handleRemove}
                    onClear={this.handleClear}
                    {...props}
                />
            </FormGroup>
        );
    }
}

export default connect(mapStateToProps, dispatchProps, null, {
    pure: false,
})(ChartDropdown);
