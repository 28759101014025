import * as echarts from 'echarts';

import {
    TYPE_ARROW_CHART,
    TYPE_BAR_CHART,
    TYPE_LINE_CHART,
    TYPE_SCATTER_MAP_CHART,
} from '@portal/common/models/chart-type';
import { ChartType } from '@portal/common/types';

const BASE_EXCLUDED_COMPONENTS = ['timeline', 'dataZoom', 'toolbox'];

export default (chartType: ChartType): Promise<{ chartDataUrl: string }> =>
    new Promise((resolve, reject) => {
        const chartWrapperNode = document.querySelector('#chart-wrapper');
        const chartNode = document.querySelector('.echarts-for-react');
        if (!chartNode || !chartWrapperNode) {
            reject();
            return;
        }

        const originalChartWrapperWidth = chartWrapperNode.clientWidth;

        const chartWidth = 1080;
        chartWrapperNode.style.width = `${chartWidth}px`;
        window.dispatchEvent(new Event('resize'));

        let excludeComponents = [...BASE_EXCLUDED_COMPONENTS, 'legend'];

        if ([TYPE_LINE_CHART, TYPE_BAR_CHART, TYPE_ARROW_CHART].includes(chartType)) {
            excludeComponents = [...BASE_EXCLUDED_COMPONENTS, 'graphic'];
        } else if (chartType === TYPE_SCATTER_MAP_CHART) {
            excludeComponents = [...BASE_EXCLUDED_COMPONENTS];
        }

        const chartInstance = echarts.getInstanceByDom(chartNode);
        chartInstance.on('finished', () => {
            chartInstance.off('finished');

            let delay = 250;
            setTimeout(() => {
                const chartDataUrl = chartInstance.getDataURL({
                    type: 'jpeg',
                    backgroundColor: '#fff',
                    pixelRatio: 1,
                    excludeComponents,
                });

                resolve({ chartDataUrl });
            }, delay);

            setTimeout(() => {
                chartWrapperNode.style.width = `${originalChartWrapperWidth}px`;
                chartWrapperNode.style.width = '100%';
                window.dispatchEvent(new Event('resize'));
            }, 2 * delay);
        });
    });
