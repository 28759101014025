import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash/fp';

import { FlexRow } from '@portal/common/components';
import { AllDataTypes } from '@portal/common/models/data-type';
import { DataCondition, DataType } from '@portal/common/types';
import { styled } from '@portal/common/theme';

import {
    changeSelectedDataType,
    resetDataTool,
    validateSelectedConditionsComplexityLimit,
} from '../../store/data-explorer/actions';
import {
    getDataToolConfig,
    getDataToolDataCollections,
    getSelectedConditions,
    getSelectedConditionsDataTypes,
    getSelectedDataType,
} from '../../store/data-explorer/selectors';
import { getSelectedDataCollectionForDataTool } from '../../store/user-settings/selectors';
import { getDataCollectionDataTypes } from '../../utility/data-loader';

import ConditionDropdown, { ConditionDropdownOption } from '../../components/ConditionDropdown';
import ConditionTypeDropdown from '../ConditionTypeDropdown';
import ConditionSmartOptionSelector from '../ConditionSmartOptionSelector';

const ConditionTypeAndOptionsWrapper = styled(FlexRow)({
    flexShrink: 0,
    width: 200,
});

const ConditionDropdownWrapper = styled.div({
    flexGrow: 1,
    position: 'relative',
});

const StyledConditionSmartOptionSelector = styled(ConditionSmartOptionSelector)({
    marginTop: 24,
});

type Props = {
    showSmartOptions?: boolean;
};

const ConditionAndTypeSelector = ({ showSmartOptions }: Props) => {
    const dispatch = useDispatch();
    const dataCollections = useSelector(getDataToolDataCollections);
    const selectedConditions = useSelector(getSelectedConditions);
    const selectedConditionsDataTypes = useSelector(getSelectedConditionsDataTypes);
    const selectedDataCollection = useSelector(getSelectedDataCollectionForDataTool);
    const selectedDataType = useSelector(getSelectedDataType);
    const dataToolConfig = useSelector(getDataToolConfig);

    const handleConditionChange = (conditions: DataCondition[]): void => {
        if (!isEmpty(conditions)) {
            dispatch(validateSelectedConditionsComplexityLimit(conditions));
        } else {
            dispatch(resetDataTool(dataToolConfig));
        }
    };

    const handleConditionRemove = (conditions: ConditionDropdownOption['value'][]): void => {
        if (isEmpty(conditions)) {
            dispatch(resetDataTool(dataToolConfig));
        }
    };

    const handleTypeChange = (type: DataType) => {
        dispatch(changeSelectedDataType(type));
    };

    if (isEmpty(dataCollections) || !selectedDataCollection) {
        return null;
    }

    const filteredDatasets = selectedDataCollection.datasets.filter(
        (i) => selectedDataType === AllDataTypes || selectedDataType === i.data_type
    );

    const availableDataTypes = getDataCollectionDataTypes(selectedDataCollection);
    const { customConditionLabel, customConditionSelectorPlaceholder, isDataTypeSelectorHidden } =
        dataToolConfig;

    return (
        <FlexRow itemsSpacing={30}>
            <ConditionDropdownWrapper>
                <ConditionDropdown
                    key={
                        selectedDataCollection.id +
                        filteredDatasets.map((i) => i.data_type).toString()
                    }
                    datasets={filteredDatasets}
                    onChange={handleConditionChange}
                    onMultiSelect={handleConditionChange}
                    onRemove={handleConditionRemove}
                    value={selectedConditions}
                    showHint={selectedConditionsDataTypes && selectedConditionsDataTypes.length}
                    {...(customConditionLabel && { label: `${customConditionLabel}s` })}
                    {...(customConditionSelectorPlaceholder && {
                        placeholder: customConditionSelectorPlaceholder,
                    })}
                />
            </ConditionDropdownWrapper>
            {!isDataTypeSelectorHidden && availableDataTypes.length > 1 && (
                <ConditionTypeAndOptionsWrapper itemsSpacing={15}>
                    <ConditionTypeDropdown
                        value={selectedDataType}
                        options={availableDataTypes}
                        onChange={handleTypeChange}
                    />
                    {showSmartOptions && (
                        <StyledConditionSmartOptionSelector
                            onSelect={handleConditionChange}
                            color="primary"
                            dataType={selectedDataType}
                        />
                    )}
                </ConditionTypeAndOptionsWrapper>
            )}
        </FlexRow>
    );
};

export default ConditionAndTypeSelector;
