import api from '../api';

export const logVisit = (standardParams) => {
    return api.analytics.visits(standardParams);
};

export const logPageView = (standardParams, customParams: { prev_app_path }) => {
    return api.analytics.pageViews({ ...standardParams, ...customParams });
};

export const logFileDownload = (standardParams, customParams: { resource_id; download_time }) => {
    return api.analytics.fileDownloads({ ...standardParams, ...customParams });
};

export const logDataQuery = (
    standardParams,
    customParams: {
        resource_id;
        visible_filters;
        queried_data_types;
        queried_granularity;
        api_request_time;
        render_time;
    }
) => {
    return api.analytics.dataQueries({ ...standardParams, ...customParams });
};

export const logDataExport = (
    standardParams,
    customParams: {
        resource_id;
        visible_filters;
        queried_data_types;
        queried_granularity;
        chart_types;
        format;
    }
) => {
    return api.analytics.dataExport({ ...standardParams, ...customParams });
};

export const logPdfExport = (
    standardParams,
    customParams: {
        resource_id;
        visible_filters;
        queried_data_types;
        queried_granularity;
        chart_types;
        pdf_report_options;
    }
) => {
    return api.analytics.pdfExport({ ...standardParams, ...customParams });
};

export const logTicketCreate = (
    standardParams,
    customParams: {
        reason;
        status;
        is_manager_copied;
    }
) => {
    return api.analytics.ticketCreate({ ...standardParams, ...customParams });
};
