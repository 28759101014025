import React from 'react';
import { RootState } from 'MyTypes';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTheme } from 'emotion-theming';
import { intersection, isEmpty, merge } from 'lodash/fp';

import { withStorage } from '@ihme/common/packages/storage';
import { Clearfix, Form } from '@ihme/common/web/components';
import { Alert, FlexColumn, Link, HorizontalButtonGroup } from '@portal/common/components';
import { DataGranularity, DataType, RefinementFilterConfig } from '@portal/common/types';
import { TYPE_BAR_CHART } from '@portal/common/models/chart-type';
import {
    AGE_GROUP_ID_KEY,
    COMORBID_CAUSE_ID_KEY,
    DATA_TYPE_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    MONTH_COUNT_KEY,
    PARTICIPANTS_NUMBER_KEY,
} from '@portal/common/models/data-key';
import {
    CauseAssociatedComorbidityStudyCoefficients,
    CauseAssociatedCoprevalenceRecords,
    CauseCoprevalenceRecords,
    ProjectedComorbidityCausesOutcomeRecords,
} from '@portal/common/models/data-type';
import { styled } from '@portal/common/theme';
import { getDataTypePrimaryEntityKey } from '@portal/common/models/data-type';
import { hasErrorInRefinementFilters } from '@portal/common/utility/filters-helpers';
import sortByName from '@portal/common/utility/sorting-helpers';

import _ from '../../locale';
import {
    addVisibleRefinementFilters,
    changeSelectedDataType,
    initializeSelectedChartType,
    resetDataTool,
} from '../../store/data-explorer/actions';
import {
    getDataToolConfig,
    getDataToolDataCollections,
    getSelectedConditionsDataTypesDefaultFilters,
    getSelectedConditions,
    getSelectedConditionsDataTypes,
    getSelectedConditionsDataTypesGranularity,
    getSelectedConditionsRefinedGranularity,
    getSelectedDataType,
    getSelectedRefinementFiltersWithFallbackToDefaultFilters,
    getVisibleRefinementFilters,
    hasCdnRestrictions,
    getDataResponsesByType,
    hasErrorInRefinements,
} from '../../store/data-explorer/selectors';
import { getSelectedDataCollectionForDataTool } from '../../store/user-settings/selectors';
import { getComorbidityCompatibleDataTypes } from './comorbidity-type-group';

import RefinementFilter from '../../components/DataExplorer/RefinementFilter';

export const REFINEMENT_OPTIONS = {
    [CauseCoprevalenceRecords]: [
        COMORBID_CAUSE_ID_KEY,
        // CORRELATION_FACTOR_KEY,
        LOCATION_ID_KEY,
        MEASURE_ID_KEY,
        METRIC_ID_KEY,
        GENDER_ID_KEY,
        DATA_TYPE_KEY,
        AGE_GROUP_ID_KEY,
    ],
    [ProjectedComorbidityCausesOutcomeRecords]: [
        COMORBID_CAUSE_ID_KEY,
        // CORRELATION_FACTOR_KEY,
        LOCATION_ID_KEY,
        GENDER_ID_KEY,
        DATA_TYPE_KEY,
        AGE_GROUP_ID_KEY,
        // MONTH_COUNT_KEY,
        // PARTICIPANTS_NUMBER_KEY,
    ],
    [CauseAssociatedCoprevalenceRecords]: [
        COMORBID_CAUSE_ID_KEY,
        // CORRELATION_FACTOR_KEY,
        LOCATION_ID_KEY,
        MEASURE_ID_KEY,
        METRIC_ID_KEY,
        GENDER_ID_KEY,
        DATA_TYPE_KEY,
        AGE_GROUP_ID_KEY,
    ],
    [CauseAssociatedComorbidityStudyCoefficients]: [
        COMORBID_CAUSE_ID_KEY,
        // CORRELATION_FACTOR_KEY,
        LOCATION_ID_KEY,
        GENDER_ID_KEY,
        DATA_TYPE_KEY,
        AGE_GROUP_ID_KEY,
        MONTH_COUNT_KEY,
        PARTICIPANTS_NUMBER_KEY,
    ],
};
export const EXCEPTION_REFINEMENT_OPTIONS = [
    DATA_TYPE_KEY,
    MONTH_COUNT_KEY,
    PARTICIPANTS_NUMBER_KEY,
    // CORRELATION_FACTOR_KEY,
];

const REFINEMENT_FILTERS_CONFIG: RefinementFilterConfig = {
    [COMORBID_CAUSE_ID_KEY]: {
        isSearchable: true,
        isMulti: false,
        placeholder: 'Select',
        defaultValue: null,
    },
    [LOCATION_ID_KEY]: {
        isSearchable: true,
        isMulti: true,
        // maxValuesAmount: 2,
        placeholder: 'Search',
        // isRevertToDefaultDisabled: true,
        isSelectSubnationalsDisabled: true,
        preprocessOptions: (options) => sortByName(options, (o: { label: string }) => o.label),
    },
    [MEASURE_ID_KEY]: {},
    [METRIC_ID_KEY]: {},
    [GENDER_ID_KEY]: { isMulti: true },
    [AGE_GROUP_ID_KEY]: { isMulti: true },
};

const REFINEMENT_FILTERS_CONFIG_DATA_TYPE_OVERRIDE: Record<DataType, RefinementFilterConfig> = {
    [CauseCoprevalenceRecords]: {},
    [ProjectedComorbidityCausesOutcomeRecords]: {},
    [CauseAssociatedCoprevalenceRecords]: {},
    [CauseAssociatedComorbidityStudyCoefficients]: {
        [AGE_GROUP_ID_KEY]: {
            label: 'Synthetic Population Age Range',
            mode: 'slider',
            undefinedWhenAllSelected: false,
        },
        [MONTH_COUNT_KEY]: {
            mode: 'slider',
            undefinedWhenAllSelected: true,
            isLockedMinValue: true,
        },
    },
};

const mapStateToProps = (state: RootState) => ({
    dataCollections: getDataToolDataCollections(state),
    defaultFilters: getSelectedConditionsDataTypesDefaultFilters(state),
    selectedConditions: getSelectedConditions(state),
    selectedConditionsDataTypes: getSelectedConditionsDataTypes(state),
    selectedDataCollection: getSelectedDataCollectionForDataTool(state),
    selectedRefinementFilters: getSelectedRefinementFiltersWithFallbackToDefaultFilters(state),
    visibleRefinementFilters: getVisibleRefinementFilters(state),
    granularity: getSelectedConditionsDataTypesGranularity(state),
    refinedGranularity: getSelectedConditionsRefinedGranularity(state),
    hasCdnRestrictions: hasCdnRestrictions(state),
    errorInRefinements: hasErrorInRefinements(state),
    dataToolConfig: getDataToolConfig(state),
    selectedDataType: getSelectedDataType(state),
    dataResponsesByType: getDataResponsesByType(state),
});

const dispatchProps = {
    resetDataTool: resetDataTool,
    changeSelectedDataType: changeSelectedDataType,
    initializeSelectedChartType: initializeSelectedChartType,
    addVisibleRefinementFilters: addVisibleRefinementFilters,
};

const LinkToCDN = ({ children, ...props }) => (
    <Link href="#cdn" style={{ color: 'white', textDecoration: 'underline' }} {...props}>
        {children}
    </Link>
);

const StyledAlert = styled(Alert)(() => ({
    marginBottom: 15,
}));

const BASIC_MODE = 'basic';
const ADVANCED_MODE = 'advanced';

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & {
        refinementFiltersConfig?: RefinementFilterConfig;
    };

type State = {
    viewMode: typeof BASIC_MODE | typeof ADVANCED_MODE;
};

class ComorbidityFiltersControls extends React.Component<Props, State> {
    state: State = {
        viewMode: BASIC_MODE,
    };

    static getDerivedStateFromProps = (props: Props, state: State) =>
        props.dataToolConfig &&
        !props.dataToolConfig.enableBasicRefinementFiltersMode &&
        state.viewMode !== ADVANCED_MODE
            ? { viewMode: ADVANCED_MODE }
            : null;

    reset = () => {
        const {
            changeSelectedDataType,
            resetDataTool,
            initializeSelectedChartType,
            dataToolConfig,
            selectedDataType,
        } = this.props;

        const compatibleDataTypes = getComorbidityCompatibleDataTypes(selectedDataType) || [
            dataToolConfig.defaultDataType,
        ];
        const defaultDataType = compatibleDataTypes[0];

        resetDataTool(dataToolConfig);
        changeSelectedDataType(defaultDataType);
        initializeSelectedChartType(TYPE_BAR_CHART);
    };

    // get available refinements based on given granularity and condition
    getAvailableRefinementsForRefinedGranularity = (granularity: DataGranularity) => {
        const { selectedConditions, selectedDataType, selectedRefinementFilters } = this.props;

        const conditionFilters =
            selectedConditions.length === 0
                ? {}
                : selectedConditions.reduce((acc, i) => {
                      const key = getDataTypePrimaryEntityKey(i.data_type);
                      const val = i.primary_entity_id;
                      if (acc[key]) {
                          acc[key] += `,${val}`;
                      } else {
                          acc[key] = val;
                      }
                      return acc;
                  }, {});

        return (
            REFINEMENT_OPTIONS[selectedDataType]
                .filter(
                    (i) => granularity.hasOwnProperty(i) || EXCEPTION_REFINEMENT_OPTIONS.includes(i)
                )
                // remove granularity with 1 value if it's not selected
                .filter(
                    (i) =>
                        (granularity[i] != null && granularity[i].length > 1) ||
                        EXCEPTION_REFINEMENT_OPTIONS.includes(i)
                )
                // filter granularity with values more than 1 or that one value isn't selected
                .filter((i) => {
                    const filterGranularity = granularity[i];
                    const selectedValue = selectedRefinementFilters[i];
                    const isSelectedValuePartOfGranularity =
                        isEmpty(selectedValue) ||
                        !isEmpty(intersection(selectedValue, filterGranularity));
                    return (
                        (filterGranularity != null &&
                            (filterGranularity.length > 1 || !isSelectedValuePartOfGranularity)) ||
                        EXCEPTION_REFINEMENT_OPTIONS.includes(i)
                    );
                })
                .filter((i) => !conditionFilters.hasOwnProperty(i))
        );
    };

    handleAddRefinementClick = (filterKey) => {
        this.props.addVisibleRefinementFilters(filterKey);
    };

    toggleAdvancedMode = () => {
        this.setState((state) => ({ viewMode: ADVANCED_MODE }));
    };

    toggleBasicMode = () => {
        this.setState((state) => ({ viewMode: BASIC_MODE }));
    };

    renderFiltersControls = () => {
        const { viewMode } = this.state;
        const {
            visibleRefinementFilters,
            refinedGranularity,
            granularity,
            selectedRefinementFilters,
            refinementFiltersConfig = REFINEMENT_FILTERS_CONFIG,
            selectedDataType,
            changeSelectedDataType,
            selectedConditions,
            dataToolConfig,
        } = this.props;

        const fallbackGranularity = refinedGranularity || granularity;

        if (isEmpty(fallbackGranularity)) {
            return null;
        }

        const availableRefinementFilters =
            this.getAvailableRefinementsForRefinedGranularity(fallbackGranularity);

        const allFiltersMode = viewMode === ADVANCED_MODE;

        const filters = allFiltersMode ? availableRefinementFilters : visibleRefinementFilters;

        const overriddenRefinementFiltersConfig = merge(
            refinementFiltersConfig,
            REFINEMENT_FILTERS_CONFIG_DATA_TYPE_OVERRIDE[selectedDataType]
        );

        const selectionHasNullComorbidity = hasErrorInRefinementFilters({
            granularity: { [COMORBID_CAUSE_ID_KEY]: fallbackGranularity[COMORBID_CAUSE_ID_KEY] },
            selectedConditions,
            selectedRefinementFilters,
        });

        if (selectionHasNullComorbidity) {
            return (
                <RefinementFilter
                    key={COMORBID_CAUSE_ID_KEY}
                    refinementFiltersConfig={overriddenRefinementFiltersConfig}
                    granularityKey={COMORBID_CAUSE_ID_KEY}
                    availableRefinementFilters={availableRefinementFilters}
                    selectedValue={selectedRefinementFilters[COMORBID_CAUSE_ID_KEY]}
                    {...(allFiltersMode && {
                        hideSelectAll: true,
                        hideDelete: true,
                    })}
                />
            );
        }

        return filters.map((filterKey) => {
            if (filterKey === DATA_TYPE_KEY) {
                return dataToolConfig.isDataTypeSelectorHidden ? null : (
                    <HorizontalButtonGroup
                        key={filterKey}
                        options={getComorbidityCompatibleDataTypes(selectedDataType) || []}
                        label={_('comorbidity_type_dropdown_label')}
                        value={selectedDataType}
                        mapValueToLabel={(value) => _.get(`data_type_${value}`)}
                        onSelect={changeSelectedDataType}
                        buttonStyle={{ minWidth: '25%' }}
                        renderTooltipTitle={(dataType) => _.get(`${dataType}_tooltip`)}
                    />
                );
            }
            /*if (filterKey === PARTICIPANTS_NUMBER_KEY) {
                return <ComorbidityParticipantsNumber key={filterKey} />;
            }*/
            /*if (filterKey === CORRELATION_FACTOR_KEY) {
                return <ComorbidityCorrelationInput key={filterKey} />;
            }*/

            return (
                <RefinementFilter
                    key={filterKey}
                    refinementFiltersConfig={overriddenRefinementFiltersConfig}
                    granularityKey={filterKey}
                    availableRefinementFilters={availableRefinementFilters}
                    selectedValue={selectedRefinementFilters[filterKey]}
                    {...(allFiltersMode && {
                        hideSelectAll: true,
                        hideDelete: true,
                    })}
                />
            );
        });
    };

    render() {
        const {
            dataCollections,
            selectedConditionsDataTypes,
            hasCdnRestrictions,
            errorInRefinements,
        } = this.props;

        if (!dataCollections || selectedConditionsDataTypes == null) {
            return null;
        }
        return (
            <div
                style={{
                    marginTop: 0,
                    background: 'white',
                }}
            >
                {hasCdnRestrictions && (
                    <StyledAlert color="info" position="middle">
                        {_('refinement_filters_model_restrictions_warning')({
                            Link: LinkToCDN,
                        })}
                    </StyledAlert>
                )}
                {errorInRefinements && (
                    <StyledAlert color="warning" position="middle">
                        {_.get('refinement_filters_refine_selection_warning')}
                    </StyledAlert>
                )}
                <Form style={{ marginTop: 0 }}>
                    <FlexColumn>{this.renderFiltersControls()}</FlexColumn>
                    <Clearfix />
                </Form>
            </div>
        );
    }
}

export default compose(
    withStorage,
    withRouter,
    withTheme,
    connect(mapStateToProps, dispatchProps)
)(ComorbidityFiltersControls);
