import React, { FC, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { intersection, isEmpty, pick } from 'lodash/fp';

import { withStorage } from '@ihme/common/packages/storage';

import { AGE_GROUP_ID_KEY, CAUSE_ID_KEY, METRIC_ID_KEY } from '@portal/common/models/data-key';
import { isSingleYearAgeEstimatesExportEnabled as checkIfSingleYearAgeEstimatesExportEnabled } from '@portal/common/models/organization';
import { DataType } from '@portal/common/types';
import { getDataTypePrimaryEntityKey } from '@portal/common/models/data-type';

import config from '../../../config';
import {
    getAgeGroups,
    getMergedSingleYearAgeReportGranularity,
    getSelectedConditionsDataTypes,
    getSelectedConditionsRefinedGranularity,
    getSelectedDataProject,
    getSelectedRefinementFiltersWithFallbackToDefaultFilters,
} from '../../../store/data-explorer/selectors';
import { getOrganization } from '../../../store/root-reducer';

import ExportSingleAgeEstimatesButton from './ExportSingleAgeEstimatesButton';
import ExportSingleAgeEstimatesModal from './ExportSingleAgeEstimatesModal';

type Props = {};

const ExportSingleAgeEstimates: FC<Props> = () => {
    const selectedDataProject = useSelector(getSelectedDataProject);
    const selectedRefinementFilters = useSelector(
        getSelectedRefinementFiltersWithFallbackToDefaultFilters
    );
    const refinedGranularity = useSelector(getSelectedConditionsRefinedGranularity);
    const selectedConditionsDataTypes = useSelector(getSelectedConditionsDataTypes);
    const organization = useSelector(getOrganization);
    const ageGroupEntities = useSelector(getAgeGroups);
    const mergedSingleYearAgeGranularity = useSelector(getMergedSingleYearAgeReportGranularity);

    const [isModalVisible, setModalVisibility] = useState(false);

    const isSingleYearAgeEstimatesExportEnabled =
        checkIfSingleYearAgeEstimatesExportEnabled(organization);

    const isDataProjectSupported = config.singleYearAgeDataProjects.includes(selectedDataProject);
    if (
        !isDataProjectSupported ||
        !isSingleYearAgeEstimatesExportEnabled ||
        !selectedConditionsDataTypes ||
        !selectedRefinementFilters
    ) {
        return null;
    }

    const convertAgeGroupIdIntoAge = useCallback(
        (ageGroupId: number | null) => {
            if (!ageGroupId) return ageGroupId;
            const ageEntity = (ageGroupEntities || []).find((entity) => entity.id === ageGroupId);
            return (ageEntity.max_age + ageEntity.min_age) / 2;
        },
        [ageGroupEntities]
    );

    const mapRangeValuesIntoAges = useCallback(
        ([key, value]) => [key, convertAgeGroupIdIntoAge(value)],
        [convertAgeGroupIdIntoAge]
    );

    const agesRange = useMemo(() => {
        const range = {
            min: null,
            max: null,
        };
        const allAgeBandsIds = config.ageBandsForSingleAgeEstimates.split(',').map(Number);

        for (let i = 0; i < allAgeBandsIds.length; i++) {
            const current = allAgeBandsIds[i];
            const isPresentInGranularity = refinedGranularity[AGE_GROUP_ID_KEY].includes(current);
            if (!isPresentInGranularity && !range.min) continue;
            if (!isPresentInGranularity) break;
            if (!range.min) {
                range.min = current;
                range.max = current;
            } else {
                range.max = current;
            }
        }
        const agesRange = Object.fromEntries(Object.entries(range).map(mapRangeValuesIntoAges));
        return agesRange;
    }, [refinedGranularity, mapRangeValuesIntoAges]);

    if (!agesRange.min || !agesRange.max) {
        return null;
    }

    const unsupportedDataTypes = (selectedConditionsDataTypes || []).filter(
        (type) => !config.singleYearAgeDataTypes.includes(type)
    );
    const isRestrictedDataTypeSelected = !isEmpty(unsupportedDataTypes);

    const filters = {
        ...refinedGranularity,
        ...pick(Object.keys(refinedGranularity), selectedRefinementFilters),
        [CAUSE_ID_KEY]: refinedGranularity[CAUSE_ID_KEY],
    };

    filters[METRIC_ID_KEY] = intersection(filters[METRIC_ID_KEY], config.singleYearAgeMetricIds);
    delete filters[AGE_GROUP_ID_KEY];
    if (isRestrictedDataTypeSelected) {
        unsupportedDataTypes.forEach((type) => {
            const key = getDataTypePrimaryEntityKey(type);
            delete filters[key];
        });
    }

    const couldReportBeGenerated = !Object.entries(filters).find(
        ([key, value]) =>
            isEmpty(intersection(mergedSingleYearAgeGranularity?.[key], value as number[])) &&
            value !== undefined &&
            !(
                Array.isArray(mergedSingleYearAgeGranularity?.[key]) &&
                mergedSingleYearAgeGranularity?.[key].length === 0
            )
    );

    return (
        <>
            <ExportSingleAgeEstimatesButton
                selectedConditionsDataTypes={selectedConditionsDataTypes}
                selectedRefinementFilters={selectedRefinementFilters}
                setModalVisibility={setModalVisibility}
                supportedDataTypes={config.singleYearAgeDataTypes as DataType[]}
                supportedMetrics={config.singleYearAgeMetricIds}
                disabled={!couldReportBeGenerated}
                selectedFilters={filters}
                granularity={mergedSingleYearAgeGranularity}
            />
            {couldReportBeGenerated && (
                <ExportSingleAgeEstimatesModal
                    isVisible={isModalVisible}
                    agesRange={agesRange}
                    setVisibility={setModalVisibility}
                    supportedMetrics={config.singleYearAgeMetricIds}
                    supportedDataTypes={config.singleYearAgeDataTypes}
                    refinedGranularity={refinedGranularity}
                    selectedFilters={filters}
                    selectedConditionsDataTypes={selectedConditionsDataTypes}
                    mergedSingleYearAgeGranularity={mergedSingleYearAgeGranularity}
                />
            )}
        </>
    );
};

export default withStorage(ExportSingleAgeEstimates);
