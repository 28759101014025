import React from 'react';
import { RouteComponentProps } from 'react-router';
import withQueryParams, {
    WithQueryParamsInjectedProps,
} from '@ihme/common/utility/hoc/with-query-params';

import { Organization } from '@portal/common/types';
import { Alert, H2, PageContainer } from '@portal/common/components';

import api from '../../api';

import _ from '../../locale';
import locale from './locale';

import RegistrationForm from './RegistrationForm';

const showOrganizationBranding = (org: Organization) => <h2>{org.name}</h2>;

type Props = RouteComponentProps & WithQueryParamsInjectedProps<{ token }>;

const RegistrationScene: React.FC<Props> = (props) => {
    const {
        history,
        queryParams: { token },
    } = props;

    const [organization, setOrganization] = React.useState(null);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        if (token) {
            api.organization
                .getOrganizations({ self_sign_up_token: token })
                .then((res) => {
                    const organization = res.results[0];
                    if (organization == null) {
                        setError('Something went wrong, please contact our support!');
                    } else {
                        setOrganization(organization);
                    }
                })
                .catch((err) => {
                    setError(err.message);
                });
        }
    }, []);

    return (
        <PageContainer centered>
            {organization != null && showOrganizationBranding(organization)}
            {error && <Alert color="error">{error}</Alert>}
            <H2>{_(locale.title)}</H2>
            <RegistrationForm token={token} history={history} />
        </PageContainer>
    );
};

export default withQueryParams(RegistrationScene);
