import React, { CSSProperties, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from 'emotion-theming';
import { FaInfoCircle } from 'react-icons/fa';

import {
    Dropdown,
    FieldLabel,
    FlexRow,
    FormGroup,
    InternalLink,
    Tooltip,
} from '@portal/common/components';
import { WithThemeProps } from '@portal/common/theme';

import { getSelectedDataTool } from '../../store/root-reducer';
import { getDataToolDataCollections } from '../../store/data-explorer/selectors';
import { getSelectedDataCollectionForDataTool } from '../../store/user-settings/selectors';
import { changeDataCollection } from '../../store/user-settings/actions';
import { DATA_LICENSE_PATH } from '../../router/paths';

import _ from '../../locale';
import { isEmpty } from 'lodash/fp';

const icon = <FaInfoCircle />;

type Props = WithThemeProps & {
    className?: string;
    style?: CSSProperties;
    isDisabled?: boolean;
    hideSingleOption?: boolean;
};

const DataCollectionDropdown = ({ className, style, hideSingleOption = true, ...props }: Props) => {
    const dispatch = useDispatch();
    const dataCollections = useSelector(getDataToolDataCollections);
    const selectedDataCollection = useSelector(getSelectedDataCollectionForDataTool);
    const dataTool = useSelector(getSelectedDataTool);

    const areDataCollectionsEmpty = isEmpty(dataCollections);

    useEffect(() => {
        if (!selectedDataCollection && dataCollections?.length > (hideSingleOption ? 1 : 0)) {
            dispatch(
                changeDataCollection({
                    dataCollectionId: dataCollections[0].id,
                    dataTool,
                })
            );
        }
    }, [selectedDataCollection, dataCollections, hideSingleOption, dispatch, dataTool]);

    const handleOnChange = (ev) => {
        const id = ev.target.value;

        if (id == null) {
            return;
        }

        dispatch(changeDataCollection({ dataCollectionId: id, dataTool }));
    };

    return (
        <FormGroup className={className} style={style}>
            <FlexRow itemsSpacing={10}>
                <FieldLabel>{_.get('data_collection_dropdown_label')}</FieldLabel>
                <Tooltip
                    interactive
                    title={
                        <div>
                            <span>{_.get('data_collections_dropdown_license_info')}</span>{' '}
                            <InternalLink href={`${DATA_LICENSE_PATH}`}>
                                {_.get('learn_more')}
                            </InternalLink>
                        </div>
                    }
                >
                    <div>{icon}</div>
                </Tooltip>
            </FlexRow>
            <FlexRow align="center" wrap="wrap">
                <Dropdown
                    dataTestid="data-collection-dropdown"
                    valueMode="single"
                    value={selectedDataCollection?.id}
                    options={dataCollections}
                    onChange={handleOnChange}
                    disabled={areDataCollectionsEmpty}
                    optionValueGetter={({ id }) => id}
                    optionLabelGetter={({ name }) => name}
                    style={{ flexGrow: 1 }}
                    {...props}
                />
            </FlexRow>
        </FormGroup>
    );
};

export default withTheme(DataCollectionDropdown);
