import React from 'react';

import _ from '@portal/common/locale';
import { styled } from '@portal/common/theme';
import { CenterBlock } from '@ihme/common/web/components';

import SignIn from '@portal/common/components/SignIn';
import { isIE } from '@portal/common/utility/detect-browser';

import locale from './locale';
import { RESEND_SIGN_UP_EMAIL_PATH } from '../../router/paths';

const IENotice = styled(CenterBlock)(({ theme }) => ({
    marginTop: 10,
    textAlign: 'center',
    width: 300,
    [theme.breakpoint.xs]: {
        width: '100%',
    },
}));

const Root = styled.div({
    marginBottom: 50,
});

type Props = {
    signIn: (email: string, password: string) => void;
    onSuccess: (response: object) => void;
    onForgotPassword: () => void;
    onRegisterClick: () => void;
    onSignInWithSSO: () => void;
    errors?: any;
    successMessages?: any;
};

const SignInSceneView: React.FC<Props> = ({
    signIn,
    onSuccess,
    onForgotPassword,
    onRegisterClick,
    onSignInWithSSO,
    errors,
    successMessages,
}) => (
    <Root>
        <SignIn
            signIn={signIn}
            onSuccess={onSuccess}
            onForgotPassword={onForgotPassword}
            onRegisterClick={onRegisterClick}
            onSignInWithSSO={onSignInWithSSO}
            errors={errors}
            successMessages={successMessages}
            resendPath={RESEND_SIGN_UP_EMAIL_PATH}
        />

        {isIE() && <IENotice>{_(locale.ieCompatibilityNotice)}</IENotice>}
    </Root>
);

export default SignInSceneView;
