import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash/fp';

import { DataCollectionResource, DataGranularity, DataValue } from '@portal/common/types';
import { MultipleMyelomaSurvivalResults } from '@portal/common/models/data-type';
import { CAUSE_ID_KEY } from '@portal/common/models/data-key';
import { BodyText2, Loading } from '@portal/common/components';
import { styled } from '@portal/common/theme';

import _ from '../../../locale';
import ChartDropdown from '../../../components/ChartDropdown';
import SurvivalResultDataTable from './SurvivalResultDataTable';

const Container = styled.div(({ theme }) => ({
    padding: '30px 0',
}));

const TitleWrapper = styled.div(({ theme }) => ({
    paddingBottom: 20,
    textAlign: 'center',
}));

type Props = {
    locationId: number;
    dataCollection: DataCollectionResource;
};

const TreatmentsOutcome = ({ dataCollection, locationId }: Props) => {
    const dataType = MultipleMyelomaSurvivalResults;

    const [granularity, setGranularity] = useState<DataGranularity>({});
    const [selectedCauseId, setCauseId] = useState<DataValue | undefined>(undefined);

    useEffect(() => {
        const mmsrGranularity = dataCollection.datasets.find(
            ({ data_type }) => data_type === dataType
        )?.granularity;
        if (mmsrGranularity) {
            setGranularity(mmsrGranularity);
            const causeId = mmsrGranularity?.cause_id?.[0];
            causeId && setCauseId([causeId]);
        }
    }, [JSON.stringify(dataCollection)]);

    if (isEmpty(granularity) || isEmpty(selectedCauseId)) {
        return <Loading />;
    }

    return (
        <Container>
            <TitleWrapper>
                <BodyText2 align={'center'}>
                    {_('simulation_tool_4_1_data_table_title', {
                        location: _(`location_${locationId}`),
                    })}
                </BodyText2>
            </TitleWrapper>
            <ChartDropdown
                label={_('refinement_cause_id')}
                id={CAUSE_ID_KEY}
                filterKey={CAUSE_ID_KEY}
                filterValue={selectedCauseId}
                onChange={setCauseId}
                granularity={granularity}
            />
            <SurvivalResultDataTable
                locationId={locationId}
                dataCollection={dataCollection}
                causeId={selectedCauseId[0]}
                granularity={granularity}
            />
        </Container>
    );
};

export default TreatmentsOutcome;
