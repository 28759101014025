export default {
    header: 'Data Sources',
    tableCol1: 'Reference Material',
    tableCol2: 'Description',
    tableContent: [
        {
            img: '/img/sources/sources_1.png',
            title: 'GBD Data Input Sources Tool',
            desc: 'Explore GBD 2019 input sources and retrieve relevant metadata.',
            url: 'http://ghdx.healthdata.org/gbd-2019/data-input-sources',
        },
        {
            img: '/img/sources/sources_2.png',
            title: 'EpiViz',
            desc: 'Explore data inputs and epidemiological estimates from the non-fatal health outcomes component of the GBD.',
            url: 'https://vizhub.healthdata.org/epi/',
        },
        {
            img: '/img/sources/cod-viz.png',
            title: 'CodViz',
            desc: 'Explore data inputs and epidemiological estimates from the fatal health outcomes component of GBD 2017.',
            url: 'https://vizhub.healthdata.org/cod/',
        },
        {
            img: '/img/sources/gbd-results-2017.png',
            title: '[Archived] GBD 2017 Results Tool',
            desc: 'Query and download archived GBD 2017 data in a CSV file.',
            url: 'https://client2017.healthdata.org/gbd-search/',
        },
        {
            img: '/img/sources/gbd-compare-2017.png',
            title: '[Archived] GBD 2017 Compare Tool',
            desc: 'Analyze the world’s health levels and trends, and refer to the archived data from the GBD 2017 study.',
            url: 'https://client2017.healthdata.org/gbd-compare/',
        },
    ],
};
