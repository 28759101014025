export const HOME_PATH = '/';
export const SIGN_IN_PATH = '/sign-in';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const REGISTRATION_PATH = '/registration';
export const EMAIL_CONFIRMATION_PATH = '/email-confirmation';
export const RESEND_SIGN_UP_EMAIL_PATH = '/resend-sign-up-email';
export const AUTH_CALLBACK_PATH = '/auth/callback';

export const FAVORITE_CONTENT_PATH = '/favorite-content';
//--
export const MY_FAVORITES_ID = 'my-favorites';
export const MY_PERMALINK = 'permalink';
export const MY_LISTS_PATH = '/my-lists';
export const MY_FAVORITIES_PATH = FAVORITE_CONTENT_PATH + MY_LISTS_PATH + '/' + MY_FAVORITES_ID;
export const MY_PERMALINK_PATH = FAVORITE_CONTENT_PATH + MY_LISTS_PATH + '/' + MY_PERMALINK;
//--
export const GROUP_CONTENT_ID = 'group-content';
export const PUBLIC_LISTS_PATH = '/public-lists';
export const GROUP_CONTENT_PATH =
    FAVORITE_CONTENT_PATH + PUBLIC_LISTS_PATH + '/' + GROUP_CONTENT_ID;

export const PROFILE_PATH = '/profile/';
//--
export const PROFILE_GENERAL_ID = 'general';
export const PROFILE_CHANGE_PASSWORD_ID = 'change-password';
export const PROFILE_API_ACCESS_ID = 'api-access';

export const DATA_EXPLORER_PATH = '/data-explorer';
export const COVID_19_EXPLORER_PATH = '/covid-19-explorer';
export const MORBIDITY_EXPLORER_PATH = '/multimorbidity-explorer';
export const PERMALINK_PATH = '/permalink';
export const EDP_EXPLORER_PATH = '/edp-explorer';
export const SIMULATION_EXPLORER_PATH = '/simulation-explorer';
export const HEALTH_THREATS_METRICS_PATH = '/health-threats-explorer';

export const SOURCES_AND_METHODOLOGY = '/sources-and-methodology';
export const DEFINITIONS_PATH = SOURCES_AND_METHODOLOGY + '/definitions';
export const SOURCES_PATH = SOURCES_AND_METHODOLOGY + '/sources';
export const METHODOLOGY_PATH = SOURCES_AND_METHODOLOGY + '/methodology';

export const IHME_STAFF_PATH = '/ihme-staff';
export const CONTACT_US_PATH = '/contact-us';
export const DATA_LICENSE_PATH = '/data-license';
export const TEAM_PATH = '/team';

export const API_DOCUMENTATION_PATH = 'https://api-docs.ihme.services';
