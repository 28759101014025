import { mergeAll } from 'lodash/fp';

import {
    AGE_GROUP_ID_KEY,
    CAUSE_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    REI_ID_KEY,
    ROUND_ID_KEY,
} from '@portal/common/models/data-key';
import { DataProjectId } from '@portal/common/models/data-project';
import {
    AntimicrobialResistanceRecords,
    CauseOutcome,
    CovariateRecords,
    Covid19Records,
    EtiologyRecords,
    HealthThreatsMetricsRecords,
    ImpairmentRecords,
    InjuryRecords,
    LifeExpectancyRecords,
    MultipleMyelomaPopulation,
    PopulationRecords,
    RiskAttribution,
    RiskExposureRecords,
    RiskPrevalence,
    SequelaOutcome,
    SevRecords,
} from '@portal/common/models/data-type';
import { DatasetConfiguration, DataType } from '@portal/common/types';

import config from '../config';

export const DATA_TYPE_ORDER: DataType[] = [
    CauseOutcome,
    SequelaOutcome,
    RiskAttribution,
    PopulationRecords,
    RiskPrevalence,
    EtiologyRecords,
    ImpairmentRecords,
    InjuryRecords,
    SevRecords,
    RiskExposureRecords,
    CovariateRecords,
    LifeExpectancyRecords,
    Covid19Records,
    AntimicrobialResistanceRecords,
    MultipleMyelomaPopulation,
    HealthThreatsMetricsRecords,
];

const defaultDatasetsConfigurationByDataProject = {
    [DataProjectId.GBD]: {
        reference_forecast_scenario_id: config.referenceForecastScenarioId,
        defaultFilters: {
            [AGE_GROUP_ID_KEY]: [config.allAgesGroupId],
            [GENDER_ID_KEY]: [config.bothGendersId],
            [MEASURE_ID_KEY]: [config.deathMeasureId, 29],
            [METRIC_ID_KEY]: [config.rateMetricId],
            [CAUSE_ID_KEY]: [config.allCausesCauseId],
            [LOCATION_ID_KEY]: [config.globalLocationId],
            [ROUND_ID_KEY]: [config.gbd2022RoundId],
            [FORECAST_SCENARIO_ID_KEY]: [config.referenceForecastScenarioId],
            [REI_ID_KEY]: [169],
        },
    },
    [DataProjectId.COVID19]: {
        observed_data_forecast_scenario_id: config.observedDataForecastScenarioId,
        autoSelectedChartFilters: {
            [FORECAST_SCENARIO_ID_KEY]: [config.observedDataForecastScenarioId],
        },
        defaultFilters: {
            forecast_scenario_id: [65],
            location_id: [1],
            measure_id: [4105, 4109, 4110],
            metric_id: [1],
        },
    },
    [DataProjectId.COMORBIDITY_STUDY]: {
        permanentChartFilters: {
            [METRIC_ID_KEY]: [config.numberMetricId],
        },
        defaultFilters: {
            age_group_id: [
                5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 30, 31, 32, 235,
            ],
            forecast_scenario_id: [1],
            gender_id: [3],
            location_id: [102],
            metric_id: [65, 1],
            measure_id: [4115],
        },
    },
    [DataProjectId.EDP]: {
        defaultFilters: {},
    },
    [DataProjectId.AMR]: {
        defaultFilters: {},
    },
    [DataProjectId.GBD2020]: {
        defaultFilters: {},
    },
    [DataProjectId.SIMULATION]: {
        defaultFilters: {},
    },
    [DataProjectId.GBD2020_CVD]: {
        defaultFilters: {},
    },
    [DataProjectId.HEALTH_THREAT_METRICS]: {
        defaultFilters: {},
    },
};

export const getDefaultDatasetsConfigurationByDataProject = (
    dataProjectId: number
): Partial<DatasetConfiguration> =>
    defaultDatasetsConfigurationByDataProject[dataProjectId] ||
    defaultDatasetsConfigurationByDataProject[DataProjectId.GBD];

export const getSelectedDatasetsConfiguration = (
    dataTypes,
    dataCollection
): Partial<DatasetConfiguration> => {
    if (!(dataCollection && dataTypes && dataTypes.length)) {
        return getDefaultDatasetsConfigurationByDataProject(DataProjectId.GBD);
    }

    const defaultConfiguration = getDefaultDatasetsConfigurationByDataProject(
        dataCollection.data_project_id
    );

    const selectedDatasets = dataCollection.datasets.filter((dataset) =>
        dataTypes.includes(dataset.data_type)
    );

    // @todo: action is required: looks like mergeAll does not work as expected - merge all properties values
    let mergedDatasetsConfig = mergeAll(selectedDatasets);
    mergedDatasetsConfig = Object.assign(
        {
            ...defaultConfiguration,
            defaultFilters: {
                ...defaultConfiguration.defaultFilters,
                ...mergedDatasetsConfig?.default_granularity_filters_values,
            },
        },
        mergedDatasetsConfig
    );

    return mergedDatasetsConfig;
};
