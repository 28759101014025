// init locales before component imports
import 'react-hot-loader';
import './init-locales';

import React from 'react';
import ReactDOM from 'react-dom';

import { configureScope } from '@sentry/react';

import { GoogleAnalyticsProvider } from '@ihme/common/packages/google-analytics';
import { localStorageAdapter, StorageProvider } from '@ihme/common/packages/storage';
import { MediaProvider, ThemeProvider } from '@portal/common/theme';
import initSentry from '@portal/common/sentry/init-sentry';

import Root from './components/Root';
import config from './config';
import defaultTheme from './theme/theme-config';

initSentry(config.sentryEnv, config.sentryDsn);

import LogRocket from 'logrocket';
// LogRocket enabled only when dsn env config is available
if (config.logrocketDsn !== null) {
    LogRocket.init(config.logrocketDsn, {
        release: process.env.REACT_APP_VERSION,
    });

    LogRocket.getSessionURL((sessionURL) => {
        configureScope((scope) => {
            scope.setExtra('sessionURL', sessionURL);
        });
    });
}

ReactDOM.render(
    <ThemeProvider theme={defaultTheme}>
        <MediaProvider>
            <StorageProvider
                version={config.storageCacheVersion}
                storageAdapter={localStorageAdapter}
            >
                <GoogleAnalyticsProvider
                    googleAnalyticsId={config.googleAnalyticsId}
                    debug={config.googleAnalyticsDebug}
                >
                    <Root />
                </GoogleAnalyticsProvider>
            </StorageProvider>
        </MediaProvider>
    </ThemeProvider>,
    document.getElementById('root')
);
