import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { getSidebarLinks } from '../../store/resources/selectors';
import { getOrganization, getSessionAccount } from '../../store/root-reducer';
import { getDataCollections } from '../../store/data-explorer/selectors';
import { toggleMinimizeSidebar } from '../../store/user-settings/actions';
import { getIsSidebarMinimized } from '../../store/user-settings/selectors';
import View from './View';

const mapStateToProps = (state: RootState) => ({
    sessionAccount: getSessionAccount(state),
    organization: getOrganization(state),
    isSidebarMinimized: getIsSidebarMinimized(state),
    sidebarLinks: getSidebarLinks(state),
    dataCollections: getDataCollections(state),
});

const dispatchProps = {
    toggleMinimizeSidebar: toggleMinimizeSidebar,
};

class Navigation extends React.PureComponent {
    render() {
        return <View {...this.props} />;
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, dispatchProps, null, { pure: false })
)(Navigation);
