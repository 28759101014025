import { EChartsInstance } from 'echarts-for-react';

export const getTitlesHeight = (chartInstance: EChartsInstance): number => {
    if (!chartInstance) {
        return 0;
    }

    const views = chartInstance._componentsViews;
    if (!views?.length) {
        return 0;
    }

    const titleViews = views.filter((view) => view.__id.endsWith('_title'));
    if (!titleViews?.length) {
        return 0;
    }

    const titles = titleViews[0].group._children;
    if (!titles?.length) {
        return 0;
    }

    return titles[titles.length - 1].shape.height;
};

export const getLegendHeight = (chartInstance: EChartsInstance, legendY: number = -1): number => {
    if (!chartInstance) {
        return 0;
    }

    const views = chartInstance._componentsViews;
    if (!views?.length) {
        return 0;
    }

    const legendViews = views.filter((view) => view.__id.includes('legend.'));
    if (
        !(
            legendViews &&
            legendViews.length &&
            legendViews[0] &&
            legendViews[0]._backgroundEl &&
            legendViews[0]._backgroundEl.shape
        )
    ) {
        return 0;
    }

    if (legendY === -1) {
        return legendViews[0]._backgroundEl.shape.height;
    }

    // line chart case - grouped multiple legends
    const legendBottoms = legendViews.map((view) => {
        const {
            group: { y },
            _backgroundEl: {
                shape: { height },
            },
        } = view;
        return y + height;
    });

    const legendBottom = Math.max(...legendBottoms);

    return legendBottom - legendY;
};
