import React from 'react';

import { styled } from '@portal/common/theme';
import { Button as BaseButton } from '@portal/common/components';

const Button = styled(BaseButton)<Props>(({ theme, selected, disabled }) => ({
    position: 'relative',
    borderRadius: 0,

    borderTopLeftRadius: selected ? theme.borderRadius.base : 0,
    borderTopRightRadius: selected ? theme.borderRadius.base : 0,
    cursor: selected || disabled ? 'default' : 'pointer',
    pointerEvents: selected || disabled ? 'none' : 'auto',
    background: selected ? theme.color.green1 : theme.color.surface2,
    ':hover': {
        borderTopLeftRadius: theme.borderRadius.base,
        borderTopRightRadius: theme.borderRadius.base,
        background: theme.color.primary1,
        boxShadow: theme.boxShadow.primary1,
        color: theme.color.primaryText,
        svg: {
            color: theme.color.primaryText,
            path: {
                fill: theme.color.primaryText,
            },
        },
    },
    svg: {
        path: {
            fill: selected ? theme.color.primaryText : theme.color.jetBlack,
        },
    },
}));

type Props = {
    value: any;
    label?: string;
    icon?: string;
    handleClick: (value: any) => void;
    selected: boolean;
    disabled: boolean;
};

const ChartTypeButton: React.FC<Props> = ({
    value,
    label,
    icon,
    handleClick,
    selected,
    disabled,
}) => (
    <Button
        onClick={() => handleClick(value)}
        color={selected ? 'primary' : 'secondary'}
        disabled={disabled}
        selected={selected}
        {...(icon && { beforeIconSrc: icon })}
    >
        {label ? label : value.toString()}
    </Button>
);

export default ChartTypeButton;
