import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';

import withQueryParams, {
    WithQueryParamsInjectedProps,
} from '@ihme/common/utility/hoc/with-query-params';

import {
    Button,
    ConfirmationModal,
    FlexColumn,
    FlexRow,
    H2,
    InputWithDebounce,
    ListItem,
    P,
    PageBody,
    PageBodyFooter,
    PageContainer,
    PageHeader,
    Popover,
    SvgIcon,
} from '@portal/common/components';
import {
    withGoogleAnalytics,
    WithGoogleAnalyticsInjectedProps,
} from '@portal/common/google-analytics';
import { DataTableQueryParams, ResourceList } from '@portal/common/types';
import { styled } from '@portal/common/theme';

import api from '../../api';
import {
    deleteOrganizationResourceListAsync,
    getPublicResourceListsAsync,
    getResourceListsAsync,
    makeResourceListPrivateAsync,
    makeResourceListPublicAsync,
    makeResourceListDefaultAsync,
    makeResourceListNotDefaultAsync,
    subscribeToListAsync,
    unsubscribeFromListAsync,
} from '../../store/resource-lists/actions';
import { getOrganizationId, getSessionAccountId, isManager } from '../../store/root-reducer';
import {
    getCustomLists,
    getDefaultList,
    getPublicLists,
} from '../../store/resource-lists/selectors';

import { GROUP_CONTENT_ID, MY_FAVORITES_ID, MY_PERMALINK } from '../../router/paths';

import _ from '../../locale';

import EditResourceListModal from '../../components/EditResourceListModal';
import FavoriteResourceList from '../../components/FavoriteResourceList';

const StyledPageHeader = styled(PageHeader)({
    justifyContent: 'space-between',
});

const ListItemWithSeparator = styled(ListItem)(({ theme }) => ({
    borderTop: theme.border.tableRowSeparator,
}));

const DEFAULT_QUERY_PARAMS: DataTableQueryParams = {
    page: '1',
    per_page: '10',
    order_by: 'created',
    order_direction: 'DESC',
};

const mapStateToProps = (state: RootState) => ({
    isManager: isManager(state),
    organizationId: getOrganizationId(state),
    memberId: getSessionAccountId(state),
    memberLists: getCustomLists(state),
    publicLists: getPublicLists(state),
    defaultList: getDefaultList(state),
});

const dispatchProps = {
    getMemberLists: getResourceListsAsync.request,
    getPublicLists: getPublicResourceListsAsync.request,
    makeListPublic: makeResourceListPublicAsync.request,
    makeListPrivate: makeResourceListPrivateAsync.request,
    makeListDefault: makeResourceListDefaultAsync.request,
    makeListNotDefault: makeResourceListNotDefaultAsync.request,
    subscribeToList: subscribeToListAsync.request,
    unsubscribeFromList: unsubscribeFromListAsync.request,
    deleteOrganizationResourceList: deleteOrganizationResourceListAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    WithQueryParamsInjectedProps<DataTableQueryParams> &
    WithGoogleAnalyticsInjectedProps &
    RouteComponentProps<{ listId: string }>;

type State = {
    isVisibleEditListModal: boolean;
    isVisibleRemoveListModal: boolean;
};

class MyListScene extends React.Component<Props, State> {
    state: State = {
        isVisibleEditListModal: false,
        isVisibleRemoveListModal: false,
    };

    getParams = (params?: any) => ({
        organizationId: this.props.organizationId,
        memberId: this.props.memberId,
        ...DEFAULT_QUERY_PARAMS,
        ...this.props.queryParams,
        ...params,
    });

    getListId = (): number => parseInt(this.props.match.params.listId);

    getList = (): ResourceList | undefined => {
        const { memberLists, publicLists } = this.props;
        return (memberLists || []).concat(publicLists || []).find((i) => i.id === this.getListId());
    };

    isPublic = () => {
        const listId = this.getListId();
        return (this.props.publicLists || []).find((list) => list.id === listId);
    };

    isDefault = () => {
        const { defaultList } = this.props;
        const listId = this.getListId();
        return defaultList && defaultList.id === listId;
    };

    isInMyLists = () => {
        const listId = this.getListId();
        return !!(this.props.memberLists || []).find((list) => list.id === listId);
    };

    isCreatedByMe = () => {
        const list = this.getList();
        return list && list.creator && list.creator.id === this.props.memberId;
    };

    handleRemoveList = () => {
        const { organizationId, deleteOrganizationResourceList } = this.props;
        const listId = this.getListId();

        deleteOrganizationResourceList({
            organizationId,
            listId,
        });
    };

    togglePublicity = () => {
        const { organizationId, makeListPublic, makeListPrivate } = this.props;
        const listId = this.getListId();

        if (this.isPublic()) {
            makeListPrivate({ organizationId, listId });
        } else {
            makeListPublic({ organizationId, listId });
        }
    };

    toggleDefault = () => {
        const { organizationId, makeListDefault, makeListNotDefault } = this.props;

        if (this.isDefault()) {
            makeListNotDefault({ organizationId, listId: this.getListId() });
        } else {
            makeListDefault({ organizationId, listId: this.getListId() });
        }
    };

    toggleSubscription = () => {
        const { organizationId, memberId, subscribeToList, unsubscribeFromList } = this.props;
        const listId = this.getListId();

        if (this.isInMyLists()) {
            unsubscribeFromList({ organizationId, memberId, listId });
        } else {
            subscribeToList({ organizationId, memberId, listId, prevList: this.getList() });
        }
    };

    showEditListModal = () => {
        this.setState({ isVisibleEditListModal: true });
    };

    hideEditListModal = () => {
        this.setState({ isVisibleEditListModal: false });
    };

    showRemoveListModal = () => {
        this.setState({ isVisibleRemoveListModal: true });
    };

    hideRemoveListModal = () => {
        this.setState({ isVisibleRemoveListModal: false });
    };

    renderSubscribeButton = () => {
        if (!this.getList() || this.isCreatedByMe()) {
            // no need to subscribe/unsubscribe own lists
            return null;
        }

        return (
            <Button onClick={this.toggleSubscription}>
                {_(this.isInMyLists() ? 'lists_unsubscribe_button' : 'lists_subscribe_button')}
            </Button>
        );
    };

    renderSettingsButton = () => {
        const { isManager } = this.props;

        const list = this.getList();

        if (!list || !(this.isCreatedByMe() || isManager)) {
            return null;
        }

        const areBaseSettingsAvailable = this.isCreatedByMe() || isManager;

        return (
            <Popover
                renderContent={() => (
                    <>
                        {areBaseSettingsAvailable && (
                            <>
                                <ListItem onClick={this.showEditListModal}>
                                    {_.get('lists_edit_modal_title')}
                                </ListItem>

                                <ListItem onClick={this.showRemoveListModal}>
                                    {_.get('lists_remove_modal_title')}
                                </ListItem>
                            </>
                        )}
                        {isManager && (
                            <ListItemWithSeparator onClick={this.togglePublicity}>
                                {_.get(
                                    this.isPublic()
                                        ? 'lists_make_list_private'
                                        : 'lists_make_list_public'
                                )}
                            </ListItemWithSeparator>
                        )}
                        {isManager && this.isPublic() && (
                            <ListItem onClick={this.toggleDefault}>
                                {_.get(
                                    this.isDefault()
                                        ? 'lists_make_list_non_default'
                                        : 'lists_make_list_default'
                                )}
                            </ListItem>
                        )}
                    </>
                )}
            >
                {({ ref, toggleVisibility }) => (
                    <div ref={ref}>
                        <SvgIcon
                            size="large"
                            height={20}
                            src="/icons/ic-settings.svg"
                            tooltip={_('tooltip_settings')}
                            color={(theme) => theme.color.green1}
                            onClick={toggleVisibility}
                        />
                    </div>
                )}
            </Popover>
        );
    };

    render() {
        const { queryParams, setQueryParams, match, memberLists } = this.props;
        const listId = match.params.listId;

        if (listId == null || memberLists == null) {
            return null;
        }

        const list = this.getList();

        let title = '';
        let desc = '';
        let loadItems: any = null;

        if (listId === MY_FAVORITES_ID) {
            title = _.get('default_list_name');
            loadItems = () =>
                api.organizationMemberFavoriteResource.getFavoriteResources(this.getParams());
        } else if (listId === GROUP_CONTENT_ID) {
            title = _.get('default_group_list_name');
            loadItems = () => api.organizationGroupContent.getLinksAndFiles(this.getParams());
        } else if (listId === MY_PERMALINK) {
            title = _.get('share_permalink_nav_link_label');
            loadItems = () => api.permalink.getPermalinks(this.getParams());
        } else if (!list) {
            // all lists are not loaded yet and selected list is not open (public or own)
            return null;
        } else {
            title = list!.name;
            desc = list!.description;
            loadItems = () =>
                api.organizationResourceListResource.getResourceListResources(
                    this.getParams({ listId: listId })
                );
        }

        return (
            <PageContainer>
                <StyledPageHeader>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <H2>{title}</H2>
                        <P style={{ position: 'absolute', top: 30 }}>{desc}</P>
                    </div>
                    <FlexRow align="center" itemsSpacing={25}>
                        <InputWithDebounce
                            initialValue={queryParams.search}
                            debounceBy={250}
                            onDebounce={(value) => {
                                setQueryParams({
                                    ...queryParams,
                                    search: value,
                                    page: '1',
                                });
                            }}
                            style={{ minWidth: 180 }}
                            beforeIconSrc="/icons/ic-search.svg"
                            placeholder={_.get('search_placeholder')}
                        />
                        {this.renderSubscribeButton()}
                        {this.renderSettingsButton()}
                    </FlexRow>
                </StyledPageHeader>

                <PageBody>
                    <FlexColumn grow={1}>
                        <FavoriteResourceList
                            key={listId}
                            defaultQueryParams={DEFAULT_QUERY_PARAMS}
                            queryParams={queryParams}
                            setQueryParams={setQueryParams}
                            loadItems={loadItems}
                        />
                    </FlexColumn>
                    <PageBodyFooter>{_.get('footer_note')}</PageBodyFooter>
                </PageBody>

                <EditResourceListModal
                    item={list}
                    isVisible={this.state.isVisibleEditListModal}
                    onClose={this.hideEditListModal}
                    onSuccess={this.hideEditListModal}
                />

                <ConfirmationModal
                    title={_.get('lists_remove_modal_title')}
                    message={_.get('lists_remove_modal_question')}
                    isVisible={this.state.isVisibleRemoveListModal}
                    onClose={this.hideRemoveListModal}
                    onSubmit={() => {
                        this.hideRemoveListModal();
                        this.handleRemoveList();
                    }}
                />
            </PageContainer>
        );
    }
}

export default compose(
    connect(mapStateToProps, dispatchProps),
    withQueryParams,
    withGoogleAnalytics({
        trackMethods: (props) => ({
            handleSearchChange: (search?: string) => {
                if (search && search.length > 1) {
                    props.trackEvent({
                        category: 'Content',
                        action: 'Search',
                        label: search,
                    });
                }
            },
        }),
    })
)(MyListScene);
