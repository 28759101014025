import React from 'react';
import { RootState } from 'MyTypes';
import { FaInfoCircle } from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import copy from 'clipboard-copy';
import { connect } from 'react-redux';

import { withGoogleAnalytics } from '@ihme/common/packages/google-analytics';
import { Image } from '@ihme/common/web/components';
import {
    Avatar,
    FlexColumn,
    FlexRow,
    SideMenuLink,
    Small,
    Text,
    Tooltip,
} from '@portal/common/components';
import {
    DataCollectionResource,
    OrganizationMember,
    PaginatedResponse,
    SidebarLinkResource,
} from '@portal/common/types';
import { isDataToolAccessible } from '@portal/common/models/data-project';
import { InjectedMediaProps, styled, withMedia } from '@portal/common/theme';

import { resetDataTool } from '../../store/data-explorer/actions';
import { getDataToolConfig } from '../../store/data-explorer/selectors';
import { getDataProjectIdsForTool } from '../../models/data-tool';
import {
    DATA_LICENSE_PATH,
    DEFINITIONS_PATH,
    FAVORITE_CONTENT_PATH,
    HOME_PATH,
    IHME_STAFF_PATH,
    CONTACT_US_PATH,
    METHODOLOGY_PATH,
    PROFILE_GENERAL_ID,
    PROFILE_PATH,
    SOURCES_PATH,
    TEAM_PATH,
} from '../../router/paths';
import _ from '../../locale';

import ListsRoute from './ListsRoute';
import MinimizeSidebarButton from './MinimizeSidebarButton';
import dataToolsConfig from './data-tools-config';

const infoIcon = <FaInfoCircle />;

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row nowrap',
}));

const accountLinkStyles = {
    minHeight: 'auto',
    fontWeight: 700,
};

const Sidenav = styled('div')(({ theme, minimized }) => ({
    color: '#fff',
    background: theme.color.jetBlack,
    display: 'flex',
    position: 'relative',
    flexFlow: 'column nowrap',
    width: minimized ? theme.minWidth.navigationMinimized : theme.minWidth.navigation,
    minWidth: minimized ? theme.minWidth.navigationMinimized : theme.minWidth.navigation,
    ...(minimized && {
        alignItems: 'center',
    }),
}));

const SidenavGroup = styled('div')(({ theme, minimized }) => ({
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'column nowrap',
    padding: minimized ? '0 0 30px 0' : '0px 30px 30px 30px',
}));

const SidenavMainMenu = styled('div')(({ theme, minimized }) => ({
    flexGrow: 1,
    display: 'block',
    padding: minimized ? '0 0 30px 0' : '0 30px 30px 35px',
    overflow: 'hidden',
    ':hover': {
        overflow: 'auto',
    },
}));

const SidenavMenuGroupTitle = styled(Small.withComponent('b'))(({ theme }) => ({
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeight.bold,
    fontSize: '1.2rem',
    color: theme.color.white30,
}));

const LogoContainer = styled(SidenavGroup)(({ theme }) => ({
    paddingTop: 30,
    height: 90,
    img: {
        height: 30,
    },
}));

const RowWithInfo = styled(FlexRow)({
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
});

const StyledAvatar = styled(Avatar)({
    cursor: 'pointer',
});

const AccountName = styled(Text.withComponent('b'))(({ theme }) => ({
    fontSize: '1.5rem',
    fontWeight: theme.typography.fontWeight.bold,
}));

const AccountTitle = styled(Small)(({ theme }) => ({
    color: theme.color.gray40,
}));

const ProfileLinkContainer = styled(LinkContainer)(({ theme }) => ({
    cursor: 'pointer',
    ':hover': {
        [AccountName]: {
            color: theme.color.green1,
        },
    },
}));

const FlexColumnStyled = styled(FlexColumn)(({ minimized }) => ({
    alignItems: minimized ? 'center' : 'start',
}));

const VersionText = styled.div(({ theme, minimized }) => ({
    fontSize: 14,
    color: theme.color.white30,
    cursor: 'pointer',
    display: minimized ? 'none' : 'inline',
    padding: '10px 0',
    textAlign: 'center',
}));

const Link = ({ to, iconSrc, name, isMinimized, isVisible = true, exact = true, ...props }) => {
    if (!isVisible) {
        return null;
    }

    return (
        <LinkContainer exact={exact} to={to}>
            <SideMenuLink
                beforeIconSrc={iconSrc}
                beforeIconAlt={name}
                centered={isMinimized}
                {...props}
            >
                {!isMinimized && <div>{name}</div>}
            </SideMenuLink>
        </LinkContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    dataToolConfig: getDataToolConfig(state),
});

const dispatchProps = {
    resetDataTool: resetDataTool,
};

type Props = typeof dispatchProps &
    ReturnType<typeof mapStateToProps> &
    InjectedMediaProps & {
        sessionAccount: OrganizationMember | null;
        sidebarLinks: PaginatedResponse<SidebarLinkResource[]> | null;
        isSidebarMinimized: boolean;
        toggleMinimizeSidebar: () => void;
        dataCollections: null | DataCollectionResource[];
    };

type State = {
    isSidebarButtonVisible: boolean;
};

class View extends React.PureComponent<Props, State> {
    static defaultProps = {
        sessionAccount: null,
    };

    state: State = {
        isSidebarButtonVisible: false,
    };

    renderSidebarLink = (resource: SidebarLinkResource) => {
        const { isSidebarMinimized } = this.props;

        return (
            <SideMenuLink
                key={resource.id}
                target="_blank"
                href={resource.url}
                beforeIconSrc={`/icons/ic-${resource.icon}.svg`}
                beforeIconAlt={resource.title}
                centered={isSidebarMinimized}
            >
                {!isSidebarMinimized && (
                    <RowWithInfo>
                        {resource.title}
                        {resource.hint && (
                            <Tooltip interactive title={resource.hint}>
                                <div>{infoIcon}</div>
                            </Tooltip>
                        )}
                    </RowWithInfo>
                )}
            </SideMenuLink>
        );
    };

    handleMinimizeSidebarButtonClick = () => {
        const { toggleMinimizeSidebar } = this.props;
        toggleMinimizeSidebar();
    };

    getVersionText = () => {
        let versionText = 'Client Portal version: ';

        const version = process.env.REACT_APP_VERSION || '';
        const commitHash = process.env.REACT_APP_GIT_COMMIT || '';
        const branch = process.env.ENV === 'staging' ? process.env.REACT_BRANCH + ' ' : '';

        versionText += version;

        if (commitHash) {
            versionText += ` (${branch}${commitHash})`;
        }

        return versionText;
    };

    checkIsProfileLinkActive = () => {
        const {
            location: { pathname },
        } = this.props;

        return pathname.includes(PROFILE_PATH);
    };

    render() {
        const {
            dataCollections,
            sessionAccount,
            isSidebarMinimized,
            sidebarLinks,
            matchMedia,
            resetDataTool,
            dataToolConfig,
        } = this.props;
        const { isSidebarButtonVisible } = this.state;

        if (!sessionAccount) return null;

        const logoSrc = matchMedia({
            mobile: isSidebarMinimized ? '/img/logo.png' : '/img/logo-with-text.png',
            tablet: isSidebarMinimized ? '/img/logo.png' : '/img/logo-with-text.png',
        });

        const versionText = this.getVersionText();

        return (
            <Container
                onMouseEnter={() => {
                    this.setState({ isSidebarButtonVisible: true });
                }}
                onMouseLeave={() => {
                    this.setState({ isSidebarButtonVisible: false });
                }}
            >
                <Sidenav minimized={isSidebarMinimized}>
                    <MinimizeSidebarButton
                        visible={isSidebarButtonVisible}
                        minimized={isSidebarMinimized}
                        onClick={this.handleMinimizeSidebarButtonClick}
                    />

                    <LogoContainer minimized={isSidebarMinimized}>
                        <RouterLink to="/">
                            <Image src={logoSrc} />
                        </RouterLink>
                    </LogoContainer>

                    <SidenavGroup minimized={isSidebarMinimized}>
                        <ProfileLinkContainer to={PROFILE_PATH + PROFILE_GENERAL_ID}>
                            <FlexRow>
                                <FlexColumn data-private>
                                    <StyledAvatar src={sessionAccount.avatar_url} />
                                </FlexColumn>
                                {!isSidebarMinimized && (
                                    <FlexColumn
                                        justify="center"
                                        itemsSpacing={4}
                                        style={{ marginLeft: 10 }}
                                    >
                                        <Link
                                            to={PROFILE_PATH + PROFILE_GENERAL_ID}
                                            checkIsActive={this.checkIsProfileLinkActive}
                                            name={
                                                <AccountName
                                                    data-private
                                                    data-testid="account-name"
                                                >
                                                    {`${sessionAccount.first_name} ${sessionAccount.last_name}`.trim()}
                                                </AccountName>
                                            }
                                            isMinimized={false}
                                            styles={accountLinkStyles}
                                        />
                                        {sessionAccount.title && (
                                            <AccountTitle>{sessionAccount.title}</AccountTitle>
                                        )}
                                    </FlexColumn>
                                )}
                            </FlexRow>
                        </ProfileLinkContainer>
                    </SidenavGroup>

                    <SidenavMainMenu minimized={isSidebarMinimized}>
                        <FlexColumn itemsSpacing={30}>
                            <FlexColumnStyled itemsSpacing={5} minimized={isSidebarMinimized}>
                                <SidenavMenuGroupTitle>
                                    {_.get('nav_group_main')}
                                </SidenavMenuGroupTitle>
                                <FlexColumn>
                                    <Link
                                        to={HOME_PATH}
                                        iconSrc="/icons/ic-dashboard.svg"
                                        name={_.get('nav_dashboard')}
                                        isMinimized={isSidebarMinimized}
                                    />
                                    <Link
                                        to={FAVORITE_CONTENT_PATH}
                                        exact={false}
                                        iconSrc="/icons/ic-star.svg"
                                        name={_.get('nav_my_favorites')}
                                        isMinimized={isSidebarMinimized}
                                    />
                                </FlexColumn>
                            </FlexColumnStyled>

                            <FlexColumnStyled itemsSpacing={5} minimized={isSidebarMinimized}>
                                <SidenavMenuGroupTitle>
                                    {_.get('nav_group_data')}
                                </SidenavMenuGroupTitle>
                                <FlexColumn
                                    onClick={() => {
                                        resetDataTool(dataToolConfig);
                                    }}
                                >
                                    {dataToolsConfig.map(({ path, iconSrc, labelKey }) => (
                                        <Link
                                            key={path}
                                            to={path}
                                            iconSrc={iconSrc}
                                            name={_.get(labelKey)}
                                            isMinimized={isSidebarMinimized}
                                            isVisible={isDataToolAccessible(
                                                getDataProjectIdsForTool(path),
                                                dataCollections
                                            )}
                                        />
                                    ))}
                                </FlexColumn>
                            </FlexColumnStyled>

                            <FlexColumnStyled itemsSpacing={5} minimized={isSidebarMinimized}>
                                <SidenavMenuGroupTitle>
                                    {_.get('nav_group_ihme')}
                                </SidenavMenuGroupTitle>
                                <FlexColumn>
                                    <Link
                                        to={DEFINITIONS_PATH}
                                        iconSrc="/icons/ic-book.svg"
                                        name={_.get('nav_definitions')}
                                        isMinimized={isSidebarMinimized}
                                    />
                                    <Link
                                        to={SOURCES_PATH}
                                        iconSrc="/icons/ic-cloud.svg"
                                        name={_.get('nav_sources')}
                                        isMinimized={isSidebarMinimized}
                                    />
                                    <Link
                                        to={METHODOLOGY_PATH}
                                        iconSrc="/icons/ic-folder.svg"
                                        name={_.get('nav_methodology')}
                                        isMinimized={isSidebarMinimized}
                                    />
                                    <Link
                                        to={IHME_STAFF_PATH}
                                        iconSrc="/icons/ic-user.svg"
                                        name={_.get('nav_staff')}
                                        isMinimized={isSidebarMinimized}
                                    />
                                    <Link
                                        to={CONTACT_US_PATH}
                                        iconSrc="/icons/ic-mail.svg"
                                        name={_.get('nav_contact_us')}
                                        isMinimized={isSidebarMinimized}
                                    />
                                </FlexColumn>
                            </FlexColumnStyled>

                            <FlexColumnStyled itemsSpacing={5} minimized={isSidebarMinimized}>
                                <SidenavMenuGroupTitle>
                                    {isSidebarMinimized ? 'Tools' : _.get('nav_group_tools')}
                                </SidenavMenuGroupTitle>
                                <FlexColumn>
                                    {sidebarLinks &&
                                        sidebarLinks.results.map(this.renderSidebarLink)}
                                </FlexColumn>
                            </FlexColumnStyled>

                            <FlexColumnStyled itemsSpacing={5} minimized={isSidebarMinimized}>
                                <SidenavMenuGroupTitle minimized={isSidebarMinimized}>
                                    {isSidebarMinimized ? 'ORG.' : _.get('nav_group_organization')}
                                </SidenavMenuGroupTitle>
                                <FlexColumn>
                                    <Link
                                        to={TEAM_PATH}
                                        iconSrc="/icons/ic-team.svg"
                                        name={_.get('nav_team')}
                                        isMinimized={isSidebarMinimized}
                                    />
                                    <Link
                                        to={DATA_LICENSE_PATH}
                                        iconSrc="/icons/ic-switches.svg"
                                        name={_.get('data_license_title')}
                                        isMinimized={isSidebarMinimized}
                                    />
                                </FlexColumn>
                            </FlexColumnStyled>
                        </FlexColumn>
                    </SidenavMainMenu>
                    <VersionText
                        minimized={isSidebarMinimized}
                        onClick={() => {
                            copy(versionText);
                        }}
                    >
                        {versionText}
                    </VersionText>
                </Sidenav>
                <ListsRoute />
            </Container>
        );
    }
}

export default compose(
    withRouter,
    withMedia,
    connect(mapStateToProps, dispatchProps),
    withGoogleAnalytics({
        trackMethods: (props) => ({
            handleExternalLinkClick: (url, title) =>
                props.trackEvent({
                    category: 'Content',
                    action: 'Open Data Tools Link',
                    label: title,
                }),
        }),
    })
)(View);
