import { toPng } from 'html-to-image';

const hideAllElements = (
    nodeElement: Element
): { marginBottom: string | number; padding: string | number } => {
    let marginBottom = 0;
    let padding = 0;
    nodeElement.children.forEach((child) => {
        marginBottom = child.style.marginBottom;
        padding = child.style.padding;

        child.style.marginBottom = 0;
        child.style.padding = 0;
        child.children.forEach((grandChild) => {
            grandChild.style.display = 'none';
        });
    });

    return { marginBottom, padding };
};

const restoreAllElementsVisibility = (nodeElement: Element, styles: object) => {
    nodeElement.style.width = '100%';
    nodeElement.children.forEach((child) => {
        child.style = { ...child.style, ...styles };
        child.children.forEach((grandChild) => {
            grandChild.style.display = '';
        });
    });
};

export default (): Promise<string[]> =>
    new Promise((resolve, reject) => {
        const nodeElement = document.querySelector('#edp-onetime-data-chart');
        nodeElement.style.width = '1080px';

        if (!nodeElement) {
            reject();
            return;
        }

        const styles = hideAllElements(nodeElement);

        const indexes = getEdpNodeElementIndexesForScreenshots(nodeElement);
        const urls: string[] = [];

        const screenshotPageFrom = (idx) => {
            screenshotPage(nodeElement, indexes[idx])
                .then((dataUrl: string) => {
                    urls.push(dataUrl);
                    idx++;

                    if (idx < indexes.length) {
                        screenshotPageFrom(idx);
                    } else {
                        restoreAllElementsVisibility(nodeElement, styles);
                        resolve(urls);
                    }
                })
                .catch(reject);
        };
        screenshotPageFrom(0);
    });

const getEdpNodeElementIndexesForScreenshots = (nodeElement: Element): number[][][] => {
    const maxHeight = 1200;
    const indexes: number[][][] = [];
    let currentScreenShotIndexes = [[0, 0]];

    hideAllElements(nodeElement);

    nodeElement.children.forEach((child, childIdx) => {
        child.children.forEach((grandChild, grandChildIdx) => {
            grandChild.style.display = '';
            if (nodeElement.clientHeight >= maxHeight) {
                indexes.push(currentScreenShotIndexes);
                currentScreenShotIndexes = [[childIdx, grandChildIdx]];
                hideAllElements(nodeElement);
            } else {
                currentScreenShotIndexes.push([childIdx, grandChildIdx]);
            }
        });
    });

    indexes.push(currentScreenShotIndexes);

    return indexes;
};

const screenshotPage = (nodeElement: Element, indexes: number[][]): Promise<string> =>
    new Promise((resolve, reject) => {
        hideAllElements(nodeElement);

        indexes.forEach(([childIdx, grandchildIdx]) => {
            const element = nodeElement.children[childIdx].children[grandchildIdx];
            element.style.display = '';
        });

        toPng(nodeElement).then(resolve).catch(reject);
    });
