import React from 'react';

import {
    withGoogleAnalytics,
    WithGoogleAnalyticsInjectedProps,
} from '@portal/common/google-analytics';
import {
    DataTableQueryParams,
    PaginatedRequest,
    PaginatedResponse,
    Resource,
} from '@portal/common/types';

import ResourceListView from './ResourceListView';

type Props = WithGoogleAnalyticsInjectedProps & {
    loadItems: (queryParams: PaginatedRequest<any>) => Promise<PaginatedResponse<any>>;
    defaultQueryParams: DataTableQueryParams;
    queryParams: DataTableQueryParams;
    setQueryParams: (params: object) => void;
    disableSorting?: boolean;
    hidePagination?: boolean;
    renderActionsCell?: (item) => React.ReactNode;
};

class ResourceList extends React.Component<Props> {
    handleResourceClick = () => {};

    render() {
        return <ResourceListView {...this.props} onClick={this.handleResourceClick} />;
    }
}

const resourceLabel = (resource) =>
    `${resource.category ? resource.category.name : 'No category'} / ${resource.name}`;

export default withGoogleAnalytics({
    trackMethods: (props) => ({
        handleResourceClick: (resource: Resource) =>
            resource.type === 'file'
                ? props.trackEvent({
                      category: 'Content',
                      action: 'Download Shared File',
                      label: resourceLabel(resource),
                  })
                : props.trackEvent({
                      category: 'Content',
                      action: 'Open Shared Link',
                      label: resourceLabel(resource),
                  }),
    }),
})(ResourceList);
