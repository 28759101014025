import { RootState } from 'MyTypes';

import { Resource, ResourceList } from '@portal/common/types';
import { GROUP_CONTENT_LIST } from './models';

// @todo Rename to getMyLists (lists created by me + subscribed lists)
export const getCustomLists = (state: RootState): ResourceList[] | null =>
    state.resourceLists.customLists;

export const getPublicLists = (state: RootState): ResourceList[] | null =>
    state.resourceLists.publicLists;

export const getFavoriteResources = (state: RootState): Resource[] | null =>
    state.resourceLists.favoriteResources;

export const getPermalinkResources = (state: RootState): Resource[] | null =>
    state.resourceLists.permalinkResources;

export const getGroupContentResources = (state: RootState): Resource[] | null =>
    state.resourceLists.groupContentResources;

export const getDefaultList = (state: RootState): ResourceList | null => {
    const publicLists = getPublicLists(state);

    const defaultList = publicLists && publicLists.find((list) => list.is_default);

    return defaultList || GROUP_CONTENT_LIST;
};
