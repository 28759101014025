import * as React from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import { intersection, isEqual, isEmpty } from 'lodash/fp';

import { Clearfix, Form } from '@ihme/common/web/components';
import { styled } from '@portal/common/theme';

import { DataFilters } from '@portal/common/types';
import {
    FORECAST_SCENARIO_ID_KEY,
    MAP_DETAIL_LEVEL_ID_KEY,
    MAP_TYPE_ID_KEY,
    PRIMARY_ENTITY_KEY,
} from '@portal/common/models/data-key';
import { TYPE_MAP_CHART, TYPE_SCATTER_MAP_CHART } from '@portal/common/models/chart-type';
import { HealthThreatsMetricsRecords } from '@portal/common/models/data-type';
import { mapConditionToValue } from '@portal/common/utility/chart-data-helpers';
import { NATIONAL_LEVEL } from '@portal/common/components';

import {
    getChartSelectedRefinementFilters,
    getDataToolConfig,
    getMultipleValuesSelectedRefinementFilters,
    getNormalizedSelectedRefinementFilters,
    getSelectedChartType,
    getSelectedConditions,
    getSelectedDataProject,
    getSelectedDatasetsConfig,
    isForecastingData,
    isGHTData,
} from '../../../store/data-explorer/selectors';
import { changeChartFilters, initializeChartFilters } from '../../../store/data-explorer/actions';
import { getSelectedDataTool } from '../../../store/root-reducer';

import { ChartRefinementFilterConfig, getChartRefinementFiltersConfig } from './utils';
import config from '../../../config';

import ChartRefinementFilter from './ChartRefinementFilter';
import ChartConditionSelector from './ChartConditionSelector';

const ButtonGroup = styled.div(({ theme }) => ({
    '> *': {
        display: 'inline-block',
        marginRight: 12,
        width: 252,
    },
}));

const mapStateToProps = (state: RootState) => ({
    chartType: getSelectedChartType(state),
    multipleValueFilters: getMultipleValuesSelectedRefinementFilters(state),
    chartFilters: getChartSelectedRefinementFilters(state),
    selectedRefinementFilters: getNormalizedSelectedRefinementFilters(state),
    selectedConditions: getSelectedConditions(state),
    isForecastingData: isForecastingData(state),
    datasetConfig: getSelectedDatasetsConfig(state),
    dataTool: getSelectedDataTool(state),
    dataToolConfig: getDataToolConfig(state),
    dataProject: getSelectedDataProject(state),
    isGHTData: isGHTData(state),
});

const dispatchProps = {
    changeFilters: changeChartFilters,
    initializeChartFilters: initializeChartFilters,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & {
        preprocessFiltersChange?: (filters: DataFilters) => DataFilters;
        config?: ChartRefinementFilterConfig[];
        skipInitialization?: boolean;
    };

class ChartRefinementFiltersControls extends React.PureComponent<Props> {
    componentDidMount(): void {
        if (!this.props.skipInitialization) {
            this.initializeFilters();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { config, selectedConditions } = this.props;

        if (
            (config && !isEqual(config, prevProps.config)) ||
            !isEqual(selectedConditions, prevProps.selectedConditions)
        ) {
            this.initializeFilters();
        }
    }

    initializeFilters = () => {
        const {
            chartType,
            initializeChartFilters,
            selectedConditions,
            selectedRefinementFilters,
            isForecastingData,
            datasetConfig,
            chartFilters,
            dataToolConfig,
            dataProject,
            isGHTData,
        } = this.props;
        const filters = {};

        const refinementFilters = { ...selectedRefinementFilters };
        // TODO: @vova please look at this and add comment what is the requirement for this
        if (datasetConfig.autoSelectedChartFilters) {
            Object.entries(datasetConfig.autoSelectedChartFilters).map(
                ([filterKey, silentlySelectedValue]) => {
                    refinementFilters[filterKey] = refinementFilters[filterKey].filter(
                        (value: number | string) => !(silentlySelectedValue || []).includes(value)
                    );
                }
            );
        }
        const filtersConfig = this.getFiltersConfig();

        const isMultiple = (value) => value && value.length > 1;
        const allowedGranularityKeys = filtersConfig.map(({ key }) => key);

        Object.entries(refinementFilters).forEach(([key, value]) => {
            if (allowedGranularityKeys.includes(key) && isMultiple(value)) {
                const selectAll = filtersConfig.find(
                    ({ key: filterKey }) => key === filterKey
                )?.selectAll;

                const hasPrevSelectedChartFilter = !!(chartFilters && chartFilters[key]);
                const intersectedChartFilter = intersection(chartFilters?.[key], value);

                if (hasPrevSelectedChartFilter && !isEmpty(intersectedChartFilter)) {
                    filters[key] = intersection(chartFilters[key], value);
                } else {
                    filters[key] = selectAll ? value : [value[0]];
                }
            }
        });

        if (
            allowedGranularityKeys.includes(PRIMARY_ENTITY_KEY) &&
            selectedConditions &&
            selectedConditions.length > 1
        ) {
            const allPathogensCondition = {
                data_type: HealthThreatsMetricsRecords,
                primary_entity_id: config.allPathogensCauseId,
            };
            const condition =
                isGHTData && chartType === TYPE_SCATTER_MAP_CHART
                    ? allPathogensCondition
                    : selectedConditions[0];
            filters[PRIMARY_ENTITY_KEY] = [mapConditionToValue(condition)];
        }

        if (chartType === TYPE_MAP_CHART) {
            const defaultFilters = dataToolConfig?.defaultChartFilters;
            filters[MAP_DETAIL_LEVEL_ID_KEY] =
                defaultFilters?.[MAP_DETAIL_LEVEL_ID_KEY] || NATIONAL_LEVEL;
            filters[MAP_TYPE_ID_KEY] = defaultFilters?.[MAP_TYPE_ID_KEY] || config.globalLocationId;
        }

        if (!isForecastingData) {
            delete filters[FORECAST_SCENARIO_ID_KEY];
        }

        const preprocessedFilters = this.preprocessFilters(filters);
        initializeChartFilters(preprocessedFilters);
    };

    preprocessFilters = (filters: DataFilters) => {
        const { preprocessFiltersChange, chartFilters } = this.props;
        const mergedFilters = { ...chartFilters, ...filters };
        const preprocessedFilters = preprocessFiltersChange
            ? preprocessFiltersChange(mergedFilters)
            : mergedFilters;
        return preprocessedFilters;
    };

    handleFiltersChange = (filters: DataFilters) => {
        const { changeFilters } = this.props;

        const preprocessedFilters = this.preprocessFilters(filters);
        changeFilters(preprocessedFilters);
    };

    renderPrimaryEntityFilterControl = () => {
        if (this.getFiltersConfig().find(({ key }) => key === PRIMARY_ENTITY_KEY)) {
            return <ChartConditionSelector onChange={this.handleFiltersChange} />;
        }

        return null;
    };

    renderFiltersControls = () => {
        const { multipleValueFilters } = this.props;

        return this.getFiltersConfig()
            .filter(
                (filterConfig) =>
                    multipleValueFilters.hasOwnProperty(filterConfig.key) ||
                    filterConfig.isPermanent
            )
            .map(({ key, isPermanent, ...props }) => (
                <ChartRefinementFilter
                    key={key}
                    granularityKey={key}
                    onChange={this.handleFiltersChange}
                    {...props}
                />
            ));
    };

    getFiltersConfig = (): ChartRefinementFilterConfig[] => {
        const { chartType, config, dataTool } = this.props;

        return config ? config : getChartRefinementFiltersConfig(chartType, dataTool);
    };

    render() {
        const { chartFilters, children } = this.props;

        return (
            <Form inline>
                {this.renderPrimaryEntityFilterControl()}
                <ButtonGroup>
                    {chartFilters && this.renderFiltersControls()}
                    {children}
                </ButtonGroup>
                <Clearfix />
            </Form>
        );
    }
}

export default connect(mapStateToProps, dispatchProps)(ChartRefinementFiltersControls);
