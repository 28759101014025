import React, { useCallback, useRef, useState } from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';

import {
    Button,
    ConfirmationModal,
    FlexRow,
    Input,
    ListItem,
    ListItemTop,
    ListItemBottom,
    Popover,
    SvgIcon,
} from '@portal/common/components';
import { styled, WithThemeProps } from '@portal/common/theme';

import _ from '../../locale';
import { FAVORITE_CONTENT_PATH, MY_FAVORITIES_PATH, MY_FAVORITES_ID } from '../../router/paths';
import {
    getFavoriteResourcesAsync,
    getResourceListsAsync,
    getPublicResourceListsAsync,
    setSavingFavoriteResource,
    setSavingResourceListResource,
} from '../../store/resource-lists/actions';
import { getCustomLists, getPublicLists } from '../../store/resource-lists/selectors';
import { getOrganizationId, getSessionAccountId, isManager } from '../../store/root-reducer';

const StyledInput = styled(Input)(({ theme }) => ({
    borderRadius: 0,
    borderTopLeftRadius: theme.borderRadius.base,
    borderBottom: theme.border.tableRowSeparator,
}));

const StyledButton = styled(Button)(({ theme }) => ({
    minWidth: 40,
    borderRadius: 0,
    borderTopRightRadius: theme.borderRadius.base,
}));

const MenuListHeader = ({ createCustomList, ...props }) => {
    const [listName, setListName] = useState<string>();
    const [isSaving, setIsSaving] = useState(false);

    const AddListButton = useCallback(
        () => (
            <ListItemTop
                isSaving={isSaving}
                onClick={(ev) => {
                    ev.nativeEvent.stopImmediatePropagation();

                    if (!isSaving) {
                        setListName('');
                    }
                }}
            >
                {_.get('lists_add_new')}
            </ListItemTop>
        ),
        [isSaving]
    );

    const AddListNameInputForm = useCallback(
        () => (
            <form
                onSubmit={(ev) => {
                    ev.preventDefault();
                    setIsSaving(true);
                    createCustomList(listName);
                    setListName(undefined);
                }}
            >
                <FlexRow>
                    <StyledInput
                        autoFocus
                        size="large"
                        placeholder="List Name"
                        value={listName}
                        onChange={(ev) => setListName(ev.target.value)}
                    />
                    <StyledButton disabled={!listName} type="submit" color="primary">
                        <SvgIcon
                            src="/icons/ic-status-success.svg"
                            color={(theme) => theme.color.white}
                        />
                    </StyledButton>
                </FlexRow>
            </form>
        ),
        [listName]
    );

    return (
        <div {...props}>
            <div>{listName == null ? <AddListButton /> : <AddListNameInputForm />}</div>
        </div>
    );
};

const MenuListFooter = ({ children, ...props }) => {
    return <ListItemBottom {...props}>{children}</ListItemBottom>;
};

/**
 * Component
 */

const mapStateToProps = (state: RootState) => ({
    organizationId: getOrganizationId(state),
    memberId: getSessionAccountId(state),
    favoriteResources: state.resourceLists.favoriteResources,
    savingFavoriteResourcesIds: state.resourceLists.savingFavoriteResourcesIds,
    customLists: getCustomLists(state),
    publicLists: getPublicLists(state),
    isManager: isManager(state),
});

const dispatchProps = {
    getFavoriteResources: getFavoriteResourcesAsync.request,
    getResourceLists: getResourceListsAsync.request,
    getPublicLists: getPublicResourceListsAsync.request,
    setSavingFavoriteResource: setSavingFavoriteResource,
    setSavingResourceListResource: setSavingResourceListResource,
};

export type AddToListDropdownProps<T> = Pick<React.ComponentProps<typeof Popover>, 'placement'> & {
    children: any;
    customSelectedListsIds?: (string | number)[];
    item: T;
    onFavoritesResourceClick?: (item: T, refreshCallback: () => void) => void;
    onCustomListResourceClick?: (
        item: T,
        listId: string | number,
        refreshCallback: () => void
    ) => void;
    onFavoritesSelectedResourceClick?: (item: T) => Promise<T>;
    onCustomListSelectedResourceClick?: (item: T, listId: string | number) => Promise<T>;
    onCreateCustomList: (name: string, refreshCallback: () => void) => Promise<T>;
    disableSavingIndicator?: boolean;
    askBeforeRemoving?: boolean;
};

type Props<T> = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    Partial<WithThemeProps> &
    AddToListDropdownProps<T>;

const AddToListDropdown = <T extends any>({
    children,
    onFavoritesResourceClick,
    onFavoritesSelectedResourceClick,
    onCustomListResourceClick,
    onCustomListSelectedResourceClick,
    onCreateCustomList,
    item,
    customLists,
    publicLists,
    favoriteResources,
    savingFavoriteResourcesIds,
    customSelectedListsIds = [],
    getFavoriteResources,
    getResourceLists,
    getPublicLists,
    organizationId,
    memberId,
    setSavingFavoriteResource,
    setSavingResourceListResource,
    disableSavingIndicator = false,
    askBeforeRemoving = false,
    isManager,
    ...props
}: Props<T>) => {
    const popoverRef = useRef<typeof Popover | null>(null);
    const [resourceIdToRemove, setResourceIdToRemove] = useState<number | string>('');

    const loadUpdatedResourceLists = () => {
        getResourceLists({ memberId, organizationId });
        getPublicLists({ organizationId });
        getFavoriteResources({ memberId, organizationId });
    };

    const addResource = (listId: number | string) => {
        if (listId === MY_FAVORITES_ID) {
            // add to favorites list
            onFavoritesResourceClick &&
                onFavoritesResourceClick(item, loadUpdatedResourceLists) &&
                !disableSavingIndicator &&
                setSavingFavoriteResource(item.id);
        } else {
            // add to custom list
            onCustomListResourceClick &&
                onCustomListResourceClick(item, listId, loadUpdatedResourceLists) &&
                !disableSavingIndicator &&
                setSavingResourceListResource({
                    listId,
                    resourceId: item.id,
                });
        }
    };

    const removeResource = (listId: number | string) => {
        if (listId === MY_FAVORITES_ID) {
            // remove from favorites
            onFavoritesSelectedResourceClick &&
                onFavoritesSelectedResourceClick(item).then(loadUpdatedResourceLists) &&
                !disableSavingIndicator &&
                setSavingFavoriteResource(item.id);
        } else {
            // remove from custom list
            onCustomListSelectedResourceClick &&
                onCustomListSelectedResourceClick(item, listId).then(loadUpdatedResourceLists) &&
                !disableSavingIndicator &&
                setSavingResourceListResource({
                    listId,
                    resourceId: item.id,
                });
        }
    };

    const handleListClick = (listId: number | string, isSelected: boolean) => {
        if (isSelected) {
            if (askBeforeRemoving) {
                setResourceIdToRemove(listId);
                popoverRef?.current?.setPopoverVisibility(false);
            } else {
                removeResource(listId);
            }
        } else {
            addResource(listId);
        }
    };

    const customListMapper = ({
        id,
        name,
        resource_ids = [],
        saving_resource_ids = [],
        is_public,
        creator,
    }) => ({
        id: id,
        name: name,
        isSelected: resource_ids.includes(item.id) || customSelectedListsIds.includes(id), //TODO: Update here
        isSaving: saving_resource_ids.includes(item.id),
        is_public,
        creator,
    });

    // when resource is already on the list => mark item as selected
    // to show saving indicator it's adding a isSaving property to the item in redux
    // then it's cleared automatically when updated resources are loaded from api
    const defaultPopoverLists =
        favoriteResources == null
            ? []
            : [
                  {
                      id: MY_FAVORITES_ID,
                      name: _.get('default_list_name'),
                      isSelected:
                          favoriteResources.map((i) => i.id).includes(item.id) ||
                          customSelectedListsIds.includes(MY_FAVORITES_ID),
                      isSaving: savingFavoriteResourcesIds.includes(item.id),
                      is_public: null,
                      creator: null,
                  },
              ];

    const customPopoverLists =
        customLists == null
            ? []
            : customLists
                  .filter(({ creator }) => creator && creator.id === memberId)
                  .map(customListMapper);
    const publicPopoverLists =
        publicLists == null || !isManager
            ? []
            : publicLists
                  .filter(({ creator }) => creator && creator.id !== memberId)
                  .map(customListMapper);

    const popoverLists = [...defaultPopoverLists, ...customPopoverLists, ...publicPopoverLists];

    const isRemoveConfirmationModalVisible = resourceIdToRemove !== '';

    const renderContent = () => (
        <div key={popoverLists.toString()}>
            <MenuListHeader
                createCustomList={(name) => onCreateCustomList(name, loadUpdatedResourceLists)}
            />
            {popoverLists.map((list) => {
                const isMyPublicList =
                    list.is_public && list.creator && list.creator.id === memberId;
                const publicListIcon = isMyPublicList ? (
                    <SvgIcon
                        size="medium"
                        src="/icons/ic-team.svg"
                        style={{ marginRight: 10, verticalAlign: 'top' }}
                    />
                ) : null;

                return (
                    <ListItem
                        key={list.id}
                        isSelected={!list.isSaving && list.isSelected}
                        isSaving={list.isSaving}
                        disabled={
                            list.isSelected &&
                            ((list.id === MY_FAVORITES_ID && !onFavoritesSelectedResourceClick) ||
                                !onCustomListSelectedResourceClick)
                        }
                        onClick={(ev) => {
                            if (!list.isSaving) {
                                handleListClick(list.id, list.isSelected);
                            }
                        }}
                    >
                        <div>
                            {publicListIcon}
                            {list.name}
                        </div>
                    </ListItem>
                );
            })}
            <Switch>
                <Route path={FAVORITE_CONTENT_PATH}>
                    {/* When matching it will not show next route because switch will stop on the first match */}
                </Route>
                <Route>
                    <LinkContainer to={MY_FAVORITIES_PATH}>
                        <MenuListFooter>
                            {_.get('lists_manage_lists')}
                            <SvgIcon
                                src="/icons/ic-arrow.svg"
                                size="medium"
                                color={(theme) => theme.color.gray40}
                                style={{ transform: 'scaleX(-1)' }}
                            />
                        </MenuListFooter>
                    </LinkContainer>
                </Route>
            </Switch>
        </div>
    );

    return (
        <>
            <Popover
                ref={popoverRef}
                disableHideOnInnerClick
                disableHideOnOuterClick={isRemoveConfirmationModalVisible}
                renderContent={renderContent}
                {...props}
            >
                {children}
            </Popover>
            <ConfirmationModal
                title={_.get('lists_unstar_resource_modal_title')}
                message={_.get('lists_remove_modal_question')}
                isVisible={isRemoveConfirmationModalVisible}
                onClose={() => setResourceIdToRemove('')}
                onSubmit={() => {
                    setResourceIdToRemove('');
                    removeResource(resourceIdToRemove);
                }}
            />
        </>
    );
};

export default compose(connect(mapStateToProps, dispatchProps))(AddToListDropdown);
