import React from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => {
        const searchParams = new URLSearchParams(search);

        const queryParams: { [key: string]: string } = {};
        searchParams.forEach((value, key) => (queryParams[key] = value));

        return queryParams;
    }, [search]);
};

export default useQuery;
