import React, { useState } from 'react';

import { SvgIcon } from '@portal/common/components';

import _ from '../../locale';

export interface CopyIconProps {
    onClick: () => void;
}

export function CopyIcon(props: CopyIconProps) {
    const { onClick } = props;

    const [iconName, setIconName] = useState('/icons/ic-copy.svg');

    const handleClick = () => {
        setIconName('/icons/ic-tick.svg');
        setTimeout(() => setIconName('/icons/ic-copy.svg'), 2000);
        onClick && onClick();
    };

    return (
        <SvgIcon
            data-testid="copy-icon"
            src={iconName}
            size="large"
            tooltip={_('tooltip_copy')}
            color={(theme) => theme.color.green1}
            style={{ zIndex: 1 }}
            onClick={handleClick}
        />
    );
}

export default CopyIcon;
