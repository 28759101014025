import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { isEmpty } from 'lodash/fp';

import {
    BodyText1,
    InternalLink,
    Modal,
    ModalBody,
    ModalButton,
    ModalFooter,
    ModalHeader,
} from '@portal/common/components';
import { DataProjectId } from '@portal/common/models/data-project';
import { ROUND_ID_KEY } from '@portal/common/models/data-key';
import { styled } from '@portal/common/theme';

import {
    getIsNotificationShown,
    getOrganizationId,
    getSession,
    getSessionAccount,
} from '../../../store/root-reducer';
import { getDataCollections } from '../../../store/data-explorer/selectors';
import { setNotificationShown, updateAccount } from '../../../store/session/actions';

import { DATA_EXPLORER_PATH } from '../../../router/paths';
import config from '../../../config';
import _ from '../../../locale';

const StyledModal = styled(Modal)({
    marginTop: 150,
});

const StyledHeader = styled(ModalHeader)({
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingRight: 20,
    paddingLeft: 20,
});

const BodyText1Styled = styled(BodyText1)(({ theme }) => ({
    marginTop: 20,
    textAlign: 'center',
    fontWeight: theme.typography.fontWeight.base,
}));

const ModalBodyStyled = styled(ModalBody)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
});

type Props = RouteComponentProps & {};

function NewGBDBecomesDefaultPopup({ history }: Props) {
    const dispatch = useDispatch();

    const account = useSelector(getSessionAccount);
    const organizationId = useSelector(getOrganizationId);
    const dataCollections = useSelector(getDataCollections);
    const isImpersonatedSession = useSelector(getSession)?.isImpersonated;
    const isNotificationDismissed = useSelector(getIsNotificationShown);

    const { id: accountId, is_stealth: isStealthUser } = account || {};

    const isUserLicensedToGBD = useMemo(() => {
        if (isEmpty(dataCollections)) {
            return false;
        }

        const gbdDataCollections = (dataCollections || []).filter(
            (collection) => collection.data_project_id === DataProjectId.GBD
        );
        if (isEmpty(gbdDataCollections)) {
            return false;
        }

        return !!gbdDataCollections.find((collection) =>
            collection.datasets.find(({ granularity }) =>
                (granularity?.[ROUND_ID_KEY] || []).includes(config.gbd2022RoundId)
            )
        );
    }, [dataCollections]);

    const [isVisible, setVisibility] = useState(false);
    const handleClose = useCallback(() => {
        if (organizationId && accountId) {
            dispatch(setNotificationShown(true));
            dispatch(updateAccount(organizationId, accountId, { is_notification_shown: true }));
        }
        setVisibility(false);
    }, [dispatch]);

    const handleExploreClick = useCallback(() => {
        handleClose();
        history.push(DATA_EXPLORER_PATH);
    }, []);

    useEffect(() => {
        setVisibility(
            !isImpersonatedSession &&
                !isStealthUser &&
                !isNotificationDismissed &&
                isUserLicensedToGBD
        );
    }, [
        isNotificationDismissed,
        isUserLicensedToGBD,
        setVisibility,
        isImpersonatedSession,
        isStealthUser,
    ]);

    const LinkToDEX = (props) => (
        <InternalLink {...props} to={DATA_EXPLORER_PATH} onClick={handleClose} />
    );

    return (
        <StyledModal show={isVisible} onHide={handleClose}>
            <StyledHeader closeButton>
                {_('notification_new_gbd_becomes_default_title')}
            </StyledHeader>
            <ModalBodyStyled>
                <BodyText1Styled>
                    {_('notification_new_gbd_becomes_default_message')(LinkToDEX)}
                </BodyText1Styled>
            </ModalBodyStyled>
            <ModalFooter>
                <ModalButton type="submit" onClick={handleExploreClick}>
                    {_('explore_now_button')}
                </ModalButton>
            </ModalFooter>
        </StyledModal>
    );
}

export default withRouter(NewGBDBecomesDefaultPopup);
