import React, { useEffect, useState } from 'react';
import { groupBy, isEmpty } from 'lodash/fp';
import numeral from 'numeral';

import {
    AGE_GROUP_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    MEASURE_ID_KEY,
    PRIMARY_ENTITY_ID_KEY,
    ROUND_ID_KEY,
    VALUE_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import { Link } from '@portal/common/components';
import { LifeExpectancyRecords } from '@portal/common/models/data-type';
import { DataCondition } from '@portal/common/types';

import useGetApiData from '../hooks/use-get-api-data';
import MetricBlock from '../components/MetricBlock';
import config from '../../../config';
import _ from '../../../locale';

const LinkToHALE = ({ children, ...props }) => (
    <Link
        target="_blank"
        href="https://www.healthdata.org/research-article/global-age-sex-specific-fertility-mortality-healthy-life-expectancy-hale-and"
        {...props}
    >
        {children}
    </Link>
);

type Props = {
    locationId: number;
};

const HALEBlock = ({ locationId }: Props) => {
    const [currentLifeExpectancy, setCurrentLifeExpectancy] = useState<number | string | null>(
        'N/A'
    );
    const conditions = [
        {
            data_type: LifeExpectancyRecords,
            [PRIMARY_ENTITY_ID_KEY]: 1,
        },
    ] as DataCondition[];

    const filters = {
        [LOCATION_ID_KEY]: [locationId],
        [AGE_GROUP_ID_KEY]: [config.birthAgeGroupId],
        [GENDER_ID_KEY]: [config.bothGendersId],
        [MEASURE_ID_KEY]: [config.lifeExpectancyMeasureId],
        [ROUND_ID_KEY]: [config.gbd2017RoundId],
        [FORECAST_SCENARIO_ID_KEY]: [config.referenceForecastScenarioId],
        [YEAR_KEY]: [2019],
    };

    const { isLoading, dataResponse } = useGetApiData({
        conditions,
        filters,
    });

    useEffect(() => {
        if (!isLoading && dataResponse) {
            const { columns, records } = dataResponse;
            const valueIdx = columns.indexOf(VALUE_KEY);
            const yearIdx = columns.indexOf(YEAR_KEY);
            if (!isEmpty(records)) {
                const recordsByYear = groupBy((record) => record[yearIdx], records);
                const getYearValue = (year: number) => recordsByYear[year][0][valueIdx];
                setCurrentLifeExpectancy(numeral(getYearValue(2019)).format('0') + ' years');
            }
        }
    }, [isLoading, dataResponse]);

    return (
        <MetricBlock
            isLoading={isLoading}
            label={_('simulation_tool_hale_metric')({ Link: LinkToHALE })}
            value={currentLifeExpectancy}
        />
    );
};

export default HALEBlock;
