import { createAction, createAsyncAction } from 'typesafe-actions';
import { Resource, ResourceList } from '@portal/common/types';

export const getFavoriteResourcesAsync = createAsyncAction(
    'GET_FAVORITE_RESOURCES_REQUEST',
    'GET_FAVORITE_RESOURCES_SUCCESS',
    'GET_FAVORITE_RESOURCES_FAILURE'
)<{ organizationId: number; memberId: number }, Resource[], string>();

export const removeFavoriteResource = createAction('FAVORITE_RESOURCE_REMOVED')<{
    resourceId: number;
}>();
export const addFavoriteResource = createAction('FAVORITE_RESOURCE_ADDED')<Resource>();

export const getPermalinkResourcesAsync = createAsyncAction(
    'GET_PERMALINK_RESOURCES_REQUEST',
    'GET_PERMALINK_RESOURCES_SUCCESS',
    'GET_PERMALINK_RESOURCES_FAILURE'
)<{ organizationId: number; memberId: number }, Resource[], string>();

export const removePermalinkResourceAsync = createAsyncAction(
    'REMOVE_PERMALINK_RESOURCES_REQUEST',
    'REMOVE_PERMALINK_RESOURCES_SUCCESS',
    'REMOVE_PERMALINK_RESOURCES_FAILURE'
)<
    { permalinkId: number; organizationId: number; memberId: number; loadItems: Function },
    { organizationId: number; memberId: number },
    string
>();

export const getGroupContentResourcesAsync = createAsyncAction(
    'GET_GROUP_CONTENT_RESOURCES_REQUEST',
    'GET_GROUP_CONTENT_RESOURCES_SUCCESS',
    'GET_GROUP_CONTENT_RESOURCES_FAILURE'
)<{ organizationId: number }, Resource[], string>();

export const setSavingFavoriteResource = createAction('SAVING_FAVORITE_RESOURCE')<number>();
export const setSavingResourceListResource = createAction('SAVING_RESOURCE_LIST_RESOURCE')<{
    listId: number;
    resourceId: number;
}>();

// Resource lists
export const getResourceListsAsync = createAsyncAction(
    'GET_RESOURCE_LISTS_REQUEST',
    'GET_RESOURCE_LISTS_SUCCESS',
    'GET_RESOURCE_LISTS_FAILURE'
)<{ organizationId: number; memberId: number }, ResourceList[], string>();

export const createResourceList = createAction('RESOURCE_LIST_CREATED')<ResourceList>();
export const updateResourceList = createAction('RESOURCE_LIST_UPDATED')<ResourceList>();

export const removeResourceListResource = createAction('RESOURCE_LIST_RESOURCE_REMOVED')<{
    listId: number;
    resourceId: number;
}>();
export const addResourceListResource = createAction('RESOURCE_LIST_RESOURCE_ADDED')<{
    listId: number;
    resource: Resource;
}>();

export const subscribeToListAsync = createAsyncAction(
    'SUBSCRIBE_TO_LIST_REQUEST',
    'SUBSCRIBE_TO_LIST_SUCCESS',
    'SUBSCRIBE_TO_LIST_FAILURE'
)<
    { organizationId: number; memberId: number; listId: number; prevList: ResourceList },
    { prevList: ResourceList; list: ResourceList },
    string
>();

export const unsubscribeFromListAsync = createAsyncAction(
    'UNSUBSCRIBE_FROM_LIST_REQUEST',
    'UNSUBSCRIBE_FROM_LIST_SUCCESS',
    'UNSUBSCRIBE_FROM_LIST_FAILURE'
)<{ organizationId: number; memberId: number; listId: number }, ResourceList, string>();

// Public lists
export const getPublicResourceListsAsync = createAsyncAction(
    'GET_PUBLIC_RESOURCE_LISTS_REQUEST',
    'GET_PUBLIC_RESOURCE_LISTS_SUCCESS',
    'GET_PUBLIC_RESOURCE_LISTS_FAILURE'
)<{ organizationId: number }, ResourceList[], string>();

export const makeResourceListPublicAsync = createAsyncAction(
    'MAKE_LIST_PUBLIC_REQUEST',
    'MAKE_LIST_PUBLIC_SUCCESS',
    'MAKE_LIST_PUBLIC_FAILURE'
)<{ organizationId: number; listId: number }, ResourceList, string>();

export const makeResourceListPrivateAsync = createAsyncAction(
    'MAKE_LIST_PRIVATE_REQUEST',
    'MAKE_LIST_PRIVATE_SUCCESS',
    'MAKE_LIST_PRIVATE_FAILURE'
)<{ organizationId: number; listId: number }, ResourceList, string>();

export const makeResourceListDefaultAsync = createAsyncAction(
    'MAKE_LIST_DEFAULT_REQUEST',
    'MAKE_LIST_DEFAULT_SUCCESS',
    'MAKE_LIST_DEFAULT_FAILURE'
)<{ organizationId: number; listId: number }, ResourceList, string>();

export const makeResourceListNotDefaultAsync = createAsyncAction(
    'MAKE_LIST_NOT_DEFAULT_REQUEST',
    'MAKE_LIST_NOT_DEFAULT_SUCCESS',
    'MAKE_LIST_NOT_DEFAULT_FAILURE'
)<{ organizationId: number; listId: number }, ResourceList, string>();

export const deleteOrganizationResourceListAsync = createAsyncAction(
    'DELETE_ORGANIZATION_RESOURCE_LIST_REQUEST',
    'DELETE_ORGANIZATION_RESOURCE_LIST_SUCCESS',
    'DELETE_ORGANIZATION_RESOURCE_LIST_FAILURE'
)<{ organizationId: number; listId: number }, { listId: number }, string>();
