import React from 'react';
import dateFormat from 'dateformat';
import { uniq } from 'lodash/fp';

import _ from '@portal/common/locale';

import { Checkbox, LineChart } from '@portal/common/components';
import {
    DataCollectionResource,
    DataCondition,
    DataExportFormat,
    DataFilters,
    DataGranularity,
    DataGranularityKey,
    DataKey,
    DataRecord,
    DataResponse,
    DataToolConfig,
    DataType,
} from '@portal/common/types';
import {
    chartProviderFiltersToObject,
    connectPastEstimateWithBaseForecast,
    getChartTitle,
    getChartSubTitle,
    getYAxisTitle,
    localizeConditions,
    filterForecastingData,
    splitForecastData,
    mapValueToCondition,
} from '@portal/common/utility/chart-data-helpers';

import {
    AGE_GROUP_ID_KEY,
    CAUSE_ID_KEY,
    EDUCATION_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    LOWER_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    PRIMARY_ENTITY_ID_LOCALIZATION_KEY,
    PRIMARY_ENTITY_KEY,
    RACE_ID_KEY,
    RESCALED_HEATMAP_VALUE,
    RESPONSE_ID_KEY,
    RISK_EXPOSURE_ID_KEY,
    UPPER_KEY,
    VALUE_KEY,
} from '@portal/common/models/data-key';
import formatChartLabel from '@portal/common/utility/formatting/formatChartLabel';
import { TYPE_LINE_CHART } from '@portal/common/models/chart-type';
import { getExportFilename } from '@portal/common/utility/get-export-filename';
import { pngIcon } from '@portal/common/theme/icons';

import { EDP_EXPLORER_PATH } from '../../../../router/paths';

import config from '../../../../config';
import echarts from '../../../../theme/echarts';
import locale from './locale';

import { PNG } from '@portal/common/models/file-format';

type Props = {
    isLoadingData: boolean;
    filtersValues: DataFilters;
    columns: Array<DataKey>;
    records: Array<DataRecord>;

    forecastTimeUnitStart: number | string;
    initialTimeUnitValue: number | string;
    enableExportPNG: boolean;
    onDataLoad?: () => void;
    isForecastingData: boolean;
    mergedDataResponses: DataResponse;
    multipleFilterKeys: DataGranularityKey[];
    selectedConditions: DataCondition[];
    selectedConditionsDataTypes: DataType[];
    selectedConditionsDataTypesGranularity: DataGranularity;
    applyPerformanceOptimization: boolean;

    baseForecastScenarioId: number;
    observedForecastScenarioId: number;
    timeUnitKey: DataGranularityKey;
    onExportData: (format: DataExportFormat) => void;
    numberFormatter: (value: number | string) => string;
    dataToolConfig: DataToolConfig;
    selectedDataTool: string;
    selectedDataCollection: DataCollectionResource;

    renderTitle?: (props) => string;
    renderSubtitle?: (props) => string;

    isHealthThreatsData: boolean;
    isHeatmapAllowed: boolean;
};

type State = {
    displayUncertainty: boolean;
    displayForecasts: boolean;
    selectedLegend: string[];
};

const getUncertaintyVisibility = (config: DataToolConfig): boolean => {
    const { lineChartConfig } = config;

    if (lineChartConfig && lineChartConfig.hasOwnProperty('initialUncertaintyValue')) {
        return lineChartConfig.initialUncertaintyValue;
    }

    if (lineChartConfig && lineChartConfig.hideUncertaintyValues) {
        return !lineChartConfig.hideUncertaintyValues;
    }

    return true;
};

class Chart extends React.Component<Props, State> {
    static defaultProps = {
        enableExportPNG: true,
        isForecastingData: false,
        mergedDataResponses: {
            columns: [],
            records: [],
        },
        multipleFilterKeys: [],
    };

    state: State = {
        displayUncertainty: getUncertaintyVisibility(this.props.dataToolConfig),
        displayForecasts: true,
        selectedLegend: [],
    };

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
        return (
            this.props.isLoadingData !== nextProps.isLoadingData ||
            this.state.displayUncertainty !== nextState.displayUncertainty ||
            this.state.displayForecasts !== nextState.displayForecasts
        );
    }

    componentDidMount() {
        const { onDataLoad } = this.props;
        onDataLoad && onDataLoad();
    }

    toggleUncertainty = () =>
        this.setState((prevState: State) => ({
            displayUncertainty: !prevState.displayUncertainty,
        }));

    toggleForecastsVisibility = () =>
        this.setState((prevState: State) => ({
            displayForecasts: !prevState.displayForecasts,
        }));

    prepareResponse = () => {
        const {
            mergedDataResponses,
            isForecastingData,
            observedForecastScenarioId,
            baseForecastScenarioId,
            timeUnitKey,
            multipleFilterKeys,
            isHealthThreatsData,
            isHeatmapAllowed,
        } = this.props;
        const { displayUncertainty, displayForecasts } = this.state;

        const { columns, records } = mergedDataResponses;
        let valueOffset = 0;
        let modifiedRecords = records;

        const valueIdx = columns.indexOf(VALUE_KEY);
        const upperIdx = columns.indexOf(UPPER_KEY);
        const lowerIdx = columns.indexOf(LOWER_KEY);

        const hasNegativeAreaValue = !!records.find(
            (record) => record[upperIdx] < 0 || record[lowerIdx] < 0
        );

        if (displayUncertainty && hasNegativeAreaValue) {
            modifiedRecords = [];

            const minValue = Math.floor(
                Math.min(
                    ...records.map((record) =>
                        Math.min(record[valueIdx], record[upperIdx], record[lowerIdx])
                    )
                )
            );

            records.forEach((record) => {
                const modifiedRecord = record.slice();
                modifiedRecord[valueIdx] -= minValue;
                modifiedRecord[upperIdx] -= minValue;
                modifiedRecord[lowerIdx] -= minValue;

                modifiedRecords.push(modifiedRecord);
            });
            valueOffset = minValue;
        }

        if (!isForecastingData) {
            return { columns, records: modifiedRecords, valueOffset };
        }

        const forecastingResponse =
            isHeatmapAllowed && !displayForecasts
                ? filterForecastingData(mergedDataResponses, observedForecastScenarioId)
                : connectPastEstimateWithBaseForecast(
                      mergedDataResponses,
                      observedForecastScenarioId,
                      baseForecastScenarioId,
                      timeUnitKey,
                      multipleFilterKeys
                  );

        if (!isHealthThreatsData) {
            return {
                ...forecastingResponse,
                valueOffset,
            };
        }

        const { observedData, forecastData } = splitForecastData(
            forecastingResponse,
            observedForecastScenarioId
        );

        return {
            ...observedData,
            heatmapDataResponse: forecastData,
            valueOffset,
        };
    };

    getSelectedCondition = (): DataCondition => {
        const { filtersValues, selectedConditions } = this.props;

        return selectedConditions.length === 1 ||
            !(
                filtersValues &&
                filtersValues[PRIMARY_ENTITY_KEY] &&
                filtersValues[PRIMARY_ENTITY_KEY].length
            )
            ? selectedConditions[0]
            : mapValueToCondition(filtersValues[PRIMARY_ENTITY_KEY][0]);
    };

    renderTitle = (renderProps) => {
        const {
            selectedConditions,
            mergedDataResponses: { records },
            selectedConditionsDataTypes,
            selectedConditionsDataTypesGranularity,
            renderTitle,
            isHealthThreatsData,
        } = this.props;

        if (renderTitle) {
            return renderTitle(renderProps);
        }

        const {
            filters,
            timeRange: { range },
        } = renderProps;

        return records.length > 0
            ? getChartTitle(
                  selectedConditionsDataTypes,
                  localizeConditions(
                      isHealthThreatsData ? [this.getSelectedCondition()] : selectedConditions
                  ),
                  chartProviderFiltersToObject(filters),
                  Object.keys(selectedConditionsDataTypesGranularity),
                  [RISK_EXPOSURE_ID_KEY, CAUSE_ID_KEY, MEASURE_ID_KEY, METRIC_ID_KEY],
                  range
              )
            : '';
    };

    renderSubtitle = (renderProps) => {
        const {
            mergedDataResponses: { records },
            selectedConditionsDataTypesGranularity,
            renderSubtitle,
        } = this.props;
        if (renderSubtitle) {
            return renderSubtitle(renderProps);
        }
        const { filters } = renderProps;

        return records.length > 0
            ? getChartSubTitle(
                  chartProviderFiltersToObject(filters),
                  Object.keys(selectedConditionsDataTypesGranularity),
                  [LOCATION_ID_KEY, AGE_GROUP_ID_KEY, GENDER_ID_KEY, RACE_ID_KEY, EDUCATION_ID_KEY]
              )
            : '';
    };

    renderNoDataMessage = (renderProps) => {
        const { isHealthThreatsData } = this.props;

        if (!isHealthThreatsData) {
            return _('chart_error_no_data');
        }

        const { filters } = renderProps;

        const pathogen = localizeConditions([this.getSelectedCondition()]);
        const location = _(`location_${filters[LOCATION_ID_KEY]}`);

        return _('chart_error_ght_no_data', { pathogen, location });
    };

    getSaveFilename = ({ filters, timeRange: { range } }) => {
        const { selectedConditions, selectedDataTool, selectedDataCollection } = this.props;

        return getExportFilename({
            trigger: TYPE_LINE_CHART,
            dataTool: selectedDataTool,
            dataCollectionName: selectedDataCollection.name,
            conditions: localizeConditions(selectedConditions),
            filters,
            selectedTimeUnit: range,
        });
    };

    formatSeriesName = (dataRecord: DataRecord, omitFilter: DataKey | null = null) => {
        const {
            mergedDataResponses: { columns },
            baseForecastScenarioId,
            dataToolConfig,
        } = this.props;

        const localizeValue = (value, localeKeyPrefix, onlyMultiple = false, prefix = ', ') => {
            if (onlyMultiple && !this.isMultipleFilter(`${localeKeyPrefix}id`)) {
                return '';
            }

            // todo: use the same function as used in data table
            return value ? `${prefix}${_(`${localeKeyPrefix}${value}`)}` : '';
        };

        const getIndexOf = (key: DataKey): number => columns.indexOf(key);
        const getValueOf = (key: DataKey): number | string => dataRecord[getIndexOf(key)];

        let includePrimaryEntityName = !(
            dataToolConfig.lineChartConfig &&
            dataToolConfig.lineChartConfig.excludePrimaryEntityFromLegendName
        );

        let filters = [];
        if (includePrimaryEntityName) {
            filters.push(PRIMARY_ENTITY_ID_LOCALIZATION_KEY);
        }
        filters.push(...this.getGroupByKeys());
        if (omitFilter) {
            filters = filters.filter((value) => value !== omitFilter);
        }

        const filterNames = uniq(filters).map((key, idx) => {
            let value = getValueOf(key);

            let prefix = idx === 0 ? '' : ', ';
            let localeKeyPrefix = key.slice(0, -2);
            let filterSingleValue = true;

            if (key === PRIMARY_ENTITY_ID_LOCALIZATION_KEY) {
                localeKeyPrefix = '';
                filterSingleValue = false;
            }

            if (key === RESPONSE_ID_KEY) {
                filterSingleValue = false;
            }

            if (key === FORECAST_SCENARIO_ID_KEY) {
                filterSingleValue = false;
                if (value === config.pastEstimateScenarioId) {
                    value = baseForecastScenarioId;
                }

                prefix = value === config.historicForecastScenarioId ? ' ' : ' with ';
            }

            return localizeValue(value, localeKeyPrefix, filterSingleValue, prefix);
        });

        return filterNames.filter((name) => name !== '').join('');
    };

    isMultipleFilter = (key: DataGranularityKey): boolean =>
        this.props.multipleFilterKeys.includes(key);

    generateLineParams = (record: DataRecord) => {
        const {
            mergedDataResponses: { columns },
            isForecastingData,
        } = this.props;
        if (!isForecastingData) {
            return null;
        }

        const forecastScenarioIdIdx = columns.indexOf(FORECAST_SCENARIO_ID_KEY);
        const forecastScenarioId = record[forecastScenarioIdIdx];
        const isSolid =
            forecastScenarioId === config.pastEstimateScenarioId ||
            forecastScenarioId === config.observedDataForecastScenarioId ||
            forecastScenarioId === config.historicForecastScenarioId;

        const lineStyle = { type: isSolid ? 'solid' : 'dotted' };

        return { lineStyle };
    };

    getAreaKeys = () => (this.state.displayUncertainty ? [UPPER_KEY, LOWER_KEY] : null);

    getGroupByKeys = () => {
        const { isForecastingData, multipleFilterKeys, selectedDataTool } = this.props;
        const keys = [...multipleFilterKeys];

        if (isForecastingData) {
            keys.push(FORECAST_SCENARIO_ID_KEY);
        }

        if (selectedDataTool === EDP_EXPLORER_PATH) {
            keys.push(RESPONSE_ID_KEY);
        }

        return uniq(keys);
    };

    getMarkLines = (): { isHorizontal?: boolean; label: string; value: number | string }[] => {
        const { isForecastingData, forecastTimeUnitStart, mergedDataResponses, dataToolConfig } =
            this.props;
        const markLines = [];
        if (isForecastingData) {
            const showToday = !forecastTimeUnitStart;

            markLines.push({
                value: showToday
                    ? dateFormat(new Date().setHours(0, 0, 0, 0), 'isoDate')
                    : (forecastTimeUnitStart - 1).toString(),
                label: showToday ? 'Today' : '',
            });
        }

        const { columns, records } = mergedDataResponses;
        const { lineChartConfig } = dataToolConfig;
        if (
            lineChartConfig &&
            lineChartConfig.markLinesByFilterValue &&
            lineChartConfig.markLinesByFilterValue.length &&
            records &&
            records.length
        ) {
            lineChartConfig.markLinesByFilterValue.forEach(({ granularityKey, label, color }) => {
                const columnIdx = columns.indexOf(granularityKey);
                const value = records[0][columnIdx];
                if (value != null) {
                    markLines.push({
                        isHorizontal: true,
                        value,
                        label,
                        color,
                    });
                }
            });
        }

        return markLines;
    };

    handleLegendSelectChange = ({ selected }) => {
        this.setState({ selectedLegend: selected });
    };

    getChartEvents = () => ({
        legendselectchanged: this.handleLegendSelectChange,
    });

    render() {
        const {
            forecastTimeUnitStart,
            initialTimeUnitValue,
            enableExportPNG,
            isForecastingData,
            dataResponses,
            multipleFilterKeys,
            applyPerformanceOptimization,
            selectedConditionsDataTypes,
            timeUnitKey,
            onExportData,
            dataToolConfig,
            isHealthThreatsData,
            isHeatmapAllowed,
            ...cleanProps
        } = this.props;

        const { displayUncertainty, displayForecasts } = this.state;
        const showUncertaintyControl = !dataToolConfig.lineChartConfig?.hideUncertaintyValues;
        const showForecastsControl = isHeatmapAllowed;
        const groupByKeys = this.getGroupByKeys();

        const { columns, records, valueOffset, heatmapDataResponse } = this.prepareResponse();

        const dataHasUncertaintyValues = columns.includes(UPPER_KEY) && columns.includes(LOWER_KEY);

        const isHeatmapEnabled = isHeatmapAllowed && displayForecasts;

        return (
            <div>
                {showUncertaintyControl && dataHasUncertaintyValues && (
                    <Checkbox checked={displayUncertainty} onChange={this.toggleUncertainty}>
                        {_(locale.displayUncertainty)}
                    </Checkbox>
                )}
                {showForecastsControl && isForecastingData && (
                    <Checkbox checked={displayForecasts} onChange={this.toggleForecastsVisibility}>
                        {_('display_forecasts')}
                    </Checkbox>
                )}
                <LineChart
                    {...cleanProps}
                    isHeatmapEnabled={isHeatmapEnabled}
                    columns={columns}
                    records={records}
                    heatmapDataResponse={heatmapDataResponse}
                    theme={echarts}
                    xAxisKey={timeUnitKey}
                    yAxisKey={isHeatmapEnabled ? RESCALED_HEATMAP_VALUE : VALUE_KEY}
                    withSlider
                    saveAsImage={{
                        visible: true,
                        enabled: enableExportPNG,
                        filename: this.getSaveFilename,
                        icon: pngIcon,
                        onClick: () => onExportData?.(PNG),
                    }}
                    markLines={this.getMarkLines()}
                    areaKeys={this.getAreaKeys()}
                    yAxisLabelFormatter={(value) =>
                        formatChartLabel(
                            isHeatmapEnabled ? config.GHT_bins[value] : value + valueOffset
                        )
                    }
                    renderYAxisTitle={({ filters }) =>
                        getYAxisTitle(
                            chartProviderFiltersToObject(filters),
                            selectedConditionsDataTypes[0]
                        )
                    }
                    onEvents={this.getChartEvents()}
                    getSelectedSeries={() => this.state.selectedLegend}
                    renderXAxisTitle={() => _(`chart_axis_label_${timeUnitKey}`)}
                    groupByKeys={groupByKeys}
                    renderTitle={this.renderTitle}
                    renderSubtitle={this.renderSubtitle}
                    renderNoDataMessage={this.renderNoDataMessage}
                    formatSeriesName={this.formatSeriesName}
                    generateLineParams={this.generateLineParams}
                    animation={!applyPerformanceOptimization}
                    legendPagination={applyPerformanceOptimization}
                />
            </div>
        );
    }
}

export default Chart;
