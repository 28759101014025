import React from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import FileSaver from 'file-saver';
import { pick } from 'lodash/fp';

import Loading from '@ihme/common/components/Loading';

import { Modal, Tooltip } from '@portal/common/components';
import {
    Checkbox,
    FlexRow,
    FlexColumn,
    ModalBody,
    ModalButton,
    ModalFooter,
    ModalHeader,
    FormGroup,
    FormInput,
    FormTextarea,
    FieldLabel,
} from '@portal/common/components';
import {
    DataExplorerSection,
    DataFilters,
    DataGranularity,
    DataToolConfig,
    PartialDataset,
} from '@portal/common/types';
import {
    DATA_TYPE_KEY,
    GENDER_ID_KEY,
    isTimeUnitKey,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import { localizeColumns, localizeConditions } from '@portal/common/utility/chart-data-helpers';
import { formatValue, formatTimeUnitRangeValue } from '@portal/common/utility/filters-helpers';
import formatPdfValue from '@portal/common/utility/formatting/formatPdfValue';
import { getDataTypeConfig } from '@portal/common/models/data-type-config';
import {
    AntimicrobialResistanceRecords,
    getDataTypePrimaryEntityKey,
} from '@portal/common/models/data-type';
import {
    CDN_SECTION,
    CHART_SECTION,
    FILTER_SECTION,
    DATA_TABLE_SECTION,
} from '@portal/common/models/data-explorer-section';
import { TYPE_DATA_TABLE_CHART } from '@portal/common/models/chart-type';
import { styled } from '@portal/common/theme';
import { getExportFilename } from '@portal/common/utility/get-export-filename';

import {
    getAgeGroupsOrder,
    getConditionDetailNotes,
    getSelectedConditions,
    getSelectedConditionsDataTypes,
    getSelectedConditionsRefinedGranularity,
    getSelectedRefinementFiltersWithFallbackToDefaultFilters,
    getDataToolConfig,
    getMergedDataResponses,
    isForecastingData,
    getSelectedDatasetsConfig,
    getDataTableVisibleColumns,
    getDataTableSortedByColumn,
    getDataTableSortedDescending,
    isPopulationData,
} from '../../../store/data-explorer/selectors';
import { getSelectedDataTool } from '../../../store/root-reducer';
import { getSelectedDataCollectionForDataTool } from '../../../store/user-settings/selectors';

import _ from '../../../locale';

import { filterDataResponse, sortRawTableData } from '../CustomDataTable/utils';

import screenshotChart from './screenshot-chart';
import screenshotEdpOnetimeDataChart from './screenshot-edp-onetime-data-chart';

const StyledCheckbox = styled(Checkbox)({
    marginBottom: 10,
});

const tooltipStyle = {
    fontSize: '1.2rem',
};

const getPDFReportFilters = (
    selectedConditions: any,
    selectedConditionsDataTypes: any,
    refinementFilters: DataFilters,
    refinedGranularity: DataGranularity,
    dataToolConfig: DataToolConfig
) => {
    if (selectedConditions.length === 0 || !selectedConditionsDataTypes) {
        return;
    }

    // add custom condition filter indicating primary entity from condition dropdown
    const dataTypeConfig = getDataTypeConfig();

    // add selected conditions to filters section
    const conditionFilter = {
        label: dataToolConfig.customConditionLabel || _('refinement_condition'),
        value: selectedConditions
            .map((i) => _(dataTypeConfig[i.data_type].localePrefix + i.primary_entity_id))
            .join(', '),
    };

    // add data type to filters section
    const conditionTypeFilter = {
        label: _('refinement_condition_type'),
        value: selectedConditionsDataTypes.map((i) => _('data_type_' + i)).join(', '),
    };

    const primaryEntityKeys = selectedConditionsDataTypes.map(getDataTypePrimaryEntityKey);

    // filtering filters based on refined granularity
    const pdfFilters: Array<{ label: string; value: string }> = Object.entries(refinementFilters)
        .filter(([filterKey, filterValue]) => {
            // filter out primary entity keys
            const isPrimaryEntity = primaryEntityKeys.includes(filterKey);
            if (isPrimaryEntity) {
                return false;
            }

            const isFilterInRefinedGranularity = refinedGranularity.hasOwnProperty(filterKey);

            return isFilterInRefinedGranularity;
        })
        .map(([key, value]) => ({
            label: _('refinement_' + key),
            value: isTimeUnitKey(key)
                ? formatTimeUnitRangeValue(key, value)
                : formatValue(
                      key,
                      value,
                      _,
                      { metric_id: 'long_metric_' },
                      selectedConditionsDataTypes[0]
                  ),
        }));

    return [conditionFilter, conditionTypeFilter, ...pdfFilters];
};

const mapStateToProps = (state: RootState) => ({
    conditionDetailNotes: getConditionDetailNotes(state),
    ageGroupsOrder: getAgeGroupsOrder(state),
    selectedConditions: getSelectedConditions(state),
    selectedConditionsDataTypes: getSelectedConditionsDataTypes(state),
    selectedRefinementFilters: getSelectedRefinementFiltersWithFallbackToDefaultFilters(state),
    refinedGranularity: getSelectedConditionsRefinedGranularity(state),
    dataToolConfig: getDataToolConfig(state),
    mergedDataResponses: getMergedDataResponses(state),
    isForecastingData: isForecastingData(state),
    isPopulationData: isPopulationData(state),
    selectedDataTool: getSelectedDataTool(state),
    selectedDataCollection: getSelectedDataCollectionForDataTool(state),
    datasetConfig: getSelectedDatasetsConfig(state),
    visibleColumns: getDataTableVisibleColumns(state),
    sortedByColumn: getDataTableSortedByColumn(state),
    sortedDescending: getDataTableSortedDescending(state),
    dataTypes: getSelectedConditionsDataTypes(state),
});

type Props = ReturnType<typeof mapStateToProps> & {
    isVisible: boolean;
    onClose: () => void;
    data: {
        activeRefinements: { label: string; value: string }[] | null;
    };
    dataset?: PartialDataset;
    chartType: string;
    onSubmit?: ({ sectionsVisibility }) => void;
    checkIsSectionCheckboxDisabled?: (section: DataExplorerSection) => boolean;
};

let sectionVisibilityCache = {
    [FILTER_SECTION]: true,
    [CDN_SECTION]: true,
    [CHART_SECTION]: true,
    [DATA_TABLE_SECTION]: true,
};

type State = {
    sectionsVisibility;
    isGeneratingReport;
    reportTitleValue: string;
    reportDescriptionValue: string;
};

class PdfReportModal extends React.Component<Props, State> {
    GeneratePdfWorker;
    static defaultProps = {
        checkIsSectionCheckboxDisabled: () => false,
    };

    getDefaultReportTitle = () =>
        _(this.props.dataToolConfig?.pdfReportDefaultTitleKey || 'pdf_report_gbd_title');

    state: State = {
        sectionsVisibility: sectionVisibilityCache,
        isGeneratingReport: false,
        reportTitleValue: this.getDefaultReportTitle(),
        reportDescriptionValue: '',
    };

    constructor(props) {
        super(props);

        this.GeneratePdfWorker = new Worker('./generate-pdf.worker', {
            type: 'module',
        });

        this.GeneratePdfWorker.onerror = (e) => {
            console.log('Worker Error:', e);
        };

        this.GeneratePdfWorker.onmessage = ({ data }) => {
            if (data.type === 'done') {
                const blob = data.payload;
                FileSaver.saveAs(blob, this.getExportFileName());
                this.handleModalClose();
            }
        };
    }

    componentWillUnmount() {
        this.GeneratePdfWorker.terminate();
    }

    handleModalClose = () => {
        this.props.onClose();
        this.GeneratePdfWorker.postMessage({ type: 'stop' });
        this.reset();
    };

    handleCheckboxClick = (key: keyof State['sectionsVisibility']) => (ev) => {
        const { sectionsVisibility } = this.state;
        sectionVisibilityCache = { ...sectionsVisibility, [key]: !sectionsVisibility[key] };
        this.setState({ sectionsVisibility: sectionVisibilityCache });
    };

    handleDownloadClick = (ev) => {
        ev.preventDefault();

        const {
            sortedByColumn,
            sortedDescending,
            ageGroupsOrder,
            selectedConditions,
            selectedConditionsDataTypes,
            selectedRefinementFilters,
            refinedGranularity,
            mergedDataResponses,
            dataset,
            onSubmit,
            visibleColumns,
            chartType,
            dataTypes,
            isPopulationData,
        } = this.props;
        const { reportTitleValue, reportDescriptionValue } = this.state;

        const params = {
            sortedByColumn: sortedByColumn,
            sortedDescending: sortedDescending,
            ageGroupsOrder: { ...ageGroupsOrder },
        };

        const { columns, records } = sortRawTableData(params)(mergedDataResponses);
        const dataTypeIdx = columns.indexOf(DATA_TYPE_KEY);
        const { value_fields: valueFields, data_type: dataType } = dataset;
        const localizedData = {
            columns: localizeColumns(columns, dataTypes, _),
            records: records.map((record) => {
                const dataType = record[dataTypeIdx];
                const primaryEntityKey = getDataTypePrimaryEntityKey(dataType);
                const primaryEntityIndex = columns.indexOf(primaryEntityKey);
                const translatedRecord = record.map((value, idx) => {
                    const prefix = idx === primaryEntityIndex ? primaryEntityKey : columns[idx];

                    // special case to format values
                    if (valueFields.includes(columns[idx])) {
                        return formatPdfValue(value, !isPopulationData);
                    }

                    return formatValue(prefix, value, _, { metric_id: 'long_metric_' }, dataType);
                });

                return translatedRecord;
            }),
        };

        let columnsToDisplay = visibleColumns || [];

        if (dataType === AntimicrobialResistanceRecords) {
            const columnsToOmit = [YEAR_KEY, GENDER_ID_KEY];
            columnsToDisplay = columnsToDisplay.filter((key) => !columnsToOmit.includes(key));
        }

        const { columns: dataTableHeaders, records: dataTableRecords } = filterDataResponse(
            localizedData,
            columnsToDisplay.map((column) => columns.indexOf(column)),
            true
        );

        this.setState({ isGeneratingReport: true });

        const workerParams = {
            dataTableHeaders,
            dataTableRecords,
            reportTitleValue,
            reportDescriptionValue,
            pdfFilters: getPDFReportFilters(
                selectedConditions,
                selectedConditionsDataTypes,
                selectedRefinementFilters,
                refinedGranularity,
                this.props.dataToolConfig
            ),
            edpOneTimeDataUrls: null,
        };

        const screenshotEdpChart = () => {
            screenshotEdpOnetimeDataChart()
                .then((urls) => {
                    workerParams.edpOneTimeDataUrls = urls;
                    this.startWorker(workerParams);
                })
                .catch((err) => {
                    this.startWorker(workerParams);
                });
        };

        screenshotChart(chartType)
            .then(({ chartDataUrl }) => {
                workerParams.chartDataUrl = chartDataUrl;

                screenshotEdpChart();
            })
            .catch((err) => {
                screenshotEdpChart();
            });
        onSubmit && onSubmit({ sectionsVisibility: this.getAllowedSectionsVisibility() });
    };

    startWorker = ({
        pdfFilters,
        dataTableHeaders,
        dataTableRecords,
        reportTitleValue,
        reportDescriptionValue,
        chartDataUrl = null,
        edpOneTimeDataUrls = null,
    }) => {
        this.GeneratePdfWorker.postMessage({
            type: 'start',
            payload: {
                chartDataUrl,
                sectionsVisibility: this.getAllowedSectionsVisibility(),
                activeRefinements: pdfFilters,
                conditionDetailNotes: this.props.conditionDetailNotes,
                dataTableHeaders,
                dataTableRecords,
                reportTitleValue,
                reportDescriptionValue,
                defaultReportTitle: this.getDefaultReportTitle(),
                edpOneTimeDataUrls,
            },
        });
    };

    getExportFileName = (): string => {
        const {
            selectedConditions,
            selectedDataTool,
            selectedDataCollection,
            selectedRefinementFilters,
            refinedGranularity,
            datasetConfig,
        } = this.props;

        return `${getExportFilename({
            trigger: 'pdf_report',
            dataTool: selectedDataTool,
            dataCollectionName: selectedDataCollection.name,
            conditions: localizeConditions(selectedConditions),
            filters: selectedRefinementFilters,
            granularity: refinedGranularity,
            timeUnitKey: datasetConfig.time_unit_field,
        })}.pdf`;
    };

    getAllowedSectionsVisibility = () => {
        const {
            chartType,
            dataToolConfig: { sections: allowedSections },
        } = this.props;

        const { sectionsVisibility } = this.state;

        const possibleSections = [FILTER_SECTION, ...allowedSections];

        if (chartType === TYPE_DATA_TABLE_CHART) {
            possibleSections.push(DATA_TABLE_SECTION);
        }

        return pick(possibleSections, sectionsVisibility);
    };

    renderCheckBox = (section: DataExplorerSection, title: string) => {
        const sectionsVisibility = this.getAllowedSectionsVisibility();

        if (!Object.keys(sectionsVisibility).includes(section)) {
            return null;
        }

        const isDisabled = this.props.checkIsSectionCheckboxDisabled(section);

        const checkbox = (
            <StyledCheckbox
                disabled={isDisabled}
                checked={sectionsVisibility[section]}
                onChange={this.handleCheckboxClick(section)}
            >
                {title}
            </StyledCheckbox>
        );

        return isDisabled ? (
            <Tooltip
                title={_(`pdf_report_modal_${section}_section_is_disabled_tooltip`)}
                placement="top"
                trigger="hover"
                textStyle={tooltipStyle}
            >
                {checkbox}
            </Tooltip>
        ) : (
            checkbox
        );
    };

    reset = () => {
        this.setState({
            isGeneratingReport: false,
            reportTitleValue: this.getDefaultReportTitle(),
            reportDescriptionValue: '',
        });
    };

    render() {
        const { isGeneratingReport, reportDescriptionValue, reportTitleValue } = this.state;

        return (
            <Modal show={this.props.isVisible} onHide={this.handleModalClose} onEnter={this.reset}>
                <form onSubmit={this.handleDownloadClick}>
                    <ModalHeader>PDF Report</ModalHeader>
                    <ModalBody
                        css={{
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    >
                        {isGeneratingReport ? (
                            <div>
                                <div>Please wait, PDF file is being generated...</div>
                                <Loading />
                            </div>
                        ) : (
                            <React.Fragment>
                                <FormInput
                                    required
                                    counter={40}
                                    size="large"
                                    autoFocus
                                    name="title"
                                    value={reportTitleValue}
                                    onChange={(ev) => {
                                        this.setState({ reportTitleValue: ev.target.value });
                                    }}
                                    label={_.get('pdf_report_modal_title_label')}
                                />
                                <FormTextarea
                                    counter={600}
                                    size="large"
                                    rows="4"
                                    name="description"
                                    value={reportDescriptionValue}
                                    onChange={(ev) => {
                                        this.setState({ reportDescriptionValue: ev.target.value });
                                    }}
                                    label={_.get('pdf_report_modal_description_label')}
                                    placeholder={_.get('pdf_report_modal_description_placeholder')}
                                />
                                <FormGroup itemsSpacing={5}>
                                    <FieldLabel>
                                        {_.get('pdf_report_modal_controls_label')}
                                    </FieldLabel>
                                    <FlexRow>
                                        <FlexColumn basis="50%">
                                            {this.renderCheckBox(FILTER_SECTION, 'Filters')}
                                            {this.renderCheckBox(
                                                CDN_SECTION,
                                                'Condition Detail Notes'
                                            )}
                                        </FlexColumn>
                                        <FlexColumn basis="50%">
                                            {this.renderCheckBox(CHART_SECTION, 'Chart')}
                                            {this.renderCheckBox(DATA_TABLE_SECTION, 'Data Table')}
                                        </FlexColumn>
                                    </FlexRow>
                                </FormGroup>
                            </React.Fragment>
                        )}
                    </ModalBody>
                    {!isGeneratingReport && (
                        <ModalFooter>
                            <ModalButton color="secondary" onClick={this.handleModalClose}>
                                {_.get('cancel_button')}
                            </ModalButton>
                            <ModalButton type="submit">{_.get('download_button')}</ModalButton>
                        </ModalFooter>
                    )}
                </form>
            </Modal>
        );
    }
}

export default connect(mapStateToProps, null)(PdfReportModal);
