export default {
    definitions_header: 'Definitions',
    definitions_table_col1: 'Term',
    definitions_table_col2: 'Definition',
    definitions_table_content: [
        {
            title: 'Age group',
            desc: 'A population segment within a specified age range.',
        },
        {
            title: 'All-cause mortality rate',
            desc: 'The number of deaths due to all conditions by the mid-year population. ',
        },
        {
            title: 'African Union',
            desc: 'The hierarchy of 54 locations that make up the African Union.',
        },
        {
            title: 'African Region',
            desc: 'The set of locations grouped as the WHO African Region.',
        },
        {
            title: 'Cause',
            desc: 'A single disease or injury or an aggregation of diseases and injuries that causes death or disability.',
        },
        {
            title: 'Cause hierarchy',
            desc: 'The classification of diseases and injuries. The causes in GBD are classified into 4 levels. At level 1, there are three large cause groupings: communicable, maternal and neonatal conditions and nutritional deficiencies (CMNN); non-communicable diseases (NCDs); and injuries. At level 2 there are 21 disease and injury categories. The finest level of detail in causes is provided at levels 3 and 4.',
        },
        {
            title: 'Cause-specific mortality Rate',
            desc: 'The number of deaths due to cause divided by the mid-year population.',
        },
        {
            title: 'Continuous variable',
            desc: 'A population characteristic that is measured on a continuous scale (e.g., the mean level of blood pressure or body mass index).',
        },
        {
            title: 'Deaths',
            desc: 'Deaths occurring in a population during a certain time period.',
        },
        {
            title: 'Disability weight',
            desc: 'A value between 0 and 1 that indicates the relative severity of a health state and reflects the proportion of health loss in a person experiencing that health state.',
        },
        {
            title: 'Disability-adjusted life years (DALYs)',
            desc: 'The sum of years lost due to premature death (YLLs) and years lived with disability (YLDs). DALYs are also defined as years of healthy life lost.',
        },
        {
            title: 'Etiology',
            desc: 'The cause, set of causes, or manner of causation of a disease or condition. For example, diarrhea is a cause that has many etiologies, like norovirus.',
        },
        {
            title: 'Excess mortality rate',
            desc: 'The number of excess deaths divided by the number of prevalent cases. It is equivalent to the cause-specific mortality rate divided by prevalence.',
        },
        {
            title: 'Expected value (life expectancy, deaths, YLLs, YLDs, DALYs)',
            desc: 'The value of a specified measure (life expectancy, deaths, YLLs, YLDs, or DALYs) that is expected for a particular GBD location and year, given its socio-demographic development status as measured by SDI.',
        },
        {
            title: 'European Union and UK',
            desc: 'The hierarchy used for the 27 European locations in the EU and the United Kingdom.',
        },
        {
            title: 'European Region',
            desc: 'The set of locations grouped as the WHO European Region.',
        },
        {
            title: 'Eastern Mediterranean Region',
            desc: 'The set of locations grouped as the WHO Eastern Mediterranean Region.',
        },
        {
            title: 'East Asia & Pacific - WB',
            desc: 'The set of locations grouped by the Word Bank as the East Asia & Pacific Region.',
        },
        {
            title: 'Europe & Central Asia - WB',
            desc: 'The set of locations grouped by the Word Bank as the Europe & Central Asia Region.',
        },
        {
            title: 'Fatal discontinuities',
            desc: 'An increase in the death rate of more than 1 per million, resulting from conflict and terrorism, natural disasters, major accidents, or epidemics.',
        },
        {
            title: 'Healthy life expectancy, or Health-adjusted life expectancy (HALE)',
            desc: 'The number of years that a person at a given age can expect to live in good health, if the rates of all-cause mortality and all-cause disability in a specified year of interest would remain constant into the future.',
        },
        {
            title: 'Impairment',
            desc: 'Consequences (or sequelae) of multiple underlying causes for which the main source of data pertain to the sum of these across all causes. GBD currently measures 9 impairments: vision loss, hearing loss, anemia, heart failure, epilepsy, infertility, developmental intellectual disability, pelvic inflammatory disease and Guillain-Barré syndrome.',
        },
        {
            title: 'Incidence',
            desc: 'The number of new cases of a cause during a given period in a population. In the results tools it is expressed as the number of new cases in a year divided by the mid-year population size. In the analytical tool EpiViz, it is expressed as the number of new cases among those not yet affected by the cause in the population.',
        },
        {
            title: 'Life expectancy',
            desc: 'The number of years a person is expected to live at a given age assuming he or she will experience the age-specific mortality rate observed in a given year throughout his or her lifetime. For GBD, the life expectancy associated with an age group (e.g., 50- to 54-year-olds) is life expectancy at the starting year of the age group.',
        },
        {
            title: 'Life expectancy (without fatal discontinuities or HIV)',
            desc: 'Life expectancy when the impact of fatal discontinuities or the HIV epidemic is removed.',
        },
        {
            title: 'Life expectancy (without fatal discontinuities, with HIV)',
            desc: 'Life expectancy as estimated without including the impact of fatal discontinuities but including the HIV epidemic.',
        },
        {
            title: 'Location',
            desc: 'Includes country, non-sovereign region, principal administrative unit of a country (e.g., state, province), GBD region, or other custom administrative division, such as World Bank Income Level or WHO region.',
        },
        {
            title: 'Latin America & Caribbean - WB',
            desc: 'The set of locations grouped by the Word Bank as the Latin America & Caribbean Region.',
        },
        {
            title: 'Maternal mortality ratio (MMR)',
            desc: 'The number of maternal deaths per 100,000 live births, including any death of a woman while pregnant or within one year of termination of pregnancy, irrespective of the duration and site of the pregnancy, from any cause related to or aggravated by the pregnancy or its management but not from accidental or incidental causes. Ages range from 10 to 54.',
        },
        {
            title: 'Measure',
            desc: 'The indicator for which results data are produced.',
        },
        {
            title: 'Metric (Units)',
            desc: 'The unit by which a measure is expressed. E.g., number, percent, rate, etc.',
        },
        {
            title: 'Middle East & North Africa - WB',
            desc: 'The set of locations grouped by the Word Bank as the Middle East & North Africa Region.',
        },
        {
            title: 'North America',
            desc: 'The set of locations grouped by the World Bank as the North America Region.',
        },
        {
            title: 'Other cause mortality rate',
            desc: 'The number of all-cause deaths minus deaths due to a specific cause divided by the mid-year population.',
        },
        {
            title: 'Prevalence',
            desc: 'The proportion of people in a population who are a case of a disease, injury or sequela. All results in GBD refer to point prevalence.',
        },
        {
            title: 'Probability of death',
            desc: 'The probability that a person dies during an interval of two ages (e.g., between birth and age 5), if the rates of all-cause mortality in a specified year of interest would remain constant into the future.',
        },
        {
            title: 'Probability of death (without fatal discontinuities or HIV)',
            desc: 'The estimated probability of death without the impact of fatal discontinuities or the HIV epidemic.',
        },
        {
            title: 'Probability of death (without fatal discontinuities, with HIV)',
            desc: 'The estimated probability of death without the impact of fatal discontinuities but including the HIV epidemic.',
        },
        {
            title: 'Proportion',
            desc: 'The number of cases with a certain characteristic in a population (e.g., the proportion of HIV that is due to sexual transmission or the proportion of households using solid fuels for cooking).',
        },
        {
            title: 'Relative risk',
            desc: 'The ratio of the mortality rate in the diseased and the mortality rate in the non-diseased population.',
        },
        {
            title: 'Remission',
            desc: 'The number of cases that resolve or are cured per person-year of follow-up.',
        },
        {
            title: 'Risk (Risk factor)',
            desc: 'An attribute, behavior, exposure, or other factor which is causally associated with an increased (or decreased) probability of a disease or injury. If the probability decreased, the risk is a protective factor.',
        },
        {
            title: 'Region of the Americas',
            desc: 'The set of locations grouped as the WHO Region of the Americas.',
        },
        { title: 'Sex', desc: 'Male, female or both sexes combined.' },
        {
            title: 'Socio-demographic index (SDI)',
            desc: 'A compound measure of income, average years of schooling and fertility for each GBD location and year which is used as a measure of socio-demographic development.',
        },
        {
            title: 'Standardized mortality ratio',
            desc: 'The mortality rate in the diseased compared to the mortality rate in the entire population.',
        },
        {
            title: 'Summary exposure value (SEV)',
            desc: 'A measure of a population’s exposure to a risk factor that takes into account the extent of exposure by risk level and the severity of that risk’s contribution to disease burden. SEV takes the value zero when no excess risk for a population exists and the value one when the total population is at the highest level of risk; we report SEV on a scale from 0% to 100% to emphasize that it is risk-weighted prevalence.',
        },
        {
            title: 'South-East Asia Region',
            desc: 'The set of locations grouped as the WHO South-East Asia Region. ',
        },
        {
            title: 'Sub-Saharan Africa - WB',
            desc: 'The set of locations grouped by the Word Bank as the Sub-Saharan Africa Region.',
        },
        {
            title: 'South Asia - WB',
            desc: 'The set of locations grouped by the Word Bank as the South Asia Region.',
        },
        {
            title: 'Uncertainty interval',
            desc: 'A range of values that reflects the certainty of an estimate. Every estimate is calculated 1,000 times, each time sampling from distributions rather than point estimates for data inputs, data transformations and model choice. The 95th uncertainty interval is determined by the 25th and 975th value of the 1,000 values after ordering them from smallest to largest. Larger uncertainty intervals can result from limited data availability, small studies, and conflicting data. Smaller uncertainty intervals can result from extensive data availability and consistency and scope.',
        },
        { title: 'Value', desc: 'The mean value of an estimate.' },
        {
            title: 'Forecast Scenario',
            html_desc:
                "<ul><li>Reference: Global Burden of Disease (GBD) forecasts are generated by first forecasting underlying GBD risk factors and covariates (e.g. income per capita, total fertility rate, and years of educational attainment). The reference scenario represents possible future health outcomes if recent trends in these risk factors and covariates were to continue.</li><li>Better: Global Burden of Disease (GBD) forecasts are generated by first forecasting underlying GBD risk factors and covariates (e.g. income per capita, total fertility rate, and years of educational attainment). The better scenario represents possible future health outcomes if a given location were to see future changes in these risk factors and covariates equal to the 85th percentile of annualized rates of change (ARC) observed across all locations and years in the past. In cases where a given location's reference scenario was higher than the 85th percentile, the reference is used as the better health scenario</li><li>Worse: Global Burden of Disease (GBD) forecasts are generated by first forecasting underlying GBD risk factors and covariates (e.g. income per capita, total fertility rate, and years of educational attainment). The worse scenario represents possible future health outcomes if a given location were to see future changes in these risk factors and covariates equal to the 15th percentile of annualized rates of change (ARC) observed across all locations and years in the past. In cases where a given location's reference scenario was lower than the 15th percentile, the reference is used as the worse health scenario.</li></ul>",
        },
        {
            title: 'With-condition mortality rate',
            desc: 'The number of total deaths per person-year among prevalent cases. It is equivalent to the sum of other cause mortality rate and cause-specific mortality rate.',
        },
        {
            title: 'World Bank Income Levels',
            desc: 'The hierarchy of four income groupings determined by GNI (gross national income in U.S. dollars) per capita, estimates obtained from the World Bank.',
        },
        {
            title: 'World Bank High Income',
            desc: 'The set of locations with GNI per capita of $12,536 or more.',
        },
        {
            title: 'World Bank Upper Middle Income',
            desc: 'The set of locations with GNI per capita between $4,046 and $12,535.',
        },
        {
            title: 'World Bank Lower Middle Income',
            desc: 'The set of locations with GNI per capita between $1,036 and $4,045.',
        },
        {
            title: 'World Bank Low Income',
            desc: 'The set of locations with GNI per capita of $1,035 or less in 2019.',
        },
        {
            title: 'WHO regions',
            desc: 'Regional groupings for the Members of the World Health Organization, as used by the WHO.',
        },
        {
            title: 'Western Pacific Region',
            desc: 'TThe set of locations grouped as the WHO Western Pacific Region.',
        },
        {
            title: 'World Bank Regions',
            desc: 'Distributions for locations as used by the World Bank.',
        },
        {
            title: 'Years lived with disability (YLDs)',
            desc: 'Years lived with any short-term or long-term health loss weighted for severity by the disability weights.',
        },
        {
            title: 'Years of life lost (YLLs)',
            desc: 'Years of life lost due to premature mortality. YLLs are the multiplication of deaths and a standard life expectancy at the age of death. The standard life expectancy is derived from a life table that contains the lowest observed mortality rate at each age that has been observed in any population greater than 5 million.',
        },
    ],
};
