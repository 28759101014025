import React, { useEffect, useRef, useState } from 'react';
import { EChartsInstance } from 'echarts-for-react';

import Loading from '@ihme/common/components/Loading';

import { DataExportFormat } from '@portal/common/types';
import { GIF } from '@portal/common/models/file-format';
import { FlexColumn, Modal, ModalBody, ModalHeader, ProgressBar } from '@portal/common/components';
import { startGIFRecord, stopGIFRecording } from '@portal/common/components/MapChart/utils';
import { gifIcon } from '@portal/common/theme/icons';

type Props = {
    onExportData?: (format: DataExportFormat) => void;
    enableExportGIF?: boolean;
};

let CanvasCapture;
let sliderIndex = -1;
let animationStartedFromIndex = -1;
let isAnimating = false;
export default (ChartComponent) => (props: Props) => {
    const chartRef = useRef<typeof ChartComponent | null>(null);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isGifGenerating, setIsGifGenerating] = useState(false);
    const [progressValue, setProgressValue] = useState(0);

    const reset = () => {
        isAnimating = false;
        sliderIndex = -1;
        animationStartedFromIndex = -1;
        setIsModalVisible(false);
        setIsGifGenerating(false);
        setProgressValue(0);
    };

    const getInnerChartRef = () => chartRef?.current?.chartRef?.current;
    const getEChartsInstance = () => getInnerChartRef()?.chartInstance;

    const getTimelineLabels = (): number[] =>
        getEChartsInstance()?.getOption?.()?.timeline?.[0]?.labels || [];

    const getTimelineIdxsToAnimate = (): number[] =>
        getTimelineLabels()
            .map((value, index) => (!!value ? index : -1))
            .filter((i) => i !== -1);

    const setTimelineTo = (currentIndex: number) => {
        getEChartsInstance()?.on('finished', handleAnimationChange);
        getEChartsInstance()?.on('timelinechanged', handleAnimationChange);

        getEChartsInstance()?.dispatchAction({
            type: 'timelineChange',
            currentIndex,
            context: 'gif_animation',
        });
    };

    const startAnimation = () => {
        setIsModalVisible(true);
        isAnimating = true;
        setTimelineTo(0);
    };

    const snapshot = () => {
        if (sliderIndex === -1 || !isAnimating) {
            return;
        }

        const sliderIdxs = getTimelineIdxsToAnimate();
        const isFirstFrame = sliderIndex === 0;
        const isLastFrame = sliderIdxs.indexOf(sliderIndex) === sliderIdxs.length - 1;

        isFirstFrame &&
            startGIFRecord(
                CanvasCapture,
                {
                    onExportProgress: (v) => {
                        setProgressValue(v);
                    },
                    onExportFinish: () => {
                        props.onExportData?.(GIF);
                        reset();
                    },
                    onError: (error) => {
                        console.log(error);
                        reset();
                    },
                },
                chartRef?.current?.getSaveFilename?.(getInnerChartRef()?.getRenderProps?.())
            );

        if (!CanvasCapture.isRecording()) {
            return;
        } else {
            CanvasCapture.recordFrame();
        }

        let nextSliderIndex: number;
        if (isLastFrame) {
            stopGIFRecording(CanvasCapture);
            isAnimating = false;
            setIsGifGenerating(true);
            nextSliderIndex = animationStartedFromIndex;
        } else {
            const indexOfCurrentValue = sliderIdxs.indexOf(sliderIndex);
            nextSliderIndex = sliderIdxs[indexOfCurrentValue + 1];
        }

        setTimelineTo(nextSliderIndex);
    };

    const handleAnimationChange = () => {
        if (!isAnimating) {
            return;
        }
        const currentIndex = getEChartsInstance()?.getOption?.()?.timeline?.[0]?.currentIndex || 0;

        if (sliderIndex !== currentIndex) {
            sliderIndex = currentIndex;

            setTimeout(snapshot, 1000);
        }
    };

    const handleSaveAsGifClick = (model, instance: EChartsInstance) => {
        // @todo: add params for the exported filename
        const { currentIndex } = instance.getOption().timeline?.[0];
        animationStartedFromIndex = currentIndex;

        startAnimation();
    };

    const handleModalClose = (ev) => {
        stopGIFRecording(CanvasCapture);
        isAnimating = false;
        setTimelineTo(animationStartedFromIndex);
        reset();
    };

    useEffect(() => {
        // workaround to avoid high CPU usage
        const CC = require('canvas-capture');
        CanvasCapture = CC.CanvasCapture;
    }, []);

    return (
        <>
            <ChartComponent
                ref={chartRef}
                {...props}
                saveAsGIF={{
                    visible: true,
                    enabled: props.enableExportGIF,
                    icon: gifIcon,
                    onClick: handleSaveAsGifClick,
                }}
            />
            <Modal show={isModalVisible} onClose={handleModalClose} onHide={handleModalClose}>
                <ModalHeader style={{ zIndex: 100, position: 'relative' }} closeButton />
                <ModalBody style={{ marginTop: -30 }}>
                    <FlexColumn itemsSpacing={20}>
                        <div>Please wait, GIF animation is being generated...</div>
                        <ProgressBar
                            now={Math.ceil(progressValue * 100)}
                            label={`${Math.ceil(progressValue * 100)}%`}
                            inProgress={isGifGenerating}
                        />
                        {isAnimating && <Loading key={isAnimating} />}
                    </FlexColumn>
                </ModalBody>
            </Modal>
        </>
    );
};
