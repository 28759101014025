import React, { createRef } from 'react';
import { RootState } from 'MyTypes';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import isEqual from 'fast-deep-equal';
import { uniq } from 'lodash/fp';

import { H4, SectionBody, SectionHeader } from '@portal/common/components';
import { DataExportFormat, DataKey, DataResponse } from '@portal/common/types';
import { styled } from '@portal/common/theme';
import { localizeConditions } from '@portal/common/utility/chart-data-helpers';
import { getExportFilename } from '@portal/common/utility/get-export-filename';
import { DATA_TABLE_SECTION } from '@portal/common/models/data-explorer-section';
import { isCsvExportEnabled, isExcelExportEnabled } from '@portal/common/models/organization';
import { FORECAST_SCENARIO_ID_KEY } from '@portal/common/models/data-key';
import { CSV, XLS } from '@portal/common/models/file-format';

import ExportApiRequestButton from '../../components/ExportApiRequestButton';
import ExportCSVButton from '../../components/ExportCSVButton';
import ExportExcelButton from '../../components/ExportExcelButton';
import _ from '../../locale';
import { queryDataRendered, updateDataTableDisplayState } from '../../store/data-explorer/actions';
import {
    getAgeGroupsOrder,
    getDataRecordsAmount,
    getDataResponsesByType,
    getDataTableSortedByColumn,
    getDataTableSortedDescending,
    getDataTableVisibleColumns,
    getDataToolConfig,
    getMergedDataResponses,
    getSelectedConditions,
    getSelectedConditionsDataTypes,
    getSelectedConditionsRefinedGranularity,
    getSelectedDataProject,
    getSelectedDatasetsConfig,
    getSelectedRefinementFiltersWithFallbackToDefaultFilters,
    isDataLoading,
    isForecastingData,
    isPopulationData,
} from '../../store/data-explorer/selectors';
import { getOrganization, getSelectedDataTool, getSessionAccount } from '../../store/root-reducer';
import { getSelectedDataCollectionForDataTool } from '../../store/user-settings/selectors';
import { isValidDataResponse } from '../../models/data-response';
import locale from './locale';

import CustomDataTable from './CustomDataTable';
import DataResponseColumnsFilter from '../DataResponseColumnsFilter';

const ExportsGroup = styled.div({
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    '>*': {
        marginLeft: 20,
    },
});

const mapStateToProps = (state: RootState) => ({
    ageGroupsOrder: getAgeGroupsOrder(state),
    dataResponsesByType: getDataResponsesByType(state),
    mergedDataResponses: getMergedDataResponses(state),
    selectedDataCollection: getSelectedDataCollectionForDataTool(state),
    selectedConditionsDataTypes: getSelectedConditionsDataTypes(state),
    selectedRefinementFilters: getSelectedRefinementFiltersWithFallbackToDefaultFilters(state),
    selectedConditions: getSelectedConditions(state),
    organization: getOrganization(state),
    organizationMember: getSessionAccount(state),
    isLoadingData: isDataLoading(state),
    isForecastingData: isForecastingData(state),
    isPopulationData: isPopulationData(state),
    dataToolConfig: getDataToolConfig(state),
    selectedDataTool: getSelectedDataTool(state),
    granularity: getSelectedConditionsRefinedGranularity(state),
    datasetConfig: getSelectedDatasetsConfig(state),
    visibleColumns: getDataTableVisibleColumns(state),
    sortedByColumn: getDataTableSortedByColumn(state),
    sortedDescending: getDataTableSortedDescending(state),
    dataProject: getSelectedDataProject(state),
    dataRecordsAmount: getDataRecordsAmount(state),
});

const dispatchProps = {
    queryDataRendered: queryDataRendered,
    updateDataTableDisplayState: updateDataTableDisplayState,
};

type Props = RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & {
        onExportData?: (format: DataExportFormat) => void;
        hideTitle?: boolean;
    };

type State = {
    dataResponse: DataResponse | null;
};

class DataTableSection extends React.Component<Props, State> {
    state: State = {
        dataResponse: null,
    };

    dataTableRef = createRef<typeof CustomDataTable>();

    componentDidMount(): void {
        this.handleDataResponseUpdate();
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: any
    ): void {
        const {
            visibleColumns,
            mergedDataResponses,
            updateDataTableDisplayState,
            isForecastingData,
        } = this.props;

        if (!isEqual(mergedDataResponses, prevProps.mergedDataResponses)) {
            this.handleDataResponseUpdate();
        }

        if (isForecastingData && !prevProps.isForecastingData && visibleColumns) {
            updateDataTableDisplayState({
                visibleColumns: uniq([...visibleColumns, FORECAST_SCENARIO_ID_KEY]),
            });
        }
    }

    handleDataResponseUpdate = () => {
        const {
            mergedDataResponses: dataResponse,
            updateDataTableDisplayState,
            visibleColumns,
            dataToolConfig,
        } = this.props;

        const isVisibleColumnsInitializationRequired =
            visibleColumns == null && isValidDataResponse(dataResponse);
        if (isVisibleColumnsInitializationRequired) {
            // visible columns are not initialized yet, let's select all available options
            updateDataTableDisplayState({
                visibleColumns: this.getAvailableColumns(dataResponse).filter(
                    (key) => !(dataToolConfig.dataTableHiddenByDefaultColumns || []).includes(key)
                ),
            });
        }
        this.setState({ dataResponse });
    };

    getData = () => {
        if (this.dataTableRef.current) {
            const { columns, records } = this.dataTableRef.current.getFullData();
            return { headers: columns, records };
        }

        return {};
    };

    getFilename = (isShorten: boolean = false): string => {
        const {
            selectedConditions,
            selectedDataTool,
            selectedDataCollection,
            selectedRefinementFilters,
            granularity,
            datasetConfig,
        } = this.props;

        return getExportFilename(
            {
                trigger: DATA_TABLE_SECTION,
                dataTool: selectedDataTool,
                dataCollectionName: selectedDataCollection?.name || '',
                conditions: localizeConditions(selectedConditions),
                filters: selectedRefinementFilters,
                granularity,
                timeUnitKey: datasetConfig.time_unit_field,
            },
            isShorten
        );
    };

    getAvailableColumns = (dataResponse: DataResponse): DataKey[] => {
        const { dataToolConfig } = this.props;
        const columnsToExclude = dataToolConfig.dataTableExcludedColumns || [];
        return (dataResponse?.columns || []).filter((column) => !columnsToExclude.includes(column));
    };

    handleExportClick = (format: DataExportFormat) => {
        const { onExportData } = this.props;
        onExportData && onExportData(format);
    };

    handleColumnsChange = (visibleColumns: DataKey[]) => {
        this.props.updateDataTableDisplayState({ visibleColumns });
    };

    handleDataTableSortingChange = ({ sortedByColumn, sortedDescending }) => {
        this.props.updateDataTableDisplayState({ sortedByColumn, sortedDescending });
    };

    render() {
        const {
            organization,
            organizationMember,
            selectedConditions,
            ageGroupsOrder,
            selectedDataCollection,
            selectedConditionsDataTypes,
            selectedRefinementFilters,
            isLoadingData,
            isForecastingData,
            isPopulationData,
            queryDataRendered,
            style,
            hideTitle,
            visibleColumns,
            sortedByColumn,
            sortedDescending,
            dataResponsesByType,
            dataToolConfig,
            dataRecordsAmount,
        } = this.props;

        const { dataResponse } = this.state;

        if (
            !(
                organization &&
                organizationMember &&
                selectedDataCollection &&
                selectedConditionsDataTypes &&
                dataResponsesByType
            )
        ) {
            return null;
        }

        const filteredDataset = selectedDataCollection.datasets.filter((i) =>
            selectedConditionsDataTypes.includes(i.data_type)
        )[0];

        const isExportAPIAllowed =
            organization?.['has_programmatic_access'] && organizationMember.has_programmatic_access;

        return (
            <SectionBody style={style}>
                <SectionHeader>
                    {!hideTitle && <H4>{_(locale.dataTableSectionTitle)}</H4>}
                    <ExportsGroup>
                        <DataResponseColumnsFilter
                            disabled={isLoadingData}
                            options={this.getAvailableColumns(dataResponse)}
                            value={visibleColumns || []}
                            onChange={this.handleColumnsChange}
                            disabledByDefaultOptions={
                                dataToolConfig.dataTableHiddenByDefaultColumns || []
                            }
                            customConditionLabel={dataToolConfig.customConditionLabel}
                        />
                        {isExportAPIAllowed && (
                            <ExportApiRequestButton
                                disabled={isLoadingData}
                                organizationId={organization.id}
                                selectedConditions={selectedConditions}
                                selectedConditionsDataTypes={selectedConditionsDataTypes}
                                selectedDataCollection={selectedDataCollection}
                                selectedRefinementFilters={selectedRefinementFilters}
                            />
                        )}
                        <ExportExcelButton
                            disabled={isLoadingData || !isExcelExportEnabled(organization)}
                            getData={this.getData}
                            filename={this.getFilename()}
                            onClick={() => this.handleExportClick(XLS)}
                            dataRecordsAmount={dataRecordsAmount}
                        />
                        <ExportCSVButton
                            disabled={isLoadingData || !isCsvExportEnabled(organization)}
                            getData={this.getData}
                            filename={this.getFilename()}
                            onClick={() => this.handleExportClick(CSV)}
                            dataRecordsAmount={dataRecordsAmount}
                        />
                    </ExportsGroup>
                </SectionHeader>

                <CustomDataTable
                    ref={this.dataTableRef}
                    isLoadingData={isLoadingData}
                    isForecastingData={isForecastingData}
                    onDisplayChange={this.handleDataTableSortingChange}
                    onDataRendered={() => void queryDataRendered(Date.now())}
                    valueFields={filteredDataset?.value_fields || []}
                    ageGroupsOrder={ageGroupsOrder}
                    selectedRefinementFilters={selectedRefinementFilters}
                    selectedConditions={selectedConditions}
                    dataResponse={dataResponse}
                    dataTypes={selectedConditionsDataTypes}
                    visibleColumns={visibleColumns}
                    sortedByColumn={sortedByColumn}
                    sortedDescending={sortedDescending}
                    showDecimals={!isPopulationData}
                />
            </SectionBody>
        );
    }
}

export default compose(withRouter, connect(mapStateToProps, dispatchProps))(DataTableSection);
