import { convertArrayToCSV } from 'convert-array-to-csv';
import FileSaver from 'file-saver';
import React from 'react';

import { SvgIcon, Button, Tooltip } from '@portal/common/components';
import { styled } from '@portal/common/theme';

import config from '../../config';
import _ from '../../locale';

const StyledIcon = styled(SvgIcon)(({ theme, disabled }) => ({
    marginRight: 10,
    ...(disabled && {
        pointerEvents: 'none',
        color: theme.color.disabled,
        fill: theme.color.disabled,
        cursor: 'default',
    }),
}));

const StyledButton = styled(Button)<{ disabled: boolean }>(({ theme, disabled }) => ({
    minWidth: 'auto',
    background: theme.color.tertiary1,
    ':hover': {
        color: theme.color.green1,
        background: theme.color.tertiary1,
    },
    ':active': {
        color: theme.color.green1,
        background: theme.color.tertiary1,
    },
    ...(disabled && {
        pointerEvents: 'none',
        color: theme.color.disabled,
        cursor: 'default',
    }),
}));

type Props = {
    getData: () => {
        headers: string[];
        records: string[][];
    };
    filename: string;
    disabled?: boolean;
    onClick?: () => {};
    dataRecordsAmount?: number;
};

class ExportCSVButton extends React.Component<Props> {
    onSave = () => {
        const { filename, getData, onClick } = this.props;
        const { records, headers } = getData();

        const data = convertArrayToCSV(records, { header: headers });

        const file = new Blob([data], { type: 'text/plain' });

        FileSaver.saveAs(file, `${filename}.csv`);
        onClick && onClick();
    };

    render() {
        const { disabled, dataRecordsAmount } = this.props;

        const canHandleExport = dataRecordsAmount && dataRecordsAmount < config.CSVRowsExportLimit;

        const buttonComponent = (
            <StyledButton
                color="tertiary"
                disabled={disabled || !canHandleExport}
                onClick={this.onSave}
            >
                <StyledIcon
                    color={(theme) => theme.color.green1}
                    src="/icons/ic-file.svg"
                    size="large"
                    disabled={disabled || !canHandleExport}
                />{' '}
                {_('export_csv_button')}
            </StyledButton>
        );

        return canHandleExport ? (
            buttonComponent
        ) : (
            <Tooltip placement="bottom" title={_('export_csv_button_disabled_tooltip')}>
                {buttonComponent}
            </Tooltip>
        );
    }
}

export default ExportCSVButton;
