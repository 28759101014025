export default {
    title: 'team_scene_title',
    icon: 'member_icon_table_column',
    name: 'table_header_name',
    actions: 'table_header_actions',
    memberTitle: 'table_header_job_title',
    organizationRole: 'table_header_organization_role',
    lastAccess: 'table_header_last_access',
    member: 'role_member',
    manager: 'role_manager',
    invite_members: 'staff_invite_members',
    bulk_invite_members: 'team_bulk_invite_members',
    deleteModalTitle: 'team_delete_modal_title',
    deleteModalMessage: 'delete_member_modal_message',
};
