import { DataCondition, PartialDataset } from '@portal/common/types';

export const getDatasetConditions = (dataset: PartialDataset): DataCondition[] => {
    const { data_type, granularity, primary_entity_id_filter } = dataset;

    return granularity && granularity[primary_entity_id_filter]
        ? (granularity[primary_entity_id_filter] || []).map((primary_entity_id) => ({
              data_type,
              primary_entity_id,
          }))
        : [];
};
