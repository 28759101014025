import { RootEpic } from 'MyTypes';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { from, of } from 'rxjs';

import {
    deleteCookie,
    REDIRECTION_SESSION,
    setTopLevelDomainCookie,
} from '@portal/common/utility/cookies';

import { getOrganizationAsync } from './actions';
import { getLocalStorageItem, removeLocalStorageItem } from '../../services/localStorageService';

const redirectToSubdomain = (res) => {
    if (
        process.env.ENV !== 'local' &&
        res.domain_prefix &&
        !window.location.href.includes(res.domain_prefix)
    ) {
        const domain = window.location.hostname.split(/\./).slice(-2).join('.');

        setTopLevelDomainCookie({
            name: REDIRECTION_SESSION,
            value: JSON.stringify(getLocalStorageItem('redux')),
        });
        removeLocalStorageItem('redux');
        window.location.href = `https://${res.domain_prefix}.${domain}`;
    } else {
        deleteCookie(REDIRECTION_SESSION);
    }
};

export const loadOrganization: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getOrganizationAsync.request])),
        switchMap((action) =>
            from(api.organization.getOrganization(action.payload.organizationId)).pipe(
                map((res) => {
                    redirectToSubdomain(res);
                    return getOrganizationAsync.success(res);
                }),
                catchError((message: string) => of(getOrganizationAsync.failure(message)))
            )
        )
    );
