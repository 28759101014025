import React, { useCallback } from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, withFormik } from 'formik';

import { Alert, EditModal, FlexColumn, FormikInput } from '@portal/common/components';
import { ResourceList } from '@portal/common/types';

import api from '../../api';
import _ from '../../locale';
import { createResourceList, updateResourceList } from '../../store/resource-lists/actions';
import { getOrganizationId, getSessionAccountId } from '../../store/root-reducer';

const mapNameToLabel = (name: string) => _(`resource_list_field_label_${name}`);

const mapStateToProps = (state: RootState) => ({
    organizationId: getOrganizationId(state),
    memberId: getSessionAccountId(state),
});

const dispatchProps = {
    createResourceList: createResourceList,
    updateResourceList: updateResourceList,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & {
        className?: string;
        style?: React.CSSProperties;
        item?: ResourceList;
        isVisible?: boolean;
        onClose?: () => void;
        onSuccess?: () => void;
        children?: (renderProps: RenderProps) => React.ReactNode;
    };

type FormValues = Pick<ResourceList, 'name' | 'description'> & {};

type RenderProps = {
    closeModal: () => void;
    openModal: () => void;
};

const EditResourceListModal = withFormik<Props, FormValues>({
    enableReinitialize: true,
    // initialize values
    mapPropsToValues: ({ item: { name, description } = {} }) => ({
        name: name || '',
        description: description || '',
    }),
    handleSubmit: (values, form) => {
        const {
            organizationId,
            memberId,
            item,
            createResourceList,
            updateResourceList,
            onSuccess,
        } = form.props;

        let onSubmit;

        const isAddingNew = item == null;

        if (isAddingNew) {
            onSubmit = () =>
                api.organizationResourceList
                    .createOrganizationResourceList({
                        organizationId,
                        item: values,
                    })
                    .then((res) => {
                        createResourceList(res);
                    });
        } else {
            onSubmit = () =>
                api.organizationResourceList
                    .updateOrganizationResourceList({
                        organizationId,
                        listId: item.id!,
                        item: values,
                    })
                    .then((res) => {
                        updateResourceList(res);
                    });
        }

        return onSubmit()
            .then((list: ResourceList) => {
                form.setStatus({ isVisible: false });
                form.resetForm();
                onSuccess && onSuccess();
            })
            .catch((err) => {
                form.setStatus({ error: err.message, isVisible: true });
                if (err.fields) {
                    form.setErrors(err.fields);
                }
            })
            .finally(() => {
                form.setSubmitting(false);
            });
    },
})(
    ({
        className,
        style,
        children,
        isVisible,
        onClose,
        isSubmitting,
        dirty,
        status = { isVisible: false },
        item,
        handleSubmit,
        setStatus,
        resetForm,
    }) => {
        const isNew = item == null;

        const renderProps: RenderProps = {
            closeModal: () => {
                setStatus({ isVisible: false });
            },
            openModal: () => {
                setStatus({ isVisible: true });
            },
        };

        const handleCloseModal = useCallback(() => {
            onClose != null ? onClose() : renderProps.closeModal();
            resetForm();
        }, [onClose, resetForm]);

        return (
            <div {...{ className, style }}>
                {children && children(renderProps)}
                <EditModal
                    title={_.get(isNew ? 'lists_add_new_modal_title' : 'lists_edit_modal_title')}
                    isVisible={isVisible != null ? isVisible : status.isVisible}
                    isSubmitting={isSubmitting}
                    isSaveDisabled={!dirty}
                    onClose={handleCloseModal}
                    onSubmit={handleSubmit}
                >
                    {status && status.error && <Alert color="error">{status.error}</Alert>}

                    <FlexColumn>
                        <Field
                            name="name"
                            component={FormikInput}
                            mapNameToLabel={mapNameToLabel}
                            type="text"
                            required
                            autoFocus
                        />
                        <Field
                            name="description"
                            component={FormikInput}
                            mapNameToLabel={mapNameToLabel}
                            type="text"
                        />
                    </FlexColumn>
                </EditModal>
            </div>
        );
    }
);

export default compose(connect(mapStateToProps, dispatchProps))(EditResourceListModal);
