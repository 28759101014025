import React from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';

import {
    NATIONAL_LEVEL,
    getLocationsForLevel,
    updateEchartsMapVisibleLocations,
} from '@portal/common/components';
import {
    DataCondition,
    DataExportFormat,
    DataFilters,
    DataGranularity,
    DataGranularityKey,
    DataResponse,
    DataType,
    TimeUnitKey,
} from '@portal/common/types';

import {
    getDataToolConfig,
    getSelectedConditionsRefinedGranularity,
} from '../../../../store/data-explorer/selectors';
import config from '../../../../config';

import ChartRefinementFiltersControls from '../ChartRefinementFiltersControls';
import Container from './Container';

const mapStateToProps = (state: RootState) => ({
    entityHierarchies: state.entityHierarchies,
    refinedGranularity: getSelectedConditionsRefinedGranularity(state),
    dataToolConfig: getDataToolConfig(state),
});

type Props = ReturnType<typeof mapStateToProps> & {
    mergedChartDataResponses: DataResponse | null;
    isForecastingData: boolean;
    initialTimeUnitValue: number | string;
    onDataLoad?: () => void;
    selectedConditionsDataTypes: DataType[];
    multipleFilterKeys: DataGranularityKey[];
    selectedRefinementFilters: DataFilters;

    isLoadingChartData: boolean;
    chartFilters: DataFilters;
    filtersValues: DataFilters; // @todo: rename to 'selectedRefinementFilters', for example
    chartDataResponsesByType: null | Record<string, DataResponse>;
    selectedConditionsDataTypesGranularity: DataGranularity;

    timeUnitKey: TimeUnitKey;
    selectedConditions: DataCondition[];
    onExportData?: (format: DataExportFormat) => void;
    enableExportPNG: boolean;
    enableExportGIF: boolean;
    numberFormatter: (value: number | string) => string;
};

type State = {
    echartsMapKey: string;
};

class ScatterMapChart extends React.PureComponent<Props, State> {
    state: State = {
        echartsMapKey: 'world',
    };

    componentDidMount(): void {
        const { entityHierarchies } = this.props;

        if (
            entityHierarchies &&
            entityHierarchies.locations &&
            entityHierarchies.locations.length
        ) {
            this.initializeMap();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { entityHierarchies } = this.props;

        if (
            !(
                prevProps.entityHierarchies &&
                prevProps.entityHierarchies.locations &&
                prevProps.entityHierarchies.locations.length
            ) &&
            entityHierarchies
        ) {
            this.initializeMap();
        }
    }

    initializeMap = () => {
        const { entityHierarchies } = this.props;

        const mapType = config.globalLocationId;
        const locationIds = getLocationsForLevel(
            NATIONAL_LEVEL,
            mapType,
            config.globalMapCountryLocationsIds,
            entityHierarchies.locations
        );

        const [echartsMapKey] = updateEchartsMapVisibleLocations(locationIds, mapType);

        this.setState({ echartsMapKey });
    };

    preprocessFiltersChange = (filters: DataFilters) => {
        const { chartFilters } = this.props;
        return { ...chartFilters, ...filters };
    };

    render() {
        const {
            initialTimeUnitValue,
            selectedConditionsDataTypes,
            selectedConditionsDataTypesGranularity,
            isForecastingData,
            isLoadingChartData,
            filtersValues,
            selectedConditions,
            chartFilters,
            selectedDataTool,
            selectedDataCollection,
            timeUnitKey,
            entityHierarchies,
            mergedChartDataResponses,
            dataToolConfig,
            onExportData,
            enableExportPNG,
            enableExportGIF,
            numberFormatter,
        } = this.props;
        const { echartsMapKey } = this.state;

        if (!entityHierarchies) {
            return null;
        }

        return (
            <>
                <ChartRefinementFiltersControls
                    preprocessFiltersChange={this.preprocessFiltersChange}
                />
                {echartsMapKey !== 'world' && (
                    <Container
                        key={initialTimeUnitValue}
                        dataToolConfig={dataToolConfig}
                        initialTimeUnitValue={initialTimeUnitValue}
                        isLoadingData={isLoadingChartData}
                        isForecastingData={isForecastingData}
                        filtersValues={filtersValues}
                        dataResponse={mergedChartDataResponses}
                        echartsMapKey={echartsMapKey}
                        chartFilters={chartFilters}
                        timeUnitKey={timeUnitKey}
                        selectedConditions={selectedConditions}
                        selectedConditionsDataTypes={selectedConditionsDataTypes}
                        selectedConditionsDataTypesGranularity={
                            selectedConditionsDataTypesGranularity
                        }
                        selectedDataTool={selectedDataTool}
                        selectedDataCollection={selectedDataCollection}
                        onExportData={onExportData}
                        enableExportPNG={enableExportPNG}
                        enableExportGIF={enableExportGIF}
                        numberFormatter={numberFormatter}
                    />
                )}
            </>
        );
    }
}

export default connect(mapStateToProps, null)(ScatterMapChart);
