import copy from 'clipboard-copy';
import query from 'query-string';
import React from 'react';

import { Button, SvgIcon } from '@portal/common/components';
import { styled } from '@portal/common/theme';

import config from '../../config';
import _ from '../../locale';
import {
    getMergedDataCollectionGranularity,
    getFiltersWithConditions,
} from '../../utility/data-loader';

const StyledIcon = styled(SvgIcon)<{ disabled: boolean }>(({ theme, disabled }) => ({
    marginRight: 10,
    ...(disabled && {
        pointerEvents: 'none',
        color: theme.color.disabled,
        fill: theme.color.disabled,
        cursor: 'default',
    }),
}));

const StyledButton = styled(Button)<{ disabled: boolean }>(({ theme, disabled }) => ({
    minWidth: 150,
    justifyContent: 'flex-start',
    background: theme.color.tertiary1,
    ':hover': {
        color: theme.color.green1,
        background: theme.color.tertiary1,
    },
    ':active': {
        color: theme.color.green1,
        background: theme.color.tertiary1,
    },
    ...(disabled && {
        pointerEvents: 'none',
        color: theme.color.disabled,
        cursor: 'default',
    }),
}));

type Props = {
    disabled?: boolean;
    selectedConditions;
    selectedConditionsDataTypes;
    selectedDataCollection;
    selectedRefinementFilters;
};

type State = {
    copied: boolean;
};

export default class ExportApiRequestButton extends React.Component<Props, State> {
    state: State = {
        copied: false,
    };

    onSave = () => {
        const {
            selectedDataCollection,
            selectedConditions,
            selectedConditionsDataTypes,
            selectedRefinementFilters: filters,
        } = this.props;

        if (selectedConditionsDataTypes) {
            const resourceId = selectedDataCollection.id;
            const dataType = selectedConditionsDataTypes[0];
            const granularity = getMergedDataCollectionGranularity(
                selectedDataCollection,
                selectedConditionsDataTypes
            );

            const url =
                config.apiUrl +
                `/my-organization/data-collection-resources/${resourceId}/datasets/${dataType}/data?` +
                query.stringify(
                    getFiltersWithConditions(selectedConditions, filters, Object.keys(granularity)),
                    { arrayFormat: 'comma' }
                );

            this.setState({ copied: true }, () => {
                copy(url);
                // copied text animation timeout
                setTimeout(() => void this.setState({ copied: false }), 3000);
            });
        }
    };

    render() {
        const { disabled } = this.props;
        const { copied } = this.state;

        const label = _.get(
            copied
                ? 'data_explorer_copied_api_request_label'
                : 'data_explorer_copy_api_request_label'
        );

        return (
            <StyledButton color="tertiary" disabled={!!disabled} onClick={this.onSave}>
                <StyledIcon
                    color={(theme) => theme.color.green1}
                    src="/icons/ic-http.svg"
                    size="large"
                    disabled={!!disabled}
                />{' '}
                <span
                    style={{
                        opacity: copied ? 0 : 1,
                        transition: copied ? 'opacity 0.5s linear 2.5s' : 'none',
                    }}
                >
                    {label}
                </span>
            </StyledButton>
        );
    }
}
