// TODO: this component needs to be completely rewritten from scratch using ChartHierarchicalDropdown as a base
import { RootState } from 'MyTypes';
import * as React from 'react';
import { createRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTheme } from 'emotion-theming';
import { find, flatten, forEach, groupBy, isEqual, keyBy, remove } from 'lodash';
import { difference, sortBy, union, uniqBy } from 'lodash/fp';

import { withStorage } from '@ihme/common/packages/storage';
import { WithStorageProps } from '@ihme/common/packages/storage/with-storage';
import { Dropdown, FieldLabel, FlexRow, FormGroup, SvgIcon } from '@portal/common/components';
import { DropdownInnerState, OptionGetters } from '@portal/common/components/Dropdown';
import { getDataTypeConfig } from '@portal/common/models/data-type-config';
import {
    AllDataTypes,
    AntimicrobialResistanceRecords,
    CauseAssociatedComorbidityStudyCoefficients,
    CauseAssociatedCoprevalenceRecords,
    CauseCoprevalenceRecords,
    CauseOutcome,
    CovariateRecords,
    Covid19Records,
    EtiologyRecords,
    getDataTypeEntityHierarchyKey,
    HealthThreatsMetricsRecords,
    ImpairmentRecords,
    InjuryRecords,
    LifeExpectancyRecords,
    MultipleMyelomaOutputShell,
    MultipleMyelomaPopulation,
    MultipleMyelomaSurvivalResults,
    PopulationRecords,
    ProjectedComorbidityCausesOutcomeRecords,
    RaceEthnicityEyeConditionsRecords,
    RiskAttribution,
    RiskExposureRecords,
    RiskPrevalence,
    SequelaOutcome,
    SevRecords,
} from '@portal/common/models/data-type';
import { styled } from '@portal/common/theme';
import {
    DataCondition,
    DataType,
    DropdownOption,
    DropdownOptionGroup,
    Entity,
    PartialDataset,
} from '@portal/common/types';

import _ from '../../locale';
import {
    getSelectedConditions,
    getSelectedConditionsCompatibleDataTypes,
    getSelectedConditionsDataTypes,
} from '../../store/data-explorer/selectors';
import {
    getCauseICDCodesById,
    getCausesById,
    getEntityHierarchiesLoaded,
    getEtiologiesById,
    getImpairmentsById,
    getInjuriesById,
    getLocationsById,
    getRisksById,
    getSequelasById,
    getSevsById,
} from '../../store/entity-hierarchies/selectors';
import { getSelectedDataCollectionForDataTool } from '../../store/user-settings/selectors';
import { getDataCollectionDataTypes } from '../../utility/data-loader';
import locale from './locale';

/**
 * Types
 */

const mapStateToProps = (state: RootState, props: { selectedDataCollection?: any }) => ({
    selectedDataCollection:
        props.selectedDataCollection || getSelectedDataCollectionForDataTool(state),
    selectedConditionsDataTypes: getSelectedConditionsDataTypes(state),
    selectedConditionsCompatibleDataTypes: getSelectedConditionsCompatibleDataTypes(state),
    entityHierarchies: state.entityHierarchies,
    entityHierarchiesLoaded: getEntityHierarchiesLoaded(state),
    causesById: getCausesById(state),
    etiologiesById: getEtiologiesById(state),
    impairmentsById: getImpairmentsById(state),
    injuriesById: getInjuriesById(state),
    locationsById: getLocationsById(state),
    risksById: getRisksById(state),
    sequelasById: getSequelasById(state),
    sevsById: getSevsById(state),
    causeICDCodesById: getCauseICDCodesById(state),
    selectedConditions: getSelectedConditions(state),
});

const dispatchProps = {};

export type ConditionDropdownOption = {
    value: string;
    label: string;
    disabled: boolean;
    hierarchyDataType: DataType;
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    WithStorageProps & {
        label?: string;
        value?: DataCondition[];
        datasets: PartialDataset[];
        onChange: (conditions: DataCondition[]) => void;
        onMultiSelect: (conditions: DataCondition[]) => void;
        onRemove: (conditions: ConditionDropdownOption['value'][]) => void;
        onClose: () => void;
        style: React.CSSProperties;
        showHint: boolean;
        isMulti?: boolean;
        isSearchable?: boolean;
        isClearable?: boolean;
        placeholder?: string;
        renderMenuHeader?: any;
    };

type State = {
    datasetsByType: Record<string, PartialDataset>;
    optionGroups: DropdownOptionGroup<ConditionDropdownOption>[];
    activeFilters: string[];
    dropdownInputValue: string;
    hierarchy: [];
};

/**
 * Utils
 */

// changes 0, 0_0, 0_0_0... into readable ordered list prefix A, A.1, A.1.1...
const FIRST_PREFIX_LETTER = 0;
const NUMERIC_SEARCH_REGEXP = /^\d+(\.\d+)*(\.?\s*)?$/;

const levelToLetter = (level) => String.fromCharCode(65 + parseInt(level)).toUpperCase();

const levelToNumber = (level) => parseInt(level) + 1;

const formatHierarchyToLocalePrefix = (
    hierarchyLevelKey,
    showPrefix = true,
    useLetterPrefix = false
) => {
    const levels = hierarchyLevelKey.split('_').slice(1);
    const padSize = levels.length * 3;
    const prefix = showPrefix
        ? levels
              .map((level, i, arr) =>
                  i === FIRST_PREFIX_LETTER && useLetterPrefix
                      ? levelToLetter(level)
                      : levelToNumber(level)
              )
              .join('.')
        : '';

    return ' '.repeat(padSize) + `${prefix} `;
};

// TODO: not needed  get rid of it, use objects as dropdown value instead
const encodeValue = (value: DataCondition): string =>
    `${value.data_type}:${value.primary_entity_id}`;

// TODO: not needed  get rid of it, use objects as dropdown value instead
const decodeValue = (value: string | null): DataCondition => {
    if (value == null) {
        throw Error('value cannot be null');
    }

    const [dataTypeString, idString] = value.split(':');
    const data_type = dataTypeString as DataType;
    const primary_entity_id = parseInt(idString);

    return { data_type, primary_entity_id };
};

const optionTagsColors = {
    [CauseOutcome]: '#1FBA59',
    [SequelaOutcome]: '#5C6BC0',
    [RiskAttribution]: '#D73146',
    [RiskPrevalence]: '#D73146',
    [EtiologyRecords]: '#FB9C39',
    [ImpairmentRecords]: '#795548',
    [InjuryRecords]: '#C35623',
    [SevRecords]: '#8560C5',
    [CovariateRecords]: '#5B5E5F',
    [PopulationRecords]: '#FBDD39',
    [Covid19Records]: '#1FBA59',
    [RiskExposureRecords]: '#9E579D',
    [CauseCoprevalenceRecords]: '#1FBA59',
    [ProjectedComorbidityCausesOutcomeRecords]: '#5C6BC0',
    [CauseAssociatedCoprevalenceRecords]: '#D73146',
    [CauseAssociatedComorbidityStudyCoefficients]: '#FB9C39',
    [LifeExpectancyRecords]: '#FB9C39',
    [AntimicrobialResistanceRecords]: '#1FBA59',
    [MultipleMyelomaPopulation]: '#5C6BC0',
    [HealthThreatsMetricsRecords]: '#5C6BC0',
    [RaceEthnicityEyeConditionsRecords]: '#5C6BC0',
};

const optionTagLocales = {
    [CauseOutcome]: _(locale.causeTag),
    [SequelaOutcome]: _(locale.sequelaTag),
    [RiskAttribution]: _(locale.riskTag),
    [RiskPrevalence]: _(locale.riskPrevalenceTag),
    [EtiologyRecords]: _(locale.etiologyTag),
    [ImpairmentRecords]: _(locale.impairmentTag),
    [InjuryRecords]: _(locale.injuryTag),
    [SevRecords]: _(locale.sevTag),
    [CovariateRecords]: _(locale.covariateTag),
    [PopulationRecords]: _(locale.populationTag),
    [Covid19Records]: _(locale.covid19Tag),
    [RiskExposureRecords]: _(locale.riskExposureRecordsTag),
    [LifeExpectancyRecords]: _(locale.lifeExpectancyRecordsTag),
    [AntimicrobialResistanceRecords]: _(locale.antimicrobialResistanceRecordsTag),
    [MultipleMyelomaOutputShell]: _(locale.multipleMyelomaOutputShellTag),
    [MultipleMyelomaSurvivalResults]: _(locale.multipleMyelomaSurvivalResultsTag),
    [MultipleMyelomaPopulation]: _(locale.multipleMyelomaPopulationTag),
    [HealthThreatsMetricsRecords]: _(locale.healthThreatsMetricsRecordsTag),
    [RaceEthnicityEyeConditionsRecords]: _(locale.raceEthnicityEyeConditionsRecordsTag),
};

const OptionContainer = styled(FlexRow)({
    alignItems: 'center',
    fontSize: '1.6rem',
});

const OptionName = styled.div({
    height: '100%',
    flexGrow: 1,
    lineHeight: '22px',
});

const OptionTag = styled('div')<{ type: string }>(({ theme, type }) => ({
    backgroundColor: optionTagsColors[type],
    color: '#FFF6FF',
    fontSize: '1.4rem',
    lineHeight: '22px',
    height: 22,
    borderRadius: theme.borderRadius.base,
    paddingLeft: 15,
    paddingRight: 15,
    marginRight: 5,
    position: 'relative',
    whiteSpace: 'nowrap',
}));

const Hint = styled.div({
    fontSize: '1.3rem',
    color: '#5B5E5F',
    paddingTop: 10,
});

const FilterFlexRowContainer = styled(FlexRow)<{}>(({ theme }) => ({
    fontSize: '1.3rem',
    padding: '0px 20px',
    paddingTop: 26,
}));

const FilterItem = styled('div')<{ isActive; onClick }>(({ theme, isActive }) => ({
    display: 'block',
    marginRight: 32,
    marginBottom: 20,
    color: isActive ? theme.color.green1 : 'inherit',
    cursor: 'pointer',
    textTransform: 'uppercase',
    ':hover, :focus': {
        color: theme.color.green1,
    },
}));

const FilterItemCounter = styled('span')<{ isActive }>(({ theme, isActive }) => ({
    color: isActive ? theme.color.green1 : theme.color.gray40,
    cursor: 'pointer',
    [`${FilterItem}:hover &`]: {
        color: theme.color.green1,
    },
}));

const ChildrenSvgIcon = styled(SvgIcon)<{ disabled: boolean; isActive: boolean }>(
    ({ theme, disabled, isActive }) => ({
        color: theme.color.disabled,
        ':hover, :focus': {
            color: theme.color.green1,
        },
        ...(isActive && {
            color: theme.color.green1,
        }),
        ...(disabled && {
            color: theme.color.disabled,
            cursor: 'default',
            pointerEvents: 'none',
        }),
    })
);

export class ConditionDropdown extends React.Component<Props, State> {
    dropdownRef = createRef<Dropdown>();
    _mounted = false;

    constructor(props) {
        super(props);
        const { datasets } = this.props;

        this.state = {
            datasetsByType: keyBy(datasets, 'data_type'),
            optionGroups: [],
            activeFilters: [],
            dropdownInputValue: '',
            hierarchy: [],
        };
    }

    componentDidMount(): void {
        this._mounted = true;
        const { entityHierarchiesLoaded } = this.props;

        if (entityHierarchiesLoaded) {
            this.loadOptionGroups();
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const {
            entityHierarchiesLoaded,
            selectedConditionsCompatibleDataTypes: selectedConditionsCompatibleDataTypes,
        } = this.props;

        if (
            (!prevProps.entityHierarchiesLoaded && entityHierarchiesLoaded) ||
            !isEqual(
                selectedConditionsCompatibleDataTypes,
                prevProps.selectedConditionsCompatibleDataTypes
            )
        ) {
            this.loadOptionGroups();
        }
    }

    loadOptionGroups() {
        const { datasetsByType } = this.state;

        const order: DataType[] = [
            CauseOutcome,
            SequelaOutcome,
            RiskAttribution,
            RiskPrevalence,
            RiskExposureRecords,
            EtiologyRecords,
            ImpairmentRecords,
            InjuryRecords,
            LifeExpectancyRecords,
            SevRecords,
            CovariateRecords,
            PopulationRecords,
            Covid19Records,
            CauseCoprevalenceRecords,
            ProjectedComorbidityCausesOutcomeRecords,
            CauseAssociatedCoprevalenceRecords,
            CauseAssociatedComorbidityStudyCoefficients,
            AntimicrobialResistanceRecords,
            HealthThreatsMetricsRecords,
            RaceEthnicityEyeConditionsRecords,
        ];

        const loaders: Promise<DropdownOptionGroup<ConditionDropdownOption>>[] = [];
        if (datasetsByType[CauseOutcome] && datasetsByType[SequelaOutcome]) {
            loaders.push(this.loadCombinedCauseAndSequelaOptionsGroupWithHierarchy());
            order.splice(order.indexOf(CauseOutcome), 1);
            order.splice(order.indexOf(SequelaOutcome), 1);
        }

        for (const dataType of order) {
            if (datasetsByType[dataType]) {
                if (getDataTypeEntityHierarchyKey(dataType)) {
                    loaders.push(this.loadGenericOptionGroupWithHierarchy(dataType));
                } else {
                    loaders.push(this.loadGenericOptionGroup(dataType));
                }
            }
        }

        return Promise.all(loaders).then((response) => {
            const optionGroups = response.map(({ optionGroups }) => optionGroups);
            const hierarchy = response
                .map(({ hierarchy }) => hierarchy)
                .flat()
                .map((item) => ({
                    ...item,
                    hierarchy: `${item.dataType}${item.hierarchy}`,
                }));

            if (this._mounted) {
                this.setState({ optionGroups, hierarchy });
            }
        });
    }

    loadGenericOptionGroup(
        dataType: DataType
    ): Promise<DropdownOptionGroup<ConditionDropdownOption>> {
        const { selectedConditionsCompatibleDataTypes: selectedConditionsCompatibleDataTypes } =
            this.props;

        const { groupLabel, filterKey, localePrefix } = getDataTypeConfig()[dataType];

        const dataset = this.state.datasetsByType[dataType];

        const datasetDataTypeActive =
            selectedConditionsCompatibleDataTypes == null
                ? true
                : selectedConditionsCompatibleDataTypes.includes(dataType);

        const options = dataset.granularity[filterKey].map((id, idx) => {
            const label = _(localePrefix + id);
            return {
                value: encodeValue({ data_type: dataType, primary_entity_id: id }),
                label,
                searchTerm: this.getSearchTerm({ dataType, id, label }),
                disabled: !datasetDataTypeActive,
                dataType,
            };
        });

        return Promise.resolve({
            optionGroups: {
                dataType,
                label: groupLabel,
                options,
            },
            hierarchy: [],
        });
    }

    loadGenericOptionGroupWithHierarchy(
        dataType: DataType
    ): Promise<DropdownOptionGroup<ConditionDropdownOption>> {
        const {
            selectedConditionsCompatibleDataTypes: selectedConditionsCompatibleDataTypes,
            entityHierarchies,
        } = this.props;

        const { entityHierarchyKey, groupLabel, filterKey, localePrefix } =
            getDataTypeConfig()[dataType];

        const hierarchy = entityHierarchies[entityHierarchyKey];
        const dataset = this.state.datasetsByType[dataType];

        const hierarchyItems = this.parseHierarchy(
            hierarchy,
            dataset.granularity[filterKey],
            dataType
        );

        const areAllItemsAtSameLevel = uniqBy('level', hierarchyItems).length === 1;

        const datasetDataTypeActive =
            selectedConditionsCompatibleDataTypes == null
                ? true
                : selectedConditionsCompatibleDataTypes.includes(dataType);

        let hierarchyOptions = hierarchyItems.map((hierarchyItem, idx) => {
            const { id } = hierarchyItem;
            const hierarchy = formatHierarchyToLocalePrefix(hierarchyItem.hierarchy);
            const label = hierarchy + _(localePrefix + id);
            return {
                value: encodeValue({ data_type: dataType, primary_entity_id: id }),
                label,
                disabled: !datasetDataTypeActive,
                searchTerm: this.getSearchTerm({ dataType, id, label, hierarchy }),
                dataType,
            };
        });

        if (areAllItemsAtSameLevel) {
            hierarchyOptions = sortBy('label', hierarchyOptions);
        }

        return Promise.resolve({
            optionGroups: {
                dataType,
                label: groupLabel,
                options: hierarchyOptions,
            },
            hierarchy: hierarchyItems,
        });
    }

    loadCombinedCauseAndSequelaOptionsGroupWithHierarchy(): Promise<
        DropdownOptionGroup<ConditionDropdownOption>
    > {
        const { selectedConditionsCompatibleDataTypes: selectedConditionsCompatibleDataTypes } =
            this.props;

        const combinedCauseSequelaHierarchy =
            this.loadCombinedCauseAndSequelaMetadataWithHierarchy();

        const causeDataResource = this.state.datasetsByType[CauseOutcome];
        const sequelaDataResource = this.state.datasetsByType[SequelaOutcome];

        const encodedAvailableCauseIds = causeDataResource.granularity.cause_id!.map((id) =>
            encodeValue({ primary_entity_id: id, data_type: CauseOutcome })
        );

        const encodedAvailableSequelaIds = sequelaDataResource.granularity.sequela_id!.map((id) =>
            encodeValue({ primary_entity_id: id, data_type: SequelaOutcome })
        );

        const hierarchyItems = this.parseHierarchy(
            combinedCauseSequelaHierarchy,
            encodedAvailableCauseIds.concat(encodedAvailableSequelaIds)
        );

        const localePrefixes = {
            [CauseOutcome]: 'cause_',
            [SequelaOutcome]: 'sequela_',
        };

        const datasetDataTypeActive =
            selectedConditionsCompatibleDataTypes == null
                ? true
                : selectedConditionsCompatibleDataTypes.includes(CauseOutcome) ||
                  selectedConditionsCompatibleDataTypes.includes(SequelaOutcome);

        const hierarchyOptions = hierarchyItems.map((hierarchyItem, idx) => {
            const encodedId = hierarchyItem.id;
            const { primary_entity_id, data_type } = decodeValue(encodedId);
            const hierarchy = formatHierarchyToLocalePrefix(hierarchyItem.hierarchy);
            const label = hierarchy + _(localePrefixes[data_type] + primary_entity_id);
            return {
                value: encodedId,
                label,
                disabled: !datasetDataTypeActive,
                searchTerm: this.getSearchTerm({
                    dataType: data_type,
                    id: primary_entity_id,
                    label,
                    hierarchy,
                }),
                dataType: AllDataTypes,
            };
        });

        return Promise.resolve({
            optionGroups: {
                dataType: CauseOutcome,
                label: _(locale.causesGroup),
                options: hierarchyOptions,
            },
            hierarchy: hierarchyItems,
        });
    }

    loadCombinedCauseAndSequelaMetadataWithHierarchy = () => {
        const { entityHierarchies } = this.props;

        const [causeMetadataWithHierarchy, sequelaMetadataWithHierarchy] = [
            entityHierarchies.causes,
            entityHierarchies.sequelas,
        ];

        if (causeMetadataWithHierarchy == null || sequelaMetadataWithHierarchy == null) {
            return [];
        }

        const typedCauseMetadataWithHierarchy: Entity[] = [];
        const encodeCauseIdField = (cause, idField) =>
            encodeValue({
                primary_entity_id: cause[idField],
                data_type: CauseOutcome,
            });

        for (const cause of causeMetadataWithHierarchy) {
            typedCauseMetadataWithHierarchy.push({
                id: encodeCauseIdField(cause, 'id'),
                level: cause.level,
                level0_parent_id: encodeCauseIdField(cause, 'level0_parent_id'),
                level1_parent_id: encodeCauseIdField(cause, 'level1_parent_id'),
                level2_parent_id: encodeCauseIdField(cause, 'level2_parent_id'),
                level3_parent_id: encodeCauseIdField(cause, 'level3_parent_id'),
            });
        }

        const typedSequelaMetadataWithHierarchy: Entity[] = [];
        const encodeSequelaIdField = (cause, idField) =>
            encodeValue({
                primary_entity_id: cause[idField],
                data_type: SequelaOutcome,
            });
        let parentCause;
        let parentIds;
        let counter;

        for (const sequela of sequelaMetadataWithHierarchy) {
            parentCause = find(
                typedCauseMetadataWithHierarchy,
                (cause) =>
                    cause.id ===
                    encodeValue({
                        primary_entity_id: sequela.parent_cause_id,
                        data_type: CauseOutcome,
                    })
            );

            parentIds = {};
            for (counter = 0; counter < parentCause.level; ++counter) {
                parentIds[`level${counter}_parent_id`] = parentCause[`level${counter}_parent_id`];
            }

            parentIds[`level${counter}_parent_id`] = parentCause.id;
            parentIds[`level${++counter}_parent_id`] = encodeSequelaIdField(
                sequela,
                'level0_parent_id'
            );

            typedSequelaMetadataWithHierarchy.push({
                id: encodeSequelaIdField(sequela, 'id'),
                level: parentCause.level + sequela.level + 1,
                ...parentIds,
            });
        }

        return typedCauseMetadataWithHierarchy.concat(typedSequelaMetadataWithHierarchy);
    };

    /**
     * @todo This logic is duplicated in ChartHierarchicalDropdown
     * Level upping items when parent level item doesn't exist
     * @param hierarchy
     * @param rootLevel
     * @returns {any[]}
     */
    tuneLevels = (hierarchy: { id: number }[], rootLevel: number) => {
        // console.log('Tuning levels');

        const availableItemIds = hierarchy.map((item) => item.id);

        const hierarchyByLevels = groupBy(hierarchy, 'level');

        const availableLevels = Object.keys(hierarchyByLevels).map((level) => parseInt(level));

        const minLevel = Math.min(...availableLevels);
        const maxLevel = Math.max(...availableLevels);

        // console.log('Min level is ' +  minLevel);
        // console.log('Max level is ' + maxLevel);

        const levelUpItem = (item, toLevel, toParentId) => {
            if (item.level > rootLevel) {
                remove(hierarchyByLevels[item.level], (i) => i.id === item.id);

                const levelUppedItem = {
                    ...item,
                    level: toLevel,
                    [`level${toLevel - 1}_parent_id`]: toParentId,
                };

                if (!hierarchyByLevels[toLevel]) {
                    hierarchyByLevels[toLevel] = [];
                }

                hierarchyByLevels[toLevel].push(levelUppedItem);
            }
        };

        const getParentId = (item, level) => item[`level${level}_parent_id`];

        const leveledUpItems = {};

        let item, parentId, expectedItemLevel, parentWasFound;

        for (let level = minLevel + 1; level <= maxLevel; ++level) {
            // console.log('    Checking level ' + level);
            const itemsAtCurrentLevel = hierarchyByLevels[level];

            if (itemsAtCurrentLevel) {
                for (let i = itemsAtCurrentLevel.length - 1; i >= 0; --i) {
                    if (itemsAtCurrentLevel[i]) {
                        item = itemsAtCurrentLevel[i];
                        // console.log('        Checking item ' + item.id);

                        // If no direct parent
                        parentId = getParentId(item, level - 1);
                        if (!availableItemIds.includes(parentId)) {
                            // console.log('            The item does not have direct parent');

                            // Find the next existing parent
                            parentWasFound = false;
                            for (
                                let parentLevel = level - 2;
                                parentLevel >= minLevel;
                                --parentLevel
                            ) {
                                parentId = getParentId(item, parentLevel);

                                // console.log('                Checking parent at level ' + parentLevel);
                                if (availableItemIds.includes(parentId)) {
                                    parentWasFound = true;

                                    // And level up just below it
                                    expectedItemLevel =
                                        (parentId in leveledUpItems
                                            ? leveledUpItems[parentId]
                                            : parentLevel) + 1;

                                    // console.log('                    Found parent ' + parentId + ' at level ' + parentLevel + ', leveling up ' + item.id + ' to level ' + expectedItemLevel);

                                    levelUpItem(item, expectedItemLevel, parentId);
                                    leveledUpItems[item.id] = expectedItemLevel;
                                    break;
                                }
                            }

                            // If no parents found then level up to the min level
                            if (!parentWasFound) {
                                // console.log('                No parent was found. Leveling up ' + item.id + ' to the min level ' + minLevel);

                                levelUpItem(item, minLevel, null);
                                leveledUpItems[item.id] = minLevel;
                            }
                        }
                        // If the direct parent was leveled up
                        else if (parentId in leveledUpItems) {
                            // console.log('            Direct parent is found but it was leveled up to level ' + leveledUpItems[parentId] + '. Leveling up ' + item.id + ' to the next level ' + (leveledUpItems[parentId] + 1));

                            levelUpItem(item, leveledUpItems[parentId] + 1, parentId);
                            leveledUpItems[item.id] = leveledUpItems[parentId] + 1;
                        }
                    }
                }
            }
        }

        // console.log('Finished tuning levels');
        return flatten(Object.values(hierarchyByLevels));
    };

    /**
     * @todo This logic is duplicated in ChartHierarchicalDropdown
     * @param metadataWithHierarchy
     * @param availableFilterOptions
     * @param dataType
     */
    parseHierarchy(
        metadataWithHierarchy: object[],
        availableFilterOptions: any[],
        dataType: DataType = AllDataTypes
    ) {
        const { limitHierarchyDepth = 6, rootLevel = 0 } = this.props;

        if (metadataWithHierarchy == null) {
            return [];
        }
        // Filtering hierarchy items so it'll include only available granularity options
        const filteredHierarchy = this.tuneLevels(
            metadataWithHierarchy.filter((item) => availableFilterOptions.includes(item.id)),
            rootLevel
        );

        const hierarchyByLevels = groupBy(filteredHierarchy, 'level');
        const minLevel = Math.min(
            ...Object.keys(hierarchyByLevels).map((level) => parseInt(level))
        );

        const parseHierarchyItem = (item, level) => {
            let unparsedChildren = null;
            let children = null;
            if (level < limitHierarchyDepth || item[`level${level + 1}_parent_id`]) {
                const subLevelItems = hierarchyByLevels[level + 1];
                if (subLevelItems && subLevelItems.length) {
                    unparsedChildren = groupBy(
                        subLevelItems,
                        `level${level + rootLevel}_parent_id`
                    )[item.id];

                    if (unparsedChildren) {
                        children = unparsedChildren.map((item) =>
                            parseHierarchyItem(item, level + 1)
                        );
                    }
                }
            }

            return {
                ...item,
                children,
            };
        };

        const parsedItems = hierarchyByLevels[minLevel].map((levelData) =>
            parseHierarchyItem(levelData, minLevel)
        );
        const result = [];
        const format = (items, prefix = '') =>
            forEach(items, (item, index) => {
                result.push({
                    id: item.id,
                    level: item.level,
                    hierarchy: prefix + index,
                    dataType,
                });

                if (item.children) {
                    format(item.children, prefix + index + '_');
                }
            });
        format(parsedItems);

        return result;
    }

    getSearchTerm = ({
        dataType,
        id,
        label,
        hierarchy = null,
    }: {
        dataType: DataType;
        id: number;
        label: string;
        hierarchy?: string | null;
    }): {} => {
        const searchTerm = {
            label,
            hierarchy,
            icdCodes: [],
            toString() {
                return `${this.label} ${this.icdCodes.join(' ')}`;
            },
        };
        if (dataType === CauseOutcome) {
            const { causeICDCodesById } = this.props;
            if (causeICDCodesById && causeICDCodesById[id]) {
                const { icd9_codes, icd10_codes } = causeICDCodesById[id];
                searchTerm.icdCodes = [...icd9_codes, ...icd10_codes];
            }
        }

        return searchTerm;
    };

    handleSelect = (
        ev: { target: { value: ConditionDropdownOption['value'][] } },
        innerState: DropdownInnerState<ConditionDropdownOption>
    ) => {
        this.handleChange(ev.target.value);
    };

    handleMultiSelect = (ev: { target: { value: ConditionDropdownOption['value'][] } }) => {
        this.handleChange(ev.target.value);
    };

    handleRemove = (
        ev: { target: { value: ConditionDropdownOption['value'][] } },
        innerState: DropdownInnerState<ConditionDropdownOption>
    ) => {
        const { value } = ev.target;
        this.handleChange(value);

        if (!innerState.isOpen) {
            const { onRemove } = this.props;
            onRemove && onRemove(value);
        }
    };

    handleClear = (
        ev: { target: { value: any[] } },
        innerState: DropdownInnerState<ConditionDropdownOption>
    ) => {
        const { value } = ev.target;
        this.handleChange(value);

        if (!innerState.isOpen) {
            const { onRemove } = this.props;
            onRemove && onRemove(value);
        }
    };

    handleChange = (value: ConditionDropdownOption['value'][] | null) => {
        const { onChange } = this.props;

        if (value == null) {
            return;
        }

        const values = value.map((i) => decodeValue(i.toString()));
        onChange && onChange(values);
    };

    handleClose = () => {
        const { onClose } = this.props;
        onClose && onClose();
    };

    handleInputChange = (inputValue: string) => {
        this.setState({ activeFilters: [], dropdownInputValue: inputValue });
    };

    refreshDropdownVisibleOptions = () => {
        if (this.dropdownRef.current) {
            this.dropdownRef.current.updateVisibleOptions(this.state.dropdownInputValue);
        }
    };

    renderMenuHeader = (innerState: DropdownInnerState<ConditionDropdownOption>) => {
        const { activeFilters } = this.state;
        const { visibleOptions } = innerState;

        const countResultsByFilter = {};
        // get number of search results by counting visible options by type
        visibleOptions.map((option) => {
            const { data_type } = decodeValue(option.value);
            countResultsByFilter[data_type] = (countResultsByFilter[data_type] || 0) + 1;
        });
        // derive filters from counters keys
        const filters = Object.keys(countResultsByFilter).sort();

        const isAllFiltersActive = activeFilters.length === 0;

        return (
            <FilterFlexRowContainer wrap="wrap">
                <FilterItem
                    onClick={() => {
                        this.setState({ activeFilters: [] }, () => {
                            this.refreshDropdownVisibleOptions();
                        });
                    }}
                    isActive={isAllFiltersActive}
                >
                    <span>
                        <SvgIcon
                            src="/icons/ic-filter.svg"
                            color={(theme) => theme.color.green1}
                            style={{ marginRight: 16, verticalAlign: 'unset' }}
                        />
                        {_(AllDataTypes)}
                        <FilterItemCounter isActive={isAllFiltersActive}>
                            {` · ${visibleOptions.length}`}
                        </FilterItemCounter>
                    </span>
                </FilterItem>
                {filters.map((filter) => {
                    const isFilterActive = activeFilters.includes(filter);

                    const handleFilterClick = () => {
                        this.setState(
                            ({ activeFilters }) => {
                                const newActivefilters = isFilterActive
                                    ? activeFilters.filter(
                                          (selectedFilter) => selectedFilter !== filter
                                      )
                                    : activeFilters.concat(filter);
                                return { activeFilters: newActivefilters };
                            },
                            () => {
                                this.refreshDropdownVisibleOptions();
                            }
                        );
                    };

                    return (
                        <FilterItem
                            key={filter}
                            onClick={handleFilterClick}
                            isActive={isFilterActive}
                        >
                            <span>
                                {_(filter)}
                                <FilterItemCounter isActive={isFilterActive}>
                                    {` · ${countResultsByFilter[filter]}`}
                                </FilterItemCounter>
                            </span>
                        </FilterItem>
                    );
                })}
            </FilterFlexRowContainer>
        );
    };

    renderOption = (o: ConditionDropdownOption, { optionDisabledGetter }: OptionGetters) => {
        const { value, dataType: hierarchyDataType, label } = o;

        if (!value) {
            // No options
            return (
                <OptionContainer>
                    <OptionName>{label}</OptionName>
                </OptionContainer>
            );
        }

        const { selectedConditions } = this.props;
        const { hierarchy } = this.state;
        const getId = (value: number | string): string => {
            const parts = value.toString().split(':');
            return parts[parts.length - 1];
        };

        const id = getId(value);
        const selectedConditionIds = selectedConditions.map(encodeValue);
        let directSubnationalsIds = [];

        let isSubnationalsButtonDisabled = optionDisabledGetter(o);
        let areAllDirectChildrenSelected = false;

        const hierarchyObject = hierarchy.find(
            (item) => item.dataType === hierarchyDataType && getId(item.id) === id
        );

        if (hierarchyObject) {
            const hierarchyPath = hierarchyObject.hierarchy;
            const getPathLevel = (path: string): number => path.split('_').length;
            const pathLevel = getPathLevel(hierarchyPath);

            directSubnationalsIds = hierarchy
                .filter((item) => {
                    const { hierarchy: itemHierarchy, entity, parentEntity } = item;
                    const itemPathLevel = getPathLevel(itemHierarchy);
                    return (
                        itemHierarchy.startsWith(`${hierarchyObject.hierarchy}_`) &&
                        itemPathLevel - pathLevel === 1
                    );
                })
                .map(({ id, dataType }) =>
                    id.toString().includes(':')
                        ? id
                        : encodeValue({
                              data_type: dataType,
                              primary_entity_id: id,
                          })
                );

            areAllDirectChildrenSelected = directSubnationalsIds.every((i) =>
                selectedConditionIds.includes(i)
            );
        }

        const hasSubnationals = !!directSubnationalsIds.length;

        const handleChildrenClick = (ev) => {
            ev.preventDefault();
            ev.stopPropagation();

            // calculate new ids
            const newValue = areAllDirectChildrenSelected
                ? difference(selectedConditionIds, directSubnationalsIds)
                : union(selectedConditionIds, directSubnationalsIds);

            this.handleChange(newValue);
        };

        const childrenIconSrc = areAllDirectChildrenSelected
            ? '/icons/ic-multiselect-all.svg'
            : '/icons/ic-multiselect-some.svg';

        const dataType = value.toString().split(':')[0];

        return (
            <OptionContainer itemsSpacing={20}>
                <OptionName>{label}</OptionName>
                {hasSubnationals && (
                    <ChildrenSvgIcon
                        data-testid="select-children-icon"
                        src={childrenIconSrc}
                        size="large"
                        disabled={isSubnationalsButtonDisabled}
                        isActive={areAllDirectChildrenSelected}
                        onClick={!isSubnationalsButtonDisabled ? handleChildrenClick : undefined}
                    />
                )}
                {value && optionTagLocales[dataType] && (
                    <OptionTag type={dataType}>{optionTagLocales[dataType]}</OptionTag>
                )}
            </OptionContainer>
        );
    };

    checkIsOptionVisible = ({
        inputSearchTerm,
        option,
        optionSearchTerm,
    }: {
        inputSearchTerm: string;
        optionSearchTerm: string;
        option: DropdownOption;
    }) => {
        const trimmedInputSearchTerm = inputSearchTerm.trim();
        const isNumericSearch = NUMERIC_SEARCH_REGEXP.test(trimmedInputSearchTerm);
        if (isNumericSearch) {
            const { icdCodes, hierarchy } = option.searchTerm;
            if (
                hierarchy?.trim().startsWith(trimmedInputSearchTerm) ||
                icdCodes.some((code) => `${code}`.startsWith(trimmedInputSearchTerm))
            )
                return true;
            return false;
        }
        return optionSearchTerm.includes(inputSearchTerm);
    };

    render() {
        const {
            selectedDataCollection,
            selectedConditionsCompatibleDataTypes,
            showHint,
            label = _('refinement_condition_label'),
            isMulti = true,
            placeholder = _('data_explorer_condition_dropdown_placeholder'),
            isSearchable = true,
            isClearable = true,
            onChange,
            onMultiSelect,
            onRemove,
            onClose,
            causesById,
            etiologiesById,
            impairmentsById,
            injuriesById,
            locationsById,
            risksById,
            sequelasById,
            sevsById,
            renderMenuHeader,
            ...props
        } = this.props;
        let { optionGroups, activeFilters } = this.state;

        if (!selectedDataCollection) {
            return null;
        }

        // when conditions are selected filter option groups with compatible data type
        if (selectedConditionsCompatibleDataTypes) {
            optionGroups = optionGroups.filter(({ dataType }) =>
                selectedConditionsCompatibleDataTypes.includes(dataType)
            );
        }

        const availableDataTypes = getDataCollectionDataTypes(selectedDataCollection);

        if (
            availableDataTypes &&
            availableDataTypes.length === 1 &&
            optionGroups &&
            optionGroups.length === 1 &&
            optionGroups[0].options.length === 1
        ) {
            return null;
        }

        const dropdownValue =
            this.props.value != null ? this.props.value.map((i) => encodeValue(i)) : null;

        // hide options that are not included in the active filters
        const optionHiddenGetter =
            activeFilters.length === 0
                ? undefined
                : (option: ConditionDropdownOption) => {
                      const { data_type } = decodeValue(option.value);
                      return activeFilters.length > 0 && !activeFilters.includes(data_type);
                  };

        return (
            <FormGroup style={{ fontSize: '1.2em', ...this.props.style }}>
                <FieldLabel htmlFor="condition-dropdown">{label}</FieldLabel>
                <Dropdown
                    id="condition-dropdown"
                    dataTestid="condition-dropdown"
                    ref={this.dropdownRef}
                    multiselectLabel={isMulti ? _.get('multiselect_label') : undefined}
                    valueMode={isMulti ? 'multiArray' : 'singleArray'}
                    isSearchable={isSearchable}
                    isClearable={isClearable}
                    placeholder={placeholder}
                    optionsLabel="All Results"
                    topOptionsLabel="Best Matches"
                    options={optionGroups}
                    checkIsOptionVisible={this.checkIsOptionVisible}
                    optionValueGetter={(o) => o.value}
                    optionLabelGetter={(o) => o.label}
                    optionSearchTermGetter={(o) => o.searchTerm}
                    optionHiddenGetter={optionHiddenGetter}
                    onSelect={this.handleSelect}
                    onMultiSelect={this.handleMultiSelect}
                    onRemove={this.handleRemove}
                    onClear={this.handleClear}
                    onClose={this.handleClose}
                    value={dropdownValue}
                    renderMenuHeader={renderMenuHeader || this.renderMenuHeader}
                    renderOption={this.renderOption}
                    renderTopOption={this.renderOption}
                    filterTopOption={(option) =>
                        ![SequelaOutcome].includes(decodeValue(option.value.toString()).data_type)
                    }
                    // optionSearchTermGetter={(o: ConditionDropdownOption) => {
                    //     const { data_type, primary_entity_id } = decodeValue(o.value);
                    //     let searchTerm = o.label;
                    //     // items without hierarchy should simply use label for search term
                    //     if (
                    //         ![
                    //             CauseOutcome,
                    //             SequelaOutcome,
                    //             RiskAttribution,
                    //             EtiologyRecords,
                    //             ImpairmentRecords,
                    //             InjuryRecords,
                    //             SevRecords,
                    //         ].includes(data_type)
                    //     ) {
                    //         return searchTerm;
                    //     }
                    //
                    //     // items with hierarchy should construct search term by including labels of it's parents
                    //     const getPrimaryEntityByIdOf = (dataType: DataType) =>
                    //         ({
                    //             [CauseOutcome]: causesById,
                    //             [SequelaOutcome]: sequelasById,
                    //             [RiskAttribution]: risksById,
                    //             [EtiologyRecords]: etiologiesById,
                    //             [ImpairmentRecords]: impairmentsById,
                    //             [InjuryRecords]: injuriesById,
                    //             [SevRecords]: sevsById,
                    //         }[dataType]);
                    //
                    //     // find entity of option
                    //     const primaryEntityById = getPrimaryEntityByIdOf(data_type);
                    //     const primaryEntity = primaryEntityById[primary_entity_id]!;
                    //
                    //     // construct search term using localizations
                    //     let level = primaryEntity.level;
                    //     let localePrefix = getDataTypeLocalePrefix(data_type);
                    //
                    //     // items should have it's parents included in the search term
                    //     // level 3 items only
                    //     while (level === 3) {
                    //         level--;
                    //         let parentId = primaryEntity[`level${level}_parent_id`];
                    //         const parentSearchTerm = _(`${localePrefix}${parentId}`);
                    //         searchTerm = `${searchTerm} ${parentSearchTerm}`;
                    //     }
                    //
                    //     return searchTerm;
                    // }}
                    onInputChange={this.handleInputChange}
                />
                {!!showHint && <Hint>{_.get('data_explorer_data_type_locked_explanation')}</Hint>}
            </FormGroup>
        );
    }
}

const Connected = compose(
    withStorage,
    withTheme,
    connect(mapStateToProps, dispatchProps)
)(ConditionDropdown);

export default React.forwardRef((props, ref) => {
    return <Connected {...props} ref={ref} />;
});
