import { DataCollectionResource } from '@portal/common/types';
import {
    dataTypeHasPrimaryEntity,
    getDataTypePrimaryEntityKey,
} from '@portal/common/models/data-type';

export default (dataCollections: DataCollectionResource[]): DataCollectionResource[] =>
    dataCollections.map((dataCollection) => ({
        ...dataCollection,
        datasets: dataCollection.datasets.map((dataset) => ({
            ...dataset,
            granularity: {
                ...dataset.granularity,
                ...(!dataTypeHasPrimaryEntity(dataset.data_type) && {
                    [getDataTypePrimaryEntityKey(dataset.data_type)]: [1],
                }),
            },
        })),
    }));
