import React from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withTheme } from 'emotion-theming';
import { intersection } from 'lodash/fp';

import { withStorage } from '@ihme/common/packages/storage';
import { Clearfix, Form } from '@ihme/common/web/components';

import { Alert, Button, FlexRow, H3, Link, SectionHeader } from '@portal/common/components';
import {
    AGE_GROUP_ID_KEY,
    CAUSE_ID_KEY,
    DAY_KEY,
    EMPLOYMENT_COHORT_ID_KEY,
    EMPLOYMENT_STATUS_ID_KEY,
    ETHNICITY_ID_KEY,
    FACILITY_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    INDUSTRY_ID_KEY,
    isTimeUnitKey,
    LOCATION_ID_KEY,
    METRIC_ID_KEY,
    PORTAL_ORGANIZATION_ID_KEY,
    RACE_ID_KEY,
    RESPONSE_ID_KEY,
    RISK_EXPOSURE_ID_KEY,
    ROUND_ID_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import { AllDataTypes } from '@portal/common/models/data-type';
import { styled } from '@portal/common/theme';
import { DataGranularity, DataKey, RefinementFilterConfig } from '@portal/common/types';

import ChartFilterReset from '../../components/ChartFilterReset';
import _ from '../../locale';
import {
    addVisibleRefinementFilters,
    changeSelectedDataType,
    dataSelectionValidation,
    resetDataTool,
} from '../../store/data-explorer/actions';
import {
    getCombinedFiltersAmount,
    getDataToolConfig,
    getDataToolDataCollections,
    getSelectedConditions,
    getSelectedConditionsDataTypes,
    getSelectedConditionsDataTypesDefaultFilters,
    getSelectedConditionsDataTypesGranularity,
    getSelectedConditionsRefinedGranularity,
    getSelectedRefinementFiltersWithFallbackToDefaultFilters,
    getVisibleRefinementFilters,
    hasCdnRestrictions,
    hasErrorInRefinements,
    isDataLoading,
} from '../../store/data-explorer/selectors';
import { getSelectedDataCollectionForDataTool } from '../../store/user-settings/selectors';
import sortRefinementsOptions from '../../utility/sort-refinements-options';
import RefinementFilter from '../../components/DataExplorer/RefinementFilter';
import RunQueryButton from '../../components/RunQueryButton';
import ToggleChartsVisibilityButton from '../../components/ToggleChartsVisibilityButton';

const RefinementFilterWrapper = styled.div<{ isFullWidth: boolean }>(({ theme, isFullWidth }) => ({
    width: isFullWidth ? '100%' : '50%',
    ':nth-child(even)': {
        paddingLeft: 30,
    },
}));

export const REFINEMENT_OPTIONS = sortRefinementsOptions([
    PORTAL_ORGANIZATION_ID_KEY,
    METRIC_ID_KEY,
    GENDER_ID_KEY,
    AGE_GROUP_ID_KEY,
    CAUSE_ID_KEY,
    RISK_EXPOSURE_ID_KEY,
    LOCATION_ID_KEY,
    YEAR_KEY,
    DAY_KEY,
    ROUND_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    RACE_ID_KEY,
    FACILITY_ID_KEY,
    INDUSTRY_ID_KEY,
    ETHNICITY_ID_KEY,
    EMPLOYMENT_COHORT_ID_KEY,
    EMPLOYMENT_STATUS_ID_KEY,
]);

export const REFINEMENT_FILTERS_CONFIG: RefinementFilterConfig = {
    [AGE_GROUP_ID_KEY]: { isMulti: true, isSearchable: false },
    [FACILITY_ID_KEY]: { isMulti: true, isSearchable: false },
    [INDUSTRY_ID_KEY]: { isMulti: true, isSearchable: false },
    [LOCATION_ID_KEY]: { isMulti: true, isSearchable: false },
    [METRIC_ID_KEY]: { isMulti: true, isSearchable: false },
    [ETHNICITY_ID_KEY]: { isMulti: true, isSearchable: false },
    [EMPLOYMENT_COHORT_ID_KEY]: { isMulti: true, isSearchable: false },
    [EMPLOYMENT_STATUS_ID_KEY]: { isMulti: true, isSearchable: false },
    [RACE_ID_KEY]: { isMulti: true, isSearchable: false },
    [PORTAL_ORGANIZATION_ID_KEY]: { isMulti: true, isSearchable: false },
    [RESPONSE_ID_KEY]: { isMulti: true, isSearchable: false },
    [PORTAL_ORGANIZATION_ID_KEY]: { isMulti: true, isSearchable: false },
};

const mapStateToProps = (state: RootState) => ({
    dataCollections: getDataToolDataCollections(state),
    defaultFilters: getSelectedConditionsDataTypesDefaultFilters(state),
    selectedConditions: getSelectedConditions(state),
    selectedConditionsDataTypes: getSelectedConditionsDataTypes(state),
    selectedDataCollection: getSelectedDataCollectionForDataTool(state),
    selectedRefinementFilters: getSelectedRefinementFiltersWithFallbackToDefaultFilters(state),
    visibleRefinementFilters: getVisibleRefinementFilters(state),
    granularity: getSelectedConditionsDataTypesGranularity(state),
    refinedGranularity: getSelectedConditionsRefinedGranularity(state),
    hasCdnRestrictions: hasCdnRestrictions(state),
    dataToolConfig: getDataToolConfig(state),
    errorInRefinements: hasErrorInRefinements(state),
    yearRefinementFilterMode: state.dataExplorer.yearRefinementFilterMode,
    dayRefinementFilterMode: state.dataExplorer.dayRefinementFilterMode,
    isLoadingData: isDataLoading(state),
    combinedFiltersAmount: getCombinedFiltersAmount(state),
});

const dispatchProps = {
    resetDataTool: resetDataTool,
    changeSelectedDataType: changeSelectedDataType,
    addVisibleRefinementFilters: addVisibleRefinementFilters,
    queryDataValidationRequest: dataSelectionValidation.request,
};

const LinkToCDN = ({ children, ...props }) => (
    <Link href="#cdn" style={{ color: 'white', textDecoration: 'underline' }} {...props}>
        {children}
    </Link>
);

const StyledAlert = styled(Alert)(() => ({
    marginBottom: 15,
}));

const BASIC_MODE = 'basic';
const ADVANCED_MODE = 'advanced';

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & {
        refinementFiltersConfig?: RefinementFilterConfig;
        areChartsVisible: boolean;
        toggleChartsVisibility: () => void;
    };

type State = {
    viewMode: typeof BASIC_MODE | typeof ADVANCED_MODE;
};

class EDPRefinementFiltersControls extends React.Component<Props, State> {
    static defaultProps = {
        refinementFiltersConfig: REFINEMENT_FILTERS_CONFIG,
    };

    state: State = {
        viewMode: BASIC_MODE,
    };

    static getDerivedStateFromProps = (props: Props, state: State) =>
        props.dataToolConfig &&
        !props.dataToolConfig.enableBasicRefinementFiltersMode &&
        state.viewMode !== ADVANCED_MODE
            ? { viewMode: ADVANCED_MODE }
            : null;

    reset = () => {
        const { changeSelectedDataType, resetDataTool, dataToolConfig } = this.props;

        resetDataTool(dataToolConfig);
        changeSelectedDataType(AllDataTypes);
    };

    // get available refinements based on given granularity
    getAvailableRefinementsForRefinedGranularity = (granularity: DataGranularity) =>
        intersection(Object.keys(granularity), REFINEMENT_OPTIONS)
            // filter granularity with values more than 1
            .filter((key) => granularity[key] != null && granularity[key].length > 1);

    handleAddRefinementClick = (filterKey) => {
        this.props.addVisibleRefinementFilters(filterKey);
    };

    toggleAdvancedMode = () => {
        this.setState((state) => ({ viewMode: ADVANCED_MODE }));
    };

    toggleBasicMode = () => {
        this.setState((state) => ({ viewMode: BASIC_MODE }));
    };

    toggleMode = () => {
        this.setState((state) => ({
            viewMode: state.viewMode === ADVANCED_MODE ? BASIC_MODE : ADVANCED_MODE,
        }));
    };

    getHiddenRefinementFilters = (): DataKey[] => {
        const { hiddenRefinementFilters } = this.props.dataToolConfig;
        return hiddenRefinementFilters && hiddenRefinementFilters.length
            ? hiddenRefinementFilters
            : [];
    };

    /**
     * There are two modes simple and advanced
     * For advanced showing all that's present in granularity
     * In simple we are showing enabled filters from visibleRefinementFilters
     * visibleRefinementFilters is also changed by validateVisibleRefinementFilters
     */
    renderFiltersControls = () => {
        const { viewMode } = this.state;
        const {
            refinedGranularity,
            granularity,
            selectedRefinementFilters,
            refinementFiltersConfig,
            yearRefinementFilterMode,
            dayRefinementFilterMode,
        } = this.props;

        const fallbackGranularity = refinedGranularity || granularity;

        const hiddenFilters = this.getHiddenRefinementFilters();
        const availableRefinementFilters = this.getAvailableRefinementsForRefinedGranularity(
            fallbackGranularity
        ).filter((key) => !hiddenFilters.includes(key));

        const createOnChange = (key) => (value) => {
            this.handleAddRefinementClick(key);
        };

        const isAdvancedMode = viewMode === ADVANCED_MODE;

        // decides which filters are displayed
        const filters = isAdvancedMode
            ? availableRefinementFilters
            : intersection(
                  [AGE_GROUP_ID_KEY, EMPLOYMENT_STATUS_ID_KEY],
                  availableRefinementFilters
              );

        return filters.map((granularityKey) => (
            <RefinementFilterWrapper
                key={granularityKey}
                isFullWidth={isTimeUnitKey(granularityKey)}
            >
                <RefinementFilter
                    refinementFiltersConfig={{
                        [granularityKey]: {
                            isMulti: true,
                            isRevertToDefaultDisabled: true,
                            ...refinementFiltersConfig[granularityKey],
                        },
                    }}
                    hideSelectAll
                    hideDelete
                    granularityKey={granularityKey}
                    mode={
                        {
                            [YEAR_KEY]: yearRefinementFilterMode,
                            [DAY_KEY]: dayRefinementFilterMode,
                        }[granularityKey]
                    }
                    availableRefinementFilters={availableRefinementFilters}
                    selectedValue={selectedRefinementFilters[granularityKey]}
                    {...(isAdvancedMode && {
                        onChange: createOnChange(granularityKey),
                    })}
                />
            </RefinementFilterWrapper>
        ));
    };

    renderActionButtons = () => {
        const { viewMode } = this.state;
        const { dataToolConfig } = this.props;

        const resetBtn =
            dataToolConfig && !dataToolConfig.disableResetRefinementFilters ? (
                <ChartFilterReset onClick={this.reset} />
            ) : null;

        if (dataToolConfig && !dataToolConfig.enableBasicRefinementFiltersMode) {
            return resetBtn;
        }

        const isAdvancedMode = viewMode === ADVANCED_MODE;

        return (
            <>
                <Button style={{ marginRight: 20 }} onClick={this.toggleMode}>
                    {_.get(
                        isAdvancedMode ? 'select_data_basic_search' : 'select_data_advanced_search'
                    )}
                </Button>
                {resetBtn}
            </>
        );
    };

    render() {
        const {
            dataCollections,
            selectedConditionsDataTypes,
            hasCdnRestrictions,
            errorInRefinements,
            isLoadingData,
            queryDataValidationRequest,
            selectedConditions,
            combinedFiltersAmount,
            areChartsVisible,
            toggleChartsVisibility,
        } = this.props;

        if (!dataCollections || selectedConditionsDataTypes == null) {
            return null;
        }

        return (
            <div>
                <SectionHeader>
                    <H3>{_('edp_data_explorer_filter_data')}</H3>
                </SectionHeader>
                {hasCdnRestrictions && (
                    <StyledAlert color="info" position="middle">
                        {_('refinement_filters_model_restrictions_warning')({
                            Link: LinkToCDN,
                        })}
                    </StyledAlert>
                )}
                {errorInRefinements && (
                    <StyledAlert color="warning" position="middle">
                        {_.get('refinement_filters_refine_selection_warning')}
                    </StyledAlert>
                )}
                <Form style={{ marginTop: 0 }}>
                    <FlexRow wrap="wrap">{this.renderFiltersControls()}</FlexRow>
                    <FlexRow align="center" justify="space-between" style={{ marginTop: 10 }}>
                        <FlexRow align="center">
                            {this.renderActionButtons()}
                            <ToggleChartsVisibilityButton
                                {...{ areChartsVisible, toggleChartsVisibility }}
                            />
                        </FlexRow>
                        {!!selectedConditions && (
                            <RunQueryButton
                                isLoading={isLoadingData}
                                disabled={isLoadingData || errorInRefinements}
                                onClick={() => {
                                    queryDataValidationRequest({
                                        conditions: selectedConditions,
                                    });
                                }}
                                filtersAmount={combinedFiltersAmount}
                            />
                        )}
                    </FlexRow>
                    <Clearfix />
                </Form>
            </div>
        );
    }
}

export default compose(
    withStorage,
    withRouter,
    withTheme,
    connect(mapStateToProps, dispatchProps)
)(EDPRefinementFiltersControls);
