import { cloneDeep, groupBy, isEmpty, maxBy, sortBy, uniqBy } from 'lodash/fp';

import { HealthThreatsMetricsRecords } from '@portal/common/models/data-type';
import {
    CAUSE_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    LOCATION_ID_KEY,
    MAX_PROBABILITY_CAUSE_ID,
    MAX_PROBABILITY_LEVEL,
    MAX_PROBABILITY_VALUE,
    START_DATE_KEY,
} from '@portal/common/models/data-key';
import {
    ChartType,
    DataKey,
    DataType,
    DataRecord,
    DataResponse,
    DataCondition,
} from '@portal/common/types';
import { TYPE_LINE_CHART, TYPE_SCATTER_MAP_CHART } from '@portal/common/models/chart-type';

import config from '../../config';

const getFilterIdx = (key: DataKey, columns: DataKey[]): number => columns.indexOf(key);
const getFilterValue = (
    key: DataKey,
    record: DataRecord,
    columns: DataKey[]
): number | string | null => record[getFilterIdx(key, columns)];

export default (
    dataResponsesByType: Record<DataType, DataResponse>,
    chartType: ChartType,
    allDataResponsesByType: Record<DataType, DataResponse> | null,
    conditions: DataCondition[]
) => {
    let newColumns;
    let newRecords;

    if (chartType === TYPE_LINE_CHART) {
        if (isEmpty(dataResponsesByType[HealthThreatsMetricsRecords])) return;

        let { columns, records } = dataResponsesByType[HealthThreatsMetricsRecords];
        newColumns = cloneDeep(columns);

        const startDateIdx = columns.indexOf(START_DATE_KEY);
        records = sortBy((record) => record[startDateIdx], records);
        const forecastScenarioIdx = newColumns.indexOf(FORECAST_SCENARIO_ID_KEY);
        newRecords = [...records];

        const recordsAmount = records.length;
        newRecords.forEach((record, idx) => {
            if (idx < recordsAmount - 3) {
                record[forecastScenarioIdx] = config.historicForecastScenarioId;
            }
        });
    } else if (chartType === TYPE_SCATTER_MAP_CHART) {
        let { columns, records: allRecords } =
            allDataResponsesByType?.[HealthThreatsMetricsRecords];

        newColumns = [...columns];
        newRecords = cloneDeep(allRecords);

        const forecastScenarioIdIdx = columns.indexOf(FORECAST_SCENARIO_ID_KEY);
        const startDateIdx = getFilterIdx(START_DATE_KEY, newColumns);
        const locationIdx = getFilterIdx(LOCATION_ID_KEY, newColumns);
        const causeIdx = getFilterIdx(CAUSE_ID_KEY, newColumns);

        newRecords = newRecords.filter(
            (record) => record[forecastScenarioIdIdx] === config.projectedForecastScenarioId
        );

        // forecast data is the same for different measures
        newRecords = uniqBy(
            (record) => `${record[startDateIdx]}_${record[locationIdx]}_${record[causeIdx]}`,
            newRecords
        );

        // add max probability level
        newColumns.push(MAX_PROBABILITY_VALUE);
        newColumns.push(MAX_PROBABILITY_LEVEL);
        newColumns.push(MAX_PROBABILITY_CAUSE_ID);

        const maxProbabilityIdx = getFilterIdx(MAX_PROBABILITY_VALUE, newColumns);
        const maxProbabilityLevelIdx = getFilterIdx(MAX_PROBABILITY_LEVEL, newColumns);
        const maxProbabilityCauseIdx = getFilterIdx(MAX_PROBABILITY_CAUSE_ID, newColumns);

        const maxLevel = 8;
        const levels = Array.from({ length: maxLevel }, (v, k) => k + 1);

        newRecords.forEach((record) => {
            const probs = levels
                .map((level) =>
                    getFilterValue(`prob_level_${level}` as DataKey, record, newColumns)
                )
                .map(Number);

            let prob: number = 0;
            let probLevel: number = 0;

            while (prob < 0.5 && probLevel < maxLevel) {
                prob += probs[probLevel];
                probLevel++;
            }

            const causeId = record[causeIdx];

            record.push(prob);
            record.push(probLevel);
            record.push(causeId);
        });

        // add All Pathogens option
        const recordsGroupedByDateAndLocation = groupBy(
            (record) => `${record[startDateIdx]}_${record[locationIdx]}`,
            newRecords
        );

        Object.values(recordsGroupedByDateAndLocation).forEach((items: DataRecord[]) => {
            const minLevel = Math.min(
                ...items.map((record) => Number(record[maxProbabilityLevelIdx]))
            );
            const minLevelRecords = items.filter(
                (record) => record[maxProbabilityLevelIdx] === minLevel
            );
            const maxProbabilityRecord = [
                ...(maxBy((record) => record[maxProbabilityIdx], minLevelRecords) as DataRecord),
            ];
            const maxProbabilityCauseId = maxProbabilityRecord[causeIdx];
            maxProbabilityRecord[causeIdx] = config.allPathogensCauseId;
            maxProbabilityRecord[maxProbabilityCauseIdx] = maxProbabilityCauseId;
            newRecords.push(maxProbabilityRecord);
        });
        const condition = conditions[0];
        const selectedConditionId = condition?.primary_entity_id || config.allPathogensCauseId;
        newRecords = newRecords.filter((record) => record[causeIdx] === selectedConditionId);
    }

    dataResponsesByType[HealthThreatsMetricsRecords] = {
        columns: newColumns,
        records: newRecords,
    };
};
