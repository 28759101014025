import React from 'react';

import {
    DataCollectionResource,
    DataCondition,
    DataExportFormat,
    DataFilters,
    DataGranularity,
    DataGranularityKey,
    DataResponse,
    DataToolConfig,
    DataType,
} from '@portal/common/types';
import { sortResponseByTimeUnit } from '@portal/common/utility/chart-data-helpers';
import { HealthThreatsMetricsRecords } from '@portal/common/models/data-type';

import config from '../../../../config';
import rescaleValuesForHeatmap from '../../../../utility/data/rescale-values-for-heatmap';

import Chart from './Chart';

type Props = {
    isForecastingData: boolean;
    forecastTimeUnitStart: number | string;
    initialTimeUnitValue: number | string;
    enableExportPNG: boolean;
    onDataLoad?: () => void;
    selectedConditions: DataCondition[];
    selectedConditionsDataTypes: DataType[];
    selectedConditionsDataTypesGranularity: DataGranularity;
    multipleFilterKeys: DataGranularityKey[];

    mergedChartDataResponses: null | DataResponse;
    isLoadingData: boolean;
    filtersValues: DataFilters;
    baseForecastScenarioId: number;
    observedForecastScenarioId: number | null;
    timeUnitKey: DataGranularityKey;
    onExportData?: (format: DataExportFormat) => void;
    numberFormatter: (value: number | string) => string;
    dataToolConfig: DataToolConfig;
    selectedDataTool: string;
    selectedDataCollection: DataCollectionResource;

    renderTitle?: (props) => string;
    renderSubtitle?: (props) => string;
    maxValuesSelectedFilter: DataGranularityKey;
};

export default class Container extends React.Component<Props> {
    static defaultProps = {
        enableExportPNG: true,
        multipleFilterKeys: [],
    };

    shouldComponentUpdate = (nextProps: Readonly<Props>): boolean => {
        const should = nextProps.isLoadingData !== this.props.isLoadingData;
        return should;
    };

    getPreparedProps = () => {
        const {
            mergedChartDataResponses,
            multipleFilterKeys,
            selectedConditions,
            timeUnitKey,
            filtersValues,
            selectedConditionsDataTypes,
        } = this.props;

        const isDALYSelected = filtersValues?.measure_id?.[0] === config.dalyMeasureId;

        const isHealthThreatsData = selectedConditionsDataTypes.includes(
            HealthThreatsMetricsRecords
        );

        const linesAmount = multipleFilterKeys.reduce((acc, key) => {
            const amount = (filtersValues[key] || '').toString().split(',').length;
            return acc * amount;
        }, selectedConditions.length);

        let mergedDataResponses = sortResponseByTimeUnit(
            mergedChartDataResponses,
            timeUnitKey,
            true
        );

        if (isHealthThreatsData) {
            mergedDataResponses = rescaleValuesForHeatmap(mergedChartDataResponses);
        }

        return {
            mergedDataResponses,
            linesAmount,
            applyPerformanceOptimization: linesAmount > 200,
            multipleFilterKeys,
            isHealthThreatsData: isHealthThreatsData,
            isHeatmapAllowed: isHealthThreatsData && isDALYSelected,
        };
    };

    render() {
        const {
            forecastTimeUnitStart,
            initialTimeUnitValue,
            selectedConditions,
            selectedConditionsDataTypes,
            selectedConditionsDataTypesGranularity,
            enableExportPNG,
            onDataLoad,
            isForecastingData,

            isLoadingData,
            filtersValues,
            baseForecastScenarioId,
            observedForecastScenarioId,
            timeUnitKey,
            onExportData,
            numberFormatter,
            selectedDataTool,
            dataToolConfig,
            selectedDataCollection,
            renderTitle,
            renderSubtitle,
            maxValuesSelectedFilter,
        } = this.props;

        return (
            <Chart
                isLoadingData={isLoadingData}
                filtersValues={filtersValues}
                isForecastingData={isForecastingData}
                forecastTimeUnitStart={forecastTimeUnitStart}
                initialTimeUnitValue={initialTimeUnitValue}
                selectedConditions={selectedConditions}
                selectedConditionsDataTypes={selectedConditionsDataTypes}
                selectedConditionsDataTypesGranularity={selectedConditionsDataTypesGranularity}
                enableExportPNG={enableExportPNG}
                onDataLoad={onDataLoad}
                baseForecastScenarioId={baseForecastScenarioId}
                observedForecastScenarioId={observedForecastScenarioId}
                timeUnitKey={timeUnitKey}
                onExportData={onExportData}
                numberFormatter={numberFormatter}
                selectedDataTool={selectedDataTool}
                dataToolConfig={dataToolConfig}
                selectedDataCollection={selectedDataCollection}
                renderTitle={renderTitle}
                renderSubtitle={renderSubtitle}
                maxValuesSelectedFilter={maxValuesSelectedFilter}
                {...this.getPreparedProps()}
            />
        );
    }
}
