import React, { FC, useCallback, useState } from 'react';

import {
    CaptionText1,
    SectionBody,
    H2,
    Accordion,
    FlexColumn,
    P,
    Table,
    TableHead,
    TableHeaderRow,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell,
    SvgIcon,
} from '@portal/common/components';
import { styled } from '@portal/common/theme';

import _ from '../../../locale/_';

type Props = {};

const MEASURES_DEFINITIONS = [
    {
        measure: _('measure_6'),
        definition: _('ght_measure_6_definition'),
    },
    {
        measure: _('measure_5'),
        definition: _('ght_measure_5_definition'),
    },
    {
        measure: _('measure_1'),
        definition: _('ght_measure_1_definition'),
    },
    {
        measure: _('measure_3'),
        definition: _('ght_measure_3_definition'),
    },
    {
        measure: _('measure_4'),
        definition: _('ght_measure_4_definition'),
    },
    {
        measure: _('measure_2'),
        definition: _('ght_measure_2_definition'),
    },
    {
        measure: _('measure_21'),
        definition: _('ght_measure_21_definition'),
    },
];

const StyledSectionBody = styled(SectionBody)(({ theme }) => ({
    padding: '15px !important',
    [theme.breakpoint.md]: {
        padding: '50px 30px 50px 30px !important',
    },
}));

const StyledFlexColumn = styled(FlexColumn)({
    padding: '15px !important',

    li: {
        marginLeft: 15,
    },
});

const StyledAccordion = styled(Accordion)({
    marginBottom: 15,

    ':before': {
        display: 'none !important',
    },
});

const StyledAccordionSummary = styled(Accordion.Summary)(({ theme }) => ({
    ':hover': {
        color: theme.color.green1,
        background: theme.color.white,
        svg: {
            color: theme.color.green1,
        },
    },
}));

const QAItem: FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleChange = useCallback(() => {
        setIsExpanded(!isExpanded);
    }, [isExpanded]);

    return (
        <StyledAccordion
            backgroundColor={(theme) => theme.color.white}
            TransitionProps={{ unmountOnExit: true }}
            expanded={isExpanded}
            onChange={handleChange}
        >
            <StyledAccordionSummary
                expandIcon={<SvgIcon src={'/icons/ic-arrow-down.svg'} size="large" />}
            >
                <H2>{title}</H2>
            </StyledAccordionSummary>
            <Accordion.Details>{children}</Accordion.Details>
        </StyledAccordion>
    );
};

const QASection: FC<Props> = ({}) => (
    <StyledSectionBody>
        <QAItem title={_('ght_qa_how_measure_loss_in_population_title')}>
            <StyledFlexColumn>
                <P>{_('ght_qa_how_measure_loss_in_population_text_1')}</P>
                <Table>
                    <TableHead>
                        <TableHeaderRow>
                            <TableHeaderCell>
                                {_('ght_qa_how_measure_loss_in_population_table_header_measure')}
                            </TableHeaderCell>
                            <TableHeaderCell>
                                {_('ght_qa_how_measure_loss_in_population_table_header_definition')}
                            </TableHeaderCell>
                        </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                        {MEASURES_DEFINITIONS.map((item) => (
                            <TableRow key={item.measure}>
                                <TableCell>
                                    <CaptionText1>{item.measure}</CaptionText1>
                                </TableCell>
                                <TableCell>{item.definition}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <br />
                <FlexColumn>{_('ght_qa_how_measure_loss_in_population_text_2')}</FlexColumn>
            </StyledFlexColumn>
        </QAItem>
        <QAItem title={_('ght_qa_how_categorize_loss_into_meaningful_threat_levels_title')}>
            <StyledFlexColumn>
                <P>{_('ght_qa_how_categorize_loss_into_meaningful_threat_levels_text')}</P>
            </StyledFlexColumn>
        </QAItem>
        <QAItem title={_('ght_qa_how_monitor_and_categorize_potential_health_threats_title')}>
            <StyledFlexColumn>
                {_('ght_qa_how_monitor_and_categorize_potential_health_threats_text')}
            </StyledFlexColumn>
        </QAItem>
    </StyledSectionBody>
);

export default QASection;
