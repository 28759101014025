import { isEmpty } from 'lodash/fp';

import { DataCollectionResource, DataCondition } from '@portal/common/types';
import { getDataTypeLocalePrefix } from '@portal/common/models/data-type';

import _ from '../locale';

export const areEqualConditions = (a: DataCondition, b: DataCondition): boolean =>
    a.data_type == b.data_type && a.primary_entity_id === b.primary_entity_id;

export const conditionComparisonFunc = (iteratee: DataCondition): string =>
    `${iteratee.data_type}_${iteratee.primary_entity_id}`;

export const localizeDataCondition = (data: DataCondition): string =>
    _(getDataTypeLocalePrefix(data.data_type) + data.primary_entity_id);

export const getDataConditionLocalePrefix = (condition: DataCondition): string =>
    getDataTypeLocalePrefix(condition.primary_entity_id);

export const isAtLeastOneConditionPresentInDataCollection = (
    conditions: DataCondition[],
    dataCollection: DataCollectionResource
) =>
    !!dataCollection.datasets.find((dataset) => dataset.primary_entity_id_filter === null) ||
    !!conditions.find((condition) => {
        const dataTypeDatasets = dataCollection.datasets.filter(
            (dataset) => dataset.data_type === condition.data_type
        );

        if (isEmpty(dataTypeDatasets)) {
            return false;
        }

        return !!dataTypeDatasets.find((dataset) => {
            const { granularity, primary_entity_id_filter } = dataset;
            const availablePrimaryEntityIds = granularity[primary_entity_id_filter];

            return (availablePrimaryEntityIds || []).includes(condition.primary_entity_id);
        });
    });
