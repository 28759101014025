import React, { FC } from 'react';

import { styled } from '@portal/common/theme';
import { SvgIcon } from '@portal/common/components';

import _ from '../../locale';

const Container = styled.div(({ theme }) => ({
    height: '100%',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    marginTop: 30,
}));

const Caption = styled.p(({ theme }) => ({
    paddingTop: 20,
    fontSize: 18,
    textAlign: 'center',
}));

const UnavailableDataError: FC = () => (
    <Container>
        <SvgIcon src="/icons/ic-warning.svg" size="huge2" />
        <Caption>
            <b>{_('permalink_unavailable_data')}</b>
        </Caption>
    </Container>
);

export default UnavailableDataError;
