import { intersection, isEmpty } from 'lodash/fp';

import {
    ChartType,
    DataCondition,
    DataFilters,
    DataGranularityKey,
    DataResponse,
    DataType,
} from '@portal/common/types';
import {
    AGE_GROUP_ID_KEY,
    ANTIBIOTIC_CLASS_ID_KEY,
    CAUSE_ID_KEY,
    COUNTERFACTUAL_ID_KEY,
    EDUCATION_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    INFECTIOUS_SYNDROME_ID_KEY,
    LOCATION_ID_KEY,
    MAP_DETAIL_LEVEL_ID_KEY,
    MAP_TYPE_ID_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    PATHOGEN_ID_KEY,
    PRIMARY_ENTITY_KEY,
    RACE_ID_KEY,
    RISK_EXPOSURE_ID_KEY,
} from '@portal/common/models/data-key';
import {
    TYPE_ARROW_CHART,
    TYPE_BAR_CHART,
    TYPE_DATA_TABLE_CHART,
    TYPE_LINE_CHART,
    TYPE_MAP_CHART,
    TYPE_SCATTER_MAP_CHART,
    TYPE_TREEMAP_CHART,
} from '@portal/common/models/chart-type';
import { getDataTypePrimaryEntityKey } from '@portal/common/models/data-type';
import { HEALTH_THREATS_METRICS_PATH } from '../../../router/paths';

export type ChartRefinementFilterConfig = {
    isMulti?: boolean;
    isPermanent?: boolean;
    selectAll?: boolean;
};

const CHART_REFINEMENT_FILTERS_CONFIG = {
    [TYPE_LINE_CHART]: {
        [HEALTH_THREATS_METRICS_PATH]: [
            { key: PRIMARY_ENTITY_KEY },
            { key: MEASURE_ID_KEY },
            { key: METRIC_ID_KEY },
            { key: LOCATION_ID_KEY },
        ],
        all: [{ key: MEASURE_ID_KEY }, { key: METRIC_ID_KEY }],
    },
    [TYPE_MAP_CHART]: [
        { key: PRIMARY_ENTITY_KEY },
        { key: PATHOGEN_ID_KEY },
        { key: INFECTIOUS_SYNDROME_ID_KEY },
        { key: CAUSE_ID_KEY },
        { key: RISK_EXPOSURE_ID_KEY },
        { key: MEASURE_ID_KEY },
        { key: GENDER_ID_KEY },
        { key: AGE_GROUP_ID_KEY },
        { key: FORECAST_SCENARIO_ID_KEY },
        { key: METRIC_ID_KEY },
        { key: ANTIBIOTIC_CLASS_ID_KEY },
        { key: COUNTERFACTUAL_ID_KEY },
        { key: RACE_ID_KEY },
        { key: MAP_DETAIL_LEVEL_ID_KEY, isPermanent: true },
        { key: MAP_TYPE_ID_KEY, isPermanent: true },
        { key: EDUCATION_ID_KEY },
    ],
    [TYPE_SCATTER_MAP_CHART]: [
        { key: PRIMARY_ENTITY_KEY },
        // { key: CAUSE_ID_KEY },
        { key: RISK_EXPOSURE_ID_KEY },
        { key: GENDER_ID_KEY },
        { key: AGE_GROUP_ID_KEY },
        // { key: MEASURE_ID_KEY },
        { key: METRIC_ID_KEY },
        { key: MAP_DETAIL_LEVEL_ID_KEY, isPermanent: true },
        { key: MAP_TYPE_ID_KEY, isPermanent: true },
        { key: EDUCATION_ID_KEY },
    ],
    [TYPE_BAR_CHART]: [
        { key: PATHOGEN_ID_KEY },
        { key: INFECTIOUS_SYNDROME_ID_KEY },
        { key: CAUSE_ID_KEY },
        { key: RISK_EXPOSURE_ID_KEY },
        { key: LOCATION_ID_KEY },
        { key: MEASURE_ID_KEY },
        { key: AGE_GROUP_ID_KEY },
        { key: FORECAST_SCENARIO_ID_KEY },
        { key: GENDER_ID_KEY, isMulti: true, selectAll: true },
        { key: METRIC_ID_KEY },
        { key: RACE_ID_KEY },
        { key: ANTIBIOTIC_CLASS_ID_KEY },
        { key: COUNTERFACTUAL_ID_KEY },
        { key: EDUCATION_ID_KEY },
    ],
    [TYPE_TREEMAP_CHART]: [
        { key: PATHOGEN_ID_KEY },
        { key: INFECTIOUS_SYNDROME_ID_KEY },
        { key: CAUSE_ID_KEY },
        { key: RISK_EXPOSURE_ID_KEY },
        { key: LOCATION_ID_KEY },
        { key: MEASURE_ID_KEY },
        { key: GENDER_ID_KEY },
        { key: FORECAST_SCENARIO_ID_KEY },
        { key: AGE_GROUP_ID_KEY },
        { key: METRIC_ID_KEY },
        { key: RACE_ID_KEY },
        { key: ANTIBIOTIC_CLASS_ID_KEY },
        { key: COUNTERFACTUAL_ID_KEY },
        { key: EDUCATION_ID_KEY },
    ],
    [TYPE_DATA_TABLE_CHART]: [],
    [TYPE_ARROW_CHART]: [
        { key: PRIMARY_ENTITY_KEY },
        { key: AGE_GROUP_ID_KEY },
        { key: CAUSE_ID_KEY },
        { key: GENDER_ID_KEY },
        { key: LOCATION_ID_KEY },
        { key: MEASURE_ID_KEY },
        { key: METRIC_ID_KEY },
        { key: RACE_ID_KEY },
        { key: EDUCATION_ID_KEY },
        { key: FORECAST_SCENARIO_ID_KEY },
    ],
};

export const getChartRefinementFiltersConfig = (
    chartType: ChartType,
    dataTool: string
): ChartRefinementFilterConfig[] => {
    const chartTypeGroup = CHART_REFINEMENT_FILTERS_CONFIG[chartType];
    return chartTypeGroup[dataTool] || chartTypeGroup['all'] || chartTypeGroup;
};
export const getAllowedChartRefinementFilters = (chartType: ChartType, dataTool: string) =>
    getChartRefinementFiltersConfig(chartType, dataTool).map(({ key }) => key);

export const areChartFiltersPartOfSelectedRefinementFilters = (
    filters,
    refinementFilters
): boolean =>
    !Object.entries(filters).find(
        ([key, value]) => !isPartOfSelectedRefinementFilters(key, value, refinementFilters)
    );

export const isPartOfSelectedRefinementFilters = (
    key: DataGranularityKey,
    value: number[],
    refinementFilters: DataFilters
) => {
    if (key === PRIMARY_ENTITY_KEY) {
        // @todo: check selected conditions
        return true;
    }

    if (!refinementFilters.hasOwnProperty(key)) {
        return false;
    }

    const selectedRefinementFilterValue = refinementFilters[key];

    if (selectedRefinementFilterValue === '' || selectedRefinementFilterValue === undefined) {
        // all values are selected
        return true;
    }

    return value.every((value) => selectedRefinementFilterValue.includes(value));
};

// @todo: measure execution time, maybe it's require optimization
export const filterDataResponses = (
    dataResponsesByType: Record<DataType, DataResponse>,
    filters: DataFilters,
    conditions: DataCondition[]
): Record<DataType, DataResponse> => {
    const filteredDataResponses = {};

    Object.entries(dataResponsesByType).map(([dataType, dataResponse]) => {
        const dataTypeConditions = conditions.filter(({ data_type }) => data_type === dataType);
        const filtersWithPrimaryEntities = { ...filters };
        if (!isEmpty(dataTypeConditions)) {
            const primaryEntityKey = getDataTypePrimaryEntityKey(dataType);
            filtersWithPrimaryEntities[primaryEntityKey] = dataTypeConditions.map(
                ({ primary_entity_id }) => primary_entity_id
            );
        }
        const { columns, records } = dataResponse;
        const keys = intersection(columns, Object.keys(filtersWithPrimaryEntities));
        const keysIdxs = keys.map((key) => columns.indexOf(key));

        let keyIdx, filterValues, recordValue;
        const filteredRecords = records.filter((record) =>
            keys.every((key: string, idx: number) => {
                keyIdx = keysIdxs[idx];
                filterValues = filtersWithPrimaryEntities[key];
                recordValue = record[keyIdx];
                return filterValues.includes(recordValue);
            })
        );

        if (filteredRecords && filteredRecords.length) {
            filteredDataResponses[dataType] = {
                columns,
                records: filteredRecords,
            };
        }
    });

    return filteredDataResponses;
};
