import * as React from 'react';
import { FC, useCallback, useMemo } from 'react';
import { intersection, isEmpty } from 'lodash/fp';

import { styled } from '@portal/common/theme';
import { DataFilters, DataGranularity, DataType } from '@portal/common/types';
import { Button, FlexRow, Tooltip } from '@portal/common/components';
import { METRIC_ID_KEY } from '@portal/common/models/data-key';

import _ from '../../../locale';

type Props = {
    setModalVisibility: (isVisible: boolean) => any;
    selectedConditionsDataTypes: DataType[];
    selectedRefinementFilters: DataFilters;
    supportedMetrics: number[];
    supportedDataTypes: DataType[];
    disabled: boolean;
    selectedFilters: DataFilters;
    granularity: DataGranularity | null;
};

const StyledRow = styled(FlexRow)(({ theme }) => ({
    marginTop: -30,
    marginBottom: 15,
}));

const StyledButton = styled(Button)(({ theme }) => ({
    padding: '0',
    minHeight: ' 40px',
    height: '40px',
}));

const ExportSingleAgeEstimatesButton: FC<Props> = ({
    selectedConditionsDataTypes,
    selectedRefinementFilters,
    setModalVisibility,
    supportedMetrics,
    supportedDataTypes,
    disabled,
    selectedFilters,
    granularity,
    ...props
}) => {
    const openExportSingleAgeEstimatesModal = useCallback(() => {
        setModalVisibility(true);
    }, [setModalVisibility]);
    const isSupportedDataTypeSelected = useMemo(
        () => !isEmpty(intersection(selectedConditionsDataTypes, supportedDataTypes)),
        [selectedConditionsDataTypes]
    );

    const selectedMetrics: number[] = useMemo(
        () => selectedRefinementFilters[METRIC_ID_KEY] || [],
        [selectedRefinementFilters]
    ) as number[];
    const isSupportedMetricSelected = useMemo(
        () => !isEmpty(intersection(selectedMetrics, supportedMetrics)),
        [selectedMetrics]
    );

    const isDisabled = disabled || !(isSupportedDataTypeSelected && isSupportedMetricSelected);

    const tooltipTitle = useMemo(() => {
        let title = ``;
        if (!isSupportedDataTypeSelected) {
            title += _('export_single_age_disabled_tooltip_label', {
                unsupported: selectedConditionsDataTypes
                    .map((type) => _(`data_type_${type}`))
                    .join(),
                supported: supportedDataTypes.map((dataType) => _(dataType)),
            });
        }
        if (!isSupportedMetricSelected && !isEmpty(selectedMetrics)) {
            title += isSupportedDataTypeSelected ? '' : '\n';
            title += _('export_single_age_disabled_tooltip_label', {
                unsupported: selectedMetrics.map((unit) => _(`metric_${unit}`)).join(),
                supported: supportedMetrics.map((id) => _(`metric_${id}`)),
            });
        }
        if (
            isSupportedDataTypeSelected &&
            isSupportedMetricSelected &&
            disabled &&
            !isEmpty(granularity)
        ) {
            title += isSupportedDataTypeSelected && isSupportedMetricSelected ? '' : '\n';

            const [missingKey, missingValues] = Object.entries(selectedFilters).find(
                ([key, value]) => isEmpty(intersection(granularity?.[key], value as number[]))
            );

            if (missingKey && missingValues && !isEmpty(granularity[missingKey])) {
                title += _('export_single_age_disabled_tooltip_label', {
                    unsupported: `${missingValues.join(', ')} ${missingKey}`,
                    supported: granularity[missingKey].join(', '),
                });
            }
        }
        return title;
    }, [
        isDisabled,
        isSupportedDataTypeSelected,
        isSupportedMetricSelected,
        selectedConditionsDataTypes,
        selectedRefinementFilters,
    ]);
    const button = (
        <StyledButton
            onClick={openExportSingleAgeEstimatesModal}
            disabled={isDisabled}
            isTextButton
            beforeIconSrc="/icons/ic-file.svg"
            {...props}
        >
            {_('export_single_age_estimates_label')}
        </StyledButton>
    );

    return (
        <StyledRow>
            {isDisabled ? (
                <Tooltip placement="right" title={tooltipTitle}>
                    {button}
                </Tooltip>
            ) : (
                button
            )}
        </StyledRow>
    );
};

export default ExportSingleAgeEstimatesButton;
