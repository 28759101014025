import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
    H2,
    PageContainer,
    PageHeader,
    PageBody,
    PageBodyFooter,
    SectionBody,
} from '@portal/common/components';
import { styled } from '@portal/common/theme';

import _ from '../../locale';
import ContactUsForm from './ContactUsForm';

const Container = styled.div({
    width: 400,
    margin: '60px auto',
});

const ContactUsScene: React.FC<RouteComponentProps> = () => {
    return (
        <PageContainer>
            <PageHeader>
                <H2>{_.get('contact_us_scene_title')}</H2>
            </PageHeader>
            <PageBody>
                <SectionBody style={{ flexGrow: 1, marginTop: 0 }}>
                    <Container>
                        <ContactUsForm />
                    </Container>
                </SectionBody>
                <PageBodyFooter>{_.get('footer_note')}</PageBodyFooter>
            </PageBody>
        </PageContainer>
    );
};

export default ContactUsScene;
