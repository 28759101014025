import { LocationEntity } from '@portal/common/types';
import { isLocationTypeGlobal } from '@portal/common/models/location-types';
import { keyBy } from 'lodash';
import { RootState } from 'MyTypes';
import { createSelector } from 'reselect';

import getLocationsHierarchyKey from '../../utility/get-location-hierarchy-key';

export const getEntityHierarchiesLoaded = ({
    entityHierarchies: {
        causes,
        etiologies,
        impairments,
        injuries,
        locations,
        risks,
        sequelas,
        sevs,
        causeICDCodes,
        vehss,
    },
}: RootState): boolean =>
    !!causes &&
    !!causeICDCodes &&
    !!etiologies &&
    !!impairments &&
    !!injuries &&
    !!locations &&
    !!risks &&
    !!sequelas &&
    !!sevs &&
    !!vehss;

export const createGetEntityHierachyOf =
    <Key extends keyof RootState['entityHierarchies']>(key: Key) =>
    ({ entityHierarchies }: RootState): RootState['entityHierarchies'][Key] =>
        entityHierarchies[key];

export const createGetEntityHierarchyByIdOf = <Key extends keyof RootState['entityHierarchies']>(
    key: Key
) =>
    createSelector(
        createGetEntityHierachyOf(key),
        (
            entityHierarchy: RootState['entityHierarchies'][Key]
        ): Record<number, RootState['entityHierarchies'][Key]> | null => {
            if (entityHierarchy == null) {
                return null;
            }

            return keyBy(entityHierarchy, 'id');
        }
    );

export const getCauses = createGetEntityHierachyOf('causes');
export const getCausesById = createGetEntityHierarchyByIdOf('causes');

export const getSequelas = createGetEntityHierachyOf('sequelas');
export const getSequelasById = createGetEntityHierarchyByIdOf('sequelas');

export const getRisks = createGetEntityHierachyOf('risks');
export const getRisksById = createGetEntityHierarchyByIdOf('risks');

export const getEtiologies = createGetEntityHierachyOf('etiologies');
export const getEtiologiesById = createGetEntityHierarchyByIdOf('etiologies');

export const getImpairments = createGetEntityHierachyOf('impairments');
export const getImpairmentsById = createGetEntityHierarchyByIdOf('impairments');

export const getInjuries = createGetEntityHierachyOf('injuries');
export const getInjuriesById = createGetEntityHierarchyByIdOf('injuries');

export const getSevs = createGetEntityHierachyOf('sevs');
export const getSevsById = createGetEntityHierarchyByIdOf('sevs');

export const getCauseICDCodes = createGetEntityHierachyOf('causeICDCodes');
export const getCauseICDCodesById = createGetEntityHierarchyByIdOf('causeICDCodes');

export const getLocations = createGetEntityHierachyOf('locations');
export const getLocationsById = createGetEntityHierarchyByIdOf('locations');

export const getLocationsByDataset = createGetEntityHierachyOf('locationsByDataset');

export const getDatasetLocations = (state: RootState): LocationEntity[] | null => {
    const key = getLocationsHierarchyKey(state);
    return key ? getLocationsByDataset(state)?.[key] : null;
};

export const getDatasetLocationsById = createSelector(
    getLocations,
    (hierarchy): Record<number, LocationEntity[]> | null => {
        if (!hierarchy) {
            return null;
        }

        return keyBy(hierarchy, 'id') as Record<number, LocationEntity[]>;
    }
);

export const getLocationChildrenById = createSelector(
    getLocations,
    (locations): Record<number, LocationEntity[]> | null => {
        if (!locations) {
            return null;
        }

        return locations.reduce((locationChildrenById, location) => {
            // for each location that has children
            if (!isLocationTypeGlobal(location.location_type_id)) {
                // find all direct children locations
                const locationChildren = locations.filter((possibleChild) => {
                    let possibleChildParentId =
                        possibleChild[`level${possibleChild.level - 1}_parent_id`];
                    return possibleChildParentId === location.id;
                });
                locationChildrenById[location.id] = locationChildren.length
                    ? locationChildren
                    : null;
            }
            return locationChildrenById;
        }, {});
    }
);
