import { RootState } from 'MyTypes';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import copy from 'clipboard-copy';

import { ConfirmationModal, FlexRow, SvgIcon } from '@portal/common/components';

import api from '../../api';
import _ from '../../locale';
import * as Paths from '../../router/paths';
import {
    removeFavoriteResource,
    removePermalinkResourceAsync,
    removeResourceListResource,
} from '../../store/resource-lists/actions';
import { getOrganizationId, getSessionAccountId, isManager } from '../../store/root-reducer';
import { getCustomLists, getPublicLists } from '../../store/resource-lists/selectors';

import { PERMALINK_PATH } from '../../router/paths';
import config from '../../config';

import AddToListResourceListDropdown from '../AddToListDropdown/AddToListResourceListDropdown';
import ResourceList from '../ResourceList';
import CopyIcon from '../CopyIcon';

const mapStateToProps = (state: RootState) => ({
    organizationId: getOrganizationId(state),
    memberId: getSessionAccountId(state),
    favoriteResources: state.resourceLists.favoriteResources,
    customLists: getCustomLists(state),
    publicLists: getPublicLists(state),
    isManager: isManager(state),
});

const dispatchProps = {
    removeFavoriteResource: removeFavoriteResource,
    removeResourceListResource: removeResourceListResource,
    removePermalinkResourceAsync: removePermalinkResourceAsync.request,
};
type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    React.ComponentProps<typeof ResourceList> &
    RouteComponentProps<{ listId: string }>;

const FavoriteResourceList = (props: Props) => {
    const [isVisibleRemoveResourceModal, setIsVisibleRemoveResourceModal] = useState({});

    const {
        match,
        organizationId,
        memberId,
        favoriteResources,
        customLists,
        publicLists,
        isManager,
        removeFavoriteResource,
        removeResourceListResource,
        removePermalinkResourceAsync,
    } = props;

    const listId = match.params.listId;
    const url = match.url;
    const list = (customLists || [])
        .concat(publicLists || [])
        .find((i) => i.id.toString() === listId);

    const renderActionsCell = (item, renderProps) => {
        let isItemFavorited = false;
        if (favoriteResources != null || customLists != null) {
            isItemFavorited =
                (favoriteResources || []).map((i) => i.id).includes(item.id) ||
                (customLists || [])
                    .filter((list) => list.creator && list.creator.id === memberId)
                    .some(({ id, name, resource_ids = [] }) => resource_ids.includes(item.id));
        }
        const favoriteIcon = ({ ref, toggleVisibility }) =>
            isItemFavorited ? (
                <div ref={ref}>
                    <SvgIcon
                        src={'/icons/ic-star.svg'}
                        tooltip={_('tooltip_add_to_list')}
                        size="large"
                        color={(theme) => theme.color.primary1}
                        style={{ zIndex: 1 }}
                        onClick={toggleVisibility}
                    />
                </div>
            ) : (
                <div ref={ref}>
                    <SvgIcon
                        src={'/icons/ic-star-outline.svg'}
                        tooltip={_('tooltip_add_to_list')}
                        size="large"
                        color={(theme) => theme.color.gray40}
                        style={{ zIndex: 1 }}
                        onClick={toggleVisibility}
                    />
                </div>
            );

        const isPublicList = list && list.is_public;
        const isMyPublicList =
            list && list.is_public && list.creator && list.creator.id === memberId;
        const isEditableList =
            isManager ||
            (url.indexOf(Paths.FAVORITE_CONTENT_PATH + Paths.MY_LISTS_PATH) === 0 &&
                (isMyPublicList || !isPublicList)) ||
            null;

        const addToFavoriteIcon = listId !== 'permalink' && (
            <AddToListResourceListDropdown
                item={item}
                onSuccess={() => {
                    renderProps.loadItems();
                }}
                placement="auto-end"
            >
                {({ ref, toggleVisibility }) => favoriteIcon({ ref, toggleVisibility })}
            </AddToListResourceListDropdown>
        );

        const copyIcon = listId === 'permalink' && (
            <CopyIcon
                onClick={() => {
                    copy(config.clientUrl + PERMALINK_PATH + '/' + item.hash);
                }}
            />
        );

        const deleteIcon = isEditableList && (
            <SvgIcon
                data-testid="delete-icon"
                src={'/icons/ic-trash.svg'}
                size="large"
                tooltip={_('tooltip_delete')}
                color={(theme) => theme.color.red}
                style={{ zIndex: 1 }}
                onClick={() => {
                    setIsVisibleRemoveResourceModal((state) => ({ ...state, [item.id]: true }));
                }}
            />
        );

        const handleRemoveResource = (item) => {
            if (listId === Paths.MY_FAVORITES_ID) {
                // removing favorite list resources
                api.organizationMemberFavoriteResource
                    .removeResourceFromFavorites({
                        organizationId,
                        memberId,
                        item,
                    })
                    .then((res) => {
                        removeFavoriteResource({ resourceId: res.id });
                        renderProps.loadItems();
                    });
            } else if (listId === 'permalink') {
                removePermalinkResourceAsync({
                    organizationId,
                    memberId,
                    permalinkId: item.id,
                    loadItems: renderProps.loadItems,
                });
            } else {
                // removing non-favorite list resources
                api.organizationResourceListResource
                    .removeResourceFromOrganizationResourceList({
                        organizationId,
                        listId: Number(listId),
                        item,
                    })
                    .then((res) => {
                        removeResourceListResource({ listId: Number(listId), resourceId: res.id });
                        renderProps.loadItems();
                    });
            }
        };

        return (
            <FlexRow itemsSpacing={30} align="center">
                {addToFavoriteIcon}
                {deleteIcon}
                {copyIcon}
                <ConfirmationModal
                    title={_.get('lists_remove_resource_modal_title')}
                    message={_.get('lists_remove_modal_question')}
                    isVisible={isVisibleRemoveResourceModal[item.id]}
                    onClose={() =>
                        setIsVisibleRemoveResourceModal((state) => ({ ...state, [item.id]: false }))
                    }
                    onSubmit={() => {
                        setIsVisibleRemoveResourceModal((state) => ({
                            ...state,
                            [item.id]: false,
                        }));
                        handleRemoveResource(item);
                    }}
                />
            </FlexRow>
        );
    };

    return <ResourceList renderActionsCell={renderActionsCell} {...props} />;
};

export default compose(withRouter, connect(mapStateToProps, dispatchProps))(FavoriteResourceList);
