import React, { useEffect, useState } from 'react';
import { groupBy, isEmpty } from 'lodash/fp';

import { DataCollectionResource, DataCondition } from '@portal/common/types';
import { BodyText2, FlexColumn } from '@portal/common/components';
import {
    AGE_GROUP_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    MEASURE_ID_KEY,
    PRIMARY_ENTITY_ID_KEY,
    ROUND_ID_KEY,
    VALUE_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import { LifeExpectancyRecords, MultipleMyelomaPopulation } from '@portal/common/models/data-type';
import formatPdfValue from '@portal/common/utility/formatting/formatPdfValue';

import _ from '../../../locale';

import useGetApiData from '../hooks/use-get-api-data';
import config from '../../../config';

import Title from '../components/Title';

type Props = {
    locationId: number;
    dataCollection: DataCollectionResource;
};

const PopulationCharacteristics = ({ locationId, dataCollection }: Props) => {
    const locationName = _(`location_${locationId}`);

    const [currentPopulationAmount, setCurrentPopulationAmount] = useState<number | null>(null);
    const [populationAmountBy2030, setPopulationAmountBy2030] = useState<number | string | null>(
        'N/A'
    );
    const [populationAmountBy2050, setPopulationAmountBy2050] = useState<number | string | null>(
        'N/A'
    );
    const [currentLifeExpectancy, setCurrentLifeExpectancy] = useState<number | string | null>(
        'N/A'
    );
    const [projectedLifeExpectancy, setProjectedLifeExpectancy] = useState<number | string | null>(
        'N/A'
    );

    const populationConditions = [
        {
            data_type: MultipleMyelomaPopulation,
            [PRIMARY_ENTITY_ID_KEY]: 1,
        },
    ] as DataCondition[];

    const populationRoundId =
        dataCollection?.datasets?.find((dataset) => dataset.data_type === MultipleMyelomaPopulation)
            ?.granularity?.round_id?.[0] || 121;

    const populationFilters = {
        [LOCATION_ID_KEY]: [locationId],
        [AGE_GROUP_ID_KEY]: [config.seventyFiveToNineAgeGroupId, 30, 31, 32, 235],
        [GENDER_ID_KEY]: [config.bothGendersId],
        [ROUND_ID_KEY]: [populationRoundId],
        [FORECAST_SCENARIO_ID_KEY]: [config.referenceForecastScenarioId],
        [YEAR_KEY]: [2019, 2030, 2050],
    };

    const { isLoading: isPopulationDataLoading, dataResponse: populationDataResponse } =
        useGetApiData({
            conditions: populationConditions,
            filters: populationFilters,
        });

    useEffect(() => {
        if (!isPopulationDataLoading && populationDataResponse) {
            const { columns, records } = populationDataResponse;
            const valueIdx = columns.indexOf(VALUE_KEY);
            const yearIdx = columns.indexOf(YEAR_KEY);
            if (!isEmpty(records)) {
                const recordsByYear = groupBy((record) => record[yearIdx], records);
                const getTotalValueForYear = (year: number) =>
                    recordsByYear[year]
                        .map((record) => parseFloat(record[valueIdx].toString()))
                        .reduce((a, b) => a + b, 0);
                setCurrentPopulationAmount(getTotalValueForYear(2019));
                setPopulationAmountBy2030(getTotalValueForYear(2030));
                setPopulationAmountBy2050(getTotalValueForYear(2050));
            }
        }
    }, [isPopulationDataLoading, populationDataResponse]);

    const lifeExpectancyConditions = [
        {
            data_type: LifeExpectancyRecords,
            [PRIMARY_ENTITY_ID_KEY]: 1,
        },
    ] as DataCondition[];

    const lifeExpectancyFilters = {
        [LOCATION_ID_KEY]: [locationId],
        [AGE_GROUP_ID_KEY]: [config.birthAgeGroupId],
        [GENDER_ID_KEY]: [config.bothGendersId],
        [MEASURE_ID_KEY]: [config.lifeExpectancyMeasureId],
        [ROUND_ID_KEY]: [config.gbd2017RoundId],
        [FORECAST_SCENARIO_ID_KEY]: [config.referenceForecastScenarioId],
        [YEAR_KEY]: [2019, 2050],
    };

    const { isLoading: isLifeExpectancyDataLoading, dataResponse: lifeExpectancyDataResponse } =
        useGetApiData({
            conditions: lifeExpectancyConditions,
            filters: lifeExpectancyFilters,
        });

    useEffect(() => {
        if (!isLifeExpectancyDataLoading && lifeExpectancyDataResponse) {
            const { columns, records } = lifeExpectancyDataResponse;
            const valueIdx = columns.indexOf(VALUE_KEY);
            const yearIdx = columns.indexOf(YEAR_KEY);
            if (!isEmpty(records)) {
                const recordsByYear = groupBy((record) => record[yearIdx], records);
                const getYearValue = (year: number) => recordsByYear[year][0][valueIdx];
                setCurrentLifeExpectancy(getYearValue(2019));
                setProjectedLifeExpectancy(getYearValue(2050));
            }
        }
    }, [isLifeExpectancyDataLoading, lifeExpectancyDataResponse]);

    return (
        <>
            <Title>{_('simulation_tool_1_2_title', { location: locationName })}</Title>

            <FlexColumn itemsSpacing={20}>
                <BodyText2>
                    {_('simulation_tool_1_2_description_1', {
                        location: locationName,
                        currentPopulation: formatPdfValue(currentPopulationAmount),
                        population2030: formatPdfValue(populationAmountBy2030),
                        population2050: formatPdfValue(populationAmountBy2050),
                    })}
                </BodyText2>
                <BodyText2>
                    {_('simulation_tool_1_2_description_2', {
                        location: locationName,
                        currentLifeExpectancy: formatPdfValue(currentLifeExpectancy, false),
                        projectedLifeExpectancy: formatPdfValue(projectedLifeExpectancy, false),
                    })}
                </BodyText2>
                <BodyText2 align="center">
                    {_('simulation_tool_1_2_image_title', { location: locationName })}
                </BodyText2>
                <div
                    key={`butterfly-chart-${locationId}`}
                    style={{
                        background: `url("img/simulation-tool/butterfly-chart-${locationId}.jpg") no-repeat`,
                        backgroundSize: 'contain',
                        height: 350,
                        width: '100%',
                        backgroundPosition: 'top center',
                    }}
                />
            </FlexColumn>
        </>
    );
};

export default PopulationCharacteristics;
