import { isEmpty } from 'lodash/fp';

import { DataResponse } from '@portal/common/types';
import { RESCALED_HEATMAP_VALUE, VALUE_KEY } from '@portal/common/models/data-key';

import config from '../../config';

export default (response: DataResponse | null): DataResponse => {
    if (!response) {
        return { columns: [], records: [] };
    }

    const { columns, records } = response;
    if (isEmpty(columns) || isEmpty(records)) {
        return response;
    }

    const valueIdx = columns.indexOf(VALUE_KEY);
    const extendedRecords = records.map((record) => [
        ...record,
        rescaleValue(record[valueIdx] as number),
    ]);

    return {
        columns: [...columns, RESCALED_HEATMAP_VALUE],
        records: extendedRecords,
    };
};

const findInterval = (
    x: number,
    vec: number[],
    rightmostClosed = false,
    allInside = true
): number => {
    let low = 0;
    let high = vec.length;

    while (low < high) {
        const mid = Math.floor((low + high) / 2);

        if (vec[mid] < x || (rightmostClosed && vec[mid] === x)) {
            low = mid + 1;
        } else {
            high = mid;
        }
    }

    return allInside ? low : low + 1;
};

const rescaleValue = (value: number): number => {
    if (value === 0) return 0;

    let rescaledValue: number;

    const level: number = findInterval(value, config.GHT_bins, false, true);

    if (level === 1) {
        rescaledValue = value / 10; // max(0, Math.log10(x + 1))
    } else {
        const log10_l: number = Math.log10(config.GHT_bins[level - 1]);
        const log10_x: number = Math.log10(value);
        const log10_u: number = Math.log10(config.GHT_bins[level]);
        rescaledValue = level + (log10_x - log10_l) / (log10_u - log10_l) - 1;
    }

    return rescaledValue;
};
