import React from 'react';
import { compose } from 'redux';

import withQueryParams, {
    WithQueryParamsInjectedProps,
} from '@ihme/common/utility/hoc/with-query-params';
import {
    DataTableWithQueryParams,
    EmptyLabel,
    SvgIcon,
    TableCell,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
} from '@portal/common/components';
import { OrganizationMemberAPICredentials } from '@portal/common/types';

import api from '../../api';
import _ from '../../locale';
import formatDate from '@portal/common/utility/formatting/formatDate';

type Props = WithQueryParamsInjectedProps<any> & {
    organizationId: number;
    memberId: number;
    secretsById: Record<number, string>;
};

class ApiCredentialsTable extends React.PureComponent<Props> {
    render() {
        const { queryParams, setQueryParams, organizationId, memberId } = this.props;

        return (
            <DataTableWithQueryParams
                queryParams={queryParams}
                setQueryParams={setQueryParams}
                defaultQueryParams={{
                    per_page: '10',
                    order_by: 'created',
                }}
                loadItems={(params) =>
                    api.organization.getOrganizationMemberAPICredentials({
                        organizationId,
                        memberId,
                        ...params,
                    })
                }
                removeItem={(apiCredentialsId) =>
                    api.organization.removeOrganizationMemberAPICredentials({
                        organizationId,
                        memberId,
                        apiCredentialsId,
                    })
                }
                renderHeaders={(renderProps) => (
                    <TableHeaderRow>
                        <TableHeaderCell>
                            {_('api_credentials_table_column_access_key')}
                        </TableHeaderCell>
                        <TableHeaderCell>
                            {_('api_credentials_table_column_created')}
                        </TableHeaderCell>
                        <TableHeaderCell>
                            {_('api_credentials_table_column_accessed')}
                        </TableHeaderCell>
                        <TableHeaderCell>
                            {_('api_credentials_table_column_actions')}
                        </TableHeaderCell>
                    </TableHeaderRow>
                )}
                renderItem={(item: OrganizationMemberAPICredentials, props) => (
                    <TableRow key={item.id}>
                        <TableCell width="5%" data-private>
                            {item.access_key}
                        </TableCell>
                        <TableCell width="25%">{formatDate(item.created)}</TableCell>
                        <TableCell width="30%">
                            {item.last_access_date ? formatDate(item.last_access_date) : 'Never'}
                        </TableCell>
                        <TableCell width="15%">
                            <SvgIcon
                                size="large"
                                color={(theme) => theme.color.red}
                                alt="delete"
                                src="/icons/ic-trash.svg"
                                onClick={() => props.removeItem(item.id)}
                            />
                        </TableCell>
                    </TableRow>
                )}
                renderEmpty={(props) => {
                    return _('api_credentials_table_empty');
                }}
            />
        );
    }
}

export default compose(withQueryParams)(ApiCredentialsTable);
