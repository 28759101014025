import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';

import { DataGranularityKey } from '@portal/common/types';
import {
    AGE_GROUP_ID_KEY,
    CAUSE_ID_KEY,
    LOCATION_ID_KEY,
    MAP_DETAIL_LEVEL_ID_KEY,
    MAP_TYPE_ID_KEY,
} from '@portal/common/models/data-key';
import { getLocalePrefixForGranularityKey } from '@portal/common/utility/filters-helpers';

import {
    getChartSelectedFiltersGranularity,
    getChartSelectedRefinementFilters,
    getSelectedChartType,
    getSelectedDatasetsConfig,
} from '../../../store/data-explorer/selectors';
import { ChartRefinementFilterConfig } from './utils';

import _ from '../../../locale';

import AgeGroupsDropdown from '../../../components/AgeGroupsDropdown';
import ChartDropdown from '../../../components/ChartDropdown';
import ChartHierarchicalDropdown from '../../../components/ChartHierarchicalDropdown';
import DetailLevelDropdown from './MapChart/DetailLevelDropdown';
import MapTypeDropdown from './MapChart/MapTypeDropdown';

const mapStateToProps = (state: RootState) => ({
    entityHierarchies: state.entityHierarchies,
    granularity: getChartSelectedFiltersGranularity(state),
    filters: getChartSelectedRefinementFilters(state),
    chartType: getSelectedChartType(state),
    datasetConfig: getSelectedDatasetsConfig(state),
});

type Props = ReturnType<typeof mapStateToProps> &
    ChartRefinementFilterConfig & {
        granularityKey: DataGranularityKey;
        onChange: (DataFilters) => void;
    };

class ChartRefinementFilter extends React.PureComponent<Props> {
    handleFilterChange = (value) => {
        const { granularityKey, onChange } = this.props;
        onChange({ [granularityKey]: value });
    };

    render() {
        const { entityHierarchies, granularity, granularityKey, filters, isMulti, datasetConfig } =
            this.props;

        let control;

        const label = _('refinement_' + granularityKey).toUpperCase();
        const localePrefix = getLocalePrefixForGranularityKey(granularityKey);

        const sharedProps = {
            disableStatusCheck: true,
            skipRefinementFiltersUpdate: true,
            isSearchable: false,
            isMulti: isMulti,
            label,
            granularity,
            filterKey: granularityKey,
            filterValue: filters && filters[granularityKey],
            optionLabelGetter: (o) => _.withPrefix(localePrefix, o),
            optionDisabledGetter: (o) => false,
            onChange: this.handleFilterChange,
            multiselectLabel: null,
        };

        // TODO: adding missing chart filters, but why they are not in the granularity?
        if (datasetConfig.autoSelectedChartFilters) {
            Object.entries(datasetConfig.autoSelectedChartFilters).map(
                ([filterKey, silentlySelectedValue]) => {
                    sharedProps.granularity[filterKey] = (
                        sharedProps.granularity[filterKey] || []
                    ).filter((value) => !silentlySelectedValue.includes(value));
                }
            );
        }

        switch (granularityKey) {
            case CAUSE_ID_KEY:
                control = (
                    <ChartHierarchicalDropdown
                        {...sharedProps}
                        useLetterPrefix={false}
                        loadHierarchy={() => Promise.resolve(entityHierarchies.causes)}
                        optionLabelGetter={(o) => o.label}
                        optionValueGetter={(o) => o.value}
                    />
                );
                break;

            case LOCATION_ID_KEY:
                control = (
                    <ChartHierarchicalDropdown
                        {...sharedProps}
                        limitHierarchyDepth={6}
                        useLetterPrefix={false}
                        loadHierarchy={() => Promise.resolve(entityHierarchies.locations)}
                        optionLabelGetter={(o) => o.label}
                        optionValueGetter={(o) => o.value}
                    />
                );
                break;

            case AGE_GROUP_ID_KEY:
                control = <AgeGroupsDropdown {...sharedProps} />;
                break;

            case MAP_DETAIL_LEVEL_ID_KEY:
                control = (
                    <DetailLevelDropdown selectedRefinementFilters={filters} {...sharedProps} />
                );
                break;

            case MAP_TYPE_ID_KEY:
                control = <MapTypeDropdown {...sharedProps} />;
                break;

            default:
                control = <ChartDropdown {...sharedProps} />;
                break;
        }

        return control;
    }
}

export default connect(mapStateToProps, null)(ChartRefinementFilter);
