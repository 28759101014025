import React from 'react';

import { H4 } from '@portal/common/components';
import { styled } from '@portal/common/theme';

const StyledH4 = styled(H4)(({ theme }) => ({
    paddingTop: 10,
    paddingBottom: 20,
}));

type Props = {
    children: any;
};

const Title = ({ children, ...props }: Props) => <StyledH4 {...props}>{children}</StyledH4>;

export default Title;
