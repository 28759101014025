import React from 'react';
import { Fab, SvgIcon, Fade } from '@portal/common/components';

import { InjectedMediaProps, styled, withMedia } from '@portal/common/theme';

const Container = styled('div')(({ theme }) => ({
    position: 'absolute',
    right: -15,
    top: 140,
    zIndex: theme.zIndex.popovers,
}));

const MinimizeSidebarButton = ({ visible, minimized, onClick, ...props }) => {
    const handleClick = (event) => {
        onClick(event);
    };

    return (
        <Fade in={visible}>
            <Container onClick={handleClick} {...props}>
                <Fab aria-label="Expand / Collapse">
                    <SvgIcon
                        src="/icons/ic-arrow-down.svg"
                        size="medium"
                        style={{ transform: minimized ? 'rotate(-90deg)' : 'rotate(90deg)' }}
                    />
                </Fab>
            </Container>
        </Fade>
    );
};

export default MinimizeSidebarButton;
