import React from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';

import {
    DataCollectionResource,
    DataCondition,
    DataExportFormat,
    DataFilters,
    DataGranularity,
    DataGranularityKey,
    DataResponse,
    DataType,
    TimeUnitKey,
} from '@portal/common/types';
import { MAP_DETAIL_LEVEL_ID_KEY, MAP_TYPE_ID_KEY } from '@portal/common/models/data-key';
import {
    getLocationsForLevel,
    getMaxLocationDetailLevel,
    updateEchartsMapVisibleLocations,
    NATIONAL_LEVEL,
    PROVINCE_LEVEL,
} from '@portal/common/components';

import { getSelectedConditionsRefinedGranularity } from '../../../../store/data-explorer/selectors';
import config from '../../../../config';

import ChartRefinementFiltersControls from '../ChartRefinementFiltersControls';
import Container from './Container';

const mapStateToProps = (state: RootState) => ({
    entityHierarchies: state.entityHierarchies,
    refinedGranularity: getSelectedConditionsRefinedGranularity(state),
});

type Props = ReturnType<typeof mapStateToProps> & {
    initialTimeUnitValue: number | string;
    onDataLoad?: () => void;
    selectedConditionsDataTypes: DataType[];
    multipleFilterKeys: DataGranularityKey[];
    selectedRefinementFilters: DataFilters;
    selectedDataTool: string;
    selectedDataCollection: DataCollectionResource;
    selectedConditionsPrimaryEntityFilters: DataGranularityKey[];
    isLoadingChartData: boolean;
    chartFilters: DataFilters;
    filtersValues: DataFilters; // @todo: rename to 'selectedRefinementFilters', for example
    chartDataResponsesByType: null | Record<string, DataResponse>;
    selectedConditions: DataCondition[];
    selectedConditionsDataTypesGranularity: DataGranularity;

    timeUnitKey: TimeUnitKey;
    onExportData?: (format: DataExportFormat) => void;
    enableExportPNG: boolean;
    enableExportGIF: boolean;
    numberFormatter: (value: number | string) => string;
};

type State = {
    echartsMapKey: string;
    mapElementsAmount: number;
};

class MapChart extends React.PureComponent<Props, State> {
    chartRef = React.createRef();
    state: State = {
        echartsMapKey: 'world',
        mapElementsAmount: 0,
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.initialTimeUnitValue !== prevProps.initialTimeUnitValue) {
            this.resetChartPosition();
        }
    }

    preprocessFiltersChange = (filters: DataFilters) => {
        const { chartFilters } = this.props;

        if (!chartFilters) {
            this.updateMap(filters);
            return filters;
        }

        const combinedFilters = { ...chartFilters, ...filters };

        const isFilterChanged = (filterKey, newFilters): boolean =>
            chartFilters[filterKey] !== newFilters[filterKey];

        if (isFilterChanged(MAP_TYPE_ID_KEY, filters)) {
            combinedFilters[MAP_DETAIL_LEVEL_ID_KEY] = this.getDetailLevel(filters, chartFilters);
        }

        const isMapTypeChanged = isFilterChanged(MAP_TYPE_ID_KEY, combinedFilters);
        if (isMapTypeChanged || isFilterChanged(MAP_DETAIL_LEVEL_ID_KEY, combinedFilters)) {
            this.updateMap(combinedFilters);
        }

        if (isMapTypeChanged && combinedFilters[MAP_TYPE_ID_KEY] !== config.globalLocationId) {
            this.resetChartPosition();
        }

        return combinedFilters;
    };

    resetChartPosition = () => {
        if (this.chartRef?.current) {
            this.chartRef.current.resetChartPosition();
        }
    };

    getDetailLevel = (filters: DataFilters, prevFilters: DataFilters): number => {
        const { entityHierarchies, refinedGranularity } = this.props;

        const mapType = filters[MAP_TYPE_ID_KEY];
        const prevMapType = prevFilters[MAP_TYPE_ID_KEY];
        const detailLevel = filters[MAP_DETAIL_LEVEL_ID_KEY];

        let nextDetailLevel = detailLevel;
        if (
            prevMapType === config.globalLocationId &&
            mapType !== config.globalLocationId &&
            detailLevel === NATIONAL_LEVEL
        ) {
            nextDetailLevel = PROVINCE_LEVEL;
        } else if (
            prevMapType !== config.globalLocationId &&
            mapType === config.globalLocationId &&
            detailLevel !== NATIONAL_LEVEL
        ) {
            nextDetailLevel = NATIONAL_LEVEL;
        }

        const maxAvailableLevel = getMaxLocationDetailLevel(
            mapType,
            refinedGranularity?.location_id,
            entityHierarchies.locations
        );

        return Math.min(nextDetailLevel, maxAvailableLevel);
    };

    updateMap = (filters) => {
        const { entityHierarchies, refinedGranularity } = this.props;

        const detailLevel = filters.hasOwnProperty(MAP_DETAIL_LEVEL_ID_KEY)
            ? filters[MAP_DETAIL_LEVEL_ID_KEY]
            : NATIONAL_LEVEL;
        const mapType = filters[MAP_TYPE_ID_KEY] || config.globalLocationId;

        const locationIds = getLocationsForLevel(
            detailLevel,
            mapType,
            refinedGranularity?.location_id,
            entityHierarchies.locations
        );

        const [echartsMapKey, mapElementsAmount] = updateEchartsMapVisibleLocations(
            locationIds,
            mapType
        );

        this.setState({ echartsMapKey, mapElementsAmount });
    };

    render() {
        const {
            initialTimeUnitValue,
            selectedConditionsDataTypes,
            selectedConditionsDataTypesGranularity,
            selectedDataTool,
            selectedDataCollection,
            selectedConditionsPrimaryEntityFilters,

            isLoadingChartData,
            chartDataResponsesByType,
            filtersValues,
            selectedConditions,
            chartFilters,

            timeUnitKey,
            entityHierarchies,
            onExportData,
            enableExportPNG,
            enableExportGIF,
            numberFormatter,
        } = this.props;

        const { echartsMapKey, mapElementsAmount } = this.state;

        if (!entityHierarchies) {
            return null;
        }

        return (
            <>
                <ChartRefinementFiltersControls
                    preprocessFiltersChange={this.preprocessFiltersChange}
                />
                {echartsMapKey !== 'world' && (
                    <Container
                        key={initialTimeUnitValue} // @todo: remove this hack
                        ref={this.chartRef}
                        echartsMapKey={echartsMapKey}
                        mapElementsAmount={mapElementsAmount}
                        initialTimeUnitValue={initialTimeUnitValue}
                        selectedConditionsDataTypes={selectedConditionsDataTypes}
                        selectedConditionsDataTypesGranularity={
                            selectedConditionsDataTypesGranularity
                        }
                        isLoadingData={isLoadingChartData}
                        filtersValues={filtersValues}
                        dataResponses={chartDataResponsesByType}
                        selectedConditions={selectedConditions}
                        selectedConditionsPrimaryEntityFilters={
                            selectedConditionsPrimaryEntityFilters
                        }
                        chartFilters={chartFilters}
                        timeUnitKey={timeUnitKey}
                        selectedDataTool={selectedDataTool}
                        selectedDataCollection={selectedDataCollection}
                        onExportData={onExportData}
                        enableExportPNG={enableExportPNG}
                        enableExportGIF={enableExportGIF}
                        numberFormatter={numberFormatter}
                    />
                )}
            </>
        );
    }
}

export default connect(mapStateToProps, null)(MapChart);
