import React from 'react';

import { ListItem, ListItemTop, Popover } from '@portal/common/components';
import { WithThemeProps } from '@portal/common/theme';
import { SmartOptionConfig } from '@portal/common/types';

/**
 * Component
 */

export type SmartOptionsDropdown<T> = Pick<React.ComponentProps<typeof Popover>, 'placement'> & {
    children: any;
    smartOptions: SmartOptionConfig[];
    onSmartOptionSelect: (value: SmartOptionConfig) => void;
};

type Props<T> = Partial<WithThemeProps> & SmartOptionsDropdown<T>;

const SmartOptionsDropdown = <T extends any>({
    children,
    smartOptions,
    onSmartOptionSelect,
    ...props
}: Props<T>) => (
    <Popover
        disableHideOnInnerClick
        renderContent={({ toggleVisibility }) => (
            <div>
                {smartOptions.map((item: SmartOptionConfig, idx) => {
                    const DynamicComponent = item.separator ? ListItemTop : ListItem;

                    return (
                        <DynamicComponent
                            key={item.id || idx}
                            isSelected={false}
                            disabled={item.disabled}
                            onClick={(ev) => {
                                !item.disabled && toggleVisibility();
                                onSmartOptionSelect(item);
                            }}
                        >
                            <div>{item.label}</div>
                        </DynamicComponent>
                    );
                })}
            </div>
        )}
        {...props}
    >
        {children}
    </Popover>
);

export default SmartOptionsDropdown;
