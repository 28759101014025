import { mapValues } from 'lodash';
import { compose, identity, reverse, sortBy, uniq } from 'lodash/fp';

import { stringValueToNumberArray } from '@ihme/common/web/utility/chart-helpers';
import {
    DataCondition,
    DataFilters,
    DataGranularityKey,
    DataKey,
    DataRecord,
    DataResponse,
    DataType,
} from '@portal/common/types';
import {
    AGE_GROUP_ID_KEY,
    CAUSE_ID_KEY,
    COVARIATE_ID_KEY,
    DATA_TYPE_KEY,
    DAY_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    LOWER_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    PRIMARY_ENTITY_ID_KEY,
    PRIMARY_ENTITY_ID_LOCALIZATION_KEY,
    REI_ID_KEY,
    RISK_EXPOSURE_ID_KEY,
    ROUND_ID_KEY,
    SEQUELA_ID_KEY,
    UPPER_KEY,
    VALUE_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import themeConfig from '@portal/common/theme/theme-config';
import { localizeRecordValue } from '@portal/common/utility/chart-data-helpers';
import { mapGranularityKeyToLocalePrefix } from '@portal/common/utility/filters-helpers';
import { getDataTypeLocalePrefix } from '@portal/common/models/data-type';
import getScrollbarWidth from '@portal/common/utility/get-scrollbar-width';

import _ from '../../../locale';

export const sortTableData =
    ({ sortedByColumn, sortedDescending, ageGroupsOrder }) =>
    (data: DataResponse): DataResponse => {
        if (!data) {
            return data;
        }

        if (!sortedByColumn) {
            return data;
        }

        // sort data by column and direction
        const sortedRecords = compose(
            sortedDescending ? identity : reverse,
            sortBy(getSortFunction({ ageGroupsOrder })(sortedByColumn, data))
        )(data.records);

        return {
            columns: data.columns,
            records: sortedRecords,
        };
    };

export const getSortFunction =
    ({ ageGroupsOrder = {} }) =>
    (columnName: DataKey, data: DataResponse) => {
        // atm we have localized response so we have to localize the order
        // @todo: get rid of localizing order keys
        const localizedOrder = {};
        Object.entries(ageGroupsOrder).forEach(([key, value]) => {
            localizedOrder[_(`age_group_${key}`)] = value;
        });

        const columnIndex = data.columns.indexOf(columnName);

        return [
            columnName === AGE_GROUP_ID_KEY && ageGroupsOrder
                ? (record) => localizedOrder[record[columnIndex]]
                : columnIndex,
        ];
    };

export const sortRawTableData =
    ({ sortedByColumn, sortedDescending, ageGroupsOrder }) =>
    (data: DataResponse): DataResponse => {
        if (!(data && data.records && data.records.length)) {
            return data;
        }

        if (!sortedByColumn) {
            return data;
        }

        const { columns, records } = data;
        const sortedByColumnIdx = columns.indexOf(sortedByColumn);

        let order = {};
        if (sortedByColumn === AGE_GROUP_ID_KEY) {
            order = ageGroupsOrder;
        } else {
            const dataTypeIdx = columns.indexOf(DATA_TYPE_KEY);
            const dataType = records[0][dataTypeIdx];
            const sortedRecords =
                sortedByColumn === DAY_KEY
                    ? records.sort((a, b) => {
                          return new Date(b[sortedByColumnIdx]) - new Date(a[sortedByColumnIdx]);
                      })
                    : uniq(
                          sortBy(
                              (record) =>
                                  localizeRecordValue(
                                      record[sortedByColumnIdx],
                                      sortedByColumn,
                                      dataType
                                  ),
                              records
                          )
                      );

            sortedRecords.forEach((record, idx) => {
                order[record[sortedByColumnIdx]] = idx;
            });
        }

        // sort data by column and direction
        const sortedRecords = compose(
            sortedDescending ? identity : reverse,
            sortBy([(record) => order[record[sortedByColumnIdx]]])
        )(data.records);

        return {
            columns: data.columns,
            records: sortedRecords,
        };
    };

export const filterDataResponse = (
    data: Record<string, DataResponse>,
    columnIdxs: number[],
    includeColumns: boolean
): DataResponse => {
    const { columns, records } = data;

    return {
        columns: includeColumns
            ? getRecordValuesByIdxs(columns, columnIdxs)
            : filterRecordByIdx(columns, columnIdxs),
        records: records.map((record) =>
            includeColumns
                ? getRecordValuesByIdxs(record, columnIdxs)
                : filterRecordByIdx(record, columnIdxs)
        ),
    };
};

export const getRecordValuesByIdxs = (
    arr: DataRecord | DataKey[],
    idxsToKeep: number[]
): DataRecord | DataKey[] => arr.filter((value, idx) => idxsToKeep.includes(idx));

export const filterRecordByIdx = (record, idxsToExclude) => {
    const filteredRecord = [...record];
    idxsToExclude.forEach((idx) => {
        filteredRecord.splice(idx, 1);
    });
    return filteredRecord;
};

export const getColumnIdxs = (
    columns: Array<DataKey>,
    isForecastingData: boolean,
    extraColumnsToFilter: DataGranularityKey[] = []
): Array<DataKey> => {
    const keysToFilter = [
        ...new Set([DATA_TYPE_KEY, PRIMARY_ENTITY_ID_LOCALIZATION_KEY, ...extraColumnsToFilter]),
    ];
    if (!isForecastingData) {
        keysToFilter.push(FORECAST_SCENARIO_ID_KEY);
    }

    return keysToFilter
        .map((key) => columns.indexOf(key))
        .filter((idx) => idx !== -1)
        .sort((a, b) => b - a);
};

export const getColumnWidth = (
    columns: string[],
    selectedConditions: DataCondition[],
    selectedFilters: DataFilters,
    dataTypes: DataType[],
    columnName: string,
    expanded: boolean = false
) => {
    switch (columnName) {
        case PRIMARY_ENTITY_ID_KEY:
        case REI_ID_KEY:
        case RISK_EXPOSURE_ID_KEY:
        case CAUSE_ID_KEY:
        case SEQUELA_ID_KEY:
        case COVARIATE_ID_KEY:
        case LOCATION_ID_KEY:
            return (
                calculateDynamicColumnsWidth(
                    columns,
                    selectedConditions,
                    selectedFilters,
                    dataTypes
                )[columnName] + 'px'
            );

        case AGE_GROUP_ID_KEY:
        case ROUND_ID_KEY:
        case FORECAST_SCENARIO_ID_KEY:
        case MEASURE_ID_KEY:
        case DAY_KEY:
        case YEAR_KEY:
        case GENDER_ID_KEY:
        case UPPER_KEY:
        case LOWER_KEY:
        case VALUE_KEY:
        case METRIC_ID_KEY:
            return calculateStaticColumnWidth(columnName) + 'px';

        case DATA_TYPE_KEY:
            return 0;

        default:
            throw Error(`Column ${columnName} not handled properly`);
    }
};

const calculateDynamicColumnsWidth = (
    columns: string[],
    selectedConditions: DataCondition[],
    selectedFilters: DataFilters,
    dataTypes: DataType[],
    isSidebarMinimized: boolean
) => {
    const DYNAMIC_COLUMNS = [
        PRIMARY_ENTITY_ID_KEY,
        REI_ID_KEY,
        RISK_EXPOSURE_ID_KEY,
        CAUSE_ID_KEY,
        SEQUELA_ID_KEY,
        COVARIATE_ID_KEY,
        LOCATION_ID_KEY,
    ];

    const MIN_TEXT_LENGTH = 12;
    const COLUMN_SPACINGS = 14;
    const TABLE_SPACINGS = 120;
    // if client width less than min width use scroll width instead
    const currentWindowWidth =
        document.body.clientWidth >= themeConfig.minWidth.window
            ? document.body.clientWidth
            : document.body.scrollWidth;
    const navigationWidth = isSidebarMinimized
        ? themeConfig.minWidth.navigationMinimized
        : themeConfig.minWidth.navigation;
    const bodySpacings = TABLE_SPACINGS + getScrollbarWidth();
    const tableWidth = currentWindowWidth - navigationWidth - bodySpacings;
    const staticColumnsWidth = columns.reduce((acc, column) => {
        if (!DYNAMIC_COLUMNS.includes(column)) {
            return acc + calculateStaticColumnWidth(column) + COLUMN_SPACINGS;
        }
        return acc;
    }, 0);
    const dynamicColumnsWidth = tableWidth - staticColumnsWidth;

    const getMaxLength = (values: string[]) => {
        const textMaxLength = values!.reduce((maxLength, value) => {
            const textLength = value.toString().length;
            if (textLength > maxLength) {
                maxLength = textLength;
            }

            return maxLength;
        }, MIN_TEXT_LENGTH);

        return textMaxLength;
    };

    const columnsLengths = columns.reduce((lengths, column) => {
        if (DYNAMIC_COLUMNS.includes(column)) {
            if (column === PRIMARY_ENTITY_ID_KEY) {
                const localizedConditions = selectedConditions.map(
                    ({ data_type, primary_entity_id }) =>
                        _.get(getDataTypeLocalePrefix(data_type) + primary_entity_id)
                );

                const textMaxLength = getMaxLength(localizedConditions);

                if (lengths[column] == null || lengths[column] < textMaxLength) {
                    lengths[column] = textMaxLength;
                }
            } else {
                dataTypes.forEach((dataType) => {
                    let filterGranularity = stringValueToNumberArray(selectedFilters[column]);
                    let localePrefix = mapGranularityKeyToLocalePrefix(column, {}, dataType);

                    const localizedValues = filterGranularity!.map((id) =>
                        _.get(localePrefix + id)
                    );

                    const textMaxLength = getMaxLength(localizedValues);

                    if (lengths[column] == null || lengths[column] < textMaxLength) {
                        lengths[column] = textMaxLength;
                    }
                });
            }
        }

        return lengths;
        // calculate ratio for all dynamic columns
    }, {} as Record<string, number>);

    const columnsLengthsMax = Math.max(...Object.values(columnsLengths));

    // column lengths must be normalized to prevent big differences
    const normalizedColumnsLengths = mapValues(columnsLengths, (length) => {
        let result = length;
        // normalize differences bigger than half of max length
        if (length / columnsLengthsMax < 1 / 2) {
            result = columnsLengthsMax / 2;
        }

        return result;
    });

    const normalizedColumnsLengthsSum = Object.values(normalizedColumnsLengths).reduce(
        (acc: number, len: number) => acc + len,
        0
    );

    const dynamicColumnsWidths = mapValues(normalizedColumnsLengths, (length) => {
        const ratio = length / normalizedColumnsLengthsSum;
        const result = dynamicColumnsWidth * ratio - COLUMN_SPACINGS;

        return result;
    });

    return dynamicColumnsWidths;
};

const calculateStaticColumnWidth = (columnName: string) => {
    switch (columnName) {
        case METRIC_ID_KEY:
        case DAY_KEY:
            return 75;

        case AGE_GROUP_ID_KEY:
        case FORECAST_SCENARIO_ID_KEY:
        case MEASURE_ID_KEY:
            return 70;

        case ROUND_ID_KEY:
        case GENDER_ID_KEY:
        case UPPER_KEY:
        case LOWER_KEY:
        case VALUE_KEY:
            return 50;

        case YEAR_KEY:
            return 40;

        default:
            throw Error(`Column ${columnName} not handled properly`);
    }
};
