import React, { createContext, FC, ReactNode, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DataResponse } from 'types';

import { LOCATION_ID_KEY } from '@portal/common/models/data-key';
import { CovariateRecords } from '@portal/common/models/data-type';

import { getSelectedRefinementFilters } from '../../../store/data-explorer/selectors';
import { getOrganizationId } from '../../../store/root-reducer';
import { getSelectedDataCollectionForDataTool } from '../../../store/user-settings/selectors';
import { loadData } from '../../../utility/data-loader';
import { requestPayloadByDataType } from './constants';

interface DataContextProps {}

const DataContext = createContext<DataContextProps | undefined>(undefined);

interface DataProviderProps {
    children: ReactNode;
}

type State = {
    data: Record<string, DataResponse> | null | null;
    isLoading: boolean;
    error: any | null;
};

const defaultState: State = {
    data: null,
    isLoading: false,
    error: null,
};

export const DataProvider: FC<DataProviderProps> = ({ children }) => {
    const selectedRefinementFilters = useSelector(getSelectedRefinementFilters);
    const organizationId = useSelector(getOrganizationId);
    const dataCollection = useSelector(getSelectedDataCollectionForDataTool);
    const locationId = parseInt(selectedRefinementFilters?.[LOCATION_ID_KEY]?.[0]);
    const [state, setState] = useState(defaultState);

    useEffect(() => {
        if (!locationId) {
            setState(defaultState);
        } else {
            setState({
                data: null,
                error: null,
                isLoading: true,
            });
            const loadDataByConditionDataType = Object.values(requestPayloadByDataType).map(
                ({ conditions, filters }) => {
                    if (conditions[0].data_type !== CovariateRecords) {
                        filters[LOCATION_ID_KEY] = [locationId];
                    }
                    return loadData(organizationId, dataCollection, conditions)(filters);
                }
            );

            Promise.all(loadDataByConditionDataType)
                .then((results) => {
                    setState({
                        data: Object.values(results).reduce((acc, dataByDatatype) => {
                            const [datatype, data] = Object.entries(dataByDatatype)[0];
                            acc[datatype] = data;
                            return acc;
                        }, {}),
                        error: null,
                        isLoading: false,
                    });
                })
                .catch((err) => {
                    setState({
                        data: null,
                        error: err?.message,
                        isLoading: false,
                    });
                });
        }
    }, [locationId, organizationId, dataCollection]);

    return <DataContext.Provider value={{ ...state }}>{children}</DataContext.Provider>;
};

export const useData = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context;
};
