import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { from } from 'rxjs';
import sortBy from 'lodash/sortBy';

import Loading from '@ihme/common/components/Loading';
import { Accordion, FlexColumn, FlexRow, ProgressBar, SvgIcon } from '@portal/common/components';
import { WithGoogleAnalyticsInjectedProps } from '@portal/common/google-analytics';
import {
    DataCollectionDataPercentage,
    DataCollectionResource,
    DataProject,
} from '@portal/common/types';
import { styled } from '@portal/common/theme';
import formatPercentage from '@portal/common/utility/formatting/formatPercentage';
import { mapGranularityKeyToLocalePrefix } from '@portal/common/utility/filters-helpers';
import { REI_ID_KEY } from '@portal/common/models/data-key';

import api from '../../api';
import _ from '../../locale';
import { getOrganizationId, getSessionAccountId } from '../../store/root-reducer';

const StyledAccordionSummary = styled(Accordion.Summary)(({ theme }) => ({
    ':hover': {
        svg: {
            color: theme.color.green1,
        },
    },
}));

const folderIcon = (
    <SvgIcon
        src={'/icons/ic-folder.svg'}
        color={(theme) => theme.color.green1}
        size="large"
        style={{ marginRight: 15 }}
    />
);

const downArrow = (
    <SvgIcon
        src={'/icons/ic-arrow.svg'}
        color={(theme) => theme.color.gray40}
        css={{
            transform: 'rotate(-90deg)',
            transition: 'transform 0.5s ease-in-out',
            [`.Mui-expanded > * > * > &`]: {
                transform: 'rotate(-90deg) scaleX(-1)',
            },
        }}
    />
);

const StyledTitle = styled.div(({ theme }) => ({
    fontWeight: theme.typography.fontWeight.bold,
    [StyledAccordionSummary + ':hover &']: {
        color: theme.color.green1,
    },
}));

const EmphasizedText = styled.span(({ theme }) => ({
    fontWeight: theme.typography.fontWeight.medium,
    color: theme.color.green1,
}));

const Option = styled.span<{ isDisabled }>(({ theme, isDisabled }) => ({
    color: isDisabled ? theme.color.gray40 : 'inherit',
    marginBottom: 10,
}));

const mapStateToProps = (state: RootState) => ({
    accountId: getSessionAccountId(state),
    organizationId: getOrganizationId(state),
});

type DataTypePercentageRecord =
    DataCollectionDataPercentage['data_type_percentage'][keyof DataCollectionDataPercentage['data_type_percentage']];

type DataTypeRecord = [string, DataTypePercentageRecord];

type Props = WithGoogleAnalyticsInjectedProps &
    ReturnType<typeof mapStateToProps> & {
        history: any;
    };

type State = {
    dataCollections: null | DataCollectionResource[];
    dataProjects: null | DataProject[];
};

class DataLicenseTab extends React.PureComponent<Props, State> {
    state: State = {
        dataCollections: null,
        dataProjects: null,
    };

    licenseDataSubscription = from(
        api.organizationGroupContent.getDataCollections({
            organizationId: this.props.organizationId,
            fields: 'data_percentage,sort_order',
        })
    ).subscribe(
        (payload) => {
            this.setState({ dataCollections: sortBy(payload.results, ['sort_order']) });
        },
        (error) => {
            console.error(error);
        }
    );

    dataProjectsSubscription = from(api.dataProjects.get()).subscribe(
        (payload) => {
            this.setState({ dataProjects: payload });
        },
        (error) => {
            console.error(error);
        }
    );

    componentWillUnmount() {
        this.licenseDataSubscription.unsubscribe();
        this.dataProjectsSubscription.unsubscribe();
    }

    renderSummaryRow = ({
        tier,
        name,
        percentage,
        icon,
        marginLeft,
        dataName,
        items,
        totalItems,
        isTopLevel,
        dataProjectId,
    }: {
        tier;
        name;
        percentage;
        icon?;
        marginLeft?;
        dataName?;
        items?;
        totalItems?;
        isTopLevel?;
        dataProjectId?;
    }) => {
        let summary = [
            <span key={0}>
                {`Contains `}
                <EmphasizedText>{formatPercentage(percentage)}</EmphasizedText>
                {` of ${
                    isTopLevel ? this.getDataProjectName(dataProjectId) + ' Data' : dataName || ''
                }`}
            </span>,
        ];
        if (items != null && totalItems != null) {
            summary.push(<span key={1}>{` (${items} out of ${totalItems})`}</span>);
        }

        return (
            <FlexRow justify="space-between" align="center" grow={1}>
                <FlexRow style={{ width: '25%' }}>
                    {icon || <div style={{ width: marginLeft || 31 }} />}
                    <StyledTitle>{name}</StyledTitle>
                </FlexRow>
                <div style={{ width: '45%', textAlign: 'left' }}>{summary}</div>
                <ProgressBar
                    inProgress
                    now={Math.ceil(percentage * 100)}
                    style={{ width: '25%' }}
                />
                <div style={{ width: '5%', textAlign: 'right' }}>{downArrow}</div>
            </FlexRow>
        );
    };

    getDataProjectName = (id: number): string => {
        const { dataProjects } = this.state;
        const dataProject = (dataProjects || []).find((dataProject) => dataProject.id === id);
        return dataProject ? dataProject.name : '';
    };

    renderCollectionTier = ([name, total_percentage, dataTypeRecords, dataProjectId]: [
        string,
        number,
        DataTypeRecord[],
        number
    ]) => (
        <div key={name}>
            <Accordion>
                <StyledAccordionSummary>
                    {this.renderSummaryRow({
                        tier: 'collection-tier',
                        name,
                        percentage: total_percentage,
                        icon: folderIcon,
                        isTopLevel: true,
                        dataName: 'data',
                        dataProjectId,
                    })}
                </StyledAccordionSummary>
                <Accordion.Details>
                    <FlexColumn grow={1}>{dataTypeRecords.map(this.renderDataTypeTier)}</FlexColumn>
                </Accordion.Details>
            </Accordion>
        </div>
    );

    renderDataTypeTier = ([
        typeKey,
        { total_percentage, filter_percentage, total_granularity, granularity },
    ]: DataTypeRecord) => {
        const name = _.get('data_license_type_' + typeKey);

        const percentage = total_percentage;

        const rows = Object.entries(total_granularity).map(([filterKey, filterValues]) => {
            const filterKeyWithRei = filterKey === REI_ID_KEY ? typeKey : filterKey;
            const name = _.get('data_license_' + filterKeyWithRei);
            const prefix = mapGranularityKeyToLocalePrefix(filterKey);
            const totalItems = filterValues!;
            const items = granularity[filterKey];
            const percentage = filter_percentage[filterKey];

            return { name, prefix, totalItems, items, percentage };
        });

        return (
            <div key={name}>
                <Accordion
                    backgroundColor={(theme) => theme.color.surface}
                    TransitionProps={{ unmountOnExit: true }}
                >
                    <StyledAccordionSummary>
                        {this.renderSummaryRow({
                            tier: 'type-tier',
                            name,
                            percentage,
                            dataName: name,
                        })}
                    </StyledAccordionSummary>
                    <Accordion.Details>
                        <FlexColumn grow={1}>{rows.map(this.renderFilterTier)}</FlexColumn>
                    </Accordion.Details>
                </Accordion>
            </div>
        );
    };

    renderFilterTier = ({
        totalItems,
        items,
        name,
        prefix,
        percentage,
    }: {
        name: string;
        prefix: string | undefined;
        totalItems: number[];
        items: number[];
        percentage: number;
    }) => {
        const records = totalItems.map((id) => {
            const isDisabled = !items.includes(id);
            if (prefix === 'age_group_' && !_.get(prefix + id)) {
                console.log(id);
            }

            return (
                <Option key={id} isDisabled={isDisabled}>
                    - {prefix ? _.get(prefix + id) : id}
                </Option>
            );
        });

        if (!(records && records.length)) {
            return null;
        }

        return (
            <div key={name}>
                <Accordion
                    backgroundColor={(theme) => theme.color.surface}
                    TransitionProps={{ unmountOnExit: true }}
                >
                    <StyledAccordionSummary>
                        {this.renderSummaryRow({
                            tier: 'filter-tier',
                            name,
                            marginLeft: 80,
                            percentage: percentage,
                            dataName: name,
                            items: items?.length,
                            totalItems: totalItems?.length,
                        })}
                    </StyledAccordionSummary>
                    <Accordion.Details>
                        <FlexColumn
                            grow={1}
                            style={{ marginLeft: 25 + 80, marginTop: 25, marginBottom: 15 }}
                        >
                            {records}
                        </FlexColumn>
                    </Accordion.Details>
                </Accordion>
            </div>
        );
    };

    render() {
        const { dataCollections, dataProjects } = this.state;

        if (!dataCollections || !dataProjects) {
            return <Loading />;
        }

        const licenseCollections: [string, number, DataTypeRecord[]][] = dataCollections.map(
            (i) => {
                const { data_type_percentage, total_percentage } = i.data_percentage!;

                const dataTypeRecords = Object.entries(data_type_percentage);
                const dataProjectId = i.data_project_id;

                return [i.name, total_percentage, dataTypeRecords, dataProjectId];
            }
        );

        return <React.Fragment>{licenseCollections.map(this.renderCollectionTier)}</React.Fragment>;
    }
}

export default compose(withRouter, connect(mapStateToProps))(DataLicenseTab);
