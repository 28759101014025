import React, { useRef, useState } from 'react';

import {
    Button,
    FlexColumn,
    FlexRow,
    H2,
    PageBody,
    PageBodyFooter,
    PageHeader,
    ScrollToTop,
} from '@portal/common/components';
import { styled } from '@portal/common/theme';

import _ from '../../locale';
import LocationSelector from './components/LocationSelector';
import ExecutiveSummarySection from './ExecutiveSummarySection';
import HealthBackgroundSection from './HealthBackgroundSection';
import ConditionBackgroundSection from './ConditionBackgroundSection';
import StudySection from './StudySection';
import ResultsSection from './ResultsSection';
import { DataProvider } from './contexts/data-context';

const ContentContainer = styled(FlexColumn)(({ theme }) => ({
    flexGrow: 1,
    '> :not(:last-child)': {
        marginBottom: 15,
        [theme.breakpoint.md]: {
            marginBottom: 30,
        },
    },
}));

const BackToTopAnchor = styled.div({
    position: 'relative',
    left: -300,
    top: -100,
    width: 100,
});

const ExplorerContent = ({ dataToolConfig, dataCollections }) => {
    const containerRef = useRef();
    const [isSectionMounted, setIsSectionMounted] = useState(false);

    const downloadReportClickHandler = () => {
        window.open('files/IHME Multiple Myeloma Simulation Phase 2 Technical Report.pdf');
    };

    return (
        <>
            <PageHeader>
                <FlexRow justify="space-between" grow={1} align="center">
                    <FlexRow align="center" itemsSpacing={0}>
                        <H2>{_(dataToolConfig?.title)}</H2>
                        <LocationSelector />
                    </FlexRow>
                    <Button onClick={downloadReportClickHandler}>
                        {_('download_sim_report_button')}
                    </Button>
                </FlexRow>
            </PageHeader>
            <PageBody
                transparentSurface
                innerRef={containerRef}
                onMountedChange={setIsSectionMounted}
            >
                <BackToTopAnchor id="back-to-top-anchor" />
                <ContentContainer>
                    <DataProvider>
                        <ExecutiveSummarySection />
                        <HealthBackgroundSection />
                        <ConditionBackgroundSection />
                        <StudySection />
                        <ResultsSection />
                    </DataProvider>
                </ContentContainer>
                <PageBodyFooter>{_.get('footer_note')}</PageBodyFooter>
            </PageBody>
            {isSectionMounted && <ScrollToTop target={containerRef.current} />}
        </>
    );
};

export default ExplorerContent;
