import { useEffect, useState } from 'react';
import { isEmpty, sortBy } from 'lodash/fp';

import { VALUE_KEY } from '@portal/common/models/data-key';
import { DataKey } from '@portal/common/types';

import useGetApiData, { GetAPiDataProps } from './use-get-api-data';

type Props = GetAPiDataProps & {
    positionByKeyValuePair: [DataKey, string | number];
};

export default ({ conditions, filters, positionByKeyValuePair }: Props) => {
    const [place, setPlace] = useState<string | number | null>(null);
    const [totalAmount, setTotalAmount] = useState<string | number | null>(null);

    const { isLoading, dataResponse, error } = useGetApiData({
        conditions,
        filters,
    });

    useEffect(() => {
        if (dataResponse && !isEmpty(dataResponse?.records)) {
            const { columns, records } = dataResponse;
            const [key, value] = positionByKeyValuePair;
            const keyIdx = columns.indexOf(key);
            const valueIdx = columns.indexOf(VALUE_KEY);
            const sortedByValue = sortBy((record) => record[valueIdx], records)
                .map((record) => record[keyIdx])
                .reverse();
            setTotalAmount(sortedByValue.length);
            setPlace(sortedByValue.indexOf(value) + 1);
        }
    }, [dataResponse, isLoading, positionByKeyValuePair.toString()]);

    return { isLoading, place, totalAmount, error };
};
