import {
    CauseAssociatedCoprevalenceRecords,
    CauseCoprevalenceRecords,
    ProjectedComorbidityCausesOutcomeRecords,
} from '@portal/common/models/data-type';
import { DataType } from '@portal/common/types';

export const All_COMORBIDITIES_TAB = 'all';
export const ASSOCIATED_COMORBIDITIES_TAB = 'associated';

export const getTabsConfig = () => [
    {
        key: ASSOCIATED_COMORBIDITIES_TAB,
        dataTypes: [CauseAssociatedCoprevalenceRecords],
    },
    {
        key: All_COMORBIDITIES_TAB,
        dataTypes: [CauseCoprevalenceRecords, ProjectedComorbidityCausesOutcomeRecords],
    },
];

export const getDataTypeIndex = (dataType: DataType): number => {
    const config = getTabsConfig().find(({ dataTypes }) => dataTypes.includes(dataType));
    if (config) {
        return config.dataTypes.indexOf(dataType);
    }
    return 0;
};

export const getTabIndexByDataType = (dataType: DataType): number =>
    getTabsConfig().findIndex(({ dataTypes }) => dataTypes.includes(dataType));

export const getOppositeDataType = (tabIndex: number, dataType: DataType): DataType => {
    const availableDataTypes = getTabsConfig()[tabIndex].dataTypes as DataType[];
    if (availableDataTypes.length === 1) {
        return availableDataTypes[0];
    }

    return availableDataTypes[getDataTypeIndex(dataType)];
};

export const getComorbidityCompatibleDataTypes = (dataType: DataType): DataType[] | undefined =>
    getTabsConfig()
        .map(({ dataTypes }) => dataTypes as DataType[])
        .find((dataTypes) => dataTypes.includes(dataType));

export const getComorbidityDataTypes = (): DataType[] =>
    getTabsConfig()
        .map(({ dataTypes }) => dataTypes as DataType[])
        .flat();

export const getComorbidityTabKeyByDataType = (dataType: DataType): string => {
    const idx = (getComorbidityDataTypes() || []).includes(dataType)
        ? getTabIndexByDataType(dataType)
        : 0;
    return getTabsConfig()[idx].key;
};
