import { uniq } from 'lodash/fp';
import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';

import { DataFilters } from '@portal/common/types';
import { MAP_TYPE_ID_KEY } from '@portal/common/models/data-key';

import ChartDropdown from '../../../../components/ChartDropdown';
import config from '../../../../config';
import _ from '../../../../locale';
import {
    getSelectedConditionsRefinedGranularity,
    getSelectedRefinementFiltersWithFallbackToDefaultFilters,
} from '../../../../store/data-explorer/selectors';

const mapStateToProps = (state: RootState) => ({
    // @todo: use merged selected refinement filters
    dataExplorerSelectedRefinementFilters:
        getSelectedRefinementFiltersWithFallbackToDefaultFilters(state),
    entityHierarchies: state.entityHierarchies,
    refinedGranularity: getSelectedConditionsRefinedGranularity(state),
});

type Props = ReturnType<typeof mapStateToProps> & React.ComponentProps<typeof ChartDropdown>;

class MapTypeDropdown extends React.PureComponent<Props> {
    /*componentDidMount(): void {
        this.validateAndUpdateMapType();
    }*/

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        const { selectedRefinementFilters } = this.props;
        if (
            selectedRefinementFilters &&
            prevProps.selectedRefinementFilters &&
            selectedRefinementFilters.location_id !==
                prevProps.selectedRefinementFilters.location_id
        ) {
            this.validateAndUpdateMapType();
        }
    }

    validateAndUpdateMapType = () => {
        const { selectedRefinementFilters, onChange } = this.props;
        if (!selectedRefinementFilters) {
            return null;
        }
        const mapType = selectedRefinementFilters[MAP_TYPE_ID_KEY];

        const granularity = this.getGranularity();
        if (!granularity[MAP_TYPE_ID_KEY].includes(mapType)) {
            onChange && onChange(config.globalLocationId);
        }
    };

    /*handleChange = (value) => {
        const newMapType = parseInt(value);
        const { setMapType, mapType: prevMapType, onChange } = this.props;
        setMapType(newMapType);
        onChange && onChange(newMapType, prevMapType);
    };*/

    getGranularity = (): DataFilters => {
        const {
            dataExplorerSelectedRefinementFilters,
            entityHierarchies: { locations: locationsHierarchy },
            refinedGranularity,
        } = this.props;

        const refinedLocationIds = refinedGranularity?.location_id || [];
        const selectedLocationIds = [];
        selectedLocationIds.push(
            ...(dataExplorerSelectedRefinementFilters &&
            dataExplorerSelectedRefinementFilters.location_id
                ? dataExplorerSelectedRefinementFilters.location_id
                      .toString()
                      .split(',')
                      .map(Number)
                      .filter((id) => refinedLocationIds.includes(id))
                : [])
        );

        const mapTypes = [config.globalLocationId];
        if (locationsHierarchy) {
            config.countrySpecificMapTypes
                .filter((id) => refinedLocationIds.includes(id))
                .forEach((locationId) => {
                    const location = locationsHierarchy.find(({ id }) => id === locationId);
                    if (location) {
                        const {
                            level0_parent_id,
                            level1_parent_id,
                            level2_parent_id,
                            level3_parent_id,
                        } = location;
                        if (
                            selectedLocationIds.includes(level0_parent_id) ||
                            selectedLocationIds.includes(level1_parent_id) ||
                            selectedLocationIds.includes(level2_parent_id) ||
                            // selectedLocationIds.includes(level3_parent_id) ||
                            selectedLocationIds.includes(locationId)
                        ) {
                            mapTypes.push(locationId);
                        }
                    }
                });
        }

        return {
            [MAP_TYPE_ID_KEY]: uniq(mapTypes),
        };
    };

    render() {
        const { onChange, ...props } = this.props;

        const granularity = this.getGranularity();
        if (granularity[MAP_TYPE_ID_KEY].length < 2 || props.filterValue == null) {
            return null;
        }

        return (
            <ChartDropdown
                {...props}
                valueMode="single"
                filterKey={MAP_TYPE_ID_KEY}
                optionLabelGetter={(o) => _.withPrefix('location_', o)}
                granularity={granularity}
                refinedGranularity={granularity}
                onChange={(value) => onChange && onChange(parseInt(value))}
            />
        );
    }
}

export default connect(mapStateToProps, null)(MapTypeDropdown);
