import React from 'react';

import { CovariateRecords } from '@portal/common/models/data-type';
import {
    AGE_GROUP_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    PRIMARY_ENTITY_ID_KEY,
    ROUND_ID_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import { Link } from '@portal/common/components';

import config from '../../../config';
import _ from '../../../locale';

import PlaceOfMetricBlock from '../components/PlaceOfMetricBlock';

type Props = {
    locationId: number;
};

const LinkToSDI = ({ children, ...props }) => (
    <Link
        target="_blank"
        href="https://ghdx.healthdata.org/record/ihme-data/gbd-2019-socio-demographic-index-sdi-1950-2019"
        {...props}
    >
        {children}
    </Link>
);

const SDIBlock = ({ locationId }: Props) => (
    <PlaceOfMetricBlock
        conditions={[
            {
                data_type: CovariateRecords,
                [PRIMARY_ENTITY_ID_KEY]: config.SDICovariateId,
            },
        ]}
        filters={{
            [LOCATION_ID_KEY]: config.countries.split(',').map(Number),
            [AGE_GROUP_ID_KEY]: [config.allAgesGroupId],
            [GENDER_ID_KEY]: [config.bothGendersId],
            [YEAR_KEY]: [2019],
            [ROUND_ID_KEY]: [config.gbd2019RoundId],
        }}
        label={_('simulation_tool_sdi_metric')({ Link: LinkToSDI })}
        positionByKeyValuePair={[LOCATION_ID_KEY, locationId]}
    />
);

export default SDIBlock;
