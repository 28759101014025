import { DataResponse, DataType } from '@portal/common/types';
import { PopulationRecords } from '@portal/common/models/data-type';
import { getValueKeys } from '@portal/common/models/data-key';

export default (response: Record<DataType, DataResponse>) => {
    const populationResponse = response[PopulationRecords];
    if (!populationResponse) {
        return response;
    }

    const { columns, records } = populationResponse;

    const valuesIdxs = getValueKeys()
        .map((key) => columns.indexOf(key))
        .filter((idx) => idx !== -1);

    records.forEach((record) => {
        valuesIdxs.forEach((idx) => {
            record[idx] = Math.round(record[idx]);
        });
    });

    return response;
};
