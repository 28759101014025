import * as React from 'react';
import * as yup from 'yup';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, FormikProps, Field, withFormik } from 'formik';

import { styled } from '@portal/common/theme';
import {
    Alert,
    Button,
    Dropdown,
    FieldLabel,
    FormGroup,
    FormikInput,
    FormikTextarea,
    FormikToggle,
} from '@portal/common/components';

import api from '../../api';

import { getSessionAccountId, getOrganizationId } from '../../store/root-reducer';
import { organizationContacted } from '../../store/organization/actions';

import _ from '../../locale';

const StyledAlert = styled(Alert)({
    marginBottom: 20,
});

const mapStateToProps = (store) => ({
    accountId: getSessionAccountId(store),
    organizationId: getOrganizationId(store),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ organizationContacted }, dispatch);

const mapNameToLabel = (name: string) => _.get(('contact_us_' + name) as any);

type Props = RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

type FormValues = {
    reason: string;
    subject: string;
    body: string;
    is_manager_copied: boolean;
};

const InnerForm: React.FC<Props & FormikProps<FormValues>> = (props) => {
    const { isSubmitting, status, setFieldValue, values } = props;
    const options = ['suggestion', 'question', 'bug', 'other'];

    const { reason, subject, body } = props.values;
    const isValid = [reason, subject, body].every(
        (value) => value && value.toString().trim().length
    );

    return (
        <Form>
            {status && status.error && <StyledAlert color="error">{status.error}</StyledAlert>}
            {status && status.success && (
                <StyledAlert color="success">{status.success}</StyledAlert>
            )}

            <FormGroup>
                <FieldLabel>{_('contact_us_reason')}</FieldLabel>
                <Dropdown
                    valueMode="single"
                    options={options}
                    value={values.reason}
                    onChange={(ev) => {
                        setFieldValue('reason', ev.target.value);
                    }}
                    optionLabelGetter={(o) => _.get(`contact_us_reason_${o}`)}
                    {...props}
                />
            </FormGroup>
            <Field
                name="subject"
                component={FormikInput}
                mapNameToLabel={mapNameToLabel}
                type="text"
                required
                data-private
            />
            <Field
                name="body"
                component={FormikTextarea}
                rows={3}
                mapNameToLabel={mapNameToLabel}
                type="text"
                required
                data-private
            />
            <Field
                name="is_manager_copied"
                component={FormikToggle}
                componentStyle={{ padding: 0 }}
                mapNameToLabel={mapNameToLabel}
                color="primary"
            />
            <Button
                color="primary"
                type="submit"
                disabled={isSubmitting || !isValid}
                style={{ width: '100%' }}
            >
                {_.get('contact_us_submit')}
            </Button>
        </Form>
    );
};

const ContactUsForm = withFormik<Props, FormValues>({
    mapPropsToValues: ({ organizationId, accountId }) => ({
        organizationId,
        accountId,
        reason: 'suggestion',
        subject: '',
        body: '',
        is_manager_copied: false,
    }),
    validationSchema: () =>
        yup.object().shape({
            reason: yup.string(),
            subject: yup.string(),
            body: yup.string(),
        }),
    handleSubmit: (values, form) => {
        api.organizationMemberTickets
            .createTicket({ ...form.props, ...values })
            .then(() => {
                form.resetForm();
                form.setStatus({ success: _.get('contact_us_success_message') });
                form.props.organizationContacted({ ...values });
            })
            .catch((err: Error & { fields?: object }) => {
                form.setStatus({ error: err.message });
                if (err.fields) {
                    form.setErrors(err.fields);
                }
            })
            .finally(() => {
                form.setSubmitting(false);
            });
    },
})(InnerForm);

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsForm);
