import React, { useEffect, useState } from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import { groupBy, sortBy } from 'lodash/fp';

import { EDPOnetimeIndicators } from '@portal/common/models/data-type';
import { INDICATOR_ID_KEY, RESPONSE_ID_KEY, VALUE_KEY } from '@portal/common/models/data-key';
import { DataResponse } from '@portal/common/types';
import {
    BodyText1,
    CaptionText4,
    CaptionText5,
    H4,
    Loading,
    SectionBody,
    SectionHeader,
} from '@portal/common/components';
import { styled } from '@portal/common/theme';
import { getDataTypeConfig } from '@portal/common/models/data-type-config';

import _ from '../../locale';
import {
    getSelectedConditions,
    getSelectedRefinementFilters,
    isDataLoading,
} from '../../store/data-explorer/selectors';
import { getSelectedDataCollectionForDataTool } from '../../store/user-settings/selectors';
import { loadData } from '../../utility/data-loader';
import { getOrganizationId } from '../../store/root-reducer';
import { indicatorGroupsMap, responseProps } from './chart-config';

const mapStateToProps = (state: RootState) => ({
    conditions: getSelectedConditions(state),
    isTimelineDataLoading: isDataLoading(state),
    organizationId: getOrganizationId(state),
    dataCollection: getSelectedDataCollectionForDataTool(state),
    timelineDataRefinementFilters: getSelectedRefinementFilters(state),
});

const StyledH4 = styled(H4)(({ theme }) => ({
    height: 'auto',
    margin: 0,
    paddingTop: 30,
    paddingBottom: 7,
}));

const Container = styled.div(({ theme }) => ({
    '> :not(:last-child)': {
        marginBottom: 30,
    },
}));

const BarChartContainer = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
}));

const IndicatorLabel = styled(BodyText1)(({ theme }) => ({
    marginTop: 23,
    marginBottom: 10,
    display: 'inline-block',
}));

const BarChartInfoWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    width: '40%',
    paddingRight: 30,
    alignItems: 'center',
}));

const BarChartInfoRow = styled.div<{ hiddenBorder?: boolean }>(({ theme, hiddenBorder }) => ({
    paddingTop: 6,
    paddingBottom: 6,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: hiddenBorder ? 'none' : `1px solid ${theme.color.secondary1}`,
    width: '100%',
}));

const InfoRowValue = styled(CaptionText5)(() => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
}));

const BarWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    boxShadow: `inset 2px 0px 0px 0px  ${theme.color.secondary1}`,
    width: '60%',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    paddingRight: 50,
}));

const Bar = styled.div<{ color: string; width: number }>(({ theme, color, width }) => ({
    background: color,
    height: 16,
    width: `${width}%`,
    borderRadius: '0 4px 4px 0',
    marginTop: 11,
    marginBottom: 11,
}));

interface EDPOneTimeDataSectionProps extends ReturnType<typeof mapStateToProps> {}

export function EDPOneTimeDataSection(props: EDPOneTimeDataSectionProps) {
    const {
        conditions,
        dataCollection,
        isTimelineDataLoading,
        organizationId,
        timelineDataRefinementFilters,
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [dataResponse, setDataResponse] = useState<DataResponse | null>(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (dataCollection && isTimelineDataLoading) {
            setIsLoading(true);
            setError(null);

            const { filterKey } = getDataTypeConfig()[EDPOnetimeIndicators];

            const dataset = dataCollection.datasets.find(
                (dataset) => dataset.data_type === EDPOnetimeIndicators
            );
            const primaryEntityIds = dataset.granularity[filterKey];
            const conditions = primaryEntityIds.map((id) => ({
                data_type: EDPOnetimeIndicators,
                primary_entity_id: id,
            }));

            loadData(
                organizationId,
                dataCollection,
                conditions
            )(timelineDataRefinementFilters || {})
                .then((res) => {
                    setIsLoading(false);
                    setDataResponse(res[EDPOnetimeIndicators]);
                })
                .catch((err) => {
                    setIsLoading(false);
                    setDataResponse(null);
                    setError(err);
                });
        }
    }, [isTimelineDataLoading]);

    if (!(dataCollection && conditions && conditions.length)) {
        return null;
    }

    if (isLoading) {
        return (
            <SectionBody>
                {/* @todo: display error if exists */}
                {!error && isLoading && <Loading />}
            </SectionBody>
        );
    }

    const getChartData = (dataResponse) => {
        const data: {
            label: string;
            indicators: {
                label: string;
                responses: { label: string; value: number; width: number; color: string }[];
            }[];
        }[] = [];
        if (!dataResponse) {
            return data;
        }

        const { columns, records } = dataResponse;

        columns.push('indicator_group_id');
        const indicatorIdIdx = columns.indexOf(INDICATOR_ID_KEY);
        const indicatorGroupIdIdx = columns.indexOf('indicator_group_id');
        const responseIdIdx = columns.indexOf(RESPONSE_ID_KEY);
        const valueIdx = columns.indexOf(VALUE_KEY);

        records.forEach((record) => {
            const indicatorId = record[indicatorIdIdx];
            record.push(indicatorGroupsMap[indicatorId]);
        });

        const indicatorGroupsData = groupBy(indicatorGroupIdIdx, records);

        Object.entries(indicatorGroupsData).forEach(([indicatorGroupId, indicatorGroupRecords]) => {
            const maxValue = Math.max(...indicatorGroupRecords.map((record) => record[valueIdx]));

            const children: {
                label: string;
                responses: { label: string; value: number; width: number; color: string }[];
            }[] = [];

            const dataByIndicator = groupBy(indicatorIdIdx, indicatorGroupRecords);

            Object.values(dataByIndicator).forEach((indicatorRecords) => {
                children.push({
                    label: _(`indicator_${indicatorRecords[0][indicatorIdIdx]}`),
                    responses: sortBy(
                        [
                            (record) => {
                                const responseId = record[responseIdIdx];
                                const props = responseProps.find((el) => el.id === responseId);
                                return props?.order;
                            },
                        ],
                        indicatorRecords
                    ).map((record) => {
                        const responseId = record[responseIdIdx];
                        const props = responseProps.find((el) => el.id === responseId);
                        const value = record[valueIdx];
                        return {
                            label: _(`response_${responseId}`),
                            value,
                            width: (100 * value) / maxValue,
                            color: props?.color,
                        };
                    }),
                });
            });

            data.push({
                label: _(`indicator_group_${indicatorGroupId}`),
                indicators: children,
            });
        });

        return data;
    };

    const chartData = getChartData(dataResponse);

    return (
        <Container id="edp-onetime-data-chart">
            {chartData.map((indicatorGroup, idx) => (
                <SectionBody key={idx}>
                    <SectionHeader>
                        <StyledH4>{indicatorGroup.label}</StyledH4>
                    </SectionHeader>
                    {indicatorGroup.indicators.map((indicatorData, idx) => (
                        <React.Fragment key={idx}>
                            <IndicatorLabel>{indicatorData.label}</IndicatorLabel>
                            {indicatorData.responses.map(({ label, value, width, color }, idx) => (
                                <BarChartContainer key={idx}>
                                    <BarChartInfoWrapper>
                                        <BarChartInfoRow
                                            hiddenBorder={
                                                idx === indicatorData.responses.length - 1
                                            }
                                        >
                                            <CaptionText4>{label}</CaptionText4>
                                            <InfoRowValue>{Math.round(value)}</InfoRowValue>
                                        </BarChartInfoRow>
                                    </BarChartInfoWrapper>
                                    <BarWrapper>
                                        <Bar color={color} width={width} />
                                    </BarWrapper>
                                </BarChartContainer>
                            ))}
                        </React.Fragment>
                    ))}
                </SectionBody>
            ))}
        </Container>
    );
}

export default connect(mapStateToProps)(EDPOneTimeDataSection);
