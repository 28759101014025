import BaseChartFilterReset from './ChartFilterReset';

import { styled } from '@portal/common/theme';

const ChartFilterReset = styled(BaseChartFilterReset)(({ theme }) => ({
    height: 50,
    width: 160,
    fontSize: '1.6rem',
    padding: '6px 20px',
    cursor: 'pointer',
    borderRadius: theme.borderRadius.base,
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    background: theme.color.base1,
    color: theme.color.baseText,
    ':hover': {
        background: theme.color.base2,
        border: 'unset',
        color: theme.color.base1,
    },
    ':active': {
        background: theme.color.base3,
        border: 'unset',
        color: theme.color.base1,
    },
}));

export default ChartFilterReset;
