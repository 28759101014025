import React from 'react';
import { connect } from 'react-redux';
import { groupBy } from 'lodash/fp';

import {
    DataCollectionResource,
    DataCondition,
    DataExportFormat,
    DataFilters,
    DataGranularity,
    DataGranularityKey,
    DataKey,
    DataRecord,
    DataResponse,
    DataToolConfig,
    DataType,
    TimeUnitKey,
} from '@portal/common/types';
import {
    AGE_GROUP_ID_KEY,
    CAUSE_ID_KEY,
    DATA_TYPE_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    MAX_PROBABILITY_CAUSE_ID,
    MAX_PROBABILITY_LEVEL,
    MAX_PROBABILITY_VALUE,
    METRIC_ID_KEY,
    PRIMARY_ENTITY_ID_KEY,
    PRIMARY_ENTITY_ID_LOCALIZATION_KEY,
    PRIMARY_ENTITY_KEY,
    RISK_EXPOSURE_ID_KEY,
    SERIES_NAME_KEY,
} from '@portal/common/models/data-key';
import {
    chartProviderFiltersToObject,
    getChartSubTitle,
    getChartTitle,
    localizeConditions,
    mapValueToCondition,
} from '@portal/common/utility/chart-data-helpers';
import { TYPE_LINE_CHART, TYPE_SCATTER_MAP_CHART } from '@portal/common/models/chart-type';
import { getExportFilename } from '@portal/common/utility/get-export-filename';
import { HealthThreatsMetricsRecords } from '@portal/common/models/data-type';
import formatChartValue from '@portal/common/utility/formatting/formatChartValue';
import { GHT_LEVEL_COLORS, rgbaToRgb } from '@portal/common/utility/color';
import { PNG } from '@portal/common/models/file-format';
import { pngIcon } from '@portal/common/theme/icons';

import config from '../../../../config';
import _ from '../../../../locale';
import echarts from '../../../../theme/echarts';

import Chart from './Chart';
import {
    changeChartFilters,
    changeSelectedChartType,
} from '../../../../store/data-explorer/actions';
import animatableChart from '../../../../utility/hoc/animatable-chart';

const dispatchProps = {
    changeChartType: changeSelectedChartType,
    changeChartFilters: changeChartFilters,
};

type Props = typeof dispatchProps & {
    dataToolConfig: DataToolConfig;
    isForecastingData: boolean;
    forecastTimeUnitStart: number | string;
    initialTimeUnitValue: number | string;
    enableExportPNG: boolean;
    onDataLoad?: () => void;
    selectedConditions: DataCondition[];
    selectedConditionsDataTypes: DataType[];
    selectedConditionsDataTypesGranularity: DataGranularity;
    multipleFilterKeys: DataGranularityKey[];
    selectedDataTool: string;
    selectedDataCollection: DataCollectionResource;
    mergedChartDataResponses: null | DataResponse;
    dataResponse: null | DataResponse;
    isLoadingData: boolean;
    filtersValues: DataFilters;
    baseForecastScenarioId: number;
    timeUnitKey: TimeUnitKey;
    onExportData?: (format: DataExportFormat) => void;
    numberFormatter: (value: number | string) => string;
    echartsMapKey: string;
};

class Container extends React.Component<Props> {
    static defaultProps = {};

    chartRef = React.createRef();
    recordsByTimeUnitAndLocation: any = null;

    shouldComponentUpdate = (nextProps: Readonly<Props>): boolean =>
        nextProps.isLoadingData !== this.props.isLoadingData;

    getFilterIdx = (key: DataKey): number => this.props.dataResponse.columns.indexOf(key);

    getPreparedData = () => {
        const { dataResponse, timeUnitKey } = this.props;
        const { columns, records } = dataResponse;

        const timeUnitIdx = columns.indexOf(timeUnitKey);
        const locationIdx = columns.indexOf(LOCATION_ID_KEY);

        this.recordsByTimeUnitAndLocation = groupBy(
            (record) => record[timeUnitIdx] + '_' + record[locationIdx],
            records
        );

        const extendedRecords = records.map((record) => {
            const seriesName = _(`prob_level_${record[this.getFilterIdx(MAX_PROBABILITY_LEVEL)]}`);
            return [...record, seriesName];
        });

        return {
            columns: [...columns, SERIES_NAME_KEY],
            records: extendedRecords,
        };
    };

    getScatterItemColor = (params) => {
        const { data } = params;

        const getFilterValue = (key: DataKey, source?: DataRecord): number | string | null => {
            const filterIdx = this.getFilterIdx(key);
            return source ? source[filterIdx] : data[filterIdx];
        };

        const dataType = getFilterValue(DATA_TYPE_KEY as DataKey);

        if (dataType !== HealthThreatsMetricsRecords) return null;

        const levels = Array.from({ length: 8 }, (v, k) => k + 1);
        const probabilities = levels
            .map((level) => getFilterValue(`prob_level_${level}` as DataKey))
            .filter((value) => value !== null)
            .map(Number);

        const maxProbability = Math.max(...probabilities);
        const maxProbabilityLevelIdx = probabilities.indexOf(maxProbability);

        const numValue = Number(maxProbability);
        const [r, g, b] = GHT_LEVEL_COLORS[maxProbabilityLevelIdx];
        const a = Math.max(numValue, 0.1);
        return rgbaToRgb(r, g, b, a);
    };

    renderTooltip = (params) => {
        const { marker, data, componentType } = params;

        if (componentType === 'geo') return '';

        const getFilterValue = (key: DataKey, source?: DataRecord): number | string | null => {
            const filterIdx = this.getFilterIdx(key);
            return source ? source[filterIdx] : data[filterIdx];
        };

        const { timeUnitKey } = this.props;

        const timeUnitValue = getFilterValue(timeUnitKey);
        const locationId = getFilterValue(LOCATION_ID_KEY);

        const location = _(`location_${locationId}`);

        const conditionId = getFilterValue(PRIMARY_ENTITY_ID_KEY as DataKey);
        const isAllPathogens = conditionId === config.allPathogensCauseId;
        const conditionName = _(getFilterValue(PRIMARY_ENTITY_ID_LOCALIZATION_KEY as DataKey));

        const prob = getFilterValue(MAX_PROBABILITY_VALUE) * 100;
        const probLevel = getFilterValue(MAX_PROBABILITY_LEVEL);
        const pathogen = _(`cause_${getFilterValue(MAX_PROBABILITY_CAUSE_ID)}`);

        const caption = isAllPathogens
            ? `Highest threat in ${location}: ${pathogen}`
            : `${conditionName} in ${location}`;

        return `<div class="chart-tooltip">
                    <div style="padding-bottom: 10px">${marker} <b>${caption}</b></div>
                    <div style="padding-bottom: 10px">Date: <b>${timeUnitValue}</b></div>
                    <div style="padding-bottom: 10px">
                        Threat level: <b>${probLevel}</b> or higher with
                        <b>${formatChartValue(prob)}%</b> probability
                    </div>
                    <div><a>Click on dot to view trends</a></div>
                </div>`;
    };
    renderTitle = ({ timeUnitValue }) => {
        const {
            dataResponse: { records },
            selectedConditionsDataTypes,
            selectedConditionsDataTypesGranularity,
            filtersValues,
        } = this.props;

        const conditions = [this.getSelectedCondition()];

        return records.length > 0
            ? getChartTitle(
                  selectedConditionsDataTypes,
                  localizeConditions(conditions),
                  chartProviderFiltersToObject(filtersValues),
                  Object.keys(selectedConditionsDataTypesGranularity),
                  [RISK_EXPOSURE_ID_KEY, CAUSE_ID_KEY, METRIC_ID_KEY],
                  timeUnitValue,
                  TYPE_SCATTER_MAP_CHART
              )
            : '';
    };

    getSelectedCondition = (): DataCondition => {
        const { chartFilters, selectedConditions } = this.props;

        return selectedConditions.length === 1 ||
            !(
                chartFilters &&
                chartFilters[PRIMARY_ENTITY_KEY] &&
                chartFilters[PRIMARY_ENTITY_KEY].length
            )
            ? selectedConditions[0]
            : mapValueToCondition(chartFilters[PRIMARY_ENTITY_KEY][0]);
    };

    renderSubtitle = ({ filters }) => {
        const {
            chartFilters,
            dataResponse: { records },
            selectedConditionsDataTypesGranularity,
            isForecastingData,
        } = this.props;
        const titleFilters = [AGE_GROUP_ID_KEY, GENDER_ID_KEY];
        if (isForecastingData) {
            titleFilters.push(FORECAST_SCENARIO_ID_KEY);
        }
        return records.length > 0
            ? getChartSubTitle(
                  chartProviderFiltersToObject(chartFilters),
                  Object.keys(selectedConditionsDataTypesGranularity),
                  titleFilters
              )
            : '';
    };

    getSaveFilename = ({ timeUnitValue }) => {
        const {
            selectedConditions,
            selectedDataTool,
            selectedDataCollection,
            chartFilters,
            filtersValues,
        } = this.props;

        return getExportFilename({
            trigger: TYPE_SCATTER_MAP_CHART,
            dataTool: selectedDataTool,
            dataCollectionName: selectedDataCollection.name,
            conditions: localizeConditions(selectedConditions),
            filters: { ...filtersValues, ...chartFilters },
            selectedTimeUnit: timeUnitValue,
        });
    };

    render() {
        const {
            initialTimeUnitValue,
            isLoadingData,
            timeUnitKey,
            dataToolConfig,
            onExportData,
            enableExportPNG,
            echartsMapKey,
            onEvents,
        } = this.props;

        const preparedDataResponse = this.getPreparedData();

        const permanentlyEnabledPlayFunction =
            dataToolConfig &&
            dataToolConfig.scatterChartConfig &&
            dataToolConfig.scatterChartConfig.permanentlyEnabledPlayFunction;

        return (
            <Chart
                {...this.props}
                ref={this.chartRef}
                initialTimeUnitValue={initialTimeUnitValue}
                permanentlyEnabledPlayFunction={permanentlyEnabledPlayFunction}
                isLoading={isLoadingData}
                dataResponse={preparedDataResponse}
                timeUnitKey={timeUnitKey}
                theme={echarts}
                enableTimelineSlider
                initialSliderValue={initialTimeUnitValue}
                seriesKey={SERIES_NAME_KEY}
                renderTooltip={this.renderTooltip}
                renderTitle={this.renderTitle}
                renderSubtitle={this.renderSubtitle}
                echartsMapKey={echartsMapKey}
                saveAsImage={{
                    visible: true,
                    enabled: enableExportPNG,
                    filename: this.getSaveFilename,
                    icon: pngIcon,
                    onClick: () => onExportData?.(PNG),
                }}
                getScatterItemColor={this.getScatterItemColor}
                onEvents={{
                    ...onEvents,
                    click: (params) => {
                        const { seriesType, componentSubType, data, dimensionNames } = params;

                        if (seriesType === 'scatter' && componentSubType === 'scatter') {
                            const locationIdx = dimensionNames.indexOf(LOCATION_ID_KEY);
                            const locationId = data[locationIdx];
                            const primaryEntityIdx =
                                dimensionNames.indexOf(MAX_PROBABILITY_CAUSE_ID);
                            const primaryEntityId = data[primaryEntityIdx];

                            this.props.changeChartType(TYPE_LINE_CHART);
                            this.props.changeChartFilters({
                                location_id: [locationId],
                                primary_entity: [
                                    `${HealthThreatsMetricsRecords}::${primaryEntityId}`,
                                ],
                            });
                        }
                    },
                }}
            />
        );
    }
}

export default connect(null, dispatchProps)(animatableChart(Container));
