import {
    COVID_19_EXPLORER_PATH,
    DATA_EXPLORER_PATH,
    EDP_EXPLORER_PATH,
    HEALTH_THREATS_METRICS_PATH,
    MORBIDITY_EXPLORER_PATH,
    SIMULATION_EXPLORER_PATH,
} from '../../router/paths';

const config: { path: string; iconSrc: string; labelKey: string }[] = [
    {
        path: DATA_EXPLORER_PATH,
        iconSrc: '/icons/ic-barchart.svg',
        labelKey: 'nav_custom_chart',
    },
    {
        path: COVID_19_EXPLORER_PATH,
        iconSrc: '/icons/ic-covid-19.svg',
        labelKey: 'nav_covid_19_data_explorer',
    },
    {
        path: MORBIDITY_EXPLORER_PATH,
        iconSrc: '/icons/ic-comorbidity.svg',
        labelKey: 'nav_morbidity_explorer',
    },
    {
        path: EDP_EXPLORER_PATH,
        iconSrc: '/icons/ic-edp.svg',
        labelKey: 'nav_edp_explorer',
    },
    {
        path: SIMULATION_EXPLORER_PATH,
        iconSrc: '/icons/ic-simulation.svg',
        labelKey: 'nav_simulation_explorer',
    },
    {
        path: HEALTH_THREATS_METRICS_PATH,
        iconSrc: '/icons/ic-health-threats.svg',
        labelKey: 'nav_health_threats_tool',
    },
];

export default config;
