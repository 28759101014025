import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { isEmpty } from 'lodash/fp';

import { PageContainer, Protect } from '@portal/common/components';
import { isDataToolAccessible } from '@portal/common/models/data-project';

import { getDataCollections, getDataToolConfig } from '../../store/data-explorer/selectors';
import { getSelectedDataCollectionForDataTool } from '../../store/user-settings/selectors';
import { initializeDataCollection } from '../../store/user-settings/actions';
import { initializeSelectedDataType } from '../../store/data-explorer/actions';

import _ from '../../locale';

import DataToolNotShared from '../../components/DataToolNotShared';
import SubmitPassword from './SubmitPassword';
import ExplorerContent from './ExplorerContent';

const SimulationExplorerScene = () => {
    const dispatch = useDispatch();

    const dataToolConfig = useSelector(getDataToolConfig);
    const dataCollections = useSelector(getDataCollections);
    const dataCollection = useSelector(getSelectedDataCollectionForDataTool);

    const { pathname: dataTool } = useLocation();

    const initializeDataTool = () => {
        const firstAvailableDataCollection = dataCollections?.find(({ data_project_id }) =>
            dataToolConfig.dataProjectIds.includes(data_project_id)
        );

        if (!firstAvailableDataCollection) {
            return null;
        }

        const dataCollectionId = firstAvailableDataCollection.id;
        // mandatory initializations
        dispatch(initializeDataCollection({ dataCollectionId, dataTool }));
        dispatch(initializeSelectedDataType(dataToolConfig.defaultDataType));
    };

    useEffect(() => {
        if (!dataCollection && !isEmpty(dataCollections)) {
            initializeDataTool();
        }
    }, [dataCollections]);

    if (dataCollections && !isDataToolAccessible(dataToolConfig.dataProjectIds, dataCollections)) {
        return <DataToolNotShared toolPath={dataTool} isLarge />;
    }

    return (
        <PageContainer>
            <Protect
                sha512="e1527de5f00b51076a760da4cd2086bf3ea8fd1a519a757c5f36fa55ec0b96d136397ca2320292c8963f334f9a0b58f256bdbb35af9073fbfad9e0b6c5c6f684"
                renderSignIn={(submitPassword) => (
                    <SubmitPassword title={_(dataToolConfig?.title)} submit={submitPassword} />
                )}
            >
                <ExplorerContent
                    dataToolConfig={dataToolConfig}
                    dataCollections={dataCollections}
                />
            </Protect>
        </PageContainer>
    );
};

export default SimulationExplorerScene;
