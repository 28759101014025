import { withTheme } from 'emotion-theming';
import { range } from 'lodash/fp';
import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStorage } from '@ihme/common/packages/storage';

import {
    AGE_GROUP_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import { WithThemeProps } from '@portal/common/theme';
import filterLabelStyles from '@portal/common/theme/filter-label-styles';
import { SmartOptionConfig } from '@portal/common/types';
import { getWholeAgeRange } from '@portal/common/models/age-group';

import config from '../../config';
import _ from '../../locale';
import { toggleYearRefinementFilterMode } from '../../store/data-explorer/actions';
import {
    getAgeGroups,
    getSelectedConditionsDataTypesGranularity,
    getSelectedConditionsRefinedGranularity,
} from '../../store/data-explorer/selectors';

import SmartOptionsSelector from './SmartOptionsSelector';

const mapGroupIntoIds = (group: string, options: number[]): number[] | null => {
    const ids = group
        .split(',')
        .map(Number)
        .filter((id) => options.includes(id));

    return ids.length ? ids : null;
};

const ONLY_SMART_OPTIONS_KEYS = [LOCATION_ID_KEY, AGE_GROUP_ID_KEY, YEAR_KEY];

const mapStateToProps = (state: RootState) => ({
    entityHierarchies: state.entityHierarchies,
    countryLocationSdi: state.dataExplorer.countryLocationSdi,
    yearRefinementFilterMode: state.dataExplorer.yearRefinementFilterMode,
    granularity: getSelectedConditionsDataTypesGranularity(state),
    refinedGranularity: getSelectedConditionsRefinedGranularity(state),
    ageGroupEntities: getAgeGroups(state),
});

const dispatchProps = {
    toggleYearRefinementFilterMode,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    React.ComponentProps<typeof SmartOptionsSelector> &
    WithThemeProps & { hideDelete: boolean };

class SmartFilterOptionSelectorContainer extends React.Component<Props> {
    isSelectAllEnabled = (): boolean => !ONLY_SMART_OPTIONS_KEYS.includes(this.props.filterKey);

    getSmartOptions = (): SmartOptionConfig[] => {
        const {
            filterKey,
            yearRefinementFilterMode,
            toggleYearRefinementFilterMode,
            granularity,
            refinedGranularity,
        } = this.props;

        let options: SmartOptionConfig[];

        switch (filterKey) {
            case LOCATION_ID_KEY:
                options = [
                    // smart options by location type
                    ...[config.countryLocationTypeId]
                        .map((locationTypeId) => {
                            const { entityHierarchies } = this.props;

                            const countries = entityHierarchies.locations
                                .filter(({ location_type_id }) => {
                                    const isCountry = location_type_id === locationTypeId;
                                    return isCountry;
                                })
                                .map(({ id }) => id);

                            // hide smart option when every selection id not present in granularity
                            if (countries.every((id) => !granularity[filterKey].includes(id))) {
                                return null;
                            }

                            return {
                                id: 'select_all_countries',
                                label: _('smart_option_select_all_countries'),
                                getNewSelectedValues: (selectedRefinementFilters, options) => {
                                    if (!countries.length) {
                                        return null;
                                    }
                                    return countries.filter((id) => options.includes(id));
                                },
                            };
                        })
                        .filter(Boolean),
                    // smart options by country
                    ...[102]
                        .map((countryId) => {
                            const { entityHierarchies } = this.props;

                            const countries = entityHierarchies.locations
                                .filter(({ location_type_id, level, ...props }) => {
                                    const isCity = props.level1_parent_id === countryId;
                                    return isCity;
                                })
                                .map(({ id }) => id);

                            // hide smart option when every selection id not present in granularity
                            if (countries.every((id) => !granularity[filterKey].includes(id))) {
                                return null;
                            }

                            return {
                                id: `smart_option_select_all_locations_${countryId}`,
                                label: _(`smart_option_select_all_locations_${countryId}`),
                                getNewSelectedValues: (selectedRefinementFilters, options) => {
                                    if (!countries.length) {
                                        return null;
                                    }
                                    return countries;
                                },
                            };
                        })
                        .filter(Boolean),
                    // smart options by region
                    ...[4, 31, 64, 103, 137, 158, 166]
                        .map((parentId) => {
                            const parentIds = [parentId];

                            // Add Southern Latin America to Latin America and Caribbean region
                            if (parentId === 103) {
                                parentIds.push(96);
                            }

                            const { entityHierarchies } = this.props;

                            const parentEntities = parentIds.map((regionId) =>
                                entityHierarchies.locations.find(({ id }) => id === regionId)
                            );

                            const countries = entityHierarchies.locations
                                .filter((entity) => {
                                    const { location_type_id, level } = entity;
                                    const isCountry =
                                        location_type_id === config.countryLocationTypeId;
                                    if (!isCountry) {
                                        return false;
                                    }

                                    const isChildren = !!parentEntities.find((regionEntity) => {
                                        const { id: regionId, level: regionLevel } = regionEntity;
                                        return entity[`level${regionLevel}_parent_id`] === regionId;
                                    });
                                    return isChildren;
                                })
                                .map(({ id }) => id);

                            // hide smart option when every selection id not present in granularity
                            if (countries.every((id) => !granularity[filterKey].includes(id))) {
                                return null;
                            }

                            return {
                                id: `smart_option_select_all_countries_${parentId}`,
                                label: _(`smart_option_select_all_countries_${parentId}`),
                                getNewSelectedValues: (selectedRefinementFilters, options) => {
                                    if (!countries.length) {
                                        return null;
                                    }
                                    return countries;
                                },
                            };
                        })
                        .filter(Boolean),
                    // smart options by sdi region
                    ...[44634, 44635, 44636, 44637, 44639]
                        .map((sdiId) => {
                            const { entityHierarchies, countryLocationSdi } = this.props;

                            if (!entityHierarchies || !countryLocationSdi) {
                                return null;
                            }

                            const countries = entityHierarchies.locations
                                .filter(({ location_type_id, level, ...props }) => {
                                    const isCountry =
                                        location_type_id === config.countryLocationTypeId;
                                    const sdiLocation = countryLocationSdi.find(
                                        (i) => Number(i.location_id) === Number(props.id)
                                    );

                                    const isChildren =
                                        sdiLocation && sdiLocation.sdi_location_id === sdiId;
                                    return isCountry && isChildren;
                                })
                                .map(({ id }) => id);

                            if (countries.every((id) => !granularity[filterKey].includes(id))) {
                                return null;
                            }

                            return {
                                id: `smart_option_select_all_countries_${sdiId}`,
                                label: _(`smart_option_select_all_countries_${sdiId}`),
                                getNewSelectedValues: (selectedRefinementFilters, options) => {
                                    if (!countries.length) {
                                        return null;
                                    }
                                    return countries;
                                },
                            };
                        })
                        .filter(Boolean),
                    ...[44591, 479, 478]
                        .map((regionId) => {
                            const { entityHierarchies } = this.props;

                            const bankLevelIncomes = entityHierarchies.locations
                                .filter(({ location_type_id, level, ...props }) => {
                                    return props.level0_parent_id === regionId;
                                })
                                .map(({ id }) => id);

                            // hide smart option when every selection id not present in granularity
                            if (
                                bankLevelIncomes.every((id) => !granularity[filterKey].includes(id))
                            ) {
                                return null;
                            }

                            return {
                                id: `smart_option_select_all_countries_${regionId}`,
                                label: _(`smart_option_select_all_countries_${regionId}`),
                                getNewSelectedValues: () => {
                                    if (!bankLevelIncomes.length) {
                                        return null;
                                    }
                                    return bankLevelIncomes;
                                },
                            };
                        })
                        .filter(Boolean),
                    ...[4743, 44587]
                        .map((unionId) => {
                            const { entityHierarchies } = this.props;
                            let countryIds = [];
                            if (unionId === 4743) {
                                countryIds = config.europeanUnionAndUKIds;
                            } else {
                                countryIds = config.africanUnionIds;
                            }

                            const unionCountries = entityHierarchies.locations
                                .filter(({ id }) => countryIds.includes(id))
                                .map(({ id }) => id);

                            if (
                                unionCountries.every((id) => !granularity[filterKey].includes(id))
                            ) {
                                return null;
                            }

                            return {
                                id: `smart_option_select_all_countries_${unionId}`,
                                label: _(`smart_option_select_all_countries_${unionId}`),
                                getNewSelectedValues: () => {
                                    if (!unionCountries.length) {
                                        return null;
                                    }

                                    return unionCountries;
                                },
                            };
                        })
                        .filter(Boolean),
                    ...[44584]
                        .map((regionId) => {
                            const { entityHierarchies } = this.props;
                            const oecdCountries = config.oecdIds;

                            const countriesIds = entityHierarchies.locations
                                .filter(({ id }) => oecdCountries.includes(id))
                                .map(({ id }) => id);

                            if (countriesIds.every((id) => !granularity[filterKey].includes(id))) {
                                return null;
                            }

                            return {
                                id: `smart_option_select_all_countries_${regionId}`,
                                label: _(`smart_option_select_all_countries_${regionId}`),
                                getNewSelectedValues: () => {
                                    if (!countriesIds.length) {
                                        return null;
                                    }
                                    return countriesIds;
                                },
                            };
                        })
                        .filter(Boolean),
                ];
                break;

            case AGE_GROUP_ID_KEY:
                options = [
                    {
                        id: 'smart_option_select_all_5_year_age',
                        label: _('smart_option_select_all_5_year_age'),
                        getNewSelectedValues: (selectedRefinementFilters, options) =>
                            mapGroupIntoIds(config.fiveYearAgeBands, options),
                    },
                    {
                        id: 'smart_option_select_whole_year_age',
                        label: _('smart_option_select_whole_year_age'),
                        getNewSelectedValues: (selectedRefinementFilters, options) => {
                            const { ageGroupEntities } = this.props;
                            const ages5years =
                                mapGroupIntoIds(config.fiveYearAgeBands, options) || [];
                            return getWholeAgeRange(ages5years, options, ageGroupEntities);
                        },
                    },
                    {
                        id: 'smart_option_select_pediatric_focus',
                        label: _('smart_option_select_pediatric_focus'),
                        getNewSelectedValues: (selectedRefinementFilters, options) =>
                            mapGroupIntoIds(config.pediatricFocusAgeGroups, options),
                    },
                    {
                        id: 'smart_option_select_elderly_focus',
                        label: _('smart_option_select_elderly_focus'),
                        getNewSelectedValues: (selectedRefinementFilters, options) =>
                            mapGroupIntoIds(config.elderlyFocusAgeGroups, options),
                    },
                    {
                        id: 'smart_option_select_reproductive_age_female',
                        label: _('smart_option_select_reproductive_age_female'),
                        getNewSelectedValues: (selectedRefinementFilters, options) =>
                            mapGroupIntoIds(config.reproductiveFemaleAges, options),
                    },
                    {
                        id: 'smart_option_select_life_course',
                        label: _('smart_option_select_life_course'),
                        getNewSelectedValues: (selectedRefinementFilters, options) =>
                            mapGroupIntoIds(config.lifeCourseAges, options),
                    },
                    {
                        id: 'smart_option_select_adult_focus',
                        label: _('smart_option_select_adult_focus'),
                        getNewSelectedValues: (selectedRefinementFilters, options) =>
                            mapGroupIntoIds(config.adultFocusAges, options),
                    },
                    {
                        id: 'smart_option_select_5_yr_age_bands_20_plus',
                        label: _('smart_option_select_5_yr_age_bands_20_plus'),
                        getNewSelectedValues: (selectedRefinementFilters, options) =>
                            mapGroupIntoIds(config.fiveYearAgeBandsTwentyPlus, options),
                    },
                ];
                break;

            case GENDER_ID_KEY:
                options = [
                    {
                        id: 'smart_option_select_male_and_female',
                        label: _('smart_option_select_male_and_female'),
                        getNewSelectedValues: (selectedRefinementFilters, options) => {
                            const genders = [config.maleGenderId, config.femaleGenderId].filter(
                                (id) => options.includes(id)
                            );

                            if (!genders.length) {
                                return null;
                            }

                            return genders;
                        },
                    },
                ];
                break;

            case YEAR_KEY:
                switch (yearRefinementFilterMode) {
                    case 'slider':
                        options = [
                            {
                                id: 'smart_option_select_individual_years',
                                label: _('smart_option_select_individual_years'),
                                separator: true,
                                getNewSelectedValues: (selectedRefinementFilters, options) => {
                                    toggleYearRefinementFilterMode();
                                    return null;
                                },
                            },
                        ];
                        break;

                    case 'dropdown':
                        options = [
                            {
                                id: 'smart_option_select_years_range',
                                label: _('smart_option_select_years_range'),
                                separator: true,
                                getNewSelectedValues: (selectedRefinementFilters, options) => {
                                    toggleYearRefinementFilterMode();
                                    return null;
                                },
                            },
                        ];
                        break;
                }

                const fallbackGranularity =
                    refinedGranularity == null ? granularity : refinedGranularity;

                if (fallbackGranularity[YEAR_KEY] != null) {
                    const yearGranularity = fallbackGranularity[YEAR_KEY];
                    const maxYear = Math.max(...yearGranularity.toString().split(',').map(Number));

                    if (maxYear > 1990) {
                        options.push(
                            {
                                id: 'smart_option_select_1990s',
                                label: _('smart_option_select_1990s'),
                                getNewSelectedValues: () => range(1990, 2000),
                            },
                            {
                                id: 'smart_option_select_historic_trends',
                                label: _('smart_option_select_historic_trends'),
                                getNewSelectedValues: () => range(1990, 2020),
                            }
                        );
                    }

                    if (maxYear > 2000) {
                        options.push({
                            id: 'smart_option_select_2000s',
                            label: _('smart_option_select_2000s'),
                            getNewSelectedValues: () => range(2000, 2010),
                        });
                    }

                    if (maxYear > 2010) {
                        options.push({
                            id: 'smart_option_select_2010s',
                            label: _('smart_option_select_2010s'),
                            getNewSelectedValues: () => range(2010, 2020),
                        });
                    }

                    if (yearGranularity.includes(2019)) {
                        options.push({
                            id: 'smart_option_select_recent_year',
                            label: _('smart_option_select_recent_year'),
                            getNewSelectedValues: () => [2019],
                        });
                    }

                    if (maxYear > 2020) {
                        options.push({
                            id: 'smart_option_select_2020s',
                            label: _('smart_option_select_2020s'),
                            getNewSelectedValues: () => range(2020, 2030),
                        });
                    }

                    if (maxYear > 2030) {
                        options.push({
                            id: 'smart_option_select_2030s',
                            label: _('smart_option_select_2030s'),
                            getNewSelectedValues: () => range(2030, 2040),
                        });
                    }
                }

                break;

            default:
                options = [];
                break;
        }

        return options;
    };

    render() {
        const { entityHierarchies, hideDelete, ...props } = this.props;

        return (
            <SmartOptionsSelector
                {...props}
                style={{
                    minWidth: 120,
                    padding: '6px 10px',
                    ...props.style,
                    marginTop:
                        props.style && props.style.marginTop ? parseInt(props.style.marginTop) : 0,
                }}
                isSelectAllEnabled={this.isSelectAllEnabled()}
                smartOptions={this.getSmartOptions()}
                locations={entityHierarchies.locations}
                labelStyles={filterLabelStyles(props.theme)}
                hideDelete={hideDelete}
            />
        );
    }
}

export default compose(
    withStorage,
    withTheme,
    connect(mapStateToProps, dispatchProps)
)(SmartFilterOptionSelectorContainer);
