import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import { H2, PageContainer } from '@portal/common/components';

import api from '../../api';

import _ from '../../locale';
import locale from './locale';

import ResendSignUpEmailForm from './ResendSignUpEmailForm';

const ResendSignUpEmailScene: React.FC = (props: RouteComponentProps) => (
    <PageContainer centered>
        <H2>{_(locale.title)}</H2>
        <ResendSignUpEmailForm
            resendEmail={api.emailConfirmation.resend}
            initialEmail={props.location.state && props.location.state.email}
        />
    </PageContainer>
);

export default withRouter(ResendSignUpEmailScene);
