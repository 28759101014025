import React from 'react';

import { Button, FormGroup } from '@portal/common/components';
import _ from '../../locale';

type Props = {
    onClick: () => void;
};

const ChartFilterReset: React.FC<Props> = ({ onClick, ...props }) => (
    <FormGroup style={{ minWidth: 160, margin: 0 }}>
        <Button
            {...props}
            onClick={() => {
                onClick();
            }}
        >
            {_('chart_reset_button_label')}
        </Button>
    </FormGroup>
);

export default ChartFilterReset;
