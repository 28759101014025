import React from 'react';

import { Toggle } from '@portal/common/components';

import _ from '../../locale';

type Props = {
    areChartsVisible: boolean;
    toggleChartsVisibility: () => void;
};

const ToggleChartsVisibilityButton = (props: Props) => {
    const { areChartsVisible, toggleChartsVisibility } = props;

    return (
        <Toggle
            checked={areChartsVisible}
            name="areChartsVisible"
            label={_('toggle_charts_visibility_button_label')}
            onChange={toggleChartsVisibility}
        />
    );
};

export default ToggleChartsVisibilityButton;
