import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';

import { MAP_DETAIL_LEVEL_ID_KEY, MAP_TYPE_ID_KEY } from '@portal/common/models/data-key';

import ChartDropdown from '../../../../components/ChartDropdown';
import {
    BOROUGH_LEVEL,
    NATIONAL_LEVEL,
    PROVINCE_LEVEL,
    REGION_LEVEL,
    getMaxLocationDetailLevel,
} from '@portal/common/components';

import _ from '../../../../locale';
import { getSelectedConditionsRefinedGranularity } from '../../../../store/data-explorer/selectors';

const mapStateToProps = (state: RootState) => ({
    entityHierarchies: state.entityHierarchies,
    dataExplorerRefinedGranularity: getSelectedConditionsRefinedGranularity(state),
});

type Props = ReturnType<typeof mapStateToProps> & React.ComponentProps<typeof ChartDropdown>;

const MAP_DETAIL_LEVEL_GRANULARITY = [NATIONAL_LEVEL, PROVINCE_LEVEL, REGION_LEVEL, BOROUGH_LEVEL];

const DetailLevelDropdown: React.FC<Props> = ({
    onChange,
    entityHierarchies,
    dataExplorerRefinedGranularity,
    ...props
}) => {
    const granularity = {
        [MAP_DETAIL_LEVEL_ID_KEY]: MAP_DETAIL_LEVEL_GRANULARITY,
    };

    const { selectedRefinementFilters } = props;

    if (
        !(selectedRefinementFilters && selectedRefinementFilters.hasOwnProperty(MAP_TYPE_ID_KEY)) ||
        props.filterValue == null
    ) {
        return null;
    }

    const maxAvailableLevel = getMaxLocationDetailLevel(
        selectedRefinementFilters[MAP_TYPE_ID_KEY],
        dataExplorerRefinedGranularity?.location_id,
        entityHierarchies.locations
    );

    return (
        <ChartDropdown
            {...props}
            valueMode="single"
            filterKey={MAP_DETAIL_LEVEL_ID_KEY}
            optionLabelGetter={(o) => _.withPrefix('map_detail_level_', o)}
            optionDisabledGetter={(o) => o > Number(maxAvailableLevel)}
            granularity={granularity}
            refinedGranularity={granularity}
            onChange={(value) => onChange && onChange(parseInt(value))}
        />
    );
};

export default connect(mapStateToProps, null)(DetailLevelDropdown);
