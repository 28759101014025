import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useQuery } from '../../hooks/use-query';
import { HOME_PATH, SIGN_IN_PATH } from '../../router/paths';
import { getSessionAccount, getSessionError } from '../../store/root-reducer';
import * as sessionActions from '../../store/session/actions';
import * as organizationActions from '../../store/organization/actions';
import _ from '../../locale';

export const AuthCallbackScene = ({ history }) => {
    const dispatch = useDispatch();
    const sessionAccount = useSelector(getSessionAccount);
    const sessionError = useSelector(getSessionError);
    const { code, state } = useQuery();
    const setOrganization = (organization) =>
        dispatch(organizationActions.selectOrganization(organization));
    const signInWithSSO = () => dispatch(sessionActions.signInWithSSO(code, state));

    useEffect(() => {
        if (sessionAccount) {
            const organization = sessionAccount.organization;
            setOrganization(organization);
            history.push(HOME_PATH);
            return;
        }

        if (sessionError) {
            history.push(SIGN_IN_PATH);
            return;
        }

        if (code && state) {
            signInWithSSO();
        } else {
            history.push(SIGN_IN_PATH);
        }
    }, [sessionAccount, code, state, sessionError]);

    return null;
};

export default AuthCallbackScene;
