declare const window: { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function };

import { routerMiddleware } from 'connected-react-router';
import { RootAction, RootState, Services } from 'MyTypes';
import { applyMiddleware, compose, createStore as reduxCreateStore, Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import thunk from 'redux-thunk';

import services from '../services';
import rootEpic from './root-epic';
import history from './history';
import createRootReducer from './root-reducer';

export const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, Services>({
    dependencies: services,
});

export function createStore<T>(initialState: Partial<T>): Store<T> {
    const composeEnhancers =
        // eslint-disable-next-line no-underscore-dangle
        (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

    // configure middlewares
    const middlewares = [thunk, epicMiddleware, routerMiddleware(history)];
    // compose enhancers
    const enhancer = composeEnhancers(applyMiddleware(...middlewares));
    // create store
    const store = reduxCreateStore(createRootReducer(), initialState as T, enhancer);
    epicMiddleware.run(rootEpic);

    return store as Store<T>;
}
