import React from 'react';
import { connect } from 'react-redux';
import { withState, withHandlers } from 'recompose';
import { compose } from 'lodash';

import Loading from '@ihme/common/components/Loading';
import { withGoogleAnalytics } from '@ihme/common/packages/google-analytics';
import withQueryParams, {
    WithQueryParamsInjectedProps,
} from '@ihme/common/utility/hoc/with-query-params';
import { withStorage } from '@ihme/common/packages/storage';

import { styled } from '@portal/common/theme';
import {
    Avatar,
    BulkInviteAccountModal,
    Button,
    ConfirmationModal,
    DataTableWithQueryParams,
    FlexColumn,
    FlexRow,
    H2,
    InputWithDebounce,
    PageBody,
    PageBodyFooter,
    PageContainer,
    PageHeader,
    TableCell,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
    TableText,
    ActionEdit,
    ActionDelete,
    ActionDemote,
    ActionPromote,
} from '@portal/common/components';
import { DataTableQueryParams } from '@portal/common/types';
import formatDateTime from '@portal/common/utility/formatting/formatDateTime';
import { parseApiError } from '@portal/common/utility/api-helpers';

import {
    getSessionAccountId,
    getOrganizationId,
    getOrganization,
    getSessionAccount,
} from '../../store/root-reducer';

import api from '../../api';

import locale from './locale';
import _ from '../../locale';

import EditOrganizationMemberModal from '../../components/EditOrganizationMemberModal';
import SelfRegistrationOptionsModal from '../../components/SelfRegistrationOptionsModal';
import SelfRegistrationUrlModal from '../../components/SelfRegistrationUrlModal';

const TrackedDataTable = compose(
    withGoogleAnalytics({
        trackMethods: (props) => ({
            handleSearchChange: (search) => {
                if (search && search.length > 1) {
                    props.trackEvent({
                        category: 'Organization',
                        action: 'Search Member',
                        label: search,
                    });
                }
            },
        }),
    })
)(DataTableWithQueryParams);

const StyledPageHeader = styled(PageHeader)(({ theme }) => ({
    justifyContent: 'space-between',
}));

const StyledPageBody = styled(PageBody)(({ theme }) => ({
    height: '100%',
    '> div': {
        padding: 0,
    },
}));

const HeaderControlsGroup = styled(FlexRow)(({ theme }) => ({
    alignItems: 'center',
    '> *': {
        marginRight: 12,
    },
}));

const ActionButtonsGroup = styled.div(({ theme }) => ({
    marginLeft: 10,
    display: 'flex',
    '*:not(:last-child)': {
        marginRight: 20,
    },
}));

const NameWithAvatar = styled(FlexRow)(({ theme }) => ({
    alignItems: 'center',
    '> :first-child': {
        paddingRight: '20px',
    },
}));

const MANAGER_ROLE = 'manager';
const MEMBER_ROLE = 'member';

const DEFAULT_QUERY_PARAMS = {
    page: '1',
    per_page: '10',
};

const mapStateToProps = (state) => ({
    organizationId: getOrganizationId(state),
    organization: getOrganization(state),
    accountId: getSessionAccountId(state),
    sessionAccount: getSessionAccount(state),
});

type Props = WithQueryParamsInjectedProps<DataTableQueryParams> &
    ReturnType<typeof mapStateToProps> & {
        history: object;
        location: object;
    };

type State = {
    activeModal: string | null;
    member: object | null;
};

class TeamScene extends React.Component<Props, State> {
    mounted = false;
    DELETE_MEMBER_MODAL = 'delete_member';
    EDIT_MEMBER_MODAL = 'edit_member';
    BULK_INVITE_MODAL = 'bulk_invite';
    SELF_REGISTRATION_MODAL = 'self_registration';
    SELF_REGISTRATION_URL_MODAL = 'self_registration_url';

    state: State = {
        activeModal: null,
        member: null,
        isDeleting: false,
    };

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setStateIfMounted(state) {
        if (this.mounted) {
            this.setState(state);
        }
    }

    getColumns = () => {
        const columns = ['name', 'title', 'organization_role', 'lastAccess'];
        if (this.isManager()) {
            columns.push('actions');
        }
        return columns;
    };

    isProfileLoaded = () => this.props.sessionAccount !== null;

    isManager = () => {
        const { sessionAccount } = this.props;
        return sessionAccount && sessionAccount.organization_role === MANAGER_ROLE;
    };

    showModal = (modal: string | null, member: object | null = null) =>
        this.setState({ activeModal: modal, member });

    closeModal = () => this.showModal(null, null);

    handleInviteMemberClick = () => this.showModal(this.EDIT_MEMBER_MODAL);

    handleBulkInviteClick = () => this.showModal(this.BULK_INVITE_MODAL);

    handleSelfRegistrationOptionsClick = () => this.showModal(this.SELF_REGISTRATION_MODAL);

    handleEditMemberClick = (member) => this.showModal(this.EDIT_MEMBER_MODAL, member);

    handleDeleteMemberClick = (member) => this.showModal(this.DELETE_MEMBER_MODAL, member);

    handleDeleteMemberSubmit = (member, loadItems) => {
        this.setState({ isDeleting: true });

        return api.organization
            .deleteOrganizationMember(this.props.organizationId, member.id)
            .then(() => {
                this.setState({ isDeleting: false });
                loadItems();
            })
            .catch((error) => {
                this.setState({ isDeleting: false });
                this.onError(error);
            });
    };

    toggleMemberRole = (member) => {
        const newRole = member.organization_role === MEMBER_ROLE ? MANAGER_ROLE : MEMBER_ROLE;
        return api.organization
            .setOrganizationMemberRole(this.props.organizationId, member.id, newRole)
            .catch(this.onError);
    };

    onError = (error) => this.setStateIfMounted({ errors: parseApiError(error) });

    isEditModalVisible = () => this.state.activeModal === this.EDIT_MEMBER_MODAL;

    isDeleteModalVisible = () => this.state.activeModal === this.DELETE_MEMBER_MODAL;

    isBulkInviteModalVisible = () => this.state.activeModal === this.BULK_INVITE_MODAL;

    isSelfRegistrationModalVisible = () => this.state.activeModal === this.SELF_REGISTRATION_MODAL;

    isSelfRegistrationUrlModalVisible = () =>
        this.state.activeModal === this.SELF_REGISTRATION_URL_MODAL;

    renderHeaders = (renderProps) => (
        <TableHeaderRow>
            <TableHeaderCell>{_(locale.name)}</TableHeaderCell>
            <TableHeaderCell>{_(locale.memberTitle)}</TableHeaderCell>
            <TableHeaderCell>{_(locale.organizationRole)}</TableHeaderCell>
            <TableHeaderCell>{_(locale.lastAccess)}</TableHeaderCell>
            {this.isManager() && <TableHeaderCell>{_(locale.actions)}</TableHeaderCell>}
        </TableHeaderRow>
    );

    renderItem = (itemData, { loadItems }) => {
        const editAction = {
            icon: <ActionEdit />,
            callback: () => this.handleEditMemberClick(itemData),
        };
        const deleteAction = {
            icon: <ActionDelete />,
            callback: () => this.handleDeleteMemberClick(itemData, loadItems),
        };

        const isManager = itemData.organization_role === 'manager';

        const toggleRoleAction = {
            icon: isManager ? <ActionDemote /> : <ActionPromote />,
            callback: () => {
                this.toggleMemberRole(itemData).then((res) => {
                    loadItems();
                });
            },
        };

        const nameWithAvatar = (
            <NameWithAvatar data-private>
                <div>
                    <Avatar size="default" src={itemData.avatar_url} />
                </div>
                <TableText type="bolder">
                    {`${itemData.first_name || ''} ${itemData.last_name || ''}`.trim()}
                </TableText>
            </NameWithAvatar>
        );

        const actions =
            itemData.id === this.props.accountId
                ? [editAction]
                : [editAction, toggleRoleAction, deleteAction];

        const member = {
            ...itemData,
            name: nameWithAvatar,
            title: itemData.title || '-',
            lastAccess: (
                <TableText type="lighter">{formatDateTime(itemData.last_access)}</TableText>
            ),
            actions,
        };

        return (
            <TableRow key={member.id}>
                {this.getColumns().map((column) => (
                    <TableCell key={column}>
                        {column === 'actions' ? (
                            <ActionButtonsGroup>
                                {member[column].map(
                                    (action, j) =>
                                        !this.state.isDeleting && (
                                            <div key={j} onClick={() => action.callback(member)}>
                                                {action.icon}
                                            </div>
                                        )
                                )}
                            </ActionButtonsGroup>
                        ) : column === 'organization_role' ? (
                            <TableText>{_(locale[member[column]])}</TableText>
                        ) : (
                            <TableText>{member[column]}</TableText>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        );
    };

    renderActionButtons = () => (
        <React.Fragment>
            <Button onClick={this.handleSelfRegistrationOptionsClick}>
                {_.get('team_self_registration_options')}
            </Button>
            <Button onClick={this.handleBulkInviteClick}>
                {_.get('team_bulk_invite_members')}
            </Button>
            <Button color="primary" onClick={this.handleInviteMemberClick}>
                {_.get('team_invite_member')}
            </Button>
        </React.Fragment>
    );

    render() {
        const { accountId, organizationId, organization, queryParams, setQueryParams } = this.props;

        const { activeModal, member } = this.state;

        return (
            <PageContainer>
                <StyledPageHeader>
                    <H2>{_.get('team_scene_title')}</H2>
                    <HeaderControlsGroup>
                        <InputWithDebounce
                            initialValue={queryParams.search}
                            debounceBy={250}
                            onDebounce={(value) => {
                                setQueryParams({
                                    ...queryParams,
                                    search: value,
                                    page: 1,
                                });
                            }}
                            beforeIconSrc="icons/ic-search.svg"
                            type="text"
                            placeholder={_.get('search_placeholder')}
                        />

                        {this.isManager() && this.renderActionButtons()}
                    </HeaderControlsGroup>
                </StyledPageHeader>
                <StyledPageBody>
                    <FlexColumn grow={1}>
                        {this.isProfileLoaded() ? (
                            <TrackedDataTable
                                queryParams={queryParams}
                                setQueryParams={setQueryParams}
                                defaultQueryParams={DEFAULT_QUERY_PARAMS}
                                loadItems={() =>
                                    api.organization.getOrganizationMembers({
                                        ...DEFAULT_QUERY_PARAMS,
                                        ...queryParams,
                                        organizationId,
                                    })
                                }
                                renderHeaders={this.renderHeaders}
                                renderItem={this.renderItem}
                                renderFooter={({ loadItems }) =>
                                    this.isManager() ? (
                                        <React.Fragment>
                                            <EditOrganizationMemberModal
                                                key={activeModal + '1'}
                                                organizationId={organizationId}
                                                member={member}
                                                isSelfEdit={member && member.id === accountId}
                                                onSuccess={() => {
                                                    this.closeModal();
                                                    loadItems();
                                                }}
                                                isVisible={this.isEditModalVisible()}
                                                onClose={this.closeModal}
                                            />
                                            <BulkInviteAccountModal
                                                key={activeModal + '2'}
                                                organizationId={organizationId}
                                                isVisible={this.isBulkInviteModalVisible()}
                                                onClose={this.closeModal}
                                                bulkCreateOrganizationMembers={
                                                    api.organization.bulkCreateOrganizationMembers
                                                }
                                                onSuccess={() => {
                                                    this.closeModal();
                                                    loadItems();
                                                }}
                                            />
                                            <SelfRegistrationOptionsModal
                                                key={activeModal + '3'}
                                                organization={organization}
                                                organizationId={organizationId}
                                                isVisible={this.isSelfRegistrationModalVisible()}
                                                onClose={this.closeModal}
                                                onSuccess={(showRegistrationUrlModal?: boolean) => {
                                                    this.closeModal();
                                                    if (showRegistrationUrlModal) {
                                                        this.showModal(
                                                            this.SELF_REGISTRATION_URL_MODAL
                                                        );
                                                    }
                                                    // TODO: reload org
                                                }}
                                            />
                                            <SelfRegistrationUrlModal
                                                key={activeModal + '4'}
                                                organization={organization}
                                                isVisible={this.isSelfRegistrationUrlModalVisible()}
                                                onClose={this.closeModal}
                                            />
                                            <ConfirmationModal
                                                key={activeModal + '5'}
                                                title={_(locale.deleteModalTitle)}
                                                message={_(locale.deleteModalMessage)}
                                                isVisible={this.isDeleteModalVisible()}
                                                onClose={this.closeModal}
                                                onSubmit={() => {
                                                    this.handleDeleteMemberSubmit(
                                                        member,
                                                        loadItems
                                                    );
                                                    this.closeModal();
                                                }}
                                            />
                                        </React.Fragment>
                                    ) : null
                                }
                            />
                        ) : (
                            <Loading />
                        )}
                    </FlexColumn>
                    <PageBodyFooter>{_.get('footer_note')}</PageBodyFooter>
                </StyledPageBody>
            </PageContainer>
        );
    }
}

export default compose(
    withStorage,
    withQueryParams,
    withState('searchValue', 'setSearchValue', ''),
    withHandlers({
        // increment: ({ setCounter }) => () => setCounter(n => n + 1),
    }),
    connect(mapStateToProps),
    withGoogleAnalytics({
        trackMethods: (props) => ({
            toggleMemberRole: (member) =>
                props.trackEvent({
                    category: 'Organization',
                    action: 'Change Role',
                }),
        }),
    })
)(TeamScene);
