import React from 'react';

import { BodyText2, FlexColumn, H1, Loading } from '@portal/common/components';
import { styled } from '@portal/common/theme';

const Container = styled(FlexColumn)(({ theme }) => ({
    paddingBottom: 30,
    '> *': {
        textAlign: 'center',
    },
}));

const ValueText = styled(H1)(({ theme }) => ({
    color: theme.color.purple,
    fontSize: 38,
}));

type Props = {
    isLoading?: boolean;
    value: string;
    label: string | React.ReactNode;
};

const MetricBlock = ({ isLoading = true, value = '', label = '' }: Props) => {
    if (isLoading) {
        return <Loading />;
    }

    return (
        <Container>
            <div>
                <ValueText align="center">{value}</ValueText>
            </div>
            <div>
                <BodyText2 align="center">{label}</BodyText2>
            </div>
        </Container>
    );
};
export default MetricBlock;
