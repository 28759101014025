import { RootState } from 'MyTypes';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { withHandlers } from 'recompose';
import { compose } from 'redux';

import {
    FlexColumn,
    H2,
    Loading,
    PageBody,
    PageBodyFooter,
    PageHeader,
    PageTabsMenuContainer,
    TabsContainer,
    TabsMenu,
    TabsMenuItem,
    TabsPanel,
} from '@portal/common/components';
import { withGoogleAnalytics } from '@portal/common/google-analytics';
import { RoleManager } from '@portal/common/models/organization-roles';
import { OrganizationMember } from '@portal/common/types';

import _ from '../../locale';
import {
    PROFILE_API_ACCESS_ID,
    PROFILE_CHANGE_PASSWORD_ID,
    PROFILE_GENERAL_ID,
    PROFILE_PATH,
} from '../../router/paths';
import { getOrganization, getSessionAccount } from '../../store/root-reducer';
import { signOut } from '../../store/session/actions';
import ApiAccessTab from './ApiAccessTab';
import ChangePasswordTab from './ChangePasswordTab';
import GeneralTab from './GeneralTab';
import api from '../../api';

const tabNamesByIndex = [PROFILE_GENERAL_ID, PROFILE_CHANGE_PASSWORD_ID, PROFILE_API_ACCESS_ID];

const mapStateToProps = (state: RootState) => ({
    sessionAccount: getSessionAccount(state),
    organization: getOrganization(state),
});

type Props = ReturnType<typeof mapStateToProps> &
    RouteComponentProps<{ tabId: string }> & {
        handleSignOutClick: (ev) => void;
    };

const ProfileScene = ({
    handleSignOutClick,
    match,
    history,
    sessionAccount,
    organization,
}: Props) => {
    const { tabId } = match.params;
    const selectedTabIndex = tabNamesByIndex.indexOf(tabId);
    const [account, setAccount] = useState<null | OrganizationMember>(null);

    const loadData = () => {
        api.organization
            .getOrganizationMember(organization.id, sessionAccount.id)
            .then((response) => {
                setAccount(response);
            })
            .catch(console.log);
    };
    useEffect(loadData, []);

    const handleTabChange = (selectedTabIndex) => {
        const tabName = tabNamesByIndex[selectedTabIndex];
        history.push(PROFILE_PATH + tabName);
    };

    if (account == null || organization == null) {
        return <Loading />;
    }

    const isApiAccessVisible =
        account.organization.has_programmatic_access &&
        account &&
        (account.organization_role === RoleManager || account.has_programmatic_access);

    return (
        <TabsContainer
            style={{ height: '100%' }}
            selectedIndex={selectedTabIndex}
            onSelect={handleTabChange}
        >
            <PageHeader>
                <H2>Profile & Settings</H2>
            </PageHeader>

            <PageTabsMenuContainer>
                <TabsMenu>
                    <TabsMenuItem>{_.get('profile_general_tab')}</TabsMenuItem>
                    <TabsMenuItem>{_.get('profile_change_password_tab')}</TabsMenuItem>
                    {isApiAccessVisible && (
                        <TabsMenuItem>{_.get('profile_api_access_tab')}</TabsMenuItem>
                    )}
                    <TabsMenuItem disabled onClick={handleSignOutClick}>
                        {_.get('profile_sign_out_tab')}
                    </TabsMenuItem>
                </TabsMenu>
            </PageTabsMenuContainer>

            <PageBody withTabs>
                <FlexColumn grow={1}>
                    <TabsPanel>
                        <GeneralTab />
                    </TabsPanel>
                    <TabsPanel>
                        <ChangePasswordTab />
                    </TabsPanel>
                    {isApiAccessVisible && (
                        <TabsPanel>
                            <ApiAccessTab />
                        </TabsPanel>
                    )}
                    <TabsPanel />
                </FlexColumn>
                <PageBodyFooter>{_.get('footer_note')}</PageBodyFooter>
            </PageBody>
        </TabsContainer>
    );
};

export default compose(
    withRouter,
    connect(mapStateToProps, { signOut }),
    withGoogleAnalytics(),
    withHandlers({
        handleSignOutClick: (props) => (event) => {
            props.trackEvent({
                category: 'Account',
                action: 'Sign Out',
            });
            props.signOut();
        },
    })
)(ProfileScene);
