import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { H4, SectionBody, SectionHeader } from '@portal/common/components';

import _ from '../../locale';
import ConditionDetailNotesTable from './ConditionDetailNotesTable';

const mapStateToProps = (state: RootState) => ({
    selectedConditions: state.dataExplorer.selectedConditions,
    conditionDetailNotes: state.dataExplorer.conditionDetailNotes || [],
});

export type Props = ReturnType<typeof mapStateToProps>;

const ConditionDetailNotesSection = ({ selectedConditions, conditionDetailNotes }: Props) => {
    if (selectedConditions.length === 0) {
        return null;
    }

    // UI State
    if (conditionDetailNotes == null || conditionDetailNotes.length === 0) {
        return null;
    }

    return (
        <SectionBody id="cdn">
            <SectionHeader>
                <H4>{_.get('custom_chart_scene_condition_detail_notes_title')}</H4>
            </SectionHeader>
            {conditionDetailNotes.map((i, idx) => (
                <ConditionDetailNotesTable key={idx} conditionDetailNotes={i} />
            ))}
        </SectionBody>
    );
};

export default compose(connect(mapStateToProps))(ConditionDetailNotesSection);
