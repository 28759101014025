import * as React from 'react';
import * as yup from 'yup';
import { RouteComponentProps } from 'react-router';
import { Form, FormikProps, Field, withFormik, ErrorMessage } from 'formik';

import { OrganizationMember } from '@portal/common/types';
import { styled } from '@portal/common/theme';
import { Button, Alert, ReCaptcha, FormikInput, FormGroup, Link } from '@portal/common/components';

import api from '../../api';
import { RESEND_SIGN_UP_EMAIL_PATH, SIGN_IN_PATH } from '../../router/paths';
import config from '../../config';

import _ from '../../locale';
import locale from './locale';

const ButtonContainer = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
});

const StyledAlert = styled(Alert)({
    marginBottom: 30,
});

const LinkContainer = styled.div({
    borderTop: '1px solid #e5e5e5',
    marginTop: 40,
    marginBottom: 60,
    paddingTop: 15,
    textAlign: 'center',
});

const StyledField = styled(Field)({
    width: 450,
});

const StyledForm = styled(Form)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 30,
});

type FormValues = Pick<
    OrganizationMember,
    'first_name' | 'last_name' | 'email' | 'password' | 'title'
> & {
    confirm_password: string;
};

type Props = RouteComponentProps & {
    token;
};

const mapNameToLabel = (name: string) => _.get(('account_' + name) as any);

const InnerForm: React.FC<Props & FormikProps<FormValues>> = (props) => {
    const { isSubmitting, status, setFieldValue, history } = props;
    return (
        <StyledForm>
            <StyledField
                name="first_name"
                component={FormikInput}
                mapNameToLabel={mapNameToLabel}
                type="text"
                required
                autoFocus
                data-private
            />

            <StyledField
                name="last_name"
                component={FormikInput}
                mapNameToLabel={mapNameToLabel}
                type="text"
                required
                data-private
            />

            <StyledField
                name="email"
                component={FormikInput}
                mapNameToLabel={mapNameToLabel}
                type="email"
                required
                data-private
            />

            <StyledField
                name="password"
                component={FormikInput}
                mapNameToLabel={mapNameToLabel}
                type="password"
                required
                data-private
            />

            <StyledField
                name="confirm_password"
                component={FormikInput}
                mapNameToLabel={mapNameToLabel}
                type="password"
                required
                data-private
            />

            <StyledField
                name="title"
                component={FormikInput}
                mapNameToLabel={mapNameToLabel}
                type="text"
            />

            <FormGroup>
                <ReCaptcha
                    reCaptchaKey={config.reCaptchaKey}
                    onVerification={(token) => {
                        setFieldValue('recaptcha_verification_token', token);
                    }}
                />
                <ErrorMessage name="recaptcha_verification_token" />
            </FormGroup>

            {status && status.error && <StyledAlert color="error">{status.error}</StyledAlert>}
            {status && status.success && (
                <StyledAlert color="success">{status.success}</StyledAlert>
            )}
            <ButtonContainer>
                <Button color="primary" type="submit" disabled={isSubmitting}>
                    {_.get('register_submit')}
                </Button>
            </ButtonContainer>

            <LinkContainer>
                <Link
                    onClick={() =>
                        history.push({
                            pathname: RESEND_SIGN_UP_EMAIL_PATH,
                            state: {
                                email: props.values.email,
                            },
                        })
                    }
                >
                    {_(locale.resendEmail)}
                </Link>
            </LinkContainer>
        </StyledForm>
    );
};

export default withFormik<Props, FormValues>({
    // initialize values
    mapPropsToValues: ({ token }) => ({
        self_sign_up_token: token,
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirm_password: '',
        title: '',
        recaptcha_verification_token: '',
    }),
    validationSchema: (test) =>
        yup.object().shape({
            confirm_password: yup
                .mixed()
                .test('match', _(locale.passwordsMustMatch), function (confirm_password) {
                    return confirm_password ? confirm_password === this.parent.password : true;
                }),
        }),
    handleSubmit: (values, form) => {
        api.organizationMember
            .add(values)
            .then((res) => {
                form.setSubmitting(false);

                form.props.history.push({
                    pathname: SIGN_IN_PATH,
                    state: {
                        successMessages: [_.get('register_success')],
                    },
                });
            })
            .catch((err: Error & { fields?: object }) => {
                form.setStatus({ error: err.message });
                form.setSubmitting(false);
                if (err.fields) {
                    form.setErrors(err.fields);
                }
            });
    },
})(InnerForm);
