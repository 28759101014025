import { deleteCookie, IMPERSONATED_SESSION } from '@portal/common/utility/cookies';

export const getLocalStorageItem = (key: string) =>
    localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;

export const deleteImpersonation = () => {
    deleteCookie(IMPERSONATED_SESSION);
};

export const removeLocalStorageItem = (key: string) => localStorage.removeItem(key);
