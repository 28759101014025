import React from 'react';

import { PRIMARY_ENTITY_ID_LOCALIZATION_KEY } from '@portal/common/models/data-key';
import { styled } from '@portal/common/theme';
import { SvgIcon, FlexRow, ButtonDropdown2 } from '@portal/common/components';
import { DataKey } from '@portal/common/types';

import _ from '../../locale';

const Container = styled.div(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginBottom: 20,
}));

const DropdownOption = styled.button<{ isChecked: boolean }>(({ theme, isChecked }) => ({
    color: isChecked ? theme.color.green1 : theme.color.jetBlack,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
}));

const StyledSvgIcon = styled(SvgIcon)(({ theme }) => ({
    path: {
        fill: theme.color.green1,
    },
}));

const RevertBtnContainer = styled.div<{ isResetDisabled?: boolean }>(
    ({ theme, isResetDisabled }) => ({
        position: 'absolute',
        top: 0,
        width: 200,
        background: theme.color.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        color: isResetDisabled ? theme.color.secondaryText : theme.color.green1,
        borderBottom: `1px solid #DFE0E0`,

        path: {
            fill: isResetDisabled ? '#DFE0E0' : theme.color.green1,
        },
    })
);

type Props = {
    className?: string;
    disabled?: boolean;
    options: DataKey[];
    value: DataKey[];
    onChange: (columns: DataKey[]) => void;
    disabledByDefaultOptions: DataKey[];
    customConditionLabel?: string;
};

const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    return sortedArr1.every((value, idx) => value === sortedArr2[idx]);
};

const DataResponseColumnsFilter: React.FC<Props> = ({
    disabled,
    options = [],
    value,
    onChange,
    disabledByDefaultOptions,
    customConditionLabel,
}) => {
    const defaultOptions = options.filter((key) => !disabledByDefaultOptions.includes(key));
    const handleChange = (checked, option) => {
        const updatedColumns = checked ? value.filter((i) => i !== option) : value.concat(option);
        onChange(updatedColumns);
    };

    const handleReset = () => {
        onChange(defaultOptions);
    };

    const isResetDisabled = areArraysEqual(defaultOptions, value);

    const customResetBtn = () => (
        <RevertBtnContainer isResetDisabled={isResetDisabled} onClick={handleReset}>
            Reset to Default
            <SvgIcon size="large" alt="revert" src="/icons/ic-revert.svg" />
        </RevertBtnContainer>
    );

    const renderOption = (item) => {
        const label =
            item === PRIMARY_ENTITY_ID_LOCALIZATION_KEY && customConditionLabel
                ? customConditionLabel
                : _(`filter_label_${item}`);
        return (
            <DropdownOption
                key={item.toString()}
                isChecked={value.includes(item)}
                onClick={() => {
                    handleChange(value.includes(item), item);
                }}
            >
                {label}{' '}
                {value.includes(item) && <StyledSvgIcon src="/icons/ic-status-success.svg" />}
            </DropdownOption>
        );
    };

    return (
        <Container>
            <ButtonDropdown2
                disabled={disabled || !options}
                isResetDisabled={isResetDisabled}
                buttonStyle={{ height: 50, minWidth: 200 }}
                disableHideOnInnerClick
                options={options}
                onReset={handleReset}
                customResetBtn={customResetBtn}
                renderOption={renderOption}
            >
                <FlexRow align="center" itemsSpacing={10}>
                    <SvgIcon src="/icons/ic-settings.svg" size="large" height={20} />
                    <div>{_('data_table_filter_select_columns')}</div>
                </FlexRow>
            </ButtonDropdown2>
        </Container>
    );
};

export default DataResponseColumnsFilter;
