import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { Resource, ResourceList } from '@portal/common/types';

import {
    getFavoriteResourcesAsync,
    getGroupContentResourcesAsync,
    getPublicResourceListsAsync,
    getResourceListsAsync,
    makeResourceListDefaultAsync,
    makeResourceListNotDefaultAsync,
    makeResourceListPrivateAsync,
    makeResourceListPublicAsync,
    setSavingFavoriteResource,
    setSavingResourceListResource,
    subscribeToListAsync,
    unsubscribeFromListAsync,
    deleteOrganizationResourceListAsync,
    removeFavoriteResource,
    removeResourceListResource,
    createResourceList,
    updateResourceList,
    addFavoriteResource,
    addResourceListResource,
    getPermalinkResourcesAsync,
} from './actions';

type State = {
    savingFavoriteResourcesIds: number[];
    favoriteResources: null | Resource[];
    permalinkResources: null | Resource[];
    groupContentResources: null | Resource[];
    // @todo Rename to myLists (lists created by me + subscribed lists) or split into two: listsCreatedByMe and subscribedLists (can be a better option)
    customLists: null | ResourceList[];
    publicLists: null | ResourceList[];
};

const initialState: State = {
    savingFavoriteResourcesIds: [],
    favoriteResources: null,
    permalinkResources: null,
    groupContentResources: null,
    customLists: null,
    publicLists: null,
};

export default combineReducers<State>({
    savingFavoriteResourcesIds: createReducer(initialState.savingFavoriteResourcesIds)
        .handleAction(getFavoriteResourcesAsync.success, (state, action) => [])
        .handleAction(setSavingFavoriteResource, (state, action) => state.concat(action.payload))
        .handleAction(addFavoriteResource, (state, action) =>
            state.filter((id) => id !== action.payload.id)
        )
        .handleAction(removeFavoriteResource, (state, action) =>
            state.filter((id) => id !== action.payload.resourceId)
        ),
    favoriteResources: createReducer(initialState.favoriteResources)
        .handleAction(getFavoriteResourcesAsync.success, (state, action) => action.payload)
        .handleAction(addFavoriteResource, (state, action) => (state || []).concat(action.payload))
        .handleAction(removeFavoriteResource, (state, action) =>
            (state || []).filter((res) => res.id !== action.payload.resourceId)
        ),

    permalinkResources: createReducer(initialState.permalinkResources).handleAction(
        getPermalinkResourcesAsync.success,
        (state, action) => action.payload
    ),

    groupContentResources: createReducer(initialState.groupContentResources).handleAction(
        getGroupContentResourcesAsync.success,
        (state, action) => action.payload
    ),
    customLists: createReducer(initialState.customLists)
        .handleAction(getResourceListsAsync.success, (state, action) => action.payload)
        .handleAction(deleteOrganizationResourceListAsync.success, (state, action) =>
            (state || []).filter((list) => list.id !== action.payload.listId)
        )
        .handleAction(createResourceList, (state, action) => (state || []).concat(action.payload))
        .handleAction(updateResourceList, (state, action) =>
            (state || []).map((list) => (list.id === action.payload.id ? action.payload : list))
        )

        .handleAction(
            addResourceListResource,
            (state, action) =>
                state &&
                state.map((list) => {
                    return list.id === action.payload.listId
                        ? {
                              ...list,
                              saving_resource_ids: (list.saving_resource_ids || []).filter(
                                  (resId) => resId !== action.payload.resource.id
                              ),
                              resource_ids: (list.resource_ids || []).concat(
                                  action.payload.resource.id
                              ),
                          }
                        : list;
                })
        )
        .handleAction(
            removeResourceListResource,
            (state, action) =>
                state &&
                state.map((list) => {
                    return list.id === action.payload.listId
                        ? {
                              ...list,
                              saving_resource_ids: (list.saving_resource_ids || []).filter(
                                  (resId) => resId !== action.payload.resourceId
                              ),
                              resource_ids: (list.resource_ids || []).filter(
                                  (resId) => resId !== action.payload.resourceId
                              ),
                          }
                        : list;
                })
        )
        .handleAction(
            setSavingResourceListResource,
            (state, action) =>
                state &&
                state.map((res) =>
                    res.id === action.payload.listId
                        ? {
                              ...res,
                              saving_resource_ids: (res.saving_resource_ids || []).concat(
                                  action.payload.resourceId
                              ),
                          }
                        : res
                )
        )
        .handleAction(subscribeToListAsync.success, (state, action) =>
            (state || [])
                .filter((list) => list.id !== action.payload.prevList.id)
                .concat({ ...action.payload.prevList, ...action.payload.list })
        )
        .handleAction(unsubscribeFromListAsync.success, (state, action) =>
            (state || []).filter((list) => list.id !== action.payload.id)
        )
        .handleAction(
            [makeResourceListPrivateAsync.success, makeResourceListPublicAsync.success],
            (state, action) =>
                (state || []).map((list) => {
                    return list.id === action.payload.id ? { ...list, ...action.payload } : list;
                })
        ),

    publicLists: createReducer(initialState.publicLists)
        .handleAction(getPublicResourceListsAsync.success, (state, action) => action.payload)
        .handleAction(makeResourceListPublicAsync.success, (state, action) =>
            (state || []).concat(action.payload)
        )
        .handleAction(makeResourceListPrivateAsync.success, (state, action) =>
            (state || []).filter((list) => list.id !== action.payload.id)
        )
        .handleAction(deleteOrganizationResourceListAsync.success, (state, action) =>
            (state || []).filter((list) => list.id !== action.payload.listId)
        )
        .handleAction(
            [makeResourceListDefaultAsync.success, makeResourceListNotDefaultAsync.success],
            (state, action) =>
                (state || []).map((list) => {
                    return list.id === action.payload.id
                        ? action.payload
                        : list.is_default
                        ? { ...list, is_default: false }
                        : list;
                })
        )
        .handleAction(updateResourceList, (state, action) =>
            (state || []).map((list) => (list.id === action.payload.id ? action.payload : list))
        ),
});
