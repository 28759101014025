import React, { useState } from 'react';

import {
    BodyText2,
    Button,
    FieldLabel,
    FlexColumn,
    FormGroup,
    H2,
    Input,
    PageBody,
    PageBodyFooter,
    PageHeader,
} from '@portal/common/components';
import { styled } from '@portal/common/theme';

import _ from '../../locale';

const Description = styled(BodyText2)(({ theme }) => ({
    padding: '50px 0',
    textAlign: 'left',
    width: 400,
}));

const Error = styled.div(({ theme }) => ({
    color: theme.color.error,
}));

type Props = {
    title: string;
    submit: (password: string) => boolean;
};

const SubmitPassword = ({ title, submit }: Props) => {
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<null | string>(null);

    const handleInputChange = (ev) => {
        setPassword(ev.target.value);
        setError(null);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleSubmit = () => {
        const isCorrectPassword = submit(password);
        setError(isCorrectPassword ? null : 'The password is not correct');
    };

    return (
        <>
            <PageHeader>
                <H2>{title}</H2>
            </PageHeader>
            <PageBody>
                <FlexColumn align="center" grow={1}>
                    <Description>{_('password_protected_tool_text')}</Description>
                    <FormGroup>
                        <FieldLabel>Password:</FieldLabel>
                        <Input
                            style={{ width: 400 }}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            value={password}
                            type="password"
                        />
                        {error && <Error>{`* ${error}`}</Error>}
                        <Button
                            color="primary"
                            type="submit"
                            onClick={handleSubmit}
                            style={{ width: 400, marginTop: 30 }}
                        >
                            {_('submit_button')}
                        </Button>
                    </FormGroup>
                </FlexColumn>
                <PageBodyFooter>{_.get('footer_note')}</PageBodyFooter>
            </PageBody>
        </>
    );
};

export default SubmitPassword;
