import React from 'react';
import { useDispatch } from 'react-redux';

import { DataCollectionResource, DataCondition, DataToolConfig } from '@portal/common/types';
import { CauseOutcome } from '@portal/common/models/data-type';
import {
    AGE_GROUP_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    PRIMARY_ENTITY_ID_KEY,
    ROUND_ID_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import formatChartValue from '@portal/common/utility/formatting/formatChartValue';
import { Alert } from '@portal/common/components';
import { styled } from '@portal/common/theme';
import { TYPE_LINE_CHART } from '@portal/common/models/chart-type';

import { downloadDataExportAsync } from '../../../store/data-explorer/actions';
import useGetApiData from '../hooks/use-get-api-data';
import config from '../../../config';
import _ from '../../../locale';
import Container from '../../../components/DataExplorer/ChartSection/LineChart/Container';

const Wrapper = styled.div(({ theme }) => ({
    width: '100%',
}));

type Props = {
    locationId: number;
    dataCollection: DataCollectionResource;
    dataToolConfig: DataToolConfig;
    dataTool: string;
};

const MortalityRateProjectionsLineChart = ({
    locationId,
    dataCollection,
    dataToolConfig,
    dataTool,
}: Props) => {
    const dispatch = useDispatch();

    const dataType = CauseOutcome;
    const conditions = [
        {
            data_type: dataType,
            [PRIMARY_ENTITY_ID_KEY]: config.allCausesCauseId,
        },
    ] as DataCondition[];

    const filters = {
        [LOCATION_ID_KEY]: [locationId],
        [AGE_GROUP_ID_KEY]: [config.standardizedAgeGroupId],
        [GENDER_ID_KEY]: [config.bothGendersId],
        [MEASURE_ID_KEY]: [config.deathMeasureId],
        [METRIC_ID_KEY]: [config.rateMetricId],
        [ROUND_ID_KEY]: [config.gbd2019RoundId],
    };

    const { isLoading, dataResponse, error } = useGetApiData({
        conditions,
        filters,
    });

    const renderTitle = () =>
        _('simulation_tool_line_chart_title', {
            location: _(`location_${locationId}`),
            yearsRange: `1990 - 2050`,
        });

    const renderSubtitle = () => '';

    const handleExportData = (format) => {
        dispatch(
            downloadDataExportAsync.request({
                format,
                chart_types: [TYPE_LINE_CHART],
                conditions,
                filters,
                data_types: [dataType],
            })
        );
    };

    return (
        <Wrapper>
            {error && <Alert color="error">{error}</Alert>}
            <Container
                key={dataResponse ? 'data-derived' : 'no-data'}
                isLoadingData={isLoading}
                filtersValues={filters}
                multipleFilterKeys={[FORECAST_SCENARIO_ID_KEY]}
                forecastTimeUnitStart={2020}
                initialTimeUnitValue={2019}
                isForecastingData
                enableExportPNG
                // onDataLoad={onDataLoad}
                selectedConditions={conditions}
                selectedConditionsDataTypes={[dataType]}
                selectedConditionsDataTypesGranularity={
                    dataCollection.datasets.find(({ data_type }) => data_type === dataType)
                        ?.granularity || {}
                }
                baseForecastScenarioId={config.referenceForecastScenarioId}
                observedForecastScenarioId={null}
                timeUnitKey={YEAR_KEY}
                mergedChartDataResponses={dataResponse}
                // chartDataResponsesByType={dataResponse}
                onExportData={handleExportData}
                numberFormatter={formatChartValue}
                dataToolConfig={dataToolConfig}
                selectedDataTool={dataTool}
                selectedDataCollection={dataCollection}
                renderTitle={renderTitle}
                renderSubtitle={renderSubtitle}
            />
        </Wrapper>
    );
};

export default MortalityRateProjectionsLineChart;
