export const COLUMN_ROW_OFFSET_Y = -8;
export const COLUMN_ROW_LABEL_PADDING_LEFT = 12;
export const TITLE_PADDING = 15;
export const BREADCRUMB_HEIGHT = 30;
export const PAGE_PADDING = 120;
export const PAGE_SIDEBAR_WIDTH = 270;
export const SCROLLBAR_WIDTH = 22;
export const COLUMN_ROW_MARGIN_BOTTOM = 8;
export const MOBILE_VIEW_WIDTH = 767;

export const STATE_UPDATE_TIMEOUT = 150;
