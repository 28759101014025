import React from 'react';

import {
    AGE_GROUP_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    ROUND_ID_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import { DataCondition } from '@portal/common/types';

import config from '../../../config';
import _ from '../../../locale';

import SingleValueMetricBlock from '../components/SingleValueMetricBlock';

type Props = {
    locationId: number;
    condition: DataCondition;
    valueFormatter?: (value: string | number | null) => string;
};

const ProjectedDeathsBlock = ({ locationId, condition, valueFormatter }: Props) => (
    <SingleValueMetricBlock
        conditions={[condition]}
        filters={{
            [LOCATION_ID_KEY]: [locationId],
            [AGE_GROUP_ID_KEY]: [config.allAgesGroupId],
            [GENDER_ID_KEY]: [config.bothGendersId],
            [MEASURE_ID_KEY]: [config.deathMeasureId],
            [METRIC_ID_KEY]: [config.numberMetricId],
            [FORECAST_SCENARIO_ID_KEY]: [config.referenceForecastScenarioId],
            [YEAR_KEY]: [2050],
            [ROUND_ID_KEY]: [config.gbd2019RoundId],
        }}
        label={_('simulation_tool_projected_deaths_metric')}
        valueFormatter={valueFormatter}
    />
);

export default ProjectedDeathsBlock;
