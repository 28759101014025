import React from 'react';

import _ from '@portal/common/locale';

import { Alert, FlexColumn, H3, Modal, ModalBody, ModalHeader } from '@portal/common/components';
import renderErrors from '@portal/common/utility/renderErrors';

import locale from './locale';
import { OrganizationMemberShape } from '../../utility/shapes';
import EditMemberForm from './EditMemberForm';

type Props = {
    organizationId: number;
    data: typeof OrganizationMemberShape;
    isNew: boolean;
    isSelfEdit: boolean;
    isVisible: boolean;
    onClose: () => void;
    onGeneratePassword: (member: typeof OrganizationMemberShape) => void;
    handleSuccess: (member: typeof OrganizationMemberShape) => void;
    errors: object | null;
    addOrganizationMember: (organizationId, data) => Promise<void>;
    editOrganizationMember: (organizationId, memberId, data) => Promise<void>;
};

const EditOrganizationMemberView: React.FC<Props> = ({
    organizationId,
    data,
    errors,
    isNew,
    isSelfEdit,
    isVisible,
    onGeneratePassword,
    onClose,
    addOrganizationMember,
    editOrganizationMember,
    handleSuccess,
}) => (
    <Modal show={isVisible} onHide={onClose}>
        <ModalHeader closeButton>
            <H3>{_(isNew ? locale.addTitle : locale.editTitle)}</H3>
        </ModalHeader>
        <ModalBody>
            {errors && (
                <FlexColumn>
                    <Alert color="error">{renderErrors(errors)}</Alert>
                </FlexColumn>
            )}

            <EditMemberForm
                organizationId={organizationId}
                isNew={isNew}
                isSelfEdit={isSelfEdit}
                member={data}
                onGeneratePassword={onGeneratePassword}
                handleCancel={onClose}
                addOrganizationMember={addOrganizationMember}
                editOrganizationMember={editOrganizationMember}
                handleSuccess={handleSuccess}
            />
        </ModalBody>
    </Modal>
);

export default EditOrganizationMemberView;
