import { CauseOutcome } from '@portal/common/models/data-type';
import { DataProjectId } from '@portal/common/models/data-project';

export const apiUrl = config.apiUrl;
export const clientUrl = config.clientUrl;
export const staffUrl = config.staffUrl;
export const appName = 'IHME Client Portal';
export const storageStateVersion = '39';
export const storageCacheVersion = '39';

export const googleAnalyticsDebug = false;
export const googleAnalyticsId = 'G-KRRFBJ660X';
export const reCaptchaKey = config.reCaptchaKey;

export const sentryDsn = 'https://22bbecc8292e4b8a955a5cc0756017b7@o251606.ingest.us.sentry.io/1437965';
export const sentryEnv = config.sentryEnv;

export const logrocketDsn = config.logrocketDsn;

// list of countries to fix empty data issues on global map
export const globalMapCountryLocationsIds =
    '6,7,8,10,11,12,13,14,15,16,17,18,19,20,22,23,24,25,26,27,28,29,30,33,34,35,36,37,38,39,40,41,43,44,45,46,47,48,49,50,51,52,53,54,55,57,58,59,60,61,62,63,66,67,68,69,71,72,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,97,98,99,101,102,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,121,122,123,125,126,127,128,129,130,131,132,133,135,136,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,157,160,161,162,163,164,165,168,169,170,171,172,173,175,176,177,178,179,180,181,182,183,184,185,186,187,189,190,191,193,194,195,196,197,198,200,201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,217,218,298,305,349,351,376,385,422,435,522';

// list of countries in sdi and haq ratings
export const countries =
    '6,7,8,10,11,12,13,14,15,16,17,18,19,20,22,23,24,25,26,27,28,29,30,33,34,35,36,37,38,39,40,41,43,44,45,46,47,48,49,50,51,52,53,54,55,57,58,59,60,61,62,63,66,67,68,69,71,72,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,97,98,99,101,102,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,121,122,123,125,126,127,128,129,130,131,132,133,135,136,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,157,160,161,162,163,164,165,168,169,170,171,172,173,175,176,177,178,179,180,181,182,183,184,185,186,187,189,190,191,193,194,195,196,197,198,200,201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,217,218,298,305,320,349,351,367,369,374,376,380,385,393,396,413,416,422,435,522';

export const locationsWithDetailedMapView = [
    135, 6, 95, 179, 163, 11, 142, 67, 180, 130, 72, 90, 62, 196, 93, 102, 86, 214, 165, 16, 51,
    523, 524, 525, 526, 527, 528, 529, 530, 531, 532, 533, 534, 535, 536, 537, 538, 539, 540, 541,
    542, 543, 544, 545, 546, 547, 548, 549, 550, 551, 552, 553, 554, 555, 556, 557, 558, 559, 560,
    561, 562, 563, 564, 565, 566, 567, 568, 569, 570, 571, 572, 573,
];

export const countrySpecificMapTypes = [
    1, 135, 6, 179, 163, 11, 142, 86, 67, 180, 130, 72, 214, 90, 165, 16, 51, 62, 196, 93, 95, 102,
    523, 524, 525, 526, 527, 528, 529, 530, 531, 532, 533, 534, 535, 536, 537, 538, 539, 540, 541,
    542, 543, 544, 545, 546, 547, 548, 549, 550, 551, 552, 553, 554, 555, 556, 557, 558, 559, 560,
    561, 562, 563, 564, 565, 566, 567, 568, 569, 570, 571, 572, 573,
];

export const globalLocationId = 1;
export const USALocationId = 102;
export const chinaLocationId = 6;
export const australiaLocationId = 71;
export const newZealandLocationId = 72;
export const southeastAsiaEastAsiaAndOceaniaLocationId = 4;
export const oceaniaLocationId = 21;

export const countryLocationTypeId = 2;

export const africanUnionIds = [
    139, 141, 147, 148, 154, 168, 169, 170, 171, 172, 173, 175, 176, 177, 178, 179, 180, 181, 182,
    183, 184, 185, 186, 187, 189, 190, 191, 193, 194, 195, 196, 197, 198, 200, 201, 202, 203, 204,
    205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 435, 522, 25344,
];
export const europeanUnionAndUKIds = [
    45, 46, 47, 48, 51, 52, 54, 55, 58, 59, 60, 75, 76, 77, 78, 79, 80, 81, 82, 84, 86, 87, 88, 89,
    91, 92, 93, 95,
];

export const oecdIds = [
75, 71, 76, 101, 98, 125, 126, 47, 78, 58, 79, 80, 81, 82, 48, 83, 84, 85, 86, 67, 68, 59, 60, 87, 130, 89, 72, 90, 51, 91, 54, 55, 92, 93, 94, 155, 95, 102
];

export const simToolCovariateRecordsLocationIds =  [
    6, 7, 8, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 23, 24, 25, 26, 27, 28, 29,
    30, 33, 34, 35, 36, 37, 38, 39, 40, 41, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
    54, 55, 57, 58, 59, 60, 61, 62, 63, 66, 67, 68, 69, 71, 72, 74, 75, 76, 77, 78, 79,
    80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 97, 98, 99, 101,
    102, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 121,
    122, 123, 125, 126, 127, 128, 129, 130, 131, 132, 133, 135, 136, 139, 140, 141, 142,
    143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 160, 161,
    162, 163, 164, 165, 168, 169, 170, 171, 172, 173, 175, 176, 177, 178, 179, 180, 181,
    182, 183, 184, 185, 186, 187, 189, 190, 191, 193, 194, 195, 196, 197, 198, 200, 201,
    202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218,
    298, 305, 320, 349, 351, 367, 369, 374, 376, 380, 385, 393, 396, 413, 416, 422, 435,
    522,
];

export const numberMetricId = 1;
export const rateMetricId = 3;

export const allAgesGroupId = 22;
export const birthAgeGroupId = 164;
export const thirtyAndAboveAgeGroupId = 338;
export const fiftyToFiftyFourAgeGroupId = 15;
export const seventyFiveToNineAgeGroupId = 20;
export const twentyAndAbove = 37;
export const standardizedAgeGroupId = 27;
export const eightyToEightyFourAgeGroupId = 30;
export const eightyFiveToEightyNineAgeGroupId = 31;
export const ninetyToNinetyFourAgeGroupId = 32;
export const ninetyFiveAndAboveAgeGroupId = 235;

export const ageBandsForSingleAgeEstimates = '34,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,30,31,32';
export const fiveYearAgeBands =
    '1,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,30,31,32,33,44,45,46,235';
export const fiveYearAgeBandsAndNeonates =
    '2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,30,31,32,235';
export const pediatricFocusAgeGroups = '164,2,3,4,5,6,7,8,37';
export const elderlyFocusAgeGroups = '284,16,17,18,19,20,30,31,32,235';
export const reproductiveFemaleAges = '7,8,9,10,11,12,13,14,15';
export const lifeCourseAges = '1,23,24,41,289,235';
export const adultFocusAges = '158,284,285';
export const fiveYearAgeBandsTwentyPlus = '9,10,11,12,13,14,15,16,17,18,19,20,30,31,32,235';

export const deathMeasureId = 1;
export const dalyMeasureId = 2;
export const yllMeasureId = 4;
export const prevalenceMeasureId = 5;
export const incidenceMeasureId = 6;
export const HALEMeasureId = 28;
export const lifeExpectancyMeasureId = 26;

export const allCausesCauseId = 294;
export const allPathogensCauseId = 100001;
export const multipleMyelomaCauseId = 486;
export const neoplasmsCauseId = 410;
export const neoplasmsChildrenCauseIds =
    '411,414,417,423,426,429,432,435,438,441,444,447,450,453,456,459,462,465,468,471,474,477,480,483,484,485,486,487,489,490,1008,1011,1012,1013';
export const newlyDiagnosedMultipleMyelomaCauseId = 10001;
export const multipleMyelomaInFirstRelapse = 10003;
export const multipleMyelomaInSecondRelapse = 10004;
export const multipleMyelomaInThirdRelapse = 10005;
export const multipleMyelomaInFourthOrHigherRelapse = 10006;

export const SDICovariateId = 881;
export const HQAIndexCovariateId = 1099;

export const pastEstimateScenarioId = null;
export const referenceForecastScenarioId = 1;
export const betterForecastScenarioId = 2;
export const worseForecastScenarioId = 3;
export const observedDataForecastScenarioId = 65;
export const historicForecastScenarioId = 10005;
export const projectedForecastScenarioId = 10006;

export const bothGendersId = 3;
export const maleGenderId = 1;
export const femaleGenderId = 2;

export const gbd2016RoundId = 4;
export const gbd2017RoundId = 5;
export const gbd2019RoundId = 6;
export const gbd2022RoundId = 7;

export const combinedFiltersWithoutTimeUnitsAmountWarning = 3000; // 3k
export const combinedFiltersWithoutTimeUnitsAmountChartLimit = 5500; // 5.5k
export const combinedFiltersAmountLimit = 2500000; // 2.5m
export const combinedFiltersAmountPerQueryLimit = 500000; // .5m

export const PDFRowsExportLimit = 175000; // 175k
export const ExcelRowsExportLimit = 210000; // 210k
export const CSVRowsExportLimit = 1000000; // 1m

export const anyMultipleMyelomaTreatmentId = 10001;

export const GHT_bins = [0, 10, 100, 500, 2000, 6000, 12000, 100000, 1000000];

export const singleYearAgeDataProjects = [DataProjectId.GBD];
export const singleYearAgeDataTypes = [CauseOutcome];
export const singleYearAgeMetricIds = [rateMetricId, numberMetricId];

export const simToolCauseOutcomeAgeGroupIds = [
    22, 27, 1, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 30, 31, 32, 33,
    44, 45, 46, 235
]
