import config from '../../config';
import { Button, SvgIcon, Tooltip } from '@portal/common/components';
import _ from '../../locale';
import React from 'react';

type Props = {
    isLoading: boolean;
    disabled: boolean;
    onClick: () => void;
    filtersAmount: number;
};
const RunQueryButton = (props: Props) => {
    const { isLoading, disabled, onClick, filtersAmount } = props;

    const canQuery = filtersAmount && filtersAmount < config.combinedFiltersAmountLimit;

    const buttonComponent = (
        <Button
            data-testid="apply-filters"
            color="primary"
            loading={isLoading}
            disabled={disabled || !canQuery}
            onClick={onClick}
            style={{ marginLeft: 20 }}
        >
            <SvgIcon
                style={{ marginRight: 10 }}
                src="/icons/ic-search-main.svg"
                color={(theme) => theme.color.white}
            />
            {_.get('select_data_apply_filters')}
        </Button>
    );

    return canQuery ? (
        buttonComponent
    ) : (
        <Tooltip placement="bottom" title={_('run_query_button_disabled_tooltip')}>
            {buttonComponent}
        </Tooltip>
    );
};

export default RunQueryButton;
