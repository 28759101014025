import React from 'react';
import { RootState } from 'MyTypes';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash/fp';

import {
    TYPE_ARROW_CHART,
    TYPE_BAR_CHART,
    TYPE_DATA_TABLE_CHART,
    TYPE_LINE_CHART,
    TYPE_MAP_CHART,
    TYPE_SCATTER_MAP_CHART,
    TYPE_TREEMAP_CHART,
} from '@portal/common/models/chart-type';
import { DataExportFormat } from '@portal/common/types';

import { changeSelectedChartType } from '../../../store/data-explorer/actions';
import {
    getDataToolConfig,
    getSelectedChartType,
    getSelectedConditions,
    getDataResponsesByType,
    getDataToolSupportedCharts,
} from '../../../store/data-explorer/selectors';
import _ from '../../../locale';

import ChartTypeSelector from '../../../components/ChartTypeSelector';
import ChartSectionRenderer from './ChartSectionRenderer';

const ICONS_BY_CHART_TYPE = {
    [TYPE_LINE_CHART]: '/icons/ic-timeline.svg',
    [TYPE_MAP_CHART]: '/icons/ic-map.svg',
    [TYPE_SCATTER_MAP_CHART]: '/icons/ic-map.svg',
    [TYPE_BAR_CHART]: '/icons/ic-barchart.svg',
    [TYPE_TREEMAP_CHART]: '/icons/ic-treemap.svg',
    [TYPE_ARROW_CHART]: '/icons/ic-arrowchart.svg',
};

const mapStateToProps = (state: RootState) => ({
    selectedChartType: getSelectedChartType(state),
    selectedConditions: getSelectedConditions(state),
    dataToolConfig: getDataToolConfig(state),
    dataResponsesByType: getDataResponsesByType(state),
    supportedCharts: getDataToolSupportedCharts(state),
});

const dispatchProps = {
    changeChartType: changeSelectedChartType,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & {
        onExportData: (format: DataExportFormat) => void;
        onDataRendered?: () => void;
        areChartsVisible?: boolean;
    };

type State = {};

class ChartSection extends React.PureComponent<Props, State> {
    static defaultProps = {
        areChartsVisible: true,
    };
    handleChartTypeChange = (chartType: any) => {
        this.props.changeChartType(chartType);
    };

    render() {
        const {
            selectedChartType,
            selectedConditions,
            dataToolConfig,
            onExportData,
            dataResponsesByType,
            onDataRendered,
            supportedCharts,
            areChartsVisible,
        } = this.props;

        if (
            !(
                selectedConditions &&
                selectedConditions.length &&
                dataToolConfig &&
                dataResponsesByType
            ) ||
            isEmpty(supportedCharts)
        ) {
            return null;
        }

        const config = supportedCharts.reduce((charts, chartType) => {
            if (areChartsVisible || chartType === TYPE_DATA_TABLE_CHART) {
                charts.push({
                    value: chartType,
                    icon: ICONS_BY_CHART_TYPE[chartType],
                });
            }

            return charts;
        }, []);

        return (
            <div key={supportedCharts.join(',')}>
                <ChartTypeSelector
                    config={config}
                    value={selectedChartType}
                    mapValueToLabel={(value) => _(`chart_type_label_${value}`)}
                    onSelect={this.handleChartTypeChange}
                />
                <ChartSectionRenderer onExportData={onExportData} onDataRendered={onDataRendered} />
            </div>
        );
    }
}

export default compose(connect(mapStateToProps, dispatchProps))(ChartSection);
