import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'MyTypes';
import { capitalize } from 'lodash/fp';
import copy from 'clipboard-copy';

import {
    FieldHintText,
    FormInput,
    H2,
    Modal,
    ModalBody,
    ModalButton,
    ModalFooter,
    ModalHeader,
} from '@portal/common/components';
import { localizeConditions } from '@portal/common/utility/chart-data-helpers';
import { DataExplorerSelectionResource } from '@portal/common/types';
import { parseApiError } from '@portal/common/utility/api-helpers';

import { getOrganizationId, getSessionAccountId } from '../../../store/root-reducer';
import _ from '../../../locale';
import api from '../../../api';
import { PERMALINK_PATH } from '../../../router/paths';
import config from '../../../config';

type Props = {
    isVisible: boolean;
    onClose: (isSubmitted: boolean) => void;
    organizationId: number;
    memberId: number;
    dataExplorerSelection: DataExplorerSelectionResource;
};

const mapStateToProps = (state: RootState) => ({
    organizationId: getOrganizationId(state),
    memberId: getSessionAccountId(state),
});

function SharePermalinkModal({
    isVisible,
    onClose,
    organizationId,
    memberId,
    dataExplorerSelection,
}: Props) {
    if (!isVisible) return null;

    const [permalink, setPermalink] = useState('');
    const [errors, setErrors] = useState<object | null>(null);

    const getShorter = (string, initArr) => {
        let innerSting = string;
        if (innerSting.length > 128) {
            const innerArr = initArr.filter((item, index) => index !== initArr.length - 1);
            innerSting = innerArr.join(', ') + ' and other conditions';
            getShorter(innerSting, innerArr);
        } else {
            setPermalink(innerSting);
        }
    };

    const getPermalinkFormattedConditions = () => {
        const initArr = localizeConditions(dataExplorerSelection.conditions).split(', ');
        const result = initArr.join(', ');

        return getShorter(result, initArr);
    };

    const handleSubmit = () => {
        api.permalink
            .createPermalink({
                name: permalink,
                organizationId,
                memberId,
                dataExplorerSelection,
            })
            .then((res) => {
                copy(config.clientUrl + PERMALINK_PATH + '/' + res.hash);
                setErrors(null);
                onClose(true);
            })
            .catch(handleError);
    };

    const handleError = (err) => {
        setErrors(parseApiError(err));
    };

    useEffect(() => {
        getPermalinkFormattedConditions();
    }, []);

    return (
        <Modal show={isVisible} onHide={onClose} onEnter={() => {}}>
            <ModalHeader>
                <H2>{capitalize(_.get('share_permalink_title'))}</H2>
            </ModalHeader>
            <ModalBody>
                <FormInput
                    required
                    size="large"
                    autoFocus
                    name="title"
                    error={errors && errors.name}
                    value={permalink}
                    onChange={(ev) => {
                        setPermalink(ev.target.value);
                    }}
                    label={_.get('share_permalink_input_label')}
                />
                <FieldHintText>{_.get('share_permalink_message')}</FieldHintText>
            </ModalBody>
            <ModalFooter>
                <ModalButton color="secondary" onClick={() => onClose(false)}>
                    {_.get('cancel_button')}
                </ModalButton>
                <ModalButton onClick={handleSubmit} type="submit">
                    {_.get('share_permalink_copy_btn')}
                </ModalButton>
            </ModalFooter>
        </Modal>
    );
}

export default connect(mapStateToProps, null)(SharePermalinkModal);
