import { intersection, isEmpty, pick } from 'lodash/fp';

import { ROUND_ID_KEY, isTimeUnitKey } from '@portal/common/models/data-key';
import {
    DataGranularity,
    DataFilters,
    DataKey,
    DataExplorerSelectionResource,
    DataValue,
} from '@portal/common/types';
import { getConditionsDataTypes } from '@portal/common/utility/filters-helpers';

/**
 * intersectRefinementFiltersWithGranularity, no workarounds
 */
export const intersectRefinementFiltersWithGranularity = (
    refinementFilters: DataFilters | null,
    granularity: DataGranularity
) => {
    if (refinementFilters == null) {
        return null;
    }

    const filters = {};
    for (const key in refinementFilters) {
        if (granularity[key] != null) {
            const intersectedValues = intersection(refinementFilters[key], granularity[key]);
            if (intersectedValues.length) {
                filters[key] = intersectedValues;
            }
        }
    }

    return filters;
};

export const fillMissingFiltersWithDefaults = (
    filters: DataFilters,
    granularity: DataGranularity,
    filtersToSkip: DataKey[] = []
): DataFilters => {
    const filtersWithDefaults = { ...filters };
    // For granularity key that has no value pick first available granularity option
    Object.entries(granularity).forEach(([granularityKey, filterGranularity]) => {
        const filterValue = filters[granularityKey];
        // skip empty granularity and time units keys
        if (
            isEmpty(filterValue) &&
            !isTimeUnitKey(granularityKey) &&
            filterGranularity &&
            filterGranularity.length &&
            !filtersToSkip.includes(granularityKey)
        ) {
            filtersWithDefaults[granularityKey] = filterGranularity.slice(0, 1);
        }
    });

    return filtersWithDefaults;
};

/**
 * @deprecated use pickRefinementFiltersByGranularity
 */
export const removeFiltersNotInGranularity = (
    partialDefaultFilters?: DataFilters,
    granularity?: DataGranularity
): DataFilters => {
    const defaultFilters = { ...partialDefaultFilters };

    if (!isEmpty(granularity)) {
        for (const filter in defaultFilters) {
            // if defaultFilter is empty, then leave it empty
            if (!defaultFilters[filter].length) {
                continue;
            }

            // except TimeUnits filters
            if (!isTimeUnitKey(filter)) {
                const defaultAndGranularityIntersection = intersection(
                    defaultFilters[filter],
                    granularity[filter]
                );
                // We need to filter out irrelevant filter keys
                if (isEmpty(granularity[filter])) {
                    delete defaultFilters[filter];
                }
                // Select the first value if the default values are not present in the granularity
                else if (
                    !(defaultAndGranularityIntersection && defaultAndGranularityIntersection.length)
                ) {
                    if (filter === ROUND_ID_KEY) {
                        // Fallback to the latest available round
                        defaultFilters[filter] = [Math.max(...granularity[filter])];
                    } else {
                        // Fallback to the first available option
                        defaultFilters[filter] = [granularity[filter][0]];
                    }
                }
            }
        }

        /*if (granularity[FORECAST_SCENARIO_ID_KEY]) {
        // auto-select all forecast scenarios
            defaultFilters[FORECAST_SCENARIO_ID_KEY] = granularity[FORECAST_SCENARIO_ID_KEY] || [];
        }*/

        if (!defaultFilters[ROUND_ID_KEY]) {
            // Fallback to the latest available round
            defaultFilters[ROUND_ID_KEY] = [Math.max(...granularity[ROUND_ID_KEY])];
        }
    }

    for (const key in defaultFilters) {
        defaultFilters[key] = intersection(granularity[key], defaultFilters[key]);
    }

    return defaultFilters;
};

export const generateDataExplorerSelection = (
    dataTool,
    dataCollection,
    conditions,
    refinementFilters,
    granularity,
    visibleRefinementFilters,
    selectedDataType,
    sortedByColumn,
    sortedDescending,
    visibleColumns,
    selectedChartType,
    chartSettings
): DataExplorerSelectionResource => {
    const conditionDataTypes =
        conditions && conditions.length ? getConditionsDataTypes(conditions) : null;

    let primaryEntityFilterKeys = [];

    if (dataCollection && conditionDataTypes) {
        const datasets = dataCollection.datasets.filter(({ data_type }) =>
            conditionDataTypes.includes(data_type)
        );
        primaryEntityFilterKeys = datasets.map(
            ({ primary_entity_id_filter }) => primary_entity_id_filter
        );
    }

    const normalizedFilters = {};
    const allowedKeys = Object.keys(granularity).filter(
        (key) => !primaryEntityFilterKeys.includes(key)
    );
    const granularityWithoutPrimaryEntities = pick(allowedKeys, granularity);

    Object.entries(granularityWithoutPrimaryEntities).forEach(([key, value]) => {
        const filterGranularity: DataValue = (value as DataValue) || [];

        const newValue = !refinementFilters.hasOwnProperty(key)
            ? [...filterGranularity]
            : intersection(filterGranularity, refinementFilters[key]);

        if (!isEmpty(newValue)) {
            normalizedFilters[key] = newValue;
        }
    });

    return {
        data_collection_resource_id: dataCollection && dataCollection.id,
        conditions,
        refinement_filters: normalizedFilters,
        configuration: {
            sortedByColumn,
            sortedDescending,
            visibleColumns,
            dataType: selectedDataType,
            chartType: selectedChartType,
            activeRefinements: visibleRefinementFilters,
            chartSettings,
            dataTool,
        },
    };
};
