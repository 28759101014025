import React, { RefObject } from 'react';
import FileSaver from 'file-saver';
import { convertArrayToCSV } from 'convert-array-to-csv';
import isEqual from 'fast-deep-equal';
import { isEmpty, groupBy, merge, uniq, uniqBy } from 'lodash/fp';

import { EChartsInstance } from 'echarts-for-react';
import SaveAsImage from 'echarts/lib/component/toolbox/feature/SaveAsImage';

import {
    DataCondition,
    DataFilters,
    DataGranularityKey,
    DataKey,
    DataRecord,
} from '@portal/common/types';
import { VALUE_KEY, YEAR_KEY } from '@portal/common/models/data-key';
import { formatValue } from '@portal/common/utility/filters-helpers';
import { EChartsWrapper } from '@portal/common/components';
import { getCanvasTextWidth } from '@portal/common/utility/echarts-helpers/get-adopted-title-and-subtitle';
import { localizeCondition } from '@portal/common/utility/chart-data-helpers';
import { csvIcon } from '@portal/common/theme/icons';

import { getLegendHeight, getTitlesHeight } from '../../utility/sizing/get-chart-component-height';
import echartsTheme, { colorPalette } from '../../theme/echarts';
import _ from '../../locale';

import { createColumnData, createLinks } from './ArrowChartElements';
import {
    BREADCRUMB_HEIGHT,
    COLUMN_ROW_MARGIN_BOTTOM,
    MOBILE_VIEW_WIDTH,
    PAGE_PADDING,
    PAGE_SIDEBAR_WIDTH,
    SCROLLBAR_WIDTH,
    STATE_UPDATE_TIMEOUT,
    TITLE_PADDING,
} from './constants';

type RenderProps = ReturnType<ArrowChart['getRenderProps']>;

type Props = {
    forwardedRef: RefObject<typeof ArrowChart>;
    dataKey: DataKey;
    timeUnitKey: DataGranularityKey;
    onEvents: object;
    mobileViewWidth: number;
    configureGrid: (props: RenderProps) => Array<object>;
    configureTitles: (props: RenderProps) => Array<object>;
    formatLegendLabel: (value: number, props: RenderProps) => string;
    renderTooltip: (params: any, props: RenderProps) => string;
    saveAsImage: {
        visible?: boolean;
        enabled?: boolean;
        filename?: (props: RenderProps) => string;
        icon?: string;
        title?: string;
        onClick?: () => void;
    };
    saveAsCSV: {
        visible: boolean;
        filename?: (props: RenderProps) => string;
        icon?: string;
        title?: string;
        headers?: Array<string>;
        keys?: Array<string>;
    };
    theme: object;
    resizeCallback?: (width: number) => void;
    legendPosition: 'right' | 'bottom' | 'none';
    isLoadingData: boolean;
    filtersValues: DataFilters;
    columns: Array<DataKey>;
    records: Array<DataRecord>;

    getCategoryName?: (record: DataRecord) => string;
    getItemLabel?: (record: DataRecord) => string;
    selectedCategories?: object;
    filterRecordsBySelectedCategories: (records: DataRecord[]) => DataRecord[];
    mapDataRecordToCategory: (record: DataRecord) => string;
    onNodeClick?: (params: any) => void;
    drillableItemIds: number[];
    drillPath?: DataCondition[];
    isDrillable: boolean;
    onBreadcrumbClick?: (value: DataCondition) => void;
};

type State = {
    sourceRecords: DataRecord[];
    targetRecords: DataRecord[];
    timeUnitValues: number[] | string[];
    sourceTimeUnit: number | string;
    targetTimeUnit: number | string;

    chartWidth: number;
    titlesHeight: number;
    legendHeight: number;
    blurredItemsDataKeyValues: [];
};

const getAvailableChartWidth = () => {
    return window.innerWidth - PAGE_SIDEBAR_WIDTH - PAGE_PADDING - SCROLLBAR_WIDTH;
};

class ArrowChart extends React.PureComponent<Props, State> {
    static defaultProps = {
        timeUnitKey: YEAR_KEY,
        mobileViewWidth: MOBILE_VIEW_WIDTH,
        configureGrid: () => {},
        configureTitles: () => {},
        saveAsImage: {
            visible: false,
        },
        saveAsCSV: {
            visible: false,
        },
        onEvents: {},
        legendPosition: 'bottom',
    };

    state: State = {
        sourceRecords: [],
        targetRecords: [],
        timeUnitValues: [],
        sourceTimeUnit: 0,
        targetTimeUnit: 0,

        chartWidth: getAvailableChartWidth(),
        titlesHeight: 0,
        legendHeight: 0,
        blurredItemsDataKeyValues: [],
    };

    theme: any;
    chartInstance = null;

    constructor(props: Props) {
        super(props);
        this.theme = merge(echartsTheme, props.theme);
    }

    componentDidMount() {
        this.setState(this.prepareData());
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: any
    ): void {
        const { records, theme, onEvents, selectedCategories } = this.props;

        this.theme = merge(echartsTheme, theme);

        let newState: {
            legendHeight?: number;
            titlesHeight?: number;
            blurredItemsDataKeyValues?: [];
        } = {};

        const isDataChanged = !isEqual(prevProps.records, records);

        if (isDataChanged) {
            newState = {
                ...newState,
                ...this.prepareData(),
            };
        }

        const titlesHeight = getTitlesHeight(this.chartInstance);
        if (titlesHeight !== 0) {
            newState.titlesHeight = titlesHeight;
        }

        const legendHeight =
            this.props.legendPosition === 'bottom' ? getLegendHeight(this.chartInstance) : 0;
        if (legendHeight !== 0) {
            newState.legendHeight = legendHeight;
        }

        if (prevProps.selectedCategories !== selectedCategories) {
            newState.blurredItemsDataKeyValues = [];
        }

        if (
            (legendHeight && prevState.legendHeight !== newState.legendHeight) ||
            (newState.titlesHeight && prevState.titlesHeight !== newState.titlesHeight) ||
            isDataChanged ||
            prevProps.selectedCategories !== selectedCategories
        ) {
            setTimeout(() => {
                this.setState(newState);
            }, STATE_UPDATE_TIMEOUT);
        }

        isDataChanged && onEvents?.finished && setTimeout(onEvents.finished, 250);
    }

    prepareData = (): Partial<State> => {
        const { records } = this.props;

        if (isEmpty(records)) {
            return {
                timeUnitValues: [],
                sourceRecords: [],
                targetRecords: [],
                sourceTimeUnit: 0,
                targetTimeUnit: 0,
            };
        }

        const [sourceTimeUnit, targetTimeUnit, timeUnitValues] = this.getTimeUnitsRange();
        const [sourceRecords, targetRecords] = this.splitRecordsByColumns(
            sourceTimeUnit,
            targetTimeUnit
        ).map((columnRecords) => columnRecords.sort(this.sortRecordsByValue));

        return {
            timeUnitValues,
            sourceTimeUnit,
            targetTimeUnit,
            sourceRecords,
            targetRecords,
        };
    };

    getTimeUnitsRange = (): [number, number, number[]] | [string, string, string[]] => {
        const { columns, records, timeUnitKey } = this.props;
        let from;
        let to;

        const timeUnitIdx = columns.indexOf(timeUnitKey);
        const recordsByTimeUnit = Object.entries(groupBy((record) => record[timeUnitIdx], records));
        recordsByTimeUnit.reduce((accumulator, [timeUnit, records]) => {
            const getCastedTimeUnitValue = () => {
                return timeUnitKey === YEAR_KEY ? parseInt(timeUnit) : timeUnit;
            };

            const recordsAmount = records.length;
            if (recordsAmount > accumulator) {
                to = from = getCastedTimeUnitValue();
            } else if (recordsAmount === accumulator) {
                to = getCastedTimeUnitValue();
            }
            return Math.max(accumulator, recordsAmount);
        }, 0);

        const allTimeUnitValues = uniq(records.map((record) => record[timeUnitIdx])).sort();
        const values = allTimeUnitValues
            .slice(0, allTimeUnitValues.indexOf(to) + 1)
            .slice(allTimeUnitValues.indexOf(from));

        return [from, to, values];
    };

    sortRecordsByValue = (a, b) => {
        const { columns } = this.props;
        const valueIdx = columns.indexOf(VALUE_KEY);

        return b[valueIdx] - a[valueIdx];
    };

    onChartReady = (instance: EChartsInstance) => {
        this.chartInstance = instance;
    };

    isMobileView = () => this.state.chartWidth <= this.props.mobileViewWidth;

    onChartResize = (width: number, height: number) => {
        this.setState({ chartWidth: width });
    };

    getRenderProps = () => ({
        ...this.state,
        isMobileView: this.isMobileView(),
        filters: this.props.filtersValues,
    });

    getGridPositions = () => [
        {
            ...this.props.configureGrid(this.getRenderProps()),
            top: this.getGridTop(),
            height: this.getGridHeight(),
            left: 20,
            right: 20,
        },
    ];

    getBreadcrumbTop = (): number => this.state.titlesHeight + TITLE_PADDING;

    getGridTop = (): number => {
        let result = this.state.titlesHeight + TITLE_PADDING;
        if (this.isBreadcrumbShown()) {
            result += BREADCRUMB_HEIGHT;
        }
        return result;
    };

    getGridHeight = (): number => {
        const { filterRecordsBySelectedCategories } = this.props;
        const { sourceRecords } = this.state;
        const totalRows = filterRecordsBySelectedCategories(sourceRecords).length + 1; // + ColumnTitle
        const rowHeight = this.theme.arrow.series.symbolSize[1];
        let height = totalRows * (rowHeight + COLUMN_ROW_MARGIN_BOTTOM);
        if (totalRows < 8) {
            // This is hack for adding gaps between rows, cause echarts doesn't add them when it's "not enough height"
            const EXTRA_HEIGHT = 10;
            height += EXTRA_HEIGHT;
        }

        return height;
    };

    getGridBottom = (): number => this.getGridTop() + this.getGridHeight();

    getTitlesPositions = () => {
        const { isLoadingData, records } = this.props;

        const isDataEmpty = records.length === 0;

        const noDataTitleOverride = {
            show: isDataEmpty && !isLoadingData,
            text: _('chart_error_no_data'),
            top: '40%',
            textStyle: {
                ...this.theme.textStyle,
                fontSize: 28,
                fontWeight: 700,
            },
        };

        return [...this.props.configureTitles(this.getRenderProps()), noDataTitleOverride];
    };

    getLegendOption = () => {
        const { selectedCategories } = this.props;
        const { chartWidth } = this.state;

        return {
            ...this.theme.arrow.legend,
            textStyle: {
                padding: [0, 0, 0, 6],
                width: chartWidth / 3,
            },
            top: this.getGridBottom(),
            ...(!isEmpty(selectedCategories) && {
                selected: selectedCategories,
                data: Object.keys(selectedCategories).map((name) => ({ name })),
            }),
        };
    };

    getTooltipOption = () =>
        this.props.renderTooltip
            ? {
                  ...this.theme.tooltip,
                  trigger: 'item',
                  formatter: (params: any) =>
                      this.props.renderTooltip(params, this.getRenderProps()),
                  position: this.isMobileView() ? [0, '50%'] : null,
              }
            : null;

    splitRecordsByColumns = (from, to): [(DataRecord | [])[], (DataRecord | [])[]] => {
        const { dataKey, records, columns, timeUnitKey } = this.props;
        const timeUnitIdx = columns.indexOf(timeUnitKey);
        const dataKeyIdx = columns.indexOf(dataKey);

        const fromRecords = uniqBy(
            (record) => record[dataKeyIdx],
            records.filter((record) => record[timeUnitIdx] === from)
        );
        const endRecords = uniqBy(
            (record) => record[dataKeyIdx],
            records.filter((record) => record[timeUnitIdx] === to)
        );

        const sourceColumn: (DataRecord | [])[] = [];
        let targetColumn: (DataRecord | [])[] = [];
        if (from === to) {
            // one time unit is chosen
            sourceColumn.push(...fromRecords);
            targetColumn = Array.from({ length: fromRecords.length }, () => []);

            return [sourceColumn, targetColumn];
        }

        fromRecords.forEach((record) => {
            sourceColumn.push(record);
            const targetRecord = endRecords.find((item) => record[dataKeyIdx] === item[dataKeyIdx]);
            targetColumn.push(targetRecord || []);
        });

        return [sourceColumn, targetColumn];
    };

    getSeries = () => {
        const { records, filterRecordsBySelectedCategories } = this.props;

        if (isEmpty(records)) {
            return { data: [], links: [], categories: [] };
        }

        const { sourceTimeUnit, targetTimeUnit, sourceRecords, targetRecords } = this.state;

        const isOneTimeUnitChosen = sourceTimeUnit === targetTimeUnit;

        const categories = this.getCategories(sourceRecords);
        const leftColumn = filterRecordsBySelectedCategories(sourceRecords);
        const rightColumn = isOneTimeUnitChosen
            ? Array.from({ length: leftColumn.length }, () => [])
            : filterRecordsBySelectedCategories(targetRecords);

        const { leftColumnData, rightColumnData } = this.getColumnsData({
            leftColumn,
            leftColumnTitle: sourceTimeUnit,
            rightColumn,
            rightColumnTitle: targetTimeUnit,
            isOneTimeUnitChosen,
            categories,
        });

        const links = isOneTimeUnitChosen
            ? []
            : this.getSeriesLinks({ leftColumn: leftColumnData, rightColumn: rightColumnData });

        return { data: [...leftColumnData, ...rightColumnData], links, categories };
    };

    getCategories = (records: DataRecord[]) => {
        return uniq(records.map(this.props.mapDataRecordToCategory)).map((name) => ({ name }));
    };

    mapDataRecordToCategoryIdx =
        (categories: []) =>
        (record: DataRecord): number => {
            const categoryName = this.props.mapDataRecordToCategory(record);
            return categories.map(({ name }) => name).indexOf(categoryName);
        };

    mapDataRecordToName = (record: DataRecord): string => {
        const { columns, dataKey } = this.props;
        const dataKeyIdx = columns.indexOf(dataKey);
        return record[dataKeyIdx].toString();
    };

    dataKeyValueGetter = (record: DataRecord): string | number => {
        const { columns, dataKey } = this.props;
        const dataKeyIdx = columns.indexOf(dataKey);
        return record[dataKeyIdx];
    };

    getColumnsData = ({
        leftColumn,
        leftColumnTitle,
        rightColumn,
        rightColumnTitle,
        isOneTimeUnitChosen,
        categories,
    }) => {
        const { chartWidth, blurredItemsDataKeyValues } = this.state;
        const { columns, dataKey, getItemLabel, drillableItemIds } = this.props;
        const sharedParams = {
            dataKey,
            columns,
            isOneTimeUnitChosen,
            theme: this.theme,
            chartWidth,
            blurredItemsDataKeyValues,
            mapDataRecordToCategoryIdx: this.mapDataRecordToCategoryIdx(categories),
            mapDataRecordToName: this.mapDataRecordToName,
            dataKeyValueGetter: this.dataKeyValueGetter,
            mapDataRecordToLabel: getItemLabel,
            drillableItems: drillableItemIds,
        };

        const coefficient =
            (-parseInt(echartsTheme.arrow.xAxis.min) + parseInt(echartsTheme.arrow.xAxis.max)) /
            chartWidth;
        const rowWidth = this.theme.arrow.series.symbolSize[0];
        const columnX = (chartWidth / 2 - rowWidth) * coefficient;

        const leftColumnData = createColumnData({
            oppositeColumn: rightColumn,
            title: leftColumnTitle,
            data: leftColumn,
            isLeftColumn: true,
            columnX: -columnX,
            ...sharedParams,
        });
        const rightColumnData = createColumnData({
            oppositeColumn: leftColumn,
            title: rightColumnTitle,
            data: rightColumn,
            isLeftColumn: false,
            columnX,
            ...sharedParams,
        });

        return { leftColumnData, rightColumnData };
    };

    getSeriesLinks = ({ leftColumn, rightColumn }) => {
        const { blurredItemsDataKeyValues } = this.state;
        return createLinks({ leftColumn, rightColumn, blurredItemsDataKeyValues });
    };

    getToolboxOption = () => {
        const mySaveAsImage = this.getSaveAsImageConfig();
        const mySaveAsCSV = this.getSaveAsCSVConfig();

        return {
            top: this.isMobileView() ? 20 : 0,
            right: 30,
            z: 10,
            ...this.theme.toolbox,
            feature: {
                ...(mySaveAsImage && { mySaveAsImage }),
                ...(mySaveAsCSV && { mySaveAsCSV }),
                saveAsImage: { show: false },
            },
        };
    };

    getSaveAsImageConfig = () => {
        const {
            visible,
            enabled,
            filename = (renderProps?) => 'Chart_ScreenShot',
            icon,
            title = _('chart_save_as_image_button_label'),
            onClick,
        } = this.props.saveAsImage;

        if (!visible) {
            return undefined;
        }

        const config = {
            excludeComponents: ['toolbox'],
            pixelRatio: 2,
            title,
            name: filename(this.getRenderProps()),
            onclick: function (...params) {
                if (!enabled) {
                    return;
                }
                SaveAsImage.prototype.onclick.call(this, ...params);
                onClick && onClick();
            },
            ...(!enabled && {
                iconStyle: {
                    color: '#A2A3A4',
                    emphasis: {
                        color: '#A2A3A4',
                    },
                },
            }),
        };

        return icon ? { ...config, icon } : config;
    };

    // @todo: move into echarts-helpers
    getSaveAsCSVConfig = () => {
        const {
            visible,
            filename = () => 'Chart_Data',
            icon,
            title = _('chart_save_as_csv_button_label'),
        } = this.props.saveAsCSV;

        if (!visible) {
            return undefined;
        }

        return {
            icon: icon || csvIcon,
            title,
            onclick: () => {
                const name = filename(this.getRenderProps()) + '.csv';
                const data = new Blob([this.getCSVData()], {
                    type: 'text/plain',
                });
                FileSaver.saveAs(data, name);
            },
        };
    };

    getCSVData = () => {
        const { columns, records, saveAsCSV } = this.props;

        const columnHeaders = saveAsCSV.headers;
        const columnsKeys = saveAsCSV.keys || [];

        const arrayRecords = records.map((record) => [
            ...columnsKeys.map((key) => formatValue(record[columns.indexOf(key)], key, _)),
        ]);

        return convertArrayToCSV(arrayRecords, { header: columnHeaders });
    };

    getYAxisOptions = ({ data }) => {
        const rowHeight = this.theme.arrow.series.symbolSize[1];
        const max = !data?.length ? rowHeight : data[data.length - 1].value[1] + rowHeight;

        return {
            ...this.theme.arrow.yAxis,
            max,
        };
    };

    getHoveredCategoryIndex = (name) => {
        if (!name) return;
        const { sourceRecords } = this.state;
        const categories = this.getCategories(sourceRecords);
        const categoryIndex = categories.findIndex((category) => category.name === name);
        return categoryIndex;
    };

    handleMouseMove = (params) => {
        if (params.data && !params.data.isDrillable) {
            this.chartInstance.getZr().setCursorStyle('default');
        }
    };

    handleHighlight = ({ name }) => {
        const categoryIndex = this.getHoveredCategoryIndex(name);
        if (!~categoryIndex) return;
        const { selectedCategories } = this.props;
        if (Object.keys(selectedCategories).length && !selectedCategories[name]) return;
        const { data } = this.getSeries();
        const itemsId = data.reduce((acc, current) => {
            if (current.category !== categoryIndex && current.dataKeyValue) {
                acc.push(current.dataKeyValue);
            }
            return acc;
        }, []);
        this.setState({ blurredItemsDataKeyValues: itemsId });
    };

    handleDownplay = ({ name }) => {
        const categoryIndex = this.getHoveredCategoryIndex(name);
        if (!~categoryIndex) return;
        this.setState({ blurredItemsDataKeyValues: [] });
    };

    handleClick = (params) => {
        const { componentType, componentSubType, dataType, seriesType, data } = params;

        if (
            componentType === 'series' &&
            seriesType === 'graph' &&
            componentSubType === 'graph' &&
            dataType === 'node' &&
            data?.isDrillable
        ) {
            this.props.onNodeClick?.(params);
        }
    };

    getEchartsOptions = () => {
        const { data, links, categories } = this.getSeries();
        const allOptions = {
            ...this.theme.arrow,
            legend: this.getLegendOption(),
            yAxis: this.getYAxisOptions({ data }),
            grid: this.getGridPositions(),
            title: this.getTitlesPositions(),
            tooltip: this.getTooltipOption(),
            toolbox: this.getToolboxOption(),
            graphic: {
                elements: [this.generateBreadcrumb()],
            },
            series: [
                {
                    ...this.theme.arrow.series,
                    data,
                    links,
                    categories,
                },
            ],
        };
        return allOptions;
    };

    isBreadcrumbShown = (): boolean => this.props.isDrillable;

    generateBreadcrumb = (): object | null => {
        if (!this.isBreadcrumbShown()) return null;

        const { drillPath } = this.props;
        const pathInfo = (drillPath || []).map((condition) => ({
            condition,
            name: !!condition?.level ? `Level ${condition.level}` : localizeCondition(condition),
        }));

        const nameWidths = pathInfo.map(({ name }): number => {
            const canvasElement = document.getElementById('canvasFontSizer');
            if (!canvasElement) {
                return 150;
            }
            const context = canvasElement.getContext('2d');
            return Math.ceil(getCanvasTextWidth(name, context, this.props.theme.textStyle));
        });

        const textPadding = 8;
        const chevronWidth = 8;
        const gap = 5;
        let xOffset = gap;
        return {
            type: 'group',
            z: 1,
            left: 8,
            top: this.getBreadcrumbTop(),
            children: pathInfo.map(({ condition, name }, idx) => {
                const isRoot = idx === 0;
                const isLast = idx === pathInfo.length - 1;
                const nameWidth = nameWidths[idx];
                const backgroundWidth = nameWidth + 2 * textPadding + (isRoot ? 0 : chevronWidth);
                const breadcrumbGraphicElement = {
                    type: 'group',
                    left: xOffset,
                    top: 4,
                    children: [
                        {
                            type: 'polygon',
                            style: {
                                fill: isRoot ? '#DFE0E0' : colorPalette[idx],
                            },
                            shape: {
                                points: [
                                    [0, 0],
                                    [backgroundWidth, 0],
                                    [backgroundWidth + chevronWidth, 12],
                                    [backgroundWidth, 24],
                                    [0, 24],
                                    ...(isRoot
                                        ? [[0, 0]]
                                        : [
                                              [chevronWidth, 12],
                                              [0, 0],
                                          ]),
                                ],
                            },
                        },
                        {
                            type: 'text',
                            left: isRoot ? textPadding : chevronWidth + textPadding,
                            top: 6,
                            style: {
                                ...this.props.theme.textStyle,
                                text: name,
                                fill: isRoot ? '#000' : '#fff',
                            },
                        },
                    ],
                    onclick: () => {
                        !isLast && this.props.onBreadcrumbClick?.(condition);
                    },
                };

                xOffset += backgroundWidth + gap;

                return breadcrumbGraphicElement;
            }),
        };
    };

    render() {
        const { forwardedRef, isLoadingData } = this.props;
        const { legendHeight } = this.state;
        const options = this.getEchartsOptions();
        const containerStyle = {
            height: this.getGridBottom() + legendHeight,
        };
        const onEvents = {
            ...this.props.onEvents,
            mousemove: this.handleMouseMove,
            highlight: this.handleHighlight,
            downplay: this.handleDownplay,
            click: this.handleClick,
        };

        return (
            // chart-wrapper is required for resize in PDF export
            <div id="chart-wrapper">
                <EChartsWrapper
                    ref={forwardedRef}
                    option={options}
                    theme={this.theme}
                    style={containerStyle}
                    showLoading={isLoadingData}
                    loadingOption={this.theme.loading}
                    onEvents={onEvents}
                    onChartResize={this.onChartResize}
                    onChartReady={this.onChartReady}
                />
            </div>
        );
    }
}

export default React.forwardRef((props, ref) => <ArrowChart {...props} ref={ref} />);
