import { RootState } from 'MyTypes';

import { DataCollectionResource, ResourceList } from '@portal/common/types';

import { getSelectedDataTool } from '../root-reducer';

export const getDashboardTabs = (state: RootState): ResourceList[] =>
    state.userSettings.dashboardTabs;

export const getSelectedDataCollectionForDataTool = (
    state: RootState
): undefined | DataCollectionResource => {
    const {
        dataExplorer: { dataCollections },
        userSettings: { selectedDataCollectionIdByDataTool },
    } = state;

    const dataTool = getSelectedDataTool(state);

    let dataCollection: DataCollectionResource | undefined;
    if (
        dataCollections &&
        selectedDataCollectionIdByDataTool &&
        dataTool &&
        selectedDataCollectionIdByDataTool[dataTool]
    ) {
        const dataCollectionId = selectedDataCollectionIdByDataTool[dataTool];
        dataCollection = dataCollections.find(({ id }) => id === dataCollectionId);
    }

    return dataCollection;
};

export const getIsSidebarMinimized = (state: RootState): boolean =>
    state.userSettings.isSidebarMinimized;

export const getSelectedDataCollectionIdByDataTools = (
    state: RootState
): null | Record<string, number> => state.userSettings.selectedDataCollectionIdByDataTool;
