import React from 'react';

import { Image } from '@ihme/common/web/components';
import { withGoogleAnalytics } from '@ihme/common/packages/google-analytics';

import {
    H2,
    PageBody,
    PageContainer,
    PageHeader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
    PageBodyFooter,
    FlexColumn,
} from '@portal/common/components';

import _ from '../../locale';
import locale from './locale';

class SourcesScene extends React.PureComponent {
    handleItemClick = (item) => window.open(item.url);

    render() {
        return (
            <PageContainer>
                <PageHeader>
                    <H2>{locale.header}</H2>
                </PageHeader>
                <PageBody>
                    <FlexColumn grow={1}>
                        <Table stripes>
                            <TableHead>
                                <TableHeaderRow>
                                    <TableHeaderCell />
                                    <TableHeaderCell>{locale.tableCol1}</TableHeaderCell>
                                    <TableHeaderCell>{locale.tableCol2}</TableHeaderCell>
                                </TableHeaderRow>
                            </TableHead>
                            <TableBody>
                                {locale.tableContent.map((item, index) => (
                                    <TableRow
                                        key={index}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.handleItemClick(item)}
                                    >
                                        <TableCell>
                                            <Image src={item.img} width={140} />
                                        </TableCell>
                                        <TableCell>{item.title}</TableCell>
                                        <TableCell>{item.desc}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </FlexColumn>
                    <PageBodyFooter>{_.get('footer_note')}</PageBodyFooter>
                </PageBody>
            </PageContainer>
        );
    }
}

export default withGoogleAnalytics({
    trackMethods: (props) => ({
        handleItemClick: (item) =>
            props.trackEvent({
                category: 'Content',
                action: 'Open Data Sources Link',
                label: item.title,
            }),
    }),
})(SourcesScene);
