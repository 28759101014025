import cuid from 'cuid';
import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import uniqBy from 'lodash/uniqBy';

import Loading from '@ihme/common/components/Loading';
import {
    FlexColumn,
    FlexRow,
    H3,
    ListItem,
    ListItemBottom,
    PageBody,
    PageBodyFooter,
    PageContainer,
    Popover,
    SvgIcon,
    TabsContainer,
    TabsMenu,
    TabsMenuItem,
    TabsPanel,
    PageTile,
    PageTileBody,
    PageTileHeader,
} from '@portal/common/components';
import { DataTableQueryParams, ResourceList } from '@portal/common/types';
import { styled } from '@portal/common/theme';

import api from '../../api';
import FavoriteResourceList from '../../components/FavoriteResourceList';
import _ from '../../locale';
import { GROUP_CONTENT_ID, MY_FAVORITES_ID, MY_FAVORITIES_PATH } from '../../router/paths';
import { MY_FAVORITES_LIST } from '../../store/resource-lists/models';
import {
    getCustomLists,
    getDefaultList,
    getPublicLists,
} from '../../store/resource-lists/selectors';
import { getOrganization, getOrganizationId, getSessionAccountId } from '../../store/root-reducer';
import {
    addDashboardTab,
    removeDashboardTab,
    updateDashboardTab,
} from '../../store/user-settings/actions';
import { getDashboardTabs } from '../../store/user-settings/selectors';
import DataExplorerWidget from './DataExplorerWidget';
import News from './News';

import WelcomeTile from './WelcomeTile';
import LicenseWarningTile from './LicenseWarningTile';

const DropdownIcon = (props) => (
    <SvgIcon
        alt="Dropdown Menu"
        src="/icons/ic-arrow-down.svg"
        style={{ marginLeft: 8, transform: 'scale(1.3)', width: 10 }}
        {...props}
    />
);

const DeleteIcon = (props) => (
    <SvgIcon
        id={'delete-icon'}
        alt="Delete Icon"
        src={'/icons/ic-cross.svg'}
        css={{ marginLeft: 8 }}
        {...props}
    />
);

const RightArrowIcon = (props) => (
    <SvgIcon
        src="/icons/ic-arrow.svg"
        size="medium"
        color={(theme) => theme.color.gray40}
        style={{ transform: 'scaleX(-1)' }}
    />
);

const StyledTabsMenuItem = styled(TabsMenuItem)({});

const Container = styled(FlexRow)(({ theme }) => ({
    flexGrow: 1,
    flexWrap: 'wrap',

    [theme.breakpoint.md]: {
        flexWrap: 'unset',
    },
}));

const Column1 = styled(FlexColumn)(({ theme }) => ({
    marginTop: -15,
    minWidth: 550,
    flexBasis: 550,
    flexGrow: 1,
    flexShrink: 1,
    '> :not(:last-child)': {
        marginBottom: 15,
    },

    [theme.breakpoint.md]: {
        marginTop: 0,
        '> :not(:last-child)': {
            marginBottom: 30,
        },
    },
}));

const Column2 = styled(FlexColumn)(({ theme }) => ({
    marginTop: 15,
    marginLeft: 0,
    minWidth: 250,
    maxWidth: '100%',
    flexBasis: '340px',
    flexShrink: 0,
    '> :not(:last-child)': {
        marginBottom: 15,
    },

    [theme.breakpoint.md]: {
        marginTop: 0,
        marginLeft: 30,
        maxWidth: 550,
        '> :not(:last-child)': {
            marginBottom: 30,
        },
    },
}));

const DEFAULT_QUERY_PARAMS = {
    page: '1',
    per_page: '5',
    order_by: 'created',
    order_direction: 'DESC',
    is_admin_created: 0,
};

const mapStateToProps = (state: RootState) => ({
    memberId: getSessionAccountId(state),
    organizationId: getOrganizationId(state),
    organization: getOrganization(state),
    customLists: getCustomLists(state), // @todo Rename to myLists (lists created by me + subscribed lists)
    publicLists: getPublicLists(state),
    dashboardTabs: getDashboardTabs(state),
    defaultList: getDefaultList(state),
    isWelcomeMessageDismissed: state.userSettings.dismissedWelcomeMessageHash,
});

const dispatchProps = {
    addDashboardTab: addDashboardTab,
    updateDashboardTab: updateDashboardTab,
    removeDashboardTab: removeDashboardTab,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

type State = {
    selectedTabIndex: number;
    queryParams: DataTableQueryParams;
};

class HomeScene extends React.Component<Props, State> {
    getInitialQueryParams = () => ({
        page: '1',
    });

    state: State = {
        selectedTabIndex: 0,
        queryParams: this.getInitialQueryParams(),
    };

    getApiParams = (params?: any) => ({
        organizationId: this.props.organizationId,
        memberId: this.props.memberId,
        ...DEFAULT_QUERY_PARAMS,
        ...params,
    });

    loadResources = (listId) => {
        let loadItems: any = null;

        if (listId === MY_FAVORITES_ID) {
            loadItems = (params) =>
                api.organizationMemberFavoriteResource.getFavoriteResources(
                    this.getApiParams(params)
                );
        } else if (listId === GROUP_CONTENT_ID) {
            loadItems = (params) =>
                api.organizationGroupContent.getLinksAndFiles(this.getApiParams(params));
        } else {
            loadItems = (params) =>
                api.organizationResourceListResource.getResourceListResources(
                    this.getApiParams({ ...params, listId: listId })
                );
        }

        return loadItems;
    };

    renderDropdownWithLists = ({ createOnClick, tabListId }) => {
        const { customLists, defaultList } = this.props;

        // defaultList can be present in myLists (customLists)
        const lists = uniqBy([MY_FAVORITES_LIST, defaultList, ...(customLists || [])], 'id');

        return lists
            .map((list) => (
                <ListItem
                    key={list.id}
                    onClick={createOnClick(list)}
                    isSelected={list.id === tabListId}
                >
                    {list.name}
                </ListItem>
            ))
            .concat(
                <LinkContainer key={'manage-lists'} to={MY_FAVORITIES_PATH}>
                    <ListItemBottom customIcon={<RightArrowIcon />}>
                        {_.get('lists_manage_lists')}
                    </ListItemBottom>
                </LinkContainer>
            );
    };

    getQueryParams = () => ({
        ...this.state.queryParams,
        per_page: this.props.isWelcomeMessageDismissed ? '10' : '5',
    });

    setQueryParams = (queryParams) => {
        if (queryParams != this.state.queryParams) {
            this.setState({ queryParams });
        }
    };

    resetPagination = () => {
        this.setState((state) => ({ queryParams: this.getInitialQueryParams() }));
    };

    handleTabChange = (selectedTabIndex) => {
        this.resetPagination();
        this.setState({ selectedTabIndex });
    };

    renderTabbedView = () => {
        const {
            addDashboardTab,
            dashboardTabs,
            removeDashboardTab,
            updateDashboardTab,
            publicLists,
        } = this.props;

        if (publicLists == null) {
            return <Loading />;
        }

        const { selectedTabIndex } = this.state;

        return (
            <>
                <TabsContainer selectedIndex={selectedTabIndex} onSelect={this.handleTabChange}>
                    <TabsMenu>
                        {dashboardTabs.map((tabList, tabIndex) => (
                            <TabsMenuItem key={tabIndex} tabIndex="true">
                                <Popover
                                    renderContent={({ toggleVisibility }) => {
                                        return this.renderDropdownWithLists({
                                            tabListId: tabList.id,
                                            createOnClick: (list: ResourceList) => {
                                                return (ev) => {
                                                    updateDashboardTab({
                                                        index: tabIndex,
                                                        list: { ...list, tabId: cuid() },
                                                    });
                                                    toggleVisibility();
                                                };
                                            },
                                        });
                                    }}
                                >
                                    {({ ref, toggleVisibility }) => (
                                        <div
                                            ref={ref}
                                            css={{
                                                '#delete-icon': { visibility: 'hidden' },
                                                ':hover #delete-icon': {
                                                    visibility: 'visible',
                                                },
                                            }}
                                        >
                                            {tabList.name}
                                            {tabIndex === selectedTabIndex ? (
                                                <DropdownIcon onClick={toggleVisibility} />
                                            ) : (
                                                <DeleteIcon
                                                    onClick={(ev) => {
                                                        ev.stopPropagation();
                                                        removeDashboardTab(tabIndex);
                                                        if (tabIndex < selectedTabIndex) {
                                                            this.setState({
                                                                selectedTabIndex:
                                                                    selectedTabIndex - 1,
                                                            });
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </Popover>
                            </TabsMenuItem>
                        ))}

                        <StyledTabsMenuItem>
                            <Popover
                                renderContent={({ toggleVisibility }) => {
                                    return this.renderDropdownWithLists({
                                        tabListId: null,
                                        createOnClick: (list: ResourceList) => {
                                            return (ev) => {
                                                addDashboardTab({ ...list, tabId: cuid() });
                                                toggleVisibility();
                                            };
                                        },
                                    });
                                }}
                            >
                                {({ ref, toggleVisibility }) => (
                                    <div ref={ref}>
                                        <div
                                            onClick={(ev) => {
                                                ev.stopPropagation();
                                                toggleVisibility();
                                            }}
                                        >
                                            {_.get('lists_add_new_tab')}
                                        </div>
                                    </div>
                                )}
                            </Popover>
                        </StyledTabsMenuItem>
                    </TabsMenu>

                    <FlexColumn grow={1}>
                        {dashboardTabs.map((tabList, tabIndex) => (
                            <TabsPanel key={tabList.tabId}>
                                <FavoriteResourceList
                                    key={tabList.tabId}
                                    disableSorting
                                    queryParams={this.getQueryParams()}
                                    setQueryParams={this.setQueryParams}
                                    dataTableStyle={{ tableLayout: 'fixed' }}
                                    loadItems={this.loadResources(tabList.id)}
                                />
                            </TabsPanel>
                        ))}
                    </FlexColumn>
                </TabsContainer>
            </>
        );
    };

    render() {
        return (
            <PageContainer>
                <PageBody transparentSurface noHeader>
                    <Container>
                        <Column1>
                            <LicenseWarningTile />
                            <WelcomeTile />
                            <PageTile style={{ padding: 30 }}>{this.renderTabbedView()}</PageTile>
                        </Column1>
                        <Column2>
                            <PageTile>
                                <PageTileBody>
                                    <PageTileHeader>
                                        {/* @todo: localize */}
                                        <H3>Explore Data</H3>
                                    </PageTileHeader>
                                    <DataExplorerWidget />
                                </PageTileBody>
                            </PageTile>
                            <PageTile>
                                <PageTileBody>
                                    <PageTileHeader>
                                        {/* @todo: localize */}
                                        <H3>News and Announcements</H3>
                                    </PageTileHeader>
                                    <News />
                                </PageTileBody>
                            </PageTile>
                        </Column2>
                    </Container>
                    <PageBodyFooter>{_.get('footer_note')}</PageBodyFooter>
                </PageBody>
            </PageContainer>
        );
    }
}

export default connect(mapStateToProps, dispatchProps)(HomeScene);
