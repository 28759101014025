import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'MyTypes';
import { isEmpty } from 'lodash/fp';

import { styled } from '@portal/common/theme';
import { ButtonDropdown, FlexColumn, H3, SectionHeader } from '@portal/common/components';
import { localizeCondition } from '@portal/common/utility/chart-data-helpers';
import { RESPONSE_ID_KEY } from '@portal/common/models/data-key';
import { DataCondition } from '@portal/common/types';
import { EDPTimelineIndicators } from '@portal/common/models/data-type';

import {
    getAvailableToSelectConditions,
    getSelectedConditions,
    getSelectedRefinementFilters,
} from '../../store/data-explorer/selectors';
import { getSelectedDataCollectionForDataTool } from '../../store/user-settings/selectors';
import { changeConditions } from '../../store/data-explorer/actions';
import { localizeDataCondition } from '../../models/data-condition';
import _ from '../../locale';
import RefinementFilter from '../../components/DataExplorer/RefinementFilter';

const StyledButtonContainer = styled.div(({ theme }) => ({
    width: 290,
    marginTop: 10,
}));

const mapStateToProps = (state: RootState) => ({
    selectedRefinementFilters: getSelectedRefinementFilters(state),
    selectedConditions: getSelectedConditions(state),
    availableConditions: getAvailableToSelectConditions(state),
    selectedDataCollection: getSelectedDataCollectionForDataTool(state),
});

const dispatchProps = {
    changeConditions,
};

type EDPConditionSelectorProps = ReturnType<typeof mapStateToProps> & typeof dispatchProps & {};

const INDICATOR_RESPONSES_MAP = {
    // Onetime data
    [1]: [100, 101, 1099], // High risk
    [2]: [200, 201, 2099], // Current smoker
    [3]: [300, 301, 3099], // Chronic condition
    [4]: [400, 401, 4099], // Senior citizen (age 65+)
    [5]: [500, 501, 502, 5099], // Flu vaccine
    [6]: [600, 601, 602, 603, 604, 605, 6099], // Covid vaccine
    [7]: [700, 701, 7088, 7099], // Safe behaviors
    [8]: [800, 801, 8088, 8099], // Stayed home
    [9]: [900, 901, 902, 903, 904, 9088, 9099], // Face mask
    [10]: [1000, 1001, 1002, 1003, 1004, 10088, 10099], // Wash hands
    [11]: [1100, 1101, 1102, 1103, 1104, 11088, 11099], // Clean space

    // Timeline data
    [12]: [1201, 1202, 1203, 12099], // Daily Temperature
    [13]: [1300, 1301, 1302, 13099], // Daily Reported Symptoms
    [14]: [1401, 1402, 14099], // Daily Blood Oxygen Saturation
};

export function EDPConditionSelector(props: EDPConditionSelectorProps) {
    const {
        selectedDataCollection,
        selectedConditions,
        availableConditions,
        selectedRefinementFilters,
        changeConditions,
    } = props;

    useEffect(() => {
        if (
            availableConditions &&
            availableConditions.length &&
            !(selectedConditions && selectedConditions.length)
        ) {
            changeConditions(
                availableConditions
                    .filter(({ data_type }) => data_type === EDPTimelineIndicators)
                    .slice(0, 1)
            );
        }
    }, [
        (availableConditions || [])
            .map(({ data_type, primary_entity_id }) => data_type + '::' + primary_entity_id)
            .join('_'),
    ]);

    if (!selectedDataCollection) {
        return null;
    }

    const handleConditionsChange = (conditions: DataCondition[]) => {
        changeConditions(conditions);
    };

    const handleConditionRemove = (condition: DataCondition) => {
        const conditions = selectedConditions.filter(
            (o: DataCondition) =>
                !(
                    o.data_type === condition.data_type &&
                    o.primary_entity_id === condition.primary_entity_id
                )
        );
        handleConditionsChange(conditions);
    };

    const handleIndicatorChange = (ev) => {
        handleConditionsChange([...selectedConditions, ev.target.value]);
    };

    return (
        <div>
            <SectionHeader>
                <H3>{_('edp_data_select_indicators')}</H3>
            </SectionHeader>
            <FlexColumn>
                {/* @todo: extract component */}
                {selectedConditions.map((condition) => (
                    <RefinementFilter
                        key={condition.primary_entity_id}
                        label={localizeCondition(condition)}
                        hideSelectAll
                        hideDelete={selectedConditions.length === 1}
                        refinementFiltersConfig={{
                            [RESPONSE_ID_KEY]: {
                                isMulti: true,
                                isRevertToDefaultDisabled: true,
                                placeholder: `Select ${localizeCondition(
                                    condition
                                ).toLowerCase()} response...`,
                            },
                        }}
                        granularityKey={RESPONSE_ID_KEY}
                        availableRefinementFilters={{
                            [RESPONSE_ID_KEY]: INDICATOR_RESPONSES_MAP[condition.primary_entity_id],
                        }}
                        preprocessOptions={(options) =>
                            options.filter((o) =>
                                INDICATOR_RESPONSES_MAP[condition.primary_entity_id].includes(o)
                            )
                        }
                        selectedValue={selectedRefinementFilters?.[RESPONSE_ID_KEY]}
                        onRemove={(granularityKey) => handleConditionRemove(condition)}
                    />
                ))}
                {!isEmpty(availableConditions) && (
                    <StyledButtonContainer>
                        <ButtonDropdown
                            options={availableConditions}
                            optionLabelGetter={localizeDataCondition}
                            label={_('edp_indicator_button_label')}
                            onChange={handleIndicatorChange}
                            isSecondary
                        />
                    </StyledButtonContainer>
                )}
            </FlexColumn>
        </div>
    );
}

export default compose(connect(mapStateToProps, dispatchProps))(EDPConditionSelector);
