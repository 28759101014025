import React from 'react';
import { connect } from 'react-redux';
import { isNumber } from 'lodash';
import { isEmpty, sortBy } from 'lodash/fp';

import {
    DataCollectionResource,
    DataCondition,
    DataGranularity,
    DataValue,
} from '@portal/common/types';
import { MultipleMyelomaSurvivalResults } from '@portal/common/models/data-type';
import {
    AGE_GROUP_ID_KEY,
    CAUSE_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    LOCATION_ID_KEY,
    MEASURE_ID_KEY,
    MULTIPLE_MYELOMA_TREATMENT_ID_KEY,
    ROUND_ID_KEY,
    VALUE_KEY,
} from '@portal/common/models/data-key';
import { styled } from '@portal/common/theme';
import {
    Loading,
    Table,
    TableBody,
    TableCell as BaseCell,
    TableHead,
    TableHeaderCell,
    TableHeaderRow,
    TableRow as BaseRow,
    Tooltip,
} from '@portal/common/components';
import formatPdfValue from '@portal/common/utility/formatting/formatPdfValue';

import useGetApiData from '../hooks/use-get-api-data';
import useGetGranularity from '../hooks/use-get-granularity';
import { getOrganizationId } from '../../../store/root-reducer';
import { getAgeGroupsOrder } from '../../../store/data-explorer/selectors';

import _ from '../../../locale';
import config from '../../../config';

const TableRow = styled(BaseRow)(({ theme }) => ({
    textAlign: 'center',
    padding: 0,
    height: 50,
}));

const TableCell = styled(BaseCell)(({ theme }) => ({
    textAlign: 'center',
    padding: '0 10px',
}));

const mapStateToProps = (state) => ({
    organizationId: getOrganizationId(state),
    ageGroupsOrder: getAgeGroupsOrder(state),
});

type Props = ReturnType<typeof mapStateToProps> & {
    locationId: number;
    dataCollection: DataCollectionResource;
    causeId: string | number;
    granularity: DataGranularity;
};

const DataRow = ({
    measureIds,
    forecastScenarioIds,
    getValue,
    ageGroupId,
    treatmentId,
    header,
}) => (
    <TableRow>
        <TableHeaderCell>{header}</TableHeaderCell>
        {measureIds.map((measureId) =>
            forecastScenarioIds.map((id) => {
                const value = getValue(measureId, ageGroupId, treatmentId, id);

                if (!isNumber(value)) {
                    return <TableCell key={`${measureId} ${id}`}>{value}</TableCell>;
                }

                return (
                    <TableCell key={id}>
                        <Tooltip title={value} disableFocusListener>
                            {value === 0 ? 'NE' : formatPdfValue(value)}
                        </Tooltip>
                    </TableCell>
                );
            })
        )}
    </TableRow>
);

const SurvivalResultDataTable = ({
    locationId,
    organizationId,
    dataCollection,
    ageGroupsOrder,
    causeId,
    granularity,
}: Props) => {
    const dataType = MultipleMyelomaSurvivalResults;
    const primaryEntityId = causeId;
    const roundId = granularity[ROUND_ID_KEY]?.[0];

    const condition = {
        data_type: dataType,
        primary_entity_id: primaryEntityId,
    } as DataCondition;

    const { isLoading, dataResponse } = useGetApiData({
        conditions: [condition],
        filters: {
            [ROUND_ID_KEY]: [roundId],
            [LOCATION_ID_KEY]: [locationId],
            [CAUSE_ID_KEY]: [primaryEntityId],
        },
        mergeResponses: false,
    });
    const {
        isLoading: isGranularityLoading,
        granularity: refinedGranularity,
        error: granularityError,
    } = useGetGranularity({
        organizationId,
        resourceId: dataCollection.id,
        dataType,
        roundId,
        primaryEntityId,
    });

    if (
        !(dataResponse && granularity && refinedGranularity && !isLoading && !isGranularityLoading)
    ) {
        return <Loading />;
    }

    let measureIds: DataValue = [];
    let forecastScenarioIds: DataValue = [];
    let ageGroupIds: DataValue = [];
    let treatmentIds: DataValue = [];

    if (refinedGranularity) {
        measureIds = refinedGranularity.measure_id || [];
        forecastScenarioIds = refinedGranularity.forecast_scenario_id || [];
        forecastScenarioIds = refinedGranularity.forecast_scenario_id || [];
        treatmentIds = refinedGranularity.multiple_myeloma_treatment_id || [];
        ageGroupIds = sortBy(
            (id) => (id === config.thirtyAndAboveAgeGroupId ? '0' : ageGroupsOrder?.[id]),
            refinedGranularity.age_group_id || []
        );
    }

    const getValue = (
        measureId: number,
        ageGroupId: number,
        treatmentId: number,
        forecastScenarioId: number
    ): number | string => {
        let value = null;
        if (dataResponse) {
            const { columns, records } = dataResponse;
            const ageGroupIdx = columns.indexOf(AGE_GROUP_ID_KEY);
            const measureIdx = columns.indexOf(MEASURE_ID_KEY);
            const scenarioIdx = columns.indexOf(FORECAST_SCENARIO_ID_KEY);
            const treatmentIdx = columns.indexOf(MULTIPLE_MYELOMA_TREATMENT_ID_KEY);
            const valueIdx = columns.indexOf(VALUE_KEY);

            const valueRecord = records.find(
                (record) =>
                    record[measureIdx] === measureId &&
                    record[ageGroupIdx] === ageGroupId &&
                    record[scenarioIdx] === forecastScenarioId &&
                    record[treatmentIdx] === treatmentId
            );

            if (!isEmpty(valueRecord)) {
                value = valueRecord[valueIdx];
            }
        }

        return value == null ? '-' : value;
    };

    return (
        <Table>
            <TableHead>
                <TableHeaderRow>
                    <TableHeaderCell>{_('filter_label_measure_id')}</TableHeaderCell>
                    {measureIds.map((id) => (
                        <TableCell key={`measure_${id}`} colSpan={3}>
                            {_(`measure_${id}`)}
                        </TableCell>
                    ))}
                </TableHeaderRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableHeaderCell>{_('filter_scenario_label')}</TableHeaderCell>
                    {measureIds.map((measureId) =>
                        forecastScenarioIds.map((id) => (
                            <TableCell key={`forecast_scenario_${measureId} ${id}`}>
                                {_(`forecast_scenario_${id}`)}
                            </TableCell>
                        ))
                    )}
                </TableRow>
                {ageGroupIds.map((ageGroupId) => (
                    <DataRow
                        key={`${config.anyMultipleMyelomaTreatmentId}_${ageGroupId}`}
                        measureIds={measureIds}
                        forecastScenarioIds={forecastScenarioIds}
                        getValue={getValue}
                        ageGroupId={ageGroupId}
                        treatmentId={config.anyMultipleMyelomaTreatmentId}
                        header={
                            ageGroupId === config.thirtyAndAboveAgeGroupId
                                ? _('total')
                                : _(`age_group_${ageGroupId}`)
                        }
                    />
                ))}

                {treatmentIds
                    .filter((id) => id !== config.anyMultipleMyelomaTreatmentId)
                    .map((treatmentId) => (
                        <DataRow
                            key={`${treatmentId}_${config.thirtyAndAboveAgeGroupId}`}
                            measureIds={measureIds}
                            forecastScenarioIds={forecastScenarioIds}
                            getValue={getValue}
                            ageGroupId={config.thirtyAndAboveAgeGroupId}
                            treatmentId={treatmentId}
                            header={_(`multiple_myeloma_treatment_${treatmentId}`)}
                        />
                    ))}
            </TableBody>
        </Table>
    );
};

export default connect(mapStateToProps, null)(SurvivalResultDataTable);
