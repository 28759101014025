import { flatten, isEqual, omit } from 'lodash/fp';
import { RootEpic } from 'MyTypes';
import { concat, empty, of } from 'rxjs';
import { bufferCount, filter, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { getType, isActionOf } from 'typesafe-actions';
import { intersection, isEmpty } from 'lodash/fp';

import {
    AGE_GROUP_ID_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    RISK_EXPOSURE_ID_KEY,
    ROUND_ID_KEY,
} from '@portal/common/models/data-key';
import { getConditions } from '@portal/common/models/dataset';
import { AllDataTypes, RiskExposureRecords } from '@portal/common/models/data-type';
import { DataCondition, DataFilters, DataKey } from '@portal/common/types';
import { hasErrorInRefinementFilters } from '@portal/common/utility/filters-helpers';
import { isSingleYearAgeEstimatesExportEnabled as checkIfSingleYearAgeEstimatesExportEnabled } from '@portal/common/models/organization';

import {
    getAllowedChartRefinementFilters,
    isPartOfSelectedRefinementFilters,
} from '../../components/DataExplorer/ChartSection/utils';
import { intersectRefinementFiltersWithGranularity } from '../../components/DataExplorer/utils';
import config from '../../config';
import {
    MORBIDITY_EXPLORER_PATH,
    COVID_19_EXPLORER_PATH,
    DATA_EXPLORER_PATH,
} from '../../router/paths';
import {
    getDataCollectionDataTypes,
    getMergedDataCollectionGranularity,
} from '../../utility/data-loader';
import { getOrganization, getSelectedDataTool } from '../root-reducer';
import { getSelectedDataCollectionForDataTool } from '../user-settings/selectors';
import {
    changeChartFilters,
    changeConditions,
    changeDataTypeSelection,
    changeDataTypeSelectionForSingleConditionTool,
    changeSelectedDataType,
    changeSelectedRefinementFilters,
    confirmSelectionCountModal,
    dataSelectionValidation,
    fixMissingLoadedRefinementFilters,
    getRefinedGranularityAsync,
    getSingleYearAgeGranularitiesAsync,
    initializeDataTypeSelection,
    initializeDefaultRefinementFilters,
    openSelectionCountModal,
    setCombinedFiltersAmount,
    validateChartFilters,
    validateSelectedConditionsComplexityLimit,
    validateSelectedRefinementFiltersComplexityLimit,
} from './actions';
import {
    getChartFiltersToOmit,
    getChartSelectedRefinementFilters,
    getDefaultFiltersByConditions,
    getNormalizedSelectedRefinementFilters,
    getSelectedChartType,
    getSelectedConditions,
    getSelectedConditionsDataTypes,
    getSelectedConditionsDataTypesGranularity,
    getSelectedConditionsRefinedGranularity,
    getSelectedDataType,
    getSelectedRefinementFilters,
    getSelectedRefinementFiltersWithFallbackToDefaultFilters,
} from './selectors';
import { changeDataCollection } from '../user-settings/actions';
import { LimitModal } from '../../models/data-tool';
import {
    calculateCombinedFiltersAmount,
    getFiltersSelectionCount,
    getTimeUnitsCount,
} from '../../utility/data/calculate-combined-filters-amount';

const NON_PRESERVABLE_REFINEMENT_FILTERS_KEYS_BY_DATA_TOOL = {
    [DATA_EXPLORER_PATH]: [],
    [COVID_19_EXPLORER_PATH]: [],
    [MORBIDITY_EXPLORER_PATH]: [AGE_GROUP_ID_KEY],
};

export const validateSelectedChartFilters: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([dataSelectionValidation.success, changeChartFilters])),
        switchMap((action) => {
            const state = state$.value;

            const refinementFilters = getNormalizedSelectedRefinementFilters(state);

            if (!refinementFilters) {
                return empty();
            }

            const dataTool = getSelectedDataTool(state);
            const chartType = getSelectedChartType(state);
            const chartFilters = getChartSelectedRefinementFilters(state) || {};

            const filtersToOmit = getChartFiltersToOmit(state);

            const allowedFilters = getAllowedChartRefinementFilters(chartType, dataTool).filter(
                (key) => !filtersToOmit.includes(key)
            );
            const updatedChartFilters = { ...chartFilters };

            const isMultiple = (value) => value && value.length > 1;

            Object.entries(refinementFilters).forEach(([key, value]) => {
                if (allowedFilters.includes(key) && isMultiple(value)) {
                    if (
                        chartFilters.hasOwnProperty(key) &&
                        isPartOfSelectedRefinementFilters(key, value, refinementFilters)
                    ) {
                        updatedChartFilters[key] = chartFilters[key];
                    } else {
                        updatedChartFilters[key] = [value[0]];
                    }
                }
            });

            Object.entries(chartFilters).forEach(([key, value]) => {
                if (
                    refinementFilters.hasOwnProperty(key) &&
                    !isPartOfSelectedRefinementFilters(key, value, refinementFilters)
                ) {
                    updatedChartFilters[key] = [refinementFilters[key][0]];
                }
            });

            return of(validateChartFilters(updatedChartFilters));
        })
    );

export const updateDataSelectionOnSelectedDataTypeChange: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([changeSelectedDataType])),
        switchMap((action) => {
            const state = state$.value;

            const selectedDataCollection = getSelectedDataCollectionForDataTool(state);
            const selectedRefinementFilters = getSelectedRefinementFilters(state);
            const selectedDataTool = getSelectedDataTool(state);

            if (selectedDataCollection == null) {
                return empty();
            }

            const selectedDataType = getSelectedDataType(state);
            const availableDatasets = selectedDataCollection.datasets.filter(
                (i) => selectedDataType === AllDataTypes || selectedDataType === i.data_type
            );
            const availableConditions = flatten(availableDatasets.map(getConditions));
            const selectedConditions = getSelectedConditions(state);

            // special case in comorbidity to preserve selected conditions
            if (selectedDataTool === MORBIDITY_EXPLORER_PATH) {
                // if no conditions selected do nothing
                if (selectedConditions.length === 0) {
                    return empty();
                }
                // otherwise reselect them from new data type
                // find selected condtions by primary id in dataset conditions
                const newConditions: DataCondition[] = selectedConditions
                    .map(({ primary_entity_id }) =>
                        availableConditions.find(
                            (condition) => condition.primary_entity_id === primary_entity_id
                        )
                    )
                    .filter(Boolean);

                const dataTypeGranularity = getMergedDataCollectionGranularity(
                    selectedDataCollection,
                    [selectedDataType]
                );
                const newRefinementFilters = {
                    ...getDefaultFiltersByConditions(newConditions)(state),
                    ...intersectRefinementFiltersWithGranularity(
                        selectedRefinementFilters,
                        omit(
                            NON_PRESERVABLE_REFINEMENT_FILTERS_KEYS_BY_DATA_TOOL[selectedDataTool],
                            dataTypeGranularity
                        )
                    ),
                };

                return of(
                    changeDataTypeSelection({
                        conditions: newConditions,
                        refinementFilters: newRefinementFilters,
                    })
                );
            }

            // reset selected conditions
            return of(
                changeDataTypeSelection({
                    conditions: [],
                    refinementFilters: null,
                })
            );
        })
    );

export const dataSelectionComplexityValidationFlow: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(
            isActionOf([
                validateSelectedRefinementFiltersComplexityLimit,
                validateSelectedConditionsComplexityLimit,
            ])
        ),
        switchMap((action) => {
            const mapToSuccessAction = (action) => {
                switch (action.type) {
                    case getType(validateSelectedRefinementFiltersComplexityLimit):
                        return changeSelectedRefinementFilters(action.payload);

                    case getType(validateSelectedConditionsComplexityLimit):
                        return changeConditions(action.payload);

                    default:
                        throw Error('Not possible');
                }
            };

            const state = state$.value;
            const selectedConditions = getSelectedConditions(state);
            const selectedRefinementFilters = getSelectedRefinementFilters(state);

            const selectedConditionsGranularity = getSelectedConditionsRefinedGranularity(state);

            const currentFiltersSelection = {
                ...selectedConditionsGranularity,
                ...selectedRefinementFilters,
            };

            const currentSelectionAmount = calculateCombinedFiltersAmount(state);
            const currentSelectionWithoutTimeUnitsAmount = calculateCombinedFiltersAmount(
                state,
                true
            );

            let nextSelectionWithoutTimeUnitsAmount;
            let nextSelectionAmount;
            if (isEmpty(selectedRefinementFilters)) {
                nextSelectionAmount = 1;
                nextSelectionWithoutTimeUnitsAmount = 1;

                return of(
                    setCombinedFiltersAmount(nextSelectionAmount, {
                        currentSelectionAmount,
                        currentSelectionWithoutTimeUnitsAmount,
                        nextSelectionAmount,
                        nextSelectionWithoutTimeUnitsAmount,
                    }),
                    mapToSuccessAction(action)
                );
            }

            if (isActionOf(validateSelectedRefinementFiltersComplexityLimit, action)) {
                const nextSelectedRefinementFilters = {
                    ...currentFiltersSelection,
                    ...action.payload,
                };
                nextSelectionWithoutTimeUnitsAmount =
                    selectedConditions.length *
                    getFiltersSelectionCount(
                        nextSelectedRefinementFilters,
                        selectedConditionsGranularity,
                        true
                    );
                nextSelectionAmount =
                    nextSelectionWithoutTimeUnitsAmount *
                    getTimeUnitsCount(nextSelectedRefinementFilters, selectedConditionsGranularity);
            } else if (isActionOf(validateSelectedConditionsComplexityLimit, action)) {
                const nextSelectedConditionsCount = action.payload.length;

                nextSelectionWithoutTimeUnitsAmount =
                    nextSelectedConditionsCount *
                    getFiltersSelectionCount(
                        currentFiltersSelection,
                        selectedConditionsGranularity,
                        true
                    );

                nextSelectionAmount =
                    nextSelectionWithoutTimeUnitsAmount *
                    getTimeUnitsCount(currentFiltersSelection, selectedConditionsGranularity);
            }

            const openConfirmationModal = (modal: LimitModal) =>
                concat(
                    of(openSelectionCountModal(modal)),
                    action$.pipe(
                        filter(isActionOf([confirmSelectionCountModal])),
                        first(),
                        switchMap((_) => [
                            setCombinedFiltersAmount(nextSelectionAmount, {
                                currentSelectionAmount,
                                currentSelectionWithoutTimeUnitsAmount,
                                nextSelectionAmount,
                                nextSelectionWithoutTimeUnitsAmount,
                            }),
                            mapToSuccessAction(action),
                        ])
                    )
                );

            if (
                currentSelectionAmount < config.combinedFiltersAmountLimit &&
                nextSelectionAmount > config.combinedFiltersAmountLimit
            ) {
                // open modal and wait for confirmation action
                return openConfirmationModal(LimitModal.dataToolLimitExceeded);
            } else if (
                currentSelectionWithoutTimeUnitsAmount <
                    config.combinedFiltersWithoutTimeUnitsAmountChartLimit &&
                nextSelectionWithoutTimeUnitsAmount >
                    config.combinedFiltersWithoutTimeUnitsAmountChartLimit
            ) {
                // open modal and wait for confirmation action
                return openConfirmationModal(LimitModal.chartsLimitExceeded);
            } else if (
                currentSelectionWithoutTimeUnitsAmount <
                    config.combinedFiltersWithoutTimeUnitsAmountWarning &&
                nextSelectionWithoutTimeUnitsAmount >
                    config.combinedFiltersWithoutTimeUnitsAmountWarning
            ) {
                // open modal and wait for confirmation action
                return openConfirmationModal(LimitModal.slowPerformanceWarning);
            }

            return of(
                setCombinedFiltersAmount(nextSelectionAmount, {
                    currentSelectionAmount,
                    currentSelectionWithoutTimeUnitsAmount,
                    nextSelectionAmount,
                    nextSelectionWithoutTimeUnitsAmount,
                }),
                mapToSuccessAction(action)
            );
        })
    );

export const triggerValidationOnDataTypeSelectionInitialization: RootEpic = (
    action$,
    state$,
    { api }
) =>
    action$.pipe(
        filter(isActionOf([initializeDataTypeSelection])),
        switchMap((action) => {
            if (isActionOf(initializeDataTypeSelection)(action)) {
                // during initialization it is possible saved selection has missing some refinement filters
                // we can fix them by merging with default filters

                if (action.payload.conditions.length) {
                    const state = state$.value;
                    const newRefinementFilters = {
                        ...getDefaultFiltersByConditions(action.payload.conditions)(state),
                        ...action.payload.refinementFilters,
                    };

                    return of(fixMissingLoadedRefinementFilters(newRefinementFilters));
                }
            }

            return empty();
        })
    );

export const initializeDefaultRefinementFiltersForSingleConditionTool: RootEpic = (
    action$,
    state$,
    { api }
) =>
    action$.pipe(
        filter(isActionOf([initializeDataTypeSelection, changeSelectedDataType])),
        switchMap((action) => {
            const state = state$.value;
            const selectedDataCollection = getSelectedDataCollectionForDataTool(state);

            // if only one condition available select it immediately
            const selectedDataType = getSelectedDataType(state);
            const availableDatasets = selectedDataCollection.datasets.filter(
                (i) => selectedDataType === AllDataTypes || selectedDataType === i.data_type
            );
            const availableConditions = flatten(availableDatasets.map(getConditions));
            if (availableConditions.length === 1) {
                return of(
                    changeDataTypeSelectionForSingleConditionTool({
                        conditions: availableConditions,
                        refinementFilters: {
                            ...getDefaultFiltersByConditions(availableConditions)(state),
                            ...getSelectedRefinementFilters(state),
                        },
                    })
                );
            }

            return empty();
        })
    );

export const initializeDefaultRefinementFiltersForMultiConditionsTool: RootEpic = (
    action$,
    state$,
    { api }
) =>
    action$.pipe(
        filter(isActionOf([changeConditions])),
        switchMap((action) => {
            const state = state$.value;
            const selectedConditions = getSelectedConditions(state$.value);
            const selectedRefinementFilters = getSelectedRefinementFilters(state$.value);

            // when conditions selected and no refinementFilters they need to be initialized
            // also round_id will always change on refinementFilters initialization
            if (selectedConditions && !selectedRefinementFilters) {
                return of(
                    initializeDefaultRefinementFilters(
                        getDefaultFiltersByConditions(selectedConditions)(state)
                    )
                );
            }

            return empty();
        })
    );

// DataSelection validation flow
// - should be tracking all changes to conditions and refinementFilters by buffering 2 last state changes
// Invariants
// 1. current conditions should not be empty
// 2. current refinementFilters should not be empty
// 3. current conditions or/and refinementFilters are changed
// 4. current refinementFilters should not have null values
export const dataSelectionValidationFlow: RootEpic = (action$, state$, { api }) =>
    concat(of(dataSelectionValidation.request({})), action$).pipe(
        filter(isActionOf([dataSelectionValidation.request])),
        map((action) => {
            return {
                conditions: getSelectedConditions(state$.value),
                refinementFilters: getSelectedRefinementFilters(state$.value),
            };
        }),
        bufferCount(2, 1),
        mergeMap(
            ([
                {
                    conditions: prevSelectedConditions,
                    refinementFilters: prevSelectedRefinementFilters,
                },
                { conditions: selectedConditions, refinementFilters: selectedRefinementFilters },
            ]) => {
                const state = state$.value;

                const selectedDataCollection = getSelectedDataCollectionForDataTool(state);
                if (selectedDataCollection == null) {
                    return of(dataSelectionValidation.failure('no data collection selected'));
                }

                const selectedConditionsGranularity =
                    getSelectedConditionsDataTypesGranularity(state);

                // conditions needs to be selected to pass validation
                if (!selectedConditions || !selectedConditions.length) {
                    return of(
                        dataSelectionValidation.failure(
                            'conditions are empty, many conditions available so waiting for user selection'
                        )
                    );
                }

                // #Piotr: DO NOT REMOVE THIS CODE
                // disabled because switched to use filtersChangedSinceLastRequest state instead
                // but we could use that in the future for other use cases
                // by putting the prev conditions and filters into the redux

                // when conditions or refinement filters haven't changed
                // flow should fail because there was no changes in selection
                // const conditionsChanged = !isEqual(selectedConditions, prevSelectedConditions);
                // const refinementFiltersChanged = !isEqual(
                //     selectedRefinementFilters,
                //     prevSelectedRefinementFilters
                // );
                // if (!conditionsChanged && !refinementFiltersChanged) {
                //     return of(
                //         dataSelectionValidation.failure(
                //             'no changes in both conditions and refinementFilters'
                //         )
                //     );
                // }

                const selectedRefinementFiltersWithFallbackToDefaultFilters =
                    getSelectedRefinementFiltersWithFallbackToDefaultFilters(state);

                // when there is a null filter for selectedConditionsGranularity
                // flow should fail waiting for user to fix selection
                const selectionHasNull = hasErrorInRefinementFilters({
                    granularity: selectedConditionsGranularity,
                    selectedConditions,
                    selectedRefinementFilters:
                        selectedRefinementFiltersWithFallbackToDefaultFilters,
                });

                if (selectionHasNull) {
                    return of(
                        dataSelectionValidation.failure(
                            'one of refinementFilters has null value, user action needed to fix selection'
                        )
                    );
                }

                return of(dataSelectionValidation.success('allowed to fetch data'));
            }
        )
    );

export const triggerLoadRefinedGranularity: RootEpic = (action$, state$, { api }) =>
    concat(of(changeConditions([])), action$).pipe(
        filter(
            isActionOf([
                changeConditions,
                changeSelectedRefinementFilters,
                initializeDataTypeSelection,
                changeDataTypeSelection,
                changeDataTypeSelectionForSingleConditionTool,
            ])
        ),
        map((action) => ({
            conditions: getSelectedConditions(state$.value),
            conditionsDataTypes: getSelectedConditionsDataTypes(state$.value),
            refinementFilters: getSelectedRefinementFilters(state$.value),
        })),
        bufferCount(2, 1),
        mergeMap(
            ([
                {
                    conditions: prevSelectedConditions,
                    conditionsDataTypes: prevSelectedConditionsDataTypes,
                    refinementFilters: prevSelectedRefinementFilters,
                },
                {
                    conditions: selectedConditions,
                    conditionsDataTypes: selectedConditionsDataTypes,
                    refinementFilters: selectedRefinementFilters,
                },
            ]) => {
                if (!selectedConditions && !selectedRefinementFilters) {
                    return empty();
                }

                // when conditions or round_id has changed we are supposed to get refined granularity
                // refined granularity doesn't have any impact on queried data
                // it only marks unavailable filters (queried data is the same with/without them)
                const conditionsChanged = !isEqual(selectedConditions, prevSelectedConditions);
                const roundIdChanged =
                    selectedRefinementFilters &&
                    selectedRefinementFilters[ROUND_ID_KEY] != null &&
                    selectedRefinementFilters[ROUND_ID_KEY] !==
                        (prevSelectedRefinementFilters || {})[ROUND_ID_KEY];

                const newDataTypeIsAdded =
                    (selectedConditionsDataTypes?.length || 0) >
                    (prevSelectedConditionsDataTypes?.length || 0);

                if (conditionsChanged || roundIdChanged) {
                    return of(getRefinedGranularityAsync.request(newDataTypeIsAdded));
                }

                return empty();
            }
        )
    );

export const triggerLoadSingleAgeRefinedGranularity: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getRefinedGranularityAsync.success])),
        isSingleYearAgeEstimationsEnabled(state$),
        switchMap((action) => {
            const refinedGranularity = action.payload;

            /*const state = state$.value;
            const selectedRefinementFilters =
                getSelectedRefinementFiltersWithFallbackToDefaultFilters(state);*/

            const metricIds = refinedGranularity?.[METRIC_ID_KEY] as number[];

            if (isEmpty(metricIds)) {
                return empty();
            }

            return of(getSingleYearAgeGranularitiesAsync.request(metricIds));
        })
    );

const isSingleYearAgeEstimationsEnabled = (state$) =>
    switchMap((action: any) => {
        const state = state$.value;
        const organization = getOrganization(state);
        const isExportEnabled = checkIfSingleYearAgeEstimatesExportEnabled(organization);
        if (!isExportEnabled) {
            return empty();
        }

        const selectedConditionDataTypes = getSelectedConditionsDataTypes(state);
        if (isEmpty(selectedConditionDataTypes)) {
            return empty();
        }

        const hasSupportedSelectedDataTypes = !isEmpty(
            intersection(selectedConditionDataTypes, config.singleYearAgeDataTypes)
        );
        if (!hasSupportedSelectedDataTypes) {
            return empty();
        }

        return of(action);
    });

export const patchRiskExposureRefinedMeasureAndMetricFilters: RootEpic = (
    action$,
    state$,
    { api }
) =>
    action$.pipe(
        filter(isActionOf(getRefinedGranularityAsync.success)),
        switchMap((action) => {
            const state = state$.value;

            const dataTypes = getSelectedConditionsDataTypes(state);

            if (!(dataTypes && dataTypes.includes(RiskExposureRecords))) {
                return empty();
            }

            const selectedRefinementFilters = getSelectedRefinementFilters(state);

            const isValidSelection = (filterKey: DataKey): boolean =>
                selectedRefinementFilters &&
                selectedRefinementFilters[filterKey] &&
                !isEmpty(
                    intersection(selectedRefinementFilters[filterKey], action.payload[filterKey])
                );

            const getInvalidValuesReplacement = (key: DataKey): DataFilters | null =>
                !isValidSelection(key)
                    ? {
                          [key]: action.payload[key],
                      }
                    : null;

            const refinementFilters = {
                ...getInvalidValuesReplacement(MEASURE_ID_KEY),
                ...getInvalidValuesReplacement(METRIC_ID_KEY),
                [RISK_EXPOSURE_ID_KEY]: action.payload[RISK_EXPOSURE_ID_KEY],
            };

            return of(changeSelectedRefinementFilters(refinementFilters));
        })
    );

export const updateDataTypeOnDataCollectionChange: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([changeDataCollection])),
        switchMap((action) => {
            const state = state$.value;

            const selectedDataCollection = getSelectedDataCollectionForDataTool(state);
            if (!selectedDataCollection) {
                return empty();
            }

            const availableDataTypes = getDataCollectionDataTypes(selectedDataCollection);
            if (!(availableDataTypes && availableDataTypes.length)) {
                return empty();
            }

            const dataType = availableDataTypes[0];
            return of(changeSelectedDataType(dataType));
        })
    );
