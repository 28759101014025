import { merge } from 'lodash/fp';

import defaultTheme from '@ihme/common/theme/echarts-theme';

export const textStyle = {
    fontSize: 14,
    fontFamily: 'Avenir, sans-serif',
    fontWeight: 400,
    color: '#333333',
};

export const colorPalette = [
    '#1FBA59',
    //'#FBDD39',

    '#0F316B',
    '#BA53DE',
    '#D81C60',
    '#FF7043',
    //'#FFF98C',
    '#795548',
    '#9575CD',
    '#75B2DA',
    '#00897B',
    //'#CBED8C',

    '#303F9F',
    '#9E579D',
    '#CABBE9',
    '#A22949',
    '#FF9F68',
    //'#F3CF7A',
    '#5D4037',
    '#B9E0F9',
    '#4DB6AC',
    '#93C635',

    '#5C6BC0',
    '#BA68C8',
    '#F090D9',
    '#AB192B',
    '#FB9C39',
    //'#E0C45C',
    '#0288D1',
    '#80DEEA',
    '#19A04B',
    '#35E075',

    '#512DA8',
    '#9C27B0',
    '#FC85AE',
    '#D73146',
    //'#FBC02D',
    '#CB7722',
    '#03A9F4',
    '#00ACC1',
    '#1FBA59',
    '#22EAAA',

    '#7E57C2',
    '#6A1C9A',
    '#EE5085',
    '#E64A19',
    //'#FBDD39',
    '#9F570F',
    '#50C3F7',
    '#278EA5',
    '#A4CC5A',
    //'#A7FF83',
];

const getTreemapLevelLabelStyle = (isRoot?: boolean) => ({
    upperLabel: {
        normal: {
            ...textStyle,
            fontSize: 13,
            color: isRoot ? '#000' : '#fff',
            show: true,
            height: 24,
            position: 'insideLeft',
            padding: [4, 0, 0, 0],
        },
        emphasis: {
            ...textStyle,
            fontSize: 13,
            fontWeight: 500,
            color: isRoot ? '#000' : '#fff',
            show: true,
            height: 24,
            position: 'insideLeft',
            padding: [3, 0, 0, 0],
        },
    },
    label: {
        ...textStyle,
        fontSize: 10,
        color: '#fff',
        position: 'insideLeft',
        padding: [1, 0, 0, 0],
        emphasis: {
            ...textStyle,
            fontSize: 10,
            fontWeight: 500,
            color: '#fff',
            padding: [0, 0, 0, 0],
        },
    },
});

export default merge(defaultTheme, {
    colorPalette,
    map: {
        locationLabelColor: '#333333',
        locationBorderColor: 'white',
        locationInRangeColorSpectrum: ['#085333', '#53C36B', '#F1E577', '#F94F35', '#6D0524'],
        locationOutOfRangeColor: '#ECEDED',
        sliderAxisColor: '#F3F5F4',
        sliderPointColor: '#E2E5E4',
        sliderPlayButtonColor: '#C1C8CB',
        sliderPlayButtonBorderColor: '#C1C8CB',
        sliderPlayButtonHoverColor: '#1FBA59',
        sliderPlayButtonBorderHoverColor: '#1FBA59',
        roam: 'move',
    },

    color: colorPalette,
    backgroundColor: 'white',

    line: {
        grid: {
            left: 54,
            right: 50,
        },
        colors: ['#1FBA59', '#FBDD39'],
        tooltip: {
            confine: true,
            trigger: 'axis',
        },
        legend: {
            top: 530,
            left: 54,
            textStyle: {
                ...textStyle,
                fontSize: 13,
                padding: [0, 0, 0, 8],
            },
            icon: 'rect',
            itemGap: 8,
            itemWidth: 16,
            itemHeight: 2,
            orient: 'horizontal',
            itemStyle: {
                opacity: 1,
            },
            titleTextStyle: {
                ...textStyle,
                fontSize: 14,
                fontWeight: 500,
                overflow: 'break',
            },
        },
        showSymbol: true,
        showAllSymbol: true,
        smooth: true,
        symbolSize: 10,
        emphasis: {
            focus: 'series',
        },
    },

    bar: {
        seriesColorSpectrum: colorPalette,
        series: {
            barCategoryGap: 4,
            label: {
                ...textStyle,
                fontWeight: 500,
                color: 'white',
            },
        },
    },

    scatter: {
        series: {
            coordinateSystem: 'geo',
            geoIndex: 0,
            symbolSize: 14,
            label: { show: false },
            itemStyle: {
                opacity: 1,
                borderColor: 'white',
                borderWidth: 2,
            },
            emphasis: {
                itemStyle: {
                    shadowColor: '#FFFFFF',
                    shadowBlur: 15,
                },
            },
            cursor: 'pointer',
            animation: false,
        },
    },

    treemap: {
        type: 'treemap',
        top: 70,
        width: '100%',
        nodeClick: false,
        height: 520,
        roam: false,
        textStyle: textStyle,
        levels: [
            {},
            getTreemapLevelLabelStyle(true),
            getTreemapLevelLabelStyle(),
            getTreemapLevelLabelStyle(),
            getTreemapLevelLabelStyle(),
            getTreemapLevelLabelStyle(),
        ],
        breadcrumb: {
            show: false,
        },
        sliderAxisColor: '#F3F5F4',
        sliderPointColor: '#E2E5E4',
        sliderPlayButtonColor: '#C1C8CB',
        sliderPlayButtonBorderColor: '#C1C8CB',
        sliderPlayButtonHoverColor: '#1FBA59',
        sliderPlayButtonBorderHoverColor: '#1FBA59',
    },
    arrow: {
        colors: {
            white: '#fff',
            black: '#000',
            emptyRowBackground: '#DFE0E0',
            headerLabelColor: '#16191C',
        },
        seriesColorSpectrum: [
            '#1FBA59',
            '#D73146',
            '#2B88D8',
            '#A82EE6',
            '#2E2EE6',
            '#6B2EE6',
            '#E62EE6',
            '#EE5085',
            '#A8E62E',
            '#E62E6B',
            '#03A9F4',
            '#CB7722',
            '#FBC02D',
            '#FF7043',
            '#9F570F',
            '#A22949',
        ],
        xAxis: {
            type: 'value',
            show: false,
            min: -500,
            max: 500,
        },
        yAxis: {
            type: 'value',
            show: false,
            inverse: true,
            min: 0,
        },
        legend: {
            show: true,
            fontSize: 14,
            fontFamily: 'Avenir',
            fontWeight: 400,
            color: '#333333',
            padding: [4, 0],
            icon: 'circle',
            itemWidth: 8,
            itemHeight: 8,
            itemGap: 18,
            orient: 'horizontal',
            left: 20,
        },
        series: {
            symbol: 'rect',
            legendHoverLink: false,
            emphasis: {
                focus: 'adjacency',
                scale: false,
                label: {
                    color: 'white',
                },
            },
            type: 'graph',
            nodeScaleRatio: 0,
            left: 'left',
            coordinateSystem: 'cartesian2d',
            symbolSize: [340, 27],
            label: {
                color: 'white',
                fontFamily: 'Avenir',
                fontSize: 16,
                show: true,
                align: 'left',
                position: 'insideLeft',
                padding: [0, 0, 0, 12],
                formatter: function (d) {
                    return d.value[2];
                },
            },
            lineStyle: {
                opacity: 1,
                width: 1,
                curveness: 0,
                color: 'source',
                type: 'solid',
            },
            blur: {
                itemStyle: {
                    opacity: 0.4,
                },
                label: {
                    show: true,
                    color: '#fff',
                    opacity: 1,
                },
            },
        },
    },

    title: {
        backgroundColor: 'rgba(255, 255, 255, .8)',
        textStyle: {
            ...textStyle,
            fontSize: 18,
            fontWeight: 700,
        },
        subtextStyle: {
            ...textStyle,
            fontSize: 16,
            fontWeight: 500,
        },
    },

    xAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#ECEDED',
                width: 2,
            },
        },
        axisLabel: {
            ...textStyle,
            fontSize: 13,
            rich: {
                p: {
                    align: 'center',
                },
            },
        },
        axisTick: {
            length: 2,
            lineStyle: {
                width: 2,
            },
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: '#ECEDED',
            },
        },
        nameLocation: 'middle',
        nameTextStyle: {
            ...textStyle,
            fontWeight: 500,
        },
        nameGap: 28,
    },

    yAxis: {
        axisLine: {
            show: true,
            lineStyle: {
                color: '#ECEDED',
                width: 2,
            },
        },
        axisTick: {
            length: 2,
            lineStyle: {
                width: 2,
            },
        },
        splitLine: {
            show: true,
            lineStyle: {
                color: '#ECEDED',
            },
        },
        axisLabel: textStyle,
        nameLocation: 'middle',
        nameTextStyle: {
            ...textStyle,
            fontWeight: 500,
        },
        nameGap: 40,
    },

    legend: {
        textStyle,
        itemWidth: 30,
        itemHeight: 30,
        padding: [4, 0],
        right: 10,
    },

    dataZoom: {
        type: 'slider',
        textStyle,
        backgroundColor: '#F5F7F8',
        fillerColor: 'rgba(194,201,204, 0.25)',
        borderColor: 'transparent',
        dataBackground: {
            lineStyle: {
                color: '#ACB8BE',
            },
            areaStyle: {
                color: '#DDE3E6',
            },
        },
        handleStyle: {
            color: '#C2C9CC',
        },
        right: 50,
    },

    toolbox: {
        top: 16,
        right: 30,
        itemSize: 26,
        itemGap: 20,
        iconStyle: {
            color: '#1FBA59',
            borderWidth: 0,
            emphasis: {
                color: '#1DAA52',
            },
        },
    },

    markLine: {
        silent: true,
        // symbol: 'rect',
        lineStyle: {
            color: 'gray',
            width: 2,
        },
        label: {
            show: false,
        },
        animation: true,
        // animation: false,
    },

    timeline: {
        progress: {
            itemStyle: {
                color: '#1FBA59',
            },
            lineStyle: {
                color: '#1FBA59',
            },
        },
    },

    textStyle,
});
