import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
    AlertModal,
    ConfirmationModal,
    FlexColumn,
    H3,
    SectionBody,
    SectionHeader,
} from '@portal/common/components';
import { RefinementFilterConfig } from '@portal/common/types';
import { styled } from '@portal/common/theme';

import {
    cancelSelectionCountModal,
    confirmSelectionCountModal,
} from '../../store/data-explorer/actions';
import { getDataToolConfig } from '../../store/data-explorer/selectors';

import { LimitModal } from '../../models/data-tool';

import _ from '../../locale';
import ConditionAndTypeSelector from './ConditionAndTypeSelector';
import DataCollectionSelector from './DataCollectionSelector';
import RefinementFiltersControls from './RefinementFiltersControls';

const StyledSectionBody = styled(SectionBody)<{ isHidden: boolean }>(({ isHidden }) => ({
    marginTop: 0,
    position: 'static',
    ...(isHidden && {
        top: -1000,
        position: 'fixed',
        opacity: 0,
        pointerEvents: 'none',
    }),
}));

const mapStateToProps = (state: RootState) => ({
    visibleModal: state.dataExplorer.activeSelectionCountModal,
    dataToolConfig: getDataToolConfig(state),
});

const dispatchProps = {
    cancelSelectionCountModal: cancelSelectionCountModal,
    confirmSelectionCountModal: confirmSelectionCountModal,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & {
        refinementFiltersConfig?: RefinementFilterConfig;
        dataTool?: string;
        areChartsVisible: boolean;
        toggleChartsVisibility: () => void;
    };

type State = {
    activeRefinements: Array<string>;
};

class SelectDataSection extends React.Component<Props, State> {
    render() {
        const {
            cancelSelectionCountModal,
            confirmSelectionCountModal,
            visibleModal,
            dataToolConfig,
            refinementFiltersConfig,
            toggleChartsVisibility,
            areChartsVisible,
        } = this.props;

        const isDataCollectionSelectorVisible = dataToolConfig?.dataCollectionSelectorVisibility;

        return (
            <StyledSectionBody isHidden={!!dataToolConfig.hideSelectDataSection}>
                <SectionHeader>
                    <H3>{_('data_explorer_select_data')}</H3>
                </SectionHeader>
                <FlexColumn itemsSpacing={10} grow={1}>
                    {isDataCollectionSelectorVisible && <DataCollectionSelector />}
                    <ConditionAndTypeSelector showSmartOptions />
                    <RefinementFiltersControls
                        refinementFiltersConfig={refinementFiltersConfig}
                        {...{ toggleChartsVisibility, areChartsVisible }}
                    />
                </FlexColumn>
                <ConfirmationModal
                    message={_.get('data_explorer_filters_amount_warning')}
                    submitLabel={_('ignore_and_continue_button')}
                    isVisible={visibleModal === LimitModal.slowPerformanceWarning}
                    onClose={cancelSelectionCountModal}
                    onSubmit={confirmSelectionCountModal}
                />
                <ConfirmationModal
                    message={_('data_explorer_filters_chart_limit_exceeded')}
                    submitLabel={_('disable_charts_and_continue_button')}
                    isVisible={visibleModal === LimitModal.chartsLimitExceeded}
                    onClose={cancelSelectionCountModal}
                    onSubmit={confirmSelectionCountModal}
                />
                <ConfirmationModal
                    message={_('data_explorer_filters_limit_exceeded')}
                    submitLabel={_('ok_button')}
                    isVisible={visibleModal === LimitModal.dataToolLimitExceeded}
                    onClose={cancelSelectionCountModal}
                    onSubmit={confirmSelectionCountModal}
                />
                <AlertModal
                    isVisible={visibleModal === LimitModal.chartsLimitReceded}
                    onClose={cancelSelectionCountModal}
                    message={_('data_explorer_charts_data_limit_receded')}
                    submitLabel="Okay"
                    onSubmit={cancelSelectionCountModal}
                />
            </StyledSectionBody>
        );
    }
}

export default compose(connect(mapStateToProps, dispatchProps))(SelectDataSection);
