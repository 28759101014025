import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { intersection } from 'lodash/fp';

import { styled } from '@portal/common/theme';
import { Button, SvgIcon } from '@portal/common/components';
import { DataGranularityKey, SmartOptionConfig } from '@portal/common/types';

import { validateSelectedRefinementFiltersComplexityLimit } from '../../store/data-explorer/actions';
import {
    getSelectedConditionsDataTypesGranularity,
    getSelectedConditionsRefinedGranularity,
    getSelectedRefinementFiltersWithFallbackToDefaultFilters,
} from '../../store/data-explorer/selectors';

import _ from '../../locale';

import SmartOptionsDropdown from './SmartOptionsDropdown';

const ChevronIcon = styled(SvgIcon)({
    height: 15,
});

const mapStateToProps = (state: RootState) => ({
    selectedRefinementFilters: getSelectedRefinementFiltersWithFallbackToDefaultFilters(state),
    granularity: getSelectedConditionsDataTypesGranularity(state),
    refinedGranularity: getSelectedConditionsRefinedGranularity(state),
});

const dispatchProps = {
    tryChangeSelectedRefinementFilters: validateSelectedRefinementFiltersComplexityLimit,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    React.ComponentProps<typeof Button> & {
        filterKey: DataGranularityKey;
        selectedConditions: any;
        hideDelete?: boolean;
        onChange?: (val: string) => void;
        isSelectAllEnabled?: boolean;
        smartOptions?: SmartOptionConfig[];
    };

type State = {
    newValue: string | null;
};

class SmartOptionsSelector extends React.PureComponent<Props, State> {
    state: State = {
        newValue: null,
    };

    handleSmartOptionSelect = (value: SmartOptionConfig) => {
        const { filterKey, selectedRefinementFilters, granularity, refinedGranularity } =
            this.props;
        const currentValues = selectedRefinementFilters[filterKey];

        let options = [];
        if (refinedGranularity && refinedGranularity[filterKey]) {
            options = refinedGranularity[filterKey];
        } else if (granularity && granularity[filterKey]) {
            options = granularity[filterKey];
        }

        const newValue = intersection(
            options,
            value.getNewSelectedValues(currentValues, options) || []
        );

        if (newValue && newValue.length) {
            this.doSelect(newValue);
        }
    };

    handleSelectAllClick = () => {
        const { filterKey, refinedGranularity } = this.props;

        if (refinedGranularity && refinedGranularity[filterKey]) {
            this.doSelect(refinedGranularity[filterKey] || []);
        }
    };

    doSelect = (value: string | null) => {
        if (value === null) {
            return;
        }

        const { filterKey, tryChangeSelectedRefinementFilters, onChange } = this.props;

        const refinementFilters = { [filterKey]: value };
        tryChangeSelectedRefinementFilters(refinementFilters);
        onChange && onChange(value);
    };

    render() {
        const {
            className,
            filterKey,
            selectedRefinementFilters,
            granularity,
            refinedGranularity,
            isSelectAllEnabled,
            smartOptions,
            hideDelete,
            ...props
        } = this.props;

        return (
            <>
                <SmartOptionsDropdown
                    placement="bottom"
                    smartOptions={smartOptions}
                    onSmartOptionSelect={this.handleSmartOptionSelect}
                    style={{
                        marginTop: -14,
                        marginLeft: isSelectAllEnabled ? -10 : 80,
                        marginBottom: -14,
                    }}
                >
                    {({ ref, toggleVisibility }) => (
                        <div style={{ marginTop: -8 }}>
                            {isSelectAllEnabled ? (
                                smartOptions && smartOptions.length ? (
                                    <>
                                        <Button
                                            onClick={this.handleSelectAllClick}
                                            color="primary"
                                            {...props}
                                            style={{
                                                ...props.style,
                                                minWidth: 90,
                                                borderRadius: '4px 0 0 4px',
                                                padding: '6px 0px',
                                            }}
                                        >
                                            {_('data_explorer_select_all')}
                                        </Button>
                                        <Button
                                            ref={ref}
                                            onClick={toggleVisibility}
                                            color="primary"
                                            {...props}
                                            style={{
                                                ...props.style,
                                                minWidth: 30,
                                                width: 30,
                                                borderRadius: '0 4px 4px 0',
                                                borderLeft: '0.5px solid rgb(240,242,241)',
                                            }}
                                        >
                                            <ChevronIcon
                                                src="/icons/ic-arrow-down.svg"
                                                color={(theme) => theme.color.white}
                                            />
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        onClick={this.handleSelectAllClick}
                                        color="primary"
                                        {...props}
                                        style={{
                                            ...props.style,
                                            minWidth: 120,
                                        }}
                                    >
                                        {_('data_explorer_select_all')}
                                    </Button>
                                )
                            ) : (
                                <Button
                                    ref={ref}
                                    color="primary"
                                    disabled={!smartOptions || !smartOptions.length}
                                    onClick={toggleVisibility}
                                    {...props}
                                    style={{
                                        ...props.style,
                                        minWidth: 120,
                                    }}
                                >
                                    {_('data_explorer_select_more')}
                                </Button>
                            )}
                        </div>
                    )}
                </SmartOptionsDropdown>
            </>
        );
    }
}

export default compose(connect(mapStateToProps, dispatchProps))(SmartOptionsSelector);
