import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose, identity } from 'lodash/fp';

import {
    FlexBox,
    H3,
    Pagination,
    ActionIcon,
    Table,
    TableBody,
    TableCell as BaseTableCell,
    TableHead,
    TableHeaderCell as BaseTableHeaderCell,
    TableHeaderRow,
    TableRow,
    Tooltip,
    RowsPerPageSelector,
} from '@portal/common/components';
import { DataCondition, DataFilters, DataKey, DataResponse, DataType } from '@portal/common/types';
import { styled } from '@portal/common/theme';
import {
    localizeColumn,
    localizeColumns,
    localizeRecords,
} from '@portal/common/utility/chart-data-helpers';
import { isIE } from '@portal/common/utility/detect-browser';
import formatPdfValue from '@portal/common/utility/formatting/formatPdfValue';
import { getValueKeys } from '@portal/common/models/data-key';

import _ from '../../../locale';

import {
    filterDataResponse,
    getColumnWidth,
    sortRawTableData,
    getRecordValuesByIdxs,
} from './utils';
import { isValidDataResponse } from '../../../models/data-response';

const LoadingLabel = styled(H3)({
    textAlign: 'center',
});

const SortHeader = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
});

const TableCell = styled(BaseTableCell)(({ theme }) => ({
    padding: '4px 0 4px 4px',
    fontSize: '1.3rem',
    [theme.breakpoint.md]: {
        padding: '4px 4px 4px 10px',
        fontSize: '1.55rem',
    },
}));

const TableHeaderCell = styled(BaseTableHeaderCell)(({ theme }) => ({
    padding: '4px 0 4px 0',
    fontSize: '1.1rem',
    [theme.breakpoint.md]: {
        padding: '4px 4px 4px 10px',
        fontSize: '1.4rem',
    },
}));

const HeaderSpacer = styled.div(({ theme }) => ({
    width: 3,
    [theme.breakpoint.md]: {
        width: 15,
    },
}));

const StyledPagination = styled(Pagination)({
    flex: 1,
});

type Props = RouteComponentProps & {
    data?: DataResponse;
    valueFields: string[];
    onDisplayChange: ({ sortedByColumn, sortedDescending }) => void;
    onDataRendered: () => void;
    preprocessTableData: (data: DataResponse) => DataResponse;
    ageGroupsOrder: any;
    selectedRefinementFilters: DataFilters;
    selectedConditions: DataCondition[];
    dataResponse: DataResponse;
    dataTypes: DataType[];
    isLoadingData: boolean;
    isForecastingData: boolean;
    visibleColumns: DataKey[];
    sortedByColumn: DataKey | null;
    sortedDescending: boolean;
    showDecimals: boolean;
};

type State = {
    page: number;
    rowsPerPage: number;
};

class CustomDataTable extends React.Component<Props, State> {
    static defaultProps = {
        valueFields: [],
        preprocessTableData: identity,
        onDisplayChange: identity,
        showDecimals: true,
    };

    state: State = {
        page: 0,
        rowsPerPage: 5,
    };

    componentWillUnmount() {
        if (isIE()) {
            window.removeEventListener('resize', this.updateDataTableColumns);
        }
    }

    componentDidMount() {
        if (isIE()) {
            window.addEventListener('resize', this.updateDataTableColumns);
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { onDataRendered, dataResponse, isLoadingData } = this.props;

        if (
            !isValidDataResponse(prevProps.dataResponse) &&
            isValidDataResponse(dataResponse) &&
            !isLoadingData
        ) {
            onDataRendered && onDataRendered();
        }
    }

    updateDataTableColumns = (ev) => {
        // force rerender to recalculate data table columns widths
        this.forceUpdate();
    };

    handleChangePage = (page) => this.setState({ page: page - 1 });

    handleSortLabelClick = (column: DataKey) => (ev) => {
        const { onDisplayChange, sortedByColumn, sortedDescending } = this.props;

        const displayState =
            sortedByColumn === column
                ? { sortedByColumn, sortedDescending: !sortedDescending }
                : { sortedByColumn: column, sortedDescending: true };

        onDisplayChange && onDisplayChange(displayState);
    };

    handleRowsPerPageChange = (ev) => {
        const { rowsPerPage, page } = this.state;
        const newRowsPerPage = ev.target.value;

        const firstRowNumber = rowsPerPage * page + 1;
        const newPage = Math.floor(firstRowNumber / newRowsPerPage);

        this.setState({
            rowsPerPage: newRowsPerPage,
            page: newPage,
        });
    };

    preprocessData = (dataResponse: DataResponse): DataResponse => {
        if (!dataResponse) {
            return {
                columns: [],
                records: [],
            };
        }

        const { sortedByColumn, sortedDescending, ageGroupsOrder } = this.props;

        const sortParams = { sortedByColumn, sortedDescending, ageGroupsOrder };

        return compose(this.props.preprocessTableData, sortRawTableData(sortParams))(dataResponse);
    };

    getVisibleColumnsIdxs = (columns): number[] =>
        (this.props.visibleColumns || []).map((column) => columns.indexOf(column));

    getFullData = (): DataResponse => {
        const { dataResponse, dataTypes } = this.props;
        const { columns, records } = this.preprocessData(dataResponse);

        const localizedData = {
            columns: localizeColumns(columns, dataTypes, _),
            records: localizeRecords(columns, records, _),
        };

        return filterDataResponse(localizedData, this.getVisibleColumnsIdxs(columns), true);
    };

    renderCell = (value: string | number, filterKey: string) => {
        if ([...this.props.valueFields, ...getValueKeys()].includes(filterKey) && value !== null) {
            return this.renderValueCell(value, filterKey);
        } else {
            return <TableCell key={filterKey}>{value}</TableCell>;
        }
    };

    renderValueCell = (value: string | number, filterKey: string) => (
        <TableCell
            key={filterKey}
            style={{
                textAlign: 'right',
                paddingRight: '20px',
                paddingLeft: 0,
            }}
        >
            <Tooltip key={filterKey} title={value} disableFocusListener>
                {formatPdfValue(value, this.props.showDecimals)}
            </Tooltip>
        </TableCell>
    );

    renderHeaders = (columns, sortedByColumn, sortedDescending) => {
        const { selectedConditions, selectedRefinementFilters, dataTypes, valueFields } =
            this.props;

        const filteredColumns = getRecordValuesByIdxs(columns, this.getVisibleColumnsIdxs(columns));

        return (
            <TableHeaderRow>
                {filteredColumns.map((column) => (
                    <TableHeaderCell key={column}>
                        <SortHeader
                            onClick={this.handleSortLabelClick(column)}
                            style={{
                                ...(isIE() && {
                                    width: getColumnWidth(
                                        filteredColumns,
                                        selectedConditions,
                                        selectedRefinementFilters,
                                        dataTypes,
                                        column
                                    ),
                                }),
                            }}
                        >
                            <div
                                style={
                                    valueFields.includes(column)
                                        ? {
                                              width: '100%',
                                              textAlign: 'right',
                                          }
                                        : {}
                                }
                            >
                                {localizeColumn(column, dataTypes, _)}
                            </div>
                            {sortedByColumn === column ? (
                                <ActionIcon
                                    size="large"
                                    src={
                                        sortedDescending
                                            ? '/icons/ic-sort-asc.svg'
                                            : '/icons/ic-sort-desc.svg'
                                    }
                                />
                            ) : (
                                <HeaderSpacer />
                            )}
                        </SortHeader>
                    </TableHeaderCell>
                ))}
            </TableHeaderRow>
        );
    };

    renderRows = (columns, records, validatedPage) => {
        const localizedRecords = localizeRecords(columns, records, _);
        const { columns: filteredColumns, records: filteredRecords } = filterDataResponse(
            { columns, records: localizedRecords },
            this.getVisibleColumnsIdxs(columns),
            true
        );

        return filteredRecords.map((record, idx) => (
            <TableRow key={`${validatedPage}:${idx}`}>
                {record.map((value, innerIdx) => this.renderCell(value, filteredColumns[innerIdx]))}
            </TableRow>
        ));
    };

    render() {
        const { dataResponse, isLoadingData, sortedByColumn, sortedDescending } = this.props;
        const { rowsPerPage, page } = this.state;

        if (isLoadingData) {
            return <LoadingLabel>Loading...</LoadingLabel>;
        }

        // TODO: this needs to be refactored in the same way as ChartSection because every change in this component will break CustomDataTableReport for PDF Export if not synchronized
        const { columns, records } = this.preprocessData(dataResponse);

        const maxPage = Math.ceil(records.length / rowsPerPage);

        // validate page by max page
        let validatedPage = page;
        if (page > maxPage) {
            validatedPage = 0;
        }

        const visibleRecords = records.slice(
            validatedPage * rowsPerPage,
            validatedPage * rowsPerPage + rowsPerPage
        );
        const remainingEmptyRows =
            rowsPerPage - Math.min(rowsPerPage, records.length - validatedPage * rowsPerPage);

        return (
            <React.Fragment>
                <Table stripes className={this.props.className}>
                    <TableHead>
                        {this.renderHeaders(columns, sortedByColumn, sortedDescending)}
                    </TableHead>
                    <TableBody>
                        {this.renderRows(columns, visibleRecords, validatedPage)}
                        {remainingEmptyRows > 0 &&
                            Array(remainingEmptyRows)
                                .fill(undefined)
                                .map((__, idx) => (
                                    <TableRow key={idx + 'missing'}>
                                        <TableCell
                                            colSpan={columns.length}
                                            style={{
                                                borderBottomColor: 'transparent',
                                            }}
                                        />
                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
                {records.length > 0 && (
                    <FlexBox style={{ position: 'relative' }}>
                        <RowsPerPageSelector
                            label={_('data_table_rows_per_page')}
                            options={[5, 10, 25]}
                            value={rowsPerPage}
                            onChange={this.handleRowsPerPageChange}
                            style={{
                                position: 'absolute',
                                paddingTop: records.length > rowsPerPage ? 12 : 20,
                            }}
                        />
                        {records.length > rowsPerPage ? (
                            <StyledPagination
                                totalItems={records.length}
                                currentPage={validatedPage + 1}
                                onChangePage={this.handleChangePage}
                                rowsPerPage={rowsPerPage}
                            />
                        ) : (
                            <div style={{ flexGrow: 1, height: 70 }} />
                        )}
                    </FlexBox>
                )}
            </React.Fragment>
        );
    }
}

const Connected = withRouter(({ forwardRef, ...props }) => (
    <CustomDataTable ref={forwardRef} {...props} />
));

export default React.forwardRef((props, ref) => <Connected {...props} forwardRef={ref} />);
