import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
    AnnouncementResource,
    PaginatedResponse,
    SidebarLinkResource,
    WelcomeMessageResource,
} from '@portal/common/types';

import { getAnnouncementsAsync, getSidebarLinksAsync, getWelcomeMessageAsync } from './actions';

type State = {
    announcements: null | PaginatedResponse<AnnouncementResource>;
    sidebarLinks: null | PaginatedResponse<SidebarLinkResource>;
    welcomeMessage: null | WelcomeMessageResource;
};

const initialState: State = {
    announcements: null,
    sidebarLinks: null,
    welcomeMessage: null,
};

export default combineReducers<State>({
    announcements: createReducer(initialState.announcements).handleAction(
        getAnnouncementsAsync.success,
        (state, action) => action.payload
    ),
    sidebarLinks: createReducer(initialState.sidebarLinks).handleAction(
        getSidebarLinksAsync.success,
        (state, action) => action.payload
    ),
    welcomeMessage: createReducer(initialState.welcomeMessage).handleAction(
        getWelcomeMessageAsync.success,
        (state, action) => action.payload
    ),
});
