import { useEffect, useState } from 'react';

import { PRIMARY_ENTITY_ID_KEY } from '@portal/common/models/data-key';
import { DataCondition, DataFilters } from '@portal/common/types';
import { mergeAndSortDataResponses } from '@portal/common/utility/chart-data-helpers';

import { useData } from '../contexts/data-context';

export type GetAPiDataProps = {
    conditions: DataCondition[];
    filters: DataFilters;
    mergeResponses?: boolean;
};

export default ({ conditions, filters, mergeResponses = true }: GetAPiDataProps) => {
    const { isLoading, error, data } = useData();
    const [dataResponse, setDataResponse] = useState(null);
    useEffect(() => {
        if (!data) {
            setDataResponse(null);
        } else {
            const datatype = conditions[0].data_type;
            const dataByDataType = data[datatype];
            const mergedDataResponse = mergeResponses
                ? mergeAndSortDataResponses({ [datatype]: dataByDataType })
                : dataByDataType;

            const primaryEntityIdColumnIndex = mergedDataResponse.columns.findIndex(
                (column) => column === PRIMARY_ENTITY_ID_KEY
            );

            const filtersByIndex = Object.entries(filters).reduce(
                (acc, [filterName, filterValue]) => {
                    const index = mergedDataResponse.columns.findIndex(
                        (column) => column === filterName
                    );
                    if (~index) {
                        acc[index] = filterValue;
                    }
                    if (~primaryEntityIdColumnIndex) {
                        acc[primaryEntityIdColumnIndex] = conditions.map(
                            (condition) => condition[PRIMARY_ENTITY_ID_KEY]
                        );
                    }
                    return acc;
                },
                {}
            );

            const dataResponse = {
                columns: mergedDataResponse.columns,
                records: mergedDataResponse.records.reduce((acc, record) => {
                    if (
                        record.every((value, index) => {
                            if (index in filtersByIndex) {
                                return filtersByIndex[index].includes(value);
                            }
                            return true;
                        })
                    ) {
                        acc.push([...record]);
                    }

                    return acc;
                }, []),
            };

            setDataResponse(dataResponse);
        }
    }, [data, JSON.stringify(filters), JSON.stringify(conditions), mergeResponses]);

    return { isLoading, dataResponse, error };
};
