import { createReducer } from 'typesafe-actions';
import { Organization } from '@portal/common/types';
import { getOrganizationAsync } from './actions';
import * as types from './types';

type State = Organization | null;
const initialState: State = null;

export default createReducer(initialState).handleAction(
    [
        types.SELECT_ORGANIZATION,
        types.UPDATE_ORGANIZATION,
        types.LOAD_ORGANIZATION,
        getOrganizationAsync.success,
    ],
    (state, action) => ({ ...state, ...action.payload })
);
