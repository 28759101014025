import { Field, Form, FormikProps, withFormik } from 'formik';
import { merge, omit } from 'lodash/fp';
import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';

import _ from '@portal/common/locale';
import { styled } from '@portal/common/theme';
import {
    Alert,
    FormikDropdown,
    FormikInput,
    FormikInputWithButton,
    FormRadioSelect,
    ModalButton,
    ModalFooter,
} from '@portal/common/components';
import { OrganizationMember } from '@portal/common/types';

import locale from './locale';
import { SESSION_ACCOUNT_UPDATED } from '../../store/session/types';

const StyledAlert = styled(Alert)({
    marginBottom: 20,
});

const StyledModalFooter = styled(ModalFooter)({
    margin: '20px -40px -40px -40px',
});

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {
    updateSessionAccount: (account) => ({
        type: SESSION_ACCOUNT_UPDATED,
        payload: account,
    }),
};

type FormValues = {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    old_password: string;
    title: string;
    organization_role: string;
    has_programmatic_access: boolean;
};

type Props = typeof dispatchProps & {
    organizationId: number;
    isNew: boolean;
    isSelfEdit: boolean;
    member: OrganizationMember;
    onGeneratePassword: (values: FormValues) => void;
    addOrganizationMember: (organizationId, data) => Promise<void>;
    editOrganizationMember: (organizationId, memberId, data) => Promise<void>;
    handleCancel: () => void;
    handleSuccess: (member: OrganizationMember) => void;
};

const mapNameToLabel = (name: string) => _('form_field_' + name);

const InnerForm: React.FC<Props & FormikProps<FormValues>> = (props) => {
    const { isSubmitting, status, isSelfEdit, onGeneratePassword, values, handleCancel } = props;

    return (
        <React.Fragment>
            <Form>
                {status && status.error && <StyledAlert color="error">{status.error}</StyledAlert>}

                {status && status.success && (
                    <StyledAlert color="success">{status.success}</StyledAlert>
                )}

                <Field
                    value={values.first_name}
                    type="text"
                    name="first_name"
                    component={FormikInput}
                    mapNameToLabel={mapNameToLabel}
                    autoFocus
                    data-private
                />

                <Field
                    value={values.last_name}
                    type="text"
                    name="last_name"
                    component={FormikInput}
                    mapNameToLabel={mapNameToLabel}
                    data-private
                />

                <Field
                    value={values.email}
                    type="email"
                    name="email"
                    component={FormikInput}
                    mapNameToLabel={mapNameToLabel}
                    required
                    data-private
                />

                {isSelfEdit && (
                    <Field
                        value={values.old_password}
                        type="password"
                        name="old_password"
                        component={FormikInput}
                        mapNameToLabel={mapNameToLabel}
                        data-private
                    />
                )}

                <Field
                    value={values.password}
                    type="password"
                    name="password"
                    component={FormikInputWithButton}
                    mapNameToLabel={mapNameToLabel}
                    buttonLabel={_(locale.generatePassword)}
                    handleButtonClick={() => onGeneratePassword(values)}
                    data-private
                />

                <Field
                    value={values.title}
                    type="text"
                    name="title"
                    component={FormikInput}
                    mapNameToLabel={mapNameToLabel}
                />

                <Field
                    value={values.organization_role}
                    type="select"
                    name="organization_role"
                    mapNameToLabel={mapNameToLabel}
                    component={FormikDropdown}
                    options={['member', 'manager']}
                    valueMode="single"
                    optionLabelGetter={(o) => _('organization_member_role_' + o)}
                />

                <Field name="has_programmatic_access">
                    {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    }) => {
                        return (
                            <FormRadioSelect
                                options={[
                                    {
                                        value: false,
                                        label: _('organization_has_programmatic_access_false'),
                                    },
                                    {
                                        value: true,
                                        label: _('organization_has_programmatic_access_true'),
                                    },
                                ]}
                                label={_('organization_has_programmatic_access')}
                                {...field}
                            />
                        );
                    }}
                </Field>

                <StyledModalFooter>
                    <ModalButton type="button" color="secondary" onClick={handleCancel}>
                        {_(locale.cancel)}
                    </ModalButton>{' '}
                    <ModalButton type="submit" disabled={isSubmitting}>
                        {_(locale.save)}
                    </ModalButton>
                </StyledModalFooter>
            </Form>
        </React.Fragment>
    );
};

const Connected = withFormik<Props, FormValues>({
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,

    mapPropsToValues: ({
        member: {
            first_name,
            last_name,
            email,
            password,
            title,
            organization_role,
            has_programmatic_access,
        },
    }) => ({
        first_name,
        last_name,
        email,
        password,
        title,
        organization_role: organization_role || 'member',
        has_programmatic_access,
    }),

    validationSchema: ({ isNew }) =>
        yup.object().shape({
            first_name: yup.string(),
            last_name: yup.string(),
            email: yup.string().email('Invalid email format').required("This field can't be empty"),
            title: yup.string(),
            organization_role: yup.string(),
            ...(isNew
                ? {
                      password: yup
                          .string()
                          .min(8, 'Password should be at least 8 characters long')
                          .required("This field can't be empty"),
                  }
                : {
                      password: yup
                          .string()
                          .min(8, 'Password should be at least 8 characters long'),
                  }),
        }),

    handleSubmit: (values, form) => {
        const {
            organizationId,
            updateSessionAccount,
            isNew,
            isSelfEdit,
            addOrganizationMember,
            editOrganizationMember,
            member,
            handleSuccess,
        } = form.props;

        const validatedValues = {
            ...values,
            email: values.email.toLowerCase(),
        };

        const updatedMember = merge(member, validatedValues);

        const request = isNew
            ? addOrganizationMember(organizationId, validatedValues)
            : editOrganizationMember(
                  organizationId,
                  updatedMember.id,
                  omit(
                      isSelfEdit ? ['id', 'avatar_url'] : ['id', 'avatar_url', 'old_password'],
                      updatedMember
                  )
              );

        request
            .then((member) => {
                form.setStatus({ success: 'Member successfully updated.' });
                form.setSubmitting(false);

                handleSuccess(member);
                if (isSelfEdit) {
                    updateSessionAccount(member);
                }
            })
            .catch((error) => {
                form.setStatus({ error: error.message });
                form.setSubmitting(false);
            });
    },
})(InnerForm);

export default connect(mapStateToProps, dispatchProps)(Connected);
