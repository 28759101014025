import makeAuthenticated from '@ihme/common/utility/hoc/authenticated';
import { getSessionAccount } from '../../store/root-reducer';

export default makeAuthenticated(
    (store) => !!getSessionAccount(store),
    (props, onLogOut) => {
        const back = props.location.pathname + props.location.search;
        return props.history.replace('/sign-in' + (onLogOut ? '' : '?back=' + back));
    }
);
