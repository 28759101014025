import api from '../../api';
import * as types from './types';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { Organization } from '@portal/common/types';

export const selectOrganization = (organization) => ({
    type: types.SELECT_ORGANIZATION,
    payload: organization,
});

export const loadOrganization = (organizationId) => (dispatch) =>
    api.organization.getOrganization(organizationId).then((res) => {
        dispatch({
            type: types.LOAD_ORGANIZATION,
            payload: res,
        });
        return res;
    });

export const getOrganizationAsync = createAsyncAction(
    'GET_ORGANIZATION_REQUEST',
    'GET_ORGANIZATION_SUCCESS',
    'GET_ORGANIZATION_FAILURE'
)<{ organizationId: number }, Organization[], string>();

export const updateOrganization = (organizationId, data) => (dispatch) =>
    api.organization.updateOrganization(organizationId, data).then((res) => {
        dispatch({
            type: types.UPDATE_ORGANIZATION,
            payload: res,
        });
        return res;
    });

export const organizationContacted = createAction('ORGANIZATION_CONTACTED')<{
    reason: string;
    status: string;
    is_manager_copied: boolean;
}>();
