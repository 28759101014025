import { createAsyncAction } from 'typesafe-actions';
import {
    AnnouncementResource,
    PaginatedResponse,
    SidebarLinkResource,
    WelcomeMessageResource,
} from '@portal/common/types';

export const getAnnouncementsAsync = createAsyncAction(
    'GET_ANNOUNCEMENTS_REQUEST',
    'GET_ANNOUNCEMENTS_SUCCESS',
    'GET_ANNOUNCEMENTS_FAILURE'
)<void, PaginatedResponse<AnnouncementResource>, string>();

export const getSidebarLinksAsync = createAsyncAction(
    'GET_SIDEBAR_LINKS_REQUEST',
    'GET_SIDEBAR_LINKS_SUCCESS',
    'GET_SIDEBAR_LINKS_FAILURE'
)<void, PaginatedResponse<SidebarLinkResource>, string>();

export const getWelcomeMessageAsync = createAsyncAction(
    'GET_WELCOME_MESSAGE_REQUEST',
    'GET_WELCOME_MESSAGE_SUCCESS',
    'GET_WELCOME_MESSAGE_FAILURE'
)<void, WelcomeMessageResource, string>();
