import {
    CauseOutcome,
    getDataTypeEntityHierarchyKey,
    getDataTypePrimaryEntityKey,
    SequelaOutcome,
} from '@portal/common/models/data-type';
import { DataResponse, DataType } from '@portal/common/types';
import { CONDITION_LEVEL_KEY } from '@portal/common/models/data-key';

const SUPPORTED_DATA_TYPES = [CauseOutcome, SequelaOutcome];

export default (dataResponses: Record<DataType, DataResponse>, state): void => {
    SUPPORTED_DATA_TYPES.forEach((dataType) => {
        const dataResponse = dataResponses?.[dataType];
        if (dataResponse) {
            let { columns, records } = dataResponse;
            const primaryEntityIdx = columns.indexOf(getDataTypePrimaryEntityKey(dataType));

            const hierarchyKey = getDataTypeEntityHierarchyKey(dataType);
            const hierarchy = state.entityHierarchies[hierarchyKey];

            dataResponses[dataType] = {
                columns: [columns[0], CONDITION_LEVEL_KEY, ...columns.slice(1)],
                records: records.map((record) => {
                    const primaryEntityId = record[primaryEntityIdx];
                    const level = hierarchy.find(({ id }) => id === primaryEntityId)?.level || 0;
                    return [record[0], level, ...record.slice(1)];
                }),
            };
        }
    });
};
