import React, { FC } from 'react';
import { SectionBody } from '@portal/common/components';
import _ from '../../../locale/_';
import { styled } from '@portal/common/theme';

type Props = {};

const StyledSectionBody = styled(SectionBody)(({ theme }) => ({
    padding: '15px !important',
    [theme.breakpoint.md]: {
        padding: '50px 30px 50px 30px !important',
    },
}));

const AboutSection: FC<Props> = ({}) => (
    <StyledSectionBody>{_('ght_about_section_text')}</StyledSectionBody>
);

export default AboutSection;
