import React from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { maxBy, uniq } from 'lodash/fp';

import {
    DAY_KEY,
    FORECAST_SCENARIO_ID_KEY,
    getTimeUnitKeys,
    PRIMARY_ENTITY_ID_LOCALIZATION_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import { DataExportFormat, DataGranularityKey, DataResponse } from '@portal/common/types';
import {
    TYPE_ARROW_CHART,
    TYPE_BAR_CHART,
    TYPE_DATA_TABLE_CHART,
    TYPE_LINE_CHART,
    TYPE_MAP_CHART,
    TYPE_SCATTER_MAP_CHART,
    TYPE_TREEMAP_CHART,
} from '@portal/common/models/chart-type';
import { BodyText2, Modal, ModalBody, SectionBody } from '@portal/common/components';
import { isGifExportEnabled, isPngExportEnabled } from '@portal/common/models/organization';
import formatChartValue from '@portal/common/utility/formatting/formatChartValue';

import { getOrganization, getSelectedDataTool } from '../../../store/root-reducer';
import { getSelectedDataCollectionForDataTool } from '../../../store/user-settings/selectors';
import {
    getChartDataResponsesByType,
    getChartSelectedRefinementFilters,
    getCombinedChartFiltersAmount,
    getCustomChartSettings,
    getDataRecordsAmount,
    getDataToolConfig,
    getMaxValuesSelectedFilter,
    getMergedChartDataResponses,
    getMergedDataResponses,
    getMultipleSelectedRefinementFilterKeys,
    getSelectedChartType,
    getSelectedConditions,
    getSelectedConditionsDataTypes,
    getSelectedConditionsDataTypesGranularity,
    getSelectedConditionsPrimaryEntityFilters,
    getSelectedConditionsRefinedGranularity,
    getSelectedDatasetsConfig,
    getSelectedDataType,
    getSelectedRefinementFiltersWithFallbackToDefaultFilters,
    getStaticForecastTimeStart,
    isChartDataLoading,
    isDataLoading,
    isForecastingData,
    isPopulationData,
} from '../../../store/data-explorer/selectors';
import { setCustomChartSettings } from '../../../store/data-explorer/actions';
import { isValidDataResponse } from '../../../models/data-response';
import { getAllowedChartRefinementFilters } from './utils';
import config from '../../../config';

import _ from '../../../locale';

import BarChart from './BarChart';
import LineChart from './LineChart';
import MapChart from './MapChart';
import TreemapChart from './TreemapChart';
import ScatterMapChart from './ScatterMapChart';
import DataTableSection from '../DataTableSection';
import ArrowChart from './ArrowChart';

const mapStateToProps = (state: RootState) => ({
    granularity: getSelectedConditionsDataTypesGranularity(state),
    refinedGranularity: getSelectedConditionsRefinedGranularity(state),
    selectedRefinementFilters: getSelectedRefinementFiltersWithFallbackToDefaultFilters(state),
    selectedConditionsDataTypes: getSelectedConditionsDataTypes(state),
    selectedConditionsPrimaryEntityFilters: getSelectedConditionsPrimaryEntityFilters(state),
    chartType: getSelectedChartType(state),
    staticForecastTimeStart: getStaticForecastTimeStart(state),
    selectedConditions: getSelectedConditions(state),
    selectedDataCollection: getSelectedDataCollectionForDataTool(state),
    multipleFilterKeys: getMultipleSelectedRefinementFilterKeys(state),
    maxValuesSelectedFilter: getMaxValuesSelectedFilter(state),
    isForecastingData: isForecastingData(state),
    isPopulationData: isPopulationData(state),
    dataTool: getSelectedDataTool(state),
    dataType: getSelectedDataType(state),

    chartDataResponsesByType: getChartDataResponsesByType(state),
    mergedChartDataResponses: getMergedChartDataResponses(state),
    dataResponse: getMergedDataResponses(state),
    isLoadingData: isDataLoading(state),
    isLoadingChartData: isChartDataLoading(state),
    chartFilters: getChartSelectedRefinementFilters(state),

    dataToolConfig: getDataToolConfig(state),
    datasetsConfig: getSelectedDatasetsConfig(state),
    organization: getOrganization(state),

    customChartSettings: getCustomChartSettings(state),
    combinedChartFiltersAmount: getCombinedChartFiltersAmount(state),
    dataRecordsAmount: getDataRecordsAmount(state),
});

const dispatchProps = {
    setCustomChartSettings,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & {
        onExportData: (format: DataExportFormat) => void;
        onDataRendered?: () => void;
    };

type State = {
    isChartPoppedOut: boolean;
};

class ChartSectionRenderer extends React.Component<Props, State> {
    state: State = {
        isChartPoppedOut: false,
    };

    componentDidMount(): void {
        this.reportIfNewDataRendered(null);
    }

    componentDidUpdate(prevProps: Props) {
        this.reportIfNewDataRendered(prevProps.dataResponse);
    }

    reportIfNewDataRendered = (prevDataResponse: DataResponse | null) => {
        const { onDataRendered, dataResponse, isLoadingData } = this.props;
        if (
            !isValidDataResponse(prevDataResponse) &&
            isValidDataResponse(dataResponse) &&
            !isLoadingData
        ) {
            onDataRendered && onDataRendered();
        }
    };

    toggleChartPoppedOut = () => {
        this.setState((state) => ({ isChartPoppedOut: !state.isChartPoppedOut }));
    };

    getInitialTimeUnitValue = () => {
        const {
            datasetsConfig: { time_unit_field },
            isForecastingData,
            staticForecastTimeStart,
            chartDataResponsesByType,
            mergedChartDataResponses,
        } = this.props;

        let initialTimeUnit = 0;

        if (!chartDataResponsesByType) {
            return initialTimeUnit;
        }

        const responses = Object.values(chartDataResponsesByType);
        if (!(responses && responses.length)) {
            return initialTimeUnit;
        }
        const { columns, records } = mergedChartDataResponses;

        if (time_unit_field && !columns.includes(time_unit_field)) {
            return initialTimeUnit;
        }

        const timeUnitIdx = columns.indexOf(time_unit_field);
        const timeUnits = uniq(records.map((record) => record[timeUnitIdx]));

        if (!(timeUnits && timeUnits.length)) {
            return initialTimeUnit;
        }

        const options = timeUnits.map((timeUnit) => ({
            rawValue: timeUnit,
            sortValue:
                time_unit_field === DAY_KEY ? new Date(timeUnit).setHours(0, 0, 0, 0) : timeUnit,
        }));

        if (isForecastingData) {
            initialTimeUnit =
                this.getPrevToStaticForecastTimeStart(staticForecastTimeStart) ||
                new Date().setHours(0, 0, 0, 0);
            const initialTimeUnitOption = options.find(
                (option) => option.sortValue === initialTimeUnit
            );

            initialTimeUnit = initialTimeUnitOption
                ? initialTimeUnitOption.rawValue
                : this.getClosest(initialTimeUnit, options).rawValue;
        } else {
            initialTimeUnit = maxBy('sortValue', options).rawValue;
        }

        return initialTimeUnit;
    };

    getPrevToStaticForecastTimeStart = (value: number | string): number | string => {
        const { time_unit_field } = this.props.datasetsConfig;
        if (value && time_unit_field === YEAR_KEY) {
            return value - 1;
        }

        return value;
    };

    getClosest = (needle, options) =>
        options.reduce((a, b) =>
            Math.abs(b.sortValue - needle) < Math.abs(a.sortValue - needle) ? b : a
        );

    getMultiValueRefinementFilters = (): DataGranularityKey[] => {
        const { chartType, isForecastingData, multipleFilterKeys, selectedConditions, dataTool } =
            this.props;

        // @todo: get rid of following hardcode
        const chartRefinementFilters = getAllowedChartRefinementFilters(chartType, dataTool);
        let keys = multipleFilterKeys.filter(
            (key) => ![...chartRefinementFilters, ...getTimeUnitKeys()].includes(key)
        );

        if (selectedConditions && selectedConditions.length > 1) {
            keys.push(PRIMARY_ENTITY_ID_LOCALIZATION_KEY);
        }

        if (!isForecastingData) {
            keys = keys.filter((key) => key !== FORECAST_SCENARIO_ID_KEY);
        }

        return keys;
    };

    render() {
        const {
            chartType,
            chartDataResponsesByType,
            refinedGranularity,
            granularity,
            selectedRefinementFilters,
            selectedConditionsDataTypes,
            selectedConditionsPrimaryEntityFilters,
            selectedConditions,
            isForecastingData,
            isPopulationData,
            isLoadingData,
            isLoadingChartData,
            chartFilters,
            dataToolConfig,
            datasetsConfig,
            staticForecastTimeStart,
            mergedChartDataResponses,
            selectedDataCollection,
            dataTool,
            dataType,
            onExportData,
            organization,
            setCustomChartSettings,
            customChartSettings,
            maxValuesSelectedFilter,
            combinedChartFiltersAmount,
            dataRecordsAmount,
        } = this.props;

        const { isChartPoppedOut } = this.state;

        if (chartType === TYPE_DATA_TABLE_CHART) {
            return (
                <DataTableSection
                    style={{ paddingTop: 40 }}
                    key={chartType}
                    hideTitle
                    onExportData={onExportData}
                />
            );
        }

        if (
            !(selectedConditions && selectedConditions.length) ||
            !selectedRefinementFilters ||
            !dataToolConfig ||
            !datasetsConfig
        ) {
            return null;
        }

        const {
            time_unit_field,
            reference_forecast_scenario_id,
            observed_data_forecast_scenario_id,
        } = datasetsConfig;

        if (!time_unit_field) {
            return null;
        }

        const isChartSectionEnabled =
            combinedChartFiltersAmount < config.combinedFiltersWithoutTimeUnitsAmountChartLimit &&
            dataRecordsAmount < 550_000;

        let chartComponent;

        if (isChartSectionEnabled) {
            const preparedContext = {
                key: chartType,
                multipleFilterKeys: this.getMultiValueRefinementFilters(),
                selectedDataTool: dataTool,
                dataToolConfig,
                dataType,
                selectedConditions,
                selectedDataCollection,
                selectedRefinementFilters,
                selectedConditionsDataTypes,
                selectedConditionsPrimaryEntityFilters,
                selectedConditionsDataTypesGranularity: refinedGranularity || granularity,
                initialTimeUnitValue: this.getInitialTimeUnitValue(),
                enableExportPNG: isPngExportEnabled(organization),
                enableExportGIF: isGifExportEnabled(organization),
                isLoadingDataExplorerData: isLoadingData,
                isLoadingChartData:
                    !chartDataResponsesByType || isLoadingData || isLoadingChartData,
                chartDataResponsesByType,
                mergedChartDataResponses,
                chartFilters,
                isForecastingData,
                timeUnitKey: time_unit_field,
                filtersValues: {
                    ...selectedRefinementFilters,
                    ...chartFilters,
                },
                onExportData,
                ...customChartSettings,
                setCustomChartSettings,
                isChartPoppedOut,
                toggleChartPoppedOut: this.toggleChartPoppedOut,
                numberFormatter: (value: number | string) =>
                    formatChartValue(value, !isPopulationData),
            };

            switch (chartType) {
                case TYPE_LINE_CHART:
                    chartComponent = (
                        <LineChart
                            {...preparedContext}
                            baseForecastScenarioId={reference_forecast_scenario_id}
                            observedForecastScenarioId={observed_data_forecast_scenario_id}
                            forecastTimeUnitStart={staticForecastTimeStart}
                            maxValuesSelectedFilter={maxValuesSelectedFilter}
                        />
                    );
                    break;

                case TYPE_MAP_CHART:
                    chartComponent = <MapChart {...preparedContext} />;
                    break;

                case TYPE_BAR_CHART:
                    chartComponent = <BarChart {...preparedContext} />;
                    break;

                case TYPE_TREEMAP_CHART:
                    chartComponent = <TreemapChart {...preparedContext} />;
                    break;

                case TYPE_SCATTER_MAP_CHART:
                    chartComponent = <ScatterMapChart {...preparedContext} />;
                    break;
                case TYPE_ARROW_CHART:
                    chartComponent = <ArrowChart {...preparedContext} />;
                    break;

                default:
                    throw new Error(`Invalid chart type ${chartType}`);
            }
        } else {
            chartComponent = (
                <BodyText2 align="center">
                    {_('data_explorer_charts_data_limit_exceeded')}
                </BodyText2>
            );
        }

        return (
            <SectionBody style={{ paddingTop: 60 }}>
                {chartComponent}
                <Modal show={isChartPoppedOut} size="large" dialogClassName="modal-95w">
                    <ModalBody>{chartComponent}</ModalBody>
                </Modal>
            </SectionBody>
        );
    }
}

export default compose(connect(mapStateToProps, dispatchProps))(ChartSectionRenderer);
