import React from 'react';
import { sortedUniq } from 'lodash';

import { EChartsInstance } from 'echarts-for-react';
import SaveAsImage from 'echarts/lib/component/toolbox/feature/SaveAsImage';

import getTitleAndSubtitleAdjustedToWidth, {
    getCanvasTextWidth,
} from '@portal/common/utility/echarts-helpers/get-adopted-title-and-subtitle';
import getTimelineConfig from '@portal/common/utility/echarts-helpers/get-time-slider-config';
import { Entity } from '@portal/common/types';
import _ from '@portal/common/locale';
import { EChartsWrapper } from '@portal/common/components';
import { gifIcon } from '@portal/common/theme/icons';

type RenderProps = ReturnType<TreemapChart['getRenderProps']>;

type Props = {
    hierarchy: object[];

    renderTitle: (props: RenderProps) => string;
    renderSubtitle: (props: RenderProps) => string;
    renderTooltip: (params: any, props: RenderProps) => string;

    data: object[];

    leafDepth: number | null;

    rootName?: string;

    enableTimelineWithFilterKey?: string;
    initialTimelineValue?: string | number;
    onTimelineChange?: (value: number | string) => void;

    saveAsGIF?: {
        visible?: boolean;
        filename?: () => void;
        icon?: string;
        title?: string;
        onClick?: () => void;
    };
    saveAsImage?: {
        visible: boolean;
        filename?: (props: RenderProps) => string;
        icon?: string;
        title?: string;
        onClick?: () => void;
    };

    onNodeSelect?: (id: number) => void;
    selectedNodeId: number | null;
    selectedNodeData: Entity | null;

    isChartPoppedOut: boolean;
    toggleChartPoppedOut: () => void;
    chartHeight: number;

    theme: object;
};

type State = {
    timelineValue: number;
    chartWidth: number;
    timelinePlayState: boolean;
};

// @todo: add mobile view support
// @todo: title font size auto calculation
// @todo: add CSV export
class TreemapChart extends React.PureComponent<Props, State> {
    static defaultProps = {
        data: [],
        hierarchy: [],
        leafDepth: null,
        renderTitle: () => '',
        renderSubtitle: () => '',
        saveAsImage: {
            visible: false,
        },
        saveAsGIF: {
            visible: false,
        },
        initialTimelineValue: 2017,
    };

    state: State = {
        timelineValue: this.props.initialTimelineValue,
        chartWidth: window.innerWidth,
        timelinePlayState: false,
    };

    chartInstance = null;

    onChartReady = (instance: EChartsInstance) => {
        this.chartInstance = instance;
    };

    onChartResize = (width, height) => {
        this.setState({ chartWidth: width });
    };

    isMobileView = () => false;

    handleClick = (params) => {
        // handle only nodes click
        if (!(params.componentType === 'series' && params.data)) {
            return null;
        }

        const { onNodeSelect } = this.props;
        onNodeSelect && onNodeSelect(params.data.id);
    };

    handleTimelineChange = (params) => {
        const timelineOptions = this.getTimelineOptions();
        const timelineValue = timelineOptions[params.currentIndex];
        this.setState({ timelineValue });
        if (this.props.onTimelineChange) {
            this.props.onTimelineChange(timelineValue);
        }

        this.props.onEvents?.timelinechanged?.(params);
    };

    handleTimelinePlayChanged = ({ playState }) => {
        this.setState({ timelinePlayState: playState });
    };

    getTimelineOptions = (): number[] | string[] => {
        const { columns, records, enableTimelineWithFilterKey } = this.props;
        const sliderIndex = columns.indexOf(enableTimelineWithFilterKey);

        return sortedUniq(records.map((record) => record[sliderIndex]).sort()) as
            | number[]
            | string[];
    };

    getRenderProps = () => ({
        filters: this.props.filtersValues,
        timelineValue: this.state.timelineValue,
    });

    getToolboxOption = () => ({
        top: this.isMobileView() ? 20 : 0,
        right: 30,
        z: 10,
        ...this.props.theme.toolbox,
        feature: this.getToolboxFeatures(),
    });

    getToolboxFeatures = () => {
        const features = {
            saveAsImage: { show: false },
        };

        const { saveAsImage, saveAsCSV, saveAsGIF } = this.props;

        if (saveAsGIF?.visible) {
            features.mySaveAsGIF = this.getSaveAsGIFConfig();
        }

        if (saveAsImage?.visible) {
            features.mySaveAsImage = this.getSaveAsImageConfig();
        }

        /*if (saveAsCSV?.visible) {
            features.mySaveAsCSV = this.getSaveAsCSVConfig();
        }*/

        return features;
    };

    getSaveAsGIFConfig = () => {
        const {
            visible,
            filename = () => 'Chart_Animation',
            icon,
            title = _('chart_save_as_gif_button_label'),
            enabled,
            onClick,
        } = this.props.saveAsGIF;

        if (!(visible && this.getTimelineOptions()?.length > 1)) {
            return { visible: false };
        }

        return {
            icon: icon || gifIcon,
            title: enabled ? title : '',
            onclick: (...params) => {
                enabled && onClick?.(...params);
            },
            ...(!enabled && {
                iconStyle: {
                    color: '#A2A3A4',
                    emphasis: {
                        color: '#A2A3A4',
                    },
                },
            }),
        };
    };

    // @todo: move into echarts-helpers
    getSaveAsImageConfig = () => {
        const {
            visible,
            enabled,
            filename = (renderProps?) => 'Chart_ScreenShot',
            icon,
            title = _('chart_save_as_image_button_label'),
            onClick,
        } = this.props.saveAsImage;

        if (!visible) {
            return undefined;
        }

        const config = {
            excludeComponents: ['toolbox', 'graphic'],
            pixelRatio: 2,
            title,
            name: filename(this.getRenderProps()),
            onclick: function (...params) {
                if (!enabled) {
                    return;
                }
                SaveAsImage.prototype.onclick.call(this, ...params);
                onClick && onClick();
            },
            ...(!enabled && {
                iconStyle: {
                    color: '#A2A3A4',
                    emphasis: {
                        color: '#A2A3A4',
                    },
                },
            }),
        };

        return icon ? { ...config, icon } : config;
    };

    addSaveAsImageOverlap = (): object | null => {
        const { visible, enabled } = this.props.saveAsImage;

        return visible && !enabled
            ? {
                  type: 'rect',
                  cursor: 'default',
                  z: 1000,
                  top: 20,
                  right: 30,
                  shape: {
                      width: 30,
                      height: 40,
                  },
                  style: {
                      fill: 'rgba(100,100,100,0)',
                  },
              }
            : null;
    };

    getTimelineConfig = () => {
        const { enableTimelineWithFilterKey } = this.props;
        const { chartWidth, timelineValue, timelinePlayState } = this.state;

        return {
            ...getTimelineConfig(
                enableTimelineWithFilterKey,
                timelineValue,
                this.getTimelineOptions(),
                this.props.theme,
                chartWidth,
                _
            ),
            autoPlay: timelinePlayState,
        };
    };

    generateFooterBackground = () => ({
        type: 'group',
        z: 1,
        left: 0,
        bottom: 74,
        children: [
            // background
            {
                type: 'rect',
                cursor: 'default',
                shape: {
                    width: this.state.chartWidth,
                    height: 36,
                },
                style: {
                    fill: '#F0F2F1',
                },
            },
            ...this.generateBreadcrumb(),
            {
                type: 'image',
                cursor: 'pointer',
                style: {
                    image: this.props.isChartPoppedOut
                        ? '/icons/ic-pop-in.svg'
                        : '/icons/ic-pop-out.svg',
                    x: this.state.chartWidth - 28,
                    y: 8,
                    width: 16,
                    height: 16,
                },
                z: 1000,
                onclick: (...params) => {
                    this.props.toggleChartPoppedOut();
                },
            },
        ],
    });

    generateBreadcrumb = (): object => {
        const path = this.getPathToSelectedNode();
        const nameWidths = path.map((node): number => {
            const canvasElement = document.getElementById('canvasFontSizer');
            if (!canvasElement) {
                return 150;
            }
            const context = canvasElement.getContext('2d');
            return Math.ceil(getCanvasTextWidth(node.name, context, this.props.theme.textStyle));
        });

        const textPadding = 8;
        const chevronWidth = 8;
        const gap = 5;
        let xOffset = gap;
        return path.map((node, idx) => {
            const isRoot = idx === 0;
            const nameWidth = nameWidths[idx];
            const backgroundWidth = nameWidth + 2 * textPadding + (isRoot ? 0 : chevronWidth);
            const breadcrumbGraphicElement = {
                type: 'group',
                left: xOffset,
                top: 4,
                info: node,
                children: [
                    {
                        type: 'polygon',
                        style: {
                            fill: isRoot ? '#DFE0E0' : node.itemStyle.color,
                        },
                        shape: {
                            points: [
                                [0, 0],
                                [backgroundWidth, 0],
                                [backgroundWidth + chevronWidth, 12],
                                [backgroundWidth, 24],
                                [0, 24],
                                ...(isRoot
                                    ? [[0, 0]]
                                    : [
                                          [chevronWidth, 12],
                                          [0, 0],
                                      ]),
                            ],
                        },
                    },
                    {
                        type: 'text',
                        left: isRoot ? textPadding : chevronWidth + textPadding,
                        top: 6,
                        style: {
                            ...this.props.theme.textStyle,
                            text: node.name,
                            fill: isRoot ? '#000' : '#fff',
                        },
                    },
                ],
                onclick: (...params) => {
                    const { onNodeSelect, selectedNodeId } = this.props;
                    const { id } = node;

                    if (onNodeSelect && selectedNodeId !== id) {
                        onNodeSelect(id);
                    }
                },
            };

            xOffset += backgroundWidth + gap;

            return breadcrumbGraphicElement;
        });
    };

    getPathToSelectedNode = (): object[] => {
        const { data, selectedNodeData } = this.props;

        //@todo: get current time unit value data
        const currentTimeUnitData = data[data.length - 1];

        if (!selectedNodeData || !data) {
            return [];
        }

        const { level } = selectedNodeData;

        const result = [];

        let currentLevelLeafs = currentTimeUnitData;
        for (let i = 0; i < level + 1; i++) {
            const leaf = (currentLevelLeafs || []).find(
                (item) =>
                    item.id === selectedNodeData[`level${i}_parent_id`] ||
                    item.id === selectedNodeData.id
            );
            if (leaf) {
                result.push(leaf);
                currentLevelLeafs = leaf.children;
            }
        }

        return result;
    };

    getTitleOption = (data, isLoadingData): object => [
        getTitleAndSubtitleAdjustedToWidth(
            this.props.renderTitle(this.getRenderProps()),
            this.props.renderSubtitle(this.getRenderProps()),
            this.state.chartWidth,
            document.getElementById('canvasFontSizer'),
            this.props.theme.title
        ),
        {
            show: !(isLoadingData || data.length),
            text: _('chart_error_no_data'),
            ...this.props.theme.title,
            top: '45%',
            textStyle: {
                fontSize: 28,
                fontWeight: 700,
            },
        },
    ];

    getChartEvents = () => ({
        ...this.props.onEvents,
        timelinechanged: this.handleTimelineChange,
        timelineplaychanged: this.handleTimelinePlayChanged,
        click: this.handleClick,
    });

    getEChartsOption = () => {
        const { isLoadingData, renderTooltip, rootName, data, theme } = this.props;

        const options = data.map((optionData) => ({
            series: [
                {
                    leafDepth: 6,
                    name: rootName,
                    ...theme.treemap,
                    data: optionData,
                },
            ],
        }));

        return {
            baseOption: {
                textStyle: theme.treemap.textStyle,
                title: this.getTitleOption(data, isLoadingData),
                tooltip: {
                    show: true,
                    ...theme.tooltip,
                    formatter: renderTooltip,
                },
                toolbox: this.getToolboxOption(),
                timeline: this.getTimelineConfig(),
                graphic: {
                    elements: [this.generateFooterBackground(), this.addSaveAsImageOverlap()],
                },
            },
            options,
        };
    };

    render() {
        const { isLoadingData, isLoadingFilters, chartHeight, theme } = this.props;

        return (
            // chart-wrapper is required for resize in PDF export
            <div id="chart-wrapper">
                <EChartsWrapper
                    onChartReady={this.onChartReady}
                    onChartResize={this.onChartResize}
                    onEvents={this.getChartEvents()}
                    option={this.getEChartsOption()}
                    showLoading={isLoadingData || isLoadingFilters}
                    loadingOption={theme.loading}
                    style={{ height: chartHeight, width: '100%' }}
                    theme={theme}
                />
            </div>
        );
    }
}

export default TreemapChart;
