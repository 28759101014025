import React from 'react';

import { CovariateRecords } from '@portal/common/models/data-type';
import {
    AGE_GROUP_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    PRIMARY_ENTITY_ID_KEY,
    ROUND_ID_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import { DataCollectionResource } from '@portal/common/types';
import { Link } from '@portal/common/components';

import config from '../../../config';
import _ from '../../../locale';

import PlaceOfMetricBlock from '../components/PlaceOfMetricBlock';

type Props = {
    locationId: number;
    organizationId: number;
    dataCollection: DataCollectionResource;
};

const LinkToHAQ = ({ children, ...props }) => (
    <Link target="_blank" href="https://www.healthdata.org/results/country-profiles/haq" {...props}>
        {children}
    </Link>
);

const HAQIndexBlock = ({ locationId, organizationId, dataCollection }: Props) => (
    <PlaceOfMetricBlock
        conditions={[
            {
                data_type: CovariateRecords,
                [PRIMARY_ENTITY_ID_KEY]: config.HQAIndexCovariateId,
            },
        ]}
        filters={{
            [LOCATION_ID_KEY]: config.countries.split(',').map(Number),
            [AGE_GROUP_ID_KEY]: [config.allAgesGroupId],
            [GENDER_ID_KEY]: [config.bothGendersId],
            [YEAR_KEY]: [2019],
            [ROUND_ID_KEY]: [config.gbd2019RoundId],
        }}
        label={_('simulation_tool_haq_metric')({ Link: LinkToHAQ })}
        positionByKeyValuePair={[LOCATION_ID_KEY, locationId]}
    />
);

export default HAQIndexBlock;
