import React, { useEffect, useState } from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import { groupBy, isEmpty } from 'lodash/fp';

import { BodyText2, SectionBody, Small } from '@portal/common/components';
import { localizeConditions } from '@portal/common/utility/chart-data-helpers';
import { styled } from '@portal/common/theme';

import {
    AGE_GROUP_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    METRIC_ID_KEY,
    VALUE_KEY,
} from '@portal/common/models/data-key';
import formatPdfValue from '@portal/common/utility/formatting/formatPdfValue';
import { ProjectedComorbidityCausesOutcomeRecords } from '@portal/common/models/data-type';

import api from '../../api';
import {
    getMergedDataCollectionGranularity,
    getFiltersWithConditions,
} from '../../utility/data-loader';
import {
    getDataResponsesByType,
    getSamplePopulationSize,
    getSelectedConditions,
    getSelectedDataType,
    getSelectedRefinementFilters,
} from '../../store/data-explorer/selectors';
import { getSelectedDataCollectionForDataTool } from '../../store/user-settings/selectors';
import { getOrganizationId } from '../../store/root-reducer';

import config from '../../config';
import _ from '../../locale';

const StyledSectionBody = styled(SectionBody)(({ theme }) => ({
    padding: 15,
    [theme.breakpoint.md]: {
        padding: 30,
    },
    '>span:not(:last-child)': {
        paddingBottom: 20,
    },
}));

const mapStateToProps = (state: RootState) => ({
    conditions: getSelectedConditions(state),
    refinementFilters: getSelectedRefinementFilters(state),
    dataType: getSelectedDataType(state),
    organizationId: getOrganizationId(state),
    dataCollection: getSelectedDataCollectionForDataTool(state),
    dataResponsesByType: getDataResponsesByType(state),
    samplePopulationSize: getSamplePopulationSize(state),
    ageGroupEntities: state.dataExplorer.ageGroups,
});

export interface Props extends ReturnType<typeof mapStateToProps> {}

export function ComorbidityStudyCoefficientsSummarySection(props: Props) {
    const {
        dataType,
        conditions,
        refinementFilters,
        organizationId,
        dataCollection,
        dataResponsesByType,
    } = props;

    if (
        !(
            dataResponsesByType &&
            dataCollection &&
            conditions &&
            conditions.length &&
            refinementFilters
        )
    ) {
        return null;
    }

    const { comorbid_cause_id, location_id } = refinementFilters;

    const [data, setData] = useState([]);

    useEffect(() => {
        const granularity = getMergedDataCollectionGranularity(dataCollection, [dataType]);

        api.data
            .queryDataCollectionResourceData(
                organizationId,
                dataCollection.id,
                dataType,
                getFiltersWithConditions(
                    conditions,
                    {
                        ...refinementFilters,
                        [AGE_GROUP_ID_KEY]: [config.allAgesGroupId],
                        [GENDER_ID_KEY]: [config.bothGendersId],
                        [METRIC_ID_KEY]: [config.rateMetricId],
                    },
                    Object.keys(granularity)
                )
            )
            .then((response) => {
                if (!(response && response[ProjectedComorbidityCausesOutcomeRecords])) {
                    return;
                }

                const { columns, records } = response[ProjectedComorbidityCausesOutcomeRecords];

                const valueIdx = columns.indexOf(VALUE_KEY);
                const locationIdx = columns.indexOf(LOCATION_ID_KEY);

                const recordsByLocation = groupBy((record) => record[locationIdx], records);

                const data = [];
                Object.entries(recordsByLocation).forEach(([locationId, locationRecords]) => {
                    data.push({
                        locationId,
                        value: locationRecords[0][valueIdx],
                    });
                });

                setData(data);
            })
            .catch(console.log);
    }, [location_id?.toString(), comorbid_cause_id?.toString(), localizeConditions(conditions)]);

    if (isEmpty(data)) {
        return null;
    }

    const primaryCondition = localizeConditions(conditions);
    const comorbidCondition = _(`comorbid_cause_${comorbid_cause_id}`);

    return (
        <StyledSectionBody>
            {data.map(({ locationId, value }) => (
                <BodyText2 key={locationId}>
                    {_('comorbidity_study_coefficients_summary', {
                        primaryCondition,
                        comorbidCondition,
                        location: _(`location_${locationId}`),
                        value: formatPdfValue(value),
                    })}
                </BodyText2>
            ))}
            <Small>{_('comorbidity_summary_annotation')}</Small>
        </StyledSectionBody>
    );
}

export default connect(mapStateToProps, null)(ComorbidityStudyCoefficientsSummarySection);
