import React from 'react';

import { Button, Tooltip } from '@portal/common/components';

import config from '../../config';
import _ from '../../locale';

type Props = {
    disabled?: boolean;
    onClick?: () => void;
    dataRecordsAmount?: number;
};

const ExportPDFButton = (props: Props) => {
    const { onClick, disabled, dataRecordsAmount } = props;

    const hasRecords = !!dataRecordsAmount;
    const canHandleExport = dataRecordsAmount && dataRecordsAmount < config.PDFRowsExportLimit;

    const buttonComponent = (
        <Button disabled={disabled || !hasRecords || !canHandleExport} onClick={onClick}>
            {_.get('export_to_pdf')}
        </Button>
    );

    return !hasRecords || canHandleExport ? (
        buttonComponent
    ) : (
        <Tooltip placement="bottom" title={_('export_pdf_button_disabled_tooltip')}>
            {buttonComponent}
        </Tooltip>
    );
};

export default ExportPDFButton;
