import React from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Field, withFormik } from 'formik';
import { from, Subject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import * as yup from 'yup';

import { withGoogleAnalytics } from '@ihme/common/packages/google-analytics';

import { OrganizationMember } from '@portal/common/types';
import {
    Button,
    FormikInput,
    Alert,
    FlexRow,
    FlexColumn,
    FormikUploadAvatarInput,
    Loading,
} from '@portal/common/components';
import { AccountAvatarType } from '@portal/common/components/Avatar';
import { styled } from '@portal/common/theme';

import api from '../../api';

import {
    getSessionAccountId,
    getOrganizationId,
    getSessionAccount,
} from '../../store/root-reducer';

import _ from '../../locale';
import { updateAccount } from '../../store/session/actions';

const StyledFlexRow = styled(FlexRow)(({ theme }) => ({
    flexWrap: 'wrap',
    '> :not(:last-child)': {
        marginRight: 50,
    },
}));

const StyledField = styled(Field)(({ theme }) => ({
    flex: '1 1',
    width: 300,
}));

const mapStateToProps = (store) => ({
    accountId: getSessionAccountId(store),
    organizationId: getOrganizationId(store),
    sessionAccount: getSessionAccount(store),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ updateAccount }, dispatch);

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        history: any;
    };

type State = {
    data: OrganizationMember | null;
    errorMessage: string | null;
};

class GeneralTab extends React.PureComponent<Props, State> {
    readonly state: State = {
        data: null,
        errorMessage: null,
    };

    updateAccountAvatarClick$ = new Subject<{ url; handleSuccess; handleError }>();

    updateAccountSubscription = this.updateAccountAvatarClick$
        .pipe(
            switchMap(({ url, handleSuccess, handleError }) =>
                from(
                    this.props.updateAccount(this.props.organizationId, this.props.accountId, {
                        avatar_url: url,
                    })
                ).pipe(map(({ avatar_url }) => ({ avatar_url, handleSuccess })))
            )
        )
        .subscribe(
            ({ handleSuccess, avatar_url }) => {
                this.setState(
                    (state) => ({
                        data: { ...state.data, avatar_url },
                    }),
                    handleSuccess
                );
            },
            (error) => {
                this.setState({
                    errorMessage: error.message,
                });
            }
        );

    componentWillUnmount() {
        this.updateAccountSubscription.unsubscribe();
    }

    isLoaded = () => this.state.data !== null;

    render() {
        const { accountId, organizationId, sessionAccount } = this.props;
        const { errorMessage } = this.state;
        if (accountId == null) {
            return <Loading />;
        }

        return (
            <React.Fragment>
                {errorMessage && <Alert color="error">{errorMessage}</Alert>}

                {sessionAccount != null ? (
                    <ProfileForm
                        data={sessionAccount}
                        updateData={(data) =>
                            this.props.updateAccount(organizationId, accountId, data)
                        }
                        updateAvatar={({ url, handleSuccess, handleError }) =>
                            this.updateAccountAvatarClick$.next({
                                url,
                                handleSuccess,
                                handleError,
                            })
                        }
                    />
                ) : (
                    <Loading />
                )}
            </React.Fragment>
        );
    }
}

type FormValues = Pick<
    OrganizationMember,
    'avatar_url' | 'first_name' | 'last_name' | 'email' | 'title'
> & {};

type ProfileFormProps = {
    data: OrganizationMember;
    updateDataModel;
    updateData: (data: Partial<OrganizationMember>) => Promise<OrganizationMember>;
    updateAvatar: (...args) => void;
};

const mapNameToLabel = (name: string) => _.get(('profile_' + name) as any);

const ProfileForm = withFormik<ProfileFormProps, FormValues>({
    enableReinitialize: true,
    // initialize values
    mapPropsToValues: ({ data: { avatar_url, first_name, last_name, email, title } }) => ({
        avatar_url,
        first_name,
        last_name,
        email,
        title,
    }),
    validationSchema: yup.object().shape({
        first_name: yup.string().trim().required("This field can't be empty"),
        last_name: yup.string().trim().required("This field can't be empty"),
        email: yup.string().email().required("This field can't be empty"),
    }),
    handleSubmit: (values, form) => {
        form.props
            .updateData(values)
            .then((data) => {
                form.setStatus({ success: 'Saved successfully!' });
                form.setSubmitting(false);
            })
            .catch((err) => {
                form.setStatus({ error: err.message });
                form.setSubmitting(false);
                form.setErrors(err.fields);
            });
        form.setSubmitting(false);
    },
})((props) => {
    if (props.data == null) return null;

    const { isSubmitting, dirty, status } = props;

    return (
        <React.Fragment>
            {status?.error && <Alert color="error">{status.error}</Alert>}
            {status?.success && <Alert color="success">{status.success}</Alert>}

            <Form>
                <FlexRow style={{ padding: 50, flexWrap: 'wrap' }}>
                    <FlexColumn align="center" style={{ paddingRight: 80 }}>
                        <Field
                            name="avatar_url"
                            type={AccountAvatarType}
                            component={FormikUploadAvatarInput}
                            mapNameToLabel={mapNameToLabel}
                            uploadFileApiRequest={api.uploadFile}
                            getUploadedFilePreviewUrl={api.getUploadedFilePreviewUrl}
                            required
                            data-private
                        />
                    </FlexColumn>
                    <FlexColumn>
                        <StyledFlexRow>
                            <StyledField
                                name="first_name"
                                component={FormikInput}
                                mapNameToLabel={mapNameToLabel}
                                type="text"
                                required
                                autoFocus
                                data-private
                            />
                            <StyledField
                                name="last_name"
                                component={FormikInput}
                                mapNameToLabel={mapNameToLabel}
                                type="text"
                                required
                                data-private
                            />
                        </StyledFlexRow>

                        <StyledFlexRow>
                            <StyledField
                                name="email"
                                component={FormikInput}
                                mapNameToLabel={mapNameToLabel}
                                type="email"
                                required
                                data-private
                            />
                            <FlexColumn>
                                <StyledField
                                    name="title"
                                    component={FormikInput}
                                    mapNameToLabel={mapNameToLabel}
                                    type="text"
                                />
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={!dirty || isSubmitting}
                                >
                                    {_.get('profile_save_changes')}
                                </Button>
                            </FlexColumn>
                        </StyledFlexRow>
                    </FlexColumn>
                </FlexRow>
            </Form>
        </React.Fragment>
    );
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withGoogleAnalytics({
        trackMethods: (props) => ({
            saveAvatar: (account) =>
                props.trackEvent({
                    category: 'Account',
                    action: 'Update Profile Picture',
                }),
            saveData: (account) =>
                props.trackEvent({
                    category: 'Account',
                    action: 'Update Profile Information',
                }),
        }),
    })
)(GeneralTab);
