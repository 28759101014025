import { useEffect, useState } from 'react';
import { isEmpty, sortBy } from 'lodash/fp';

import { VALUE_KEY } from '@portal/common/models/data-key';
import { DataResponse } from '@portal/common/types';

import useGetApiData, { GetAPiDataProps } from './use-get-api-data';

export default ({ conditions, filters }: GetAPiDataProps) => {
    const [value, setValue] = useState<DataResponse | null>(null);

    const { isLoading, dataResponse, error } = useGetApiData({
        conditions,
        filters,
    });

    useEffect(() => {
        if (isLoading) {
            setValue(null);
        } else if (dataResponse) {
            const { columns, records } = dataResponse;
            const valueIdx = columns.indexOf(VALUE_KEY);
            if (!isEmpty(records)) {
                const maxValueRecord = sortBy((record) => record[valueIdx], records).reverse()[0];
                setValue({
                    columns,
                    records: [maxValueRecord],
                });
            }
        }
    }, [isLoading, dataResponse]);

    return { isLoading, value, error };
};
