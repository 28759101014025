import React from 'react';

import { DataCondition, DataFilters } from '@portal/common/types';
import formatPdfValue from '@portal/common/utility/formatting/formatPdfValue';

import useGetApiDataSingleValue from '../hooks/use-get-api-data-single-value';

import MetricBlock from './MetricBlock';

type Props = {
    conditions: DataCondition[];
    filters: DataFilters;
    label: string;
    valueFormatter?: (value: string | number | null) => string;
};

const SingleValueMetricBlock = ({
    conditions,
    filters,
    label,
    valueFormatter = formatPdfValue,
}: Props) => {
    const { isLoading, value } = useGetApiDataSingleValue({
        conditions,
        filters,
    });

    return <MetricBlock isLoading={isLoading} value={valueFormatter(value)} label={label} />;
};

export default SingleValueMetricBlock;
