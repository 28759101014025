import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from 'MyTypes';
import { isEqual, uniq } from 'lodash';
import { isEmpty } from 'lodash/fp';

import Loading from '@ihme/common/components/Loading';
import { WithStorageProps } from '@ihme/common/packages/storage';
import { Button, FlexColumn, FlexRow } from '@portal/common/components';
import { AllDataTypes } from '@portal/common/models/data-type';
import { styled } from '@portal/common/theme';
import { DataCondition, DataType } from '@portal/common/types';
import { DataProjectId } from '@portal/common/models/data-project';

import { getDataCollections } from '../../store/data-explorer/selectors';
import { getSelectedDataCollectionForDataTool } from '../../store/user-settings/selectors';
import { getSelectedDataTool } from '../../store/root-reducer';
import { getDataCollectionDataTypes } from '../../utility/data-loader';
import { getDataToolForDataProject } from '../../models/data-tool';

import _ from '../../locale';

import ConditionDropdown from '../../components/ConditionDropdown';
import ConditionTypeDropdown from '../../components/ConditionTypeDropdown';
import DataCollectionDropdown from '../../components/DataCollectionDropdown';
import NoSharedCollections from '../../components/NoSharedCollections';
import ConditionSmartOptionSelector from '../../components/ConditionSmartOptionSelector';

const ConditionAndTypeWrapper = styled(FlexRow)({
    '>:first-child': {
        minWidth: 212,
        flexGrow: 1,
    },
});

const mapStateToProps = (state: RootState) => ({
    dataCollections: getDataCollections(state),
    selectedDataCollection: getSelectedDataCollectionForDataTool(state),
    dataTool: getSelectedDataTool(state),
});

type Props = ReturnType<typeof mapStateToProps> & WithStorageProps & RouteComponentProps & {};

type State = {
    selectedDataType: null | DataType;
    selectedConditions: DataCondition[];
};

class DataExplorerWidget extends React.Component<Props, State> {
    state: State = {
        selectedDataType: null,
        selectedConditions: [],
    };

    componentDidMount() {
        const { selectedDataCollection } = this.props;

        if (selectedDataCollection) {
            this.initialize();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const { selectedDataCollection } = this.props;

        if (
            selectedDataCollection != null &&
            !isEqual(selectedDataCollection, prevProps.selectedDataCollection)
        ) {
            this.initialize();
        }
    }

    initialize = () => {
        const { selectedDataCollection } = this.props;

        if (!selectedDataCollection) {
            return;
        }

        const availableDataTypes = getDataCollectionDataTypes(selectedDataCollection);
        const defaultDataType = availableDataTypes.includes(AllDataTypes)
            ? AllDataTypes
            : availableDataTypes[0];

        this.setState({
            selectedDataType: defaultDataType,
            selectedConditions: [],
        });
    };

    handleDataTypeChange = (value: DataType): void => {
        this.setState({ selectedDataType: value, selectedConditions: [] });
    };

    handleConditionsChange = (conditions: DataCondition[]): void => {
        this.setState(() => {
            return { selectedConditions: conditions };
        });
    };

    handleExploreClick = () => {
        const { history, selectedDataCollection } = this.props;
        const { selectedDataType, selectedConditions } = this.state;

        const dataTool = getDataToolForDataProject(selectedDataCollection.data_project_id);

        history.push({
            pathname: dataTool,
            state: {
                dataLoaded: true,
                dataCollectionId: selectedDataCollection.id,
                conditions: selectedConditions,
                dataType: selectedDataType,
            },
        });
    };

    handleSmartOptionSelect = (conditions: DataCondition[]): void => {
        this.setState(() => {
            return { selectedConditions: conditions };
        });
    };

    renderDataSelectionControls = () => {
        const { dataCollections, selectedDataCollection } = this.props;
        const { selectedDataType, selectedConditions } = this.state;

        if (!(dataCollections?.length && selectedDataCollection && selectedDataType)) {
            return null;
        }

        const filteredDatasets = selectedDataCollection.datasets.filter(
            (i) => selectedDataType === AllDataTypes || selectedDataType === i.data_type
        );

        const availableDataTypes = getDataCollectionDataTypes(selectedDataCollection);
        const selectedConditionsDataTypes = uniq(selectedConditions.map((c) => c.data_type));

        const dataProjectId = selectedDataCollection.data_project_id;
        const isCovidDataCollection = dataProjectId === DataProjectId.COVID19;
        const isEdpDataCollection = dataProjectId === DataProjectId.EDP;
        const isComorbidityDataCollection = dataProjectId === DataProjectId.COMORBIDITY_STUDY;
        const isSimulationDataCollection = dataProjectId === DataProjectId.SIMULATION;
        const isHealthThreatMetricsCollection =
            dataProjectId === DataProjectId.HEALTH_THREAT_METRICS;

        const isExploreButtonDisabled =
            isEmpty(selectedConditions) &&
            !isCovidDataCollection &&
            !isEdpDataCollection &&
            !isSimulationDataCollection &&
            !isHealthThreatMetricsCollection;

        const isConditionMultiSelectEnabled = !isComorbidityDataCollection;

        const isConditionSelectorHidden =
            isCovidDataCollection || isHealthThreatMetricsCollection || isSimulationDataCollection;

        return (
            <FlexColumn itemsSpacing={15}>
                {!isConditionSelectorHidden && (
                    <>
                        <ConditionAndTypeWrapper itemsSpacing={15}>
                            <ConditionDropdown
                                key={
                                    selectedDataCollection.id +
                                    filteredDatasets.map((i) => i.data_type).toString()
                                }
                                selectedDataCollection={selectedDataCollection}
                                isMulti={isConditionMultiSelectEnabled}
                                isSearchable={isConditionMultiSelectEnabled}
                                datasets={filteredDatasets}
                                onChange={this.handleConditionsChange}
                                onMultiSelect={
                                    isConditionMultiSelectEnabled && this.handleConditionsChange
                                }
                                value={selectedConditions}
                                showHint={!isEmpty(selectedConditionsDataTypes)}
                                renderMenuHeader={() => {}}
                                placeholder={_(
                                    'data_explorer_widget_condition_dropdown_placeholder'
                                )}
                            />
                            <ConditionTypeDropdown
                                options={availableDataTypes}
                                value={selectedDataType}
                                onChange={this.handleDataTypeChange}
                                menuPlacement="bottom-end"
                            />
                        </ConditionAndTypeWrapper>
                        <ConditionSmartOptionSelector
                            onSelect={this.handleSmartOptionSelect}
                            style={{ marginTop: -15 }}
                            dataType={selectedDataType}
                        />
                    </>
                )}
                <Button
                    disabled={isExploreButtonDisabled}
                    color="primary"
                    style={{ width: '100%' }}
                    onClick={this.handleExploreClick}
                >
                    {_('data_explorer_widget_explore')}
                </Button>
            </FlexColumn>
        );
    };

    render() {
        const { dataCollections } = this.props;

        if (!dataCollections) {
            return <Loading />;
        }

        if (isEmpty(dataCollections)) {
            return <NoSharedCollections />;
        }

        return (
            <>
                <p>{_('data_explorer_widget_description')}</p>
                <DataCollectionDropdown hideSingleOption={false} />
                <FlexColumn>{this.renderDataSelectionControls()}</FlexColumn>
            </>
        );
    }
}

export default withRouter(connect(mapStateToProps, null)(DataExplorerWidget));
