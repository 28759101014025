import React from 'react';

import { withGoogleAnalytics } from '@ihme/common/packages/google-analytics';
import { OrganizationMember } from '@portal/common/types';
import { parseApiError } from '@portal/common/utility/api-helpers';

import api from '../../api';
// @todo: delete this component as we have the same in common
import EditOrganizationMemberView from './EditOrganizationMemberView';

type Props = {
    member: OrganizationMember;
    organizationId: number;
    isSelfEdit: boolean;
    isVisible: boolean;
    onClose?: () => void;
    onSuccess: () => void;
};

type State = {
    data: OrganizationMember;
    errors: object | null;
};

const EMPTY_DATA = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    old_password: '',
    title: '',
    organization_role: 'member',
    has_programmatic_access: false,
};

class EditOrganizationMemberModal extends React.PureComponent<Props, State> {
    static defaultProps = {
        isVisible: false,
        isSelfEdit: false,
        onSuccess: () => {},
    };

    state: State = {
        data: this.props.member || EMPTY_DATA,
        errors: null,
    };

    isNew = () => !this.state.data.id;

    clear = () =>
        this.setState({
            data: EMPTY_DATA,
            errors: null,
        });

    onGeneratePassword = (formData) => {
        api.passwords
            .generate()
            .then((password) =>
                this.setState(({ data }) => ({
                    data: { ...data, ...formData, password },
                }))
            )
            .catch(this.onError);
    };

    onError = (err) => {
        this.setState({
            errors: parseApiError(err),
        });
    };

    onClose = () => {
        this.clear();
        this.props.onClose();
    };

    render() {
        const { organizationId, onSuccess, isVisible, isSelfEdit } = this.props;

        const { data, errors } = this.state;

        return (
            <EditOrganizationMemberView
                organizationId={organizationId}
                data={data}
                isNew={this.isNew()}
                isSelfEdit={isSelfEdit}
                onClose={this.onClose}
                onGeneratePassword={this.onGeneratePassword}
                isVisible={isVisible}
                addOrganizationMember={api.organization.addOrganizationMember}
                editOrganizationMember={api.organization.setOrganizationMember}
                handleSuccess={onSuccess}
                errors={errors}
            />
        );
    }
}

export default withGoogleAnalytics({
    trackMethods: (props) => ({
        onSubmit: (e) => {
            if (props.member == null) {
                props.trackEvent({
                    category: 'Organization',
                    action: 'Invite Member',
                });
            } else {
                props.trackEvent({
                    category: 'Organization',
                    action: 'Edit Member',
                });
            }
        },
    }),
})(EditOrganizationMemberModal);
