import React from 'react';
import { useSelector } from 'react-redux';

import { LOCATION_ID_KEY } from '@portal/common/models/data-key';
import { SectionBody } from '@portal/common/components';
import { styled } from '@portal/common/theme';

import {
    getDataToolConfig,
    getSelectedRefinementFilters,
} from '../../../store/data-explorer/selectors';
import { getOrganizationId, getSelectedDataTool } from '../../../store/root-reducer';
import { getSelectedDataCollectionForDataTool } from '../../../store/user-settings/selectors';

import _ from '../../../locale';

import Header from '../components/Header';
import Title from '../components/Title';

import PopulationMetrics from './PopulationMetrics';
import PopulationCharacteristics from './PopulationCharacteristics';
import MortalityRateProjectionsLineChart from './MortalityRateProjectionsLineChart';

const DataContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    [theme.media.laptop]: {
        flexDirection: 'row',
        gap: 30,
    },
}));

const HealthBackgroundSection = () => {
    const organizationId = useSelector(getOrganizationId);
    const dataCollection = useSelector(getSelectedDataCollectionForDataTool);
    const selectedRefinementFilters = useSelector(getSelectedRefinementFilters);
    const dataToolConfig = useSelector(getDataToolConfig);
    const dataTool = useSelector(getSelectedDataTool);

    if (!selectedRefinementFilters?.[LOCATION_ID_KEY]?.length) {
        return null;
    }

    const locationId = parseInt(selectedRefinementFilters?.[LOCATION_ID_KEY]?.[0]);
    const locationName = _(`location_${locationId}`);

    return (
        <SectionBody isCollapsible isCollapsedInitially>
            <Header>{_('simulation_tool_1_header', { location: locationName })}</Header>

            <Title>{_('simulation_tool_1_1_title', { location: locationName })}</Title>

            <DataContainer>
                <PopulationMetrics
                    locationId={locationId}
                    organizationId={organizationId}
                    dataCollection={dataCollection}
                />
                <MortalityRateProjectionsLineChart
                    locationId={locationId}
                    dataCollection={dataCollection}
                    dataToolConfig={dataToolConfig}
                    dataTool={dataTool}
                />
            </DataContainer>

            <PopulationCharacteristics locationId={locationId} dataCollection={dataCollection} />
        </SectionBody>
    );
};

export default HealthBackgroundSection;
