import * as React from 'react';
import { FC, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { convertArrayToCSV } from 'convert-array-to-csv';
import FileSaver from 'file-saver';
import { isEmpty, sortBy } from 'lodash/fp';

import { withStorage } from '@ihme/common/packages/storage';

import { styled } from '@portal/common/theme';
import {
    FieldLabel,
    RangeSlider,
    FormGroup,
    Loading,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    FlexColumn,
    FlexRow,
    Alert,
    FieldHintText,
    RadioSelect,
} from '@portal/common/components';
import { DataFilters, DataGranularity, DataType } from '@portal/common/types';
import { AGE_KEY, METRIC_ID_KEY } from '@portal/common/models/data-key';
import { CauseOutcome } from '@portal/common/models/data-type';
import { localizeColumns, localizeRecords } from '@portal/common/utility/chart-data-helpers';
import renderErrors from '@portal/common/utility/renderErrors';
import formatDateWithFormat from '@portal/common/utility/formatting/formatDateWithFormat';

import api from '../../../api';
import { getSelectedDataCollectionForDataTool } from '../../../store/user-settings/selectors';
import {
    getMergedSingleYearAgeReportGranularity,
    getSingleYearAgeReportGranularity,
} from '../../../store/data-explorer/selectors';

import _ from '../../../locale';
import { SingleAgeReportTypes } from '../../../types';
import { intersectRefinementFiltersWithGranularity } from '../utils';
import { getFiltersSelectionCount } from '../../../utility/data/calculate-combined-filters-amount';
import config from '../../../config';

const Label = styled(FieldLabel)(({ theme }) => ({
    marginLeft: '-12px',
}));

const Hint = styled(FieldHintText)(({ theme }) => ({
    marginBottom: '16px',
    marginLeft: '-12px',
}));

const modalBodyStyle = {
    position: 'relative',
    overflow: 'hidden',
};

export const DATE_FORMAT = 'mm/dd/yyyy';

type Props = {
    isVisible: boolean;
    setVisibility: (isVisible: boolean) => any;
    agesRange: {
        min: number;
        max: number;
    };
    supportedMetrics: number[];
    supportedDataTypes: DataType[];
    selectedFilters: DataFilters;
    selectedConditionsDataTypes: DataType[] | null;
    refinedGranularity: DataGranularity;
};

const calculateFiltersCardinality = (
    filters: DataFilters,
    granularity: DataGranularity,
    ageRange: [min: number, max: number]
): number => {
    const [min, max] = ageRange;
    const ages = max - min + 1;
    return getFiltersSelectionCount(filters, granularity) * ages;
};

const ExportSingleAgeEstimatesModal: FC<Props> = ({
    isVisible,
    setVisibility,
    agesRange,
    refinedGranularity,
    selectedFilters,
    selectedConditionsDataTypes,
    supportedMetrics,
    supportedDataTypes,
}) => {
    const selectedDataCollection = useSelector(getSelectedDataCollectionForDataTool);
    const singleYearAgeGranularity = useSelector(getSingleYearAgeReportGranularity);
    const mergedSingleYearAgeGranularity = useSelector(getMergedSingleYearAgeReportGranularity);

    const [selectedAges, setSelectedAges] = useState([agesRange.min, agesRange.max]);
    const [selectedReportType, setSelectedReportType] = useState<SingleAgeReportTypes>(
        SingleAgeReportTypes.Normal
    );
    const [filtersCardinality, setFiltersCardinality] = useState<number>(0);
    const [inProgress, setInProgress] = useState(false);
    const [errors, setErrors] = useState(null);

    const isSingleAgesReport = selectedReportType === SingleAgeReportTypes.Normal;
    const isCardinalityLimitExceeded = filtersCardinality > config.combinedFiltersAmountLimit;

    const selectedDataTypes = selectedConditionsDataTypes || [];
    const unsupportedDataTypes = selectedDataTypes.filter(
        (type) => !supportedDataTypes.includes(type)
    );
    const isRestrictedDataTypeSelected = !isEmpty(unsupportedDataTypes);

    const selectedMetrics = (selectedFilters?.[METRIC_ID_KEY] as number[]) || [];
    const unsupportedMetrics = selectedMetrics.filter((id) => !supportedMetrics.includes(id));
    const isRestrictedMetricSelected = !isEmpty(unsupportedMetrics);

    const reportGranularity = singleYearAgeGranularity?.[selectedReportType];

    const saveDataAsCsv = useCallback(({ filename, response: { records, columns } }) => {
        const localizedData = {
            columns: localizeColumns(columns, selectedConditionsDataTypes, _),
            records: sortBy((record) => record[0], localizeRecords(columns, records, _)),
        };
        const citation = _('export_file_citation', {
            date: formatDateWithFormat(new Date(), DATE_FORMAT),
        });
        let data = convertArrayToCSV(localizedData.records, { header: localizedData.columns });
        data += `\n${citation}`;
        const name = filename + '.csv';
        const file = new Blob([data], {
            type: 'text/plain',
        });
        FileSaver.saveAs(file, name);
    }, []);

    const getReport = useCallback(
        async (ev) => {
            ev.preventDefault();
            setErrors(null);
            setInProgress(true);
            const ages = {
                age_min: [selectedAges[0]],
                age_max: [selectedAges[1]],
            };

            const queryFn = isSingleAgesReport
                ? api.data.getSingleYearAgeData
                : api.data.getSingleYearAgeAggregatedData;

            try {
                const response = {
                    columns: [],
                    records: [],
                };
                for (const metricId of selectedMetrics) {
                    const refinedFilters = intersectRefinementFiltersWithGranularity(
                        { ...selectedFilters, [METRIC_ID_KEY]: [metricId] },
                        reportGranularity?.[metricId] || {}
                    );

                    const { columns, records } = await queryFn({
                        filters: { ...refinedFilters, ...ages },
                        dataType: CauseOutcome,
                        resourceId: selectedDataCollection?.id,
                    });

                    response.columns = columns;
                    response.records = response.records.concat(records);
                }

                const filename = isSingleAgesReport
                    ? _('export_single_age_file_name')
                    : _('export_custom_age_band_file_name');
                saveDataAsCsv({ filename, response });
                setVisibility(false);
            } catch (e) {
                setErrors(e);
            } finally {
                setInProgress(false);
            }
        },
        [
            selectedAges,
            refinedGranularity,
            selectedFilters,
            selectedDataCollection,
            saveDataAsCsv,
            unsupportedDataTypes,
            selectedReportType,
            supportedMetrics,
        ]
    );

    const handleCloseModal = useCallback(() => {
        setErrors(null);
        setVisibility(false);
    }, [setVisibility]);

    const reset = useCallback(() => {
        setInProgress(false);
    }, []);

    const handleReportTypeChange = useCallback((ev) => {
        setSelectedReportType(ev.target.value);
    }, []);

    useEffect(() => {
        setSelectedAges([agesRange.min, agesRange.max]);
    }, [isVisible]);

    useEffect(() => {
        const cardinality = calculateFiltersCardinality(
            selectedFilters,
            mergedSingleYearAgeGranularity || {},
            selectedAges
        );
        setFiltersCardinality(cardinality);
    }, [selectedFilters, mergedSingleYearAgeGranularity, selectedAges]);

    useEffect(() => {
        setErrors(isCardinalityLimitExceeded ? { message: _('error_limit_exceeded') } : null);
    }, [isCardinalityLimitExceeded]);

    return (
        <Modal show={isVisible} onHide={handleCloseModal} onEnter={reset}>
            <form onSubmit={getReport}>
                <ModalHeader>{_('export_single_age_modal_title')}</ModalHeader>
                <ModalBody css={modalBodyStyle}>
                    {errors && (
                        <FormGroup>
                            <Alert color="error">
                                {errors.fields ? renderErrors(errors.fields) : errors.message}
                            </Alert>
                        </FormGroup>
                    )}
                    {inProgress ? (
                        <FlexColumn justify="center">
                            <FlexRow justify="center">
                                <span>{_('export_single_age_modal_loading_text')}</span>
                            </FlexRow>
                            <Loading />
                        </FlexColumn>
                    ) : (
                        <FormGroup>
                            {isRestrictedDataTypeSelected ? (
                                <Hint>
                                    {_('export_single_age_modal_hint_text', {
                                        unsupported: unsupportedDataTypes.map((dataType) =>
                                            _(`data_type_${dataType}`)
                                        ),
                                        supported: supportedDataTypes.map((dataType) =>
                                            _(`data_type_${dataType}`)
                                        ),
                                    })}
                                </Hint>
                            ) : null}
                            {isRestrictedMetricSelected ? (
                                <Hint>
                                    {_('export_single_age_modal_hint_text', {
                                        unsupported: unsupportedMetrics.map((id) =>
                                            _(`metric_${id}`)
                                        ),
                                        supported: supportedMetrics.map((id) => _(`metric_${id}`)),
                                    })}
                                </Hint>
                            ) : null}
                            <Label>{_('export_single_age_range_slider_title')}</Label>
                            <RangeSlider
                                id={AGE_KEY}
                                min={agesRange.min}
                                max={agesRange.max}
                                marks={
                                    agesRange.max
                                        ? {
                                              [agesRange.min]: `${agesRange.min} years`,
                                              [agesRange.max]: `${agesRange.max} years`,
                                          }
                                        : undefined
                                }
                                value={selectedAges || [agesRange.min, agesRange.max]}
                                onChange={setSelectedAges}
                            />

                            <div style={{ height: 40 }} />

                            <Label>{_('export_single_report_type_selector_label')}</Label>
                            <RadioSelect
                                name="report_type_selector"
                                value={selectedReportType}
                                options={Object.values(SingleAgeReportTypes).map((type) => ({
                                    value: type,
                                    label: _(`export_single_report_type_${type}`),
                                }))}
                                onChange={handleReportTypeChange}
                            />
                        </FormGroup>
                    )}
                </ModalBody>
                {!inProgress && (
                    <ModalFooter>
                        <ModalButton color="secondary" onClick={handleCloseModal}>
                            {_('export_single_age_modal_cancel_label')}
                        </ModalButton>
                        <ModalButton type="submit" disabled={isCardinalityLimitExceeded}>
                            {_('export_single_age_modal_submit_label')}
                        </ModalButton>
                    </ModalFooter>
                )}
            </form>
        </Modal>
    );
};

export default withStorage(ExportSingleAgeEstimatesModal);
