import _ from '../../../locale';

export const decodeCdnRow = ({
    key,
    value,
    isPdf,
}: {
    key: string;
    value: any;
    isPdf?: boolean;
}) => {
    const ALL_AGES = [
        2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 30, 31, 32, 235,
    ];

    let formattedValue;
    let formattedKey;

    switch (key) {
        case 'measure_id':
            formattedValue = value
                .filter((item) => item !== null)
                .map((val) => _('measure_' + val))
                .join(', ');
            formattedKey = _(key);
            break;
        case 'fatal_data_source_link':
        case 'non_fatal_data_source_link':
            formattedValue = value.join(', ');
            formattedKey = _(key);
            break;
        case 'non_fatal_female_age_group_restriction_ids':
        case 'non_fatal_male_age_group_restriction_ids':
        case 'fatal_female_age_group_restriction_ids':
        case 'fatal_male_age_group_restriction_ids':
            if (value == null || value.length === 0) {
                if (isPdf) return null;
                formattedValue = _.get('cdn_none');
            } else if (ALL_AGES.every((id) => value.includes(id))) {
                formattedValue = _.get('cdn_ages_not_modeled');
            } else {
                // localize age_group_id
                formattedValue = value.map((id) => _('age_group_' + id)).join(', ');
            }
            formattedKey = _(key);
            break;

        // localize location_id
        // if value length === 585 => 'All locations not modeled for <field name>'
        case 'non_fatal_location_restriction_ids':
        case 'fatal_location_restriction_ids':
            if (value == null || value.length === 0) {
                if (isPdf) return null;
                formattedValue = _.get('cdn_none');
                // Global location id
            } else if (value.includes(1)) {
                formattedValue = _.get('cdn_locations_not_modeled');
            } else {
                // localize location_id
                formattedValue = value.map((id) => _('location_' + id)).join(', ');
            }
            formattedKey = _(key);
            break;

        case 'non_fatal_icd9_codes':
        case 'non_fatal_icd10_codes':
        case 'fatal_icd9_codes':
        case 'fatal_icd10_codes':
            if (value == null || value.length === 0) {
                if (isPdf) return null;
                formattedValue = _.get('cdn_no_codes_for_modeling');
            } else if (Array.isArray(value)) {
                formattedValue = value.join(', ');
            } else {
                formattedValue = value;
            }
            formattedKey = _(key);
            break;

        default:
            if (value == null || value.length === 0) {
                return null;
            }

            // fields that are different between measures
            if (key.startsWith('case_definition:') || key.startsWith('notes:')) {
                formattedValue = value;
                const [mainKey, suffix] = key.split(':');
                formattedKey = `${_(mainKey)} (${_(suffix)})`;
                break;
            }

            formattedValue = Array.isArray(value)
                ? value.join(',')
                : typeof value === 'boolean' // boolean values should display "Yes" on true and "No" on false
                ? value
                    ? 'Yes'
                    : 'no'
                : value;
            formattedKey = _(key);
            break;
    }

    return { label: formattedKey, value: formattedValue };
};
