import { RootEpic } from 'MyTypes';
import { from, of } from 'rxjs';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { PaginatedResponse, Poll } from '@portal/common/types';
import { deserializeRichText } from '@portal/common/utility/api-helpers';

import { getOrganizationAsync } from '../organization/actions';
import { getPollsAsync } from './actions';

export const loadPolls: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getOrganizationAsync.success])),
        switchMap((action) =>
            from(api.polls.getAll()).pipe(
                map((res: PaginatedResponse<Poll>) =>
                    getPollsAsync.success(res.results.map(deserializeRichText('description')))
                ),
                catchError((message: string) => of(getPollsAsync.failure(message)))
            )
        )
    );
