import React from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';

import { DropdownOption } from '@portal/common/types';
import { AGE_GROUP_ID_KEY } from '@portal/common/models/data-key';

import { getAgeGroupsOrder } from '../../store/data-explorer/selectors';

import ChartDropdown from '../ChartDropdown';

const mapStateToProps = (state: RootState) => ({
    order: getAgeGroupsOrder(state),
});

type Props = ReturnType<typeof mapStateToProps> &
    React.ComponentPropsWithoutRef<typeof ChartDropdown>;

class AgeGroupsDropdown extends React.Component<Props> {
    sortAgeGroups = (options: DropdownOption[]): DropdownOption[] => {
        const { order } = this.props;

        if (order) {
            return options.sort((option1, option2) => order[option1] - order[option2]);
        }

        return options;
    };

    render() {
        return (
            <ChartDropdown
                {...this.props}
                filterKey={AGE_GROUP_ID_KEY}
                preprocessOptions={this.sortAgeGroups}
            />
        );
    }
}

export default connect(mapStateToProps, null)(AgeGroupsDropdown);
