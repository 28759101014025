import {
    LifeExpectancyRecords,
    MultipleMyelomaSurvivalResults,
    CauseOutcome,
    CovariateRecords,
    MultipleMyelomaPopulation,
} from '@portal/common/models/data-type';
import config from '../../../config';

const neoplasmsChildrenCauseIds = [
    config.neoplasmsCauseId,
    ...config.neoplasmsChildrenCauseIds.split(',').map(Number),
];

export const requestPayloadByDataType = {
    [LifeExpectancyRecords]: {
        conditions: [
            {
                data_type: LifeExpectancyRecords,
                primary_entity_id: 1,
            },
        ],
        filters: {
            location_id: [config.USALocationId],
            age_group_id: [config.birthAgeGroupId],
            gender_id: [config.bothGendersId],
            measure_id: [config.lifeExpectancyMeasureId],
            // forecast_scenario_id: [1],
            year: [2019, 2050],
            round_id: [config.gbd2017RoundId],
        },
    },
    [CauseOutcome]: {
        conditions: [
            {
                data_type: CauseOutcome,
                primary_entity_id: config.allCausesCauseId,
            },
            {
                data_type: CauseOutcome,
                primary_entity_id: config.multipleMyelomaCauseId,
            },
            ...neoplasmsChildrenCauseIds.map((id) => ({
                data_type: CauseOutcome,
                primary_entity_id: id,
            })),
        ],
        filters: {
            location_id: [config.USALocationId],
            age_group_id: config.simToolCauseOutcomeAgeGroupIds,
            gender_id: [config.bothGendersId],
            measure_id: [
                config.deathMeasureId,
                config.dalyMeasureId,
                config.prevalenceMeasureId,
                config.yllMeasureId,
                config.incidenceMeasureId,
            ],
            metric_id: [config.numberMetricId, config.rateMetricId],
            year: [2019, 1990, 2050],
            round_id: [config.gbd2019RoundId],
            // forecast_scenario_id: [1],
            cause_id: neoplasmsChildrenCauseIds,
            data_type: CauseOutcome,
        },
    },
    [CovariateRecords]: {
        conditions: [
            {
                data_type: CovariateRecords,
                primary_entity_id: config.HQAIndexCovariateId,
            },
            {
                data_type: CovariateRecords,
                primary_entity_id: config.SDICovariateId,
            },
        ],
        filters: {
            location_id: config.simToolCovariateRecordsLocationIds,
            age_group_id: [config.allAgesGroupId],
            gender_id: [config.bothGendersId],
            year: [2019],
            round_id: [config.gbd2019RoundId],
        },
    },
    [MultipleMyelomaPopulation]: {
        conditions: [
            {
                data_type: MultipleMyelomaPopulation,
                primary_entity_id: 1,
            },
        ],
        filters: {
            location_id: [config.USALocationId],
            age_group_id: [
                config.seventyFiveToNineAgeGroupId,
                config.eightyToEightyFourAgeGroupId,
                config.eightyFiveToEightyNineAgeGroupId,
                config.ninetyToNinetyFourAgeGroupId,
                config.ninetyFiveAndAboveAgeGroupId,
            ],
            gender_id: [config.bothGendersId],
            round_id: [118],
            // forecast_scenario_id: [1],
            year: [2019, 2030, 2050],
        },
    },
    [MultipleMyelomaSurvivalResults]: {
        conditions: [
            {
                data_type: MultipleMyelomaSurvivalResults,
                primary_entity_id: config.newlyDiagnosedMultipleMyelomaCauseId,
            },
            {
                data_type: MultipleMyelomaSurvivalResults,
                primary_entity_id: config.multipleMyelomaInFirstRelapse,
            },
            {
                data_type: MultipleMyelomaSurvivalResults,
                primary_entity_id: config.multipleMyelomaInSecondRelapse,
            },
            {
                data_type: MultipleMyelomaSurvivalResults,
                primary_entity_id: config.multipleMyelomaInThirdRelapse,
            },
            {
                data_type: MultipleMyelomaSurvivalResults,
                primary_entity_id: config.multipleMyelomaInFourthOrHigherRelapse,
            },
        ],
        filters: {
            round_id: [118],
            location_id: [config.USALocationId],
        },
    },
};
