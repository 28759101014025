export const indicatorGroupsMap = {
    // Underlying Risks
    [1]: 1,
    [2]: 1,
    [3]: 1,
    [4]: 1,

    // Vaccines
    [5]: 2,
    [6]: 2,

    // Behavioural Risks
    [7]: 3,
    [8]: 3,
    [9]: 3,
    [10]: 3,
    [11]: 3,
};

export const responseProps = [
    {
        //High risk (pregnant OR current smoker (currently smokes every day or some days) OR at least one underlying condition OR age 65+)',
        id: 101,
        color: '#D73146',
        order: 1,
    },
    {
        id: 100, // 'Not high risk',
        color: '#1FBA59',
        order: 2,
    },
    {
        id: 1099, // 'DTR/Unknown',
        color: '#2B88D8',
        order: 3,
    },

    ////
    {
        id: 201, // 'Current smoker (Every day or some days)',
        color: '#D73146',
        order: 1,
    },
    {
        id: 200, // 'Not current smoker',
        color: '#1FBA59',
        order: 2,
    },
    {
        id: 2099, // 'DTR/Unknown',
        color: '#2B88D8',
        order: 3,
    },

    ///------
    {
        id: 301, // 'Known to have any underlying health condition',
        color: '#D73146',
        order: 1,
    },
    {
        id: 300, // 'No underlying health condition',
        color: '#1FBA59',
        order: 2,
    },
    {
        id: 3099, // 'DTR/Unknown',
        color: '#2B88D8',
        order: 3,
    },

    // ----
    {
        id: 401, // '65+ years of age',
        color: '#D73146',
        order: 1,
    },
    {
        id: 400, // 'Under 65 years of age',
        color: '#1FBA59',
        order: 2,
    },
    {
        id: 4099, // 'DTR/Unknown',
        color: '#2B88D8',
        order: 3,
    },

    // ----
    {
        id: 500, // 'Do not plan to receive flu vaccine this season',
        color: '#D73146',
        order: 1,
    },
    {
        id: 502, // 'Plan to receive flu vaccine this season',
        color: '#FBDD39',
        order: 2,
    },
    {
        id: 501, // 'Received flu vaccine this season',
        color: '#1FBA59',
        order: 3,
    },
    {
        id: 5099, // 'DTR/Unknown',
        color: '#2B88D8',
        order: 4,
    },

    // ----
    {
        id: 600, // 'Definitely do not plan to receive COVID-19 vaccine',
        color: '#D73146',
        order: 1,
    },
    {
        id: 605, // 'Probably do not plan to receive COVID-19 vaccine',
        color: '#F09536',
        order: 2,
    },
    {
        id: 604, // 'Probably plan to receive COVID-19 vaccine',
        color: '#FBDD39',
        order: 3,
    },
    {
        id: 603, // 'Definitely plan to receive COVID-19 vaccine',
        color: '#CBED8C',
        order: 4,
    },
    {
        id: 602, // 'Received partial COVID-19 vaccine doses',
        color: '#1FBA59',
        order: 5,
    },
    {
        id: 601, // 'Received all COVID-19 vaccine doses',
        color: '#178C43',
        order: 6,
    },
    {
        id: 6099, // 'DTR/Unknown',
        color: '#2B88D8',
        order: 7,
    },
    // ----
    {
        id: 700, // Reported taking part in activities other than grocery shopping/visiting pharmacy outside of the home in past 7 days',
        color: '#D73146',
        order: 1,
    },
    {
        id: 701, // Reported only grocery shopping or visiting the pharmacy outside of the home in past 7 days',
        color: '#1FBA59',
        order: 2,
    },
    {
        id: 7088, // 'Question not included in questionnaire (Safe behaviors)',
        color: '#CBED8C',
        order: 3,
    },
    {
        id: 7099, // 'DTR/Unknown',
        color: '#2B88D8',
        order: 4,
    },

    // ----
    {
        id: 800, // 'Did not report practicing all safe behaviors in past 7 days',
        color: '#D73146',
        order: 1,
    },
    {
        id: 801, // Reported practicing all safe behaviors in past 7 days (Always or frequently wears mask, cleans living/workspace, covers cough, maintains 6 ft of distance, and wears mask)',
        color: '#1FBA59',
        order: 2,
    },
    {
        id: 8088, // 'Question not included in questionnaire (Stayed home)',
        color: '#CBED8C',
        order: 3,
    },
    {
        id: 8099, // 'DTR/Unknown',
        color: '#2B88D8',
        order: 4,
    },

    // ----
    {
        id: 903, // 'Uses facemask (past 7 days), // Never',
        color: '#D73146',
        order: 1,
    },
    {
        id: 902, // 'Uses facemask (past 7 days), // Sometimes',
        color: '#F09536',
        order: 2,
    },
    {
        id: 901, // 'Uses facemask (past 7 days), // Always',
        color: '#1FBA59',
        order: 3,
    },
    {
        id: 904, // 'Uses facemask (past 7 days), Not applicable',
        color: '#FBDD39',
        order: 4,
    },
    {
        id: 9088, // 'Question not included in questionnaire (Face mask)',
        color: '#CBED8C',
        order: 5,
    },
    {
        id: 9099, // 'DTR/Unknown',
        color: '#2B88D8',
        order: 6,
    },

    // ----
    {
        id: 1003, // 'Wash hands (past 7 days), // Never',
        color: '#D73146',
        order: 1,
    },
    {
        id: 1002, // 'Wash hands (past 7 days), // Sometimes',
        color: '#F09536',
        order: 2,
    },
    {
        id: 1001, // 'Wash hands (past 7 days), // Frequently',
        color: '#1FBA59',
        order: 3,
    },
    {
        id: 1004, // 'Wash hands (past 7 days), // Not applicable',
        color: '#FBDD39',
        order: 4,
    },
    {
        id: 10088, // 'Question not included in questionnaire (Wash hands)',
        color: '#CBED8C',
        order: 5,
    },
    {
        id: 10099, // 'DTR/Unknown',
        color: '#2B88D8',
        order: 6,
    },

    // ----
    {
        id: 1103, // 'Cleans or disinfects living/workspace (past 7 days), // Never',
        color: '#D73146',
        order: 1,
    },
    {
        id: 1102, // 'Cleans or disinfects living/workspace (past 7 days), // Sometimes',
        color: '#F09536',
        order: 2,
    },
    {
        id: 1101, // 'Cleans or disinfects living/workspace (past 7 days), // Frequently',
        color: '#1FBA59',
        order: 3,
    },
    {
        id: 1104, // 'Cleans or disinfects living/workspace (past 7 days), // Not applicable',
        color: '#FBDD39',
        order: 4,
    },
    {
        id: 11088, // 'Question not included in questionnaire (Clean space)',
        color: '#CBED8C',
        order: 5,
    },
    {
        id: 11099, // 'DTR/Unknown',
        color: '#2B88D8',
        order: 6,
    },

    // ----
    {
        id: 1203, // 'Temperature >= 104 °F',
        color: '#D73146',
        order: 1,
    },
    {
        id: 1202, // 'Temperature 100.4 - 103.9 °F',
        color: '#FBDD39',
        order: 2,
    },
    {
        id: 1201, // 'Temperature < 100.4 °F',
        color: '#1FBA59',
        order: 3,
    },
    {
        id: 12099, // 'DTR/Unknown',
        color: '#2B88D8',
        order: 4,
    },

    // ----
    {
        id: 1302, // '2+ symptoms',
        color: '#D73146',
        order: 1,
    },
    {
        id: 1301, // 'One symptom',
        color: '#FBDD39',
        order: 2,
    },
    {
        id: 1300, // 'No symptoms',
        color: '#1FBA59',
        order: 3,
    },
    {
        id: 13099, // 'DTR/Unknown',
        color: '#2B88D8',
        order: 4,
    },

    // ----
    {
        id: 1401, // 'Blood Oxygen Saturation < 94',
        color: '#D73146',
        order: 1,
    },
    {
        id: 1402, // 'Blood Oxygen Saturation >= 94',
        color: '#1FBA59',
        order: 2,
    },
    {
        id: 14099, // 'DTR/Unknown',
        color: '#2B88D8',
        order: 3,
    },
];
