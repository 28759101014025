import React from 'react';
import numeral from 'numeral';

import { DataCondition } from '@portal/common/types';
import {
    AGE_GROUP_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    ROUND_ID_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';

import config from '../../../config';
import _ from '../../../locale';

import SingleValueMetricBlock from '../components/SingleValueMetricBlock';

type Props = {
    locationId: number;
    condition: DataCondition;
};

const IncidenceBlock = ({ locationId, condition }: Props) => (
    <SingleValueMetricBlock
        conditions={[condition]}
        filters={{
            [LOCATION_ID_KEY]: [locationId],
            [AGE_GROUP_ID_KEY]: [config.allAgesGroupId],
            [GENDER_ID_KEY]: [config.bothGendersId],
            [MEASURE_ID_KEY]: [config.incidenceMeasureId],
            [METRIC_ID_KEY]: [config.numberMetricId],
            [YEAR_KEY]: [2019],
            [ROUND_ID_KEY]: [config.gbd2019RoundId],
        }}
        label={_('simulation_tool_new_cases_metric', { location: _(`location_${locationId}`) })}
        valueFormatter={(value) => numeral(value).format('0,0')}
    />
);

export default IncidenceBlock;
