import React from 'react';
import { useSelector } from 'react-redux';

import { LOCATION_ID_KEY } from '@portal/common/models/data-key';
import { BodyText2, SectionBody } from '@portal/common/components';
import { styled } from '@portal/common/theme';

import { getSelectedDataCollectionForDataTool } from '../../../store/user-settings/selectors';
import { getSelectedRefinementFilters } from '../../../store/data-explorer/selectors';

import _ from '../../../locale';
import config from '../../../config';

import Header from '../components/Header';
import Title from '../components/Title';
import TreatmentsOutcome from './TreatmentsOutcome';

const Description = styled(BodyText2)(({ theme }) => ({
    paddingTop: 10,
    paddingBottom: 30,
}));

const ResultsSection = () => {
    const selectedRefinementFilters = useSelector(getSelectedRefinementFilters);
    const dataCollection = useSelector(getSelectedDataCollectionForDataTool);

    if (!selectedRefinementFilters?.[LOCATION_ID_KEY]?.length) {
        return null;
    }

    const locationId = parseInt(selectedRefinementFilters?.[LOCATION_ID_KEY]?.[0]);
    const isUSA = locationId === config.USALocationId;
    const locationName = _(`location_${locationId}`);

    return (
        <SectionBody isCollapsible isCollapsedInitially>
            <Header>{_('simulation_tool_4_header')}</Header>
            <Title>{_('simulation_tool_4_1_title')}</Title>

            {/* ---- */}
            <Description>
                {_(`simulation_tool_4_1_description_1_location_${locationId}`)}
            </Description>
            <BodyText2 align="center">
                {_('simulation_tool_4_1_image_title_1', { location: locationName })}
            </BodyText2>
            <div
                key={`ndmm-setting_${locationId}`}
                style={{
                    background: `url('img/simulation-tool/ndmm-setting_${locationId}.png') no-repeat`,
                    backgroundSize: 'contain',
                    height: 350,
                    width: '100%',
                    backgroundPosition: '50% 50%',
                }}
            />
            {/* ---- */}
            {!isUSA && (
                <Description>
                    {_(`simulation_tool_4_1_description_2_location_${locationId}`)}
                </Description>
            )}
            {isUSA && (
                <>
                    <BodyText2 align="center">
                        {_(`simulation_tool_4_1_image_title_2_location_${locationId}`, {
                            location: locationName,
                        })}
                    </BodyText2>
                    <div
                        style={{
                            background: `url('img/simulation-tool/rrmm-setting_102.png') no-repeat`,
                            backgroundSize: 'contain',
                            height: 350,
                            width: '100%',
                            backgroundPosition: '50% 50%',
                        }}
                    />
                </>
            )}

            {/* ---- */}
            <TreatmentsOutcome locationId={locationId} dataCollection={dataCollection} />
            <Title>{_('simulation_tool_4_2_title')}</Title>
            <Description>
                {_(`simulation_tool_4_2_description_1_location_${locationId}`)}
            </Description>
            <BodyText2 align="center">{_('simulation_tool_4_2_image_title')}</BodyText2>
            <div
                key={`baseline-sankey-${locationId}`}
                style={{
                    background: `url("img/simulation-tool/baseline-sankey-${locationId}.png") no-repeat`,
                    backgroundSize: 'contain',
                    height: 500,
                    width: '100%',
                    backgroundPosition: '50% 50%',
                }}
            />
            <Description>{_(`simulation_tool_4_2_description_location_${locationId}`)}</Description>
            <Description>{_('simulation_tool_4_2_description_2')}</Description>

            <Title>{_('simulation_tool_4_3_title')}</Title>
            <Description>{_('simulation_tool_4_3_description')}</Description>
        </SectionBody>
    );
};

export default ResultsSection;
