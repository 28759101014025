import React from 'react';

import {
    H2,
    PageBody,
    PageContainer,
    PageHeader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
    PageBodyFooter,
} from '@portal/common/components';

import _ from '../../locale';
import locale from './locale';

export default class DefinitionsScene extends React.PureComponent {
    render() {
        return (
            <PageContainer>
                <PageHeader>
                    <H2>{locale.definitions_header}</H2>
                </PageHeader>
                <PageBody>
                    <Table stripes>
                        <TableHead>
                            <TableHeaderRow>
                                <TableHeaderCell style={{ width: '25%' }}>
                                    {locale.definitions_table_col1}
                                </TableHeaderCell>
                                <TableHeaderCell>{locale.definitions_table_col2}</TableHeaderCell>
                            </TableHeaderRow>
                        </TableHead>
                        <TableBody>
                            {locale.definitions_table_content
                                .sort((a, b) => a.title.localeCompare(b.title))
                                .map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <b>{item.title}</b>
                                        </TableCell>
                                        {item.html_desc ? (
                                            <TableCell
                                                dangerouslySetInnerHTML={{
                                                    __html: item.html_desc,
                                                }}
                                            />
                                        ) : (
                                            <TableCell>{item.desc}</TableCell>
                                        )}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <PageBodyFooter>{_.get('footer_note')}</PageBodyFooter>
                </PageBody>
            </PageContainer>
        );
    }
}
