export default {
    title: 'forgot_password_title',
    description: 'forgot_password_description',
    email: 'email',
    required_field: 'error_required_field',
    invalid_email_format: 'error_invalid_email',
    reset_password_button: 'forgot_password_title',
    passwordReset: 'forgot_password_reset',
    successResetPassword: 'forgot_password_success_reset',
    ok: 'global_ok',
};
