import { flatten, intersection, isEmpty, isEqual, sortBy, uniq } from 'lodash/fp';
import { RootEpic } from 'MyTypes';
import { empty, from, iif, of, zip } from 'rxjs';
import { catchError, concat, concatMap, delay, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
    COMORBID_CAUSE_ID_KEY,
    MEASURE_ID_KEY,
    PRIMARY_ENTITY_KEY,
    ROUND_ID_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import {
    AllDataTypes,
    CauseOutcome,
    getDataTypeLocalePrefix,
    getDataTypePrimaryEntityKey,
    getDataTypesWithoutCondition,
    SequelaOutcome,
} from '@portal/common/models/data-type';
import { CDN_SECTION } from '@portal/common/models/data-explorer-section';
import { TYPE_DATA_TABLE_CHART } from '@portal/common/models/chart-type';
import {
    ApiError,
    ConditionDetailNotes,
    DataCondition,
    DataGranularityResponse,
    DataType,
} from '@portal/common/types';
import { mapValueToCondition } from '@portal/common/utility/chart-data-helpers';
import {
    firstAndLastFromArray,
    noneOfSelectedOptionsIsAllowedByGranularity,
} from '@portal/common/utility/filters-helpers';
import { mergeGranularity } from '@portal/common/utility/merge-granularity';
import { updateLocaleWith } from '@portal/common/locale';
import { DataProjectId } from '@portal/common/models/data-project';

import {
    areChartFiltersPartOfSelectedRefinementFilters,
    filterDataResponses,
} from '../../components/DataExplorer/ChartSection/utils';
import { REFINEMENT_OPTIONS } from '../../components/DataExplorer/RefinementFiltersControls';
import _ from '../../locale';
import { getMergedDataCollectionGranularity, loadData } from '../../utility/data-loader';
import addMissingPrimaryEntityToGranularity from '../../utility/data/add-missing-primary-entity-to-granularity';
import deleteUselessForecastScenarioData from '../../utility/data/delete-useless-forecast-scenario-data';
import roundPopulationDataResponseValues from '../../utility/data/round-data-response-values';
import adoptGHTData from '../../utility/data/adopt-ght-data';
import { getOrganizationId } from '../root-reducer';
import { getSelectedDataCollectionForDataTool } from '../user-settings/selectors';
import {
    calculateChartDataResponsesFromDataQuery,
    changeChartFilters,
    changeDataTypeSelectionForSingleConditionTool,
    changeSelectedDataType,
    dataSelectionValidation,
    emulateDataIsLoadingToMakeChartsRefresh,
    getAgeGroupsAsync,
    getConditionDetailNotes,
    getCountryLocationsSdi,
    getDataCollectionsAsync,
    getRefinedGranularityAsync,
    getRoundsAsync,
    getSingleYearAgeGranularitiesAsync,
    initializeChartFilters,
    initializeDataTypeSelection,
    openSelectionCountModal,
    queryChartDataAsync,
    queryDataAsync,
    resetDataTool,
    setCombinedFiltersAmount,
    setDrillIntoCondition,
    validateChartFilters,
    validateVisibleRefinementFilters,
    validateYearRefinementFilters,
} from './actions';
import {
    getChartDataRequestFilters,
    getChartSelectedRefinementFilters,
    getDataResponsesByType,
    getDataToolConfig,
    getRoundId,
    getSelectedChartConditions,
    getSelectedChartType,
    getSelectedConditions,
    getSelectedConditionsDataTypes,
    getSelectedDataType,
    getSelectedRefinementFilters,
    getSelectedRefinementFiltersWithFallbackToDefaultFilters,
    getSelectedRefinementFiltersWithoutPrimaryEntities,
    isDataLoading,
    isForecastingData,
    isGHTData,
    isPopulationData,
} from './selectors';
import config from '../../config';
import { LimitModal } from '../../models/data-tool';
import { calculateCombinedFiltersAmount } from '../../utility/data/calculate-combined-filters-amount';
import addPrimaryEntityLevel from '../../utility/data/add-primary-entity-level';
import { SingleAgeReportTypes } from '../../types';

export const loadAgeGroups: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getAgeGroupsAsync.request])),
        switchMap((action) =>
            from(api.getAgeGroups()).pipe(
                map((res) => getAgeGroupsAsync.success(res)),
                catchError((message: string) => of(getAgeGroupsAsync.failure(message)))
            )
        )
    );

export const loadCountryLocationsSdi: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getCountryLocationsSdi.request])),
        switchMap((action) =>
            from(api.data.getCountryLocationsSdi()).pipe(
                map((res) => getCountryLocationsSdi.success(res)),
                catchError((message: string) => of(getCountryLocationsSdi.failure(message)))
            )
        )
    );

export const loadRounds: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getRoundsAsync.request])),
        switchMap((action) =>
            from(api.rounds.get()).pipe(
                map((res) => getRoundsAsync.success(res)),
                catchError((message: string) => of(getRoundsAsync.failure(message)))
            )
        )
    );

// TODO: instead of a separate epic it would be better to move it in loadRounds epic (using "tap" operator)
export const addRoundTranslations: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getRoundsAsync.success])),
        switchMap((action) => {
            const roundTranslations = {};
            action.payload.forEach(({ id, name }) => {
                roundTranslations[`round_${id}`] = name;
            });
            updateLocaleWith(roundTranslations);

            return empty();
        })
    );

export const loadDataCollections: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getDataCollectionsAsync.request])),
        switchMap((action) =>
            from(api.organizationGroupContent.getDataCollections(action.payload)).pipe(
                map((res) => {
                    const dataCollections = addMissingPrimaryEntityToGranularity(res.results);
                    const sorted = sortBy(['sort_order'], dataCollections);
                    return getDataCollectionsAsync.success(sorted);
                }),
                catchError((message: string) => of(getDataCollectionsAsync.failure(message)))
            )
        )
    );

export const triggerConditionDetailNotes: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([dataSelectionValidation.success])),
        switchMap((action) => {
            const selectedConditions = getSelectedConditions(state$.value);
            const selectedRefinementFilters =
                getSelectedRefinementFiltersWithFallbackToDefaultFilters(state$.value);
            const selectedDataType = getSelectedDataType(state$.value);
            const dataToolConfig = getDataToolConfig(state$.value);

            if (!dataToolConfig.sections.includes(CDN_SECTION)) {
                return empty();
            }

            // additional refinement filters to merge
            const selectedMeasures = selectedRefinementFilters[MEASURE_ID_KEY];

            let comorbidConditions: DataCondition[] = [];
            const selectedComorbidConditions = selectedRefinementFilters[COMORBID_CAUSE_ID_KEY];
            if (selectedComorbidConditions && selectedComorbidConditions.length > 0) {
                comorbidConditions = selectedComorbidConditions.map((id) => ({
                    data_type: selectedDataType,
                    primary_entity_id: id,
                }));
            }

            // todo: return null when measure_id haven't changed
            return of(
                getConditionDetailNotes.request([...selectedConditions, ...comorbidConditions], {
                    [MEASURE_ID_KEY]: selectedMeasures,
                })
            );
        })
    );

export const loadConditionDetailNotes: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getConditionDetailNotes.request])),
        switchMap((action) => {
            const state = state$.value;
            const sequelasEntityHierarchy = state.entityHierarchies.sequelas!;

            const entitiesByDataType: Record<string, number[]> = {};
            action.payload.forEach((condition) => {
                let currentEntities = entitiesByDataType[condition.data_type];

                if (currentEntities == null) {
                    currentEntities = [];
                }

                entitiesByDataType[condition.data_type] = currentEntities.concat(
                    condition.primary_entity_id
                );
            });

            const measure_id = action.meta.measure_id && action.meta.measure_id.join(',');

            const requestsByType = Object.entries(entitiesByDataType).map(
                ([data_type, primary_entity_ids]: [string, number[]]) => {
                    const primaryEntityIdsValue = primary_entity_ids.join(',');

                    let cdnRequest: Promise<[ConditionDetailNotes[], ConditionDetailNotes[]]>;

                    // sequelas are correlated with causes by using their condition and case definition, will need to trigger additional parent cause request and merge CDN
                    const sequelasParentsIdsById = {};
                    if (data_type === SequelaOutcome) {
                        primary_entity_ids.forEach((seqId) => {
                            sequelasParentsIdsById[seqId] = sequelasEntityHierarchy.find(
                                (seq) => seq.id === seqId
                            ).parent_cause_id;
                        });

                        // get sequelas parent causes
                        const sequelasParentsIdsValue = uniq(
                            primary_entity_ids.map(
                                (seqId) =>
                                    sequelasEntityHierarchy.find((seq) => seq.id === seqId)
                                        .parent_cause_id
                            )
                        ).join(',');

                        cdnRequest = Promise.all([
                            api.conditionDetailNotes.get({
                                data_type: data_type,
                                primary_entity_id: primaryEntityIdsValue,
                                measure_id,
                            }),
                            api.conditionDetailNotes.get({
                                data_type: CauseOutcome,
                                primary_entity_id: sequelasParentsIdsValue,
                                measure_id,
                            }),
                        ]);
                    } else {
                        cdnRequest = Promise.all([
                            api.conditionDetailNotes.get({
                                data_type: data_type,
                                primary_entity_id: primaryEntityIdsValue,
                                measure_id,
                            }),
                            Promise.resolve([]),
                        ]);
                    }

                    return cdnRequest.then(([cdnRes, parentCdnRes]) => {
                        const REDUNDANT_FIELDS = [
                            'quantity_of_interest',
                            'exclusion_criteria',
                            'has_inpatient',
                            'has_outpatient',
                            'has_primary_care',
                            'has_claims',
                            'has_survey',
                            'has_disease_registry',
                            'has_case_notification',
                            'has_cohort_study',
                            'has_intervention_study',
                            'has_case_control_study',
                            'has_case_series',
                            'has_self_report',
                            'has_physical_biomarker',
                            'has_direct_observation',
                            'has_physician_diagnosis',
                            'has_physical_monitoring',
                            'has_other_source_types',
                            'other_source_type_notes',
                            'data_type',
                            'primary_entity_id',
                            'reference_id',
                            'is_reference_definition',
                            'notes',
                        ];

                        const parsedResponses: any[] = [];

                        primary_entity_ids.forEach((id) => {
                            const metaRecords: Record<'key' | 'value', string>[] = [
                                {
                                    key: 'data_type',
                                    value: data_type,
                                },
                                {
                                    key: 'primary_entity_id',
                                    value: id,
                                },
                            ];
                            const hiddenRecords: Record<'key' | 'value', string>[] = [];
                            const records: Record<'key' | 'value', string>[] = [
                                {
                                    key: 'cdn_condition',
                                    value: _(getDataTypeLocalePrefix(data_type) + id),
                                },
                            ];

                            if (data_type === SequelaOutcome) {
                                const parentId = sequelasParentsIdsById[id];
                                const parent = parentCdnRes.find(
                                    (parent) => parent.primary_entity_id === parentId
                                );

                                if (parent != null) {
                                    records.push({
                                        key: 'parent_condition',
                                        value: _(getDataTypeLocalePrefix(CauseOutcome) + parentId),
                                    });
                                    records.push({
                                        key: 'parent_case_definition',
                                        value: parent['case_definition'],
                                    });
                                }
                            }

                            const cdnResByMeasure = cdnRes.filter(
                                (r) => r.primary_entity_id === id
                            );
                            if (cdnResByMeasure != null && cdnResByMeasure.length > 0) {
                                const mergedCdnRecord = cdnResByMeasure.reduce(
                                    (cdnRecord, measureRes) => {
                                        Object.entries(measureRes)
                                            .filter(
                                                ([key, value]) => !REDUNDANT_FIELDS.includes(key)
                                            )
                                            .forEach(([key, value]) => {
                                                switch (key) {
                                                    case 'case_definition':
                                                        cdnRecord.top[
                                                            `${key}${
                                                                measureRes.measure_id != null
                                                                    ? `:measure_${measureRes.measure_id}`
                                                                    : ''
                                                            }`
                                                        ] = value;
                                                        break;

                                                    case 'non_fatal_icd9_codes':
                                                    case 'non_fatal_icd10_codes':
                                                    case 'fatal_icd9_codes':
                                                    case 'fatal_icd10_codes':
                                                    case 'non_fatal_female_age_group_restriction_ids':
                                                    case 'non_fatal_male_age_group_restriction_ids':
                                                    case 'fatal_female_age_group_restriction_ids':
                                                    case 'fatal_male_age_group_restriction_ids':
                                                    case 'non_fatal_location_restriction_ids':
                                                    case 'fatal_location_restriction_ids':
                                                        if (cdnRecord.middle[key] == null) {
                                                            // init empty with value
                                                            cdnRecord.middle[key] = value;
                                                        } else if (
                                                            !isEqual(cdnRecord.middle[key], value)
                                                        ) {
                                                            throw Error(
                                                                'CDN condition, restrictions and codes should be the same between measures'
                                                            );
                                                        }
                                                        break;

                                                    case 'measure_id':
                                                    case 'non_fatal_data_source_link':
                                                    case 'fatal_data_source_link':
                                                        // init empty with array
                                                        if (cdnRecord.top[key] == null) {
                                                            cdnRecord.top[key] = [];
                                                        }

                                                        cdnRecord.top[key].push(value);
                                                        break;

                                                    // case 'condition':
                                                    //     hiddenRecords.push({ key, value });
                                                    //     break;

                                                    default:
                                                        break;
                                                }
                                            });

                                        return cdnRecord;
                                    },
                                    { top: {}, middle: {}, bottom: {} }
                                );

                                Object.entries(mergedCdnRecord).forEach(([key, value]) => {
                                    Object.entries(value).forEach(([key, value]) => {
                                        records.push({ key, value });
                                    });
                                });
                            }

                            parsedResponses.push({
                                records,
                                metaRecords,
                                hiddenRecords,
                            });
                        });

                        return parsedResponses;
                    });
                }
            );

            return from(Promise.all(requestsByType)).pipe(
                map((cdnByType) => {
                    const cdn = flatten(cdnByType);
                    return getConditionDetailNotes.success(cdn);
                }),
                catchError((error: Error) => {
                    return of(getConditionDetailNotes.failure(error.message));
                })
            );
        })
    );

export const loadRefinedGranularity: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getRefinedGranularityAsync.request])),
        switchMap((action) => {
            const newDataTypeIsAdded = action.payload;

            const state = state$.value;
            const organizationId = getOrganizationId(state);
            const selectedConditions = getSelectedConditions(state);
            const selectedConditionsDataTypes = getSelectedConditionsDataTypes(state);
            const selectedDataCollection = getSelectedDataCollectionForDataTool(state);
            const selectedRefinementFilters = getSelectedRefinementFilters(state);
            const roundId = getRoundId(state);

            let ajax = from(Promise.resolve(null));

            if (selectedConditionsDataTypes) {
                const getRefinedGranularityParams = (dataType: DataType) => {
                    const dataTypeHasConditions =
                        !getDataTypesWithoutCondition().includes(dataType);

                    return {
                        organizationId,
                        resourceId: selectedDataCollection && selectedDataCollection.id,
                        data_type: dataType,
                        ...(dataTypeHasConditions && {
                            primary_entity_id: selectedConditions
                                .filter((i) => i.data_type === dataType)
                                .map((i) => i.primary_entity_id)
                                .join(','),
                        }),
                        round_id: roundId,
                    };
                };

                ajax = from(
                    zip(
                        ...selectedConditionsDataTypes.map((dataType) =>
                            from(
                                api.organization.getOrganizationDataCollectionResourceRefinedGranularity(
                                    getRefinedGranularityParams(dataType)
                                )
                            )
                        )
                    ).pipe(
                        map((granularities: DataGranularityResponse[]) =>
                            mergeGranularity(...granularities)
                        )
                    )
                );
            }

            return ajax.pipe(
                concatMap((res: {} | null) => {
                    const granularity = getMergedDataCollectionGranularity(
                        selectedDataCollection,
                        selectedConditionsDataTypes
                    );

                    const refinedGranularity = res;

                    if (refinedGranularity && refinedGranularity[ROUND_ID_KEY] != null) {
                        const patchRoundIdGranularity = (_granularity) =>
                            (_granularity[ROUND_ID_KEY] = granularity[ROUND_ID_KEY]);
                        patchRoundIdGranularity(refinedGranularity);
                    }

                    if (refinedGranularity && refinedGranularity[YEAR_KEY] != null) {
                        const patchYearGranularity = (_granularity) =>
                            (_granularity[YEAR_KEY] = _granularity[YEAR_KEY].sort());
                        patchYearGranularity(refinedGranularity);
                    }

                    const observables = [getRefinedGranularityAsync.success(refinedGranularity)];
                    if (newDataTypeIsAdded) {
                        observables.push(
                            initializeDataTypeSelection({
                                conditions: selectedConditions,
                                refinementFilters: selectedRefinementFilters,
                            })
                        );
                    }

                    return of(...observables);
                }),
                catchError((message: string) => of(getRefinedGranularityAsync.failure(message)))
            );
        })
    );

export const validateYearRefinementFiltersAfterRefinedGranularityChanged: RootEpic = (
    action$,
    state$,
    { api }
) =>
    action$.pipe(
        filter(isActionOf([getRefinedGranularityAsync.success])),
        switchMap((action) => {
            const refinedGranularity = action.payload;
            if (refinedGranularity == null) {
                return empty();
            }

            const { selectedRefinementFilters } = state$.value.dataExplorer;

            // TODO: we should check all time unit keys instead of only YEAR (using getTimeUnitKeys())
            const yearGranularity = refinedGranularity[YEAR_KEY];
            const yearSelectedFilters =
                selectedRefinementFilters && selectedRefinementFilters[YEAR_KEY];
            if (yearSelectedFilters == null || yearGranularity == null) {
                return empty();
            }

            // TODO: update the logic for day strings
            const [minYear, maxYear] = firstAndLastFromArray(yearGranularity.sort()) || [0, 0];
            const validatedYears = yearSelectedFilters.filter(
                (year) => year >= minYear && year <= maxYear
            );
            return of(validateYearRefinementFilters({ [YEAR_KEY]: validatedYears || undefined }));
        })
    );

export const visibleRefinementFiltersAfterRefinedGranularityChanged: RootEpic = (
    action$,
    state$,
    { api }
) =>
    action$.pipe(
        filter(isActionOf([getRefinedGranularityAsync.success])),
        switchMap((action) => {
            const {
                selectedRefinementFilters,
                visibleRefinementFilters,
                selectedConditionsRefinedGranularity,
            } = state$.value.dataExplorer;

            if (!(selectedRefinementFilters && selectedConditionsRefinedGranularity)) {
                return empty();
            }

            const selectedConditionsDataTypes = getSelectedConditionsDataTypes(state$.value) || [];

            const allowedRefinementFilters = REFINEMENT_OPTIONS.filter((key) => {
                if (
                    isEmpty(selectedConditionsRefinedGranularity[key]) ||
                    selectedConditionsDataTypes.map(getDataTypePrimaryEntityKey).includes(key)
                ) {
                    return false;
                }

                return noneOfSelectedOptionsIsAllowedByGranularity(
                    selectedRefinementFilters,
                    selectedConditionsRefinedGranularity
                )(key);
            });

            const emptyErrorRefinementFilters = Object.entries(selectedRefinementFilters)
                .filter(([key, value]) => {
                    return !value?.length;
                })
                .map(([key, value]) => {
                    return key;
                });

            const newVisibleRefinementFilters = uniq([
                ...visibleRefinementFilters,
                ...allowedRefinementFilters,
                ...emptyErrorRefinementFilters,
            ]);

            if (isEqual(newVisibleRefinementFilters, visibleRefinementFilters)) {
                return empty();
            }

            return of(validateVisibleRefinementFilters(newVisibleRefinementFilters));
        })
    );

export const triggerQueryData: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(dataSelectionValidation.success)),
        map((action) => queryDataAsync.request())
    );

export const queryData: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([queryDataAsync.request])),
        switchMap(() => {
            const state = state$.value;
            const selectedConditions = getSelectedConditions(state);
            return getObservableDataLoad(state, queryDataAsync, selectedConditions);
        })
    );

const getObservableDataLoad = (
    state,
    queryDataAsyncAction,
    selectedConditions,
    customFilters = {},
    customGranularity = null
) => {
    const organizationId = getOrganizationId(state);
    const selectedDataCollection = getSelectedDataCollectionForDataTool(state);
    const filters = getSelectedRefinementFiltersWithFallbackToDefaultFilters(state);
    const dataToolConfig = getDataToolConfig(state);

    return from(
        loadData(
            organizationId,
            selectedDataCollection,
            selectedConditions,
            customFilters,
            customGranularity
        )(filters)
    ).pipe(
        map((res) => {
            if (!isForecastingData(state)) {
                // mutating the data
                deleteUselessForecastScenarioData(res);
            }
            if (isPopulationData(state)) {
                // mutating the data
                roundPopulationDataResponseValues(res);
            }
            if (selectedDataCollection?.data_project_id === DataProjectId.GBD) {
                addPrimaryEntityLevel(res, state);
            }

            return queryDataAsyncAction.success(res, Date.now());
        }),
        catchError((err: ApiError) => {
            // data collection was unshared with user organization
            if (err.code === 404) {
                alert(
                    `Selected Data Collection "${selectedDataCollection.name}" is not shared with your organization anymore.`
                );

                return of(
                    queryDataAsyncAction.failure(err.message),
                    getDataCollectionsAsync.request({ organizationId }),
                    resetDataTool(dataToolConfig),
                    changeSelectedDataType(AllDataTypes)
                );
            }

            if (err.code === 400 && err.message === _('error_limit_exceeded')) {
                return of(
                    openSelectionCountModal('LIMIT_EXCEEDED_MODAL'),
                    queryDataAsyncAction.failure(err.message)
                );
            }

            return of(queryDataAsyncAction.failure(err.message));
        })
    );
};

export const filteringDataResponsesForChart: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([initializeChartFilters, changeChartFilters, queryDataAsync.success])),
        concatMap((action) => {
            const state = state$.value;

            const chartType = getSelectedChartType(state);
            if (chartType === TYPE_DATA_TABLE_CHART) {
                return empty();
            }

            const refinementFilters =
                getSelectedRefinementFiltersWithFallbackToDefaultFilters(state);
            const adoptedChartFilters = getChartDataRequestFilters(state);
            const dataResponsesByType = getDataResponsesByType(state);
            const selectedChartConditions = getSelectedChartConditions(state);

            // chart filters should be part of refinement filters and Data Explorer data loaded
            const shouldFilterDataResponsesForChart =
                !isDataLoading(state) &&
                adoptedChartFilters &&
                dataResponsesByType &&
                refinementFilters &&
                areChartFiltersPartOfSelectedRefinementFilters(
                    adoptedChartFilters,
                    refinementFilters
                );

            if (shouldFilterDataResponsesForChart) {
                const chartDataResponses = dataResponsesByType
                    ? filterDataResponses(
                          dataResponsesByType,
                          adoptedChartFilters,
                          selectedChartConditions
                      )
                    : null;

                if (chartDataResponses && isGHTData(state)) {
                    // mutating the data
                    adoptGHTData(
                        chartDataResponses,
                        chartType,
                        getDataResponsesByType(state),
                        selectedChartConditions
                    );
                }

                // Added delay as a workaround for connected component not rerendering IHME-2247
                // the reason the component is not rerendering is still unknown
                return of(emulateDataIsLoadingToMakeChartsRefresh()).pipe(
                    concat(
                        of(calculateChartDataResponsesFromDataQuery(chartDataResponses)).pipe(
                            delay(100)
                        )
                    )
                );
            }

            return empty();
        })
    );

export const triggerQueryChartData: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(
            isActionOf([
                validateChartFilters,
                initializeChartFilters,
                changeChartFilters,
                setDrillIntoCondition,
            ])
        ),
        switchMap((action) =>
            iif(
                () => {
                    const state = state$.value;

                    const selectedChartType = getSelectedChartType(state);
                    if (selectedChartType === TYPE_DATA_TABLE_CHART) {
                        return false;
                    }

                    const selectedRefinementFilters =
                        getSelectedRefinementFiltersWithFallbackToDefaultFilters(state);
                    const adoptedChartFilters = getChartDataRequestFilters(state);

                    const shouldQueryChartData =
                        adoptedChartFilters &&
                        selectedRefinementFilters &&
                        !areChartFiltersPartOfSelectedRefinementFilters(
                            adoptedChartFilters,
                            selectedRefinementFilters
                        );

                    // chart filters are not part of refined granularity
                    return shouldQueryChartData;
                },
                of(queryChartDataAsync.request()),
                empty()
            )
        )
    );

export const queryChartData: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([queryChartDataAsync.request])),
        switchMap(() => {
            const state = state$.value;
            const dataCollection = getSelectedDataCollectionForDataTool(state);
            const chartFilters = getChartSelectedRefinementFilters(state);
            const adoptedChartFilters = {
                ...getSelectedRefinementFiltersWithoutPrimaryEntities(state),
                ...getChartDataRequestFilters(state),
            };

            let conditions = [...getSelectedConditions(state)];
            const dataTypes = uniq(conditions.map(({ data_type }) => data_type));
            const granularity = getMergedDataCollectionGranularity(dataCollection, dataTypes);

            if (chartFilters.hasOwnProperty(PRIMARY_ENTITY_KEY)) {
                const conditionOrConditions = chartFilters[PRIMARY_ENTITY_KEY];
                if (Array.isArray(conditionOrConditions)) {
                    conditions = chartFilters[PRIMARY_ENTITY_KEY].map(mapValueToCondition);
                } else {
                    conditions = [mapValueToCondition(chartFilters[PRIMARY_ENTITY_KEY])];
                }
            }

            return getObservableDataLoad(
                state,
                queryChartDataAsync,
                conditions,
                adoptedChartFilters,
                granularity
            );
        })
    );

/*export const triggerLoadDatasetLocations: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(
            isActionOf([
                changeConditions,
                changeDataTypeSelectionForSingleConditionTool,
                changeSelectedDataType,
                initializeDataTypeSelection,
                changeDataTypeSelection,
            ])
        ),
        switchMap((action) => {
            const state = state$.value;

            const resourceId = getSelectedDataCollectionForDataTool(state)?.id;
            //@todo: handle case with multiple selected data types
            const dataTypes = getSelectedConditionsDataTypes(state);
            if (isEmpty(dataTypes)) {
                return empty();
            }

            const dataType = dataTypes[0];

            const locationsByDataset = getLocationsByDataset(state);
            const key = getLocationsHierarchyKey(state);

            const isHierarchyPresent = key && !isEmpty(locationsByDataset[key]);

            return of(
                isHierarchyPresent
                    ? getDatasetLocationsAsync.success({
                          resourceId,
                          dataType,
                          hierarchy: locationsByDataset[key],
                      })
                    : getDatasetLocationsAsync.request()
            );
        })
    );*/

export const checkIfChartsAreReEnabled: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([setCombinedFiltersAmount])),
        switchMap((action) =>
            iif(
                () => {
                    const {
                        meta: {
                            currentSelectionWithoutTimeUnitsAmount,
                            nextSelectionWithoutTimeUnitsAmount,
                        },
                    } = action;

                    return (
                        currentSelectionWithoutTimeUnitsAmount >=
                            config.combinedFiltersWithoutTimeUnitsAmountChartLimit &&
                        nextSelectionWithoutTimeUnitsAmount <
                            config.combinedFiltersWithoutTimeUnitsAmountChartLimit
                    );
                },
                of(openSelectionCountModal(LimitModal.chartsLimitReceded)),
                empty()
            )
        )
    );

export const updateCombinedFiltersAmountOnDataTypeChanged: RootEpic = (action$, state$) =>
    action$.pipe(
        filter(isActionOf([changeSelectedDataType, changeDataTypeSelectionForSingleConditionTool])),
        switchMap((action) => {
            const state = state$.value;

            const currentSelectionAmount = calculateCombinedFiltersAmount(state);
            const currentSelectionWithoutTimeUnitsAmount = calculateCombinedFiltersAmount(
                state,
                true
            );

            return of(
                setCombinedFiltersAmount(currentSelectionAmount, {
                    currentSelectionAmount,
                    currentSelectionWithoutTimeUnitsAmount,
                    nextSelectionAmount: currentSelectionAmount,
                    nextSelectionWithoutTimeUnitsAmount: currentSelectionWithoutTimeUnitsAmount,
                })
            );
        })
    );

export const loadSingleAgeDataGranularity: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getSingleYearAgeGranularitiesAsync.request)),
        switchMap((action) => {
            const state = state$.value;
            const metricIds = action.payload;

            const selectedConditions = getSelectedConditions(state);

            const selectedRefinementFilters =
                getSelectedRefinementFiltersWithFallbackToDefaultFilters(state);
            const roundId = selectedRefinementFilters?.round_id?.toString();

            const resourceId = getSelectedDataCollectionForDataTool(state)?.id;
            const selectedConditionDataTypes = getSelectedConditionsDataTypes(state);
            const supportedSelectedDataTypes = intersection(
                selectedConditionDataTypes,
                config.singleYearAgeDataTypes
            ) as DataType[];

            const getDataTypePrimaryEntities = (dataType: DataType): string =>
                selectedConditions
                    .filter((i) => i.data_type === dataType)
                    .map((i) => i.primary_entity_id)
                    .join(',');

            const promises = supportedSelectedDataTypes
                .map((dataType) =>
                    metricIds
                        .map((metricId) => {
                            const primaryEntityId = getDataTypePrimaryEntities(dataType);
                            const params = {
                                resourceId,
                                dataType,
                                metricId,
                                roundId,
                                primaryEntityId,
                            };

                            return [
                                api.data.getSingleYearAgeGranularity(params),
                                api.data.getSingleYearAgeAggregatedGranularity(params),
                            ];
                        })
                        .flat()
                        .flat()
                )
                .flat();

            return from(Promise.all(promises)).pipe(
                map((responses) => {
                    const granularity = {};
                    responses.forEach((response, idx) => {
                        const type =
                            idx % 2 === 0
                                ? SingleAgeReportTypes.Normal
                                : SingleAgeReportTypes.Aggregated;
                        const key = metricIds[Math.floor(idx / 2)];
                        if (isEmpty(granularity[type])) {
                            granularity[type] = {};
                        }
                        granularity[type][key] = response;
                    });

                    return getSingleYearAgeGranularitiesAsync.success(granularity);
                }),
                catchError((error: Error) => {
                    return of(getSingleYearAgeGranularitiesAsync.failure(error.message));
                })
            );
        })
    );
