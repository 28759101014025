import * as React from 'react';
import {
    TYPE_ARROW_CHART,
    TYPE_BAR_CHART,
    TYPE_DATA_TABLE_CHART,
    TYPE_LINE_CHART,
    TYPE_MAP_CHART,
    TYPE_SCATTER_MAP_CHART,
    TYPE_TREEMAP_CHART,
} from '@portal/common/models/chart-type';
import {
    CDN_SECTION,
    CHART_SECTION,
    CUSTOM_TEXT_SECTION,
    DATA_TABLE_SECTION,
    ONETIME_DATA_SECTION,
    SUMMARY_SECTION,
} from '@portal/common/models/data-explorer-section';
import {
    CAUSE_ID_KEY,
    CAUSE_PREVALENCE_KEY,
    CAUSE_PREVALENCE_LOWER_KEY,
    CAUSE_PREVALENCE_UPPER_KEY,
    COMORBID_CAUSE_ID_KEY,
    CONDITION_LEVEL_KEY,
    DATA_TYPE_KEY,
    EMPLOYMENT_COHORT_ID_KEY,
    ETHNICITY_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    MAP_DETAIL_LEVEL_ID_KEY,
    MAP_TYPE_ID_KEY,
    PATHOGEN_ID_KEY,
    PRIMARY_ENTITY_ID_KEY,
    PRIMARY_ENTITY_ID_LOCALIZATION_KEY,
    RACE_ID_KEY,
    ROUND_ID_KEY,
    UNDERLYING_CONDITION_KEY,
} from '@portal/common/models/data-key';
import { DataProjectId } from '@portal/common/models/data-project';
import {
    AllDataTypes,
    CauseAssociatedComorbidityStudyCoefficients,
    CauseAssociatedCoprevalenceRecords,
    Covid19Records,
    EDPTimelineIndicators,
    HealthThreatsMetricsRecords,
    LifeExpectancyRecords,
    PopulationRecords,
    ProjectedComorbidityCausesOutcomeRecords,
} from '@portal/common/models/data-type';
import { DataToolConfig } from '@portal/common/types';
import { Tooltip, PROVINCE_LEVEL } from '@portal/common/components';

import {
    COVID_19_EXPLORER_PATH,
    DATA_EXPLORER_PATH,
    EDP_EXPLORER_PATH,
    HEALTH_THREATS_METRICS_PATH,
    MORBIDITY_EXPLORER_PATH,
    SIMULATION_EXPLORER_PATH,
} from '../router/paths';
import _ from '../locale';
import config from '../config';
import AboutSection from '../scenes/HealthThreatsExplorerScene/sections/AboutSection';
import LocationDefinitionsSection from '../scenes/HealthThreatsExplorerScene/sections/LocationsDefinitionsSection';
import QASection from '../scenes/HealthThreatsExplorerScene/sections/QASection';

export enum LimitModal {
    dataToolLimitExceeded = 'data_tool_limit_exceeded_modal',
    chartsLimitExceeded = 'charts_limit_exceeded_modal',
    chartsLimitReceded = 'charts_limit_receded_modal',
    slowPerformanceWarning = 'slow_performance_warning_modal',
}

const DATA_PROJECT_IDS_BY_TOOL_PATH = {
    [DATA_EXPLORER_PATH]: [
        DataProjectId.GBD,
        DataProjectId.LUNG_CANCER,
        DataProjectId.RACE_ETHNICITY,
        DataProjectId.RACE_ETHNICITY_EYE_CONDITIONS,
        DataProjectId.AMR,
        DataProjectId.GBD2020,
        DataProjectId.GBD2020_CVD,
    ],
    [COVID_19_EXPLORER_PATH]: [DataProjectId.COVID19, DataProjectId.COVID19_US_COUNTIES],
    [MORBIDITY_EXPLORER_PATH]: [DataProjectId.COMORBIDITY_STUDY],
    [EDP_EXPLORER_PATH]: [DataProjectId.EDP],
    [SIMULATION_EXPLORER_PATH]: [DataProjectId.SIMULATION],
    [HEALTH_THREATS_METRICS_PATH]: [DataProjectId.HEALTH_THREAT_METRICS],
};

export const getDataProjectIdsForTool = (tool: string): number[] =>
    DATA_PROJECT_IDS_BY_TOOL_PATH[tool] ? DATA_PROJECT_IDS_BY_TOOL_PATH[tool] : [];

export const getDataToolForDataProject = (dataProjectId: number): string => {
    let dataTool = DATA_EXPLORER_PATH;

    const dataProjectsByDataTool = Object.entries(DATA_PROJECT_IDS_BY_TOOL_PATH).find(
        ([tool, projectIds]) => projectIds.includes(dataProjectId)
    );

    if (dataProjectsByDataTool) {
        dataTool = dataProjectsByDataTool[0]; // first element is a tool name
    }

    return dataTool;
};

export const createCustomTextSection = (SectionComponent: React.ReactNode) => ({
    type: CUSTOM_TEXT_SECTION,
    SectionComponent,
});

export const DATA_TOOL_CONFIG_BY_TOOL_PATH: Record<string, DataToolConfig> = {
    [DATA_EXPLORER_PATH]: {
        title: 'custom_chart_scene_title',
        pdfReportDefaultTitleKey: 'pdf_report_gbd_title',
        dataProjectIds: getDataProjectIdsForTool(DATA_EXPLORER_PATH),
        sections: [DATA_TABLE_SECTION, CHART_SECTION, CDN_SECTION],
        dataCollectionSelectorVisibility: true,
        enableBasicRefinementFiltersMode: true,
        defaultDataType: AllDataTypes,
        defaultChartType: TYPE_LINE_CHART,
        supportedCharts: [TYPE_LINE_CHART, TYPE_MAP_CHART, TYPE_BAR_CHART, TYPE_TREEMAP_CHART],
        dataTableExcludedColumns: [],

        dataTableHiddenByDefaultColumns: [DATA_TYPE_KEY, PRIMARY_ENTITY_ID_KEY],
        overwritesByDataProjectId: {
            [DataProjectId.AMR]: {
                supportedCharts: [TYPE_BAR_CHART, TYPE_MAP_CHART, TYPE_TREEMAP_CHART],
                dataTableExcludedColumns: [CAUSE_ID_KEY, ROUND_ID_KEY],
                customConditionLabel: _(`refinement_${PATHOGEN_ID_KEY}`),
                customConditionSelectorPlaceholder: _('search_pathogen_id'),
            },
            [DataProjectId.GBD]: {
                supportedCharts: [
                    TYPE_LINE_CHART,
                    TYPE_MAP_CHART,
                    TYPE_BAR_CHART,
                    TYPE_TREEMAP_CHART,
                    TYPE_ARROW_CHART,
                ],
                dataTableHiddenByDefaultColumns: [
                    DATA_TYPE_KEY,
                    PRIMARY_ENTITY_ID_KEY,
                    CONDITION_LEVEL_KEY,
                ],
            },
            [DataProjectId.RACE_ETHNICITY]: {
                supportedCharts: [
                    TYPE_MAP_CHART,
                    TYPE_BAR_CHART,
                    TYPE_TREEMAP_CHART,
                    TYPE_ARROW_CHART,
                ],
                defaultChartFilters: {
                    [MAP_DETAIL_LEVEL_ID_KEY]: PROVINCE_LEVEL,
                    [MAP_TYPE_ID_KEY]: config.USALocationId,
                },
                overwritesByDataType: {
                    [PopulationRecords]: {
                        supportedCharts: [
                            TYPE_LINE_CHART,
                            TYPE_BAR_CHART,
                            TYPE_MAP_CHART,
                            TYPE_ARROW_CHART,
                        ],
                    },
                    [LifeExpectancyRecords]: {
                        supportedCharts: [TYPE_BAR_CHART, TYPE_MAP_CHART, TYPE_ARROW_CHART],
                    },
                },
            },
            [DataProjectId.RACE_ETHNICITY_EYE_CONDITIONS]: {
                supportedCharts: [TYPE_MAP_CHART, TYPE_BAR_CHART],
                defaultChartFilters: {
                    [MAP_DETAIL_LEVEL_ID_KEY]: PROVINCE_LEVEL,
                    [MAP_TYPE_ID_KEY]: config.USALocationId,
                },
            },
        },
    },
    [COVID_19_EXPLORER_PATH]: {
        title: 'covid_19_explorer_title',
        pdfReportDefaultTitleKey: 'pdf_report_covid_19_title',
        dataProjectIds: getDataProjectIdsForTool(COVID_19_EXPLORER_PATH),
        dataCollectionSelectorVisibility: true,
        enableBasicRefinementFiltersMode: false,
        disableResetRefinementFilters: true,
        sections: [CHART_SECTION, DATA_TABLE_SECTION, CDN_SECTION],
        supportedCharts: [TYPE_LINE_CHART, TYPE_MAP_CHART, TYPE_BAR_CHART],
        defaultDataType: Covid19Records,
        defaultChartType: TYPE_LINE_CHART,
        dataTableExcludedColumns: [
            DATA_TYPE_KEY,
            PRIMARY_ENTITY_ID_LOCALIZATION_KEY,
            PRIMARY_ENTITY_ID_KEY,
        ],

        overwritesByDataProjectId: {
            [DataProjectId.COVID19_US_COUNTIES]: {
                supportedCharts: [TYPE_BAR_CHART],
            },
        },
    },
    [MORBIDITY_EXPLORER_PATH]: {
        title: `morbidity_explorer_title`,
        pdfReportDefaultTitleKey: 'pdf_report_morbidity_title',
        sections: [SUMMARY_SECTION, DATA_TABLE_SECTION, CHART_SECTION, CDN_SECTION],
        dataProjectIds: getDataProjectIdsForTool(MORBIDITY_EXPLORER_PATH),
        supportedCharts: [TYPE_BAR_CHART],
        defaultDataType: CauseAssociatedCoprevalenceRecords,
        defaultChartType: TYPE_BAR_CHART,
        dataTableExcludedColumns: [
            ROUND_ID_KEY,
            CAUSE_PREVALENCE_KEY,
            CAUSE_PREVALENCE_UPPER_KEY,
            CAUSE_PREVALENCE_LOWER_KEY,
            DATA_TYPE_KEY,
            PRIMARY_ENTITY_ID_KEY,
            FORECAST_SCENARIO_ID_KEY,
        ],
        barChartConfig: {},
        // isDataTypeSelectorHidden: true,
        customConditionLabel: _(`filter_label_${UNDERLYING_CONDITION_KEY}`),
        filtersSkipDefaultSelection: [COMORBID_CAUSE_ID_KEY],
        areRefinementFiltersSmartOptionsDisabled: true,
        overwritesByDataType: {
            [ProjectedComorbidityCausesOutcomeRecords]: {
                sections: [SUMMARY_SECTION, CHART_SECTION, DATA_TABLE_SECTION, CDN_SECTION],
            },
            [CauseAssociatedComorbidityStudyCoefficients]: {
                sections: [CHART_SECTION, DATA_TABLE_SECTION, SUMMARY_SECTION, CDN_SECTION],
            },
        },
    },
    [EDP_EXPLORER_PATH]: {
        title: `edp_explorer_title`,
        pdfReportDefaultTitleKey: 'pdf_report_edp_explorer_title',
        dataProjectIds: getDataProjectIdsForTool(EDP_EXPLORER_PATH),

        dataCollectionSelectorVisibility: false,
        enableBasicRefinementFiltersMode: true,
        disableResetRefinementFilters: true,

        dataTableExcludedColumns: [
            RACE_ID_KEY,
            ROUND_ID_KEY,
            GENDER_ID_KEY,
            ETHNICITY_ID_KEY,
            EMPLOYMENT_COHORT_ID_KEY,
            DATA_TYPE_KEY,
            PRIMARY_ENTITY_ID_KEY,
            FORECAST_SCENARIO_ID_KEY,
        ],

        defaultDataType: EDPTimelineIndicators,

        sections: [CHART_SECTION, ONETIME_DATA_SECTION],
        supportedCharts: [TYPE_DATA_TABLE_CHART, TYPE_LINE_CHART],
        defaultChartType: TYPE_DATA_TABLE_CHART,

        lineChartConfig: {
            hideUncertaintyValues: true,
        },
        areRefinementFiltersSmartOptionsDisabled: true,
    },
    [SIMULATION_EXPLORER_PATH]: {
        title: `simulation_explorer_title`,
        pdfReportDefaultTitleKey: 'pdf_report_simulation_explorer_title',
        dataProjectIds: getDataProjectIdsForTool(SIMULATION_EXPLORER_PATH),

        dataCollectionSelectorVisibility: false,
        enableBasicRefinementFiltersMode: true,
        disableResetRefinementFilters: true,
        dataTableExcludedColumns: [],

        defaultDataType: AllDataTypes,

        sections: [CHART_SECTION, ONETIME_DATA_SECTION],
        supportedCharts: [TYPE_DATA_TABLE_CHART, TYPE_LINE_CHART],
        defaultChartType: TYPE_DATA_TABLE_CHART,

        lineChartConfig: {
            hideUncertaintyValues: true,
        },
        areRefinementFiltersSmartOptionsDisabled: true,
    },
    [HEALTH_THREATS_METRICS_PATH]: {
        title: `health_threats_explorer_title`,
        pdfReportDefaultTitleKey: 'pdf_report_health_threats_explorer_title',
        dataProjectIds: getDataProjectIdsForTool(HEALTH_THREATS_METRICS_PATH),

        dataCollectionSelectorVisibility: true,
        // enableBasicRefinementFiltersMode: false,
        // disableResetRefinementFilters: true,
        sections: [
            createCustomTextSection(AboutSection),
            CHART_SECTION,
            createCustomTextSection(LocationDefinitionsSection),
            createCustomTextSection(QASection),
        ],
        supportedCharts: [TYPE_SCATTER_MAP_CHART, TYPE_LINE_CHART],
        defaultDataType: HealthThreatsMetricsRecords,
        defaultChartType: TYPE_SCATTER_MAP_CHART,
        shouldRequestAllDataOnInit: true,
        hideSelectDataSection: true,
        customConditionLabel: _('filter_label_primary_entity_ght'),
        customSelectDataConditionLabel: _('filter_label_primary_entity_ght_with_tooltip')(Tooltip),
    },
};
