import React from 'react';
import { RootState } from 'MyTypes';
import LogRocket from 'logrocket';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { pick } from 'lodash';

import * as Sentry from '@sentry/react';

import { parseApiError } from '@ihme/common/api-request';
import { localStorageAdapter } from '@ihme/common/packages/storage';
import AppStateProvider from '@ihme/common/packages/storage/AppStateProvider';

import { BodyText3 } from '@portal/common/components';
import Link from '@portal/common/components/Link';
import {
    getCookie,
    IMPERSONATED_SESSION,
    REDIRECTION_SESSION,
} from '@portal/common/utility/cookies';
import { styled } from '@portal/common/theme';

import api from '../../api';
import config from '../../config';
import Router from '../../router';
import { createStore } from '../../store';
import {
    getOrganizationId,
    getSession,
    getSessionAccount,
    getSessionAccountId,
} from '../../store/root-reducer';
import * as sessionActions from '../../store/session/actions';
import * as organizationActions from '../../store/organization/actions';
import history from '../../store/history';

import AppNotifications from '../AppNotifications';

// hot-reload debug
// setConfig({ logLevel: 'debug' });

const ImpersonateInfo = styled.div(({ theme }) => ({
    width: '100%',
    background: theme.color.red,
    color: theme.color.white,
    padding: '8px 20px',
}));

const mapStateToProps = (state: RootState) => ({
    accountId: getSessionAccountId(state),
    organizationId: getOrganizationId(state),
    sessionAccount: getSessionAccount(state),
    session: getSession(state),
});

const dispatchProps = {
    destroySession: sessionActions.destroySession,
    loadAccount: sessionActions.loadAccount,
    selectOrganization: organizationActions.selectOrganization,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;
type State = { error: object | null; eventId?: string };

class Root extends React.Component<Props, State> {
    state: State = { error: null };

    componentDidMount() {
        const { accountId, organizationId, destroySession, loadAccount, selectOrganization } =
            this.props;

        // set global error handler
        api.setGlobalErrorHandler((error) => {
            const apiError = parseApiError(error);
            Sentry.captureException(error, {
                tags: {
                    api: true,
                    code: apiError.code,
                },
            });
            LogRocket.captureException(error, {
                tags: {
                    api: true,
                    code: apiError.code,
                },
            });

            // if invalid session destroy the user session
            if (apiError.code === 401 && apiError.message === 'Invalid session') {
                destroySession({ error: 'Session expired. Please Sign In again.' });
            }
            // it should always throw parsedError to be then catched by consumer handler
            // becauseas global handler is invoked before consumer then handler
            throw apiError;
        });

        if (accountId) {
            // Load the latest account info, validate the session, and actualize the organization data
            loadAccount(organizationId, accountId).then(({ organization }) =>
                selectOrganization(organization)
            );
        }
    }

    componentDidCatch(error, info) {
        console.log('Unhandled component error', error, info);
        this.setState({ error });
        Sentry.withScope((scope) => {
            scope.setExtras(info);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });
        LogRocket.captureException(error, { extra: info });
    }

    render() {
        const { accountId, sessionAccount, session, destroySession } = this.props;

        if (accountId && sessionAccount == null) {
            return null;
        }

        const renderImpersonationsInfo = () => {
            if (session.isImpersonated && session.account) {
                const { first_name, last_name, organization } = session.account;
                return (
                    <ImpersonateInfo>
                        <BodyText3>
                            You are temporarily logged in as {first_name} {last_name}. When you're
                            done,{' '}
                            <Link
                                style={{
                                    color: 'white',
                                    textDecoration: 'underline',
                                }}
                                href={
                                    config.staffUrl +
                                    'organizations/' +
                                    organization.id +
                                    '/members'
                                }
                                onClick={(ev) => {
                                    destroySession();
                                }}
                            >
                                switch back
                            </Link>{' '}
                            to your account
                        </BodyText3>
                    </ImpersonateInfo>
                );
            }
            return null;
        };

        if (this.state.error) {
            // render fallback UI
            return (
                <button
                    type="button"
                    onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
                >
                    Report feedback
                </button>
            );
        } else {
            return (
                <>
                    {renderImpersonationsInfo()}
                    <AppNotifications />
                    <Router />
                </>
            );
        }
    }
}

const ConnectedRoot = connect(mapStateToProps, dispatchProps)(Root);

const ConnectedRootWithStateProvider = () => {
    const impersonatedSession = getCookie(IMPERSONATED_SESSION);
    const redirectionSession = getCookie(REDIRECTION_SESSION);

    return (
        <AppStateProvider<RootState>
            version={config.storageStateVersion}
            stateKey="redux"
            storageAdapter={localStorageAdapter}
            createStore={(initialState) => {
                const state = impersonatedSession
                    ? {
                          session: {
                              ...impersonatedSession,
                              isImpersonated: !!impersonatedSession,
                          },
                          organization: impersonatedSession.account.organization,
                      }
                    : redirectionSession
                    ? redirectionSession
                    : initialState;

                return createStore(state);
            }}
            onInitialState={({ state }) => {
                if (impersonatedSession) {
                    api.setSessionToken(impersonatedSession.token);
                } else if (state && state.session && state.session.token) {
                    api.setSessionToken(state.session.token);
                }
            }}
            onStateUpdate={({ state, setState }) => {
                setState(pick(state, ['session', 'organization', 'userSettings']));
            }}
        >
            <ConnectedRouter history={history}>
                <ConnectedRoot />
            </ConnectedRouter>
        </AppStateProvider>
    );
};

export default hot(module)(ConnectedRootWithStateProvider);
