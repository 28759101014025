import React from 'react';
import * as yup from 'yup';
import { Field, Form, FormikProps, withFormik } from 'formik';

import { Alert, Button, FormikInput } from '@portal/common/components';
import { styled } from '@portal/common/theme';

import _ from '../../locale';
import locale from './locale';

const StyledAlert = styled(Alert)({
    marginBottom: 20,
});

const StyledForm = styled(Form)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 30,
});
const StyledField = styled(Field)({
    width: 450,
});

type FormValues = {
    email: string;
};

type Props = {
    resendEmail: (email: string) => void;
    initialEmail?: string;
};

const mapNameToLabel = (name: string) => _('form_field_' + name);

const ResendSignUpEmailForm: React.FC<Props & FormikProps<FormValues>> = (props) => {
    const { isSubmitting, status } = props;
    return (
        <StyledForm>
            {status && status.error && <StyledAlert color="error">{status.error}</StyledAlert>}

            {status && status.success && (
                <StyledAlert color="success">{status.success}</StyledAlert>
            )}

            <StyledField
                type="email"
                name="email"
                component={FormikInput}
                mapNameToLabel={mapNameToLabel}
                required
                autoFocus
                data-private
            />

            <Button color="primary" disabled={isSubmitting} type="submit">
                {_(locale.resendButton)}
            </Button>
        </StyledForm>
    );
};

export default withFormik<Props, FormValues>({
    validateOnBlur: false,
    validateOnChange: false,

    // initialize values
    mapPropsToValues: (props) => ({
        email: props.initialEmail,
    }),

    validationSchema: yup.object().shape({
        email: yup.string().email('Invalid email format').required("This field can't be empty"),
    }),

    handleSubmit: (values, form) => {
        const { resendEmail } = form.props;

        resendEmail(values.email)
            .then((response) => {
                form.setStatus({ success: _(locale.successfulResend) });
                form.setSubmitting(false);
            })
            .catch((error) => {
                form.setStatus({ error: error.message });
                form.setSubmitting(false);
            });
    },
})(ResendSignUpEmailForm);
