import * as React from 'react';
import { mapKeys } from 'lodash/fp';
import { FaInfoCircle } from 'react-icons/fa';

import causes from '@portal/common/locale/en/causes';
import sequelas from '@portal/common/locale/en/sequelas';
import common from '@portal/common/locale/en/common';
import conditionDetailNotes from '@portal/common/locale/condition-detail-notes';
import {
    HealthThreatsMetricsRecords,
    RaceEthnicityEyeConditionsRecords,
} from '@portal/common/models/data-type';
import {
    CONDITION_LEVEL_KEY,
    PRIMARY_ENTITY_ID_LOCALIZATION_KEY,
} from '@portal/common/models/data-key';

import {
    COVID_19_EXPLORER_PATH,
    DATA_EXPLORER_PATH,
    EDP_EXPLORER_PATH,
    HEALTH_THREATS_METRICS_PATH,
    MORBIDITY_EXPLORER_PATH,
    SIMULATION_EXPLORER_PATH,
} from '../router/paths';

const infoIcon = <FaInfoCircle />;

export default {
    // portal common
    ...common,

    ...conditionDetailNotes,

    // merged causes and sequela into one dictionary for data explorer special case
    // it gives following formats: cause_sequela_cause_XXX | cause_sequela_sequela_XXX
    ...mapKeys((key) => 'cause_sequela_' + key, causes),
    ...mapKeys((key) => 'cause_sequela_' + key, sequelas),

    // Form fields
    form_field_first_name: 'First Name',
    form_field_last_name: 'Last Name',
    form_field_email: 'Email Address',
    form_field_title: 'Title',
    form_field_password: 'Password',
    form_field_old_password: 'Old Password',
    form_field_new_password: 'New Password', // @deprecated We are switching to password/old_password instead of new_password/password
    form_field_new_password_confirmation: 'Confirm New Password',
    form_field_organization_role: 'Role',
    organization_has_programmatic_access: 'Programmatic Access',
    organization_has_programmatic_access_false: 'Not Allowed',
    organization_has_programmatic_access_true: 'Allowed',

    // Table Headers
    table_header_name: 'Name',
    table_header_job_title: 'Job Title',
    table_header_email: 'Email',
    table_header_organization_role: 'Role',
    table_header_actions: 'Actions',
    table_header_category: 'Category',
    table_header_created: 'Date Added',
    table_header_last_access: 'Last Access',
    table_header_added: 'Date Added',
    table_header_icon: ' ',

    // Errors
    error_user_has_no_organization:
        "We're sorry but you cannot access this site. Your user is not a member of organization.",
    error_disabled_organization:
        "We're sorry but you cannot access this site. Your organization is disabled.",
    error_only_csv: 'File: Only .csv file types are supported.',
    error_no_valid_emails:
        'File: No email addresses were found. Please upload a CSV file containing valid email addresses.',
    error_not_valid_csv_file: 'File: Not valid csv file.',
    error_field_is_required: 'This field is required',
    error_short_password: 'Password should be at least 8 characters long',
    error_required_field: "This field can't be empty",
    error_invalid_email: 'Invalid email format',
    error_passwords_must_match: 'Passwords have to match',
    error_server: 'Server error',

    error_limit_exceeded:
        'The maximum number of filters has been exceeded. Reduce the number of selected filters and try again.', // do not edit this message it's coming from the server

    // Navigation
    nav_group_main: 'Main',
    nav_group_data: 'Data',
    nav_group_tools: 'IHME Tools',
    nav_group_ihme: 'IHME',
    nav_group_organization: 'Organization',

    nav_dashboard: 'Dashboard',
    nav_my_favorites: 'Favorite Content',
    nav_custom_chart: 'Data Explorer',
    nav_covid_19_data_explorer: 'Covid-19 Explorer',
    nav_covid_19_testing_protocol: 'Covid-19 Testing Protocol',
    nav_morbidity_explorer: 'Multimorbidity Explorer',
    nav_edp_explorer: 'Early Detection Program',
    nav_simulation_explorer: 'Simulation Tool',
    nav_health_threats_tool: 'Global Health Threats Tool',
    nav_gbd_tools: 'GBD Tools',
    nav_gbd_2017_compare: 'GBD 2017 Compare',
    nav_gbd_2017_search: 'GBD 2017 Results',
    nav_cod_viz: 'CodViz',
    nav_epi_viz: 'EpiViz',
    nav_disease_atlas: 'Disease Atlas',
    nav_us_disease_expenditure: 'US Disease Expenditure',
    nav_background_info: 'Background Info',
    nav_sources_methodology: 'Sources & Methodology',
    nav_definitions: 'Definitions',
    nav_sources: 'Data Sources',
    nav_methodology: 'Methodology',
    nav_staff: 'IHME CSU Staff',
    nav_contact_us: 'Contact Us',
    nav_profile: 'My Profile',
    nav_team: 'My Group',
    nav_sign_out: 'Sign Out',

    // TeamScene
    team_scene_title: 'My Group',
    team_invite_member: 'Invite Member',
    team_bulk_invite_members: 'Bulk Invite (CSV)',
    team_self_registration_options: 'Self Registration',
    team_delete_modal_title: 'Delete Member',
    delete_member_modal_message: (
        <span>
            <p>
                Are you sure? <br />{' '}
            </p>
            This user will be permanently deleted. Any resources they created will also be deleted.
        </span>
    ),

    // TeamScene
    content_scene_title: 'Group Content',

    // DataExplorerScene
    custom_chart_scene_title: 'Data Explorer',
    covid_19_explorer_title: 'Covid-19 Explorer',
    covid_19_testing_protocol_title: 'Covid-19 Testing Protocol',
    morbidity_explorer_title: 'Multimorbidity Explorer',
    edp_explorer_title: 'Early Detection Program',
    simulation_explorer_title: 'Multiple Myeloma Simulation Explorer',
    health_threats_explorer_title: 'Global Health Threats Tool',

    custom_chart_scene_data_table_section_title: 'Data Table',
    custom_chart_scene_charts_section_title: 'Chart',
    custom_chart_scene_condition_detail_notes_title: 'Condition Detail Notes',
    chart_type_label_line: 'Timeline',
    chart_type_label_map: 'Map',
    chart_type_label_bar: 'Bar Chart',
    chart_type_label_treemap: 'Treemap',
    chart_type_label_arrow: 'Arrow Chart',
    chart_type_label_scatter_map: 'Map',
    chart_type_label_table: 'Data Table',
    line_chart_regular_scenario: 'Regular Scenario',
    line_chart_better_scenario: 'Better Scenario',
    line_chart_worse_scenario: 'Worse Scenario',
    display_uncertainty: 'Display uncertainty?',
    display_forecasts: 'Show forecast levels?',
    map_chart_zoom_controls_label: 'ZOOM IN/OUT',
    bar_chart_clear_selection: 'Clear Selection',
    bar_chart_display_labels: 'Include data labels',
    bar_chart_uncertainty_checkbox_tooltip:
        'Visualization of uncertainty values is not supported for stacked bar charts.',
    bar_chart_labels_checkbox_tooltip:
        'There is not enough room to display values on the chart. Try reducing the scope of your filters.',
    data_explorer_data_type_locked_explanation:
        'Due to differences in data display and scale, causes and sequela are the only Types that can be combined in a search. Other searches are limited by Type.',
    data_explorer_copy_api_request_label: 'API Request',
    data_explorer_copied_api_request_label: 'Copied!',
    arrow_chart_choose_another_year_to_compare: 'Choose another year to compare',

    custom_chart_scene_citation_text:
        'Institute for Health Metrics and Evaluation (IHME) at the University of Washington, accessed on: %date%. Used with permission.',

    custom_chart_scene_na_treemap_chart: 'Treemap chart is not applicable for selected data type.',
    custom_chart_scene_sequela_data_na: 'Treemap chart is not applicable for Sequela data type.',
    custom_chart_scene_treemap_feedback:
        'We\'re testing the tree map functionality. We would love to hear your feedback. Tell us what you like or would improve while using this chart: <a href="mailto:services@healthdata.org">services@healthdata.org</a>',

    add_to_list_name_your_selection: 'How would you like to name this favorite content?',
    select_data_basic_search: 'Basic Search',
    select_data_advanced_search: 'Advanced Search',
    select_data_apply_filters: 'Run Query',
    run_query_button_disabled_tooltip:
        'The scope of data is too large to query.\nTry reducing the number of filter selections.',

    // Notifications
    notification_new_gbd_becomes_default_title:
        'Global Burden of Disease queries now available through 2022',
    notification_new_gbd_becomes_default_message: (Link) => (
        <p>
            The years 2020-2022 marked a significant transformation in the global health landscape,
            diverging notably from trends observed in previous years. The latest GBD study from IHME
            provides unparalleled insight into these changes. <Link>Explore</Link> your GBD license
            now.
        </p>
    ),
    // EDP data explorer
    edp_data_explorer_filter_data: 'Filter Data',
    edp_data_select_indicators: 'Select Indicators',
    edp_indicator_button_label: 'Add indicator',

    // Simulation Explorer
    simulation_tool_0_header: 'Executive Summary',
    simulation_tool_0_dynamic_text_6:
        'In 2019, an estimated 55.55k people were living with MM in China.',
    simulation_tool_0_dynamic_text_102:
        'In 2019, an estimated 89.57k people were living with MM in the US.',
    simulation_tool_0_text: (HeaderComponent, dynamicText) => (
        <>
            <p>
                Multiple Myeloma (MM) is an incurable disease causing significant health burden
                worldwide. {dynamicText} However, a variety of life-extending treatment options
                exist. As the treatment landscape for MM evolves, Sanofi is interested in previewing
                potential directions for its new anti-CD38 drug therapy, isatuximab-irfc
                (isatuximab), over the next few years. Specifically, the study explorable in this
                tool estimates the potential population-level uptake rate and health impact of
                isatuximab usage in the US and China over the next five years, under multiple ‘what
                if’ scenarios.
            </p>
            <p>
                Simulation studies provide a powerful option to address complex treatment questions.
                In the case of MM, there is much uncertainty regarding how to best treat
                patients—especially around the selection of subsequent lines of treatment after
                frontline therapy loses effectiveness. With patients often undergoing 3 or more
                lines of treatment, and with many different treatment options available at each
                stage, head-to-head treatment-sequencing clinical trials become less practical.
            </p>
            <p>
                Simulation studies such as this one, estimating patient volumes under various
                scenarios and the population-level impact of isatuximab, can inform strategies to
                increase uptake of isatuximab in the population. The <b>analytic approach</b>{' '}
                demonstrated here can also be adapted to other cancers with complex treatment
                pathways. Our simulation platform is built to draw on detailed population-level
                estimates stratified by age, sex, time, and location, with the capacity to further
                incorporate external data as needed to mirror relevant attributes of real-world
                populations.
            </p>
            <p>
                This work demonstrates the flexibility of the simulation platform for further use
                and development, given additional input data and locations of interest.
            </p>
            <HeaderComponent>Topline findings</HeaderComponent>
            <ul>
                <li>
                    <b>FINDING 1:</b> In the US, isatuximab treated patients are projected to have
                    slightly improved outcomes relative to non-monoclonal antibody treated patients
                    in the newly diagnosed multiple myeloma (NDMM) setting. In China the projected
                    differences are even smaller.
                </li>
                <li>
                    <b>FINDING 2:</b> Scale-up of isatuximab frontline treatment to 14% in the US
                    and 19.23% in China dramatically increases annual patients treated, compared to
                    both baseline and isatuximab following daratumumab scenarios for both locations.
                    <ul>
                        <li>
                            In the US we estimate a 177% increase in patients treated with
                            isatuximab across all lines by 2027.
                        </li>
                        <li>
                            In China, we estimate a 91% increase in patients treated with isatuximab
                            across all lines by 2027.
                        </li>
                    </ul>
                </li>

                <li>
                    <b>FINDING 3:</b> The scale-up of isatuximab in early lines of treatment does
                    not decrease its use in later lines of treatment in the US or in China.
                </li>
            </ul>
        </>
    ),

    simulation_tool_1_header: 'Background: %location% population and health',
    download_sim_report_button: 'Download full technical report',

    // ---
    simulation_tool_1_1_title: 'Understand the status of population health in %location%.',

    simulation_tool_estimated_death_metric: 'estimated deaths per year',
    simulation_tool_hale_metric: ({ Link }) => (
        <>
            <Link>health-adjusted life expectancy</Link> for 50-54-year-olds
        </>
    ),
    simulation_tool_haq_metric: ({ Link }) => (
        <>
            place in global ranking of <Link>healthcare access and quality index</Link>
        </>
    ),
    simulation_tool_sdi_metric: ({ Link }) => (
        <>
            place in global ranking of <Link>socio-demographic index</Link>
        </>
    ),

    simulation_tool_line_chart_title:
        'Figure 1: Estimated death rate in %location% by year %yearsRange%, age standardized. Future projections based on “business as usual”, better, and worse case scenarios.',

    simulation_tool_1_2_title: 'Explore population characteristics in %location%.',

    simulation_tool_1_2_description_1:
        'In 2019, %currentPopulation% of %location%’s population was aged 75 years or older. This number is expected to increase to %population2030% by 2030 and %population2050% by 2050.',
    simulation_tool_1_2_description_2:
        'Life expectancy at birth is projected to increase from %currentLifeExpectancy% in 2019 to %projectedLifeExpectancy% years by 2050.',
    simulation_tool_1_2_image_title:
        'Figure 2: Stacked estimated population by age in %location% by sex and year, 2020 - 2050',
    simulation_tool_1_2_description_3:
        'Multiple myeloma is an incurable disease, but a variety of life-extending treatment options exist. This tool presents topline findings and summarizes the methods of a simulation estimating potential population-level impact of isatuximab usage on patient outcomes.',
    simulation_tool_1_2_chart_title: 'Age structure in %location% by sex and year, 1990 - 2100',

    // ---
    simulation_tool_2_header: 'Background: Multiple Myeloma in %location%',
    simulation_tool_2_description: ({
        location,
        prevalence,
        deaths,
        ylls,
        rank2019,
        dalyNumber2019,
        rank1990,
        dalyRankChange,
        dalyNumberChange,
        highestRateAgeGroup,
        highestRateValue,
    }) => (
        <React.Fragment>
            <p>
                In 2019, {prevalence} people were living with multiple myeloma (MM) in {location}.
                Among all neoplasms in {location}, multiple myeloma ranked {rank2019} in terms of
                DALYs incurred in 2019, causing the loss of {dalyNumber2019} years of healthy life
                among the population that year. This ranking has {dalyRankChange} from {rank1990} in
                1990. Total DALYs caused by multiple myeloma has {dalyNumberChange} over the past
                decade. In 2019 multiple myeloma caused {deaths} deaths and {ylls} years of life
                lost (YLLs).
            </p>
            <p>
                As MM is largely considered a disease of the elderly, with the average onset between
                70 to 75 years of age, its prevalence has been projected to increase in the future,
                as the global population continues to age and live longer due to modern medicine and
                other life improving factors. The {highestRateAgeGroup} year old age group
                experienced the highest rate of multiple myeloma deaths, at {highestRateValue} per
                100,000.
            </p>
        </React.Fragment>
    ),

    simulation_tool_mm_death_metric: 'multiple myeloma deaths per year',
    simulation_tool_projected_deaths_metric: 'projected deaths in 2050 based on current trajectory',
    simulation_tool_cancer_prevalence_metric: 'most prevalent cancer in %location%',
    simulation_tool_new_cases_metric: 'new cases per year in %location%',
    simulation_tool_treemap_chart_title:
        'Figure 3: Multiple myeloma situated within all cancers, size proportional to number of DALYs incurred by both sexes and all ages in %location% in %year%',

    // ---
    simulation_tool_3_header: 'Study methods',
    simulation_tool_3_1_title: 'About the study',
    simulation_tool_3_1_description: ({ GBDLink, DownloadReportLink }) => (
        <React.Fragment>
            <p>
                The simulation, run by the Institute for Health Metrics and Evaluation (IHME) at the
                University of Washington, explored isatuximab uptake under a baseline treatment
                scenario and two alternative scenarios. It estimated survival endpoints following
                isatuximab, daratumumab, and other treatments. Each of the three scenarios was
                simulated both in the United States and China.
            </p>
            <p>
                The simulation provides Sanofi with results based on current best evidence, to aid
                in long-term strategic planning. This work showcases the flexibility of the
                simulation platform for further use and development, given additional input data and
                locations of interest. Input sources include{' '}
                <GBDLink>the Global Burden of Disease study (GBD) 2019</GBDLink>, Flatiron Health,
                literature searches, and Sanofi’s expert guidance. Detailed methods of the study are
                available in the Technical Report available for{' '}
                <DownloadReportLink>download</DownloadReportLink> above. Below follows an
                abbreviated description.
            </p>
        </React.Fragment>
    ),

    simulation_tool_3_3_title: 'IHME simulation background',
    simulation_tool_3_3_description: (
        <React.Fragment>
            <p>
                To enable faithful scenario estimation and uncertainty quantification for health
                interventions, IHME's simulation
            </p>
            <ul>
                <li>is an individual-based, discrete-time, Monte Carlo simulation system.</li>
                <li>
                    creates an ensemble of simulated populations that mirror the real-world
                    population of interest.
                </li>
            </ul>
            <p>
                Each simulated individual, called a <b>simulant</b>, is assigned all attributes
                relevant to the study question: age, sex, disease status, risk factors, etc. By
                default, these attrubutes are assigned based on their estimated prevalence and
                uncertainty in the GBD study; but external data can be incorporated as needed.
            </p>

            <p>IHME’s simulations account for two major types of uncertainty:</p>
            <ul>
                <li>
                    <b>Parameter uncertainty</b> reflects the limits of our knowledge of true
                    population rates (e.g. disease prevalence and mortality) and of the exact
                    efficacy of interventions.
                </li>
                <li>
                    <b>Stochastic uncertainty reflects</b> the fact that even if population-level
                    rates and effects were knowable, individual outcomes still vary. Therefore,
                    finite-sized samples of individuals exhibit variability.
                </li>
            </ul>
            <p>
                The ensemble of simulated populations allows for the quantification of uncertainty,
                with single populations viewed as draws from Bayesian-derived joint posterior
                distributions. The same population ensemble is used in each scenario, thus reducing
                unnecessary variability when comparing scenario outcomes.
            </p>
        </React.Fragment>
    ),

    simulation_tool_3_4_title: 'Scenarios',
    simulation_tool_baseline_scenario: 'Baseline scenario',
    simulation_tool_baseline_scenario_location_102: (
        <>
            <p>
                This scenario reflects current regulatory approvals, current practice, and
                business-as-usual expectations, as described by Sanofi experts.
            </p>
            <p>
                Isatuximab is eligible for use from the second line of treatment (LoT) onwards.
                However, in our baseline scenario it is disallowed <b>immediately</b> following a
                LoT containing daratumumab. This daratumumab-specific restriction does not reflect
                any regulatory policy, but rather expert assumptions about clinical practice.
            </p>
            <p>
                Under the baseline scenario, the proportion of patients receiving isatuximab is
                linearly ramped up to line-specific target treatment coverage rates by 2028.
                Demographic characteristics and population rates are derived from GBD 2019.
            </p>
        </>
    ),

    simulation_tool_baseline_scenario_location_6: (
        <>
            <p>
                This scenario reflects current regulatory approvals, current practice, and
                business-as-usual expectations, as described by Sanofi experts.
            </p>
            <p>
                Isatuximab is not approved in China yet. Sanofi projects that an approval will be
                granted soon. However, in our baseline scenario it will remain disallowed{' '}
                <b>immediately</b> following a LoT containing daratumumab. This daratumumab-specific
                restriction does not reflect any regulatory policy, but rather expert assumptions
                about clinical practice.
            </p>
            <p>
                Under the baseline scenario, the proportion of patients receiving isatuximab is
                linearly ramped up (starting in 2024) to line-specific target treatment coverage
                rates by 2028. Demographic characteristics and population rates are derived from GBD
                2019.
            </p>
        </>
    ),
    simulation_tool_alternative_scenario_1: 'Alternative scenario 1',
    simulation_tool_alternative_scenario_1_location_102: (
        <p>
            The same as the baseline scenario, except that isatuximab is allowed as a treatment
            option directly after daratumumab <b>frontline</b> (LoT 1) treatment. Specifically, if a
            simulant receives daratumumab in LoT 1, they have a 5% probability of receiving
            isatuximab in LoT 2.
        </p>
    ),
    simulation_tool_alternative_scenario_1_location_6: (
        <p>
            The same as the baseline scenario, except that isatuximab is allowed as a treatment
            option directly after daratumumab <b>frontline</b> (LoT 1) treatment. Specifically, if a
            simulant receives daratumumab in LoT 1, they have a 5% probability of receiving
            isatuximab in LoT 2.
        </p>
    ),
    simulation_tool_alternative_scenario_2: 'Alternative scenario 2',
    simulation_tool_alternative_scenario_2_location_102: (
        <p>
            The same as the baseline scenario, except that isatuximab is also approved and adopted
            as frontline treatment. Frontline coverage starts ramping up in 2021. By 2025
            approximately 10% of simulants receive an isatuximab-containing LoT 1, linearly ramping
            up to 14% by 2028.
        </p>
    ),
    simulation_tool_alternative_scenario_2_location_6: (
        <p>
            The same as the baseline scenario, except that isatuximab is also approved and adopted
            as frontline treatment starting in 2024. By 2028 approximately 19.2% of simulants
            receive an isatuximab-containing LoT 1.
        </p>
    ),
    simulation_tool_both_countries_title: 'Both countries',
    simulation_tool_both_countries_text:
        'Additional specific rates for all scenarios can be found in the treatment coverage scale-up section of the Technical Report.',

    simulation_tool_3_5_title: 'Concept model',
    simulation_tool_3_5_description: (
        <>
            <p>
                The concept model (Figure 4) is an accurate but simplified blueprint for the
                simulation, describing how risk factors, disease, and interventions relate to one
                another in the simulated environment. The model and its main components (covariates,
                risk factors, causes, health system) are described in detail on pages 26-48 of the
                accompanying Technical Report.
            </p>
        </>
    ),
    simulation_tool_3_5_image_title:
        'Figure 4: Concept model diagram presenting a high-level blueprint of the simulation structure.',

    simulation_tool_3_6_title: 'Data sources',
    simulation_tool_3_6_description: ({ GBDLink, NCCNLink }) => (
        <>
            <br />
            <p>About the individual sources:</p>

            <ul>
                <li>
                    <GBDLink>The Global Burden of Disease (GBD)</GBDLink> is the most comprehensive
                    worldwide epidemiological study to date, estimating the burden of over 350
                    causes of death and disability related to thousands of conditions for every
                    country globally. The study also generates demographic (age, sex) information.
                    All disease estimates from the study are age- and sex-specific. This simulation
                    used GBD 2019, the most recent GBD version at the time of model development.
                </li>
                <li>
                    Flatiron Health is an oncology-focused electronic health record (EHR) company.
                    The simulation used a Flatiron Health microdata dataset of US MM patients,
                    facilitated by Sanofi. Challenges encountered by IHME and Sanofi with this data
                    source, and our approach to resolve them, are described in pages 23-25 of the
                    accompanying Technical Report.
                </li>
                <li>
                    RCTs: for some key inputs for which Flatiron data was revealed as not
                    sufficiently reliable, we conducted an independent literature search and network
                    meta-analysis of randomized control trials (RCTs).
                </li>
                <li>
                    <NCCNLink>NCCN</NCCNLink> is the National Comprehensive Cancer Network, a
                    nonprofit alliance of leading US-based cancer centers.
                </li>
            </ul>
        </>
    ),

    // ---
    simulation_tool_4_header: 'Simulation results',
    simulation_tool_4_1_title: 'Survival findings',
    simulation_tool_4_1_description_1_location_102:
        'Isatuximab treated patients have slightly improved outcomes relative to non-monoclonal antibody treated patients in the newly diagnosed multiple myeloma (NDMM) setting.',
    simulation_tool_4_1_image_title_1:
        'Figure 5: %location% relapse-free survival in the NDMM setting for treatment line 1 in alternative scenario 2.',
    simulation_tool_4_1_image_title_2_location_102:
        'Figure 6: US relapse-free survival in the relapsed and refractory multiple myeloma setting for treatment line 2 in alternative scenario 2.',
    simulation_tool_4_1_data_table_title:
        'Table 2: Treatment outcomes by population subgroups, %location%.',

    simulation_tool_4_1_description_1_location_6: (
        <p>
            The simulation suggested a minimal difference in population-level survival outcomes
            associated with a 19.2% scale-up of frontline isatuximab coverage by the beginning of
            2028.
        </p>
    ),

    simulation_tool_4_1_description_2_location_6:
        'With isatuximab projected to enter the Chinese market in 2024 as a frontline therapy, the time period simulated is too limited to capture any effect by 2028 of isatuximab usage on survival results. Additionally, there is increased stochastic uncertainty due to the lower MM prevalence in China. Therefore we only present NDMM setting results for China.',

    simulation_tool_4_2_title: 'Treatment coverage findings',
    simulation_tool_4_2_image_title:
        'Figure 7: Example treatment sequencing in the simulated population (lines 1 - 3).',
    simulation_tool_4_2_description_1_location_6: (
        <div>
            <b>FINDING 1:</b> Scale-up of isatuximab frontline treatment to 19.23% in China
            dramatically increases annual patients treated, compared to both baseline and isatuximab
            following daratumumab scenarios for China. Topline findings are presented here; the
            accompanying Technical Report contains more detailed analysis.
        </div>
    ),
    simulation_tool_4_2_description_1_location_102: (
        <div>
            <b>FINDING 1:</b> Scale-up of isatuximab frontline treatment to 14% in the US
            dramatically increases annual patients treated, compared to both baseline and isatuximab
            following daratumumab scenarios for United States. Topline findings are presented here;
            the accompanying Technical Report contains more detailed analysis.
        </div>
    ),
    simulation_tool_4_2_description_location_102: (
        <ul>
            <li>
                Total number of patients treated in alternative scenario 2 (Isa frontline) increases
                by <b>155%</b> from 2,668 (95% UI: 1,250 – 4,500) in 2023 to 6,796 (4,710 – 10,060)
                in 2027.
            </li>

            <li>
                Scale-up of frontline isatuximab treatment yields a <b>177%</b> increase in total
                annual patients treated between baseline and alternative scenario 2. 2,027 (1,238 –
                3,787) patients are treated in 2027 under baseline compared to 6,796 (4,710 –
                10,060) alternative scenario 2.
            </li>
            <li>
                5% isatuximab treatment directly after daratumumab treatment yields an <b>8%</b>{' '}
                difference in total annual patients treated between baseline and alternative
                scenario 1. 2,455 (1,238 – 3,787) patients are treated in 2027 under baseline
                compared to 2,648 (1,239 – 4,186) under alternative scenario 1.
            </li>
        </ul>
    ),

    simulation_tool_4_2_description_location_6: (
        <ul>
            <li>
                Total number of patients treated increases from 0 in 2023 to 6,684 (95% UI: 1,092 –
                13,613) in 2028. There is a <b>641%</b> increase across all lines between 2023 and
                2027.
            </li>
            <li>
                Scale-up of frontline isatuximab treatment yields a <b>91%</b> increase in total
                annual patients treated between baseline and alternative scenario 2. 3,491 (0 –
                7,650) patients are treated in 2027 under baseline compared to 6,684 (1,092 –
                13,613) under alternative scenario 2.
            </li>
            <li>
                5% isatuximab treatment directly after daratumumab treatment yields a <b>3%</b>{' '}
                increase in total annual patients treated between baseline and alternative scenario
                1. 3,491 (0 – 7,650) patients are treated in 2027 under baseline and 3,606 (0 –
                8,060) under alternative scenario 1. These uncertainty intervals begin at 0 due to
                stochastic uncertainty introduced by lower MM prevalence in China.
            </li>
        </ul>
    ),
    simulation_tool_4_2_description_2: (
        <div>
            <b>FINDING 2:</b> The scale-up of isatuximab in early lines of treatment does not
            decrease its use in later lines of treatment in the US or China. In other words, the
            increase in isatuximab usage in the first line of treatment in alternative scenario 2
            did not substantially decrease its use in subsequent lines of treatment.
        </div>
    ),

    simulation_tool_4_3_title: 'Future directions',
    simulation_tool_4_3_description: (
        <>
            <p>
                IHME has built a highly flexible simulation platform whose input parameters and
                logic can be updated and expanded. Further iterations or phases of this simulation
                could lead to updated results based on additional isatuximab data, additional
                complexity, as well as results for more locations of interest.
            </p>
            <p>
                There is uncertainty regarding how to best treat MM patients, especially regarding
                optimal treatment sequencing given so many different options. In this context, where
                head-to-head clinical trials are not practical, simulation studies can address
                complex treatment questions. Our analytic approach, informed by both clinical trial
                data and existing real-world data, adds to the emerging effort to simulate
                treatment-related outcomes for rare cancers with complex treatment pathways.
            </p>
        </>
    ),

    // SelfRegistrationOptionsModal
    self_sign_up_options_title: 'Self Registration Options',
    self_sign_up_mode_label: 'Self registration mode',
    self_sign_up_token_label: 'Self registration URL',
    self_sign_up_copy_link: 'Copy to clipboard',
    self_sign_up_regenerate_link: 'Regenerate URL',
    self_sign_up_mode_none: 'Not Allowed',
    self_sign_up_mode_email: 'Email Based',
    self_sign_up_mode_link: 'URL Based',
    email_domains_label: 'Email domains',
    email_domains_hint:
        'Enter a comma-separated list of email domains that members of this Organization should belong to in order to be allowed to be invited. Leave empty for no restriction.',

    modal_close: 'Close',
    modal_done: 'Done',

    // Profile Scene
    profile_general_tab: 'General',
    profile_change_password_tab: 'Change Password',
    profile_api_access_tab: 'API Access',
    profile_sign_out_tab: 'Log out',
    profile_save_changes: 'Save Changes',
    profile_first_name: 'First Name',
    profile_last_name: 'Last Name',
    profile_email: 'Email Address',
    profile_title: 'Job Title',
    profile_avatar_url: 'Avatar',

    data_license_title: 'Data Collections',

    // Change Password Tab
    change_password_title: 'Change Password',
    change_password_button: 'Change Password',
    change_password_success_message:
        'Your password has been successfully changed. Please log in with your new password',

    // API Access Tab
    api_access_programmatic_toggle_label: 'Programmatic access:',
    api_access_create_button_label: 'Create Access Key',
    api_access_view_docs_button: 'View Documentation',
    api_credentials_table_column_access_key: 'Access Key',
    api_credentials_table_column_secret_key: 'Secret Key',
    api_credentials_table_column_created: 'Created',
    api_credentials_table_column_accessed: 'Last Accessed',
    api_credentials_table_column_actions: 'Delete',
    api_credentials_table_empty_disabled:
        'API access is disabled. You can enable access by toggling on the Programmatic Access switch above.',
    api_credentials_table_empty:
        'There are no API access keys available. You can create a key by selecting the Create Access Key button above.',
    api_credentials_created_modal_title: 'Create Access Key',
    api_credentials_created_modal_message:
        'This is the only time that the secret access key can be viewed. You cannot recover them later. However, you can create new access keys at any time.',

    // EditPictureModal
    edit_picture_add_title: 'Add Picture',
    edit_picture_edit_title: 'Update Picture',
    edit_picture_add_button: 'Add Picture',
    edit_picture_update_button: 'Update Picture',
    edit_picture_url: 'File',

    // HomeScene
    cancel_confirmation_question: 'Are you sure you want to cancel?',
    cancel_confirmation_title: 'Cancel download',
    cancel_confirmation_button: 'Confirm',

    file_upload_input_choose_file_button_label: 'Choose File',
    file_upload_input_upload_button_label: 'Upload a new file',
    file_upload_input_cancel_button_label: 'Cancel upload',
    file_upload_input_overwrite_warning: 'WARNING: This action will overwrite the old file',

    staff_scene_title: 'IHME Client Services Staff',

    // Sign In scene
    sign_in_scene_register_link: 'New User? Click here to register.',
    sign_in_scene_sign_in_button: 'Sign In',
    sign_in_scene_sign_in_with_sso_button: 'Sign In with SSO',
    sign_in_scene_forgot_password_link: 'Forgot password?',

    // AuthCallback Scene
    please_resign_in: ({ Link }) => (
        <React.Fragment>
            Please, try to&nbsp;<Link>sign in</Link>&nbsp;again.
        </React.Fragment>
    ),

    // Self Registration Scene
    registration_scene_resend_title: 'Registration Form',
    registration_scene_resend_email: 'Resend my sign up email.',

    // Resend Sign Up Email
    resend_sign_up_email_title: 'Resend Sign Up Email',
    resend_sign_up_email_success: 'Your sign up email has been sent. Please check your inbox.',
    resend_sign_up_email_resend: 'Resend',

    // CustomDataTableScene
    custom_data_table_title: 'Custom Data Tables',

    // MethodologyScene
    methodology_scene_title: 'Methodology',
    methodology_column_title: 'Reference material',
    methodology_column_description: 'Description',

    // Data Explorer
    data_explorer_condition_dropdown_placeholder: 'Search conditions by name or ICD code...',
    data_explorer_widget_condition_dropdown_placeholder: 'Search conditions...',

    /**
     * Please try to keep it grouped by the scene as above
     */
    empty_label_default_text: '-',

    country_forecasting_regular: 'Regular Scenario',
    country_forecasting_better: 'Better Scenario',
    country_forecasting_worse: 'Worse Scenario',

    forgot_password_reset: 'Password reset',
    forgot_password_success_reset:
        'You have been sent a new password. Please log in with your new password.',

    forgot_password_title: 'Reset Password',
    forgot_password_reset_button: 'Reset',
    forgot_password_description:
        'To reset your password, please confirm your email address below.\n\nYou will receive an email with a new password, after which you can go back and log in again.',

    global_ok: 'Ok',

    account_email: 'Email',
    account_password: 'Password',
    account_confirm_password: 'Confirm Password',
    account_first_name: 'First Name',
    account_last_name: 'Last Name',
    account_title: 'Title',
    account_role: 'Role',
    account_role_member: 'Member',
    account_role_admin: 'Admin',
    account_status: 'Status',
    account_generate_password_button: 'Generate password',

    contact_us_scene_title: 'Contact Us',
    contact_us_reason_suggestion: 'Suggestion',
    contact_us_reason_question: 'Data request or question',
    contact_us_reason_bug: 'Report a bug',
    contact_us_reason_other: 'Other',
    contact_us_reason: 'Reason',
    contact_us_subject: 'Subject',
    contact_us_body: 'Comment',
    contact_us_submit: 'Submit',
    contact_us_is_manager_copied: 'Copy my Organization Admin(s)',
    contact_us_success_message: 'Thank you for contacting us',

    organization_member_add_title: 'Add member',
    organization_member_edit_title: 'Edit member',
    organization_member_role: 'Role',
    organization_member_select_role: 'Select role',
    organization_member_role_member: 'Member',
    organization_member_role_manager: 'Team Manager',

    save_button: 'Save',
    cancel_button: 'Cancel',
    reset_button: 'Reset',
    download_button: 'Download',
    okay_button: 'Okay',
    explore_now_button: 'Explore Now',

    app_name: 'IHME Client Portal',
    sign_in: 'Sign In',
    email: 'Email',
    forgot_password_question: 'Forgot password?',
    ie_compatibility_notice:
        'We are working to resolve some technical issues with how the Client Portal functions in Internet Explorer. If you are using IE, please try opening this link in another browser.',
    register_submit: 'Register',
    register_success:
        'Registration successful. Please check your inbox for a verification email and click on activation link in order to activate your account.',
    register_confirmation: 'Your account is activated, please ',
    register_confirmation_link: 'continue to the sign in page.',

    methodology_title: 'IHME Methodology',
    global_forecasting_title: 'Global Forecasting Map',
    country_forecasting_title: 'Country Forecasting Tool',
    news_title: "What's New",

    global_first_name: 'First Name',
    global_last_name: 'Last Name',
    global_role: 'Role',
    global_search_placeholder: 'Search',

    staff_invite_members: 'Invite Members',

    role_admin: 'Team Admin',
    role_member: 'Member',
    role_manager: 'Team Manager',

    profile_update_picture: 'Update Picture',
    profile_email_address: 'Email Address', // I think it's not used?

    lorem_ipsum:
        'Lorem ipsum dolor sit amet, ad dolorem fierent neglegentur eam, qui ne labores menandri scriptorem, soleat integre et eam. Nibh efficiendi vis et, ne dicta volutpat quaerendum sed, agam democritum adipiscing an pro. Quod verear nec ad. Ad diam latine patrioque per, ipsum apeirian definitionem usu cu. Enim meis aperiam vix at, aliquip singulis ius no. Ei liber quidam delicatissimi pri, quis semper denique ei mel, enim dicam an per.',

    filter_age_group_title: 'Age',
    filter_gender_title: 'Sex',
    filter_measure_title: 'Measure',
    filter_metric_title: 'Unit',
    filter_cause_title: 'Cause',
    filter_location_title: 'Location',

    data_collection_dropdown_label: 'Data Collection',
    data_collections_empty_dropdown_info:
        "Your organization does not currently have an active data collection with IHME. If you believe this is in error please contact your organization's administrator for more information.",
    data_collections_dropdown_license_info:
        'Data access is based on your organization’s agreement with IHME.',
    condition_type_dropdown_label: 'Types',
    data_collections_create_new_dropdown_option: 'Create a New Data Collection (Coming Soon)',

    refinement_condition_label: 'Conditions',
    refinement_types_label: 'Types',
    refinement_button_label: 'Add Refinement',
    refinement_filter_year_undefined: 'All Years',
    refinement_filter_day_undefined: 'All Days',

    export_single_age_estimates_label: 'Export for Custom Ages',
    export_single_age_modal_title: 'Custom Ages Report',
    export_single_age_file_name: 'Single-Year Ages Report',
    export_custom_age_band_file_name: 'Custom Ages Report',
    export_file_citation:
        'Institute for Health Metrics and Evaluation (IHME) at the University of Washington, accessed on: %date%. Used with permission.',
    export_single_age_range_slider_title: 'Select years range',
    export_single_report_type_selector_label: 'Select one',
    export_single_age_modal_cancel_label: 'Cancel',
    export_single_age_modal_submit_label: 'Generate report',
    export_single_age_modal_loading_text: 'Generating your report. This may take a minute or two.',
    export_single_age_disabled_tooltip_label:
        '%unsupported% is not supported for single age data interpolation. Supported: %supported%',
    export_single_age_modal_hint_text:
        '%unsupported% is not supported for single age data interpolation. Report will include only %supported%',
    export_single_report_type_normal: 'Single Ages',
    export_single_report_type_aggregated: 'Aggregate',
    refinement_day: 'Date Range',

    filter_label_cause_sequela_: 'Cause / Sequela',
    filter_label_round_id: 'Data Suite',
    filter_label_forecast_scenario_id: 'Forecast Scenario',
    filter_label_gender_id: 'Sex',
    filter_label_primary_entity_id: 'GBD Id',
    filter_label_underlying_condition: 'Underlying',
    filter_label_day: 'Day',
    filter_label_comorbid_cause_id: 'Comorbid',
    filter_label_month_count: 'Study (Months)',
    filter_label_cases: 'Cases',
    filter_label_age_group_range: 'Age Range',
    filter_label_data_type_id: 'Data Type',
    filter_label_primary_entity_localization_key: 'Condition',
    filter_label_risk_exposure_id: 'Exposure',
    [`filter_label_${CONDITION_LEVEL_KEY}`]: 'Data Type Level',

    filter_label_race_id: 'Race',
    filter_label_education_id: 'Education',
    filter_label_facility_id: 'Facility',
    filter_label_industry_id: 'Industry',
    filter_label_response_id: 'Response',
    filter_label_ethnicity_id: 'Ethnicity',
    filter_label_indicator_id: 'Indicator',
    filter_label_employment_cohort_id: 'Employment Cohort',
    filter_label_employment_status_id: 'Employment Status',
    filter_label_portal_organization_id: 'Portal Organization',
    filter_label_infectious_syndrome_id: 'Infectious Syndrome',
    filter_label_antibiotic_class_id: 'Antibiotic Class',
    filter_label_counterfactual_id: 'Counterfactual',
    filter_label_pathogen_id: 'Pathogen',

    data_license_type_cause_outcome: 'Cause Data',
    data_license_type_sequela_outcome: 'Sequela Data',
    data_license_type_risk_attribution: 'Risk Burden Data',
    data_license_type_risk_prevalence: 'Risk Prevalence Data',
    data_license_type_injury_records: 'Injury Data',
    data_license_type_risk_exposure_records: 'Risk Exposure Data',
    data_license_type_etiology_records: 'Etiology Data',
    data_license_type_impairment_records: 'Impairment Data',
    data_license_type_sev_records: 'SEV Data',
    data_license_type_covariate_records: 'Covariate Data',
    data_license_type_population_records: 'Population Data',
    data_license_type_life_expectancy_records: 'Life Expectancy Data',
    data_license_type_covid19_records: 'Covid-19 Data',
    data_license_type_cause_coprevalence_records: 'Current Co-Prevalence',
    data_license_type_projected_comorbidity_causes_outcome_records: 'Projected New Cases',
    data_license_type_cause_associated_coprevalence_records: 'Associated Current Co-Prevalence',
    data_license_type_cause_associated_comorbidity_study_coefficients:
        'Associated Projected New Cases',
    data_license_type_early_detection_onetime_indicators: 'Early Detection Onetime Indicators',
    data_license_type_early_detection_timeline_indicators: 'Early Detection Timeline Indicators',
    [`data_license_type_${HealthThreatsMetricsRecords}`]: 'Health Threats Metrics Data',
    [`data_license_type_${RaceEthnicityEyeConditionsRecords}`]:
        'Race & Ethnicity Eye Conditions Data',

    data_license_measure_id: 'Measures',
    data_license_metric_id: 'Units',
    data_license_gender_id: 'Sexes',
    data_license_age_group_id: 'Age Groups',
    data_license_cause_id: 'Causes',
    data_license_comorbid_cause_id: 'Comorbid Causes',
    data_license_location_id: 'Locations',
    data_license_forecast_scenario_id: 'Forecast Scenarios',
    data_license_round_id: 'Data Suites',
    data_license_year: 'Years',
    data_license_month_count: 'Duration Of Projection (Months)',
    data_license_day: 'Days',
    data_license_sequela_id: 'Sequelas',
    data_license_covariate_id: 'Covariates',
    data_license_covariate_records: 'Covariates',
    data_license_sev_records: 'SEVs',
    data_license_impairment_records: 'Impairments',
    data_license_etiology_records: 'Etiologies',
    data_license_risk_attribution: 'Risks',
    data_license_risk_prevalence: 'Risks',
    data_license_race_id: 'Races',
    data_license_injury_records: 'Injuries',
    data_license_risk_exposure_records: 'Risks',
    data_license_risk_exposure_id: 'Exposures',

    cause_search: 'Search...',
    sequela_search: 'Search...',
    rei_search: 'Search...',
    covariate_search: 'Search...',

    forecast_scenario_short_1: 'Reference',
    forecast_scenario_short_2: 'Better',
    forecast_scenario_short_3: 'Worse',

    footer_note: `
    <span>
    Questions/Comments? Contact
    <a href="mailto://services@healthdata.org">services@healthdata.org</a>
    </span>
    `,

    cdn_show_more: 'Show More',
    cdn_show_less: 'Show Less',
    cdn_none: 'None',
    cdn_no_codes_for_modeling: 'No codes used for modeling',
    measure_id: 'Measure',
    cdn_condition: 'Condition',
    parent_condition: 'Parent condition',
    cdn_ages_not_modeled: 'All ages not modeled',
    cdn_locations_not_modeled: 'Globally not modeled',

    chart_legend_value: '(Value)',
    chart_legend_upper: '(Upper)',
    chart_legend_lower: '(Lower)',
    chart_legend_forecast_scenario_0_value: '(Past Estimate)',
    chart_legend_forecast_scenario_1_value: '(Reference)',
    chart_legend_forecast_scenario_2_value: '(Better forecast)',
    chart_legend_forecast_scenario_3_value: '(Worse forecast)',

    pdf_report_gbd_title: 'GBD Data Report',
    pdf_report_covid_19_title: 'Covid-19 Data Report',
    pdf_report_morbidity_title: 'Multimorbidity Report',
    pdf_report_lung_cancer_title: 'Extended Lung Cancer Data Report',
    pdf_report_covid_19_testing_protocol_title: 'Covid-19 Testing Protocol Data Report',
    pdf_report_edp_explorer_title: 'Early Detection Program Data Report',
    pdf_report_health_threats_explorer_title: 'Global Health Threats Data Report',
    pdf_report_data_table_too_large:
        'The selected data table is too large to download as a PDF export. You can export the data table separately as a CSV or Excel export.',
    refinement_filters_refine_selection_warning:
        'In order to display data, further refine your selection below.',
    refinement_filters_model_restrictions_warning: ({ Link }) => (
        <React.Fragment>
            <Link>Condition Detail Notes</Link> &nbsp; on model restrictions exist for the selected
            condition/s. &nbsp;<Link>See details.</Link>
        </React.Fragment>
    ),
    pdf_report_modal_title_label: 'Report Title',
    pdf_report_modal_description_label: 'Description',
    pdf_report_modal_description_placeholder: 'Add optional description/notes here',
    pdf_report_modal_controls_label: 'Report Content',
    pdf_report_modal_chart_section_is_disabled_tooltip:
        'Visualizations must be enabled to select this option.',

    filter_label_primary_entity: 'Conditions',
    filter_label_primary_entity_ght: 'Pathogens',
    filter_label_primary_entity_ght_with_tooltip: (Tooltip) => (
        <>
            Pathogens <span style={{ width: 20, display: 'inline-block' }} />
            <Tooltip
                title={
                    <div>
                        The pathogens below are being actively monitored but there is not enough
                        activity to model at this time.
                        <u>
                            <li>Novel Flu</li>
                            <li>Novel Sars</li>
                            <li>Ebola</li>
                            <li>Cholera</li>
                            <li>Polio</li>
                        </u>
                    </div>
                }
            >
                <span>{infoIcon} Other pathogens</span>
            </Tooltip>
        </>
    ),
    filter_label_x_axis_dimension: 'Axis dimension',
    filter_label_dimension: 'Dimension',
    filter_label_map_detail_level: 'Map Detail',
    filter_label_map_type: 'Map Type',

    // Lists
    lists_my_lists_header: 'My Lists',
    lists_public_lists_header: 'Public Lists',
    lists_add_new: '+ New List',
    lists_manage_lists: 'Manage Lists',
    lists_add_to_list: 'Add To List',
    default_list_name: 'My Favorites',
    default_group_list_name: 'Group Content',
    lists_add_new_modal_title: 'New List',
    lists_edit_modal_title: 'Edit List',
    lists_remove_modal_title: 'Remove List',
    lists_remove_modal_question: 'Are you sure?',
    lists_remove_resource_modal_title: 'Remove Item',
    lists_unstar_resource_modal_title: 'Remove item from list',
    lists_add_new_tab: '+ New Tab',
    lists_make_list_private: 'Make list private',
    lists_make_list_public: 'Make list public',
    lists_make_list_default: 'Make list default',
    lists_make_list_non_default: 'Disable as default list',
    lists_subscribe_button: 'Subscribe',
    lists_unsubscribe_button: 'Unsubscribe',
    lists_list_is_empty:
        "This list doesn't have any items yet. You can add files and links provided by your Organization. Or add your favorite Data Explorer configurations.",

    resource_list_field_label_name: 'Name',
    resource_list_field_label_description: 'Description',

    arrow_chart_location_id_dimension: 'Locations',
    arrow_chart_primary_entity_dimension: 'Conditions',
    arrow_chart_cause_id_dimension: 'Conditions',
    arrow_chart_race_id_dimension: 'Races',

    bar_chart_location_id_dimension: 'Location',
    bar_chart_age_group_id_dimension: 'Age',
    bar_chart_gender_id_dimension: 'Sex',
    bar_chart_year_dimension: 'Year',
    bar_chart_day_dimension: 'Day',
    bar_chart_race_id_dimension: 'Race',
    bar_chart_education_id_dimension: 'Education',

    tooltip_label_year: 'Year',
    tooltip_label_day: 'Day',
    tooltip_label_location_id: 'Location',
    tooltip_label_comorbid_cause_id: 'Comorbid Condition',
    tooltip_label_age_group_id: 'Age',
    tooltip_label_gender_id: 'Sex',
    tooltip_label_metric_id: 'Unit',
    tooltip_label_measure_id: 'Measure',
    tooltip_label_cause_id: 'Cause',
    tooltip_label_forecast_scenario_id: 'Forecast scenario',
    tooltip_label_month_count: 'Study (Months)',
    tooltip_label_risk_exposure_id: 'Exposure',
    tooltip_label_facility_id: 'Facility',
    tooltip_label_industry_id: 'Industry',
    tooltip_label_response_id: 'Response',
    tooltip_label_ethnicity_id: 'Ethnicity',
    tooltip_label_indicator_id: 'Indicator',
    tooltip_label_employment_cohort_id: 'Employment Cohort',
    tooltip_label_employment_status_id: 'Employment Status',
    tooltip_label_portal_organization_id: 'Portal Organization',
    tooltip_label_race_id: 'Race',
    tooltip_label_antibiotic_class_id: 'Antibiotic Class',
    tooltip_label_counterfactual_id: 'Counterfactual',
    tooltip_label_pathogen_id: 'Pathogen',
    tooltip_label_education_id: 'Education',
    tooltip_label_infectious_syndrome_id: 'Infectious Syndrome',
    [`tooltip_label_${PRIMARY_ENTITY_ID_LOCALIZATION_KEY}`]: 'Condition',

    population_1: 'Population',
    life_expectancy_1: 'Life Expectancy',
    covid19_1: 'Covid-19',

    yes_button: 'Yes',
    ignore_and_continue_button: 'Ignore and Continue',
    disable_charts_and_continue_button: 'Disable Charts and Continue',

    data_explorer_select_data: 'Select Data',
    data_explorer_select_all: 'Select All',
    data_explorer_select_more: 'Select More...',
    data_explorer_remove: 'Remove',

    data_explorer_filters_amount_warning:
        'The scope of data is large and may impact performance of the Data Explorer. You can ignore this warning and proceed to run the query or cancel.',
    data_explorer_filters_chart_limit_exceeded:
        'The scope of data is too large to visualize in charts. As a result, charts will be disabled for this query.',
    data_explorer_filters_limit_exceeded:
        'The scope of data is too large to query. Try reducing the number of filter selections.',
    data_explorer_charts_data_limit_receded:
        'Charts have been re-enabled based on your new data selection.',
    data_explorer_charts_data_limit_exceeded:
        'The scope of data is too large to visualize. Reduce the number of filter selections to re-enable charts.',

    map_detail_level_0: 'National',
    map_detail_level_1: 'Subnational',
    map_detail_level_2: 'Detail Subnational',
    map_detail_level_3: 'Borough',

    treemap_detail_level_1: 'Level 1',
    treemap_detail_level_2: 'Level 2',
    treemap_detail_level_3: 'Level 3',

    chart_axis_label_day: 'Dates',
    chart_axis_label_year: 'Years',
    chart_axis_label_start_date: 'Weeks',
    chart_axis_label_month_count: 'Study (Months)',
    chart_axis_label_location_id: 'Locations',
    chart_axis_label_age_group_id: 'Ages',
    chart_axis_label_gender_id: 'Sexes',
    chart_axis_label_race_id: 'Race',

    sev_records_long_metric_3: 'Exposure per 100',
    ght_records_measure_2: 'DALYs per 100k',
    ght_records_measure_10005: 'Cases',

    content_no_longer_available_title: 'Content is No Longer Available',
    content_no_longer_available_message:
        'This list item relies on content that has been removed or expired.<br/> For questions/concerns please reach out to services@healthdata.org.',

    gbd_compare_access_tooltip:
        'To access GBD Compare use the following login:<br/><div><b>Username:</b> ihmeclient</div><div><b>Password:</b> discoverydatum</div>',
    gbd_results_access_tooltip:
        'To access GBD Results use the following login:<br/><div><b>Username:</b> ihmeclient</div><div><b>Password:</b> discoverydatum</div>',

    [`data_tool_data_${DATA_EXPLORER_PATH}`]: 'GBD',
    [`data_tool_data_${COVID_19_EXPLORER_PATH}`]: 'Covid-19',
    [`data_tool_data_${MORBIDITY_EXPLORER_PATH}`]: 'Multimorbidity',
    [`data_tool_data_${EDP_EXPLORER_PATH}`]: 'EDP',
    [`data_tool_data_${SIMULATION_EXPLORER_PATH}`]: 'Simulation',
    [`data_tool_data_${HEALTH_THREATS_METRICS_PATH}`]: 'Health Threats',

    [`data_tool_${DATA_EXPLORER_PATH}`]: 'Data Explorer',
    [`data_tool_${COVID_19_EXPLORER_PATH}`]: 'Covid-19 Explorer',
    [`data_tool_${MORBIDITY_EXPLORER_PATH}`]: 'Multimorbidity Explorer',
    [`data_tool_${EDP_EXPLORER_PATH}`]: 'Early Detection Program Explorer',
    [`data_tool_${SIMULATION_EXPLORER_PATH}`]: 'Simulation Explorer',
    [`data_tool_${HEALTH_THREATS_METRICS_PATH}`]: 'Health Threats',

    export_trigger_line: 'Timeline Chart',
    export_trigger_map: 'Map Chart',
    export_trigger_bar: 'Bar Chart',
    export_trigger_treemap: 'Treemap Chart',
    export_trigger_scatter_map: 'Map Chart',
    export_trigger_arrow: 'Arrow Chart',
    export_trigger_data_table: 'Data',
    export_trigger_pdf_report: 'Report',

    data_explorer_share_permalink_btn: 'Share',
    share_permalink_copy_btn: 'Copy Permalink',
    share_permalink_title: 'Share Permalink',
    share_permalink_input_label: 'Name Permalink',
    share_permalink_nav_link_label: 'Permalink',
    share_permalink_message:
        'Data resources can only be shared with members inside your organization. A copy of the Permalink can be found in the Permalink list, in Favorite Content.',
    permalink_unavailable_data: 'Underlying data is unavailable',

    cause_coprevalence_records_tooltip:
        'Estimates of current co-prevalence of disease, based on most-recent year available from the Global Burden of Disease Study.',
    projected_comorbidity_causes_outcome_records_tooltip:
        'Future projections of new cases of co-occurring disease among a hypothetical population and duration that you can specify.',

    cause_associated_coprevalence_records_tooltip:
        'Estimates of current co-prevalence of disease, based on most-recent year available from the Global Burden of Disease Study.',
    cause_associated_comorbidity_study_coefficients_tooltip:
        'Future projections of new cases of co-occurring disease among a hypothetical population and duration that you can specify.',

    comorbidity_type_dropdown_label: 'Data Type',
    comorbidity_correlation_factor_label: 'Relative risk of co-occurrence of diseases',
    comorbidity_participants_number_label: 'Size Of Synthetic Population',
    comorbidity_condition_label: 'Underlying Condition',
    comorbidity_condition_placeholder: 'Search',

    comorbidity_all_tab: 'Co-Occurrence',
    comorbidity_all_tab_select_data:
        'Independent Co-occurrence of diseases estimated using the Global Burden of Disease Study',
    comorbidity_all_tab_tooltip: (
        <div>
            <li>
                Using this data set, a user can view estimates, by age and sex, of the co-occurrence
                (also known as co-prevalence) and incidence of a wide range of disease pairs among
                the general population in the United States based on data from the Global Burden of
                Disease.
            </li>
            <li>
                The estimates in this data set provide a background rate of co-occurrence assuming{' '}
                <i>no increased risk</i> of a comorbid diseases among those with an underlying
                disease. These estimates assume independence between the occurrence of pairs of
                diseases.
            </li>
            <li>
                The rates of each disease in the selected population determine the estimated
                co-occurrence rate.
            </li>
            <li>
                Co-occurrence can be considered a plausible lower bound estimate of comorbidity for
                any two conditions, excluding cases where a first disease confers a protective
                effect for the second disease.
            </li>
        </div>
    ),

    comorbidity_associated_tab: 'Comorbidity',
    comorbidity_associated_tab_select_data:
        'Comorbidity estimated using the Global Burden of Disease Study and representative (USA) population survey data',
    comorbidity_associated_tab_tooltip: (
        <div>
            <li>
                Using this data set, a user can view estimates, by age and sex, of comorbid
                prevalence for approximately 500 disease pairs among the general population in the
                United States based on information from the Global Burden of Disease Study and the
                Medical Expenditures Panel Survey (MEPS), a nationwide study of health care use and
                expenditures conducted by the Agency for Healthcare Research and Quality (AHRQ)
            </li>
            <li>
                The estimates in this data set account for the increased risk of comorbid diseases
                conditional on having an underlying disease.
            </li>
            <li>
                The rates of each disease in the selected population and the probability that they
                co-occur as observed among subjects of the MEPS panel survey determine the estimated
                comorbidity rate.
            </li>
        </div>
    ),

    comorbidity_all_summary:
        'Among an estimated %prevalenceCauseValue% individuals, all ages, with %primaryCondition% in %location%, an estimated %coPrevalencePercent%% (%value% individuals) have %comorbidCondition% as a comorbidity. Results for queried age and sex groups are shown in table below.',
    comorbidity_associated_summary:
        'Among an estimated %prevalenceCauseValue% individuals aged 20+ with %primaryCondition% in %location%, an estimated %coPrevalencePercent%% (%value% individuals) have %comorbidCondition% as a comorbidity. Results for queried age and sex groups are shown in table below.',
    comorbidity_summary_annotation: '*Uncertainty available for all values',

    comorbidity_study_coefficients_summary:
        'Among patients with %primaryCondition%, all ages, in %location%, an estimated %value% per 100,000 would be expected to develop %comorbidCondition% over a 12-month period. Results for queried age and sex groups are shown in table below.',

    comorbidity_summary_gender_1: 'males',
    comorbidity_summary_gender_2: 'females',
    comorbidity_summary_gender_3: 'individuals',

    data_table_filter_select_columns: 'Select Columns',

    export_to_pdf: 'Export to PDF',
    export_pdf_button_disabled_tooltip:
        'The scope of data is too large to export for PDF. Try reducing the number of filter selections.',

    export_excel_button: 'Excel',
    export_excel_button_disabled_tooltip:
        'The scope of data is too large to export in Excel. Try reducing the number of filter selections.',

    export_csv_button: 'CSV',
    export_csv_button_disabled_tooltip:
        'The scope of data is too large to export in CSV. Try reducing the number of filter selections.',

    data_explorer_widget_description: 'Visualize estimates in tables and charts.',
    data_explorer_widget_explore: 'Explore',

    submit_button: 'Submit',
    password_protected_tool_text:
        'Please enter the Multiple Myeloma Simulation Explorer password to access the tool.',

    share_more: 'Share More:',

    increased: 'increased',
    decreased: 'decreased',
    not_changed: 'not changed',

    total: 'Total',

    prob_level_1: 'Level 1',
    prob_level_2: 'Level 2',
    prob_level_3: 'Level 3',
    prob_level_4: 'Level 4',
    prob_level_5: 'Level 5',
    prob_level_6: 'Level 6',
    prob_level_7: 'Level 7',
    prob_level_8: 'Level 8',

    chart_error_ght_no_data:
        'Currently monitoring %pathogen% in %location%; there is not enough activity to model.',

    smart_option_causes_level_2: 'Select All Causes Level 2',
    smart_option_causes_level_3: 'Select All Causes Level 3',
    smart_option_causes_level_4: 'Select All Causes Level 4',

    please_specify: 'Please specify',
};
