import React from 'react';
import copy from 'clipboard-copy';

import { Organization } from '@portal/common/types';
import {
    Button,
    H3,
    Modal,
    ModalBody,
    ModalButton,
    ModalFooter,
    ModalHeader,
} from '@portal/common/components';
import { styled } from '@portal/common/theme';

import _ from '../../locale';
import config from '../../config';
import { REGISTRATION_PATH } from '../../router/paths';

const ButtonsGroup = styled.div({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 20,
});

type Props = {
    organization: Organization;
    isVisible: boolean;
    onClose: () => void;
};

const capitalize = (str, lower = false) =>
    (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
        match.toUpperCase()
    );
const SelfRegistrationUrlModal: React.FC<Props> = ({
    isVisible,
    organization: { self_sign_up_token },
    onClose,
}) => {
    const url = config.clientUrl + REGISTRATION_PATH + '?token=' + self_sign_up_token;

    return (
        <Modal show={isVisible} onHide={onClose}>
            <ModalHeader closeButton>
                <H3>{capitalize(_.get('self_sign_up_token_label'))}</H3>
            </ModalHeader>
            <ModalBody>
                <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
                    {url}
                    <ButtonsGroup>
                        <Button onClick={() => copy(url)}>{_.get('self_sign_up_copy_link')}</Button>
                    </ButtonsGroup>
                </div>
            </ModalBody>
            <ModalFooter>
                <ModalButton onClick={onClose}>{_.get('ok_button')}</ModalButton>
            </ModalFooter>
        </Modal>
    );
};

export default SelfRegistrationUrlModal;
