import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { css } from 'emotion';
import { withTheme } from 'emotion-theming';
import { RootState } from 'MyTypes';

import { withStorage } from '@ihme/common/packages/storage';
import { Clearfix, Form } from '@ihme/common/web/components';

import {
    Alert,
    Button,
    ButtonDropdown,
    FlexColumn,
    FlexRow,
    FormGroup,
    Link,
} from '@portal/common/components';
import {
    AGE_GROUP_ID_KEY,
    ANTIBIOTIC_CLASS_ID_KEY,
    CAUSE_ID_KEY,
    COUNTERFACTUAL_ID_KEY,
    DAY_KEY,
    EDUCATION_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    INFECTIOUS_SYNDROME_ID_KEY,
    LOCATION_ID_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    PATHOGEN_ID_KEY,
    RACE_ID_KEY,
    RISK_EXPOSURE_ID_KEY,
    ROUND_ID_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import { getDataTypePrimaryEntityKey } from '@portal/common/models/data-type';
import { styled } from '@portal/common/theme';
import { DataGranularity, DataKey, RefinementFilterConfig } from '@portal/common/types';

import {
    addVisibleRefinementFilters,
    changeSelectedDataType,
    dataSelectionValidation,
    resetDataTool,
} from '../../store/data-explorer/actions';
import {
    getCombinedFiltersAmount,
    getDataToolConfig,
    getDataToolDataCollections,
    getSelectedConditions,
    getSelectedConditionsDataTypes,
    getSelectedConditionsDataTypesDefaultFilters,
    getSelectedConditionsDataTypesGranularity,
    getSelectedConditionsRefinedGranularity,
    getSelectedRefinementFiltersWithFallbackToDefaultFilters,
    getVisibleRefinementFilters,
    hasCdnRestrictions,
    hasErrorInRefinements,
    hasFiltersChangedSinceLastRequest,
    isDataLoading,
} from '../../store/data-explorer/selectors';
import { getSelectedDataCollectionForDataTool } from '../../store/user-settings/selectors';
import sortRefinementsOptions from '../../utility/sort-refinements-options';
import _ from '../../locale';

import ChartFilterReset from '../../components/ChartFilterReset';
import RefinementFilter from './RefinementFilter';
import ExportSingleAgeEstimates from './ExportSingleAgeEstimates';
import RunQueryButton from '../RunQueryButton';
import { intersection, isEmpty, sortBy } from 'lodash/fp';
import ToggleChartsVisibilityButton from '../ToggleChartsVisibilityButton';

export const REFINEMENT_OPTIONS = sortRefinementsOptions([
    INFECTIOUS_SYNDROME_ID_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    GENDER_ID_KEY,
    AGE_GROUP_ID_KEY,
    CAUSE_ID_KEY,
    RISK_EXPOSURE_ID_KEY,
    LOCATION_ID_KEY,
    YEAR_KEY,
    DAY_KEY,
    ROUND_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    RACE_ID_KEY,
    PATHOGEN_ID_KEY,
    ANTIBIOTIC_CLASS_ID_KEY,
    COUNTERFACTUAL_ID_KEY,
    EDUCATION_ID_KEY,
]);

export const REFINEMENT_FILTERS_CONFIG: RefinementFilterConfig = {
    [INFECTIOUS_SYNDROME_ID_KEY]: { isMulti: true },
    [MEASURE_ID_KEY]: {
        isMulti: true,
    },
    [CAUSE_ID_KEY]: {
        isSearchable: true,
        isMulti: true,
    },
    [LOCATION_ID_KEY]: {
        isSearchable: true,
        isMulti: true,
    },
    [AGE_GROUP_ID_KEY]: { isMulti: true },
    [FORECAST_SCENARIO_ID_KEY]: { isMulti: true },
    [GENDER_ID_KEY]: { isMulti: true },
    [METRIC_ID_KEY]: { isMulti: true },
    [YEAR_KEY]: { isMulti: true, undefinedWhenAllSelected: true },
    [RISK_EXPOSURE_ID_KEY]: { isMulti: true },
    [RACE_ID_KEY]: { isMulti: true },
    [PATHOGEN_ID_KEY]: { isMulti: true },
    [ANTIBIOTIC_CLASS_ID_KEY]: { isMulti: true },
    [COUNTERFACTUAL_ID_KEY]: { isMulti: true },
    [EDUCATION_ID_KEY]: { isMulti: true },
};

const mapStateToProps = (state: RootState) => ({
    dataCollections: getDataToolDataCollections(state),
    defaultFilters: getSelectedConditionsDataTypesDefaultFilters(state),
    selectedConditions: getSelectedConditions(state),
    selectedConditionsDataTypes: getSelectedConditionsDataTypes(state),
    selectedDataCollection: getSelectedDataCollectionForDataTool(state),
    selectedRefinementFilters: getSelectedRefinementFiltersWithFallbackToDefaultFilters(state),
    visibleRefinementFilters: getVisibleRefinementFilters(state),
    granularity: getSelectedConditionsDataTypesGranularity(state),
    refinedGranularity: getSelectedConditionsRefinedGranularity(state),
    hasCdnRestrictions: hasCdnRestrictions(state),
    dataToolConfig: getDataToolConfig(state),
    errorInRefinements: hasErrorInRefinements(state),
    filtersChangedSinceLastRequest: hasFiltersChangedSinceLastRequest(state),
    isLoadingData: isDataLoading(state),
    yearRefinementFilterMode: state.dataExplorer.yearRefinementFilterMode,
    dayRefinementFilterMode: state.dataExplorer.dayRefinementFilterMode,
    organization: state.organization,
    combinedFiltersAmount: getCombinedFiltersAmount(state),
});

const dispatchProps = {
    resetDataTool: resetDataTool,
    changeSelectedDataType: changeSelectedDataType,
    addVisibleRefinementFilters: addVisibleRefinementFilters,
    queryDataValidationRequest: dataSelectionValidation.request,
};

const LinkToCDN = ({ children, ...props }) => (
    <Link href="#cdn" style={{ color: 'white', textDecoration: 'underline' }} {...props}>
        {children}
    </Link>
);

const StyledAlert = styled(Alert)(() => ({
    marginBottom: 15,
}));

const BASIC_MODE = 'basic';
const ADVANCED_MODE = 'advanced';

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & {
        refinementFiltersConfig?: RefinementFilterConfig;
        areChartsVisible: boolean;
        toggleChartsVisibility: () => void;
    };

type State = {
    viewMode: typeof BASIC_MODE | typeof ADVANCED_MODE;
};

class RefinementFiltersControls extends React.Component<Props, State> {
    static defaultProps = {
        refinementFiltersConfig: REFINEMENT_FILTERS_CONFIG,
    };

    state: State = {
        viewMode: BASIC_MODE,
    };

    static getDerivedStateFromProps = (props: Props, state: State) =>
        props.dataToolConfig &&
        !props.dataToolConfig.enableBasicRefinementFiltersMode &&
        state.viewMode !== ADVANCED_MODE
            ? { viewMode: ADVANCED_MODE }
            : null;

    reset = () => {
        const { resetDataTool, dataToolConfig } = this.props;
        this.setState({ viewMode: BASIC_MODE });
        resetDataTool(dataToolConfig);
    };

    // get available refinements based on given granularity and condition
    getAvailableRefinementsForRefinedGranularity = (granularity: DataGranularity) => {
        const { selectedConditions, selectedRefinementFilters } = this.props;

        const conditionFilters =
            selectedConditions.length === 0
                ? {}
                : selectedConditions.reduce((acc, i) => {
                      const key = getDataTypePrimaryEntityKey(i.data_type);
                      const val = i.primary_entity_id;
                      if (acc[key]) {
                          acc[key] += `,${val}`;
                      } else {
                          acc[key] = val;
                      }
                      return acc;
                  }, {});

        return (
            REFINEMENT_OPTIONS.filter((i) => granularity.hasOwnProperty(i))
                // filter granularity with values more than 1 or that one value isn't selected
                .filter((i) => {
                    const filterGranularity = granularity[i];
                    const selectedValue = selectedRefinementFilters[i];
                    const isSelectedValuePartOfGranularity =
                        isEmpty(selectedValue) ||
                        !isEmpty(intersection(selectedValue, filterGranularity));
                    return (
                        filterGranularity != null &&
                        (filterGranularity.length > 1 || !isSelectedValuePartOfGranularity)
                    );
                })
                .filter((i) => !conditionFilters.hasOwnProperty(i))
        );
    };

    handleAddRefinementClick = (filterKey) => {
        this.props.addVisibleRefinementFilters(filterKey);
    };

    toggleAdvancedMode = () => {
        this.setState((state) => ({ viewMode: ADVANCED_MODE }));
    };

    toggleBasicMode = () => {
        this.setState((state) => ({ viewMode: BASIC_MODE }));
    };

    /**
     * @todo: I have a doubt that it doesn't work and is useless
     */
    getHiddenRefinementFilters = (): DataKey[] => {
        const { hiddenRefinementFilters } = this.props.dataToolConfig;
        return hiddenRefinementFilters && hiddenRefinementFilters.length
            ? hiddenRefinementFilters
            : [];
    };

    /**
     * There are two modes simple and advanced
     * For advanced showing all that's present in granularity
     * In simple we are showing enabled filters from visibleRefinementFilters
     * visibleRefinementFilters is also changed by validateVisibleRefinementFilters
     */
    renderFiltersControls = () => {
        const { viewMode } = this.state;
        const {
            visibleRefinementFilters,
            refinedGranularity,
            granularity,
            selectedRefinementFilters,
            refinementFiltersConfig,
            yearRefinementFilterMode,
            dayRefinementFilterMode,
            dataToolConfig,
        } = this.props;

        const fallbackGranularity = refinedGranularity || granularity;

        const hiddenFilters = this.getHiddenRefinementFilters();
        const availableRefinementFilters = this.getAvailableRefinementsForRefinedGranularity(
            fallbackGranularity
        ).filter((key) => !hiddenFilters.includes(key));

        const createOnChange = (key) => (value) => {
            this.handleAddRefinementClick(key);
        };

        const isAdvancedMode = viewMode === ADVANCED_MODE;

        // decides which filters are displayed
        const filters = sortRefinementsOptions(
            isAdvancedMode ? availableRefinementFilters : visibleRefinementFilters
        );
        return filters.map((filterKey) => {
            const mode = {
                [YEAR_KEY]: yearRefinementFilterMode,
                [DAY_KEY]: dayRefinementFilterMode,
            }[filterKey];

            return (
                <React.Fragment key={filterKey}>
                    <RefinementFilter
                        refinementFiltersConfig={refinementFiltersConfig}
                        key={filterKey}
                        mode={mode}
                        granularityKey={filterKey}
                        availableRefinementFilters={availableRefinementFilters}
                        selectedValue={selectedRefinementFilters[filterKey]}
                        hideSelectAll={!!dataToolConfig.areRefinementFiltersSmartOptionsDisabled}
                        {...(isAdvancedMode && {
                            hideDelete: true,
                            onChange: createOnChange(filterKey),
                        })}
                    />
                    {filterKey === AGE_GROUP_ID_KEY && <ExportSingleAgeEstimates />}
                </React.Fragment>
            );
        });
    };

    renderActionButtons = () => {
        const { viewMode } = this.state;
        const { visibleRefinementFilters, granularity, refinedGranularity, dataToolConfig } =
            this.props;

        const resetBtn =
            dataToolConfig && !dataToolConfig.disableResetRefinementFilters ? (
                <ChartFilterReset onClick={this.reset} />
            ) : null;

        if (dataToolConfig && !dataToolConfig.enableBasicRefinementFiltersMode) {
            return resetBtn;
        }

        const labelGetter = (i) => _('refinement_' + i);
        const fallbackGranularity = refinedGranularity || granularity;
        const availableRefinementFilters = sortBy(
            labelGetter,
            this.getAvailableRefinementsForRefinedGranularity(fallbackGranularity).filter(
                (i) => !visibleRefinementFilters.includes(i)
            )
        );

        switch (viewMode) {
            case BASIC_MODE:
                return (
                    <>
                        {availableRefinementFilters.length > 0 && (
                            <div className={css({ width: 230, marginRight: 20 })}>
                                <ButtonDropdown
                                    id="add-refinement"
                                    dataTestid="add-refinement"
                                    options={availableRefinementFilters}
                                    optionLabelGetter={labelGetter}
                                    label={_('refinement_button_label')}
                                    onChange={(ev) => {
                                        this.handleAddRefinementClick(ev.target.value);
                                    }}
                                    isSecondary
                                />
                            </div>
                        )}
                        <Button
                            data-testid="toggle-advanced-mode"
                            style={{ marginRight: 20 }}
                            onClick={this.toggleAdvancedMode}
                        >
                            {_.get('select_data_advanced_search')}
                        </Button>
                        {resetBtn}
                    </>
                );
            case ADVANCED_MODE:
                return (
                    <>
                        <Button style={{ marginRight: 20 }} onClick={this.toggleBasicMode}>
                            {_.get('select_data_basic_search')}
                        </Button>
                        {resetBtn}
                    </>
                );
        }
    };

    render() {
        const {
            dataCollections,
            selectedConditionsDataTypes,
            selectedRefinementFilters,
            selectedConditions,
            queryDataValidationRequest,
            errorInRefinements,
            isLoadingData,
            filtersChangedSinceLastRequest,
            combinedFiltersAmount,
            areChartsVisible,
            toggleChartsVisibility,
        } = this.props;

        if (!dataCollections || selectedConditionsDataTypes == null) {
            return null;
        }

        return (
            <div style={{ background: 'white' }}>
                {this.props.hasCdnRestrictions && (
                    <StyledAlert color="info" position="middle">
                        {_('refinement_filters_model_restrictions_warning')({
                            Link: LinkToCDN,
                        })}
                    </StyledAlert>
                )}
                {errorInRefinements && (
                    <StyledAlert color="warning" position="middle">
                        {_.get('refinement_filters_refine_selection_warning')}
                    </StyledAlert>
                )}
                <Form>
                    <FlexColumn>{this.renderFiltersControls()}</FlexColumn>

                    <FlexRow align="center" justify="space-between" style={{ marginTop: 20 }}>
                        <FlexRow align="center">
                            {this.renderActionButtons()}
                            <FormGroup style={{ minWidth: 160, margin: '0px 0px 0px 20px' }}>
                                <ToggleChartsVisibilityButton
                                    {...{ areChartsVisible, toggleChartsVisibility }}
                                />
                            </FormGroup>
                        </FlexRow>
                        <RunQueryButton
                            isLoading={isLoadingData}
                            disabled={
                                isLoadingData ||
                                errorInRefinements ||
                                !filtersChangedSinceLastRequest
                            }
                            onClick={() => {
                                queryDataValidationRequest({
                                    conditions: selectedConditions,
                                    refinementFilters: selectedRefinementFilters,
                                });
                            }}
                            filtersAmount={combinedFiltersAmount}
                        />
                    </FlexRow>
                    <Clearfix />
                </Form>
            </div>
        );
    }
}

export default compose(
    withStorage,
    withRouter,
    withTheme,
    connect(mapStateToProps, dispatchProps)
)(RefinementFiltersControls);
