import React from 'react';
import copy from 'clipboard-copy';

import Loading from '@ihme/common/components/Loading';
import { FormControl } from '@ihme/common/web/components';
import { FormGroup, Modal } from '@portal/common/components';

import { styled } from '@portal/common/theme';
import {
    Alert,
    Button,
    CsvInput,
    FieldLabel,
    H3,
    ModalBody,
    ModalButton,
    ModalFooter,
    ModalHeader,
    Radio,
} from '@portal/common/components';
import renderErrors from '@portal/common/utility/renderErrors';
import { Organization } from '@portal/common/types';

import _ from '../../locale';
import config from '../../config';
import { REGISTRATION_PATH } from '../../router/paths';

const StyledFieldLabel = styled(FieldLabel)({
    marginBottom: 10,
    'not:first-child': {
        marginTop: 20,
    },
});

const StyledInfoAlert = styled(Alert)({
    marginTop: 15,
});

const ButtonsGroup = styled.div({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 20,
});

type Props = {
    data: object;
    onDataChange: (value: any) => void;
    isVisible: boolean;
    onClose: () => void;
    onSubmit: () => void;
    errors: object | null;
    isLoaded: boolean;
    isSubmittable: boolean;
    onRegenerateToken: (organization: Organization) => void;
};

export default class ModalForm extends React.Component<Props> {
    formFields = Object.entries({
        self_sign_up_mode: {
            Control: ({
                value,
                onChange,
            }: {
                value: Organization['self_sign_up_mode'];
                onChange: () => void;
            }) => (
                <FormGroup>
                    <Radio
                        name="self_sign_up_mode"
                        value="none"
                        checked={value === 'none'}
                        onChange={onChange}
                    >
                        {_.get('self_sign_up_mode_none')}
                    </Radio>
                    <Radio
                        name="self_sign_up_mode"
                        value="domain"
                        checked={value === 'domain'}
                        onChange={onChange}
                    >
                        {_.get('self_sign_up_mode_email')}
                    </Radio>
                    <Radio
                        name="self_sign_up_mode"
                        value="url"
                        checked={value === 'url'}
                        onChange={onChange}
                    >
                        {_.get('self_sign_up_mode_link')}
                    </Radio>
                </FormGroup>
            ),
        },
        email_domains: {
            shouldBeVisible: (data) => data.self_sign_up_mode === 'domain',
            Control: CsvInput,
            placeholder: 'domain1.com, domain2.com',
            infoText: _.get('email_domains_hint'),
        },
        self_sign_up_token: {
            shouldBeVisible: (data) => data.self_sign_up_mode === 'url' && data.self_sign_up_token,
            Control: ({ value: token }) => {
                const url = config.clientUrl + REGISTRATION_PATH + '?token=' + token;

                return (
                    <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
                        {url}
                        <ButtonsGroup>
                            <Button onClick={() => copy(url)}>
                                {_.get('self_sign_up_copy_link')}
                            </Button>
                            <Button onClick={this.props.onRegenerateToken}>
                                {_.get('self_sign_up_regenerate_link')}
                            </Button>
                        </ButtonsGroup>
                    </div>
                );
            },
        },
    });

    render() {
        const { isVisible, onClose, errors, isLoaded, onSubmit, isSubmittable } = this.props;

        return (
            <Modal show={isVisible} onHide={onClose}>
                <ModalHeader closeButton>
                    <H3>{_.get('self_sign_up_options_title')}</H3>
                </ModalHeader>
                <ModalBody>
                    {errors && <Alert color="error">{renderErrors(errors)}</Alert>}

                    {isLoaded ? (
                        this.formFields.map(
                            (
                                [
                                    name,
                                    {
                                        Control = FormControl,
                                        type,
                                        placeholder,
                                        value = this.props.data[name],
                                        label = _(`${name}_label`),
                                        infoText,
                                        shouldBeVisible = () => true,
                                    },
                                ],
                                index
                            ) =>
                                shouldBeVisible(this.props.data) && (
                                    <FormGroup key={name} controlId={name + '-input'}>
                                        <StyledFieldLabel>{label}</StyledFieldLabel>
                                        <Control
                                            autoFocus={index === 0}
                                            type={type || 'text'}
                                            name={name}
                                            placeholder={placeholder || label}
                                            value={value}
                                            onChange={this.props.onDataChange}
                                        />
                                        {infoText && (
                                            <StyledInfoAlert color="warning">
                                                {infoText}
                                            </StyledInfoAlert>
                                        )}
                                    </FormGroup>
                                )
                        )
                    ) : (
                        <Loading />
                    )}
                </ModalBody>
                <ModalFooter>
                    <ModalButton color="secondary" onClick={onClose}>
                        {_.get('cancel_button')}
                    </ModalButton>
                    <ModalButton onClick={onSubmit} disabled={!isSubmittable}>
                        {_.get('save_button')}
                    </ModalButton>
                </ModalFooter>
            </Modal>
        );
    }
}
