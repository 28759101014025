import { RootState } from 'MyTypes';

import { getSelectedDataCollectionForDataTool } from '../store/user-settings/selectors';
import { getSelectedConditionsDataTypes } from '../store/data-explorer/selectors';

export default (state: RootState): string | null => {
    const resourceId = getSelectedDataCollectionForDataTool(state)?.id;
    const dataType = getSelectedConditionsDataTypes(state)?.[0];

    //@todo: handle case with multiple selected data types
    if (resourceId && dataType) {
        return `${resourceId}_${dataType}`;
    }
    return null;
};
