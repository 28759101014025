import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'emotion-theming';
import * as PopperJS from '@popperjs/core';

import {
    BodyText2,
    Button,
    FieldLabel,
    FlexRow,
    FormGroup,
    Popover,
    SvgIcon,
} from '@portal/common/components';
import { DataType } from '@portal/common/types';
import { AllDataTypes } from '@portal/common/models/data-type';
import { styled } from '@portal/common/theme';

import { getSelectedDataType } from '../../store/data-explorer/selectors';
import { DATA_TYPE_ORDER } from '../../models/data-explorer-config';
import _ from '../../locale';

const StyledButton = styled(Button)({
    minWidth: 52,
    width: 52,
});

const Circle = styled.div(({ theme }) => ({
    position: 'absolute',
    background: '#58B664',
    width: 10,
    height: 10,
    borderRadius: '50%',
    marginLeft: 18,
    marginBottom: 18,
}));

const Menu = styled.div(({ theme }) => ({
    backgroundColor: theme.color.secondary1,
    borderRadius: theme.borderRadius.base,
    maxHeight: 312,
    overflowY: 'scroll',
}));

const Item = styled(FlexRow)<{ selected: boolean }>(({ theme, selected }) => ({
    height: 52,
    padding: '0 16px',
    backgroundColor: theme.color.secondary1,
    borderRadius: theme.borderRadius.base,
    ':hover': {
        backgroundColor: theme.color.secondary2,
    },
    cursor: 'pointer',

    ...(selected && {
        color: theme.color.primary1,
        cursor: 'default',
    }),
}));

type Props = {
    className?: string;
    style?: CSSProperties;
    isDisabled?: boolean;
    value: DataType;
    onChange: (value: DataType) => void;
    options: DataType[] | null;
    menuPlacement?: PopperJS.Placement;
};

const ConditionTypeDropdown = ({
    value,
    onChange,
    style,
    options,
    menuPlacement = 'bottom-start',
}: Props) => {
    const selectedDataType = value || useSelector(getSelectedDataType) || AllDataTypes;
    const isAnyDataTypeSelected = selectedDataType !== AllDataTypes;

    const sortedOptions = (options || []).sort(
        (v1, v2) => DATA_TYPE_ORDER.indexOf(v1) - DATA_TYPE_ORDER.indexOf(v2)
    );

    if (sortedOptions.length < 3) {
        // Only All types and one type means that nothing would change
        return null;
    }

    return (
        <FormGroup style={style}>
            <FieldLabel>{_('condition_type_dropdown_label')}</FieldLabel>
            <Popover
                placement={menuPlacement}
                renderContent={({ toggleVisibility }) => (
                    <Menu>
                        {sortedOptions.map((dataType) => (
                            <Item
                                align="center"
                                key={dataType}
                                selected={dataType === selectedDataType}
                                onClick={() => onChange(dataType)}
                                value={dataType}
                            >
                                <BodyText2>{_(`data_type_${dataType}`)}</BodyText2>
                            </Item>
                        ))}
                    </Menu>
                )}
                style={{ marginTop: -15 }}
            >
                {({ ref, toggleVisibility }) => (
                    <div ref={ref}>
                        <StyledButton color="secondary" onClick={toggleVisibility}>
                            <SvgIcon src="icons/ic-filter-2.svg" width={20} height={20} />
                            {isAnyDataTypeSelected && <Circle />}
                        </StyledButton>
                    </div>
                )}
            </Popover>
        </FormGroup>
    );
};

export default withTheme(ConditionTypeDropdown);
