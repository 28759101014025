import React from 'react';

import { DataCondition, DataFilters, DataKey } from '@portal/common/types';

import MetricBlock from './MetricBlock';
import useGetApiDataPlaceOf from '../hooks/use-get-api-data-place-of';

type Props = {
    conditions: DataCondition[];
    filters: DataFilters;
    label: string | React.ReactNode;
    positionByKeyValuePair: [DataKey, string | number];
};

const PlaceOfMetricBlock = ({ conditions, filters, label, positionByKeyValuePair }: Props) => {
    const { isLoading, place, totalAmount } = useGetApiDataPlaceOf({
        conditions,
        filters,
        positionByKeyValuePair,
    });

    return <MetricBlock isLoading={isLoading} value={`${place} of ${totalAmount}`} label={label} />;
};

export default PlaceOfMetricBlock;
