import React from 'react';

import NewGBDBecomesDefaultPopup from '../popups/NewGBDBecomesDefaultPopup';
import PollPopup from '../popups/PollPopup';

function AppNotifications() {
    return (
        <>
            <NewGBDBecomesDefaultPopup />
            <PollPopup />
        </>
    );
}

export default AppNotifications;
