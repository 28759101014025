import React from 'react';

import { localize as _ } from '@portal/common/locale';
import { withGoogleAnalytics } from '@ihme/common/packages/google-analytics';

import ForgotPassword from '@portal/common/components/ForgotPassword';

import api from '../../api';
import { SIGN_IN_PATH } from '../../router/paths';

import locale from './locale';

type Props = {
    history: any;
    email: string;
};

class ForgotPasswordScene extends React.PureComponent<Props> {
    onNext = () => {
        this.props.history.push({
            pathname: SIGN_IN_PATH,
            state: {
                successMessages: [_(locale.successResetPassword)],
            },
        });
    };

    render() {
        return (
            <ForgotPassword
                email={this.props.email}
                resetPassword={api.organizationMember.resetPassword}
                onNext={this.onNext}
            />
        );
    }
}

export default withGoogleAnalytics({
    trackMethods: (props) => ({
        onResetPassword: () =>
            props.trackEvent({
                category: 'Account',
                action: 'Reset Password',
            }),
    }),
})(ForgotPasswordScene);
