import React from 'react';

import { H2, SectionHeader } from '@portal/common/components';
import { styled } from '@portal/common/theme';

const StyledSectionHeader = styled(SectionHeader)(({ theme }) => ({
    borderBottom: '1px solid gray',
    paddingTop: 10,
    height: 90,
    marginBottom: 20,
}));

const Circle = styled.span(({ theme }) => ({
    width: 34,
    height: 34,
    borderRadius: 17,
    backgroundColor: '#D8D8D8',
    marginTop: -4,
}));

const Text = styled(H2)(({ theme }) => ({
    width: '100%',
    paddingLeft: 50,
    marginLeft: -30,
}));

type Props = {
    children: any;
};

const Header = ({ children }: Props) => (
    <StyledSectionHeader>
        <Circle />
        <Text>{children}</Text>
    </StyledSectionHeader>
);

export default Header;
