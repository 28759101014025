import { RootState } from 'MyTypes';
import UAParser from 'ua-parser-js';

import pkg from '../../package.json';

const parser = new UAParser();
const uaParserResult = parser.getResult();

const getDeviceType = (uaParserResult) => {
    const DEVICE_TYPE_PHONE = 'Phone';
    const DEVICE_TYPE_TABLET = 'Tablet';
    const DEVICE_TYPE_COMPUTER = 'Computer';
    const DEVICE_TYPE_OTHER = 'Other';

    if (!uaParserResult.device.type) {
        return DEVICE_TYPE_COMPUTER;
    }

    return {
        console: DEVICE_TYPE_OTHER,
        mobile: DEVICE_TYPE_PHONE,
        tablet: DEVICE_TYPE_TABLET,
        smarttv: DEVICE_TYPE_OTHER,
        wearable: DEVICE_TYPE_OTHER,
        embedded: DEVICE_TYPE_OTHER,
    }[uaParserResult.device.type];
};

const getBrowserName = (uaParserResult) => {
    const BROWSER_NAME_CHROME = 'Chrome';
    const BROWSER_NAME_IE = 'Internet Explorer';
    const BROWSER_NAME_SAFARI = 'Safari';
    const BROWSER_NAME_EDGE = 'Edge';
    const BROWSER_NAME_FIREFOX = 'Firefox';
    const BROWSER_NAME_OTHER = 'Other';

    const BROWSER_NAMES = {
        [BROWSER_NAME_CHROME]: BROWSER_NAME_CHROME,
        [BROWSER_NAME_IE]: 'IE',
        [BROWSER_NAME_SAFARI]: BROWSER_NAME_SAFARI,
        [BROWSER_NAME_EDGE]: BROWSER_NAME_EDGE,
        [BROWSER_NAME_FIREFOX]: BROWSER_NAME_FIREFOX,
        [BROWSER_NAME_OTHER]: BROWSER_NAME_OTHER,
    };

    for (const browserName in BROWSER_NAMES) {
        if (
            uaParserResult.browser.name.match(
                new RegExp('\\b' + BROWSER_NAMES[browserName] + '\\b')
            )
        ) {
            return browserName;
        }
    }

    return BROWSER_NAME_OTHER;
};

const getBrowserLanguageCode = (uaParserResult) => {
    return (window.navigator.userLanguage || window.navigator.language).substr(0, 2);
};

const getOsName = (uaParserResult) => {
    const OS_NAME_WINDOWS = 'Windows';
    const OS_NAME_MAC_OS = 'Mac OS';
    const OS_NAME_IOS = 'iOS';
    const OS_NAME_ANDROID = 'Android';
    const OS_NAME_LINUX = 'Linux';
    const OS_NAME_OTHER = 'Other';

    const OS_NAMES = [
        OS_NAME_WINDOWS,
        OS_NAME_MAC_OS,
        OS_NAME_IOS,
        OS_NAME_ANDROID,
        OS_NAME_LINUX,
        OS_NAME_OTHER,
    ];

    for (const osName of OS_NAMES) {
        if (uaParserResult.os.name.match(new RegExp('\\b' + osName + '\\b', 'i'))) {
            return osName;
        }
    }

    return OS_NAME_OTHER;
};

const getOsVersion = (uaParserResult) => {
    if ('x86_64' === uaParserResult.os.version) {
        return null;
    }

    return uaParserResult.os.version;
};

const getDeviceName = (uaParserResult) => {
    if (uaParserResult.device.model) {
        return uaParserResult.device.model;
    }

    if (navigator.platform === 'MacIntel') {
        return 'Apple Computer';
    }

    if (navigator.platform === 'Win32') {
        return 'Unknown PC';
    }

    return null;
};

const device_type = getDeviceType(uaParserResult);
const device_name = getDeviceName(uaParserResult);
const browser_name = getBrowserName(uaParserResult);
const browser_language_code = getBrowserLanguageCode(uaParserResult);
const os_name = getOsName(uaParserResult);
const os_version = getOsVersion(uaParserResult);

export const getStandardTrackingParams = ({ session, router }: RootState) => {
    const visit_id = session.visitId;
    // (starts with a /)
    const app_path = router.location.pathname;

    const app_version = pkg.version;
    const browser_version = uaParserResult.browser.version;
    const window_width = window.innerHeight;
    const window_height = window.innerWidth;

    return {
        visit_id,
        app_path,
        app_version,
        device_type,
        device_name,
        os_name,
        os_version,
        browser_name,
        browser_version,
        browser_language_code,
        window_width,
        window_height,
        source: 'web',
    };
};
