import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { withSentryRouting } from '@sentry/react';

import { withTrackedSceneView } from '@ihme/common/packages/google-analytics';

import AuthCallbackScene from '../scenes/AuthCallbackScene';
import DataExplorerScene from '../scenes/DataExplorerScene';
import Covid19ExplorerScene from '../scenes/Covid19ExplorerScene';
import ComorbidityExplorerScene from '../scenes/ComorbidityExplorerScene';
import EDPExplorerScene from '../scenes/EDPExplorerScene';
import DataLicenseScene from '../scenes/DataLicenseScene';
import DefinitionsScene from '../scenes/DefinitionsScene';
import EmailConfirmationScene from '../scenes/EmailConfirmationScene';
import ForgotPasswordScene from '../scenes/ForgotPasswordScene';
import HomeScene from '../scenes/HomeScene';
import MethodologyScene from '../scenes/MethodologyScene';
import MyListScene from '../scenes/MyListScene';
import ProfileScene from '../scenes/ProfileScene';
import RegistrationScene from '../scenes/RegistrationScene';
import ResendSignUpEmailScene from '../scenes/ResendSignUpEmailScene';
import SignInScene from '../scenes/SignInScene';
import SourcesScene from '../scenes/SourcesScene';
import StaffScene from '../scenes/StaffScene';
import ContactUsScene from '../scenes/ContactUsScene';
import TeamScene from '../scenes/TeamScene';
import PermalinkScene from '../scenes/PermalinkScene';
import SimulationExplorerScene from '../scenes/SimulationExplorerScene';
import HealthThreatsExplorerScene from '../scenes/HealthThreatsExplorerScene';

import authenticated from '../utility/hoc/authenticated';
import GuestLayout from './GuestLayout';
import LayoutWithSidenav from './LayoutWithSidenav';
import {
    AUTH_CALLBACK_PATH,
    CONTACT_US_PATH,
    COVID_19_EXPLORER_PATH,
    DATA_EXPLORER_PATH,
    DATA_LICENSE_PATH,
    DEFINITIONS_PATH,
    EDP_EXPLORER_PATH,
    EMAIL_CONFIRMATION_PATH,
    FAVORITE_CONTENT_PATH,
    FORGOT_PASSWORD_PATH,
    HEALTH_THREATS_METRICS_PATH,
    HOME_PATH,
    IHME_STAFF_PATH,
    METHODOLOGY_PATH,
    MORBIDITY_EXPLORER_PATH,
    MY_FAVORITIES_PATH,
    MY_LISTS_PATH,
    PERMALINK_PATH,
    PROFILE_PATH,
    PUBLIC_LISTS_PATH,
    REGISTRATION_PATH,
    RESEND_SIGN_UP_EMAIL_PATH,
    SIGN_IN_PATH,
    SIMULATION_EXPLORER_PATH,
    SOURCES_PATH,
    TEAM_PATH,
} from './paths';

const authenticatedWrapper = (Component) =>
    authenticated(
        withTrackedSceneView((props) => (
            <LayoutWithSidenav>
                <Component {...props} />
            </LayoutWithSidenav>
        ))
    );

const anonymousWrapper = (Component) =>
    withTrackedSceneView((props) => (
        <GuestLayout>
            <Component {...props} />
        </GuestLayout>
    ));

const SentryRoute = withSentryRouting(Route);

export default React.memo(() => (
    <Switch>
        {/* Authenticated User Routes */}
        <SentryRoute exact path={HOME_PATH} component={authenticatedWrapper(HomeScene)} />
        <SentryRoute
            exact
            path={DEFINITIONS_PATH}
            component={authenticatedWrapper(DefinitionsScene)}
        />
        <SentryRoute exact path={SOURCES_PATH} component={authenticatedWrapper(SourcesScene)} />
        <SentryRoute
            path={FAVORITE_CONTENT_PATH + MY_LISTS_PATH + '/:listId'}
            component={authenticatedWrapper(MyListScene)}
        />
        <SentryRoute
            path={FAVORITE_CONTENT_PATH + PUBLIC_LISTS_PATH + '/:listId'}
            component={authenticatedWrapper(MyListScene)}
        />

        <SentryRoute
            exact
            path={DATA_EXPLORER_PATH}
            component={authenticatedWrapper(DataExplorerScene)}
        />

        <SentryRoute
            exact
            path={COVID_19_EXPLORER_PATH}
            component={authenticatedWrapper(Covid19ExplorerScene)}
        />

        <SentryRoute
            exact
            path={MORBIDITY_EXPLORER_PATH}
            component={authenticatedWrapper(ComorbidityExplorerScene)}
        />

        <SentryRoute
            exact
            path={EDP_EXPLORER_PATH}
            component={authenticatedWrapper(EDPExplorerScene)}
        />

        <SentryRoute
            exact
            path={SIMULATION_EXPLORER_PATH}
            component={authenticatedWrapper(SimulationExplorerScene)}
        />

        <SentryRoute
            exact
            path={HEALTH_THREATS_METRICS_PATH}
            component={authenticatedWrapper(HealthThreatsExplorerScene)}
        />

        <SentryRoute
            exact
            path={PERMALINK_PATH + '/:hash'}
            component={authenticatedWrapper(PermalinkScene)}
        />
        <SentryRoute
            exact
            path={METHODOLOGY_PATH}
            component={authenticatedWrapper(MethodologyScene)}
        />
        <SentryRoute exact path={IHME_STAFF_PATH} component={authenticatedWrapper(StaffScene)} />
        <SentryRoute
            exact
            path={CONTACT_US_PATH}
            component={authenticatedWrapper(ContactUsScene)}
        />
        <SentryRoute
            exact
            path={DATA_LICENSE_PATH}
            component={authenticatedWrapper(DataLicenseScene)}
        />
        <SentryRoute
            exact
            path={PROFILE_PATH + ':tabId'}
            component={authenticatedWrapper(ProfileScene)}
        />
        <SentryRoute exact path={TEAM_PATH} component={authenticatedWrapper(TeamScene)} />

        {/* Unauthenticated User Routes */}
        <SentryRoute exact path={SIGN_IN_PATH} component={anonymousWrapper(SignInScene)} />
        <SentryRoute
            exact
            path={EMAIL_CONFIRMATION_PATH}
            component={anonymousWrapper(EmailConfirmationScene)}
        />
        <SentryRoute
            exact
            path={REGISTRATION_PATH}
            component={anonymousWrapper(RegistrationScene)}
        />
        <SentryRoute
            exact
            path={RESEND_SIGN_UP_EMAIL_PATH}
            component={anonymousWrapper(ResendSignUpEmailScene)}
        />
        <SentryRoute
            exact
            path={FORGOT_PASSWORD_PATH}
            component={anonymousWrapper(ForgotPasswordScene)}
        />
        <SentryRoute
            exact
            path={AUTH_CALLBACK_PATH}
            component={anonymousWrapper(AuthCallbackScene)}
        />

        <Redirect from={FAVORITE_CONTENT_PATH} to={MY_FAVORITIES_PATH} />
        <Redirect to={HOME_PATH} />
    </Switch>
));
