import { useEffect, useState } from 'react';

import { DataGranularityResponse, DataType } from '@portal/common/types';

import api from '../../../api';

export type GetGranularityProps = {
    organizationId: number;
    resourceId: number;
    dataType: DataType;
    roundId: number;
    primaryEntityId?: number;
};

export default ({
    organizationId,
    resourceId,
    dataType,
    roundId,
    primaryEntityId,
}: GetGranularityProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [granularity, setGranularity] = useState<DataGranularityResponse | null>(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        setError(null);

        api.organization
            .getOrganizationDataCollectionResourceRefinedGranularity({
                organizationId,
                resourceId,
                data_type: dataType,
                round_id: roundId,
                primary_entity_id: primaryEntityId,
            })
            .then((response) => {
                setGranularity(response);
                setIsLoading(false);
            })
            .catch((err) => {
                setGranularity(null);
                setIsLoading(false);
                setError(err?.message);
            });
    }, [organizationId, resourceId, dataType, roundId, primaryEntityId]);

    return { isLoading, granularity, error };
};
