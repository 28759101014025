import { v4 } from 'uuid';
import { OrganizationMember } from '@portal/common/types';

import * as types from './types';

type Action = {
    type: keyof typeof types;
    payload: { account: OrganizationMember };
};

type State = {
    token: null | string;
    expires: null | string;
    account: null | OrganizationMember;
    error: null | string;
    visitId: null | string;
    isImpersonated?: boolean;
};

const initialState: State = {
    token: null,
    expires: null,
    account: null,
    error: null,
    visitId: v4(),
    isImpersonated: false,
};

export default function (state: State = initialState, action: Action): State {
    switch (action.type) {
        case types.SESSION_SIGNED_IN:
        case types.SESSION_ACCOUNT_LOADED:
            return {
                ...state,
                error: null,
                visitId: v4(),
                ...action.payload,
            };

        case types.SESSION_ACCOUNT_UPDATED:
        case types.SESSION_ACCOUNT_PASSWORD_CHANGED:
            return {
                ...state,
                error: null,
                account: action.payload,
            };

        case types.SESSION_SIGNED_OUT:
            return {
                ...initialState,
                error: action.payload ? action.payload.error : null,
            };

        case types.SESSION_ACCOUNT_NOTIFICATION_SHOWN_CHANGED:
            return {
                ...state,
                account: {
                    ...state.account,
                    is_notification_shown: action.payload,
                },
            };
        case types.SESSION_ERROR:
            return {
                ...initialState,
                error: action.payload.error,
            };
        case types.SESSION_RESET:
            return {
                ...initialState,
            };

        default:
            return {
                ...state,
            };
    }
}
