import * as React from 'react';
import chroma from 'chroma-js';

import { styled } from '@portal/common/theme';
import { ChartZoomProvider, Clearfix } from '@ihme/common/web/components';

import {
    DataCollectionResource,
    DataCondition,
    DataExportFormat,
    DataFilters,
    DataGranularity,
    DataGranularityKey,
    DataResponse,
    DataType,
} from '@portal/common/types';
import { MapChartZoomControls, MapChart } from '@portal/common/components';
import { PNG } from '@portal/common/models/file-format';
import {
    chartProviderFiltersToObject,
    getChartSubTitle,
    getChartTitle,
    localizeConditions,
    mapValueToCondition,
} from '@portal/common/utility/chart-data-helpers';
import {
    AGE_GROUP_ID_KEY,
    ANTIBIOTIC_CLASS_ID_KEY,
    CAUSE_ID_KEY,
    COUNTERFACTUAL_ID_KEY,
    EDUCATION_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    INFECTIOUS_SYNDROME_ID_KEY,
    MAP_TYPE_ID_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    PATHOGEN_ID_KEY,
    PRIMARY_ENTITY_KEY,
    RACE_ID_KEY,
    RISK_EXPOSURE_ID_KEY,
    VALUE_KEY,
} from '@portal/common/models/data-key';
import { TYPE_MAP_CHART } from '@portal/common/models/chart-type';
import { getExportFilename } from '@portal/common/utility/get-export-filename';
import { pngIcon } from '@portal/common/theme/icons';

import animatableChart from '../../../../utility/hoc/animatable-chart';
import config from '../../../../config';
import _ from '../../../../locale';
import echarts from '../../../../theme/echarts';

const XAxisLabel = styled.div(({ theme }) => ({
    textAlign: 'center',
    marginTop: -14,
    fontWeight: theme.typography.fontWeight.medium,
    fontSize: '1.4rem',
    color: '#333333',
}));

type Props = {
    initialTimeUnitValue: number | string;
    selectedConditionsDataTypes: DataType[];
    selectedConditionsDataTypesGranularity: DataGranularity;
    selectedConditionsPrimaryEntityFilters: DataGranularityKey[];
    selectedDataTool: string;
    selectedDataCollection: DataCollectionResource;
    isLoadingData: boolean;
    isForecastingData: boolean;
    filtersValues: DataFilters;
    selectedConditions: DataCondition[];

    dataResponse: DataResponse;
    chartFilters: DataFilters;
    timeUnitKey: DataGranularityKey;
    onExportData?: (format: DataExportFormat) => void;
    enableExportPNG: boolean;
    enableExportGIF: boolean;
    numberFormatter: (value: number | string) => string;

    echartsMapKey: string;
    mapElementsAmount: number;
};

class Chart extends React.PureComponent<Props> {
    chartRef = React.createRef();

    resetChartPosition = () => {
        if (this.chartRef.current) {
            this.chartRef.current.resetChartPosition();
        }
    };

    renderTooltip = (params) => {
        const { numberFormatter } = this.props;
        if (!params.data) {
            return `<div class="chart-tooltip">${_('chart_error_no_data')}</div>`;
        }

        const { id, value: rawValue, upper, lower } = params.data;

        const name = _(`location_${id}`);
        const value = numberFormatter(rawValue);
        const upperValue = numberFormatter(upper);
        const lowerValue = numberFormatter(lower);

        const uncertainty =
            upperValue !== null && lowerValue !== null ? `(${lowerValue} - ${upperValue})` : '';

        return `<div class="chart-tooltip">
                <div><b>${name}: </b>${value} ${uncertainty}</div>
            </div>`;
    };

    renderTitle = ({ filters, sliderValue }) => {
        const {
            dataResponse: { records },
            selectedConditionsDataTypes,
            selectedConditionsDataTypesGranularity,
        } = this.props;
        const conditions = [this.getSelectedCondition()];

        return records.length > 0
            ? getChartTitle(
                  selectedConditionsDataTypes,
                  localizeConditions(conditions),
                  chartProviderFiltersToObject(filters),
                  Object.keys(selectedConditionsDataTypesGranularity),
                  [RISK_EXPOSURE_ID_KEY, CAUSE_ID_KEY, MEASURE_ID_KEY, METRIC_ID_KEY],
                  sliderValue
              )
            : '';
    };

    getSelectedCondition = (): DataCondition => {
        const { chartFilters, selectedConditions } = this.props;

        return selectedConditions.length === 1 ||
            !(
                chartFilters &&
                chartFilters[PRIMARY_ENTITY_KEY] &&
                chartFilters[PRIMARY_ENTITY_KEY].length
            )
            ? selectedConditions[0]
            : mapValueToCondition(chartFilters[PRIMARY_ENTITY_KEY][0]);
    };

    renderSubtitle = ({ filters }) => {
        const {
            chartFilters,
            dataResponse: { records },
            isForecastingData,
            selectedConditionsDataTypesGranularity,
            selectedConditionsPrimaryEntityFilters,
        } = this.props;
        const mapType = chartFilters ? chartFilters[MAP_TYPE_ID_KEY] : config.globalLocationId;
        const prefix = mapType !== config.globalLocationId ? `${_(`location_${mapType}`)}, ` : '';

        const titleFilters = [
            PATHOGEN_ID_KEY,
            INFECTIOUS_SYNDROME_ID_KEY,
            RACE_ID_KEY,
            AGE_GROUP_ID_KEY,
            GENDER_ID_KEY,
            ANTIBIOTIC_CLASS_ID_KEY,
            COUNTERFACTUAL_ID_KEY,
            EDUCATION_ID_KEY,
        ].filter((key) => !selectedConditionsPrimaryEntityFilters.includes(key));

        if (isForecastingData) {
            titleFilters.push(FORECAST_SCENARIO_ID_KEY);
        }
        return records.length > 0
            ? prefix +
                  getChartSubTitle(
                      chartProviderFiltersToObject(filters),
                      Object.keys(selectedConditionsDataTypesGranularity),
                      titleFilters
                  )
            : '';
    };

    getSaveFilename = ({ filters, sliderValue }) => {
        const { selectedDataTool, selectedDataCollection } = this.props;

        return getExportFilename({
            trigger: TYPE_MAP_CHART,
            dataTool: selectedDataTool,
            dataCollectionName: selectedDataCollection.name,
            conditions: localizeConditions([this.getSelectedCondition()]),
            filters,
            selectedTimeUnit: sliderValue,
        });
    };

    calculateColorScalePalette = (
        min: number,
        max: number,
        minRange: number,
        maxRange: number,
        sliderValue: number = 2017
    ): string[] => {
        const {
            dataResponse: { columns, records },
            timeUnitKey,
        } = this.props;

        //todo: check cases with equal values, with only 1 value etc
        const valueIdx = columns.indexOf(VALUE_KEY);
        const timeUnitIdx = columns.indexOf(timeUnitKey);

        const currentTimeUnitData = records.filter(
            (record) =>
                record[timeUnitIdx] === sliderValue &&
                record[valueIdx] >= minRange &&
                record[valueIdx] <= maxRange
        );

        const currentTimeUnitValues = currentTimeUnitData.map((record) => record[valueIdx]);

        const sortedValues = currentTimeUnitValues.sort((a, b) => a - b);
        const length = sortedValues.length;

        const fullRange = max - min;
        const selectedRange = length === 1 ? fullRange : maxRange - minRange;

        const value25 = sortedValues[Math.round(length / 4)];
        const value50 = sortedValues[Math.round(length / 2)];
        const value75 = sortedValues[Math.round((3 * length) / 4)];

        const group25coef = (100 * (value25 - minRange)) / selectedRange;
        const group50coef = (100 * (value50 - minRange)) / selectedRange;
        const group75coef = (100 * (value75 - minRange)) / selectedRange;

        const gradientColors = chroma
            .scale(echarts.map.locationInRangeColorSpectrum.slice(0, 5))
            .domain([0, group25coef, group50coef, group75coef, 100])
            .colors(100);

        const selectedRangePercentage = selectedRange / fullRange;

        const stopsAmount = gradientColors.length;
        const requiredStopsAmount = Math.floor(stopsAmount / selectedRangePercentage);
        const topMissingStops = Math.ceil((requiredStopsAmount * (max - maxRange)) / fullRange);
        const bottomMissingStops = Math.max(requiredStopsAmount - stopsAmount - topMissingStops, 0);

        return [
            ...Array(Math.round(bottomMissingStops)).fill(gradientColors[0]),
            ...gradientColors,
            ...Array(Math.round(topMissingStops)).fill(gradientColors[gradientColors.length - 1]),
        ];
    };

    render() {
        const {
            dataResponse,
            initialTimeUnitValue,
            timeUnitKey,
            onExportData,
            enableExportPNG,
            echartsMapKey,
            mapElementsAmount,
        } = this.props;

        if (!echartsMapKey) {
            return null;
        }

        return (
            <ChartZoomProvider echartsMapKey={echartsMapKey}>
                <div>
                    <MapChartZoomControls />
                    <Clearfix />
                    <MapChart
                        {...this.props}
                        {...dataResponse}
                        ref={this.chartRef}
                        theme={echarts}
                        mapElementsAmount={mapElementsAmount}
                        height={550}
                        enableSliderWithFilterKey={timeUnitKey}
                        initialSliderValue={initialTimeUnitValue}
                        renderTooltip={this.renderTooltip}
                        renderTitle={this.renderTitle}
                        renderSubtitle={this.renderSubtitle}
                        colorScaleConfig={{
                            show: true,
                            showScaleControls: true,
                            generatePalette: this.calculateColorScalePalette,
                        }}
                        saveAsImage={{
                            visible: true,
                            enabled: enableExportPNG,
                            filename: this.getSaveFilename,
                            icon: pngIcon,
                            onClick: () => onExportData?.(PNG),
                        }}
                    />
                    <XAxisLabel>{_(`chart_axis_label_${timeUnitKey}`)}</XAxisLabel>
                </div>
            </ChartZoomProvider>
        );
    }
}

export default animatableChart(Chart);
