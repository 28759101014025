import { DataGranularity } from '@portal/common/types';
import { mergeGranularity } from '@portal/common/utility/merge-granularity';

import { SingleAgeReportTypes } from '../types';

export const mergeSingleYearAgeGranularity = (
    source: Record<SingleAgeReportTypes, Record<number, DataGranularity>> | null
): DataGranularity => {
    let granularity: DataGranularity = {};

    Object.values(SingleAgeReportTypes).forEach((type) =>
        Object.values(source?.[type] || {}).forEach((subGranularity = {}) => {
            if (subGranularity) {
                granularity = mergeGranularity(granularity || {}, subGranularity);
            }
        })
    );

    return granularity;
};
