import React from 'react';

import {
    DataCollectionResource,
    DataCondition,
    DataExportFormat,
    DataFilters,
    DataGranularity,
    DataGranularityKey,
    DataResponse,
    DataToolConfig,
    DataType,
} from '@portal/common/types';

import ChartRefinementFiltersControls from '../ChartRefinementFiltersControls';
import Container from './Container';

type Props = {
    forecastTimeUnitStart: number | string;
    initialTimeUnitValue: number | string;
    isForecastingData: boolean;
    enableExportPNG: boolean;
    onDataLoad?: () => void;
    selectedConditionsDataTypes: DataType[];
    selectedConditionsDataTypesGranularity: DataGranularity;
    selectedConditions: DataCondition[];
    multipleFilterKeys: DataGranularityKey[];

    mergedChartDataResponses: null | DataResponse;
    isLoadingDataExplorerData: boolean;
    isLoadingChartData: boolean;
    filtersValues: DataFilters;
    baseForecastScenarioId: number;
    observedForecastScenarioId: number;
    timeUnitKey: DataGranularityKey;
    onExportData?: (format: DataExportFormat) => void;
    numberFormatter: (value: number | string) => string;
    dataToolConfig: DataToolConfig;
    selectedDataTool: string;
    selectedDataCollection: DataCollectionResource;
    maxValuesSelectedFilter: DataGranularityKey;
};

export default class LineChartSection extends React.PureComponent<Props> {
    render() {
        const {
            forecastTimeUnitStart,
            selectedConditions,
            selectedConditionsDataTypes,
            selectedConditionsDataTypesGranularity,
            enableExportPNG,
            onDataLoad,
            isForecastingData,
            isLoadingDataExplorerData,
            isLoadingChartData,
            filtersValues,
            multipleFilterKeys,
            baseForecastScenarioId,
            observedForecastScenarioId,
            timeUnitKey,
            initialTimeUnitValue,
            mergedChartDataResponses,
            onExportData,
            numberFormatter,
            dataToolConfig,
            selectedDataTool,
            selectedDataCollection,
            maxValuesSelectedFilter,
        } = this.props;

        return (
            <>
                <ChartRefinementFiltersControls />
                <Container
                    isLoadingData={isLoadingChartData || isLoadingDataExplorerData}
                    filtersValues={filtersValues}
                    multipleFilterKeys={multipleFilterKeys}
                    forecastTimeUnitStart={forecastTimeUnitStart}
                    initialTimeUnitValue={initialTimeUnitValue}
                    isForecastingData={isForecastingData}
                    enableExportPNG={enableExportPNG}
                    onDataLoad={onDataLoad}
                    selectedConditions={selectedConditions}
                    selectedConditionsDataTypes={selectedConditionsDataTypes}
                    selectedConditionsDataTypesGranularity={selectedConditionsDataTypesGranularity}
                    baseForecastScenarioId={baseForecastScenarioId}
                    observedForecastScenarioId={observedForecastScenarioId}
                    timeUnitKey={timeUnitKey}
                    mergedChartDataResponses={mergedChartDataResponses}
                    onExportData={onExportData}
                    numberFormatter={numberFormatter}
                    dataToolConfig={dataToolConfig}
                    selectedDataTool={selectedDataTool}
                    selectedDataCollection={selectedDataCollection}
                    maxValuesSelectedFilter={maxValuesSelectedFilter}
                />
            </>
        );
    }
}
