import {
    AGE_GROUP_ID_KEY,
    ANTIBIOTIC_CLASS_ID_KEY,
    CAUSE_ID_KEY,
    COUNTERFACTUAL_ID_KEY,
    DAY_KEY,
    EDUCATION_ID_KEY,
    EMPLOYMENT_COHORT_ID_KEY,
    EMPLOYMENT_STATUS_ID_KEY,
    ETHNICITY_ID_KEY,
    FACILITY_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    INDUSTRY_ID_KEY,
    INFECTIOUS_SYNDROME_ID_KEY,
    LOCATION_ID_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    PATHOGEN_ID_KEY,
    PORTAL_ORGANIZATION_ID_KEY,
    RACE_ID_KEY,
    RISK_EXPOSURE_ID_KEY,
    ROUND_ID_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import { DataType } from '@portal/common/types';

const order = [
    INFECTIOUS_SYNDROME_ID_KEY,
    MEASURE_ID_KEY,
    AGE_GROUP_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    METRIC_ID_KEY,
    CAUSE_ID_KEY,
    RISK_EXPOSURE_ID_KEY,
    RACE_ID_KEY,
    PATHOGEN_ID_KEY,
    ANTIBIOTIC_CLASS_ID_KEY,
    COUNTERFACTUAL_ID_KEY,
    EDUCATION_ID_KEY,
    PORTAL_ORGANIZATION_ID_KEY,
    FACILITY_ID_KEY,
    INDUSTRY_ID_KEY,
    ETHNICITY_ID_KEY,
    EMPLOYMENT_COHORT_ID_KEY,
    EMPLOYMENT_STATUS_ID_KEY,
    ROUND_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    YEAR_KEY,
    DAY_KEY,
];

export default (refinementFiltersKeys: string[], dataType?: DataType): string[] => {
    const customSort = (a: string, b: string) => order.indexOf(a) - order.indexOf(b);

    let sortedKeys = refinementFiltersKeys.sort(customSort);

    const missingKeys = refinementFiltersKeys.filter((key) => !order.includes(key)).sort();
    const endOfListFilters = [...missingKeys, FORECAST_SCENARIO_ID_KEY, YEAR_KEY, DAY_KEY];
    endOfListFilters.forEach((key) => {
        if (sortedKeys.includes(key)) {
            sortedKeys = moveToEnd(sortedKeys, key);
        }
    });

    return sortedKeys;
};

const moveToEnd = (source, filterKey) => source.filter((i) => i !== filterKey).concat(filterKey);
