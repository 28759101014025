import React from 'react';
import { useSelector } from 'react-redux';

import { LOCATION_ID_KEY } from '@portal/common/models/data-key';
import { BodyText2, SectionBody } from '@portal/common/components';
import { styled } from '@portal/common/theme';

import {
    getDataToolConfig,
    getSelectedRefinementFilters,
} from '../../../store/data-explorer/selectors';
import { getSelectedDataTool } from '../../../store/root-reducer';
import { getSelectedDataCollectionForDataTool } from '../../../store/user-settings/selectors';

import _ from '../../../locale';

import Header from '../components/Header';
import ConditionDescription from './ConditionDescription';
import ConditionMetrics from './ConditionMetrics';
import NeoplasmsTreemapChart from './NeoplasmsTreemapChart';

const DataContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    [theme.media.laptop]: {
        flexDirection: 'row',
        gap: 30,
    },
}));

const ConditionBackgroundSection = () => {
    const dataCollection = useSelector(getSelectedDataCollectionForDataTool);
    const selectedRefinementFilters = useSelector(getSelectedRefinementFilters);
    const dataToolConfig = useSelector(getDataToolConfig);
    const dataTool = useSelector(getSelectedDataTool);
    const entityHierarchies = useSelector((store) => store.entityHierarchies);

    if (!selectedRefinementFilters?.[LOCATION_ID_KEY]?.length) {
        return null;
    }

    const locationId = parseInt(selectedRefinementFilters?.[LOCATION_ID_KEY]?.[0]);
    const locationName = _(`location_${locationId}`);

    return (
        <SectionBody isCollapsible isCollapsedInitially>
            <Header>{_('simulation_tool_2_header', { location: locationName })}</Header>
            <ConditionDescription dataCollection={dataCollection} locationId={locationId} />

            <DataContainer>
                <ConditionMetrics locationId={locationId} />
                <NeoplasmsTreemapChart
                    locationId={locationId}
                    dataCollection={dataCollection}
                    dataToolConfig={dataToolConfig}
                    dataTool={dataTool}
                    entityHierarchies={entityHierarchies}
                />
            </DataContainer>
            <BodyText2>{_('simulation_tool_1_2_description_3')}</BodyText2>
        </SectionBody>
    );
};

export default ConditionBackgroundSection;
