import { RootState } from 'MyTypes';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { FlexColumn, Image, Loading, Pagination } from '@portal/common/components';
import { AnnouncementResource } from '@portal/common/types';
import { styled } from '@portal/common/theme';

import { getAnnouncements } from '../../store/resources/selectors';

const List = styled.ul({
    paddingLeft: 0,
});

const ListItem = styled.li({
    position: 'relative',
    display: 'block',
    ':not(:last-child)': {
        marginBottom: 15,
    },
});

const ArticleTitle = styled.div({
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 40,
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: '#FFFFFF',
    fontWeight: 500,
});

const Article = ({ title, url, image_url }: AnnouncementResource) => (
    <Image size="medium" src={image_url} onClick={() => window.open(url, '_blank')}>
        <ArticleTitle>{title}</ArticleTitle>
    </Image>
);

const mapStateToProps = (state: RootState) => ({
    announcements: getAnnouncements(state),
});

const dispatchProps = {};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & {};

const News = ({ announcements }: Props) => {
    if (announcements == null) {
        return <Loading />;
    }

    const { results } = announcements;
    const rowsPerPage = 3;

    const [page, setPage] = useState(1);

    return (
        <FlexColumn>
            <List>
                {results
                    .filter((i, idx) => {
                        return idx >= (page - 1) * rowsPerPage && idx < page * rowsPerPage;
                    })
                    .map((article, index) => (
                        <ListItem key={index}>
                            <Article {...article} />
                        </ListItem>
                    ))}
            </List>
            {results.length > 0 &&
                (results.length > rowsPerPage ? (
                    <Pagination
                        totalItems={results.length}
                        currentPage={page}
                        onChangePage={(page) => setPage(page)}
                        rowsPerPage={rowsPerPage}
                        isMinimized
                    />
                ) : (
                    <div style={{ flexGrow: 1 }} />
                ))}
        </FlexColumn>
    );
};

export default connect(mapStateToProps, dispatchProps)(News);
