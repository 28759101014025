import { RootState } from 'MyTypes';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Linkify from 'react-linkify';

import {
    H5,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHeaderCell,
    TableRow,
    Text,
} from '@portal/common/components';

import { ConditionDetailNotesRecord } from '@portal/common/types';
import _ from '../../locale';
import { decodeCdnRow } from './PdfReport/decode-cdn-row';

const mapStateToProps = (state: RootState) => ({});

const dispatchProps = {};

export type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & {
        conditionDetailNotes: ConditionDetailNotesRecord;
    };

const ConditionDetailNotesTable = ({ conditionDetailNotes }: Props) => {
    const [showMore, setShowMore] = useState(false);

    if (conditionDetailNotes.records.length === 0) {
        return null;
    }

    const renderRow = (cdnRow: { key: string; value: any }) => {
        const result = decodeCdnRow(cdnRow);
        if (result == null) {
            return null;
        }

        const { label, value } = result;

        return (
            <TableRow key={label}>
                <TableHeaderCell style={{ width: 160 }}>
                    <Text>{label}</Text>
                </TableHeaderCell>
                <TableCell>
                    <Text>
                        {value.split('\n').map((line, idx) => (
                            <Linkify
                                key={idx}
                                componentDecorator={(
                                    decoratedHref: string,
                                    decoratedText: string,
                                    key: number
                                ): React.Node => (
                                    <a href={decoratedHref} key={key} target="_blank">
                                        {decoratedText}
                                    </a>
                                )}
                            >
                                {line}
                                <br />
                            </Linkify>
                        ))}
                    </Text>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <div style={{ marginBottom: 30 }}>
            <H5>{conditionDetailNotes.records.find((r) => r.key === 'cdn_condition')!.value}</H5>
            <Table>
                <TableBody>{conditionDetailNotes.records.map(renderRow)}</TableBody>
            </Table>
            {conditionDetailNotes.hiddenRecords.length > 0 && (
                <div>
                    {showMore && (
                        <Table>
                            <TableBody>
                                {conditionDetailNotes.hiddenRecords.map(renderRow)}
                            </TableBody>
                        </Table>
                    )}
                    <Link
                        onClick={() => setShowMore(!showMore)}
                        style={{ display: 'block', marginTop: 15 }}
                    >
                        {showMore ? _.get('cdn_show_less') : _.get('cdn_show_more')}
                    </Link>
                </div>
            )}
        </div>
    );
};

export default compose(connect(mapStateToProps, dispatchProps))(ConditionDetailNotesTable);
