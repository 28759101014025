import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash/fp';

import Button from '@portal/common/components/Button';
import { DataCondition, DataType } from '@portal/common/types';
import { styled } from '@portal/common/theme';
import { Color } from '@portal/common/utility/common-props-helpers';

import {
    getEntityHierarchies,
    getSelectedConditionsDataTypes,
} from '../../store/data-explorer/selectors';
import { getSelectedDataCollectionForDataTool } from '../../store/user-settings/selectors';
import {
    getDataCollectionDataTypes,
    getDataCollectionGranularityByDataTypes,
} from '../../utility/data-loader';

import getAvailableSmartOptions from './get-available-smart-options';
import { ConditionSmartOption } from './options';
import _ from '../../locale';

import SmartOptionsDropdown from '../SmartFilterOptionSelector/SmartOptionsDropdown';

const StyledButton = styled(Button)({
    minWidth: 120,
    width: '100%',
});

type Props = {
    dataType: DataType;
    onSelect: (conditions: DataCondition[]) => void;
    color?: Color;
    style?: React.CSSProperties;
};

export default ({ onSelect, color = 'base', dataType, ...props }: Props) => {
    const dataCollection = useSelector(getSelectedDataCollectionForDataTool);
    const selectedConditionsDataTypes = useSelector(getSelectedConditionsDataTypes);
    const hierarchy = useSelector(getEntityHierarchies);

    if (!dataCollection) return null;

    const dataCollectionDataTypes = getDataCollectionDataTypes(dataCollection);
    const granularity = getDataCollectionGranularityByDataTypes(
        dataCollection,
        dataCollectionDataTypes
    );

    const smartOptions = getAvailableSmartOptions(
        dataCollection,
        dataType,
        selectedConditionsDataTypes,
        granularity,
        hierarchy
    );

    const handleSmartOptionSelect = useCallback(
        (smartOption: ConditionSmartOption) => {
            const conditions = smartOption.getSelectedValues(granularity, hierarchy);
            onSelect(conditions);
        },
        [granularity, hierarchy]
    );

    if (isEmpty(smartOptions)) return null;

    return (
        <SmartOptionsDropdown
            placement="bottom"
            smartOptions={smartOptions}
            onSmartOptionSelect={handleSmartOptionSelect}
            style={{ marginTop: -14 }}
        >
            {({ ref, toggleVisibility }) => (
                <div ref={ref} {...props}>
                    <StyledButton color={color} onClick={toggleVisibility}>
                        {_('data_explorer_select_more')}
                    </StyledButton>
                </div>
            )}
        </SmartOptionsDropdown>
    );
};
