import React from 'react';
import { useSelector } from 'react-redux';

import { LOCATION_ID_KEY } from '@portal/common/models/data-key';
import {
    BodyText2,
    H5,
    Link,
    SectionBody,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
} from '@portal/common/components';
import { styled } from '@portal/common/theme';

import { getSelectedRefinementFilters } from '../../../store/data-explorer/selectors';

import _ from '../../../locale';

import Header from '../components/Header';
import Title from '../components/Title';

const Description = styled(BodyText2)(({ theme }) => ({
    paddingTop: 10,
    paddingBottom: 30,
}));

const DownloadReportLink = ({ children, ...props }) => (
    <Link {...props} href="files/IHME Multiple Myeloma Simulation Phase 2 Technical Report.pdf">
        {children}
    </Link>
);

const GBDLink = ({ children, ...props }) => (
    <Link {...props} target="_blank" href="https://www.healthdata.org/gbd/2019">
        {children}
    </Link>
);

const NCCNLink = ({ children, ...props }) => (
    <Link {...props} target="_blank" href="https://www.nccn.org/">
        {children}
    </Link>
);

const StudySection = () => {
    const selectedRefinementFilters = useSelector(getSelectedRefinementFilters);
    if (!selectedRefinementFilters?.[LOCATION_ID_KEY]?.length) {
        return null;
    }

    const locationId = parseInt(selectedRefinementFilters?.[LOCATION_ID_KEY]?.[0]);

    return (
        <SectionBody isCollapsible isCollapsedInitially>
            <Header>{_('simulation_tool_3_header')}</Header>
            <Title>{_('simulation_tool_3_1_title')}</Title>
            <Description>
                {_('simulation_tool_3_1_description')({ GBDLink, DownloadReportLink })}
            </Description>
            <Title>{_('simulation_tool_3_3_title')}</Title>
            <Description>{_('simulation_tool_3_3_description')}</Description>
            <Title>{_('simulation_tool_3_4_title')}</Title>
            <H5>{_('simulation_tool_baseline_scenario')}</H5>
            <BodyText2 style={{ paddingBottom: 30 }}>
                {_(`simulation_tool_baseline_scenario_location_${locationId}`)}
            </BodyText2>
            <H5>{_('simulation_tool_alternative_scenario_1')}</H5>
            <BodyText2 style={{ paddingBottom: 30 }}>
                {_(`simulation_tool_alternative_scenario_1_location_${locationId}`)}
            </BodyText2>
            <H5>{_('simulation_tool_alternative_scenario_2')}</H5>
            <BodyText2 style={{ paddingBottom: 30 }}>
                {_(`simulation_tool_alternative_scenario_2_location_${locationId}`)}
            </BodyText2>
            <BodyText2 style={{ paddingBottom: 30 }}>
                {_(`simulation_tool_both_countries_text`)}
            </BodyText2>
            <Title>{_('simulation_tool_3_5_title')}</Title>
            <Description>{_('simulation_tool_3_5_description')}</Description>
            <BodyText2 align="center">{_('simulation_tool_3_5_image_title')}</BodyText2>
            <div
                style={{
                    background: 'url("img/simulation-tool/simulation-structure.png") no-repeat',
                    backgroundSize: 'contain',
                    height: 500,
                    marginTop: 20,
                    width: '100%',
                    backgroundPosition: '50% 50%',
                }}
            />
            <Title>{_('simulation_tool_3_6_title')}</Title>
            <BodyText2 align="center">Table 1: Summary of simulation data sources.</BodyText2>
            <Table>
                <TableHead>
                    <TableHeaderRow>
                        <TableHeaderCell>Simulation Parameter</TableHeaderCell>
                        <TableHeaderCell>Concept-Model Component</TableHeaderCell>
                        <TableHeaderCell>United States</TableHeaderCell>
                        <TableHeaderCell>China</TableHeaderCell>
                    </TableHeaderRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableHeaderCell>1. Population age and sex structure</TableHeaderCell>
                        <TableCell>Covariates</TableCell>
                        <TableCell>GBD 2019</TableCell>
                        <TableCell>GBD 2019</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableHeaderCell>
                            2. Risk factor exposures among MM patients
                        </TableHeaderCell>
                        <TableCell>Risk factors</TableCell>
                        <TableCell>Meta-analysis of Randomized Clinical Trials (RCTs)</TableCell>
                        <TableCell>
                            Derived with expert input from Sanofi’s China oncology team
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableHeaderCell>3. Risk factor effects</TableHeaderCell>
                        <TableCell>Risk factors</TableCell>
                        <TableCell>Meta-analysis of RCTs</TableCell>
                        <TableCell>Meta-analysis of RCTs</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableHeaderCell>4. NDMM Incidence</TableHeaderCell>
                        <TableCell>Causes</TableCell>
                        <TableCell>GBD 2019</TableCell>
                        <TableCell>GBD 2019</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableHeaderCell>5. MM Mortality rates</TableHeaderCell>
                        <TableCell>Causes</TableCell>
                        <TableCell>GBD 2019</TableCell>
                        <TableCell>GBD 2019</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableHeaderCell>
                            6. MM Mortality and relapse survival curves
                        </TableHeaderCell>
                        <TableCell>Causes</TableCell>
                        <TableCell>Flatiron Health</TableCell>
                        <TableCell>
                            <ul>
                                <li>Sanofi’s China oncology team</li>
                                <li>Flatiron Health</li>
                            </ul>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableHeaderCell>7. Treatment categories</TableHeaderCell>
                        <TableCell>Health system (treatment)</TableCell>
                        <TableCell>NCCN Guidelines</TableCell>
                        <TableCell>NCCN Guidelines</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableHeaderCell>8. Treatment coverage</TableHeaderCell>
                        <TableCell>Health system (treatment)</TableCell>
                        <TableCell>
                            <ul>
                                <li>Sanofi commercial team (isatuximab projections)</li>
                                <li>Flatiron Health</li>
                                <li>Domain expert assumptions (daratumumab projections)</li>
                            </ul>
                        </TableCell>
                        <TableCell>
                            <ul>
                                <li>Sanofi market research</li>
                                <li>Flatiron Health</li>
                                <li>
                                    Sanofi commercial team (isatuximab and daratumumab projections)
                                </li>
                            </ul>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableHeaderCell>9. Treatment effects</TableHeaderCell>
                        <TableCell>Health system (treatment)</TableCell>
                        <TableCell>Meta-analysis of RCTs</TableCell>
                        <TableCell>Meta-analysis of RCTs</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Description>{_('simulation_tool_3_6_description')({ GBDLink, NCCNLink })}</Description>
        </SectionBody>
    );
};

export default StudySection;
