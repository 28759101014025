import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Resource } from '@portal/common/types';

import api from '../../api';
import { getOrganizationId, getSessionAccountId } from '../../store/root-reducer';
import AddToListDropdown, { AddToListDropdownProps } from './';
import {
    addFavoriteResource,
    removeFavoriteResource,
    addResourceListResource,
    removeResourceListResource,
    createResourceList,
} from '../../store/resource-lists/actions';

const mapStateToProps = (state: RootState) => ({
    organizationId: getOrganizationId(state)!,
    memberId: getSessionAccountId(state)!,
});

const dispatchProps = {
    addFavoriteResource: addFavoriteResource,
    removeFavoriteResource: removeFavoriteResource,
    addResourceListResource: addResourceListResource,
    removeResourceListResource: removeResourceListResource,
    createResourceList: createResourceList,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    AddToListDropdownProps<Resource> & {
        item: Resource;
        onSuccess?: () => void;
    };

const AddToListResourceListDropdown = ({
    organizationId,
    memberId,
    item,
    onSuccess,
    addFavoriteResource,
    removeFavoriteResource,
    addResourceListResource,
    removeResourceListResource,
    createResourceList,
    ...props
}: Props) => {
    return (
        <AddToListDropdown
            askBeforeRemoving={true}
            onFavoritesResourceClick={(item, refreshCallback) => {
                return api.organizationMemberFavoriteResource
                    .addResourceToFavorites({
                        organizationId,
                        memberId,
                        item,
                    })
                    .then((res) => {
                        addFavoriteResource(res);
                    })
                    .then(refreshCallback);
            }}
            onFavoritesSelectedResourceClick={(item) => {
                return api.organizationMemberFavoriteResource
                    .removeResourceFromFavorites({
                        organizationId,
                        memberId,
                        item,
                    })
                    .then((res) => {
                        removeFavoriteResource({ resourceId: res.id });
                    })
                    .then(() => {
                        onSuccess && onSuccess();
                    });
            }}
            onCustomListResourceClick={(item, listId, refreshCallback) => {
                return api.organizationResourceListResource
                    .addResourceToOrganizationResourceList({
                        organizationId,
                        listId,
                        item,
                    })
                    .then((res) => {
                        addResourceListResource({ listId: listId, resource: res });
                    })
                    .then(refreshCallback);
            }}
            onCustomListSelectedResourceClick={(item, listId) => {
                return api.organizationResourceListResource
                    .removeResourceFromOrganizationResourceList({
                        organizationId,
                        listId,
                        item,
                    })
                    .then((res) => {
                        removeResourceListResource({ listId: listId, resourceId: res.id });
                    })
                    .then(() => {
                        onSuccess && onSuccess();
                    });
            }}
            onCreateCustomList={(name, refreshCallback) => {
                return api.organizationResourceList
                    .createOrganizationResourceList({
                        organizationId,
                        item: { name },
                    })
                    .then((list) => {
                        createResourceList(list);

                        return api.organizationResourceListResource
                            .addResourceToOrganizationResourceList({
                                organizationId,
                                listId: list.id,
                                item,
                            })
                            .then((res) => {
                                addResourceListResource({ listId: list.id, resource: res });
                            });
                    })
                    .then(refreshCallback);
            }}
            item={item}
            {...props}
        />
    );
};

export default compose(connect(mapStateToProps, dispatchProps))(AddToListResourceListDropdown);
