import React, { useEffect, useState } from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import numeral from 'numeral';
import { groupBy, uniq } from 'lodash/fp';

import { BodyText2, SectionBody, Small } from '@portal/common/components';
import { localizeConditions } from '@portal/common/utility/chart-data-helpers';
import { styled } from '@portal/common/theme';
import formatChartValue from '@portal/common/utility/formatting/formatChartValue';
import {
    AGE_GROUP_ID_KEY,
    CAUSE_PREVALENCE_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    METRIC_ID_KEY,
    VALUE_KEY,
} from '@portal/common/models/data-key';
import {
    CauseAssociatedCoprevalenceRecords,
    isComorbidityCoprevalenceDataType,
} from '@portal/common/models/data-type';

import api from '../../api';
import {
    getMergedDataCollectionGranularity,
    getFiltersWithConditions,
} from '../../utility/data-loader';
import {
    getDataResponsesByType,
    getSelectedConditions,
    getSelectedDataType,
    getSelectedRefinementFilters,
} from '../../store/data-explorer/selectors';
import { getSelectedDataCollectionForDataTool } from '../../store/user-settings/selectors';
import { getOrganizationId } from '../../store/root-reducer';
import { getComorbidityTabKeyByDataType } from './comorbidity-type-group';

import config from '../../config';
import _ from '../../locale';

const StyledSectionBody = styled(SectionBody)(({ theme }) => ({
    padding: 15,
    [theme.breakpoint.md]: {
        padding: 30,
    },
}));

const mapStateToProps = (state: RootState) => ({
    conditions: getSelectedConditions(state),
    refinementFilters: getSelectedRefinementFilters(state),
    dataType: getSelectedDataType(state),
    organizationId: getOrganizationId(state),
    dataCollection: getSelectedDataCollectionForDataTool(state),
    dataResponsesByType: getDataResponsesByType(state),
});

interface Props extends ReturnType<typeof mapStateToProps> {}

export function ComorbidityCoprevalenceSummarySection(props: Props) {
    const {
        dataType,
        conditions,
        refinementFilters,
        organizationId,
        dataCollection,
        dataResponsesByType,
    } = props;

    if (
        !(
            dataResponsesByType &&
            dataCollection &&
            conditions &&
            conditions.length &&
            refinementFilters &&
            isComorbidityCoprevalenceDataType(dataType)
        )
    ) {
        return null;
    }

    const { comorbid_cause_id, location_id, gender_id } = refinementFilters;

    const [dataByLocationId, setData] = useState(null);

    useEffect(() => {
        const granularity = getMergedDataCollectionGranularity(dataCollection, [dataType]);

        api.data
            .queryDataCollectionResourceData(
                organizationId,
                dataCollection.id,
                dataType,
                getFiltersWithConditions(
                    conditions,
                    {
                        ...refinementFilters,
                        [AGE_GROUP_ID_KEY]: [
                            dataType === CauseAssociatedCoprevalenceRecords
                                ? config.twentyAndAbove
                                : config.allAgesGroupId,
                        ],
                        [METRIC_ID_KEY]: [config.numberMetricId],
                        [GENDER_ID_KEY]: uniq([
                            ...(refinementFilters[GENDER_ID_KEY] || []),
                            config.bothGendersId,
                        ]),
                    },
                    Object.keys(granularity)
                )
            )
            .then((res) => {
                const { columns, records } = res[dataType];
                const genderIdx = columns.indexOf(GENDER_ID_KEY);
                const recordsByGender = groupBy((record) => record[genderIdx], records);

                let genderRenders;
                if (recordsByGender.hasOwnProperty(config.bothGendersId)) {
                    genderRenders = recordsByGender[config.bothGendersId];
                } else {
                    genderRenders = Object.values(recordsByGender)[0];
                }

                const locationIdx = columns.indexOf(LOCATION_ID_KEY);
                const valueIdx = columns.indexOf(VALUE_KEY);
                const prevalenceCauseValueIdx = columns.indexOf(CAUSE_PREVALENCE_KEY);

                let dataByLocationId = null;
                if (genderRenders && genderRenders.length) {
                    dataByLocationId = genderRenders.map((record) => {
                        return {
                            locationId: record[locationIdx],
                            value: record[valueIdx],
                            prevalenceCauseValue: record[prevalenceCauseValueIdx],
                            genderId: record[genderIdx],
                        };
                    });
                }
                setData(dataByLocationId);
            })
            .catch(console.log);
    }, [
        location_id?.toString(),
        comorbid_cause_id?.toString(),
        gender_id?.toString(),
        localizeConditions(conditions),
    ]);

    if (dataByLocationId === null) {
        return null;
    }

    const formatIndividualsNumber = (value: number): string => numeral(value).format('0,0');

    const primaryCondition = localizeConditions(conditions).toLowerCase();
    const comorbidCondition = _(`comorbid_cause_${comorbid_cause_id}`).toLowerCase();
    const correlationFactorValue = '1.0';
    const gender = _(`comorbidity_summary_gender_${dataByLocationId[0]?.genderId}`);

    const renderLocationData = ({ locationId, value, prevalenceCauseValue }) => (
        <span key={locationId}>
            <BodyText2>
                {_(`comorbidity_${getComorbidityTabKeyByDataType(dataType)}_summary`, {
                    primaryCondition,
                    comorbidCondition,
                    location: _(`location_${locationId}`),
                    prevalenceCauseValue: formatIndividualsNumber(prevalenceCauseValue),
                    coPrevalencePercent: formatChartValue((100 * value) / prevalenceCauseValue),
                    value: formatIndividualsNumber(value),
                    correlationFactorValue,
                    gender,
                })}
            </BodyText2>
            <br />
        </span>
    );

    return (
        <StyledSectionBody>
            {(dataByLocationId || []).map(renderLocationData)}
            <Small>{_('comorbidity_summary_annotation')}</Small>
        </StyledSectionBody>
    );
}

export default connect(mapStateToProps, null)(ComorbidityCoprevalenceSummarySection);
