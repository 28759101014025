import React from 'react';
import { connect } from 'react-redux';

import { Organization } from '@portal/common/types';
import { parseApiError } from '@portal/common/utility/api-helpers';

import {
    selectOrganization,
    loadOrganization,
    updateOrganization,
} from '../../store/organization/actions';

import api from '../../api';

import ModalForm from './ModalForm';

type Props = {
    organizationId: number;
    organization: Organization;
    loadOrganization: (id: number) => void;
    updateOrganization: (id: number, data: Organization) => void;
    selectOrganization: (data: Organization) => void;
    isVisible: boolean;
    onClose: () => void;
    onSuccess: (showRegistrationUrlModal?: boolean) => void;
};

type State = {
    data: Organization;
    isSubmittable: boolean;
    errors: object | null;
};

const INITIAL_STATE = {
    data: {
        self_sign_up_mode: 'url',
    },
    isSubmittable: false,
    errors: null,
};

class SelfRegistrationOptionsModal extends React.Component<Props, State> {
    state: State = INITIAL_STATE;

    componentDidMount() {
        this.props.loadOrganization(this.props.organizationId);
        this.setState({ data: this.props.organization });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.organization !== this.props.organization) {
            this.setState({ data: this.props.organization });
        }
    }

    isLoaded = () =>
        this.props.organization != null && this.props.organization.self_sign_up_mode != null;

    handleClose = () => {
        this.props.onClose();
    };

    handleSubmit = () => {
        const {
            organization: { self_sign_up_token },
            organizationId,
        } = this.props;
        const { self_sign_up_mode, email_domains } = this.state.data;
        this.props
            .updateOrganization(organizationId, {
                self_sign_up_mode,
                email_domains,
            })
            .then((updatedOrganization: Organization) => {
                const showRegistrationUrlModal =
                    !self_sign_up_token && !!updatedOrganization.self_sign_up_token;
                this.props.onSuccess(showRegistrationUrlModal);
            })
            .catch((err) => {
                this.setState({ errors: parseApiError(err) });
            });
    };

    handleRegenerateTokenClick = () => {
        api.organization
            .regenerateSelfSignUpUrl({
                organizationId: this.props.organizationId,
            })
            .then((updatedOrganization) => {
                this.props.selectOrganization(updatedOrganization);
            })
            .catch((err) => {
                this.setState({ errors: parseApiError(err) });
            });
    };

    onDataChange = (e) => {
        const { name, value } = e.target;

        const newState = {
            data: {
                ...this.state.data,
                [name]: value,
            },
        };

        const { self_sign_up_mode, email_domains } = this.props.organization;

        newState.isSubmittable =
            newState.data.self_sign_up_mode !== self_sign_up_mode ||
            newState.data.email_domains !== email_domains;

        this.setState(newState);
    };

    render() {
        const { errors, data, isSubmittable } = this.state;

        return (
            <ModalForm
                isLoaded={this.isLoaded()}
                isVisible={this.props.isVisible}
                onClose={this.handleClose}
                errors={errors}
                onSubmit={this.handleSubmit}
                data={data}
                onDataChange={this.onDataChange}
                onRegenerateToken={this.handleRegenerateTokenClick}
                isSubmittable={isSubmittable}
            />
        );
    }
}

export default connect(null, {
    selectOrganization,
    loadOrganization,
    updateOrganization,
})(SelfRegistrationOptionsModal);
