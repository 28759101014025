import { uniq } from 'lodash/fp';

import {
    CauseEntity,
    DataCollectionResource,
    DataCondition,
    DataFilters,
    DataType,
} from '@portal/common/types';

import {
    getDataCollectionGranularityByDataTypes,
    getFiltersWithConditions,
} from '../../utility/data-loader';
import { intersectRefinementFiltersWithGranularity } from '../../components/DataExplorer/utils';

export const normalizeQueryParams = (
    dataCollection: DataCollectionResource,
    conditions: DataCondition[],
    filters: DataFilters
) => {
    const dataTypes = conditions ? uniq(conditions.map((i) => i.data_type)) : null;

    if (!dataTypes) {
        return null;
    }

    const granularityByDataType = getDataCollectionGranularityByDataTypes(
        dataCollection,
        dataTypes
    );

    return (dataTypes || []).map((dataType) => {
        const dataTypeConditions = conditions.filter(
            (condition: DataCondition) => condition.data_type === dataType
        );

        const dataTypeGranularity = granularityByDataType[dataType];

        const granularityKeys = Object.keys(dataTypeGranularity);
        const intersectedFilters =
            intersectRefinementFiltersWithGranularity(
                getFiltersWithConditions(dataTypeConditions, filters, granularityKeys),
                dataTypeGranularity
            ) || {};

        Object.entries(intersectedFilters).forEach(([key, value]) => {
            if (!(value as number[])?.length) {
                delete intersectedFilters[key];
            }
        });

        return {
            dataType,
            filters: intersectedFilters,
        };
    });
};

export const getLicensedConditionsByLevel = (
    dataType: DataType,
    hierarchy: CauseEntity[],
    level: number = -1
): DataCondition[] =>
    hierarchy
        .filter((entity) => entity.level === level)
        .map(({ id }) => ({
            data_type: dataType,
            primary_entity_id: id,
        }));

export const getLicensedConditionChildren = (
    condition: DataCondition,
    hierarchy: CauseEntity[]
): DataCondition[] => {
    const { data_type: dataType, primary_entity_id: conditionId } = condition;
    const conditionEntity = hierarchy.find(({ id }) => id === conditionId);
    const conditionLevel = conditionEntity?.level || 0;
    const level = conditionLevel + 1;

    return hierarchy
        .filter(
            (entity) =>
                entity.level === level && entity[`level${conditionLevel}_parent_id`] === conditionId
        )
        .map(({ id }) => ({
            data_type: dataType,
            primary_entity_id: id,
        }));
};
