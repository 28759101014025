import React from 'react';
import { debounce, flatten, remove } from 'lodash';
import { groupBy, isEmpty, isEqual, sortBy } from 'lodash/fp';

import _ from '@portal/common/locale';

import {
    DataCollectionResource,
    DataExportFormat,
    DataFilters,
    DataGranularity,
    DataKey,
    DataRecord,
    DataResponse,
    DataType,
    Entity,
    TimeUnitKey,
} from '@portal/common/types';

import {
    AGE_GROUP_ID_KEY,
    CAUSE_ID_KEY,
    EDUCATION_ID_KEY,
    FORECAST_SCENARIO_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    LOWER_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    PRIMARY_ENTITY_ID_KEY,
    RACE_ID_KEY,
    RISK_EXPOSURE_ID_KEY,
    UPPER_KEY,
    VALUE_KEY,
} from '@portal/common/models/data-key';

import {
    chartProviderFiltersToObject,
    getChartSubTitle,
    getChartTitle,
    getFirstSupportedDataResponse,
    getLeadingDataTypeInMultiTypeSelections,
    sortResponseByTimeUnit,
} from '@portal/common/utility/chart-data-helpers';
import { TYPE_TREEMAP_CHART } from '@portal/common/models/chart-type';
import { getExportFilename } from '@portal/common/utility/get-export-filename';
import { hslToHex } from '@portal/common/utility/color';
import { CauseOutcome } from '@portal/common/models/data-type';
import { PNG } from '@portal/common/models/file-format';
import { pngIcon } from '@portal/common/theme/icons';

import animatableChart from '../../../../utility/hoc/animatable-chart';

import TreemapChartView from './TreemapChartView';

const COLORS = [352, 208, 142, 50, 100, 250, 300, 25, 75, 125, 175, 230, 275, 325];

type Props = {
    dataTypeConfig?: {
        groupLabel: string;
        filterKey: string;
        localePrefix: string;
    };
    dataType: DataType;
    initialTimeUnitValue: number | string;
    hierarchy: Entity[] | null;
    selectedConditionsDataTypes: DataType[];
    selectedConditionsDataTypesGranularity: DataGranularity;
    selectedDataTool: string;
    selectedDataCollection: DataCollectionResource;
    dataResponses: null | Record<string, DataResponse>;
    isLoadingData: boolean;
    isForecastingData: boolean;
    filtersValues: DataFilters; // @todo: rename to 'selectedRefinementFilters', for example
    timeUnitKey: TimeUnitKey;
    onExportData?: (format: DataExportFormat) => void;
    enableExportPNG: boolean;

    isChartPoppedOut: boolean;
    toggleChartPoppedOut: () => void;

    detailLevel: number;
    setDetailLevel?: (value: number) => void;

    selectedConditionEntity: Entity | null;
    setConditionEntity: (value: Entity | null) => void;
    numberFormatter: (value: number | string) => string;

    renderTitle?: (props) => string;
    renderSubtitle?: (props) => string;
};

type State = {
    dataResponse: DataResponse;
    level1ConditionIds: number[];
    windowHeight: number;

    conditionGranularity: number[];
    parsedHierarchy: Entity[];
    flatParsedHierarchy: Entity[];
};

class TreemapChartContainer extends React.PureComponent<Props, State> {
    state: State = {
        dataResponse: {
            columns: [],
            records: [],
        },
        level1ConditionIds: [],
        windowHeight: window.innerHeight,

        conditionGranularity: [],
        parsedHierarchy: [],
        flatParsedHierarchy: [],
    };

    constructor(props: Props) {
        super(props);

        this.onWindowResize = debounce(this.onWindowResize.bind(this), 250);
    }

    chartRef = React.createRef();

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);

        const { dataResponses } = this.props;

        if (dataResponses) {
            this.initializeData();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    onWindowResize = () => {
        this.setState({ windowHeight: window.innerHeight });
    };

    componentDidUpdate(prevProps: Props) {
        const { isLoadingData, filtersValues, dataResponses, selectedConditionEntity, hierarchy } =
            this.props;

        const isNewDataLoaded = !isLoadingData && prevProps.isLoadingData;
        if (
            dataResponses &&
            (!isEqual(filtersValues && prevProps.filtersValues) ||
                isNewDataLoaded ||
                selectedConditionEntity?.id !== prevProps.selectedConditionEntity?.id ||
                (prevProps.hierarchy === null && hierarchy))
        ) {
            this.initializeData(isNewDataLoaded);
        }
    }

    initializeData = (isNewDataLoaded: boolean = false) => {
        const {
            dataResponses,
            timeUnitKey,
            hierarchy,
            selectedConditionEntity,
            setConditionEntity,
            dataTypeConfig,
        } = this.props;

        const dataResponse = sortResponseByTimeUnit(
            getFirstSupportedDataResponse(dataResponses, TYPE_TREEMAP_CHART),
            timeUnitKey
        );

        const { columns, records } = dataResponse;

        let conditionIdIdx = columns.indexOf(dataTypeConfig?.filterKey);
        if (conditionIdIdx === -1) {
            conditionIdIdx = columns.indexOf(PRIMARY_ENTITY_ID_KEY);
        }

        const conditionGranularity = Object.keys(
            groupBy((record) => record[conditionIdIdx], records)
        ).map(Number);
        const parsedHierarchy = this.parseHierarchy(hierarchy, conditionGranularity);
        const level1ConditionIds = flatten(
            parsedHierarchy.map((level0Item) => (level0Item.children || []).map(({ id }) => id))
        );

        const nextState: Partial<State> = {
            dataResponse,
            conditionGranularity,
            parsedHierarchy,
            level1ConditionIds,
            flatParsedHierarchy: this.getFlatHierarchy(parsedHierarchy),
        };

        if (isNewDataLoaded && selectedConditionEntity && selectedConditionEntity.id) {
            if (
                !(nextState.flatParsedHierarchy || []).find(
                    ({ id }) => id === selectedConditionEntity.id
                )
            ) {
                setConditionEntity(null);
            }
        }

        this.setState(nextState);
    };

    getFlatHierarchy = (hierarchy: Entity[]): Entity[] => {
        const hasKids = (item) => item && item.children && item.children.length;

        const flatParsedHierarchy = [];
        const doHierarchyFlatten = (hierarchy) => {
            hierarchy.forEach((item) => {
                flatParsedHierarchy.push(item);

                if (hasKids(item)) {
                    doHierarchyFlatten(item.children);
                }
            });
        };

        doHierarchyFlatten(hierarchy);
        return flatParsedHierarchy;
    };

    parseHierarchy = (entityHierarchy: Entity[] | null, granularity: number[]): [] => {
        const rootLevel = 0;
        const limitHierarchyDepth = 6;

        if (!(entityHierarchy && entityHierarchy.length)) {
            return [];
        }

        // Filtering hierarchy items so it'll include only available granularity options
        const filteredHierarchy = this.tuneLevels(
            this.tuneRootLevel(entityHierarchy, granularity).filter(
                (item) => granularity.includes(item.id) || item.level === 0
            ),
            rootLevel
        );

        const hierarchyByLevels = groupBy('level', filteredHierarchy);
        const minLevel = Math.min(
            ...Object.keys(hierarchyByLevels).map((level) => parseInt(level))
        );

        const parseHierarchyItem = (item, level) => {
            let unparsedChildren = null;
            let children = null;
            if (level < limitHierarchyDepth || item[`level${level + 1}_parent_id`]) {
                const subLevelItems = hierarchyByLevels[level + 1];
                if (subLevelItems && subLevelItems.length) {
                    unparsedChildren = groupBy(
                        `level${level + rootLevel}_parent_id`,
                        subLevelItems
                    )[item.id];

                    if (unparsedChildren) {
                        children = unparsedChildren.map((item) =>
                            parseHierarchyItem(item, level + 1)
                        );
                    }
                }
            }

            return {
                ...item,
                children,
            };
        };

        return hierarchyByLevels[minLevel].map((levelData) =>
            parseHierarchyItem(levelData, minLevel)
        );
    };

    /**
     * @todo This logic is duplicated in ConditionDropdown
     * Level upping items when parent level item doesn't exist
     */
    tuneLevels = (hierarchyRecords: Entity[], rootLevel: number) => {
        // console.log('Tuning levels');

        const availableItemIds = hierarchyRecords.map((item) => item.id);
        const hierarchyByLevels = groupBy('level', hierarchyRecords);
        const availableLevels = Object.keys(hierarchyByLevels).map((level) => parseInt(level));

        const minLevel = Math.min(...availableLevels);
        const maxLevel = Math.max(...availableLevels);

        const levelUpItem = (item, toLevel, toParentId) => {
            if (item.level > rootLevel) {
                remove(hierarchyByLevels[item.level], (i) => i.id === item.id);

                const levelUppedItem = {
                    ...item,
                    level: toLevel,
                    [`level${toLevel - 1}_parent_id`]: toParentId,
                };

                if (!hierarchyByLevels[toLevel]) {
                    hierarchyByLevels[toLevel] = [];
                }

                hierarchyByLevels[toLevel].push(levelUppedItem);
            }
        };

        const getParentId = (item, level) => item[`level${level}_parent_id`];

        const leveledUpItems = {};

        let item, parentId, expectedItemLevel, parentWasFound;

        for (let level = minLevel + 1; level <= maxLevel; ++level) {
            // console.log('    Checking level ' + level);
            const itemsAtCurrentLevel = hierarchyByLevels[level];

            if (itemsAtCurrentLevel) {
                for (let i = itemsAtCurrentLevel.length - 1; i >= 0; --i) {
                    if (itemsAtCurrentLevel[i]) {
                        item = itemsAtCurrentLevel[i];
                        // console.log('        Checking item ' + item.id);

                        // If no direct parent
                        parentId = getParentId(item, level - 1);
                        if (!availableItemIds.includes(parentId)) {
                            // console.log('            The item does not have direct parent');

                            // Find the next existing parent
                            parentWasFound = false;
                            for (
                                let parentLevel = level - 2;
                                parentLevel >= minLevel;
                                --parentLevel
                            ) {
                                parentId = getParentId(item, parentLevel);

                                // console.log('                Checking parent at level ' + parentLevel);
                                if (availableItemIds.includes(parentId)) {
                                    parentWasFound = true;

                                    // And level up just below it
                                    expectedItemLevel =
                                        (parentId in leveledUpItems
                                            ? leveledUpItems[parentId]
                                            : parentLevel) + 1;

                                    // console.log('                    Found parent ' + parentId + ' at level ' + parentLevel + ', leveling up ' + item.id + ' to level ' + expectedItemLevel);

                                    levelUpItem(item, expectedItemLevel, parentId);
                                    leveledUpItems[item.id] = expectedItemLevel;
                                    break;
                                }
                            }

                            // If no parents found then level up to the min level
                            if (!parentWasFound) {
                                // console.log('                No parent was found. Leveling up ' + item.id + ' to the min level ' + minLevel);

                                levelUpItem(item, minLevel, null);
                                leveledUpItems[item.id] = minLevel;
                            }
                        }
                        // If the direct parent was leveled up
                        else if (parentId in leveledUpItems) {
                            // console.log('            Direct parent is found but it was leveled up to level ' + leveledUpItems[parentId] + '. Leveling up ' + item.id + ' to the next level ' + (leveledUpItems[parentId] + 1));

                            levelUpItem(item, leveledUpItems[parentId] + 1, parentId);
                            leveledUpItems[item.id] = leveledUpItems[parentId] + 1;
                        }
                    }
                }
            }
        }

        // console.log('Finished tuning levels');
        return flatten(Object.values(hierarchyByLevels));
    };

    tuneRootLevel = (hierarchy: Entity[], granularity: number[]) => {
        const { dataTypeConfig } = this.props;

        const newRootItemId = -1;
        const levelUpAllItems = (items: Entity[]) =>
            (items || []).map((item) => ({
                ...item,
                level: item.level + 1,
                level0_parent_id: newRootItemId,
                level1_parent_id: item.level0_parent_id,
                level2_parent_id: item.level1_parent_id,
                level3_parent_id: item.level2_parent_id,
                level4_parent_id: item.level3_parent_id,
                level5_parent_id: item.level4_parent_id,
                level6_parent_id: item.level5_parent_id,
            }));

        const level0Items = hierarchy.filter(({ level }) => level === 0);
        const level0ItemsWithValues = level0Items.filter(({ id }) => granularity.includes(id));

        if (level0ItemsWithValues.length > 1) {
            const newTopLevelItem = {
                id: newRootItemId,
                name: dataTypeConfig?.groupLabel,
                level: 0,
                level0_parent_id: 0,
                level1_parent_id: 0,
                level2_parent_id: 0,
                level3_parent_id: 0,
                level4_parent_id: 0,
                level5_parent_id: 0,
                level6_parent_id: 0,
            };

            return levelUpAllItems(hierarchy).concat(newTopLevelItem);
        }

        return hierarchy;
    };

    handleNodeSelect = (id) => {
        //@todo: replace with adopted/recalculated hierarchy
        const {
            detailLevel: prevDetailLevel,
            setDetailLevel,
            selectedConditionEntity: prevSelectedConditionEntity,
            setConditionEntity,
        } = this.props;
        const { flatParsedHierarchy } = this.state;

        let selectedConditionEntity = (flatParsedHierarchy || []).find(({ id: id1 }) => id === id1);
        let detailLevel = prevDetailLevel;

        if (selectedConditionEntity) {
            setConditionEntity(
                selectedConditionEntity.level === 0 ? null : selectedConditionEntity
            );
        }

        if (!selectedConditionEntity) {
            detailLevel = 3;
        } else if (!prevSelectedConditionEntity && selectedConditionEntity.level > 0) {
            detailLevel = Math.max(2, prevDetailLevel);
        }

        setDetailLevel && setDetailLevel(detailLevel);
    };

    getChartData = () => {
        const { timeUnitKey } = this.props;
        const { records } = this.state.dataResponse;

        if (records.length) {
            const timeUnitIdx = this.getDataKeyIdx(timeUnitKey);
            const recordsByTimeUnit = Object.values(
                groupBy((record) => record[timeUnitIdx], records)
            );
            return recordsByTimeUnit.map(this.parseRecords);
        } else {
            return [];
        }
    };

    parseRecords = (records: DataRecord[]): [] => {
        const { parsedHierarchy } = this.state;
        return parsedHierarchy.map((condition) => this.parseCondition(condition, records));
    };

    getConditionKeyIdx = () => this.getDataKeyIdx(this.props.dataTypeConfig.filterKey);

    getDataKeyIdx = (dataKey: DataKey): number => this.state.dataResponse.columns.indexOf(dataKey);

    parseCondition = (condition: Entity, records: DataRecord[]) => {
        const {
            dataType,
            dataTypeConfig: { localePrefix, groupLabel },
            getConditionStyles,
        } = this.props;

        const { id } = condition;

        const conditionKeyIdx = this.getConditionKeyIdx();
        const valueIdx = this.getDataKeyIdx(VALUE_KEY);

        const rawData = records.find((record) => record[conditionKeyIdx] === id);

        let name = id === -1 ? groupLabel : _(`${localePrefix}${id}`);
        if (dataType === CauseOutcome) {
            const shortNameKey = `${localePrefix}short_${id}`;
            const shortName = _(shortNameKey);
            if (shortName !== shortNameKey) {
                // we have a translation for short cause
                name = shortName;
            }
        }

        const value = rawData ? rawData[valueIdx] : null;

        return {
            id,
            name,
            value,
            rawData,
            itemStyle: {
                color: this.getNodeColor(condition),
                borderColor: this.getNodeColor(condition),
                borderWidth: 4,
                gapWidth: 4,
            },
            emphasis: {
                itemStyle: {
                    color: this.getNodeColor(condition, true),
                    borderColor: this.getNodeColor(condition, true),
                    borderWidth: 14,
                },
            },
            ...(getConditionStyles && getConditionStyles(condition)),
            children: (condition.children || [])
                .filter(this.filterByDetailLevel)
                .map((item) => this.parseCondition(item, records)),
        };
    };

    filterByDetailLevel = (item): boolean => {
        const { detailLevel, selectedConditionEntity: selectedCondition } = this.props;

        switch (detailLevel) {
            case 1:
                return selectedCondition
                    ? selectedCondition[`level${item.level}_parent_id`] === item.id || // parent of selected condition
                          selectedCondition.level === item.level // all siblings
                    : item.level === 1;

            case 2:
                return selectedCondition
                    ? selectedCondition[`level${item.level}_parent_id`] === item.id || // parent of selected condition
                          selectedCondition.level === item.level || // all siblings
                          selectedCondition.level === item.level - 1 // selected condition children
                    : item.level === 1;

            case 3:
                return selectedCondition
                    ? selectedCondition.id === item.id || // selected condition
                          selectedCondition[`level${item.level}_parent_id`] === item.id || // parent of selected condition
                          selectedCondition.level < item.level // selected condition children
                    : true;
        }

        return true;
    };

    getNodeColor = (condition: Entity, isHover?: boolean): string => {
        const { level1ConditionIds } = this.state;
        const { id, level, level1_parent_id } = condition;

        const idx = level1ConditionIds.indexOf(level === 1 ? id : level1_parent_id);

        const sMap = [7, 67, 67, 67, 75];
        const sHoverMap = [7, 67, 67, 78, 100];
        const lMap = [95, 18, 30, 48, 61];
        const lHoverMap = [95, 24, 36, 54, 67];

        const h = level === 0 ? 150 : COLORS[idx % COLORS.length];
        const s = (isHover ? sHoverMap : sMap)[level];
        const l = (isHover ? lHoverMap : lMap)[level];

        return hslToHex(h, s, l);
    };

    renderTooltip = (params) => {
        if (!params.data) {
            return null;
        }

        const {
            dataType,
            dataTypeConfig: { localePrefix },
        } = this.props;

        const { children, name, rawData, id } = params.data;
        const hasChildren = !isEmpty(children);

        let tooltipContent = '<table style="padding-bottom: 10px;">';

        const getConditionName = (name: string, id: number): string => {
            const isCauseOutcome = dataType === CauseOutcome;
            if (isCauseOutcome) {
                const fullName = _(`${localePrefix}${id}`);

                if (fullName !== name) {
                    return `${fullName} (${name})`;
                }
            }

            return name;
        };

        // @todo: check why some conditions has undefined rawData
        tooltipContent += this.renderConditionRow(getConditionName(name, id), rawData, hasChildren);

        if (hasChildren) {
            const sortedByValueConditions = sortBy('value', children).reverse();
            const itemsAmount = sortedByValueConditions.length;
            const conditionsToDisplay = sortedByValueConditions.slice(0, Math.min(5, itemsAmount));
            const hiddenConditionsAmount = itemsAmount - conditionsToDisplay.length;

            tooltipContent += conditionsToDisplay
                .map(({ name, rawData, id }, idx) =>
                    this.renderConditionRow(
                        getConditionName(name, id),
                        rawData,
                        false,
                        idx === 0 ? 10 : 0
                    )
                )
                .join('');
            tooltipContent += '</table>';

            if (hiddenConditionsAmount) {
                tooltipContent += `<div style="margin-top: 8px; padding-top: 10px; border-top: 1px solid #DFE0E0;">+ ${hiddenConditionsAmount} more</div>`;
            }
        } else {
            tooltipContent += '</table>';
        }

        return `<div class="chart-tooltip">${tooltipContent}</div>`;
    };

    renderConditionRow = (
        name: string,
        record: DataRecord,
        showBottomBorder: boolean = false,
        paddingTop: number = 0
    ): string | null => {
        if (!record) {
            return this.renderTooltipRow(name, '<b>N/A</b>');
        }

        const { numberFormatter } = this.props;
        const { columns } = this.state.dataResponse;

        const values = {};
        columns.forEach((key, idx) => {
            values[key] = record[idx];
        });

        const value = numberFormatter(values[VALUE_KEY]);
        const upper = numberFormatter(values[UPPER_KEY]);
        const lower = numberFormatter(values[LOWER_KEY]);

        const uncertainty =
            values[UPPER_KEY] !== null && values[LOWER_KEY] !== null ? `(${lower} - ${upper})` : '';

        return this.renderTooltipRow(
            name,
            `<b>${value}</b> ${uncertainty}`,
            showBottomBorder,
            paddingTop
        );
    };

    renderTooltipRow = (
        name: string,
        value: string | number,
        showBottomBorder: boolean = false,
        paddingTop: number = 0
    ) =>
        `<tr class="chart-tooltip" style="min-height: 28px; ${
            showBottomBorder ? 'border-bottom: 1px solid #DFE0E0; padding-bottom: 10px;' : ''
        }">
            <td style="padding-right: 20px; ${
                showBottomBorder ? 'padding-bottom: 10px;' : ''
            } padding-top: ${paddingTop}px;">
                <div style="width: 140px; word-wrap: break-word; white-space: normal;">${name}</div>
            </td>
            <td>
            <div style="float: right; ${
                showBottomBorder ? 'padding-bottom: 10px;' : ''
            } padding-top: ${paddingTop}px;">${value}</div>
            </td>
        </tr>`;

    renderTitle = (renderProps) => {
        const { filters, timelineValue } = renderProps;
        const { selectedConditionsDataTypes, selectedConditionsDataTypesGranularity, renderTitle } =
            this.props;
        if (renderTitle) {
            return renderTitle(renderProps);
        }

        const {
            dataResponse: { records },
        } = this.state;

        return records.length > 0
            ? getChartTitle(
                  [getLeadingDataTypeInMultiTypeSelections(selectedConditionsDataTypes)],
                  '',
                  chartProviderFiltersToObject(filters),
                  Object.keys(selectedConditionsDataTypesGranularity),
                  [RISK_EXPOSURE_ID_KEY, CAUSE_ID_KEY, MEASURE_ID_KEY, METRIC_ID_KEY],
                  timelineValue
              )
            : '';
    };

    renderSubtitle = (renderProps) => {
        const { isForecastingData, selectedConditionsDataTypesGranularity, renderSubtitle } =
            this.props;
        if (renderSubtitle) {
            return renderSubtitle(renderProps);
        }

        const { records } = this.state.dataResponse;
        const { filters } = renderProps;
        const titleFilters = [
            RACE_ID_KEY,
            LOCATION_ID_KEY,
            AGE_GROUP_ID_KEY,
            GENDER_ID_KEY,
            EDUCATION_ID_KEY,
        ];
        if (isForecastingData) {
            titleFilters.push(FORECAST_SCENARIO_ID_KEY);
        }

        return records.length > 0
            ? getChartSubTitle(
                  chartProviderFiltersToObject(filters),
                  Object.keys(selectedConditionsDataTypesGranularity),
                  titleFilters
              )
            : '';
    };

    getSaveFilename = ({ filters, timelineValue }) => {
        const { selectedConditionsDataTypes, selectedDataTool, selectedDataCollection } =
            this.props;

        return getExportFilename({
            trigger: TYPE_TREEMAP_CHART,
            dataTool: selectedDataTool,
            conditions: _(
                `data_license_type_${getLeadingDataTypeInMultiTypeSelections(
                    selectedConditionsDataTypes
                )}`
            ),
            dataCollectionName: selectedDataCollection.name,
            filters,
            selectedTimeUnit: timelineValue,
        });
    };

    render() {
        const {
            dataTypeConfig,
            initialTimeUnitValue,
            timeUnitKey,
            onExportData,
            enableExportPNG,
            isChartPoppedOut,
            selectedConditionEntity,
            theme,
        } = this.props;

        const { dataResponse, windowHeight } = this.state;

        const conditionGroupName = dataTypeConfig ? dataTypeConfig.groupLabel : '';

        const chartHeight = isChartPoppedOut ? windowHeight - 20 : 700;
        theme.treemap.height = chartHeight - 180;

        return (
            <div>
                <TreemapChartView
                    key={initialTimeUnitValue}
                    {...this.props}
                    {...dataResponse}
                    ref={this.chartRef}
                    chartHeight={chartHeight}
                    data={this.getChartData()}
                    theme={theme}
                    renderTitle={this.renderTitle}
                    renderSubtitle={this.renderSubtitle}
                    renderTooltip={this.renderTooltip}
                    rootName={conditionGroupName}
                    saveAsImage={{
                        visible: true,
                        enabled: enableExportPNG,
                        filename: this.getSaveFilename,
                        icon: pngIcon,
                        onClick: () => onExportData?.(PNG),
                    }}
                    enableTimelineWithFilterKey={timeUnitKey}
                    initialTimelineValue={initialTimeUnitValue}
                    onNodeSelect={this.handleNodeSelect}
                    selectedNodeId={selectedConditionEntity?.id}
                    selectedNodeData={selectedConditionEntity}
                />
            </div>
        );
    }
}

export default animatableChart(TreemapChartContainer);
