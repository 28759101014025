import React, { createRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'MyTypes';
import { RouteComponentProps, withRouter } from 'react-router';
import { isEqual, omit } from 'lodash/fp';

import {
    Button,
    FlexColumn,
    FlexRow,
    H2,
    Loading,
    PageBody,
    PageBodyFooter,
    PageContainer,
    PageHeader,
    ScrollToTop,
} from '@portal/common/components';
import {
    DataExplorerSection,
    DataExplorerSelectionResource,
    DataExportFormat,
} from '@portal/common/types';
import {
    CHART_SECTION,
    DATA_TABLE_SECTION,
    ONETIME_DATA_SECTION,
} from '@portal/common/models/data-explorer-section';
import { isPdfExportEnabled } from '@portal/common/models/organization';
import { isDataToolAccessible } from '@portal/common/models/data-project';
import { TYPE_DATA_TABLE_CHART, TYPE_MAP_CHART } from '@portal/common/models/chart-type';
import { styled } from '@portal/common/theme';
import { CSV, PDF, XLS } from '@portal/common/models/file-format';

import { MY_FAVORITES_ID } from '../../router/paths';
import {
    dataSelectionValidation,
    downloadDataExportAsync,
    downloadPdfExportAsync,
    initializeDataTableDisplayState,
    initializeDataTypeSelection,
    initializeSelectedChartType,
    initializeSelectedDataType,
    initializeVisibleRefinementFilters,
    queryDataRendered,
    resetDataTool,
    validateSelectedRefinementFiltersComplexityLimit,
} from '../../store/data-explorer/actions';
import {
    getDataCollections,
    getDataRecordsAmount,
    getDataResponsesByType,
    getDataTableSortedByColumn,
    getDataTableSortedDescending,
    getDataTableVisibleColumns,
    getDataToolConfig,
    getSelectedChartType,
    getSelectedConditions,
    getSelectedConditionsDataTypes,
    getSelectedConditionsRefinedGranularity,
    getSelectedDataType,
    getSelectedRefinementFiltersWithFallbackToDefaultFilters,
    getVisibleRefinementFilters,
} from '../../store/data-explorer/selectors';
import {
    getSelectedDataCollectionForDataTool,
    getSelectedDataCollectionIdByDataTools,
} from '../../store/user-settings/selectors';
import { initializeDataCollection } from '../../store/user-settings/actions';
import { getOrganization } from '../../store/root-reducer';

import _ from '../../locale';
import DataToolNotShared from '../../components/DataToolNotShared';
import DataTableSection from '../../components/DataExplorer/DataTableSection';
import ChartSection from '../../components/DataExplorer/ChartSection';
import SharePermalinkModal from '../../components/DataExplorer/SharePermalink/SharePermalinkModal';
import CopiedPermalinkModal from '../../components/DataExplorer/SharePermalink/CopiedPermalinkModal';
import ToolbarSection from '../../components/DataExplorer/ToolbarSection';
import PdfReportModal from '../../components/DataExplorer/PdfReport/PdfReportModal';
import AddToListDataExplorerDropdown from '../../components/AddToListDropdown/AddToListDataExplorerDropdown';
import { generateDataExplorerSelection } from '../../components/DataExplorer/utils';
import ExportPDFButton from '../../components/ExportPDFButton';

import EDPSelectDataSection from './EDPSelectDataSection';
import EDPOneTimeDataSection from './EDPOnetimeDataSection';

const ContentContainer = styled(FlexColumn)(({ theme }) => ({
    flexGrow: 1,
    marginTop: -30,
    '> :not(:last-child)': {
        marginBottom: 15,
        [theme.breakpoint.md]: {
            marginBottom: 30,
        },
    },
}));

const mapStateToProps = (state: RootState) => ({
    dataCollections: getDataCollections(state),
    selectedDataCollection: getSelectedDataCollectionForDataTool(state),
    selectedDataCollectionsByDataTools: getSelectedDataCollectionIdByDataTools(state),
    selectedConditions: getSelectedConditions(state),
    selectedConditionsDataTypes: getSelectedConditionsDataTypes(state),
    selectedRefinementFilters: getSelectedRefinementFiltersWithFallbackToDefaultFilters(state),
    visibleRefinementFilters: getVisibleRefinementFilters(state),
    selectedChartType: getSelectedChartType(state),
    selectedDataType: getSelectedDataType(state),
    dataToolConfig: getDataToolConfig(state),
    visibleColumns: getDataTableVisibleColumns(state),
    sortedByColumn: getDataTableSortedByColumn(state),
    sortedDescending: getDataTableSortedDescending(state),
    organization: getOrganization(state),
    dataResponsesByType: getDataResponsesByType(state),
    granularity: getSelectedConditionsRefinedGranularity(state),
    dataRecordsAmount: getDataRecordsAmount(state),
});

const dispatchProps = {
    queryDataRendered,
    initializeSelectedChartType,
    initializeSelectedDataType,
    initializeVisibleRefinementFilters,
    initializeDataCollection,
    resetDataTool,
    initializeDataTypeSelection,
    downloadDataExportRequest: downloadDataExportAsync.request,
    downloadPdfExportRequest: downloadPdfExportAsync.request,
    initializeDataTableDisplayState,
    queryDataValidationRequest: dataSelectionValidation.request,
    validateSelectedRefinementFiltersComplexityLimit:
        validateSelectedRefinementFiltersComplexityLimit,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & RouteComponentProps & {};

type State = {
    isVisiblePdfReportModal: boolean;
    isVisibleSharePermalinkModal: boolean;
    isVisibleCopiedPermalinkModal: boolean;
    areChartsVisible: boolean;
    pdfFilters: { label: string; value: string }[] | null;
    dataExplorerSelectedListsIds: (string | number)[];
};

class EDPExplorerScene extends React.Component<Props, State> {
    clearHistoryListen: any;

    state: State = {
        isVisibleSharePermalinkModal: false,
        isVisiblePdfReportModal: false,
        isVisibleCopiedPermalinkModal: false,
        areChartsVisible: true,
        pdfFilters: null,
        dataExplorerSelectedListsIds: [],
    };

    containerRef = createRef<HTMLDivElement>();

    componentDidMount() {
        this.clearHistoryListen = this.props.history.listen((location, action) => {
            if (location.pathname != this.getDataToolName()) {
                this.props.resetDataTool(this.props.dataToolConfig);
            }
        });

        if (this.areDataCollectionsLoaded()) {
            this.initialize();
        }
    }

    componentWillUnmount() {
        this.clearHistoryListen();
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedConditions, selectedRefinementFilters } = this.props;

        if (!prevProps.dataCollections && this.areDataCollectionsLoaded()) {
            this.initialize();
        }

        if (
            !isEqual(prevProps.selectedConditions, selectedConditions) ||
            !isEqual(prevProps.selectedRefinementFilters, selectedRefinementFilters)
        ) {
            this.clearDataExplorerSelectedLists();
        }
    }

    getDataToolName = (): string => this.props.location.pathname;

    areDataCollectionsLoaded = (): boolean => {
        const { dataCollections } = this.props;
        return !!(dataCollections && dataCollections.length);
    };

    initialize = () => {
        const {
            dataCollections,
            location,
            history,
            dataToolConfig,
            selectedDataCollection,
            initializeSelectedChartType,
            initializeSelectedDataType,
            initializeVisibleRefinementFilters,
            initializeDataCollection,
            initializeDataTypeSelection,
            initializeDataTableDisplayState,
            queryDataValidationRequest,
            validateSelectedRefinementFiltersComplexityLimit,
        } = this.props;

        const initData: {
            dataCollectionId;
            conditions;
            refinementFilters;
            dataType;
            chartType;
            activeRefinements;
            configuration;
        } = {};

        const routerState = location.state;

        // initialize from DataExplorerSelectionResource
        if (routerState && routerState.dataLoaded) {
            const {
                dataCollectionId,
                conditions,
                refinementFilters,
                dataType,
                chartType,
                activeRefinements,
                configuration,
            } = location.state;

            initData.dataCollectionId = dataCollectionId;
            initData.conditions = conditions;
            initData.refinementFilters = refinementFilters;
            initData.dataType = dataType;
            initData.chartType = chartType;
            initData.activeRefinements = activeRefinements;
            initData.configuration = configuration;

            history.replace({
                state: omit(
                    [
                        'dataCollectionId',
                        'conditions',
                        'refinementFilters',
                        'dataType',
                        'chartType',
                        'activeRefinements',
                        'configuration',
                    ],
                    routerState
                ),
            });
        }
        let {
            dataCollectionId,
            conditions,
            refinementFilters,
            dataType,
            chartType,
            activeRefinements,
            configuration,
        } = initData;

        const dataProjectsDataCollections = (dataCollections || []).filter((collection) =>
            dataToolConfig.dataProjectIds.includes(collection.data_project_id)
        );

        if (dataCollectionId == null) {
            if (selectedDataCollection != null) {
                dataCollectionId = selectedDataCollection.id;
            } else if (dataProjectsDataCollections.length) {
                // fallback
                dataCollectionId = dataProjectsDataCollections[0].id;
            } else {
                return;
            }
        }

        // mandatory initializations
        initializeDataCollection({
            dataCollectionId,
            dataTool: this.getDataToolName(),
        });

        initializeSelectedDataType(dataType || dataToolConfig.defaultDataType);
        initializeSelectedChartType(chartType || dataToolConfig.defaultChartType);

        initializeDataTypeSelection({
            conditions: conditions || [],
            refinementFilters: refinementFilters || null,
        });

        validateSelectedRefinementFiltersComplexityLimit(refinementFilters);

        if (typeof configuration?.chartSettings?.areVisible !== 'undefined') {
            this.setState({ areChartsVisible: configuration?.chartSettings?.areVisible });
        }

        // optional initializations
        if (activeRefinements) {
            initializeVisibleRefinementFilters(activeRefinements);
        }

        initializeDataTableDisplayState(configuration);

        queryDataValidationRequest({
            conditions: conditions || [],
            refinementFilters: refinementFilters || null,
        });
    };

    clearDataExplorerSelectedLists = () => this.setState({ dataExplorerSelectedListsIds: [] });

    setDataExplorerSelectedLists = (listId: string | number) =>
        this.setState(({ dataExplorerSelectedListsIds }) => ({
            dataExplorerSelectedListsIds: dataExplorerSelectedListsIds.concat(listId),
        }));

    handleChangePdfFilters = (pdfFilters) => {
        this.setState({ pdfFilters });
    };

    handleSharePermalinkModalOpen = () => {
        this.setState({ isVisibleSharePermalinkModal: true });
    };

    handleSharePermalinkModalClose = (isSubmitted: boolean) => {
        this.setState({
            isVisibleSharePermalinkModal: false,
            isVisibleCopiedPermalinkModal: isSubmitted,
        });
    };

    handleCopiedPermalinkModalClose = () => {
        this.setState({ isVisibleCopiedPermalinkModal: false });
    };

    handlePdfReportModalOpen = () => {
        this.setState({ isVisiblePdfReportModal: true });
    };

    handlePdfReportModalClose = () => {
        this.setState({ isVisiblePdfReportModal: false });
    };

    getSelectedDataset = (selectedDataCollectionResource, selectedConditionsDataTypes) =>
        selectedDataCollectionResource.datasets.find(
            (i) => i.data_type === selectedConditionsDataTypes[0]
        );

    getChartSettings = () => {
        const { selectedChartType, detailLevel, mapType: type } = this.props;
        const mapChartSettings = { detailLevel, type };

        return {
            ...(selectedChartType === TYPE_MAP_CHART && mapChartSettings),
            areVisible: this.state.areChartsVisible,
        };
    };

    getDataExplorerSelection = (): DataExplorerSelectionResource =>
        generateDataExplorerSelection(
            this.getDataToolName(),
            this.props.selectedDataCollection,
            this.props.selectedConditions,
            this.props.selectedRefinementFilters,
            this.props.granularity,
            this.props.visibleRefinementFilters,
            this.props.selectedDataType,
            this.props.sortedByColumn,
            this.props.sortedDescending,
            this.props.visibleColumns,
            this.props.selectedChartType,
            this.getChartSettings()
        );

    renderHeaderControls = () => {
        const {
            selectedConditionsDataTypes,
            dataResponsesByType,
            organization,
            dataRecordsAmount,
        } = this.props;
        const { dataExplorerSelectedListsIds } = this.state;

        return (
            <FlexRow itemsSpacing={15}>
                <Button
                    disabled={!selectedConditionsDataTypes}
                    onClick={this.handleSharePermalinkModalOpen}
                >
                    {_.get('data_explorer_share_permalink_btn')}
                </Button>
                <AddToListDataExplorerDropdown
                    item={this.getDataExplorerSelection()}
                    customSelectedListsIds={dataExplorerSelectedListsIds}
                    onFavoritesResourceClick={() =>
                        this.setDataExplorerSelectedLists(MY_FAVORITES_ID)
                    }
                    onCustomListResourceClick={(_, listId) =>
                        this.setDataExplorerSelectedLists(listId)
                    }
                >
                    {({ ref, toggleVisibility }) => (
                        <Button
                            ref={ref}
                            onClick={toggleVisibility}
                            beforeIconSrc="/icons/ic-star.svg"
                            disabled={!selectedConditionsDataTypes}
                        >
                            {_.get('lists_add_to_list')}
                        </Button>
                    )}
                </AddToListDataExplorerDropdown>
                <ExportPDFButton
                    disabled={!(dataResponsesByType && isPdfExportEnabled(organization))}
                    onClick={this.handlePdfReportModalOpen}
                    dataRecordsAmount={dataRecordsAmount}
                />
            </FlexRow>
        );
    };

    handleExportData = (format: DataExportFormat) => {
        const { dataToolConfig, downloadDataExportRequest, selectedChartType } = this.props;
        const { sections } = dataToolConfig;

        let chart_types;
        switch (format) {
            case CSV:
            case XLS: {
                chart_types = [TYPE_DATA_TABLE_CHART];
                break;
            }
            case PDF: {
                chart_types = [selectedChartType];
                if (sections.includes(DATA_TABLE_SECTION)) {
                    chart_types.push(TYPE_DATA_TABLE_CHART);
                }
                break;
            }
            default: {
                chart_types = [selectedChartType];
                break;
            }
        }

        downloadDataExportRequest({ chart_types, format });
    };

    toggleChartsVisibility = () => {
        this.setState((state) => ({
            ...state,
            areChartsVisible: !state.areChartsVisible,
        }));
    };

    renderSections = () => {
        const { dataToolConfig, queryDataRendered } = this.props;
        const { areChartsVisible } = this.state;

        if (!dataToolConfig) {
            return null;
        }

        const { sections } = dataToolConfig;

        return (
            <>
                <EDPSelectDataSection
                    areChartsVisible={areChartsVisible}
                    toggleChartsVisibility={this.toggleChartsVisibility}
                />
                {sections.map((section) => {
                    switch (section) {
                        case DATA_TABLE_SECTION:
                            return (
                                <DataTableSection
                                    key={section}
                                    onExportData={this.handleExportData}
                                />
                            );
                        case CHART_SECTION:
                            return (
                                <ChartSection
                                    key={section}
                                    areChartsVisible={areChartsVisible}
                                    onExportData={this.handleExportData}
                                    onDataRendered={() => void queryDataRendered(Date.now())}
                                />
                            );
                        case ONETIME_DATA_SECTION:
                            return <EDPOneTimeDataSection key={section} />;
                        default: {
                            return null;
                        }
                    }
                })}
            </>
        );
    };

    checkIsSectionDisabled = (section: DataExplorerSection) => {
        if (section === CHART_SECTION && !this.state.areChartsVisible) {
            return true;
        }

        return false;
    };

    renderDataExplorerContent = () => {
        const {
            selectedDataCollection,
            selectedConditionsDataTypes,
            selectedChartType,
            dataToolConfig,
        } = this.props;

        const {
            pdfFilters,
            isVisiblePdfReportModal,
            isVisibleSharePermalinkModal,
            isVisibleCopiedPermalinkModal,
        } = this.state;

        const selectedDataset =
            selectedConditionsDataTypes == null
                ? null
                : this.getSelectedDataset(selectedDataCollection, selectedConditionsDataTypes);

        return (
            <>
                <div
                    id="back-to-top-anchor"
                    style={{
                        position: 'relative',
                        left: -300,
                        top: -100,
                        width: 100,
                    }}
                />
                {this.renderSections()}
                {selectedConditionsDataTypes && dataToolConfig && (
                    <>
                        <PdfReportModal
                            isVisible={isVisiblePdfReportModal}
                            onClose={this.handlePdfReportModalClose}
                            data={{ activeRefinements: pdfFilters }}
                            dataset={selectedDataset}
                            chartType={selectedChartType}
                            checkIsSectionCheckboxDisabled={this.checkIsSectionDisabled}
                            onSubmit={({ sectionsVisibility }) => {
                                const { downloadPdfExportRequest, selectedChartType } = this.props;
                                const { sections } = dataToolConfig;
                                const chart_types = [selectedChartType];
                                if (sections.includes(DATA_TABLE_SECTION)) {
                                    chart_types.push(TYPE_DATA_TABLE_CHART);
                                }
                                const pdf_report_options = Object.entries(
                                    sectionsVisibility
                                ).reduce((acc, [key, value]) => {
                                    if (value && !this.checkIsSectionDisabled(key)) {
                                        acc.push(key);
                                    }

                                    return acc;
                                }, []);

                                downloadPdfExportRequest({
                                    chart_types,
                                    pdf_report_options,
                                });
                            }}
                        />
                    </>
                )}
                {selectedConditionsDataTypes && dataToolConfig && (
                    <SharePermalinkModal
                        isVisible={isVisibleSharePermalinkModal}
                        onClose={this.handleSharePermalinkModalClose}
                        dataExplorerSelection={this.getDataExplorerSelection()}
                    />
                )}

                <CopiedPermalinkModal
                    isVisible={isVisibleCopiedPermalinkModal}
                    onClose={this.handleCopiedPermalinkModalClose}
                />
                <ToolbarSection>
                    {_('custom_chart_scene_citation_text', {
                        date: new Date().toLocaleDateString('en-US'),
                    })}
                </ToolbarSection>
            </>
        );
    };

    render() {
        const { dataToolConfig, dataCollections } = this.props;

        if (!dataToolConfig) {
            return null;
        }

        if (!dataCollections) {
            return <Loading />;
        }

        if (!isDataToolAccessible(dataToolConfig.dataProjectIds, dataCollections)) {
            return <DataToolNotShared toolPath={this.getDataToolName()} isLarge />;
        }

        return (
            <PageContainer>
                <PageHeader>
                    <FlexRow justify="space-between" grow={1} align="center">
                        <H2>{_(dataToolConfig.title)}</H2>
                        {!!(dataCollections && dataCollections.length) &&
                            this.renderHeaderControls()}
                    </FlexRow>
                </PageHeader>
                <PageBody transparentSurface innerRef={this.containerRef}>
                    <ContentContainer>{this.renderDataExplorerContent()}</ContentContainer>
                    <PageBodyFooter>{_.get('footer_note')}</PageBodyFooter>
                </PageBody>
                {this.containerRef.current && <ScrollToTop target={this.containerRef.current} />}
            </PageContainer>
        );
    }
}

export default compose(withRouter, connect(mapStateToProps, dispatchProps))(EDPExplorerScene);
