import { AxiosRequestConfig, CancelToken } from 'axios';
import { mapValues, merge } from 'lodash';
import { pipe } from 'lodash/fp';

import api from '@portal/common/api';
import request from '@ihme/common/api-request';
import {
    AnnouncementResource,
    ConditionDetailNotes,
    CountryLocationSdi,
    DataExplorerSelectionResource,
    DataGranularityResponse,
    DataProject,
    DataRequest,
    DataResponse,
    Entity,
    MethodologyResource,
    OrganizationMember,
    OrganizationMemberAPICredentials,
    PaginatedRequest,
    PaginatedResponse,
    Poll,
    PollRespond,
    Resource,
    ResourceList,
    Round,
    SidebarLinkResource,
    WelcomeMessageResource,
} from '@portal/common/types';
import {
    deserializeResource,
    deserializeRichText,
    serializeResource,
} from '@portal/common/utility/api-helpers';

import config from './config';

const UPLOAD_TIMEOUT = 5 * 60 * 1000;
const DOWNLOAD_TIMEOUT = 3600 * 1000;

api.setApiUrl(config.apiUrl);

export default merge(api, {
    auth: {
        getEndpoint: () => request.get('oauth2/authorization-endpoint'),
    },

    session: {
        signIn: (email, password) =>
            request.post('organization-member-sessions', { email, password }),
        signInWithSSO: (code, state) =>
            request.post('oauth2/organization-member-sessions', { code, state }),
    },

    account: {
        getOrganization: (accountId) =>
            request.get(`organization-members/${accountId}/organization`, {
                fields: 'email_domains,self_sign_up_mode,self_sign_up_token',
            }),
        getAccount: (id) => request.get('organization-members/' + id.toString()),
        updateAccount: (id, data) => request.put(`organization-members/${id}`, data),
        setRole: (id, role) => request.put(`organization-members/${id}/role`, { role }),
        setPassword: (id, old_password, password) =>
            request.put(`/my-organization/members/${id}`, { password, old_password }),
    },

    organizationMember: {
        add: (data) => request.post(`organization-members`, data),
        resetPassword: (email) => request.post('organization-member-passwords/' + email),
    },

    organization: {
        addOrganization: (data) => request.post('organizations', data),
        updateOrganization: (id, data) => request.put(`organizations/${id}`, data),
        getOrganization: (id) =>
            request
                .get(`organizations/${id}`, {
                    fields: 'email_domains,self_sign_up_mode,self_sign_up_token,licensed_until,license_expiration_warning',
                })
                .then(pipe(deserializeRichText('license_expiration_warning'))),

        getOrganizations: ({ search, page, per_page, self_sign_up_token }) =>
            request.get('organizations', {
                search,
                page,
                per_page,
                self_sign_up_token,
            }),

        addOrganizationMember: (organizationId, data): Promise<OrganizationMember> =>
            request.post(`organizations/${organizationId}/members`, data),
        bulkCreateOrganizationMembers: (organizationId, members) =>
            request.patch(
                `organizations/${organizationId}/members`,
                members.map((member) => ({
                    op: 'add',
                    path: '/',
                    value: member,
                })),
                { timeout: 5 * 60 * 1_000 }
            ),
        setOrganizationMember: (
            organizationId: number,
            memberId: number,
            data: Partial<OrganizationMember>
        ): Promise<OrganizationMember> =>
            request.put(`organizations/${organizationId}/members/${memberId}`, data),
        getOrganizationMember: (
            organizationId: number,
            memberId: number
        ): Promise<OrganizationMember> =>
            request.get(`organizations/${organizationId}/members/${memberId}`),
        deleteOrganizationMember: (
            organizationId: number,
            memberId: number
        ): Promise<OrganizationMember> =>
            request.delete(`organizations/${organizationId}/members/${memberId}`),
        getOrganizationMembers: ({
            organizationId,
            search,
            page,
            per_page,
        }): Promise<OrganizationMember[]> =>
            request.get(`organizations/${organizationId}/members`, {
                search,
                page,
                per_page,
                fields: 'first_name,last_name,last_access,avatar_url,email',
            }),

        setOrganizationMemberRole: (organizationId, memberId, organization_role) =>
            request.put(`organizations/${organizationId}/members/${memberId}`, {
                organization_role,
            }),

        getOrganizationMemberAPICredentials: ({
            organizationId,
            memberId,
            ...params
        }): Promise<PaginatedResponse<OrganizationMemberAPICredentials>> =>
            request.get(
                `organizations/${organizationId}/members/${memberId}/api-credentials`,
                params
            ),
        addOrganizationMemberAPICredentials: ({
            organizationId,
            memberId,
            data,
        }): Promise<OrganizationMemberAPICredentials> =>
            request.post(
                `organizations/${organizationId}/members/${memberId}/api-credentials`,
                data
            ),
        removeOrganizationMemberAPICredentials: ({
            organizationId,
            memberId,
            apiCredentialsId,
        }): Promise<OrganizationMemberAPICredentials> =>
            request.delete(
                `organizations/${organizationId}/members/${memberId}/api-credentials/${apiCredentialsId}`
            ),

        getOrganizationDataExplorerSelections: ({
            organizationId,
            ...params
        }: PaginatedRequest<DataExplorerSelectionResource> & {
            organizationId: number;
        }): Promise<PaginatedResponse<DataExplorerSelectionResource>> =>
            request
                .get(`organizations/${organizationId}/data-explorer-selection-resources`, {
                    fields: 'category{description},created,sort_order',
                    ...params,
                })
                .then((res) => {
                    return {
                        ...res,
                        results: res.results.map(deserializeResource),
                    };
                }),
        createOrganizationDataExplorerSelections: ({
            organizationId,
            item,
        }: {
            organizationId: number;
            item: DataExplorerSelectionResource;
        }): Promise<DataExplorerSelectionResource> =>
            request
                .post(
                    `organizations/${organizationId}/data-explorer-selection-resources`,
                    serializeResource(item)
                )
                .then((res) => deserializeResource(res)),
        updateOrganizationDataExplorerSelections: ({
            organizationId,
            item,
        }: {
            organizationId: number;
            item: DataExplorerSelectionResource;
        }): Promise<DataExplorerSelectionResource> =>
            request
                .put(
                    `organizations/${organizationId}/data-explorer-selection-resources/${item.id}`,
                    serializeResource(item)
                )
                .then((res) => deserializeResource(res)),

        getOrganizationDataCollectionResourceRefinedGranularity: ({
            organizationId,
            resourceId,
            data_type,
            round_id,
            primary_entity_id = null,
        }): Promise<DataGranularityResponse> =>
            request.post(
                `organizations/${organizationId}/data-collection-resources/${resourceId}/${data_type}/granularity`,
                {
                    round_id,
                    primary_entity_id,
                }
            ),
        regenerateSelfSignUpUrl: ({ organizationId }) =>
            request.post(`organizations/${organizationId}/self_sign_up_token`, {}),

        // best-practice
        getAnnouncements: (
            paginationParams: PaginatedRequest<AnnouncementResource>
        ): Promise<PaginatedResponse<AnnouncementResource>> =>
            request.get(`my-organization/announcements`, {
                ...paginationParams,
                fields: 'is_live',
            }),
        getSidebarLinks: (
            paginationParams: PaginatedRequest<SidebarLinkResource>
        ): Promise<PaginatedResponse<SidebarLinkResource>> =>
            request.get(`my-organization/sidebar-links`, {
                ...paginationParams,
                fields: '',
            }),
        getWelcomeMessage: (): Promise<WelcomeMessageResource> =>
            request
                .get(`my-organization/welcome-message`, {
                    fields: 'creator{first_name,last_name,email},created,updated',
                })
                .then(pipe(deserializeRichText('text'), deserializeRichText('title'))),

        getOrganizationResource: ({
            organizationId,
            hash,
        }: {
            organizationId: number;
            hash: string;
        }) => request.get(`/organizations/${organizationId}/resources?hash=${hash}`),
    },

    organizationGroupContent: {
        getLinksAndFiles: ({
            organizationId,
            ...params
        }: PaginatedRequest<ResourceList> & {
            organizationId: number;
        }): Promise<PaginatedResponse<ResourceList>> =>
            request.get(`organizations/${organizationId}/group-content-resources`, {
                ...params,
                fields: 'category{description},created,sort_order',
                type: 'link,file',
            }),

        getLinks: ({
            organizationId,
            ...params
        }: PaginatedRequest<ResourceList> & {
            organizationId: number;
        }): Promise<PaginatedResponse<ResourceList>> =>
            request.get(`organizations/${organizationId}/group-content-link-resources`, {
                ...params,
                fields: 'category{description},created,sort_order',
            }),

        getFiles: ({
            organizationId,
            ...params
        }: PaginatedRequest<ResourceList> & {
            organizationId: number;
        }): Promise<PaginatedResponse<ResourceList>> =>
            request.get(`organizations/${organizationId}/group-content-file-resources`, {
                ...params,
                fields: 'category{description},created,sort_order',
            }),

        getDataCollections: ({
            organizationId,
            ...params
        }: PaginatedRequest<ResourceList> & {
            organizationId: number;
        }): Promise<PaginatedResponse<ResourceList>> =>
            request.get(`organizations/${organizationId}/group-content-data-collection-resources`, {
                page: 1,
                per_page: 1000,
                ...params,
                fields: ['created', 'sort_order']
                    .concat(params.fields ? params.fields : [])
                    .join(','),
            }),
    },

    permalink: {
        getPermalinks: ({
            organizationId,
            memberId,
            ...params
        }: PaginatedRequest<Resource> & {
            organizationId: number;
            memberId: number;
        }): Promise<PaginatedResponse<Resource>> =>
            request
                .get(`organizations/${organizationId}/members/${memberId}/permalinked-resources`, {
                    fields: 'category{description},created,sort_order',
                    ...params,
                })
                .then((res) => {
                    return {
                        ...res,
                        results: res.results.map(deserializeResource),
                    };
                }),

        createPermalink: ({
            name,
            organizationId,
            memberId,
            dataExplorerSelection,
        }: {
            name: string;
            organizationId: number;
            memberId: number;
            dataExplorerSelection: DataExplorerSelectionResource;
        }): Promise<Resource> =>
            request
                .post(
                    `organizations/${organizationId}/members/${memberId}/permalinked-data-explorer-selection-resources`,
                    {
                        ...serializeResource(dataExplorerSelection),
                        name,
                    }
                )
                .then((res) => deserializeResource(res)),

        removePermalink: ({
            organizationId,
            memberId,
            permalinkId,
        }: {
            organizationId: number;
            memberId: number;
            permalinkId: number;
        }): Promise<Resource> =>
            request
                .delete(
                    `organizations/${organizationId}/members/${memberId}/permalinked-resources/${permalinkId}`
                )
                .then((res) => deserializeResource(res)),
    },
    organizationMemberFavoriteResource: {
        getFavoriteResources: ({
            organizationId,
            memberId,
            ...params
        }: PaginatedRequest<Resource> & {
            organizationId: number;
            memberId: number;
        }): Promise<PaginatedResponse<Resource>> =>
            request
                .get(`organizations/${organizationId}/members/${memberId}/favorite-resources`, {
                    fields: 'category{description},created,sort_order',
                    ...params,
                })
                .then((res) => {
                    return {
                        ...res,
                        results: res.results.map(deserializeResource),
                    };
                }),
        addResourceToFavorites: ({
            organizationId,
            memberId,
            item,
        }: {
            organizationId: number;
            memberId: number;
            item: Resource;
        }): Promise<Resource> =>
            request
                .post(
                    `organizations/${organizationId}/members/${memberId}/favorite-resources/${item.id}`,
                    serializeResource(item)
                )
                .then((res) => deserializeResource(res)),
        createDataExplorerSelectionResourceAndAddItToFavorites: ({
            organizationId,
            memberId,
            item,
        }: {
            organizationId: number;
            memberId: number;
            item: Resource;
        }): Promise<Resource> =>
            request
                .post(
                    `organizations/${organizationId}/members/${memberId}/favorite-data-explorer-selection-resources`,
                    serializeResource(item)
                )
                .then((res) => deserializeResource(res)),
        removeResourceFromFavorites: ({
            organizationId,
            memberId,
            item,
        }: {
            organizationId: number;
            memberId: number;
            item: Resource;
        }): Promise<Resource> =>
            request
                .delete(
                    `organizations/${organizationId}/members/${memberId}/favorite-resources/${item.id}`
                )
                .then((res) => deserializeResource(res)),
    },

    organizationResourceList: {
        createOrganizationResourceList: ({
            organizationId,
            item,
        }: {
            organizationId: number;
            item: Partial<ResourceList>;
        }): Promise<ResourceList> =>
            request.post(`organizations/${organizationId}/resource-lists`, item),
        updateOrganizationResourceList: ({
            organizationId,
            listId,
            item,
        }: {
            organizationId: number;
            listId: number;
            item: Partial<ResourceList>;
        }): Promise<ResourceList> =>
            request.put(`organizations/${organizationId}/resource-lists/${listId}`, {
                fields: 'resource_ids',
                ...item,
            }),
        deleteOrganizationResourceList: ({
            organizationId,
            listId,
        }: {
            organizationId: number;
            listId: number;
        }): Promise<ResourceList> =>
            request.delete(`organizations/${organizationId}/resource-lists/${listId}`),
    },

    organizationResourceListResource: {
        getResourceListResources: ({
            organizationId,
            listId,
            ...params
        }: PaginatedRequest<Resource> & {
            organizationId: number;
            listId: number;
        }): Promise<PaginatedResponse<Resource>> =>
            request
                .get(`organizations/${organizationId}/resource-lists/${listId}/resources`, {
                    fields: 'category{description},created,sort_order',
                    ...params,
                })
                .then((res) => {
                    return {
                        ...res,
                        results: res.results.map(deserializeResource),
                    };
                }),
        addResourceToOrganizationResourceList: ({
            organizationId,
            listId,
            item,
        }: {
            organizationId: number;
            listId: number;
            item: Resource;
        }): Promise<Resource> =>
            request
                .post(
                    `organizations/${organizationId}/resource-lists/${listId}/resources/${item.id}`,
                    serializeResource(item)
                )
                .then((res) => deserializeResource(res)),
        createDataExplorerSelectionResourceAndAddItToOrganizationResourceList: ({
            organizationId,
            listId,
            item,
        }: {
            organizationId: number;
            listId: number;
            item: Resource;
        }): Promise<Resource> =>
            request
                .post(
                    `organizations/${organizationId}/resource-lists/${listId}/data-explorer-selection-resources`,
                    serializeResource(item)
                )
                .then((res) => deserializeResource(res)),
        removeResourceFromOrganizationResourceList: ({
            organizationId,
            listId,
            item,
        }: {
            organizationId: number;
            listId: number;
            item: Resource;
        }): Promise<Resource> =>
            request
                .delete(
                    `organizations/${organizationId}/resource-lists/${listId}/resources/${item.id}`
                )
                .then((res) => deserializeResource(res)),
    },

    organizationPublicResourceList: {
        getPublicResourceLists: ({
            organizationId,
            ...params
        }: PaginatedRequest<ResourceList> & {
            organizationId: number;
        }): Promise<PaginatedResponse<ResourceList>> =>
            request.get(`organizations/${organizationId}/public-resource-lists`, params),

        makeResourceListPublic: (organizationId: number, listId: number): Promise<ResourceList> =>
            request.post(`/organizations/${organizationId}/public-resource-lists/${listId}`, {
                fields: 'resource_ids',
            }),

        makeResourceListPrivate: (organizationId: number, listId: number): Promise<ResourceList> =>
            request.delete(`/organizations/${organizationId}/public-resource-lists/${listId}`, {}),

        makeResourceListDefault: (organizationId: number, listId: number): Promise<ResourceList> =>
            request.post(
                `/organizations/${organizationId}/public-resource-lists/${listId}/is_default`,
                { fields: 'resource_ids' }
            ),

        makeResourceListNotDefault: (
            organizationId: number,
            listId: number
        ): Promise<ResourceList> =>
            request.delete(
                `/organizations/${organizationId}/public-resource-lists/${listId}/is_default`,
                { fields: 'resource_ids' }
            ),
    },

    organizationMemberSubscribedResourceList: {
        subscribe: (
            organizationId: number,
            memberId: number,
            listId: number
        ): Promise<ResourceList> =>
            request.post(
                `/organizations/${organizationId}/members/${memberId}/subscribed-resource-lists/${listId}`,
                {}
            ),

        unsubscribe: (
            organizationId: number,
            memberId: number,
            listId: number
        ): Promise<ResourceList> =>
            request.delete(
                `/organizations/${organizationId}/members/${memberId}/subscribed-resource-lists/${listId}`,
                {}
            ),

        getSubscribedResourceLists: ({
            organizationId,
            memberId,
            ...params
        }: PaginatedRequest<ResourceList> & {
            organizationId: number;
            memberId: number;
        }): Promise<PaginatedResponse<ResourceList>> =>
            request
                .get(
                    `organizations/${organizationId}/members/${memberId}/subscribed-resource-lists`,
                    params
                )
                .then((res) => {
                    return {
                        ...res,
                        results: res.results.map(deserializeResource),
                    };
                }),
    },

    // Include subscribed lists + lists created by the organization member
    organizationMemberMyResourceList: {
        getMyResourceLists: ({
            organizationId,
            memberId,
            ...params
        }: PaginatedRequest<ResourceList> & {
            organizationId: number;
            memberId: number;
        }): Promise<PaginatedResponse<ResourceList>> =>
            request
                .get(
                    `organizations/${organizationId}/members/${memberId}/my-resource-lists`,
                    params
                )
                .then((res) => {
                    return {
                        ...res,
                        results: res.results.map(deserializeResource),
                    };
                }),
    },

    polls: {
        getAll: (): Promise<PaginatedResponse<Poll>> => request.get('my-organization/polls'),
        vote: ({ id, ...restParams }: PollRespond): Promise<PaginatedResponse<Poll>> =>
            request.post(`my-organization/polls/${id}/responses`, restParams),
    },

    organizationMemberTickets: {
        createTicket: ({ organizationId, accountId, ...params }): Promise<void> =>
            request.post(`organizations/${organizationId}/members/${accountId}/tickets`, params),
    },

    getPublicAdmins: ({ search, page, per_page }) =>
        request.get('public-admins', {
            search,
            page,
            per_page,
        }),

    data: {
        getCauseForecastFilters: () =>
            request.get('data/cause-forecast-filters', {}, { timeout: 100_000 }),
        getCauses: (): Promise<Entity[]> =>
            request
                .get('causes', {
                    page: 1,
                    per_page: 1000,
                })
                .then((res) => res.results),
        getCauseIcdCodes: (): Promise<Entity[]> => request.get('/cause-icd-codes'),
        getSequelas: (): Promise<Entity[]> =>
            request
                .get('sequelas', {
                    page: 1,
                    per_page: 3000,
                })
                .then((res) => res.results),
        getVehss: (): Promise<Entity[]> => request.get('vehss-conditions'),
        getRei: (type): Promise<Entity[]> =>
            request
                .get('rei', {
                    type,
                    page: 1,
                    per_page: 1000,
                })
                .then((res) => res.results),
        queryDataCollectionResourceData: (
            organizationId,
            resourceId,
            dataType,
            filters,
            requestConfig?: AxiosRequestConfig
        ): Promise<Record<string, DataResponse>> =>
            request
                .post(
                    `/organizations/${organizationId}/data-collection-resources/${resourceId}/${dataType}/data`,
                    mapValues(filters, (values) => {
                        if (values && !Array.isArray(values)) {
                            throw Error('filter values are not an array');
                        }
                        return values && values.join(',');
                    }),
                    { timeout: 300_000, ...requestConfig }
                )
                .then((data) => ({
                    [dataType]: data,
                })),
        getSingleYearAgeData: ({
            resourceId,
            dataType,
            filters,
            requestConfig,
        }): Promise<Record<string, DataResponse>> =>
            request.post(
                `/my-organization/data-collection-resources/${resourceId}/datasets/${dataType}/single-year-age/data`,
                mapValues(filters, (values) => {
                    if (values && !Array.isArray(values)) {
                        throw Error('filter values are not an array');
                    }
                    return values && values.join(',');
                }),
                { timeout: 300_000, ...requestConfig }
            ),
        getSingleYearAgeAggregatedData: ({
            resourceId,
            dataType,
            filters,
            requestConfig,
        }): Promise<Record<string, DataResponse>> =>
            request.post(
                `/my-organization/data-collection-resources/${resourceId}/datasets/${dataType}/single-year-age-aggregated/data`,
                mapValues(filters, (values) => {
                    if (values && !Array.isArray(values)) {
                        throw Error('filter values are not an array');
                    }
                    return values && values.join(',');
                }),
                { timeout: 300_000, ...requestConfig }
            ),
        getSingleYearAgeGranularity: ({
            resourceId,
            dataType,
            roundId,
            primaryEntityId,
            metricId,
        }): Promise<Record<string, DataResponse>> =>
            request.post(
                `/my-organization/data-collection-resources/${resourceId}/datasets/${dataType}/single-year-age/granularity`,
                {
                    round_id: roundId,
                    primary_entity_id: primaryEntityId,
                    metric_id: metricId,
                }
            ),
        getSingleYearAgeAggregatedGranularity: ({
            resourceId,
            dataType,
            roundId,
            primaryEntityId,
            metricId,
        }): Promise<Record<string, DataResponse>> =>
            request.post(
                `/my-organization/data-collection-resources/${resourceId}/datasets/${dataType}/single-year-age-aggregated/granularity`,
                {
                    round_id: roundId,
                    primary_entity_id: primaryEntityId,
                    metric_id: metricId,
                }
            ),
        // Methodology
        getMethodologies: (
            paginationParams: PaginatedRequest<MethodologyResource>
        ): Promise<PaginatedResponse<MethodologyResource>> =>
            request.get('methodologies', {
                ...paginationParams,
                fields: 'creator{first_name,last_name,email},created,updated,sort_order',
                order_by: 'sort_order',
            }),

        getForecastScenarios: () => request.get('data/forecast-scenarios'),
        // { location_id, age_group_id, gender_id, cause_id, measure_id, metric_id, forecast_scenario_id (optional), year (optional) }
        getCauseForecasts: (dataRequest: DataRequest): Promise<DataResponse> =>
            request.get(
                'data/cause-forecasts',
                {
                    location_id: config.globalMapCountryLocationsIds,
                    ...dataRequest,
                },
                { timeout: 30_000 }
            ),
        // /country-locations-sdi-quintiles
        getCountryLocationsSdi: (): Promise<CountryLocationSdi[]> =>
            request.get('country-locations-sdi-quintiles', {}, { timeout: 30_000 }),

        getDatasetGranularityKeyMetadata: (
            organizationId,
            resourceId,
            dataType,
            granularityKey
        ): Promise<Record<string, DataResponse>> =>
            request.get(
                `/organizations/${organizationId}/data-collection-resources/${resourceId}/datasets/${dataType}/granularity/${granularityKey}/metadata`
            ),
    },

    dataProjects: {
        get: (): Promise<DataProject[]> => request.get('data-projects'),
    },

    rounds: {
        get: (): Promise<Round[]> => request.get('rounds'),
    },

    conditionDetailNotes: {
        get: ({ ...params }): Promise<ConditionDetailNotes[]> =>
            request.post('condition-detail-notes', params),
    },

    analytics: {
        visits: (params): Promise<void> => request.post('stats/visits', params),
        pageViews: (params): Promise<void> => request.post('stats/page-views', params),
        fileDownloads: (params): Promise<void> => request.post('stats/file-downloads', params),
        dataQueries: (params): Promise<void> => request.post('stats/data-queries', params),
        dataExport: (params): Promise<void> => request.post('stats/data-exports', params),
        pdfExport: (params): Promise<void> => request.post('stats/pdf-exports', params),
        ticketCreate: (params): Promise<void> => request.post('stats/tickets', params),
    },

    // TODO: move to common
    downloadFile: ({ url, onProgress, cancelController }) =>
        request.get(url, null, {
            responseType: 'blob',
            onDownloadProgress: onProgress,
            timeout: DOWNLOAD_TIMEOUT,
            transformResponse: [
                (payload, headers) => {
                    if (headers['content-type'] === 'application/json') {
                        throw Error(
                            'Server download error. Please try again later or contact IHME support.'
                        );
                    }

                    if (headers['content-type'] === 'application/octet-stream') {
                        const filenameRegExp =
                            /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;

                        const [_0, _1, quotedMatch, unquotedMatch] =
                            filenameRegExp.exec(headers['content-disposition']) || [];

                        if (!quotedMatch && !unquotedMatch) {
                            throw Error('Error parsing Content-Disposition header from the server');
                        }

                        const name = quotedMatch || unquotedMatch;
                        const data = new Blob([payload]);

                        return {
                            data: {
                                name,
                                data,
                            },
                        };
                    }

                    return payload;
                },
            ],
            cancelToken: new CancelToken((cancelCallback) => {
                if (cancelController) {
                    cancelController.cancel = cancelCallback;
                }
            }),
        }),

    // TODO: move to common
    uploadFile: ({ file, onProgress, cancelController }) => {
        const formData = new FormData();
        formData.append('file', file);

        return request.post('uploads', formData, {
            onUploadProgress: onProgress,
            transformRequest: [
                (payload, headers) => {
                    headers.Accept = 'text/plain';
                    return payload;
                },
            ],
            timeout: UPLOAD_TIMEOUT,
            responseType: 'text',
            transformResponse: [
                (payload) => {
                    const data = payload.trim().split('\n').map(JSON.parse);
                    return { data };
                },
            ],
            cancelToken: new CancelToken((cancelCallback) => {
                if (cancelController) {
                    cancelController.cancel = cancelCallback;
                }
            }),
        });
    },

    getUploadedFilePreviewUrl: (uploadedFileId: number): Promise<string> =>
        request.get(`/uploads/${uploadedFileId}/view`),
});
