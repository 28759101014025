import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
    getCauseIcdCodesAsync,
    getCausesAsync,
    getEtiologiesAsync,
    getImpairmentsAsync,
    getInjuryAsync,
    getLocationsAsync,
    getRisksAsync,
    getSequelasAsync,
    getVehssAsync,
} from './actions';
import {
    CauseEntity,
    CauseICDCodeEntity,
    LocationEntity,
    ReiEntity,
    SequelaEntity,
} from '@portal/common/types';

type State = {
    locations: null | LocationEntity[];
    causes: null | CauseEntity[];
    sequelas: null | SequelaEntity[];
    risks: null | ReiEntity[];
    etiologies: null | ReiEntity[];
    impairments: null | ReiEntity[];
    injuries: null | ReiEntity[];
    sevs: null | ReiEntity[];
    vehss: null | CauseEntity[];
    causeICDCodes: null | CauseICDCodeEntity[];
};

const initialState: State = {
    locations: null,
    causes: null,
    sequelas: null,
    risks: null,
    etiologies: null,
    impairments: null,
    injuries: null,
    sevs: null,
    vehss: null,
    causeICDCodes: null,
};

export default combineReducers<State>({
    locations: createReducer(initialState.locations).handleAction(
        getLocationsAsync.success,
        (state, action) => action.payload
    ),
    causes: createReducer(initialState.causes).handleAction(
        getCausesAsync.success,
        (state, action) => action.payload
    ),
    causeICDCodes: createReducer(initialState.causeICDCodes).handleAction(
        getCauseIcdCodesAsync.success,
        (state, action) => action.payload
    ),
    sequelas: createReducer(initialState.sequelas).handleAction(
        getSequelasAsync.success,
        (state, action) => action.payload
    ),
    risks: createReducer(initialState.risks).handleAction(
        getRisksAsync.success,
        (state, action) => action.payload
    ),
    etiologies: createReducer(initialState.etiologies).handleAction(
        getEtiologiesAsync.success,
        (state, action) => action.payload
    ),
    impairments: createReducer(initialState.impairments).handleAction(
        getImpairmentsAsync.success,
        (state, action) => action.payload
    ),
    injuries: createReducer(initialState.injuries).handleAction(
        getInjuryAsync.success,
        (state, action) => action.payload
    ),
    sevs: createReducer(initialState.sevs).handleAction(
        getRisksAsync.success,
        (state, action) => action.payload
    ),
    vehss: createReducer(initialState.vehss).handleAction(
        getVehssAsync.success,
        (state, action) => action.payload
    ),
});
