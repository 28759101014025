import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';

import { Organization } from '@portal/common/types';
import { RoleManager } from '@portal/common/models/organization-roles';

import dataExplorer from './data-explorer/reducer';
import entityHierarchies from './entity-hierarchies/reducer';
import history from './history';
import organization from './organization/reducer';
import resourceLists from './resource-lists/reducer';
import session from './session/reducer';
import * as sessionTypes from './session/types';
import userSettings from './user-settings/reducer';
import resources from './resources/reducer';
import polls from './polls/reducer';

const createRootReducer = () => (prevState: any, action: any) => {
    const reducer = combineReducers({
        router: connectRouter(history),
        session,
        organization,
        userSettings,
        dataExplorer,
        entityHierarchies,
        resourceLists,
        resources,
        polls,
    });

    switch (action.type) {
        // clean root state on sign out
        case sessionTypes.SESSION_SIGNED_OUT:
            return reducer({}, action);

        default:
            return reducer(prevState, action);
    }
};

export default createRootReducer;

export type RootState = StateType<ReturnType<typeof createRootReducer>>;

// session selectors
export const getSessionAccount = (state: RootState) => state.session.account;
export const getSession = (state: RootState) => state.session;
export const getSessionAccountId = (state: RootState) => getSessionAccount(state)?.id;
export const getSessionError = (state: RootState) => getSession(state)?.error;
export const isManager = (state: RootState) =>
    getSessionAccount(state)?.organization_role === RoleManager;
export const getIsNotificationShown = (state: RootState) =>
    getSessionAccount(state)?.is_notification_shown;

// organization selectors
export const getOrganization = (state: RootState): Organization => state.organization;
export const getOrganizationId = (state: RootState): number => getOrganization(state)?.id;

// userSettings selectors
export const getDismissedWelcomeMessageHash = (state: RootState) =>
    state.userSettings.dismissedWelcomeMessageHash;

export const getRouter = (state: RootState) => state.router;
export const getSelectedDataTool = (state: RootState) => getRouter(state).location.pathname;
