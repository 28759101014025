import React from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import { compose } from 'redux';
import query from 'query-string';

import { withGoogleAnalytics } from '@ihme/common/packages/google-analytics';

import _ from '@portal/common/locale';
import { parseApiError } from '@portal/common/utility/api-helpers';

import { removeLocalStorageItem } from '../../services/localStorageService';
import * as sessionActions from '../../store/session/actions';
import * as organizationActions from '../../store/organization/actions';
import { getSessionAccount } from '../../store/root-reducer';
import { FORGOT_PASSWORD_PATH, HOME_PATH, REGISTRATION_PATH } from '../../router/paths';
import api from '../../api';

import SignInSceneView from './SignInSceneView';

import locale from './locale';

const mapStateToProps = (state: RootState, { successMessages, location }) => {
    if (location && location.state && location.state.successMessages) {
        successMessages = location.state.successMessages;
    }

    const { back = '/' } = query.parse(location.search);
    return {
        sessionAccount: getSessionAccount(state),
        successMessages,
        back,
        errorMessage: state.session.error,
    };
};

const dispatchProps = {
    signIn: sessionActions.signIn,
    destroySession: sessionActions.destroySession,
    selectOrganization: organizationActions.selectOrganization,
    resetSession: sessionActions.resetSession,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & {
        history: any;
        back: string;
    };

type State = {
    errors: object[] | null;
};

class SignInScene extends React.PureComponent<Props, State> {
    state: State = {
        errors: null,
    };

    componentDidMount = () => {
        if (this.props.sessionAccount) {
            this.props.history.push(HOME_PATH);
        }
    };

    handleForgotPassword = () => this.props.history.push(FORGOT_PASSWORD_PATH);

    handleRegisterClick = () => this.props.history.push(REGISTRATION_PATH);

    handleSuccess = async (session) => {
        this.clearErrors();

        // Clear entered cipher for Simulation Tool
        removeLocalStorageItem('cipher');

        const account = session.account;
        const organization = account.organization;

        if (organization == null) {
            this.setErrors(new Error(_(locale.notMember)));
            return;
        }

        if (!organization.is_enabled) {
            this.setErrors(new Error(_(locale.disabledOrganization)));
            return;
        }

        this.props.selectOrganization(organization);
        this.postSignIn();
    };

    postSignIn = () => this.props.history.replace(this.props.back);

    signInWithSSO = async () => {
        const url = await api.auth.getEndpoint();
        this.props.resetSession();
        window.open(url, '_self');
    };

    clearErrors = () => this.setState({ errors: null });

    setErrors = (err) => {
        this.props.destroySession();
        this.setState({ errors: parseApiError(err) });
    };

    render() {
        const { errorMessage, signIn, successMessages } = this.props;

        const errors = errorMessage
            ? {
                  'Session Error': errorMessage,
                  ...this.state.errors,
              }
            : this.state.errors;

        return (
            <SignInSceneView
                signIn={signIn}
                onSuccess={this.handleSuccess}
                onForgotPassword={this.handleForgotPassword}
                onRegisterClick={this.handleRegisterClick}
                onSignInWithSSO={this.signInWithSSO}
                errors={errors}
                successMessages={successMessages}
            />
        );
    }
}

export default compose(
    connect(mapStateToProps, dispatchProps),
    withGoogleAnalytics({
        trackMethods: (props) => ({
            handleSuccess: (session) =>
                props.trackEvent({
                    category: 'Account',
                    action: 'Sign In',
                }),
        }),
    })
)(SignInScene);
