import { compose, merge } from 'lodash/fp';

import ihmeTheme from '@ihme/common/theme/default';
import commonTheme from '@portal/common/theme/theme-config';

export default compose(
    merge(ihmeTheme),
    merge(commonTheme)
)({
    // local overrides
});
