import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import cuid from 'cuid';

import { TabResourceList } from '@portal/common/types';

import _ from '../../locale';
import { GROUP_CONTENT_ID } from '../../router/paths';
import {
    addDashboardTab,
    changeDataCollection,
    dismissWelcomeMessage,
    initializeDataCollection,
    removeDashboardTab,
    toggleMinimizeSidebar,
    updateDashboardTab,
} from './actions';
import {
    getPublicResourceListsAsync,
    makeResourceListDefaultAsync,
    makeResourceListNotDefaultAsync,
    makeResourceListPrivateAsync,
    deleteOrganizationResourceListAsync,
} from '../resource-lists/actions';

export const FALLBACK_DEFAULT_TAB: TabResourceList = {
    id: GROUP_CONTENT_ID,
    name: _.get('default_group_list_name'),
    tabId: cuid(),
    is_default: true,
    is_public: true,
};

type State = {
    isSidebarMinimized: boolean;
    dismissedWelcomeMessageHash: null | string;
    selectedDataCollectionIdByDataTool: null | Record<string, number>;
    dashboardTabs: TabResourceList[];
};

const initialState: State = {
    isSidebarMinimized: false,
    dismissedWelcomeMessageHash: null,
    selectedDataCollectionIdByDataTool: null,
    dashboardTabs: [FALLBACK_DEFAULT_TAB],
};

export default combineReducers<State>({
    isSidebarMinimized: createReducer(initialState.isSidebarMinimized).handleAction(
        toggleMinimizeSidebar,
        (state, action) => !state
    ),
    dismissedWelcomeMessageHash: createReducer(
        initialState.dismissedWelcomeMessageHash
    ).handleAction(dismissWelcomeMessage, (state, action) => action.payload),
    selectedDataCollectionIdByDataTool: createReducer(
        initialState.selectedDataCollectionIdByDataTool
    ).handleAction([changeDataCollection, initializeDataCollection], (state, action) => {
        const { dataTool, dataCollectionId } = action.payload;
        return {
            ...state,
            [dataTool]: dataCollectionId,
        };
    }),
    dashboardTabs: createReducer(initialState.dashboardTabs)
        .handleAction(addDashboardTab, (state, action) => state.concat(action.payload))
        .handleAction(updateDashboardTab, (state, action) =>
            state.map((i, index) => (index === action.payload.index ? action.payload.list : i))
        )
        .handleAction(removeDashboardTab, (state, action) =>
            state.filter((i, index) => index !== action.payload)
        )
        .handleAction(getPublicResourceListsAsync.success, (state, action) => {
            const defaultTab = state.find((tab) => tab.is_default);
            const defaultList = action.payload.find((list) => list.is_default);

            // if default tab is present and it's different from new default list then replace it
            if (defaultTab != null && defaultList != null && defaultTab.id != defaultList.id) {
                const newDefaultTab: TabResourceList = { ...defaultList, tabId: cuid() };
                return state.map((tab) => (tab.is_default ? newDefaultTab : tab));
            }

            return state;
        })
        .handleAction(makeResourceListDefaultAsync.success, (state, action) => {
            const defaultTab = state.find((tab) => tab.is_default);
            const defaultList = action.payload;

            // if default tab is present and it's different from new default list then replace it
            if (defaultTab != null && defaultList != null && defaultTab.id != defaultList.id) {
                const newDefaultTab: TabResourceList = { ...defaultList, tabId: cuid() };
                return state.map((tab) => (tab.is_default ? newDefaultTab : tab));
            }

            return state;
        })
        .handleAction(makeResourceListNotDefaultAsync.success, (state, action) => {
            return state.map((tab) => (tab.is_default ? FALLBACK_DEFAULT_TAB : tab));
        })
        .handleAction(makeResourceListPrivateAsync.success, (state, action) => {
            const defaultTab = state.find((tab) => tab.is_default);
            // if default tab list was turned private reset to fallback
            if (defaultTab && defaultTab.id === action.payload.id) {
                return state.map((tab) => (tab.is_default ? FALLBACK_DEFAULT_TAB : tab));
            }
            return state;
        })
        .handleAction(deleteOrganizationResourceListAsync.success, (state, action) => {
            const listId = action.payload.listId;
            const tabs = state.filter((tab) => tab.id !== listId);

            if (tabs.length === 0) {
                return [FALLBACK_DEFAULT_TAB];
            }
            return tabs;
        }),
});
