import React from 'react';

import { Link, SvgIcon } from '@portal/common/components';
import { styled } from '@portal/common/theme';

import _ from '../locale';

const Container = styled.div(({ theme, isLarge }) => ({
    height: '100%',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    ...(isLarge && {
        marginTop: 30,
    }),
}));

const Caption = styled.p(({ theme, isLarge }) => ({
    paddingTop: 20,
    ...(isLarge && {
        fontSize: 18,
        textAlign: 'center',
    }),
}));

const Text = styled.p(({ theme, isLarge }) => ({
    color: '#5B5E5F',
    ...(isLarge && {
        fontSize: 16,
        textAlign: 'center',
    }),
}));

type Props = {
    toolPath: string;
    isLarge?: boolean;
};

export default ({ toolPath, isLarge }: Props) => (
    <Container isLarge={isLarge}>
        <SvgIcon src="/icons/ic-warning.svg" size="huge2" />
        <Caption isLarge={isLarge}>
            <b>Data has not been shared with your organization.</b>
        </Caption>
        <Text isLarge={isLarge}>
            {_(`data_tool_data_${toolPath}`)} data is no longer shared with your organization. To
            gain access please reach out to{' '}
            <Link href="mailto:services@healthdata.org" style={{ display: 'inline' }}>
                services@healthdata.org
            </Link>
            .
        </Text>
    </Container>
);
