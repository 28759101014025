import React from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';

import { styled } from '@portal/common/theme';

import {
    DataCollectionResource,
    DataExportFormat,
    DataFilters,
    DataGranularity,
    DataResponse,
    DataType,
    Entity,
    TimeUnitKey,
} from '@portal/common/types';
import { DATA_TYPE_KEY } from '@portal/common/models/data-key';
import { H4 } from '@portal/common/components';

import _ from '../../../../locale';
import locale from '../../locale';
import theme from '../../../../theme/echarts';

import ChartRefinementFiltersControls from '../ChartRefinementFiltersControls';
import Container from './Container';
import {
    CHARTS_NON_SUPPORTED_DATA_TYPES,
    TYPE_TREEMAP_CHART,
} from '@portal/common/models/chart-type';

const Wrapper = styled.div<{ isReverse: boolean }>(({ isReverse }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: isReverse ? 'column-reverse' : 'column',
}));

const NAMessage = styled(H4)({
    textAlign: 'center',
});

const mapStateToProps = (state: RootState) => ({
    entityHierarchies: state.entityHierarchies,
});

type Props = ReturnType<typeof mapStateToProps> & {
    initialTimeUnitValue: number | string;
    onDataLoad: () => void;
    selectedConditionsDataTypes: DataType[];
    selectedConditionsDataTypesGranularity: DataGranularity;

    isLoadingDataExplorerData: boolean;
    isLoadingChartData: boolean;
    chartFilters: DataFilters;
    filtersValues: DataFilters; // @todo: rename to 'selectedRefinementFilters', for example
    chartDataResponsesByType: null | Record<string, DataResponse>;
    timeUnitKey: TimeUnitKey;

    selectedDataTool: string;
    selectedDataCollection: DataCollectionResource;
    dataType: DataType;
    onExportData?: (format: DataExportFormat) => void;
    enableExportPNG: boolean;
    enableExportGIF: boolean;

    setCustomChartSettings: (value: any) => void;
    isChartPoppedOut: boolean;
    toggleChartPoppedOut: () => void;
    selectedConditionEntity: Entity | null;
    numberFormatter: (value: number | string) => string;
};

type State = {
    detailLevel: number;
};

class TreemapChart extends React.PureComponent<Props, State> {
    state: State = { detailLevel: 3 };

    preprocessFiltersChange = (filters: DataFilters) => {
        const { chartFilters, selectedConditionsDataTypes } = this.props;

        const combinedFilters = { ...chartFilters, ...filters };

        const dataType = selectedConditionsDataTypes.find(
            (dataType) => !CHARTS_NON_SUPPORTED_DATA_TYPES[TYPE_TREEMAP_CHART].includes(dataType)
        );
        if (!!dataType) {
            combinedFilters[DATA_TYPE_KEY] = dataType;
        }

        return combinedFilters;
    };

    setDetailLevel = (value: number) => {
        this.setState({ detailLevel: value });
    };

    setConditionEntity = (value: Entity | null) => {
        this.props.setCustomChartSettings({ selectedConditionEntity: value });
    };

    isDataTypeSupported = (): boolean => {
        const { selectedConditionsDataTypes } = this.props;
        const filteredConditions = selectedConditionsDataTypes.find(
            (dataType) => !CHARTS_NON_SUPPORTED_DATA_TYPES[TYPE_TREEMAP_CHART].includes(dataType)
        );
        return !!filteredConditions;
    };

    render() {
        const {
            initialTimeUnitValue,
            entityHierarchies,
            selectedConditionsDataTypes,
            selectedConditionsDataTypesGranularity,
            isLoadingChartData,
            filtersValues,
            chartDataResponsesByType,
            timeUnitKey,
            selectedDataTool,
            selectedDataCollection,
            dataType,
            onExportData,
            enableExportPNG,
            enableExportGIF,
            isChartPoppedOut,
            toggleChartPoppedOut,
            selectedConditionEntity,
            numberFormatter,
        } = this.props;

        const { detailLevel } = this.state;

        if (!this.isDataTypeSupported()) {
            return <NAMessage>{_(locale.naTreemapChart)}</NAMessage>;
        }

        return (
            <Wrapper isReverse={isChartPoppedOut}>
                <ChartRefinementFiltersControls
                    preprocessFiltersChange={this.preprocessFiltersChange}
                    skipInitialization={isChartPoppedOut}
                >
                    {/* Temporary disabled by Alison's request */}
                    {/*<DetailLevelDropdown
                        filterValue={[this.state.detailLevel]}
                        onChange={(values) => this.setDetailLevel(values[0])}
                    />*/}
                </ChartRefinementFiltersControls>
                <Container
                    theme={theme}
                    initialTimeUnitValue={initialTimeUnitValue}
                    selectedConditionsDataTypes={selectedConditionsDataTypes}
                    selectedConditionsDataTypesGranularity={selectedConditionsDataTypesGranularity}
                    entityHierarchies={entityHierarchies}
                    isLoadingData={isLoadingChartData}
                    filtersValues={filtersValues}
                    dataResponses={chartDataResponsesByType}
                    timeUnitKey={timeUnitKey}
                    selectedDataTool={selectedDataTool}
                    selectedDataCollection={selectedDataCollection}
                    dataType={dataType}
                    onExportData={onExportData}
                    enableExportPNG={enableExportPNG}
                    enableExportGIF={enableExportGIF}
                    isChartPoppedOut={isChartPoppedOut}
                    toggleChartPoppedOut={toggleChartPoppedOut}
                    detailLevel={detailLevel}
                    // setDetailLevel={this.setDetailLevel}
                    selectedConditionEntity={selectedConditionEntity}
                    setConditionEntity={this.setConditionEntity}
                    numberFormatter={numberFormatter}
                />
            </Wrapper>
        );
    }
}

export default connect(mapStateToProps)(TreemapChart);
