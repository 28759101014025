import React from 'react';
import FileSaver from 'file-saver';
import { utils as xlsxUtils, write } from 'xlsx';

import { SvgIcon, Button, Tooltip } from '@portal/common/components';
import { styled } from '@portal/common/theme';

import config from '../../config';
import _ from '../../locale';

const StyledIcon = styled(SvgIcon)(({ theme, disabled }) => ({
    marginRight: 10,
    ...(disabled && {
        pointerEvents: 'none',
        color: theme.color.disabled,
        fill: theme.color.disabled,
        cursor: 'default',
    }),
}));

const StyledButton = styled(Button)(({ theme, disabled }) => ({
    minWidth: 'auto',
    background: theme.color.tertiary1,
    ':hover': {
        color: theme.color.green1,
        background: theme.color.tertiary1,
    },
    ':active': {
        color: theme.color.green1,
        background: theme.color.tertiary1,
    },
    ...(disabled && {
        pointerEvents: 'none',
        color: theme.color.disabled,
        cursor: 'default',
    }),
}));

type Props = {
    getData: () => {
        headers: string[];
        records: string[][];
    };
    filename: string;
    disabled?: boolean;
    onClick?: () => {};
    dataRecordsAmount?: number;
};

class ExportExcelButton extends React.Component<Props> {
    onSave = () => {
        const { filename, getData, onClick } = this.props;
        const { headers, records } = getData();

        const worksheet = xlsxUtils.aoa_to_sheet([headers, ...records]);

        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet);

        const wopts = { bookType: 'xlsx', bookSST: false, type: 'array' };
        const wbout = write(workbook, wopts);

        const file = new Blob([wbout], { type: 'application/octet-stream' });

        FileSaver.saveAs(file, `${filename}.xlsx`);
        onClick && onClick();
    };

    render() {
        const { disabled, dataRecordsAmount } = this.props;

        const canHandleExport =
            dataRecordsAmount && dataRecordsAmount < config.ExcelRowsExportLimit;

        const buttonComponent = (
            <StyledButton
                color="tertiary"
                disabled={disabled || !canHandleExport}
                onClick={this.onSave}
            >
                <StyledIcon
                    color={(theme) => theme.color.green1}
                    src="/icons/ic-excel.svg"
                    disabled={disabled || !canHandleExport}
                    size="large"
                />{' '}
                {_('export_excel_button')}
            </StyledButton>
        );

        return canHandleExport ? (
            buttonComponent
        ) : (
            <Tooltip placement="bottom" title={_('export_excel_button_disabled_tooltip')}>
                {buttonComponent}
            </Tooltip>
        );
    }
}

export default ExportExcelButton;
