import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { RootState } from 'MyTypes';
import { LinkContainer } from 'react-router-bootstrap';

import { H3, SideMenuLink, Spinner, Tooltip, SvgIcon } from '@portal/common/components';
import { styled } from '@portal/common/theme';

import {
    getPublicLists,
    getCustomLists,
    getFavoriteResources,
    getGroupContentResources,
    getPermalinkResources,
} from '../../store/resource-lists/selectors';

import {
    FAVORITE_CONTENT_PATH,
    GROUP_CONTENT_PATH,
    MY_FAVORITIES_PATH,
    MY_LISTS_PATH,
    MY_PERMALINK_PATH,
    PUBLIC_LISTS_PATH,
} from '../../router/paths';

import _ from '../../locale';

import EditResourceListModal from '../EditResourceListModal';
import { getSessionAccountId } from '../../store/root-reducer';

const StyledSpinner = styled(Spinner)(({ theme }) => ({
    marginLeft: 10,
}));

const SidenavNavLink = styled(SideMenuLink)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 'auto',
    color: theme.color.gray40,
    ':hover, :active, &.active': {
        color: theme.color.green2,
        svg: {
            color: theme.color.green2,
        },
    },
}));

const SidenavSubmenu = styled.div(({ theme }) => ({
    color: theme.color.white,
    background: theme.color.gray95,
    display: 'flex',
    flexFlow: 'column nowrap',
    padding: '60px 20px 0 20px',
    overflowY: 'hidden',
    ':hover': {
        overflowY: 'scroll',
    },

    minWidth: 160,
    width: 160,
    [theme.breakpoint.md]: {
        padding: '90px 30px 0 30px',
        minWidth: 230,
        width: 230,
    },
}));

const SidenavSubmenuHeader = styled.div(({ theme }) => ({
    color: theme.color.white,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    padding: '40px 0 16px',
}));

const MyListsSubmenuHeader = styled(SidenavSubmenuHeader)(({ theme }) => ({
    padding: '8px 0 16px',
}));

const SidenavSubmenuList = styled.div(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column nowrap',
    '> *': {
        flexShrink: 0,
    },
}));

const mapStateToProps = (state: RootState) => ({
    favoriteResources: getFavoriteResources(state),
    permalinkResources: getPermalinkResources(state),
    groupContentResources: getGroupContentResources(state),
    publicLists: getPublicLists(state),
    myLists: getCustomLists(state),
    memberId: getSessionAccountId(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const dispatchProps = {};

class ListsRoute extends React.PureComponent<Props> {
    renderListItem = ({
        name,
        count,
        is_public,
        creator,
    }: {
        name: string;
        count: number;
        is_public?: boolean;
        creator?: { id: number };
    }) => {
        const icon =
            is_public && creator && creator.id !== this.props.memberId ? (
                <SvgIcon
                    size="medium"
                    src="/icons/ic-team.svg"
                    style={{ marginRight: 10, verticalAlign: 'top' }}
                />
            ) : null;

        return (
            <SidenavNavLink>
                <div>
                    {icon}
                    {name}
                </div>
                <div>{count}</div>
            </SidenavNavLink>
        );
    };

    renderMyListsMenu = () => {
        const { myLists, favoriteResources, permalinkResources } = this.props;
        const favoriteResourcesCount = favoriteResources && favoriteResources.length;
        const permalinkResourcesCount = permalinkResources && permalinkResources.length;

        return (
            <>
                <MyListsSubmenuHeader>
                    <H3>{_.get('lists_my_lists_header')}</H3>
                    {myLists == null && <StyledSpinner />}
                </MyListsSubmenuHeader>
                <SidenavSubmenuList>
                    <LinkContainer to={MY_FAVORITIES_PATH}>
                        {this.renderListItem({
                            name: _.get('default_list_name'),
                            count: favoriteResourcesCount,
                        })}
                    </LinkContainer>
                    <LinkContainer to={MY_PERMALINK_PATH}>
                        {this.renderListItem({
                            name: _.get('share_permalink_nav_link_label'),
                            count: permalinkResourcesCount,
                        })}
                    </LinkContainer>
                    {myLists != null &&
                        myLists.map((list) => (
                            <Tooltip key={`tooltip-${list.id}`} title={list.description}>
                                <div>
                                    <LinkContainer
                                        key={list.id}
                                        to={FAVORITE_CONTENT_PATH + MY_LISTS_PATH + `/${list.id}`}
                                    >
                                        {this.renderListItem({
                                            name: list.name,
                                            count: list.resource_ids ? list.resource_ids.length : 0,
                                            is_public: list.is_public,
                                            creator: list.creator,
                                        })}
                                    </LinkContainer>
                                </div>
                            </Tooltip>
                        ))}
                    <EditResourceListModal>
                        {({ openModal }) => (
                            <SideMenuLink onClick={openModal}>
                                {_.get('lists_add_new')}
                            </SideMenuLink>
                        )}
                    </EditResourceListModal>
                </SidenavSubmenuList>
            </>
        );
    };

    renderPublicListsMenu = () => {
        const { publicLists, groupContentResources } = this.props;

        const groupContentCount = groupContentResources && groupContentResources.length;

        return (
            <>
                <SidenavSubmenuHeader>
                    <H3>{_.get('lists_public_lists_header')}</H3>
                    {publicLists == null && <StyledSpinner />}
                </SidenavSubmenuHeader>
                <SidenavSubmenuList>
                    <LinkContainer to={GROUP_CONTENT_PATH}>
                        {this.renderListItem({
                            name: _.get('default_group_list_name'),
                            count: groupContentCount,
                        })}
                    </LinkContainer>
                    {publicLists != null &&
                        publicLists.map((list) => (
                            <Tooltip key={`tooltip-${list.id}`} title={list.description}>
                                <div>
                                    <LinkContainer
                                        key={list.id}
                                        to={
                                            FAVORITE_CONTENT_PATH +
                                            PUBLIC_LISTS_PATH +
                                            `/${list.id}`
                                        }
                                    >
                                        {this.renderListItem({
                                            name: list.name,
                                            count: list.resource_ids ? list.resource_ids.length : 0,
                                        })}
                                    </LinkContainer>
                                </div>
                            </Tooltip>
                        ))}
                </SidenavSubmenuList>
            </>
        );
    };

    render() {
        return (
            <Switch>
                <Route path={FAVORITE_CONTENT_PATH}>
                    <SidenavSubmenu>
                        {this.renderMyListsMenu()}
                        {this.renderPublicListsMenu()}
                    </SidenavSubmenu>
                </Route>
            </Switch>
        );
    }
}

export default compose(withRouter, connect(mapStateToProps, dispatchProps))(ListsRoute);
