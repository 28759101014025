import React from 'react';
import { useSelector } from 'react-redux';

import { LOCATION_ID_KEY } from '@portal/common/models/data-key';
import { BodyText2, H5, SectionBody } from '@portal/common/components';
import { styled } from '@portal/common/theme';

import { getSelectedRefinementFilters } from '../../../store/data-explorer/selectors';

import _ from '../../../locale';

import Header from '../components/Header';

const StyledH5 = styled(H5)(({ theme }) => ({
    marginTop: 30,
}));

const ExecutiveSummarySection = () => {
    const selectedRefinementFilters = useSelector(getSelectedRefinementFilters);
    if (!selectedRefinementFilters?.[LOCATION_ID_KEY]?.length) {
        return null;
    }

    const locationId = parseInt(selectedRefinementFilters?.[LOCATION_ID_KEY]?.[0]);

    return (
        <SectionBody isCollapsible>
            <Header>{_('simulation_tool_0_header')}</Header>
            <BodyText2>
                {_('simulation_tool_0_text')(
                    StyledH5,
                    _(`simulation_tool_0_dynamic_text_${locationId}`)
                )}
            </BodyText2>
        </SectionBody>
    );
};

export default ExecutiveSummarySection;
