import React from 'react';

import { ChartType } from '@portal/common/types';
import { styled } from '@portal/common/theme';

import ChartTypeButton from './ChartTypeButton';

const Container = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    marginBottom: -30,
    '> *': { flexGrow: 1 },
    '> ': { flexGrow: 1 },
    '> :first-child': {
        borderTopLeftRadius: theme.borderRadius.base,
    },
    '> :last-child': {
        borderTopRightRadius: theme.borderRadius.base,
    },
    '::after': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        content: '""',
        boxShadow: 'inset 0 -4px 10px 0 rgba(0, 0, 0, 0.06)',
        pointerEvents: 'none',
    },
}));

type TabConfig = {
    value: any;
    icon?: string;
    isDisabled: boolean;
};

type Props = {
    config: TabConfig[];
    mapValueToLabel?: (value: string) => string;
    onSelect?: (value: any) => void;
    value?: ChartType;
};

class ChartTypeSelector extends React.PureComponent<Props> {
    static defaultProps = {
        isDisabled: false,
    };

    componentDidMount(): void {
        const { value: selectedChartType, config } = this.props;
        const options = config.map(({ value }) => value);
        if (!options.includes(selectedChartType)) {
            this.handleSelect(options[0]);
        }
    }

    handleSelect = (value: any) => {
        const { onSelect } = this.props;

        if (onSelect) {
            onSelect(value);
        }
    };

    render() {
        const { config, mapValueToLabel, value: selectedValue } = this.props;
        if (!(config && config.length > 1)) {
            return null;
        }

        return (
            <Container>
                {config.map(({ value, icon, isDisabled }) => (
                    <ChartTypeButton
                        key={value.toString()}
                        value={value}
                        label={mapValueToLabel ? mapValueToLabel(value) : value.toString()}
                        icon={icon}
                        selected={value === selectedValue}
                        disabled={isDisabled}
                        handleClick={this.handleSelect}
                    />
                ))}
            </Container>
        );
    }
}

export default ChartTypeSelector;
