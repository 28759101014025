import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
    AlertModal,
    CaptionText3,
    ConfirmationModal,
    FlexColumn,
    FlexRow,
    H3,
    SectionBody,
    SectionHeader,
} from '@portal/common/components';
import { RefinementFilterConfig } from '@portal/common/types';
import { styled } from '@portal/common/theme';

import DataCollectionSelector from '../../components/DataExplorer/DataCollectionSelector';
import _ from '../../locale';
import {
    cancelSelectionCountModal,
    confirmSelectionCountModal,
    dataSelectionValidation,
} from '../../store/data-explorer/actions';
import {
    getCombinedFiltersAmount,
    getDataToolConfig,
    getSelectedConditions,
    getSelectedDataType,
    getSelectedRefinementFiltersWithFallbackToDefaultFilters,
    hasErrorInRefinements,
    hasFiltersChangedSinceLastRequest,
    isDataLoading,
} from '../../store/data-explorer/selectors';
import { LimitModal } from '../../models/data-tool';
import { getComorbidityTabKeyByDataType } from './comorbidity-type-group';

import RunQueryButton from '../../components/RunQueryButton';
import ComorbidityConditionSelector from './ComorbidityConditionSelector';
import ComorbidityFiltersControls from './ComorbidityFiltersControls';
import ToggleChartsVisibilityButton from '../../components/ToggleChartsVisibilityButton';

const StyledSectionBody = styled(SectionBody)(({ theme }) => ({
    marginTop: 0,
}));

const mapStateToProps = (state: RootState) => ({
    visibleModal: state.dataExplorer.activeSelectionCountModal,
    dataToolConfig: getDataToolConfig(state),
    selectedConditions: getSelectedConditions(state),
    selectedRefinementFilters: getSelectedRefinementFiltersWithFallbackToDefaultFilters(state),
    errorInRefinements: hasErrorInRefinements(state),
    isLoadingData: isDataLoading(state),
    filtersChangedSinceLastRequest: hasFiltersChangedSinceLastRequest(state),
    dataType: getSelectedDataType(state),
    combinedFiltersAmount: getCombinedFiltersAmount(state),
});

const dispatchProps = {
    cancelSelectionCountModal: cancelSelectionCountModal,
    confirmSelectionCountModal: confirmSelectionCountModal,
    queryDataValidationRequest: dataSelectionValidation.request,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & {
        refinementFiltersConfig?: RefinementFilterConfig;
        areChartsVisible: boolean;
        toggleChartsVisibility: () => void;
    };

type State = {
    activeRefinements: Array<string>;
};

class ComorbiditySelectDataSection extends React.Component<Props, State> {
    render() {
        const {
            cancelSelectionCountModal,
            confirmSelectionCountModal,
            visibleModal,
            dataToolConfig,
            refinementFiltersConfig,
            selectedConditions,
            queryDataValidationRequest,
            selectedRefinementFilters,
            errorInRefinements,
            isLoadingData,
            filtersChangedSinceLastRequest,
            dataType,
            combinedFiltersAmount,
            toggleChartsVisibility,
            areChartsVisible,
        } = this.props;

        const isDataCollectionSelectorVisible = dataToolConfig?.dataCollectionSelectorVisibility;
        const currentTabKey = getComorbidityTabKeyByDataType(dataType);

        return (
            <StyledSectionBody>
                <SectionHeader>
                    <H3>{_(`comorbidity_${currentTabKey}_tab_select_data`)}</H3>
                </SectionHeader>
                <FlexColumn itemsSpacing={30} grow={1}>
                    <CaptionText3>{_(`comorbidity_${currentTabKey}_tab_tooltip`)}</CaptionText3>
                    {isDataCollectionSelectorVisible && <DataCollectionSelector />}
                    <ComorbidityConditionSelector />
                    <ComorbidityFiltersControls refinementFiltersConfig={refinementFiltersConfig} />
                    {!!selectedConditions.length && (
                        <FlexRow
                            align="center"
                            style={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <ToggleChartsVisibilityButton
                                {...{ areChartsVisible, toggleChartsVisibility }}
                            />
                            <RunQueryButton
                                isLoading={isLoadingData}
                                disabled={
                                    isLoadingData ||
                                    errorInRefinements ||
                                    !filtersChangedSinceLastRequest
                                }
                                onClick={() => {
                                    queryDataValidationRequest({
                                        conditions: selectedConditions,
                                        refinementFilters: selectedRefinementFilters,
                                    });
                                }}
                                filtersAmount={combinedFiltersAmount}
                            />
                        </FlexRow>
                    )}
                </FlexColumn>
                <ConfirmationModal
                    message={_.get('data_explorer_filters_amount_warning')}
                    submitLabel={_('ignore_and_continue_button')}
                    isVisible={visibleModal === LimitModal.slowPerformanceWarning}
                    onClose={cancelSelectionCountModal}
                    onSubmit={confirmSelectionCountModal}
                />
                <ConfirmationModal
                    message={_('data_explorer_filters_chart_limit_exceeded')}
                    submitLabel={_('disable_charts_and_continue_button')}
                    isVisible={visibleModal === LimitModal.chartsLimitExceeded}
                    onClose={cancelSelectionCountModal}
                    onSubmit={confirmSelectionCountModal}
                />
                <ConfirmationModal
                    message={_('data_explorer_filters_limit_exceeded')}
                    submitLabel={_('ok_button')}
                    isVisible={visibleModal === LimitModal.dataToolLimitExceeded}
                    onClose={cancelSelectionCountModal}
                    onSubmit={confirmSelectionCountModal}
                />
                <AlertModal
                    isVisible={visibleModal === LimitModal.chartsLimitReceded}
                    onClose={cancelSelectionCountModal}
                    message={_('data_explorer_charts_data_limit_receded')}
                    submitLabel={_('okay_button')}
                    onSubmit={cancelSelectionCountModal}
                />
            </StyledSectionBody>
        );
    }
}

export default compose(connect(mapStateToProps, dispatchProps))(ComorbiditySelectDataSection);
