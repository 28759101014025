import { RootState } from 'MyTypes';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Loading from '@ihme/common/components/Loading';

import { FormInput, Modal, ModalBody, ModalButton, ModalFooter } from '@portal/common/components';
import { DataExplorerSelectionResource } from '@portal/common/types';
import { filterValuesByGranularity } from '@portal/common/utility/filters-helpers';
import { parseApiError } from '@portal/common/utility/api-helpers';

import { getOrganizationId, getSessionAccountId } from '../../store/root-reducer';
import {
    addFavoriteResource,
    addResourceListResource,
    createResourceList,
} from '../../store/resource-lists/actions';
import { getSelectedConditionsDataTypesGranularity } from '../../store/data-explorer/selectors';

import api from '../../api';
import _ from '../../locale';
import { MY_FAVORITES_ID } from '../../router/paths';

import AddToListDropdown, { AddToListDropdownProps } from './';

const mapStateToProps = (state: RootState) => ({
    organizationId: getOrganizationId(state),
    memberId: getSessionAccountId(state),
    granularity: getSelectedConditionsDataTypesGranularity(state),
});

const dispatchProps = {
    addFavoriteResource: addFavoriteResource,
    addResourceListResource: addResourceListResource,
    createResourceList: createResourceList,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    AddToListDropdownProps<DataExplorerSelectionResource> & {
        item: DataExplorerSelectionResource;
    };

const AddToListDataExplorerDropdown = ({
    organizationId,
    memberId,
    item,
    customSelectedListsIds,
    onCustomListResourceClick,
    onFavoritesResourceClick: onFavoriteResourceClick,
    addFavoriteResource,
    addResourceListResource,
    createResourceList,
    granularity,
    ...props
}: Props) => {
    const [dialogType, setDialogType] = useState<{
        type: 'favorite' | 'customList';
        listId?: string;
        refreshCallback: () => void;
    } | null>(null);
    const [name, setName] = useState('');
    const [modalIsSubmitting, setModalIsSubmitting] = useState(false);
    const [errors, setErrors] = useState<object | null>(null);

    const openModal = (type, listId, refreshCallback) =>
        setDialogType({ type, listId, refreshCallback });

    const closeModal = () => {
        setModalIsSubmitting(false);
        setDialogType(null);
    };

    // @todo Rename two methods below to something better
    const handleCreateFavoriteResource = (item) =>
        api.organizationMemberFavoriteResource
            .createDataExplorerSelectionResourceAndAddItToFavorites({
                organizationId,
                memberId,
                item,
            })
            .then((res) => {
                addFavoriteResource(res);
                setErrors(null);
                closeModal();
            })
            .catch(handleError);

    const handleCreateCustomListResource = (item, listId) =>
        api.organizationResourceListResource
            .createDataExplorerSelectionResourceAndAddItToOrganizationResourceList({
                organizationId,
                listId,
                item,
            })
            .then((res) => {
                addResourceListResource({ listId: listId, resource: res });
                setErrors(null);
                closeModal();
            })
            .catch(handleError);

    const handleError = (err) => {
        setModalIsSubmitting(false);
        setErrors(parseApiError(err));
    };

    return (
        <>
            <AddToListDropdown
                disableSavingIndicator
                onFavoritesResourceClick={(item, refreshCallback) => {
                    if (
                        !customSelectedListsIds ||
                        !customSelectedListsIds.includes(MY_FAVORITES_ID)
                    ) {
                        openModal('favorite', undefined, refreshCallback);
                    }
                }}
                onCustomListResourceClick={(item, listId, refreshCallback) => {
                    if (!customSelectedListsIds || !customSelectedListsIds.includes(listId)) {
                        openModal('customList', listId, refreshCallback);
                    }
                }}
                onCreateCustomList={(name, refreshCallback) => {
                    return api.organizationResourceList
                        .createOrganizationResourceList({
                            organizationId,
                            item: { name },
                        })
                        .then((list) => {
                            createResourceList(list);
                            openModal('customList', list.id, refreshCallback);
                        });
                }}
                item={{ ...item, name }}
                customSelectedListsIds={customSelectedListsIds}
                {...props}
            />
            <Modal show={Boolean(dialogType)} onHide={closeModal}>
                <form
                    onSubmit={(ev) => {
                        const refinedItem = {
                            ...item,
                            name,
                            refinement_filters: filterValuesByGranularity(
                                item.refinement_filters,
                                granularity
                            ),
                        };

                        ev.preventDefault();
                        setModalIsSubmitting(true);
                        switch (dialogType!.type) {
                            case 'favorite':
                                handleCreateFavoriteResource(refinedItem)
                                    .then(dialogType!.refreshCallback)
                                    .then(() => {
                                        onFavoriteResourceClick &&
                                            onFavoriteResourceClick(item, () => {});
                                    });
                                break;
                            case 'customList':
                                handleCreateCustomListResource(refinedItem, dialogType!.listId)
                                    .then(dialogType!.refreshCallback)
                                    .then(() => {
                                        onCustomListResourceClick &&
                                            onCustomListResourceClick(
                                                item,
                                                dialogType!.listId!,
                                                () => {}
                                            );
                                    });
                                break;

                            default:
                                break;
                        }
                    }}
                >
                    <ModalBody style={{ height: 160 }}>
                        {modalIsSubmitting ? (
                            <Loading backdrop />
                        ) : (
                            <>
                                <p>{_.get('add_to_list_name_your_selection')}</p>
                                <FormInput
                                    data-testid="favorite-item-name-input"
                                    autoFocus
                                    error={errors && errors.name}
                                    value={name}
                                    onChange={(ev) => {
                                        setName(ev.target.value);
                                    }}
                                />
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <ModalButton
                            color={'secondary'}
                            onClick={closeModal}
                            type="button"
                            disabled={modalIsSubmitting}
                        >
                            {_.get('cancel_button')}
                        </ModalButton>
                        <ModalButton type="submit" disabled={modalIsSubmitting}>
                            {_.get('save_button')}
                        </ModalButton>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    );
};

export default compose(connect(mapStateToProps, dispatchProps))(AddToListDataExplorerDropdown);
