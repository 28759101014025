import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@portal/common/theme';
import {
    BodyText1,
    H2,
    Modal,
    ModalBody,
    ModalButton,
    ModalFooter,
    SvgIcon,
} from '@portal/common/components';
import _ from '../../../locale';

import { MY_PERMALINK_PATH } from '../../../router/paths';

type Props = {
    isVisible: boolean;
    onClose: Function;
};

const Title = styled(H2)(({ theme }) => ({
    marginTop: 30,
}));

const BodyText1Styled = styled(BodyText1)(({ theme }) => ({
    marginTop: 20,
    textAlign: 'center',
    fontWeight: theme.typography.fontWeight.base,
}));

const ModalBodyStyled = styled(ModalBody)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
}));

function CopiedPermalinkModal({ onClose, isVisible }: Props) {
    const handleClose = () => {
        onClose();
    };

    return (
        <Modal show={isVisible} onHide={onClose} onEnter={() => {}}>
            <ModalBodyStyled>
                <SvgIcon
                    width={60}
                    height={60}
                    src="/icons/ic-check-filled.svg"
                    color={(theme) => theme.color.white}
                />
                <Title>Copied to clipboard!</Title>
                <BodyText1Styled>
                    A copy of the Permalink can be found in the{' '}
                    <Link to={MY_PERMALINK_PATH}>Permalink list</Link>, <br /> in Favorite Content.
                </BodyText1Styled>
            </ModalBodyStyled>
            <ModalFooter>
                <ModalButton onClick={handleClose} type="submit">
                    {_.get('modal_done')}
                </ModalButton>
            </ModalFooter>
        </Modal>
    );
}

export default CopiedPermalinkModal;
