import React, { FC } from 'react';

import DataCollectionDropdown from '../../components/DataCollectionDropdown';

type Props = {};

const DataCollectionSelector: FC<Props> = ({}) => (
    <DataCollectionDropdown style={{ flexGrow: 1 }} />
);

export default DataCollectionSelector;
