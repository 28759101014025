import { RootEpic } from 'MyTypes';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
    ReiTypeEtiology,
    ReiTypeImpairment,
    ReiTypeInjury,
    ReiTypeRisk,
} from '@portal/common/models/rei-types';

import {
    getCauseIcdCodesAsync,
    getCausesAsync,
    getEtiologiesAsync,
    getImpairmentsAsync,
    getInjuryAsync,
    getLocationsAsync,
    getRisksAsync,
    getSequelasAsync,
    getVehssAsync,
} from './actions';
import config from '../../config';

export const loadLocations: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getLocationsAsync.request])),
        switchMap((action) =>
            from(api.getLocations(undefined)).pipe(
                map((res) => {
                    const modifiedRes = res.map((location) => {
                        // Workaround #1: Rewrite location hierarchy for China
                        if (location.level3_parent_id === config.chinaLocationId) {
                            return {
                                ...location,
                                level: 4,
                                level4_parent_id: 0,
                            };
                        }

                        // Workaround #2: Rewrite location hierarchy for Australia and New Zealand
                        if (
                            [config.australiaLocationId, config.newZealandLocationId].includes(
                                location.id
                            )
                        ) {
                            return {
                                ...location,
                                level1_parent_id: config.southeastAsiaEastAsiaAndOceaniaLocationId,
                                level2_parent_id: config.oceaniaLocationId,
                            };
                        }

                        return location;
                    });

                    return getLocationsAsync.success(modifiedRes);
                }),
                catchError((message: string) => of(getLocationsAsync.failure(message)))
            )
        )
    );

export const loadCauses: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getCausesAsync.request])),
        switchMap((action) =>
            from(api.data.getCauses()).pipe(
                map(getCausesAsync.success),
                catchError((message: string) => of(getCausesAsync.failure(message)))
            )
        )
    );

export const requestCauseIcdCodes: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getCausesAsync.success])),
        map((action) => getCauseIcdCodesAsync.request())
    );

export const loadCauseIcdCodes: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getCauseIcdCodesAsync.request])),
        switchMap((action) =>
            from(api.data.getCauseIcdCodes()).pipe(
                map(getCauseIcdCodesAsync.success),
                catchError((message: string) => of(getCauseIcdCodesAsync.failure(message)))
            )
        )
    );

export const loadSequelas: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getSequelasAsync.request])),
        switchMap((action) =>
            from(api.data.getSequelas()).pipe(
                map((res) => getSequelasAsync.success(res)),
                catchError((message: string) => of(getSequelasAsync.failure(message)))
            )
        )
    );

export const loadRisks: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getRisksAsync.request])),
        switchMap((action) =>
            from(api.data.getRei(ReiTypeRisk)).pipe(
                map(getRisksAsync.success),
                catchError((message: string) => of(getRisksAsync.failure(message)))
            )
        )
    );

export const loadEtiology: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getEtiologiesAsync.request])),
        switchMap((action) =>
            from(api.data.getRei(ReiTypeEtiology)).pipe(
                map(getEtiologiesAsync.success),
                catchError((message: string) => of(getEtiologiesAsync.failure(message)))
            )
        )
    );

export const loadImpairment: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getImpairmentsAsync.request])),
        switchMap((action) =>
            from(api.data.getRei(ReiTypeImpairment)).pipe(
                map(getImpairmentsAsync.success),
                catchError((message: string) => of(getImpairmentsAsync.failure(message)))
            )
        )
    );

export const loadInjury: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getInjuryAsync.request])),
        switchMap((action) =>
            from(api.data.getRei(ReiTypeInjury)).pipe(
                map(getInjuryAsync.success),
                catchError((message: string) => of(getInjuryAsync.failure(message)))
            )
        )
    );

export const loadVehss: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getVehssAsync.request])),
        switchMap((action) =>
            from(api.data.getVehss()).pipe(
                map(getVehssAsync.success),
                catchError((message: string) => of(getVehssAsync.failure(message)))
            )
        )
    );

/*
export const loadDatasetLocations: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getDatasetLocationsAsync.request])),
        switchMap((action) => {
            const state = state$.value;

            const organizationId = getOrganizationId(state);
            const resourceId = getSelectedDataCollectionForDataTool(state)?.id;
            const dataType = getSelectedConditionsDataTypes(state)[0];
            const granularityKey = LOCATION_ID_KEY;

            return from(
                api.data.getDatasetGranularityKeyMetadata(
                    organizationId,
                    resourceId,
                    dataType,
                    granularityKey
                )
            ).pipe(
                map((res) =>
                    getDatasetLocationsAsync.success({
                        resourceId,
                        dataType,
                        hierarchy: res[granularityKey],
                    })
                ),
                catchError((message: string) => of(getDatasetLocationsAsync.failure(message)))
            );
        })
    );
*/
