import FileSaver from 'file-saver';
import { RootState } from 'MyTypes';
import React from 'react';
import Icon from 'react-icons-kit';
import { cancelCircle } from 'react-icons-kit/icomoon/cancelCircle';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import cuid from 'cuid';

import _ from '@portal/common/locale';
import {
    AsyncRender,
    ConfirmationModal,
    DataTableWithQueryParams,
    ProgressBar,
    SvgIcon,
    TableCell,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
    TableText,
    Tooltip,
    AlertModal,
    ActionIcon,
    ActionClose,
} from '@portal/common/components';
import {
    DataExplorerSelectionResource,
    DataTableQueryParams,
    PaginatedRequest,
    PaginatedResponse,
    Resource,
} from '@portal/common/types';
import { AllDataTypes } from '@portal/common/models/data-type';
import { styled } from '@portal/common/theme';
import { getOrganizationFileDownloadUrl } from '@portal/common/utility/api-helpers';
import formatDateTime from '@portal/common/utility/formatting/formatDateTime';
import { TYPE_LINE_CHART } from '@portal/common/models/chart-type';
import getOrderDirection from '@portal/common/utility/get-order-direction';

import api from '../../api';
import { DATA_EXPLORER_PATH } from '../../router/paths';
import { downloadFileAsync } from '../../store/data-explorer/actions';
import { getDataCollections } from '../../store/data-explorer/selectors';
import { getOrganizationId } from '../../store/root-reducer';
import { isAtLeastOneConditionPresentInDataCollection } from '../../models/data-condition';
import locale from './locale';

const ContentNoLongerAvailableModal = 'ContentNoLongerAvailableModal';

const HeaderContent = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
});

const IconTableHeaderCell = styled(TableHeaderCell)({
    width: 50,
    padding: 0,
});

const StyledTableRow = styled(TableRow)(({ theme, inProgress }) => ({
    ...(inProgress && {
        border: 'none',
    }),
}));

const EmptyRow = styled(StyledTableRow)({
    height: 0,
    padding: 0,
    margin: 0,
});

const ResourceIcon = styled(SvgIcon)(({ theme }) => ({
    paddingTop: 4,
    color: theme.color.gray95,
}));

const IconTableCell = styled(TableCell)(({ theme }) => ({
    padding: '4px 0 0 30px',
    width: 50,
}));

const CancelIcon = styled(Icon)(({ theme }) => ({
    color: theme.color.danger1,
    cursor: 'pointer',
    marginLeft: -4,
}));

const ProgressBarContainer = styled(TableCell)({
    padding: 0,
});

const ErrorRow = styled(StyledTableRow)(({ theme }) => ({
    color: theme.color.red,
}));

const mapStateToProps = (state: RootState) => ({
    dataCollections: getDataCollections(state),
    organizationId: getOrganizationId(state),
});

const dispatchProps = {
    downloadFileAsyncRequest: downloadFileAsync.request,
    downloadFileAsyncSuccess: downloadFileAsync.success,
    downloadFileAsyncFailure: downloadFileAsync.failure,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    RouteComponentProps<{}> & {
        loadItems: <T = any>(queryParams: PaginatedRequest<T>) => Promise<PaginatedResponse<T>>;
        defaultQueryParams: DataTableQueryParams;
        queryParams: DataTableQueryParams;
        setQueryParams: (params: object) => void;
        onClick: (resource: Resource) => void;
        disableSorting?: boolean;
        hidePagination?: boolean;
        renderActionsCell?: <T = any>(item: T, renderProps) => React.ReactNode;
    };

type State = {
    activeModal: null | string;
    activeConfirmationModalIds: object;
};

class ResourceListView extends React.PureComponent<Props, State> {
    static defaultProps = {
        defaultQueryParams: {
            per_page: '10',
        },
        queryParams: {},
    };

    state: State = {
        activeModal: null,
        activeConfirmationModalIds: {},
    };

    activateModal = (itemId) =>
        this.setState((state) => ({
            activeConfirmationModalIds: {
                ...state.activeConfirmationModalIds,
                [itemId]: true,
            },
        }));

    deactivateModal = (itemId) =>
        this.setState((state) => ({
            activeConfirmationModalIds: {
                ...state.activeConfirmationModalIds,
                [itemId]: false,
            },
        }));

    closeModal = () =>
        this.setState({
            activeModal: null,
        });

    renderHeaders = (renderProps) => (
        <TableHeaderRow>
            <IconTableHeaderCell />
            {this.renderSortHeader(_(locale.name), 'name', renderProps, '40%')}
            {this.renderSortHeader(_(locale.category), 'category', renderProps)}
            {this.renderSortHeader(_(locale.added), 'created', renderProps)}
            <TableHeaderCell>{_(locale.actions)}</TableHeaderCell>
        </TableHeaderRow>
    );

    renderSortHeader = (
        columnName,
        sortKey,
        { params, setSortOrder, toggleSortDirection },
        width = 'auto'
    ) => (
        <TableHeaderCell style={{ width: width }}>
            {this.props.disableSorting ? (
                columnName
            ) : (
                <HeaderContent
                    onClick={() =>
                        params.order_by === sortKey
                            ? toggleSortDirection()
                            : setSortOrder(sortKey, 'ASC')
                    }
                >
                    {columnName}
                    {params.order_by === sortKey && (
                        <ActionIcon
                            size="large"
                            src={
                                params.order_direction === 'DESC'
                                    ? '/icons/ic-sort-desc.svg'
                                    : '/icons/ic-sort-asc.svg'
                            }
                        />
                    )}
                </HeaderContent>
            )}
        </TableHeaderCell>
    );

    renderCategoryCell = (item) => {
        const { name = '-', description } = item || {};

        return description ? (
            <Tooltip placement="top" title={description} disableFocusListener>
                <TableText>{name}</TableText>
            </Tooltip>
        ) : (
            <TableText>{name}</TableText>
        );
    };

    renderActionsCell = (item, renderProps) => {
        const { renderActionsCell } = this.props;

        if (renderActionsCell) {
            return renderActionsCell(item, renderProps);
        }
    };

    renderItem = (resource, renderProps) => {
        switch (resource.type) {
            case 'link':
                return this.renderLinkRow(resource, renderProps);
            case 'file':
                return this.renderFileRow(resource, renderProps);
            case 'data-explorer-selection':
                return this.renderDataExplorerSelectionRow(resource, renderProps);

            default:
                throw Error('Unknown resource type');
        }
    };

    renderLinkRow = (item, renderProps) => {
        const openLink = () => {
            this.props.onClick(item);
            window.open(item.url);
        };

        return (
            <StyledTableRow key={item.id}>
                <IconTableCell>
                    <ResourceIcon
                        src="/icons/ic-link.svg"
                        tooltip={_('tooltip_open_link')}
                        width={24}
                        height={24}
                        onClick={openLink}
                    />
                </IconTableCell>
                <TableCell>
                    <TableText type="bolder" style={{ cursor: 'pointer' }} onClick={openLink}>
                        {item.name}
                    </TableText>
                </TableCell>
                <TableCell>{this.renderCategoryCell(item.category)}</TableCell>
                <TableCell>
                    <TableText type="lighter">{formatDateTime(item.created)}</TableText>
                </TableCell>
                <TableCell>{this.renderActionsCell(item, renderProps)}</TableCell>
            </StyledTableRow>
        );
    };

    renderFileRow = (item, renderProps) => {
        const { organizationId, onClick } = this.props;

        const downloadId = cuid();

        const downloadFile = ({ inProgress, load, handleProgress, cancelController }) => {
            if (inProgress) {
                return;
            }
            onClick(item);
            load({
                url: getOrganizationFileDownloadUrl(organizationId, item.id),
                onProgress: handleProgress,
                cancelController,
            });
            this.props.downloadFileAsyncRequest({
                id: downloadId,
                resource_id: item.id,
            });
        };

        return (
            <AsyncRender
                key={item.id}
                onLoad={api.downloadFile}
                onSuccess={(file) => {
                    this.props.downloadFileAsyncSuccess({
                        id: downloadId,
                        resource_id: item.id,
                    });
                    FileSaver.saveAs(file.data, file.name);
                    // this.deactivateModal(item.id);
                }}
                onError={(err) => {
                    this.props.downloadFileAsyncFailure({
                        id: downloadId,
                        resource_id: item.id,
                    });
                    // this.deactivateModal(item.id);
                }}
                render={({
                    inProgress,
                    progressValue,
                    handleProgress,
                    cancel,
                    cancelController,
                    load,
                    error,
                    hideErrorMessage,
                }) => (
                    <React.Fragment>
                        <ConfirmationModal
                            title={_(locale.cancel_confirmation_title)}
                            message={_(locale.cancel_confirmation_question)}
                            isVisible={inProgress && this.state.activeConfirmationModalIds[item.id]}
                            onClose={() => this.deactivateModal(item.id)}
                            onSubmit={() => {
                                this.deactivateModal(item.id);
                                cancel();
                            }}
                        />
                        <StyledTableRow inProgress={inProgress}>
                            <React.Fragment>
                                <IconTableCell>
                                    {inProgress ? (
                                        <CancelIcon
                                            size={24}
                                            tooltip={_('tooltip_cancel')}
                                            icon={cancelCircle}
                                            onClick={() => this.activateModal(item.id)}
                                        />
                                    ) : (
                                        <ResourceIcon
                                            src="/icons/ic-download.svg"
                                            tooltip={_('tooltip_download')}
                                            width={24}
                                            height={24}
                                            onClick={() =>
                                                downloadFile({
                                                    inProgress,
                                                    load,
                                                    handleProgress,
                                                    cancelController,
                                                })
                                            }
                                        />
                                    )}
                                </IconTableCell>
                                <TableCell>
                                    <TableText
                                        type="bolder"
                                        style={{ cursor: inProgress ? 'default' : 'pointer' }}
                                        onClick={() =>
                                            downloadFile({
                                                inProgress,
                                                load,
                                                handleProgress,
                                                cancelController,
                                            })
                                        }
                                    >
                                        {item.name}
                                    </TableText>
                                </TableCell>
                                <TableCell>{this.renderCategoryCell(item.category)}</TableCell>
                                <TableCell>
                                    <TableText type="lighter">
                                        {formatDateTime(item.created)}
                                    </TableText>
                                </TableCell>
                                <TableCell>{this.renderActionsCell(item, renderProps)}</TableCell>
                            </React.Fragment>
                        </StyledTableRow>
                        {error && (
                            <ErrorRow>
                                <TableCell colSpan={4}>{error.message}</TableCell>
                                <TableCell colSpan={1}>
                                    <ActionClose onClick={hideErrorMessage} />
                                </TableCell>
                            </ErrorRow>
                        )}
                        <EmptyRow />
                        <EmptyRow>
                            <ProgressBarContainer colSpan="5">
                                <ProgressBar
                                    now={Math.ceil(progressValue * 100)}
                                    label={`${Math.ceil(progressValue * 100)}%`}
                                    inProgress={inProgress}
                                />
                            </ProgressBarContainer>
                        </EmptyRow>
                    </React.Fragment>
                )}
            />
        );
    };

    renderDataExplorerSelectionRow = (item: DataExplorerSelectionResource, renderProps) => {
        const loadDataExplorerSelection = () => {
            const { dataCollections, history } = this.props;

            const { conditions, configuration, data_collection_resource_id, refinement_filters } =
                item;

            const dataCollection = dataCollections!.find(
                (i) => i.id === data_collection_resource_id
            );

            if (
                !(
                    dataCollection != null &&
                    isAtLeastOneConditionPresentInDataCollection(conditions, dataCollection)
                )
            ) {
                this.setState({ activeModal: ContentNoLongerAvailableModal });
                return;
            }

            const {
                activeRefinements,
                chartType = TYPE_LINE_CHART,
                dataType,
                sortedByColumn,
                sortedDescending,
                chartSettings,
                visibleColumns,
                dataTool,
            } = configuration;

            // @todo: add reading and writing chart filters
            /*if (chartType === TYPE_MAP_CHART && chartSettings) {
                if (chartSettings.detailLevel) {
                    setDetailLevel(chartSettings.detailLevel);
                }
                if (chartSettings.type) {
                    setMapType(chartSettings.type);
                }
            }*/

            history.push({
                pathname: dataTool || DATA_EXPLORER_PATH,
                state: {
                    dataLoaded: true,
                    dataCollectionId: dataCollection.id,
                    dataType: dataType || AllDataTypes,
                    conditions,
                    configuration: {
                        sortedByColumn,
                        sortedDescending,
                        visibleColumns,
                        chartSettings,
                    },
                    refinementFilters: refinement_filters,
                    activeRefinements,
                    chartType,
                },
            });
        };

        const { category, created, name, id } = item;

        return (
            <StyledTableRow key={id}>
                <IconTableCell>
                    <ResourceIcon
                        src="/icons/ic-barchart.svg"
                        width={24}
                        height={24}
                        onClick={loadDataExplorerSelection}
                    />
                </IconTableCell>
                <TableCell>
                    <TableText
                        type="bolder"
                        style={{ cursor: 'pointer' }}
                        onClick={loadDataExplorerSelection}
                    >
                        {name}
                    </TableText>
                </TableCell>
                <TableCell>{this.renderCategoryCell(category)}</TableCell>
                <TableCell>
                    <TableText type="lighter">{formatDateTime(created)}</TableText>
                </TableCell>
                <TableCell>{this.renderActionsCell(item, renderProps)}</TableCell>
            </StyledTableRow>
        );
    };

    render() {
        const { queryParams, setQueryParams, loadItems, defaultQueryParams, hidePagination } =
            this.props;

        return (
            <DataTableWithQueryParams
                dataTableStyle={{ ...this.props.dataTableStyle }}
                queryParams={{
                    order_direction: getOrderDirection(
                        queryParams.order_by || defaultQueryParams.order_by
                    ),
                    ...queryParams,
                }}
                setQueryParams={setQueryParams}
                defaultQueryParams={defaultQueryParams}
                loadItems={loadItems}
                renderHeaders={this.renderHeaders}
                renderItem={this.renderItem}
                hidePagination={hidePagination}
                renderFooter={({ loadItems }) => (
                    <React.Fragment>
                        <AlertModal
                            isVisible={this.state.activeModal === ContentNoLongerAvailableModal}
                            onClose={this.closeModal}
                            title={_.get('content_no_longer_available_title')}
                            message={_.get('content_no_longer_available_message')}
                            submitLabel="Okay"
                            onSubmit={() => {
                                this.closeModal();
                            }}
                        />
                    </React.Fragment>
                )}
            />
        );
    }
}

export default compose(withRouter, connect(mapStateToProps, dispatchProps))(ResourceListView);
