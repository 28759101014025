import { createAction } from 'typesafe-actions';
import LogRocket from 'logrocket';

import { OrganizationMember } from '@portal/common/types';
import {
    deleteCookie,
    IMPERSONATED_SESSION,
    REDIRECTION_SESSION,
} from '@portal/common/utility/cookies';

import * as types from './types';
import api from '../../api';
import { deleteImpersonation } from '../../services/localStorageService';

const processSession = ({ dispatch, session }) => {
    api.setSessionToken(session.token);
    dispatch({
        type: types.SESSION_SIGNED_IN,
        payload: session,
    });

    LogRocket.identify(session.account.id.toString(), {
        organization: session.account.organization.id,
        role: session.account.organization_role,
    });

    return session;
};

export const signIn = (email, password) => (dispatch) =>
    api.session.signIn(email, password).then((session) => processSession({ dispatch, session }));

export const signInWithSSO = (code, state) => async (dispatch) => {
    try {
        const session = await api.session.signInWithSSO(code, state);
        processSession({ dispatch, session });
    } catch (error) {
        dispatch({
            type: types.SESSION_ERROR,
            payload: { error: error.message },
        });
    }
};

export const signOut = ({ error } = {}) => {
    deleteImpersonation();
    deleteCookie(IMPERSONATED_SESSION);
    deleteCookie(REDIRECTION_SESSION);
    api.setSessionToken(null);

    return {
        type: types.SESSION_SIGNED_OUT,
        payload: { error },
    };
};

export const destroySession = signOut;

export const loadAccount = (organizationId, accountId) => (dispatch) =>
    api.organization.getOrganizationMember(organizationId, accountId).then((account) => {
        dispatch({
            type: types.SESSION_ACCOUNT_LOADED,
            payload: { account },
        });

        LogRocket.identify(account.id.toString(), {
            organization: account.organization.id,
            role: account.organization_role,
        });

        return account;
    });

export const updateAccount =
    (organizationId: number, accountId: number, data: Partial<OrganizationMember>) => (dispatch) =>
        api.organization.setOrganizationMember(organizationId, accountId, data).then((account) => {
            dispatch({
                type: types.SESSION_ACCOUNT_UPDATED,
                payload: account,
            });
            return account;
        });

export const changeAccountPassword = (accountId, password, newPassword) => (dispatch) =>
    api.account.setPassword(accountId, password, newPassword).then((account) => {
        dispatch({
            type: types.SESSION_ACCOUNT_PASSWORD_CHANGED,
            payload: account,
        });
        return account;
    });

export const setNotificationShown = createAction(
    types.SESSION_ACCOUNT_NOTIFICATION_SHOWN_CHANGED
)<boolean>();

export const resetSession = createAction(types.SESSION_RESET)<void>();
