import { flatten, forEach, groupBy, remove } from 'lodash';
import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { parseApiError } from '@ihme/common/api-request';
import { DropdownOption, Entity } from '@portal/common/types';
import { getLocalePrefixForGranularityKey } from '@portal/common/utility/filters-helpers';
import { OptionGetters } from '@portal/common/components/Dropdown';

import { validateSelectedRefinementFiltersComplexityLimit } from '../../store/data-explorer/actions';
import {
    getSelectedConditionsDataTypesGranularity,
    getSelectedConditionsRefinedGranularity,
} from '../../store/data-explorer/selectors';
import _ from '../../locale';
import ChartDropdown from '../ChartDropdown';

// changes 0, 0_0, 0_0_0... into readable ordered list prefix A, A.1, A.1.1...
const FIRST_PREFIX_LETTER = 0;

const levelToLetter = (level) => String.fromCharCode(65 + parseInt(level)).toUpperCase();

const levelToNumber = (level) => parseInt(level) + 1;

const formatHierarchyToLocalePrefix = (hierarchyLevelKey, showPrefix, useLetterPrefix) => {
    const levels = hierarchyLevelKey.split('_').slice(1);
    const padSize = levels.length * 3;
    const prefix = showPrefix
        ? levels
              .map((level, i, arr) =>
                  i === FIRST_PREFIX_LETTER && useLetterPrefix
                      ? levelToLetter(level)
                      : levelToNumber(level)
              )
              .join('.')
        : '';

    return ' '.repeat(padSize) + `${prefix} `;
};

const defaultProps = {
    limitHierarchyDepth: 4, // level 4 (subnational), level 5 (district), level 6 (county),
    rootLevel: 0,
    showPrefix: true,
    useLetterPrefix: true,
};

type OwnProps = {
    loadHierarchy: () => Promise<any>;
    limitHierarchyDepth?: number;
    rootLevel?: number;
    showPrefix?: boolean;
    useLetterPrefix?: boolean;
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
    granularity: ownProps.granularity || getSelectedConditionsDataTypesGranularity(state),
    refinedGranularity:
        ownProps.refinedGranularity || getSelectedConditionsRefinedGranularity(state),
});

const dispatchProps = {
    tryChangeSelectedRefinementFilters: validateSelectedRefinementFiltersComplexityLimit,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    React.ComponentProps<typeof ChartDropdown> &
    OwnProps;

type DropdownHierarchyItem = {
    value: number;
    label: string;
    disabled?: boolean;
    level: number;
    children: DropdownHierarchyItem[];
    location_type_id: number;
};

type State = {
    hierarchyItems: Entity[] | null;
};

// TODO: finish refactoring by moving parsing location labels with prefixes and level left-padding to selector
class ChartHierarchicalDropdown extends React.Component<Props, State> {
    static defaultProps = defaultProps;

    state: State = {
        hierarchyItems: this.loadInitialHierarchy(),
    };

    componentDidMount() {
        this.loadHierarchy();
    }

    componentDidUpdate(prevProps: Props): void {
        // sliders options are required to successfully parse hierarchy from api
        // this check make sure it's not empty
        const { refinedGranularity, filterKey } = this.props;
        if (
            prevProps.refinedGranularity &&
            refinedGranularity &&
            prevProps.refinedGranularity[filterKey] !== refinedGranularity[filterKey]
        ) {
            this.setState(({ hierarchyItems }) => ({
                hierarchyItems: this.recalculateDisabledStateInHierarchyItems(hierarchyItems),
            }));
        }
    }

    loadInitialHierarchy() {
        return null;
    }

    loadHierarchy() {
        const { granularity, filterKey } = this.props;
        if (granularity[filterKey] == null) return;

        this.props
            .loadHierarchy()
            .then((hierarchy) => {
                if (hierarchy == null) {
                    return;
                }
                const hierarchyItems = this.parseHierarchy(hierarchy);
                this.setState({
                    hierarchyItems: this.recalculateDisabledStateInHierarchyItems(hierarchyItems),
                });
            })
            .catch(this.handleError);
    }

    recalculateDisabledStateInHierarchyItems = (hierarchyItems) => {
        const { refinedGranularity, filterKey } = this.props;

        if (hierarchyItems == null) {
            return hierarchyItems;
        }

        // check if refinedGranularity exists to make items disabled
        const refinedGranularityKeys = refinedGranularity && refinedGranularity[filterKey];

        const markDisabled = (item) => {
            const disabled =
                !!refinedGranularityKeys && !refinedGranularityKeys.includes(Number(item.value));

            return {
                ...item,
                children: item.children && item.children.map(markDisabled),
                disabled,
            };
        };

        const newHierarchyItems = hierarchyItems.map(markDisabled);

        return newHierarchyItems;
    };

    /**
     * @todo This logic is duplicated in ConditionDropdown
     * Level upping items when parent level item doesn't exist
     */
    tuneLevels = (hierarchyRecords: { id: number }[], rootLevel: number) => {
        // console.log('Tuning levels');

        const availableItemIds = hierarchyRecords.map((item) => item.id);

        const hierarchyByLevels = groupBy(hierarchyRecords, 'level');

        const availableLevels = Object.keys(hierarchyByLevels).map((level) => parseInt(level));

        const minLevel = Math.min(...availableLevels);
        const maxLevel = Math.max(...availableLevels);

        // console.log('Min level is ' +  minLevel);
        // console.log('Max level is ' + maxLevel);

        const levelUpItem = (item, toLevel, toParentId) => {
            if (item.level > rootLevel) {
                remove(hierarchyByLevels[item.level], (i) => i.id === item.id);

                const levelUppedItem = {
                    ...item,
                    level: toLevel,
                    [`level${toLevel - 1}_parent_id`]: toParentId,
                };

                if (!hierarchyByLevels[toLevel]) {
                    hierarchyByLevels[toLevel] = [];
                }

                hierarchyByLevels[toLevel].push(levelUppedItem);
            }
        };

        const getParentId = (item, level) => item[`level${level}_parent_id`];

        const leveledUpItems = {};

        let item, parentId, expectedItemLevel, parentWasFound;

        for (let level = minLevel + 1; level <= maxLevel; ++level) {
            // console.log('    Checking level ' + level);
            const itemsAtCurrentLevel = hierarchyByLevels[level];

            if (itemsAtCurrentLevel) {
                for (let i = itemsAtCurrentLevel.length - 1; i >= 0; --i) {
                    if (itemsAtCurrentLevel[i]) {
                        item = itemsAtCurrentLevel[i];
                        // console.log('        Checking item ' + item.id);

                        // If no direct parent
                        parentId = getParentId(item, level - 1);
                        if (!availableItemIds.includes(parentId)) {
                            // console.log('            The item does not have direct parent');

                            // Find the next existing parent
                            parentWasFound = false;
                            for (
                                let parentLevel = level - 2;
                                parentLevel >= minLevel;
                                --parentLevel
                            ) {
                                parentId = getParentId(item, parentLevel);

                                // console.log('                Checking parent at level ' + parentLevel);
                                if (availableItemIds.includes(parentId)) {
                                    parentWasFound = true;

                                    // And level up just below it
                                    expectedItemLevel =
                                        (parentId in leveledUpItems
                                            ? leveledUpItems[parentId]
                                            : parentLevel) + 1;

                                    // console.log('                    Found parent ' + parentId + ' at level ' + parentLevel + ', leveling up ' + item.id + ' to level ' + expectedItemLevel);

                                    levelUpItem(item, expectedItemLevel, parentId);
                                    leveledUpItems[item.id] = expectedItemLevel;
                                    break;
                                }
                            }

                            // If no parents found then level up to the min level
                            if (!parentWasFound) {
                                // console.log('                No parent was found. Leveling up ' + item.id + ' to the min level ' + minLevel);

                                levelUpItem(item, minLevel, null);
                                leveledUpItems[item.id] = minLevel;
                            }
                        }
                        // If the direct parent was leveled up
                        else if (parentId in leveledUpItems) {
                            // console.log('            Direct parent is found but it was leveled up to level ' + leveledUpItems[parentId] + '. Leveling up ' + item.id + ' to the next level ' + (leveledUpItems[parentId] + 1));

                            levelUpItem(item, leveledUpItems[parentId] + 1, parentId);
                            leveledUpItems[item.id] = leveledUpItems[parentId] + 1;
                        }
                    }
                }
            }
        }

        // console.log('Finished tuning levels');
        return flatten(Object.values(hierarchyByLevels));
    };

    /**
     * @todo This logic is duplicated in ConditionDropdown
     */
    parseHierarchy(hierarchyRecords: object[]) {
        const {
            granularity,
            filterKey,
            showPrefix,
            useLetterPrefix,
            limitHierarchyDepth,
            rootLevel,
        } = this.props;

        const localePrefix = getLocalePrefixForGranularityKey(filterKey);

        if (hierarchyRecords == null) {
            return [];
        }

        // Filtering hierarchy items so it'll include only available granularity options
        const granularityValues = granularity[filterKey] || [];
        const filteredHierarchy = this.tuneLevels(
            hierarchyRecords.filter((item) => granularityValues.includes(item.id)),
            rootLevel
        );

        const hierarchyByLevels = groupBy(filteredHierarchy, 'level');
        const minLevel = Math.min(
            ...Object.keys(hierarchyByLevels).map((level) => parseInt(level))
        );

        const parseHierarchyItem = (item, level) => {
            let unparsedChildren = null;
            let children = null;
            if (level < limitHierarchyDepth || item[`level${level + 1}_parent_id`]) {
                const subLevelItems = hierarchyByLevels[level + 1];
                if (subLevelItems && subLevelItems.length) {
                    unparsedChildren = groupBy(
                        subLevelItems,
                        `level${level + rootLevel}_parent_id`
                    )[item.id];

                    if (unparsedChildren) {
                        children = unparsedChildren.map((item) =>
                            parseHierarchyItem(item, level + 1)
                        );
                    }
                }
            }

            return {
                ...item,
                children,
            };
        };

        const parsedItems = hierarchyByLevels[minLevel].map((levelData) =>
            parseHierarchyItem(levelData, minLevel)
        );

        const hierarchyItems = [] as DropdownHierarchyItem[];
        const format = (items, prefix = '') =>
            forEach(items, (item, index) => {
                const value = item.id;
                const label =
                    value && localePrefix ? _.get(`${localePrefix}${value}`) : value || '';

                hierarchyItems.push({
                    value,
                    label:
                        formatHierarchyToLocalePrefix(prefix + index, showPrefix, useLetterPrefix) +
                        label,
                    level: item.level,
                    children: item.children,
                    location_type_id: item.location_type_id,
                });

                if (item.children) {
                    format(item.children, prefix + index + '_');
                }
            });
        format(parsedItems);

        return hierarchyItems;
    }

    handleError = (error) => {
        this.setState({
            isLoading: false,
            errors: parseApiError(error),
        });
    };

    canUseLetterPrefix = (items) => {
        const { useLetterPrefix } = this.props;

        if (!useLetterPrefix) {
            return false;
        }

        const hierarchyItemsByLevels = Object.values(groupBy(items, 'level'));

        // @todo Unclear reference to the fact that the first level items come w/o prefixes
        return hierarchyItemsByLevels[FIRST_PREFIX_LETTER + 1]
            ? hierarchyItemsByLevels[FIRST_PREFIX_LETTER + 1].length <= 26
            : true;
    };

    handleRenderOption = (option: DropdownOption, optionGetters: OptionGetters) => {
        const {
            renderOption,
            filterValue,
            filterKey,
            tryChangeSelectedRefinementFilters,
            ...props
        } = this.props;
        const value = props.value !== undefined ? props.value : filterValue;
        const handleChange = (value) =>
            tryChangeSelectedRefinementFilters({
                [filterKey]: value,
            });

        return renderOption && renderOption(option, optionGetters, { value, handleChange });
    };

    render() {
        const {
            id,
            label,
            filterKey,
            filterValue,
            defaultValue,
            preprocessOptions,
            isMulti,
            limitHierarchyDepth,
            loadHierarchy,
            showPrefix,
            renderOption,
            refinedGranularity,
            ...props
        } = this.props;

        const { hierarchyItems } = this.state;
        if (hierarchyItems == null) {
            return null;
        }

        const options = hierarchyItems;
        const dropdownValue = filterValue == null ? [] : filterValue;

        const enabledOptions = refinedGranularity && refinedGranularity[filterKey];

        return (
            <ChartDropdown
                id={id}
                dataTestid={id}
                label={label}
                options={options}
                preprocessOptions={preprocessOptions}
                filterKey={filterKey}
                filterValue={dropdownValue}
                defaultValue={defaultValue}
                isMulti={isMulti}
                renderOption={renderOption && this.handleRenderOption}
                optionDisabledGetter={(o) => enabledOptions && !enabledOptions.includes(o.value)}
                {...props}
            />
        );
    }
}

export default compose(connect(mapStateToProps, dispatchProps))(ChartHierarchicalDropdown);
