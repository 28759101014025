import { init as initLocales, setLocale } from '@portal/common/locale';

import en from './en';

export const init = () => {
    initLocales({ en });
    setLocale('en');
};

// default export _
export { default } from './_';
