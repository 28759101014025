import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'MyTypes';
import { isEmpty, isEqual } from 'lodash/fp';

import { withStorage, WithStorageProps } from '@ihme/common/packages/storage';

import {
    LOCATION_ID_KEY,
    PRIMARY_ENTITY_ID_LOCALIZATION_KEY,
    PRIMARY_ENTITY_KEY,
    RACE_ID_KEY,
} from '@portal/common/models/data-key';
import {
    DataCondition,
    DataExportFormat,
    DataFilters,
    DataGranularity,
    DataGranularityKey,
    DataKey,
    DataResponse,
    DataType,
} from '@portal/common/types';
import { TYPE_ARROW_CHART } from '@portal/common/models/chart-type';

import { getSelectedDataTool } from '../../../../store/root-reducer';
import {
    getChartDimensionFilter,
    getDataToolConfig,
    getDrillIntoCondition,
    getRefinedCausesHierarchy,
    getSelectedDataProject,
    isArrowChartDrillable,
} from '../../../../store/data-explorer/selectors';
import {
    changeChartDimension,
    setDrillIntoCondition,
} from '../../../../store/data-explorer/actions';
import { ChartRefinementFilterConfig, getChartRefinementFiltersConfig } from '../utils';
import _ from '../../../../locale';

import ChartRefinementFiltersControls from '../ChartRefinementFiltersControls';
import ChartDimensionSelector from '../../../ChartDimensionSelector';
import Chart from './Chart';

const mapStateToProps = (state: RootState) => ({
    dataTool: getSelectedDataTool(state),
    dataToolConfig: getDataToolConfig(state),
    dataProject: getSelectedDataProject(state),
    refinedHierarchy: getRefinedCausesHierarchy(state),
    drillIntoEntity: getDrillIntoCondition(state),
    selectedYAxisDimension: getChartDimensionFilter(state),
    isDrillable: isArrowChartDrillable(state),
});

const dispatchProps = {
    changeChartDimension,
    setDrillIntoCondition,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    WithStorageProps & {
        onDataLoad?: () => void;
        selectedConditionsDataTypes: DataType[];
        selectedConditionsDataTypesGranularity: DataGranularity;
        selectedConditions: DataCondition[];
        mergedChartDataResponses: DataResponse;
        isLoadingDataExplorerData: boolean;
        isLoadingChartData: boolean;
        filtersValues: DataFilters;
        timeUnitKey: DataGranularityKey;
        onExportData?: (format: DataExportFormat) => void;
        enableExportPNG: boolean;
        numberFormatter: (value: number | string) => string;
    };

class ArrowChart extends React.PureComponent<Props> {
    chartRef = React.createRef();

    componentDidMount() {
        const { onDataLoad } = this.props;
        onDataLoad && onDataLoad();
        this.initialize();
    }

    componentDidUpdate(prevProps) {
        const { selectedConditionsDataTypes } = this.props;

        if (!isEqual(selectedConditionsDataTypes, prevProps.selectedConditionsDataTypes)) {
            this.initialize();
        }
    }

    initialize = () => {
        this.props.changeChartDimension(this.getInitialYAxisDimension());
    };

    getInitialYAxisDimension = (): DataKey => PRIMARY_ENTITY_KEY;

    getRefinementFiltersConfig = (): ChartRefinementFilterConfig[] => {
        const { selectedYAxisDimension } = this.props;

        const config = getChartRefinementFiltersConfig(TYPE_ARROW_CHART).filter(
            ({ key }) => selectedYAxisDimension !== key
        );

        return config;
    };

    getYAxisDimensionOptions = (): DataKey[] => {
        const { selectedConditionsDataTypesGranularity } = this.props;

        const allOptions = [PRIMARY_ENTITY_KEY, LOCATION_ID_KEY, RACE_ID_KEY];

        return allOptions.filter(
            (key) =>
                key === PRIMARY_ENTITY_KEY ||
                selectedConditionsDataTypesGranularity[key]?.length > 1
        );
    };

    getDataKey = () => {
        const { selectedYAxisDimension } = this.props;
        return selectedYAxisDimension === PRIMARY_ENTITY_KEY
            ? PRIMARY_ENTITY_ID_LOCALIZATION_KEY
            : selectedYAxisDimension;
    };

    getPathToSelectedNode = (): DataCondition[] => {
        const { refinedHierarchy, drillIntoEntity } = this.props;

        const path: DataCondition[] = [];
        if (isEmpty(refinedHierarchy) || !drillIntoEntity) {
            return path;
        }

        const dataType = drillIntoEntity.data_type;

        const isDrillFromLevel = !!drillIntoEntity?.level;
        if (isDrillFromLevel) {
            const rootElement = refinedHierarchy.find(({ level }) => level === 0);

            if (rootElement) {
                path.push({
                    data_type: dataType,
                    primary_entity_id: rootElement.id,
                });
            }
        } else {
            const drillIntoConditionHierarchyObj = refinedHierarchy.find(
                ({ id }) => id === drillIntoEntity.primary_entity_id
            );

            if (!drillIntoConditionHierarchyObj) {
                return path;
            }

            const { level } = drillIntoConditionHierarchyObj;

            for (let i = 0; i < level; i++) {
                path.push({
                    data_type: dataType,
                    primary_entity_id: drillIntoConditionHierarchyObj[`level${i}_parent_id`],
                });
            }
        }

        path.push(drillIntoEntity);
        return path;
    };

    getDrillableItemIds = (): number[] => {
        const { refinedHierarchy } = this.props;
        return refinedHierarchy
            .filter(({ id, level }) =>
                refinedHierarchy.find(
                    (entity) =>
                        entity[`level${level}_parent_id`] === id && entity.level === level + 1
                )
            )
            .map(({ id }) => id);
    };

    render() {
        const {
            selectedConditions,
            selectedConditionsDataTypes,
            selectedConditionsDataTypesGranularity,
            chartFilters,
            isLoadingDataExplorerData,
            isLoadingChartData,
            filtersValues,
            timeUnitKey,
            mergedChartDataResponses,
            dataTool,
            onExportData,
            numberFormatter,
            setDrillIntoCondition,
            isDrillable,
            drillIntoEntity,
        } = this.props;
        const dataKey = this.getDataKey();
        const refinementFiltersConfig = this.getRefinementFiltersConfig();

        return (
            <>
                <ChartRefinementFiltersControls config={refinementFiltersConfig} />
                <ChartDimensionSelector
                    label={_('filter_label_dimension')}
                    options={this.getYAxisDimensionOptions()}
                    mapValueToLabel={(value) => _(`arrow_chart_${value}_dimension`)}
                />
                <Chart
                    {...this.props}
                    ref={this.chartRef}
                    chartFilters={chartFilters}
                    dataTool={dataTool}
                    dataKey={dataKey}
                    selectedConditions={selectedConditions}
                    selectedConditionsDataTypes={selectedConditionsDataTypes}
                    selectedConditionsDataTypesGranularity={selectedConditionsDataTypesGranularity}
                    isLoadingData={isLoadingChartData || isLoadingDataExplorerData}
                    filtersValues={filtersValues}
                    timeUnitKey={timeUnitKey}
                    dataResponse={mergedChartDataResponses}
                    onExportData={onExportData}
                    numberFormatter={numberFormatter}
                    onConditionSelect={setDrillIntoCondition}
                    drillableItemIds={this.getDrillableItemIds()}
                    drillPath={this.getPathToSelectedNode()}
                    isDrillable={isDrillable}
                    drillIntoEntity={drillIntoEntity}
                />
            </>
        );
    }
}

export default compose(connect(mapStateToProps, dispatchProps), withStorage)(ArrowChart);
