import { isEmpty, maxBy, omit, pick, uniq } from 'lodash/fp';

import {
    DataCollectionResource,
    DataCondition,
    DataFilters,
    DataResponse,
    DataType,
} from '@portal/common/types';
import {
    AllDataTypes,
    CauseOutcome,
    dataTypeHasPrimaryEntity,
    getDataTypePrimaryEntityKey,
    SequelaOutcome,
} from '@portal/common/models/data-type';
import { MONTH_COUNT_KEY, ROUND_ID_KEY } from '@portal/common/models/data-key';
import {
    DATA_PROJECTS_WITH_ALL_DATA_TYPES_OPTION,
    DataProjectId,
} from '@portal/common/models/data-project';
import { mergeGranularity } from '@portal/common/utility/merge-granularity';

import api from '../api';
import config from '../config';

import { intersectRefinementFiltersWithGranularity } from '../components/DataExplorer/utils';

export const loadData =
    (
        organizationId: number,
        dataCollection: DataCollectionResource,
        conditions: DataCondition[],
        customFilters: DataFilters = {},
        customGranularity = null
    ) =>
    (filters: DataFilters) => {
        const dataTypes = conditions ? uniq(conditions.map((i) => i.data_type)) : null;

        if (!dataTypes) {
            return Promise.resolve(null);
        }

        const granularityByDataType = getDataCollectionGranularityByDataTypes(
            dataCollection,
            dataTypes
        );

        const mergedGranularity =
            customGranularity || getMergedDataCollectionGranularity(dataCollection, dataTypes);

        return Promise.all(
            (dataTypes || [])
                .map((dataType) => {
                    const dataTypeConditions = conditions.filter(
                        (condition: DataCondition) => condition.data_type === dataType
                    );

                    const granularity = { ...mergedGranularity };

                    Object.entries(granularityByDataType[dataType]).forEach(([key, value]) => {
                        if (isEmpty(value)) {
                            granularity[key] = [];
                        }
                    });

                    const granularityKeys = Object.keys(granularity);
                    const intersectedFilters = {
                        ...granularity,
                        ...intersectRefinementFiltersWithGranularity(
                            {
                                ...getFiltersWithConditions(
                                    dataTypeConditions,
                                    filters,
                                    granularityKeys
                                ),
                                ...customFilters,
                            },
                            granularity
                        ),
                    };

                    Object.entries(intersectedFilters).forEach(([key, value]) => {
                        if (!value?.length) {
                            delete intersectedFilters[key];
                        }
                    });

                    const getFiltersSelectionCount = (filters) =>
                        Object.entries(filters || {})
                            .map(([key, value]) => (value || []).length)
                            .reduce((total, num) => total * num, 1);

                    const getMaxFiltersSelectionCount = (filters: DataFilters[]): number => {
                        const counts = filters.map(getFiltersSelectionCount);
                        return counts.sort((a, b) => a - b)[counts.length - 1];
                    };

                    const splitArrayChunks = (value: T[], n: number): T[][] => {
                        const result: T[][] = [];

                        for (let i = 0; i < value.length; i += n) {
                            result.push(value.slice(i, i + n));
                        }

                        return result;
                    };

                    const limit = config.combinedFiltersAmountPerQueryLimit;

                    let newFilters = [{ ...intersectedFilters }];
                    while (getMaxFiltersSelectionCount(newFilters) > limit) {
                        const dividedFilters: DataFilters[] = [];

                        newFilters.forEach((filters) => {
                            const filtersSelectionCount = getFiltersSelectionCount(filters);
                            if (filtersSelectionCount > limit) {
                                const [maxValuesFilterKey, maxValuesFilterValue] = maxBy(
                                    ([key, value]) => value.length,
                                    Object.entries(filters)
                                );
                                const divideBy = Math.min(
                                    Math.ceil(
                                        (limit * maxValuesFilterValue.length) /
                                            filtersSelectionCount
                                    ) + 1,
                                    maxValuesFilterValue.length / 2
                                );
                                const splittedFilters = splitArrayChunks(
                                    maxValuesFilterValue,
                                    divideBy
                                ).map((filters) => ({
                                    ...omit(maxValuesFilterKey, intersectedFilters),
                                    [maxValuesFilterKey]: filters,
                                }));

                                dividedFilters.push(...splittedFilters);
                            } else {
                                dividedFilters.push(filters);
                            }
                        });

                        newFilters = dividedFilters;
                    }

                    return newFilters.map((filters) =>
                        api.data.queryDataCollectionResourceData(
                            organizationId,
                            dataCollection.id,
                            dataType,
                            filters
                        )
                    );
                })
                .flat()
        ).then((responses) => {
            const mergedResponses = {};

            responses.forEach((responseByDataType) => {
                const [dataType, response] = Object.entries(responseByDataType)[0];
                const { columns, records } = response as DataResponse;
                if (!mergedResponses[dataType]) {
                    mergedResponses[dataType] = { columns, records: [] };
                }
                mergedResponses[dataType].records =
                    mergedResponses[dataType].records.concat(records);
            });

            return mergedResponses;
        });
    };

export const getMergedDataCollectionGranularity = (
    dataCollection?: DataCollectionResource,
    dataTypes?: DataType[]
) => {
    if (!dataCollection) {
        return {};
    }

    if (!dataTypes || !dataTypes.length) {
        return {};
    }

    const dataSet = dataCollection.datasets.find(({ data_type }) => data_type === dataTypes[0]);
    if (!dataSet) {
        return {};
    }

    let granularity;
    if (dataTypes.includes(CauseOutcome) && dataTypes.includes(SequelaOutcome)) {
        // special case for causes and sequelas
        // cause and sequela granularity needs to be merged as union
        const causeGranularity = getGranularityByDataType(dataCollection, CauseOutcome);
        const sequelaGranularity = getGranularityByDataType(dataCollection, SequelaOutcome);

        granularity = mergeGranularity(causeGranularity, sequelaGranularity);
    } else {
        granularity = dataSet.granularity;
    }

    return granularity;
};

export const getDataCollectionGranularityByDataTypes = (
    dataCollection?: DataCollectionResource,
    dataTypes?: DataType[]
) => {
    let granularity = {};
    if (!(dataCollection && !isEmpty(dataTypes))) {
        return granularity;
    }

    (dataTypes || []).forEach((value) => {
        granularity[value] = dataCollection.datasets.find(
            ({ data_type }) => data_type === value
        )?.granularity;
    });

    return granularity;
};

const getGranularityByDataType = (
    selectedDataCollectionResource: DataCollectionResource,
    dataType: DataType
) => {
    if (!selectedDataCollectionResource) {
        return;
    }

    return selectedDataCollectionResource.datasets.find(({ data_type }) => data_type === dataType)!
        .granularity;
};

// it will add selected conditions to filters
export const getFiltersWithConditions = (
    selectedConditions: DataCondition[],
    filters: DataFilters,
    granularityKeys: string[]
): DataFilters => {
    const conditionFilters =
        selectedConditions.length === 0
            ? null
            : selectedConditions.reduce((filters, condition) => {
                  if (!dataTypeHasPrimaryEntity(condition.data_type)) {
                      return filters;
                  }
                  const key = getDataTypePrimaryEntityKey(condition.data_type);
                  const primaryEntityId = condition.primary_entity_id;
                  if (filters[key] == null) {
                      filters[key] = [];
                  }
                  filters[key].push(primaryEntityId);

                  return filters;
              }, {});

    return pickByGranularityKeys(
        {
            ...filters,
            ...conditionFilters,
        },
        granularityKeys
    );
};

export const pickByGranularityKeys = (
    filters: DataFilters,
    granularityKeys: string[]
): DataFilters => pick([...granularityKeys, ROUND_ID_KEY, MONTH_COUNT_KEY], filters);

export const getDataCollectionDataTypes = (dataCollection: DataCollectionResource): DataType[] => {
    const dataTypes = dataCollection.datasets.map((dataset) => dataset.data_type);
    const uniqueDataTypes = [...new Set(dataTypes)];
    if (DATA_PROJECTS_WITH_ALL_DATA_TYPES_OPTION.includes(dataCollection.data_project_id)) {
        uniqueDataTypes.unshift(AllDataTypes);
    }
    return uniqueDataTypes;
};

export function getDataCollectionDefaultDataType(dataCollection: DataCollectionResource) {
    const availableDataTypes = getDataCollectionDataTypes(dataCollection);

    if (dataCollection.data_project_id === DataProjectId.GBD) {
        return AllDataTypes;
    } else {
        return availableDataTypes[0];
    }
}
