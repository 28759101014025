import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { from, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { withGoogleAnalytics } from '@ihme/common/packages/google-analytics';
import {
    Button,
    ConfirmationModal,
    FlexRow,
    FlexColumn,
    HiddenText,
    Loading,
    Table,
    TableEmptyText,
    TableHead,
    TableHeaderRow,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell,
    Toggle,
} from '@portal/common/components';
import { RoleManager } from '@portal/common/models/organization-roles';
import { styled } from '@portal/common/theme';

import api from '../../api';
import _ from '../../locale';
import {
    getOrganizationId,
    getSessionAccount,
    getSessionAccountId,
} from '../../store/root-reducer';
import { signOut, updateAccount } from '../../store/session/actions';
import { API_DOCUMENTATION_PATH } from '../../router/paths';
import ApiCredentialsTable from './ApiCredentialsTable';

const ApiAccessHeader = styled(FlexRow)<{}>(({ theme }) => ({
    padding: '10px 30px',
    background: theme.color.tableZebraStripeGray,
    borderTopLeftRadius: theme.borderRadius.base,
    borderTopRightRadius: theme.borderRadius.base,
}));

const ToggleContainer = styled.span(({ theme }) => ({
    paddingTop: 4,
    minWidth: 300,
}));

const ViewDocsButton = styled(Button)(({ theme }) => ({
    margin: '10px 10px 10px 0',
}));

const APIAccessButton = styled(Button)(({ theme }) => ({
    margin: '10px 0',
}));

const mapStateToProps = (state: RootState) => ({
    accountId: getSessionAccountId(state),
    organizationId: getOrganizationId(state),
    sessionAccount: getSessionAccount(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ updateAccount, signOut }, dispatch);

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
        history: any;
    };

const ACCESS_KEY_CREATED_MODAL = 'accessKeyCreated';

type State = {
    tableKeyCounter: number;
    accessKey: string;
    secretKey: string;
    activeModal: null | typeof ACCESS_KEY_CREATED_MODAL;
};

class ApiAccessTab extends React.PureComponent<Props, State> {
    state: State = {
        tableKeyCounter: 0,
        accessKey: '',
        secretKey: '',
        activeModal: null,
    };

    toggleProgrammaticAccessClick$ = new Subject<{ has_programmatic_access }>();

    updateAccountSubscription = this.toggleProgrammaticAccessClick$
        .pipe(
            switchMap(({ has_programmatic_access }) =>
                from(
                    this.props.updateAccount(this.props.organizationId, this.props.accountId, {
                        has_programmatic_access,
                    })
                )
            )
        )
        .subscribe(({ has_programmatic_access }) => {
            this.forceUpdate();
        });

    handleOpenDocumentation = (ev: any) => {
        window.open(API_DOCUMENTATION_PATH, '_blank');
    };

    handleCreateAccessKey = (ev: any) => {
        const { organizationId, accountId } = this.props;

        api.organization
            .addOrganizationMemberAPICredentials({
                organizationId,
                memberId: accountId,
                data: {},
            })
            .then(({ access_key, secret_key }) => {
                this.setState((state) => ({
                    activeModal: ACCESS_KEY_CREATED_MODAL,
                    tableKeyCounter: state.tableKeyCounter + 1,
                    accessKey: access_key,
                    secretKey: secret_key,
                }));
            });
    };

    handleToggleProgrammaticAccess = (ev: any) => {
        const { sessionAccount } = this.props;
        this.toggleProgrammaticAccessClick$.next({
            has_programmatic_access: !sessionAccount.has_programmatic_access,
        });
    };

    componentWillUnmount() {
        this.updateAccountSubscription.unsubscribe();
    }

    renderToggle = () => {
        const { sessionAccount } = this.props;

        const checked = sessionAccount.has_programmatic_access;

        if (sessionAccount.organization_role !== RoleManager) {
            return <span />;
        }

        return (
            <ToggleContainer>
                {_.get('api_access_programmatic_toggle_label')}
                &nbsp;
                <Toggle checked={checked} onChange={this.handleToggleProgrammaticAccess} />
            </ToggleContainer>
        );
    };

    render() {
        const { sessionAccount, organizationId, accountId } = this.props;
        const { accessKey, secretKey } = this.state;

        if (sessionAccount == null) {
            return <Loading />;
        }

        const hasProgrammaticAccess = sessionAccount.has_programmatic_access;

        return (
            <React.Fragment>
                <ApiAccessHeader justify="space-between" align="center">
                    {this.renderToggle()}
                    <div>
                        <ViewDocsButton color="base" onClick={this.handleOpenDocumentation}>
                            {_.get('api_access_view_docs_button')}
                        </ViewDocsButton>
                        <APIAccessButton
                            color="primary"
                            onClick={this.handleCreateAccessKey}
                            disabled={!hasProgrammaticAccess}
                        >
                            {_.get('api_access_create_button_label')}
                        </APIAccessButton>
                    </div>
                </ApiAccessHeader>
                {!sessionAccount.has_programmatic_access ? (
                    <TableEmptyText>{_('api_credentials_table_empty_disabled')}</TableEmptyText>
                ) : (
                    <ApiCredentialsTable
                        key={this.state.tableKeyCounter}
                        organizationId={organizationId}
                        memberId={accountId}
                    />
                )}
                <ConfirmationModal
                    bsSize="lg"
                    title={_.get('api_credentials_created_modal_title')}
                    message={
                        <FlexColumn>
                            <div>{_.get('api_credentials_created_modal_message')}</div>
                            <br />
                            <Table stripes>
                                <TableHead>
                                    <TableHeaderRow>
                                        <TableHeaderCell>
                                            {_('api_credentials_table_column_access_key')}
                                        </TableHeaderCell>
                                        <TableHeaderCell>
                                            {_('api_credentials_table_column_secret_key')}
                                        </TableHeaderCell>
                                    </TableHeaderRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell width="30%">{accessKey}</TableCell>
                                        <TableCell style={{ overflowWrap: 'anywhere' }}>
                                            <HiddenText message="Show secret" value={secretKey} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </FlexColumn>
                    }
                    isVisible={this.state.activeModal === ACCESS_KEY_CREATED_MODAL}
                    isCancelEnabled={false}
                    onClose={() => {
                        this.setState({ activeModal: null });
                    }}
                    submitLabel={_('modal_done')}
                    onSubmit={() => {
                        this.setState({ activeModal: null });
                    }}
                />
            </React.Fragment>
        );
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withGoogleAnalytics({
        trackMethods: (props) => ({
            saveAvatar: (account) =>
                props.trackEvent({
                    category: 'Account',
                    action: 'Update Profile Picture',
                }),
            saveData: (account) =>
                props.trackEvent({
                    category: 'Account',
                    action: 'Update Profile Information',
                }),
        }),
    })
)(ApiAccessTab);
