import React from 'react';
import numeral from 'numeral';

import { DataCondition } from '@portal/common/types';
import { CauseOutcome } from '@portal/common/models/data-type';
import { PRIMARY_ENTITY_ID_KEY } from '@portal/common/models/data-key';
import { styled } from '@portal/common/theme';

import config from '../../../config';
import _ from '../../../locale';

import DeathsBlock from '../HealthBackgroundSection/DeathsBlock';
import IncidenceBlock from './IncidenceBlock';
import MostPrevalentCancerBlock from './MostPrevalentCancerBlock';
import ProjectedDeathsBlock from './ProjectedDeathsBlock';

const Container = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 30,
    gap: 20,
    [theme.media.laptop]: {
        flexDirection: 'column',
        minWidth: 250,
    },
}));

type Props = {
    locationId: number;
};

const ConditionMetrics = ({ locationId }: Props) => {
    const condition = {
        data_type: CauseOutcome,
        [PRIMARY_ENTITY_ID_KEY]: config.multipleMyelomaCauseId,
    } as DataCondition;

    const sharedProps = {
        locationId,
        condition,
    };

    return (
        <Container>
            <DeathsBlock
                {...sharedProps}
                label={_('simulation_tool_mm_death_metric')}
                valueFormatter={(value) => numeral(value).format('0,0')}
            />
            <ProjectedDeathsBlock
                {...sharedProps}
                valueFormatter={(value) => numeral(value).format('0,0')}
            />
            <MostPrevalentCancerBlock {...sharedProps} />
            <IncidenceBlock {...sharedProps} />
        </Container>
    );
};

export default ConditionMetrics;
