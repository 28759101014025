import { createAsyncAction } from 'typesafe-actions';
import {
    CauseEntity,
    CauseICDCodeEntity,
    LocationEntity,
    ReiEntity,
    SequelaEntity,
} from '@portal/common/types';

export const getLocationsAsync = createAsyncAction(
    'GET_LOCATIONS_REQUEST',
    'GET_LOCATIONS_SUCCESS',
    'GET_LOCATIONS_FAILURE'
)<void, LocationEntity[], string>();

/*export const getDatasetLocationsAsync = createAsyncAction(
    'GET_DATASET_LOCATIONS_REQUEST',
    'GET_DATASET_LOCATIONS_SUCCESS',
    'GET_DATASET_LOCATIONS_FAILURE'
)<undefined, { resourceId: number; dataType: string; hierarchy: LocationEntity[] }, string>();*/

export const getCausesAsync = createAsyncAction(
    'GET_CAUSES_REQUEST',
    'GET_CAUSES_SUCCESS',
    'GET_CAUSES_FAILURE'
)<void, CauseEntity[], string>();

export const getVehssAsync = createAsyncAction(
    'GET_VEHSS_REQUEST',
    'GET_VEHSS_SUCCESS',
    'GET_VEHSS_FAILURE'
)<void, CauseEntity[], string>();

export const getCauseIcdCodesAsync = createAsyncAction(
    'GET_CAUSE_ICD_CODES_REQUEST',
    'GET_CAUSE_ICD_CODES_SUCCESS',
    'GET_CAUSE_ICD_CODES_FAILURE'
)<void, CauseICDCodeEntity[], string>();

export const getSequelasAsync = createAsyncAction(
    'GET_SEQUELAS_REQUEST',
    'GET_SEQUELAS_SUCCESS',
    'GET_SEQUELAS_FAILURE'
)<void, SequelaEntity[], string>();

export const getRisksAsync = createAsyncAction(
    'GET_RISKS_REQUEST',
    'GET_RISKS_SUCCESS',
    'GET_RISKS_FAILURE'
)<void, ReiEntity[], string>();

export const getEtiologiesAsync = createAsyncAction(
    'GET_ETIOLOGIES_REQUEST',
    'GET_ETIOLOGIES_SUCCESS',
    'GET_ETIOLOGIES_FAILURE'
)<void, ReiEntity[], string>();

export const getImpairmentsAsync = createAsyncAction(
    'GET_IMPAIRMENTS_REQUEST',
    'GET_IMPAIRMENTS_SUCCESS',
    'GET_IMPAIRMENTS_FAILURE'
)<void, ReiEntity[], string>();

export const getInjuryAsync = createAsyncAction(
    'GET_INJURY_REQUEST',
    'GET_INJURY_SUCCESS',
    'GET_INJURY_FAILURE'
)<void, ReiEntity[], string>();
