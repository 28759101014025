import { intersection, isEmpty } from 'lodash/fp';

import { DataCollectionResource, DataGranularity, DataType, Entity } from '@portal/common/types';
import { AllDataTypes } from '@portal/common/models/data-type';

import allOptions, { ConditionSmartOption } from './options';

export default (
    dataCollection: DataCollectionResource,
    dataType: DataType,
    selectedConditionsDataTypes: DataType[] | null,
    granularityByDataType: Record<DataType, DataGranularity>,
    hierarchy: Record<string, Entity[]>
): ConditionSmartOption[] => {
    const dataCollectionDataTypes = (dataCollection?.datasets || []).map(
        ({ data_type }) => data_type
    );
    let eligibleDataTypes = dataType === AllDataTypes ? dataCollectionDataTypes : [dataType];

    if (!isEmpty(selectedConditionsDataTypes)) {
        eligibleDataTypes = intersection(selectedConditionsDataTypes, eligibleDataTypes);
    }

    return allOptions.filter((smartOption) => {
        const values = smartOption
            .getSelectedValues(granularityByDataType, hierarchy)
            .filter(({ data_type }) => eligibleDataTypes.includes(data_type));
        return !isEmpty(values);
    });
};
