import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash/fp';

import { DataGranularity, DataType } from '@portal/common/types';
import { LOCATION_ID_KEY } from '@portal/common/models/data-key';
import { Dropdown } from '@portal/common/components';
import { withTheme } from '@portal/common/theme';

import { changeSelectedRefinementFilters } from '../../../store/data-explorer/actions';
import { getSelectedRefinementFilters } from '../../../store/data-explorer/selectors';
import { getSelectedDataCollectionForDataTool } from '../../../store/user-settings/selectors';
import _ from '../../../locale';
import {
    getDataCollectionDataTypes,
    getMergedDataCollectionGranularity,
} from '../../../utility/data-loader';

const LocationSelector = ({ theme }) => {
    const dispatch = useDispatch();
    const selectedRefinementFilters = useSelector(getSelectedRefinementFilters);
    const dataCollection = useSelector(getSelectedDataCollectionForDataTool);

    let dataTypes: DataType[] = [];
    let granularity: DataGranularity = {};

    if (dataCollection) {
        dataTypes = getDataCollectionDataTypes(dataCollection);
        granularity = getMergedDataCollectionGranularity(dataCollection, dataTypes);
    }

    if (isEmpty(granularity)) {
        return null;
    }

    const handleSelect = (ev) => {
        const { value } = ev.target;
        dispatch(changeSelectedRefinementFilters({ [LOCATION_ID_KEY]: value }));
    };

    const value = selectedRefinementFilters?.[LOCATION_ID_KEY] || [];

    return (
        <Dropdown
            label=""
            valueMode="singleArray"
            options={granularity[LOCATION_ID_KEY]}
            value={value}
            placeholder="Select location..."
            optionLabelGetter={(o) => _.withPrefix('location_', o)}
            onSelect={handleSelect}
            inputFieldContainerStyle={{
                fontSize: 26,
                fontWeight: theme.typography.fontWeight.bold,
            }}
            innerInputFieldStyle={{
                background: 'transparent',
                color: theme.color.purple,
            }}
            selectedOptionStyle={{
                paddingTop: 10,
                paddingBottom: 4,
                borderBottom: `4px solid ${theme.color.secondary3}`,
            }}
        />
    );
};

export default withTheme(LocationSelector);
