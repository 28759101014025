import { uniqueId } from 'lodash';
import {
    COLUMN_ROW_LABEL_PADDING_LEFT,
    COLUMN_ROW_OFFSET_Y,
    COLUMN_ROW_MARGIN_BOTTOM,
} from './constants';
import _ from '../../locale';

export class ColumnElement {
    constructor(props) {
        this.name = uniqueId();
        this.symbolOffset = this.getSymbolOffset(props);
        this.item = props.item;
        this.index = props.index;
        this.x = props.columnX;
        this.y = this.getYPosition(props);
    }

    private getSymbolOffset = ({ theme, isLeftColumn }) => {
        const symbolWidth = theme.arrow.series.symbolSize[0];
        const x = +`${isLeftColumn ? '-' : ''}${symbolWidth / 2}`;

        return [x, 0];
    };

    private getYPosition = ({ index, theme }) => {
        const symbolHeight = theme.arrow.series.symbolSize[1];
        const START_Y = index ? COLUMN_ROW_OFFSET_Y : 0;
        const marginBottom = index ? COLUMN_ROW_MARGIN_BOTTOM : 0;
        const rowHalfHeight = symbolHeight / 2;
        const y = START_Y + marginBottom + rowHalfHeight + symbolHeight * index;
        return y;
    };

    private getLabel = ({ index, item, mapDataRecordToLabel }) => {
        const name = mapDataRecordToLabel(item);
        return `${index}  ${name}`;
    };
}

function Header<TBaseColumnElement extends FunctionConstructor>(
    BaseColumnElement: TBaseColumnElement
) {
    return class Header extends BaseColumnElement {
        constructor(props) {
            super(props);
            this.blur = this.getBlur(props);
            this.emphasis = this.getEmphasis();
            this.itemStyle = this.getItemStyle(props);
            this.label = this.getLabelStyle(props);
            this.value = this.getValue(props);
        }

        private getBlur = ({ theme }) => {
            return {
                itemStyle: {
                    opacity: 1,
                    color: theme.arrow.colors.white,
                },
                label: {
                    color: theme.arrow.colors.black,
                    opacity: 1,
                },
            };
        };

        private getItemStyle = ({ theme }) => {
            return {
                color: theme.arrow.colors.white,
            };
        };

        private getEmphasis = () => {
            return {
                disabled: true,
            };
        };

        private getLabelStyle = ({ theme }) => {
            return {
                color: theme.arrow.colors.headerLabelColor,
                fontWeight: 500,
                fontSize: 16,
                fontFamily: 'Avenir',
                align: 'center',
                position: 'inside',
                padding: [0, 0, 0, 0],
            };
        };

        private getLabel = ({ title, isOneTimeUnitChosen, isLeftColumn }) => {
            return isOneTimeUnitChosen && !isLeftColumn
                ? _('choose_another_year_to_compare')
                : title;
        };

        private getValue = (props) => {
            const x = props.columnX;
            const y = this.getYPosition(props);
            const label = this.getLabel(props);
            return [x, y, label, label];
        };

        public getEchartsProps() {
            const { blur, emphasis, itemStyle, label, value, name, symbolOffset } = this;
            return { blur, emphasis, itemStyle, label, value, name, symbolOffset };
        }
    };
}

function Row<TBaseColumnElement extends FunctionConstructor>(
    BaseColumnElement: TBaseColumnElement
) {
    return class Row extends BaseColumnElement {
        constructor(props) {
            super(props);

            const { item, mapDataRecordToCategoryIdx } = props;

            this.dataKeyValue = this.getDataKeyValue(props);
            this.id = this.getId(props);
            this.category = mapDataRecordToCategoryIdx(item);
            this.value = this.getValue(props);
            this.label = this.getLabelStyle(props);
            this.itemStyle = this.getItemStyle(props);
            this.isHoverable = true;
            this.isDrillable = this.getIsDrillable(props);
        }

        private getIsDrillable = (props): boolean => {
            const { drillableItems } = props;
            const dataKeyValue = this.getDataKeyValue(props);
            const id = Number(dataKeyValue.toString().split('_').slice(-1));
            return drillableItems.includes(id);
        };

        private getItemStyle = ({ blurredItemsDataKeyValues }) => {
            const opacity = blurredItemsDataKeyValues.includes(this.dataKeyValue) ? 0.4 : 1;
            return {
                opacity,
            };
        };

        private getDataKeyValue = (props) => {
            const { item, dataKeyValueGetter } = props;
            return dataKeyValueGetter(item);
        };

        private getId = (props) => {
            const { isLeftColumn } = props;
            const prefix = isLeftColumn ? 'l' : 'r';
            return `${prefix}-${this.dataKeyValue}`;
        };

        private getValue = (props) => {
            const x = props.columnX;
            const y = this.getYPosition(props);
            const label = this.getLabel(props);
            return [x, y, label, props.item];
        };

        private getLabelStyle = ({ theme }) => {
            return {
                overflow: 'truncate',
                width: theme.arrow.series.symbolSize[0] - COLUMN_ROW_LABEL_PADDING_LEFT,
                opacity: 1,
                show: true,
                color: theme.arrow.colors.white,
            };
        };

        public getEchartsProps() {
            const {
                index,
                dataKeyValue,
                id,
                category,
                value,
                label,
                itemStyle,
                name,
                symbolOffset,
                isHoverable,
                isDrillable,
            } = this;
            return {
                index,
                dataKeyValue,
                id,
                category,
                value,
                label,
                itemStyle,
                name,
                symbolOffset,
                isHoverable,
                isDrillable,
            };
        }
    };
}

function EmptyRow<TBaseColumnElement extends FunctionConstructor>(
    BaseColumnElement: TBaseColumnElement
) {
    return class EmptyRow extends BaseColumnElement {
        constructor(props) {
            super(props);
            this.value = this.getValue(props);
            this.itemStyle = this.getItemStyle(props);
            this.emphasis = this.getEmphasis();
        }

        private getValue = (props) => {
            const x = props.columnX;
            const y = this.getYPosition(props);
            return [x, y, ''];
        };

        private getItemStyle = ({ theme }) => {
            return {
                color: theme.arrow.colors.emptyRowBackground,
            };
        };

        private getEmphasis = () => {
            return {
                disabled: true,
            };
        };

        public getEchartsProps() {
            const { value, itemStyle, emphasis, name, symbolOffset } = this;
            return { value, itemStyle, emphasis, name, symbolOffset };
        }
    };
}

const ColumnHeader = Header(ColumnElement);
const ColumnRow = Row(ColumnElement);
const EmptyColumnRow = EmptyRow(ColumnElement);
export class Link {
    constructor(props) {
        this.source = props.source.id;
        this.target = props.target.id;
        this.lineStyle = this.getLineStyle(props);
        this.isHoverable = true;
    }

    private getLineStyle = ({ source, target, blurredItemsDataKeyValues }) => {
        let type = 'solid';
        if (source.index < target.index) {
            type = 'dashed';
        }
        const opacity = blurredItemsDataKeyValues.includes(source.dataKeyValue) ? 0.4 : 1;

        return {
            type,
            opacity,
        };
    };

    public getEchartsProps() {
        const { source, target, lineStyle, isHoverable } = this;
        return { source, target, lineStyle, isHoverable };
    }
}

export const createColumnData = ({ data, isLeftColumn, isOneTimeUnitChosen, ...props }) => {
    const columnData = [];

    const columnTitle = new ColumnHeader({ index: 0, isLeftColumn, isOneTimeUnitChosen, ...props });
    columnData.push(columnTitle.getEchartsProps());
    data.forEach((item, index) => {
        const columnProps = { item, isLeftColumn, index: index + 1, isOneTimeUnitChosen, ...props };
        let column =
            isOneTimeUnitChosen && !isLeftColumn
                ? new EmptyColumnRow(columnProps)
                : new ColumnRow(columnProps);
        columnData.push(column.getEchartsProps());
    });

    return columnData;
};

export const createLinks = ({ leftColumn, rightColumn, blurredItemsDataKeyValues }) => {
    const links: Link[] = [];
    leftColumn.forEach((source) => {
        const target = rightColumn.find((item) => item.dataKeyValue === source.dataKeyValue);
        const link = new Link({ source, target, blurredItemsDataKeyValues });
        links.push(link.getEchartsProps());
    });
    return links;
};
