import * as React from 'react';
import withQueryParams, {
    WithQueryParamsInjectedProps,
} from '@ihme/common/utility/hoc/with-query-params';
import { Alert } from '@ihme/common/web/components';

import { H3, SectionHeader, InternalLink } from '@portal/common/components';

import _ from '../../locale';
import api from '../../api';
import { SIGN_IN_PATH } from '../../router/paths';

// localhost:8000/email-confirmation?token=9e149c84f43e0d988e08ce41db95b1
type Props = WithQueryParamsInjectedProps<{ token }>;

const EmailConfirmationScene: React.FC<Props> = (props) => {
    const {
        queryParams: { token },
    } = props;

    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        if (token) {
            api.emailConfirmation.delete({ token }).catch((err) => {
                setError(err.message);
            });
        }
    }, []);

    return (
        <div style={{ maxWidth: 500, margin: '0 auto', marginBottom: 80 }}>
            <SectionHeader>
                <H3>Registration Confirmation</H3>
            </SectionHeader>
            {error && <Alert bsStyle="danger">{error}</Alert>}
            {!error && (
                <Alert bsStyle="success">
                    {_.get('register_confirmation')}
                    <InternalLink
                        href={{
                            pathname: SIGN_IN_PATH,
                            state: {
                                action: 'scroll_to_top',
                            },
                        }}
                    >
                        {_.get('register_confirmation_link')}
                    </InternalLink>
                </Alert>
            )}
        </div>
    );
};

export default withQueryParams(EmailConfirmationScene);
