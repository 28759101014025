import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'MyTypes';
import { isEmpty, isEqual } from 'lodash/fp';
import isFastDeepEqual from 'fast-deep-equal';

import { withStorage, WithStorageProps } from '@ihme/common/packages/storage';

import {
    AGE_GROUP_ID_KEY,
    CAUSE_ID_KEY,
    EDUCATION_ID_KEY,
    GENDER_ID_KEY,
    isTimeUnitKey,
    LOCATION_ID_KEY,
    LOWER_KEY,
    RACE_ID_KEY,
    UPPER_KEY,
} from '@portal/common/models/data-key';
import {
    DataCondition,
    DataExportFormat,
    DataFilters,
    DataGranularity,
    DataGranularityKey,
    DataKey,
    DataResponse,
    DataType,
} from '@portal/common/types';
import { TYPE_BAR_CHART } from '@portal/common/models/chart-type';
import { areComorbidityDataTypes } from '@portal/common/models/data-type';
import { Checkbox, FlexColumn, Tooltip } from '@portal/common/components';
import { styled } from '@portal/common/theme';

import { getSelectedDataTool } from '../../../../store/root-reducer';
import {
    getAgeGroups,
    getAgeGroupsOrder,
    getChartDimensionFilter,
    getDataToolConfig,
    getSelectedDataProject,
} from '../../../../store/data-explorer/selectors';
import { changeChartDimension } from '../../../../store/data-explorer/actions';

import { ChartRefinementFilterConfig, getChartRefinementFiltersConfig } from '../utils';

import _ from '../../../../locale';

import ChartRefinementFiltersControls from '../ChartRefinementFiltersControls';
import ChartDimensionSelector from '../../../ChartDimensionSelector';
import Chart from './Chart';

const CheckBoxContainer = styled.div(({ theme }) => ({
    width: 'fit-content',
}));

const mapStateToProps = (state: RootState) => ({
    ageGroupsOrder: getAgeGroupsOrder(state),
    dataTool: getSelectedDataTool(state),
    ageGroupEntities: getAgeGroups(state),
    dataToolConfig: getDataToolConfig(state),
    dataProject: getSelectedDataProject(state),
    selectedXAxisDimension: getChartDimensionFilter(state),
});

const dispatchProps = {
    changeChartDimension,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    WithStorageProps & {
        initialTimeUnitValue: number | string;
        onDataLoad?: () => void;
        selectedConditionsDataTypes: DataType[];
        selectedConditionsDataTypesGranularity: DataGranularity;
        selectedConditionsPrimaryEntityFilters: DataGranularityKey[];
        selectedConditions: DataCondition[];
        multipleFilterKeys: DataGranularityKey[];

        mergedChartDataResponses: DataResponse;
        isLoadingDataExplorerData: boolean;
        isLoadingChartData: boolean;
        filtersValues: DataFilters;
        timeUnitKey: DataGranularityKey;
        onExportData?: (format: DataExportFormat) => void;
        enableExportPNG: boolean;
        enableExportGIF: boolean;
        numberFormatter: (value: number | string) => string;
    };

type State = {
    areDataLabelsDisabled: boolean;
    displayBarLabels: boolean;
    displayUncertainties: boolean;
    dataDeliveryCounter: number;
    areUncertaintiesEnabled: boolean;
};

class BarChart extends React.PureComponent<Props, State> {
    state: State = {
        areDataLabelsDisabled: false,
        displayBarLabels: false,
        dataDeliveryCounter: 0,
        displayUncertainties: false,
        areUncertaintiesEnabled: false,
    };

    chartRef = React.createRef();

    componentDidMount() {
        const { onDataLoad } = this.props;
        onDataLoad && onDataLoad();
        this.initialize();
    }

    componentDidUpdate(prevProps) {
        const { selectedConditionsDataTypes, mergedChartDataResponses } = this.props;

        if (!isEqual(selectedConditionsDataTypes, prevProps.selectedConditionsDataTypes)) {
            this.initialize();
        }

        if (
            mergedChartDataResponses &&
            !isFastDeepEqual(mergedChartDataResponses, prevProps.mergedChartDataResponses)
        ) {
            const isEmptyData = isEmpty(mergedChartDataResponses.records.length === 0);
            this.setState((state) => ({
                dataDeliveryCounter: state.dataDeliveryCounter + 1,
                ...(isEmptyData && {
                    areUncertaintiesEnabled: false,
                }),
            }));
        }
    }

    initialize = () => {
        this.props.changeChartDimension(this.getInitialXAxisDimension());
    };

    getInitialXAxisDimension = (): DataKey => {
        const { selectedConditionsDataTypes, timeUnitKey } = this.props;
        let value = timeUnitKey;
        if (selectedConditionsDataTypes && areComorbidityDataTypes(selectedConditionsDataTypes)) {
            value = AGE_GROUP_ID_KEY;
        }

        const options = this.getXAxisDimensionOptions();
        if (options && options.length && !options.includes(value)) {
            value = options[options.length - 1];
        }

        return value;
    };

    getRefinementFiltersConfig = (): ChartRefinementFilterConfig[] => {
        const { multipleFilterKeys, selectedXAxisDimension } = this.props;

        const config = getChartRefinementFiltersConfig(TYPE_BAR_CHART).filter(
            ({ key }) =>
                selectedXAxisDimension !== key ||
                (key === CAUSE_ID_KEY && !multipleFilterKeys.includes(key))
        );

        return config;
    };

    getXAxisDimensionOptions = (): DataKey[] => {
        const { selectedConditionsDataTypesGranularity, timeUnitKey } = this.props;

        const allOptions = [
            AGE_GROUP_ID_KEY,
            GENDER_ID_KEY,
            RACE_ID_KEY,
            LOCATION_ID_KEY,
            EDUCATION_ID_KEY,
            timeUnitKey,
        ];

        // return only keys present in a granularity
        return allOptions.filter((key) => selectedConditionsDataTypesGranularity[key]?.length > 1);
    };

    toggleUncertaintyVisibility = () =>
        this.setState((prevState: State) => ({
            displayUncertainties: !prevState.displayUncertainties,
        }));

    toggleBarLabelsVisibility = () =>
        this.setState((prevState: State) => ({
            displayBarLabels: !prevState.displayBarLabels,
        }));

    handleDataLabelsEnabledChange = (isEnabled: boolean) => {
        this.setState({ areDataLabelsDisabled: !isEnabled });
    };

    handleUncertaintyAvailabilityChange = (areUncertaintiesEnabled: boolean) => {
        this.setState({ areUncertaintiesEnabled });
    };

    render() {
        const {
            initialTimeUnitValue,
            selectedConditions,
            selectedConditionsDataTypes,
            selectedConditionsDataTypesGranularity,
            chartFilters,
            isLoadingDataExplorerData,
            isLoadingChartData,
            filtersValues,
            multipleFilterKeys,
            timeUnitKey,
            mergedChartDataResponses,
            dataTool,
            ageGroupEntities,
            dataToolConfig,
            dataProject,
            numberFormatter,
            selectedXAxisDimension,
        } = this.props;

        const {
            displayBarLabels,
            displayUncertainties,
            areDataLabelsDisabled,
            dataDeliveryCounter,
            areUncertaintiesEnabled,
        } = this.state;

        if (!selectedConditionsDataTypesGranularity || mergedChartDataResponses == null) {
            return null;
        }

        const { barChartConfig } = dataToolConfig;
        const stackBy = barChartConfig?.stackBy
            ? barChartConfig.stackBy
            : selectedXAxisDimension !== GENDER_ID_KEY &&
              chartFilters &&
              chartFilters.hasOwnProperty(GENDER_ID_KEY) &&
              Array.isArray(chartFilters[GENDER_ID_KEY]) &&
              chartFilters[GENDER_ID_KEY].length > 1
            ? GENDER_ID_KEY
            : null;

        const dataHasUncertaintyValues =
            mergedChartDataResponses?.columns.includes(UPPER_KEY) &&
            mergedChartDataResponses?.columns.includes(LOWER_KEY);

        return (
            <>
                <ChartRefinementFiltersControls config={this.getRefinementFiltersConfig()} />
                <ChartDimensionSelector
                    label={_('filter_label_x_axis_dimension')}
                    options={this.getXAxisDimensionOptions()}
                    mapValueToLabel={(value) => _.get(`bar_chart_${value}_dimension`)}
                />
                <FlexColumn itemsSpacing={20}>
                    {dataHasUncertaintyValues && (
                        <CheckBoxContainer>
                            <Tooltip
                                title={_('bar_chart_uncertainty_checkbox_tooltip')}
                                placement="top"
                                trigger={areUncertaintiesEnabled ? 'none' : 'hover'}
                            >
                                <Checkbox
                                    checked={displayUncertainties}
                                    disabled={!areUncertaintiesEnabled}
                                    onChange={this.toggleUncertaintyVisibility}
                                >
                                    {_('display_uncertainty')}
                                </Checkbox>
                            </Tooltip>
                        </CheckBoxContainer>
                    )}
                    <CheckBoxContainer>
                        <Tooltip
                            title={_('bar_chart_labels_checkbox_tooltip')}
                            placement="top"
                            trigger={areDataLabelsDisabled ? 'hover' : 'none'}
                        >
                            <Checkbox
                                checked={displayBarLabels}
                                disabled={areDataLabelsDisabled}
                                onChange={this.toggleBarLabelsVisibility}
                            >
                                {_('bar_chart_display_labels')}
                            </Checkbox>
                        </Tooltip>
                    </CheckBoxContainer>
                </FlexColumn>
                <Chart
                    key={isTimeUnitKey(selectedXAxisDimension).toString()}
                    {...this.props}
                    ref={this.chartRef}
                    dataTool={dataTool}
                    initialTimeUnitValue={initialTimeUnitValue}
                    xAxisKey={selectedXAxisDimension}
                    selectedConditions={selectedConditions}
                    selectedConditionsDataTypes={selectedConditionsDataTypes}
                    selectedConditionsDataTypesGranularity={selectedConditionsDataTypesGranularity}
                    stackByKey={stackBy}
                    isLoadingData={isLoadingChartData || isLoadingDataExplorerData}
                    filtersValues={filtersValues}
                    multipleFilterKeys={multipleFilterKeys}
                    timeUnitKey={timeUnitKey}
                    mergedDataResponses={mergedChartDataResponses}
                    ageGroupEntities={ageGroupEntities}
                    dataProject={dataProject}
                    numberFormatter={numberFormatter}
                    displayBarLabels={displayBarLabels}
                    displayUncertainties={displayUncertainties}
                    areDataLabelsDisabled={areDataLabelsDisabled}
                    onDataLabelsEnabledChange={this.handleDataLabelsEnabledChange}
                    dataDeliveryCounter={dataDeliveryCounter}
                    onUncertaintyAvailabilityChange={this.handleUncertaintyAvailabilityChange}
                />
            </>
        );
    }
}

export default compose(connect(mapStateToProps, dispatchProps), withStorage)(BarChart);
