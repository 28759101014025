export default {
    causeTag: 'cause',
    sequelaTag: 'sequela',
    riskTag: 'risk',
    riskPrevalenceTag: 'riskprevalence',
    etiologyTag: 'etiology',
    impairmentTag: 'impairment',
    injuryTag: 'injury',
    sevTag: 'sev',
    covariateTag: 'covariate',
    populationTag: 'population',
    covid19Tag: 'covid19',
    riskExposureRecordsTag: 'riskexposure',
    lifeExpectancyRecordsTag: 'lifeExpectancy',
    antimicrobialResistanceRecordsTag: 'antimicrobialResistance',
    multipleMyelomaSurvivalResultsTag: 'multipleMyelomaSurvivalResults',
    multipleMyelomaOutputShellTag: 'multipleMyelomaOutputShell',
    multipleMyelomaPopulationTag: 'multipleMyelomaPopulation',
    healthThreatsMetricsRecordsTag: 'healthThreatsMetricsRecords',
    raceEthnicityEyeConditionsRecordsTag: 'raceEthnicityEyeConditionsRecords',
};
