export const breakLineAtEach = (value: string, occurrenceIndex: number) => {
    const words = value.split(' ');

    return words.reduce((accumulator, currentValue, idx) => {
        const jumper = idx % occurrenceIndex === 0 ? '\n' : ' ';
        return `${accumulator}${jumper}${currentValue}`;
    });
};

export const breakLineAt = (string, occurrenceIndex) =>
    replaceAt(string, getPosition(string, ' ', occurrenceIndex), '\n');

const replaceAt = (string, index, replacement) =>
    index > string.length - 1
        ? string
        : string.substr(0, index) + replacement + string.substr(index + replacement.length);

const getPosition = (string, symbol, occurrenceIndex) =>
    string.split(symbol, occurrenceIndex).join(symbol).length;
