import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { FlexRow } from '@portal/common/components';
import { AllDataTypes } from '@portal/common/models/data-type';
import { DataCondition, DataGranularityKey } from '@portal/common/types';

import ConditionDropdown from '../../components/ConditionDropdown';
import _ from '../../locale';
import { changeConditions } from '../../store/data-explorer/actions';
import {
    getDataToolDataCollections,
    getSelectedConditions,
    getSelectedConditionsDataTypes,
    getSelectedDataType,
} from '../../store/data-explorer/selectors';
import { getSelectedDataCollectionForDataTool } from '../../store/user-settings/selectors';

const mapStateToProps = (state: RootState) => ({
    dataCollections: getDataToolDataCollections(state),
    selectedConditions: getSelectedConditions(state),
    selectedConditionsDataTypes: getSelectedConditionsDataTypes(state),
    selectedDataCollection: getSelectedDataCollectionForDataTool(state),
    selectedDataType: getSelectedDataType(state),
});

const dispatchProps = {
    changeConditions: changeConditions,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & {
        granularityKey: DataGranularityKey;
        defaultFilters: {};
        refinementFiltersConfig: {};
    };

type State = {
    activeRefinements: Array<string>;
};

class ComorbidityConditionSelector extends React.Component<Props, State> {
    handleChange = (conditions: DataCondition[]): void => {
        const { changeConditions } = this.props;
        changeConditions(conditions);
    };

    render = () => {
        const { dataCollections, selectedDataCollection, selectedConditions, selectedDataType } =
            this.props;

        if (!dataCollections || !dataCollections.length || !selectedDataCollection) {
            return null;
        }

        const filteredDatasets = selectedDataCollection.datasets.filter(
            (i) => selectedDataType === AllDataTypes || selectedDataType === i.data_type
        );

        return (
            <FlexRow itemsSpacing={30}>
                <div style={{ flexGrow: 1, position: 'relative' }}>
                    <ConditionDropdown
                        key={
                            selectedDataCollection.id +
                            filteredDatasets.map((i) => i.data_type).toString()
                        }
                        label={_('comorbidity_condition_label')}
                        placeholder={_('comorbidity_condition_placeholder')}
                        datasets={filteredDatasets}
                        onChange={this.handleChange}
                        value={selectedConditions}
                        isMulti={false}
                        isSearchable
                        renderMenuHeader={() => null}
                    />
                </div>
            </FlexRow>
        );
    };
}

export default compose(connect(mapStateToProps, dispatchProps))(ComorbidityConditionSelector);
