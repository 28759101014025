import { RootState } from 'MyTypes';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { FieldLabel, FormGroup, RangeSlider } from '@portal/common/components';
import { DataGranularityKey } from '@portal/common/types';
import {
    firstAndLastFromArray,
    getLocalePrefixForGranularityKey,
    minMaxTupleToRangeArray,
} from '@portal/common/utility/filters-helpers';

import _ from '../../locale';
import { validateSelectedRefinementFiltersComplexityLimit } from '../../store/data-explorer/actions';
import {
    getSelectedConditionsDataTypesGranularity,
    getSelectedConditionsRefinedGranularity,
} from '../../store/data-explorer/selectors';

const mapStateToProps = (state: RootState) => ({
    granularity: getSelectedConditionsDataTypesGranularity(state),
    refinedGranularity: getSelectedConditionsRefinedGranularity(state),
});

const dispatchProps = {
    tryChangeSelectedRefinementFilters: validateSelectedRefinementFiltersComplexityLimit,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps &
    React.ComponentProps<typeof RangeSlider> & {
        id?: string;
        styles?: object;
        label?: string;
        localePrefix?: string;
        labelStyles?: object;
        filterKey: DataGranularityKey;
        preprocessChange?: (value: any) => any;
        onAfterChange?: (value: any) => any;
        filterValue?: number[];
        undefinedWhenAllSelected?: boolean;
        isLockedMinValue?: boolean;
    };

const ChartRangeSlider: React.FC<Props> = ({
    id,
    className,
    label,
    filterKey,
    preprocessChange,
    labelStyles,
    style,
    granularity,
    refinedGranularity,
    tryChangeSelectedRefinementFilters,
    onAfterChange,
    undefinedWhenAllSelected,
    isLockedMinValue,
    ...props
}) => {
    const localePrefix = props.localePrefix || getLocalePrefixForGranularityKey(filterKey);

    const options = (
        (refinedGranularity != null ? refinedGranularity[filterKey] : granularity[filterKey]) || []
    ).sort();

    const indexToFilterValue = (index: number) => options[index];

    const filterValue = props.filterValue;
    const setFilterValue = (value) => {
        tryChangeSelectedRefinementFilters({ [filterKey]: value });
    };

    const [sliderValue, setSliderValue] = React.useState<[number, number] | null>(null);

    const [minIndex, maxIndex] = firstAndLastFromArray(options.map((v, idx) => idx)) || [0, 0];

    const handleSliderChange = React.useCallback(
        (value: [number, number] | null) => {
            if (value) {
                const minValue = isLockedMinValue ? minIndex : value[0];
                const maxValue = value[1];

                if (minValue == minIndex && maxValue == maxIndex) {
                    setSliderValue(null);
                } else {
                    setSliderValue([minValue, maxValue]);
                }
            }
        },
        [minIndex, maxIndex]
    );

    const filterToSliderValue = (filterValue?: number[]) => {
        const values = filterValue;
        if (values) {
            return values.map((v) => options.indexOf(v));
        }

        return filterValue;
    };

    const [selectedStartIndex, selectedEndIndex] = firstAndLastFromArray(
        filterToSliderValue(filterValue)
    ) || [0, 0];

    // sync internal sliderValue with external filterValue
    useEffect(() => {
        if (sliderValue != null && filterValue == null) {
            setSliderValue(null);
        } else if (sliderValue == null && filterValue != null) {
            setSliderValue([selectedStartIndex, selectedEndIndex]);
        }
    }, [selectedStartIndex, selectedEndIndex]);

    return (
        <FormGroup className={className} style={{ padding: '0px 12px 16px', marginBottom: 30 }}>
            {label && (
                <FieldLabel style={{ marginLeft: -12, ...labelStyles }} htmlFor={id}>
                    {label}
                </FieldLabel>
            )}
            <RangeSlider
                id={id}
                data-testid={id}
                min={minIndex}
                max={maxIndex}
                marks={
                    maxIndex
                        ? {
                              [minIndex]: _.withPrefix(localePrefix, indexToFilterValue(minIndex)),
                              [maxIndex]: _.withPrefix(localePrefix, indexToFilterValue(maxIndex)),
                          }
                        : undefined
                }
                tipFormatter={(index) => _.withPrefix(localePrefix, indexToFilterValue(index))}
                value={sliderValue || [minIndex, maxIndex]}
                onChange={handleSliderChange}
                onAfterChange={(value: [number, number]) => {
                    // preserve current value when new value empty
                    if (!value || !setFilterValue) {
                        return;
                    }

                    if (undefinedWhenAllSelected) {
                        const [startIndex, endIndex] = value;
                        if (startIndex === minIndex && endIndex === maxIndex) {
                            setFilterValue(undefined);

                            return;
                        }
                    }

                    const rangeArrayToFilterValue = (rangeValue: number[] | null) => {
                        if (rangeValue == null) {
                            return rangeValue;
                        }

                        return rangeValue.map(indexToFilterValue);
                    };
                    let filterValue = rangeArrayToFilterValue(minMaxTupleToRangeArray(value));

                    if (preprocessChange) {
                        filterValue = preprocessChange(filterValue);
                    }

                    setFilterValue(filterValue);
                    onAfterChange && onAfterChange(filterValue);
                }}
                {...(isLockedMinValue && {
                    handleStyle: [{ display: 'none' }, {}],
                    dotStyle: { pointerEvents: 'none' },
                })}
                style={style}
                {...props}
            />
        </FormGroup>
    );
};

export default compose(connect(mapStateToProps, dispatchProps))(ChartRangeSlider);
