import translate from '@portal/common/locale';
import locale from './en';
import { Parser } from 'html-to-react';

const htmlParser = Parser();

/**
 * @description get translation by key (with type-checking)
 */
function get(key: keyof typeof locale, replacements?: {}, localeCode?: string): string {
    const text = translate(key, replacements, localeCode);
    const result = htmlParser.parse(text);
    return result != null ? result : text;
}

/**
 * @description get translation by matching the key in map object (with type-checking)
 */
function match<T extends { [P in keyof T]: keyof typeof locale }>(matchers: T) {
    return (key: keyof T, replacements?: {}, localeCode?: string) =>
        translate(matchers[key], replacements, localeCode);
}

/**
 * @description parse html tree string
 * ('<Link>Text</Link>')
 */
function parse(text: string): string {
    const result = htmlParser.parse(text);
    return result != null ? result : text;
}

/**
 * @description get translation using prefix and key
 */
function withPrefix(
    prefix: string | null,
    key: string | number,
    replacements?: {},
    localeCode?: string
): string {
    if (!prefix) {
        return `${key}`;
    }
    const text = translate(`${prefix}${key}`, replacements, localeCode);
    const result = htmlParser.parse(text);
    return result != null ? result : text;
}

const _ = translate as typeof translate & {
    get: typeof get;
    match: typeof match;
    parse: typeof parse;
    withPrefix: typeof withPrefix;
};

_.get = get;
_.match = match;
_.parse = parse;
_.withPrefix = withPrefix;

export default _;
