import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import { Image } from '@ihme/common/web/components';

import { styled, withMedia } from '@portal/common/theme';
import { FlexRow } from '@portal/common/components';

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    height: '100vh',
}));

const Navbar = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row',
    height: 70,
    padding: 15,
    background: theme.color.jetBlack,
}));

const Body = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'column',
    flexGrow: 1,
    padding: 20,
    overflow: 'auto',
}));

const StyledImage = styled(Image)({
    boxSizing: 'border-box',
    height: '40',
});

type Props = {};

class GuestLayout extends React.Component<Props> {
    render() {
        const logoSrc = this.props.matchMedia({
            mobile: '/img/logo-with-text.png',
        });

        return (
            <Container>
                <Navbar>
                    <FlexRow align="center">
                        <Link to="/">
                            <StyledImage src={logoSrc} />
                        </Link>
                    </FlexRow>
                </Navbar>
                <Body>{this.props.children}</Body>
            </Container>
        );
    }
}

export default compose(withMedia)(GuestLayout);
