import React from 'react';
import numeral from 'numeral';

import { DataCollectionResource } from '@portal/common/types';
import { CauseOutcome } from '@portal/common/models/data-type';
import { PRIMARY_ENTITY_ID_KEY } from '@portal/common/models/data-key';
import { styled } from '@portal/common/theme';

import config from '../../../config';
import _ from '../../../locale';

import DeathsBlock from './DeathsBlock';
import HAQIndexBlock from './HAQIndexBlock';
import SDIBlock from './SDIBlock';
import HALEBlock from './HALEBlock';

const Container = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 30,
    gap: 20,
    [theme.media.laptop]: {
        flexDirection: 'column',
        minWidth: 250,
    },
}));

type Props = {
    locationId: number;
    organizationId: number;
    dataCollection: DataCollectionResource;
};

const PopulationMetrics = ({ locationId, organizationId, dataCollection }: Props) => {
    const sharedProps = {
        locationId,
        organizationId,
        dataCollection,
    };

    return (
        <Container>
            <DeathsBlock
                {...sharedProps}
                condition={{
                    data_type: CauseOutcome,
                    [PRIMARY_ENTITY_ID_KEY]: config.allCausesCauseId,
                }}
                label={_('simulation_tool_estimated_death_metric')}
                valueFormatter={(value) => numeral(value / 1_000_000).format('0.0') + ' million'}
            />
            <HALEBlock {...sharedProps} />
            <HAQIndexBlock {...sharedProps} />
            <SDIBlock {...sharedProps} />
        </Container>
    );
};

export default PopulationMetrics;
