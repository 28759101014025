import PropTypes from 'prop-types';

export const localeTypes = PropTypes.shape({
    title: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    new_password: PropTypes.string.isRequired,
    new_password_confirmation: PropTypes.string.isRequired,
    required_field: PropTypes.string.isRequired,
    password_requirements: PropTypes.string.isRequired,
    passwords_must_match: PropTypes.string.isRequired,
    change_password_button: PropTypes.string.isRequired,
});

export default {
    title: 'change_password_title',
    password: 'password',
    new_password: 'new_password',
    new_password_confirmation: 'new_password_confirmation',
    required_field: 'error_required_field',
    password_requirements: 'password_requirements',
    passwords_must_match: 'error_passwords_must_match',
    change_password_button: 'change_password_button',
};
