import React from 'react';
import { debounce, flatten, merge, uniq, groupBy } from 'lodash/fp';

import { EChartsInstance } from 'echarts-for-react';
import SaveAsImage from 'echarts/lib/component/toolbox/feature/SaveAsImage';

import echartsTheme from '@ihme/common/theme/echarts-theme';

import { DataGranularityKey, DataRecord, DataResponse, TimeUnitKey } from '@portal/common/types';
import {
    isTimeUnitKey,
    LAT_KEY,
    LNG_KEY,
    LOCATION_ID_KEY,
    VALUE_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import getTimelineConfig from '@portal/common/utility/echarts-helpers/get-time-slider-config';
import { formatValue } from '@portal/common/utility/filters-helpers';
import getTitleAndSubtitle from '@portal/common/utility/echarts-helpers/get-adopted-title-and-subtitle';
import { GHT_LEVEL_COLORS, rgbaToRgb } from '@portal/common/utility/color';
import { EChartsWrapper } from '@portal/common/components';
import { gifIcon } from '@portal/common/theme/icons';

import _ from '../../../../locale';

import coordsMap from './county-coordinates';

type Props = {
    dataResponse: DataResponse | null;
    enableTimelineSlider: boolean;
    isLoading: boolean;
    timeUnitKey: TimeUnitKey;
    valueKey: DataGranularityKey;
    seriesKey: DataGranularityKey;
    initialSliderValue: number | string;
    renderTitle: (params) => '';
    renderSubtitle: (params) => '';
    renderTooltip?: (params) => string;
    saveAsImage?: {
        visible?: boolean;
        filename?: () => void;
        icon?: string;
        title?: string;
        onClick?: () => void;
    };
    saveAsGIF?: {
        visible?: boolean;
        filename?: () => void;
        icon?: string;
        title?: string;
        onClick?: () => void;
    };
    permanentlyEnabledPlayFunction?: boolean;
    echartsMapKey: string;
    getScatterItemColor: (params) => string | number;
};

type State = {
    sliderValue: number | string;
    chartWidth: number;
    windowWidth: number;
    timelinePlayState: boolean;
};

class Chart extends React.PureComponent<Props, State> {
    static defaultProps = {
        timeUnitKey: YEAR_KEY,
        valueKey: VALUE_KEY,
        saveAsImage: {
            visible: false,
            filename: () => 'Chart_ScreenShot',
            icon: '',
            title: '',
        },
        saveAsGIF: {
            visible: false,
        },
    };

    state: State = {
        sliderValue: this.props.initialSliderValue,
        chartWidth: window.innerWidth,
        windowWidth: window.innerWidth,
        timelinePlayState: false,
    };

    theme: any;
    chartInstance = null;

    constructor(props) {
        super(props);

        this.theme = merge(echartsTheme, props.theme);

        this.onWindowResize = debounce(250, this.onWindowResize.bind(this));
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    onChartReady = (instance: EChartsInstance) => {
        this.chartInstance = instance;
    };

    onChartResize = (width) => {
        this.setState({ chartWidth: width });
    };

    onWindowResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    };

    handleSliderChange = (params) => {
        const options = this.getTimelineOptions();
        const sliderValue = options[params.currentIndex];
        this.setState({ sliderValue });
        if (this.props.onSliderChange) {
            this.props.onSliderChange(sliderValue);
        }

        this.props.onEvents?.timelinechanged?.(params);
    };

    handleTimelinePlayChanged = (params) => {
        this.setState({ timelinePlayState: params.playState });

        this.props.onEvents?.timelineplaychanged?.(params);
    };

    getDatasetsAndOptions = () => {
        const {
            enableTimelineSlider,
            dataResponse: { records },
            timeUnitKey,
            seriesKey,
        } = this.props;

        const groupedRecords = (
            enableTimelineSlider ? this.groupRecordsBy([timeUnitKey], records) : [records]
        ).map((timeUnitRecords) => this.groupRecordsBy([seriesKey], timeUnitRecords));

        const datasets = this.getDatasets(groupedRecords);
        const options = this.getChartOptions(groupedRecords, datasets);

        return { datasets, options };
    };

    groupRecordsBy = (keys: DataGranularityKey[], records: DataRecord[]) => {
        if (!records.length) {
            return [];
        }

        const {
            dataResponse: { columns },
        } = this.props;
        const idxs = keys.map((key) => columns.indexOf(key));

        return Object.values(
            groupBy((record) => idxs.map((idx) => record[idx]).join('::'), records)
        );
    };

    getDatasets = (groupedRecords) => {
        const {
            dataResponse: { columns },
        } = this.props;

        const locationIdx = columns.indexOf(LOCATION_ID_KEY);
        const dimensions = [...columns, LNG_KEY, LAT_KEY];

        return flatten(groupedRecords).map((records) => ({
            id: this.getRecordId(records[0]),
            source: records
                .filter((record) => {
                    const locationId = record[locationIdx];
                    return !!coordsMap[locationId];
                })
                .map((record) => {
                    const locationId = record[locationIdx];
                    const { lat, lng } = coordsMap[locationId];
                    return [...record, lng, lat];
                }),
            dimensions,
        }));
    };

    getChartOptions = (groupedRecords, datasets) => {
        if (!groupedRecords || datasets.length === 0) {
            return [];
        }
        const { seriesKey, valueKey, getScatterItemColor } = this.props;
        const { dimensions } = datasets[0];

        return groupedRecords.map((timeUnitRecords) => ({
            series: [
                ...GHT_LEVEL_COLORS.map((color, idx) => {
                    return {
                        ...this.theme.scatter.series,
                        type: 'scatter',
                        name: _(`prob_level_${idx + 1}`),
                        itemStyle: {
                            opacity: 0,
                        },
                    };
                }),
                ...timeUnitRecords.map((seriesRecords) => ({
                    ...this.theme.scatter.series,
                    type: 'scatter',
                    name: seriesRecords[0][dimensions.indexOf(seriesKey)],
                    datasetIndex: this.getDatasetIdxForRecord(seriesRecords[0], datasets),
                    encode: {
                        lng: dimensions.indexOf(LNG_KEY),
                        lat: dimensions.indexOf(LAT_KEY),
                        value: dimensions.indexOf(valueKey),
                    },
                    itemStyle: {
                        ...this.theme.scatter.series.itemStyle,
                        ...(getScatterItemColor && { color: getScatterItemColor }),
                    },
                })),
            ],
        }));
    };

    getRecordId = (record: DataRecord): string => {
        const {
            dataResponse: { columns },
            seriesKey,
            timeUnitKey,
        } = this.props;

        const timeUnitIdx = columns.indexOf(timeUnitKey);
        const seriesKeyIdx = columns.indexOf(seriesKey);

        return `${record[timeUnitIdx]}::${record[seriesKeyIdx]}`;
    };

    getDatasetIdxForRecord = (record: DataRecord, datasets): number => {
        const datasetId = this.getRecordId(record);
        return datasets.findIndex(({ id }) => id === datasetId);
    };

    getTimelineOptions = (): any[] => {
        const {
            dataResponse: { columns, records },
            timeUnitKey,
        } = this.props;
        const timeUnitIdx = columns.indexOf(timeUnitKey);
        return uniq(records.map((record) => record[timeUnitIdx])).sort();
    };

    getRenderProps = () => {
        const { filtersValues: filters, timeUnitKey } = this.props;
        const { sliderValue } = this.state;

        return { filters, timeUnitValue: formatValue(timeUnitKey, sliderValue, _) };
    };

    getToolboxFeatures = () => {
        const features: any = {
            saveAsImage: { show: false },
        };

        const { saveAsGIF, saveAsImage } = this.props;

        if (saveAsGIF?.visible) {
            features.mySaveAsGIF = this.getSaveAsGIFConfig();
        }
        if (saveAsImage && saveAsImage.visible) {
            features.mySaveAsImage = this.getSaveAsImageConfig();
        }

        return features;
    };

    getSaveAsGIFConfig = () => {
        const { xAxisKey, saveAsGIF } = this.props;

        const {
            visible,
            filename = () => 'Chart_Animation',
            icon,
            title = _('chart_save_as_gif_button_label'),
            enabled,
            onClick,
        } = saveAsGIF;

        if (!(visible && this.getTimelineOptions()?.length > 1 && !isTimeUnitKey(xAxisKey))) {
            return { visible: false };
        }

        return {
            icon: icon || gifIcon,
            title: enabled ? title : '',
            onclick: (...params) => {
                enabled && onClick?.(...params);
            },
            ...(!enabled && {
                iconStyle: {
                    color: '#A2A3A4',
                    emphasis: {
                        color: '#A2A3A4',
                    },
                },
            }),
        };
    };

    // @todo: move into echarts-helpers
    getSaveAsImageConfig = () => {
        const {
            visible,
            enabled,
            filename = () => 'Chart_ScreenShot',
            icon,
            title = _('chart_save_as_image_button_label'),
            onClick,
        } = this.props.saveAsImage;

        if (!visible) {
            return undefined;
        }

        const config = {
            excludeComponents: ['toolbox', 'graphic'],
            pixelRatio: 2,
            title,
            name: filename(this.getRenderProps()),
            onclick: function (...params) {
                if (!enabled) {
                    return;
                }
                SaveAsImage.prototype.onclick.call(this, ...params);
                onClick && onClick();
            },
            ...(!enabled && {
                iconStyle: {
                    color: '#A2A3A4',
                    emphasis: {
                        color: '#A2A3A4',
                    },
                },
            }),
        };

        return icon ? { ...config, icon } : config;
    };

    addSaveAsImageOverlap = (): object | null => {
        const { visible, enabled } = this.props.saveAsImage;

        return visible && !enabled
            ? {
                  type: 'rect',
                  cursor: 'default',
                  z: 1000,
                  top: 20,
                  right: 30,
                  shape: {
                      width: 30,
                      height: 40,
                  },
                  style: {
                      fill: 'rgba(100,100,100,0)',
                  },
              }
            : null;
    };

    getLegendOption = () => ({
        orient: 'vertical',
        top: 0,
        right: 0,
        show: true,
        padding: [90, 48, 20, 10],
        itemWidth: 18,
        itemHeight: 6,
        icon: 'circle',
        backgroundColor: 'rgba(255, 255, 255)',
        data: GHT_LEVEL_COLORS.map(([r, g, b], idx) => ({
            name: _(`prob_level_${idx + 1}`),
            itemStyle: {
                color: rgbaToRgb(r, g, b, 1),
                opacity: 1,
            },
        })),
    });

    getChartOption = (datasets, options) => {
        const {
            enableTimelineSlider,
            isLoading,
            renderTitle,
            renderSubtitle,
            renderTooltip,
            timeUnitKey,
            permanentlyEnabledPlayFunction,
            echartsMapKey,
        } = this.props;
        const { chartWidth, sliderValue, timelinePlayState } = this.state;

        const isDataEmpty = options.length === 0;

        return {
            baseOption: {
                dataset: datasets,
                geo: {
                    id: 0,
                    show: true,
                    map: echartsMapKey,
                    roam: 'move',
                    height: '90%',
                    label: {
                        formatter: (data) => _(`location_${data.name}`),
                        emphasis: {
                            ...this.theme.textStyle,
                            color: this.theme.map.locationLabelColor,
                            fontSize: 12,
                        },
                    },
                    itemStyle: {
                        areaColor: this.theme.map.locationDefaultColor,
                        borderWidth: 1,
                        borderColor: this.theme.map.locationBorderColor,
                        emphasis: {
                            areaColor: this.theme.map.locationSelectedColor,
                        },
                    },
                    tooltip: {
                        show: true,
                        formatter: renderTooltip,
                        alwaysShowContent: false,
                        backgroundColor: 'white',
                        triggerOn: 'none',
                    },
                    ...this.theme.map,
                },
                title: [
                    getTitleAndSubtitle(
                        renderTitle(this.getRenderProps()),
                        renderSubtitle(this.getRenderProps()),
                        chartWidth,
                        document.getElementById('canvasFontSizer'),
                        this.theme.title
                    ),
                    {
                        show: isDataEmpty && !isLoading,
                        text: _('chart_error_no_data'),
                        top: '45%',
                        textStyle: {
                            ...echartsTheme.textStyle,
                            fontSize: 26,
                        },
                    },
                    {
                        show: true,
                        text: '↓ Highest to Lowest',
                        right: 0,
                        top: 64,
                        textStyle: {
                            ...echartsTheme.textStyle,
                            fontSize: 11,
                        },
                        z: 1000,
                    },
                ],
                timeline: {
                    ...getTimelineConfig(
                        enableTimelineSlider ? timeUnitKey : null,
                        sliderValue,
                        this.getTimelineOptions(),
                        this.theme,
                        chartWidth,
                        _,
                        null,
                        permanentlyEnabledPlayFunction
                    ),
                    autoPlay: timelinePlayState,
                },
                tooltip: {
                    show: true,
                    formatter: renderTooltip,
                    alwaysShowContent: true,
                    backgroundColor: 'white',
                    enterable: true,
                    triggerOn: 'mousemove|click',
                    transitionDuration: 0,
                    hideDelay: 0,
                },
                toolbox: {
                    top: 0,
                    right: 30,
                    z: 10,
                    ...this.theme.toolbox,
                    feature: this.getToolboxFeatures(),
                },
                textStyle: this.theme.textStyle,
                legend: this.getLegendOption(),
                color: ['#1FBA59', '#FABC4D', '#E01F27'],
                graphic: {
                    elements: [this.addSaveAsImageOverlap()],
                },
            },
            options,
        };
    };

    getEChartsEvents = () => ({
        ...this.props.onEvents,
        timelinechanged: this.handleSliderChange,
        timelineplaychanged: this.handleTimelinePlayChanged,
    });

    render() {
        const { dataResponse, isLoading } = this.props;

        const { datasets, options } = this.getDatasetsAndOptions();

        if (!(dataResponse && dataResponse.columns && dataResponse.columns.length)) {
            return null;
        }

        return (
            // chart-wrapper is required for resize in PDF export
            <div id="chart-wrapper">
                <EChartsWrapper
                    style={{
                        width: '100%',
                        height: 600,
                    }}
                    onChartReady={this.onChartReady}
                    onChartResize={this.onChartResize}
                    option={this.getChartOption(datasets, options)}
                    onEvents={this.getEChartsEvents()}
                    showLoading={isLoading}
                    loadingOption={this.theme.loading}
                    theme={this.theme}
                />
            </div>
        );
    }
}

export default Chart;
