import { RootEpic } from 'MyTypes';
import { from, of } from 'rxjs';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getAnnouncementsAsync, getSidebarLinksAsync } from './actions';

export const loadAnnouncements: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getAnnouncementsAsync.request])),
        switchMap((action) =>
            from(
                api.organization.getAnnouncements({
                    page: 1,
                    per_page: 1_000_000,
                    order_by: 'created',
                    order_direction: 'DESC',
                })
            ).pipe(
                map((res) => getAnnouncementsAsync.success(res)),
                catchError((message: string) => of(getAnnouncementsAsync.failure(message)))
            )
        )
    );

export const loadSidebarLinks: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf([getSidebarLinksAsync.request])),
        switchMap((action) =>
            from(
                api.organization.getSidebarLinks({
                    page: 1,
                    per_page: 1000,
                    order_by: 'sort_order',
                    order_direction: 'ASC',
                })
            ).pipe(
                map((res) => getSidebarLinksAsync.success(res)),
                catchError((message: string) => of(getSidebarLinksAsync.failure(message)))
            )
        )
    );
