import {
    compose,
    flatten,
    intersection,
    isEmpty,
    keyBy,
    mapValues,
    omit,
    pick,
    uniq,
    uniqBy,
} from 'lodash/fp';
import { RootState } from 'MyTypes';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';

import { getLocationsForLevel } from '@portal/common/components';
import {
    AgeGroupEntity,
    CauseEntity,
    ChartType,
    ConditionDetailNotesRecord,
    ConditionRestrictionRecord,
    DataCondition,
    DataFilters,
    DataGranularity,
    DataGranularityKey,
    DataKey,
    DataResponse,
    DataTableDisplayState,
    DataToolConfig,
    DataType,
    Entity,
    LocationEntity,
} from '@portal/common/types';
import {
    TYPE_ARROW_CHART,
    TYPE_DATA_TABLE_CHART,
    TYPE_LINE_CHART,
    TYPE_MAP_CHART,
    TYPE_SCATTER_MAP_CHART,
    TYPE_TREEMAP_CHART,
} from '@portal/common/models/chart-type';
import {
    DATA_TYPE_KEY,
    FORECAST_SCENARIO_ID_KEY,
    isTimeUnitKey,
    LOCATION_ID_KEY,
    MAP_DETAIL_LEVEL_ID_KEY,
    MAP_TYPE_ID_KEY,
    METRIC_ID_KEY,
    PRIMARY_ENTITY_ID_KEY,
    PRIMARY_ENTITY_ID_LOCALIZATION_KEY,
    PRIMARY_ENTITY_KEY,
    ROUND_ID_KEY,
} from '@portal/common/models/data-key';
import {
    DALYs,
    Deaths,
    Incidence,
    Prevalence,
    YLDs,
    YLLs,
} from '@portal/common/models/measure-types';
import {
    AllDataTypes,
    CauseOutcome,
    COMPATIBLE_DATA_TYPE_GROUPS,
    getDataTypePrimaryEntityKey,
    HealthThreatsMetricsRecords,
    PopulationRecords,
} from '@portal/common/models/data-type';
import {
    mapValueToCondition,
    mergeAndSortDataResponses,
} from '@portal/common/utility/chart-data-helpers';
import {
    getConditionsDataTypes,
    hasErrorInRefinementFilters,
} from '@portal/common/utility/filters-helpers';
import { getDataTypeConfig } from '@portal/common/models/data-type-config';
import { DataProjectId } from '@portal/common/models/data-project';
import { getAvailableChartTypes } from '@portal/common/models/organization';

import {
    fillMissingFiltersWithDefaults,
    removeFiltersNotInGranularity,
} from '../../components/DataExplorer/utils';
import { getSelectedDatasetsConfiguration } from '../../models/data-explorer-config';
import { DATA_TOOL_CONFIG_BY_TOOL_PATH, getDataProjectIdsForTool } from '../../models/data-tool';
import { getMergedDataCollectionGranularity } from '../../utility/data-loader';
import { getOrganization, getSelectedDataTool } from '../root-reducer';
import { getSelectedDataCollectionForDataTool } from '../user-settings/selectors';
import { getLocationChildrenById } from '../entity-hierarchies/selectors';

import { getDatasetConditions } from '../../models/dataset';
import { conditionComparisonFunc } from '../../models/data-condition';
import config from '../../config';
import { SingleAgeReportTypes } from '../../types';
import { mergeSingleYearAgeGranularity } from '../../utility/merge-single-age-granularity';
import { getTimeUnitsCount } from '../../utility/data/calculate-combined-filters-amount';
import { getLicensedConditionChildren, getLicensedConditionsByLevel } from './utils';

export const getAgeGroups = (state: RootState): AgeGroupEntity[] | null =>
    state.dataExplorer.ageGroups;

export const getAgeGroupsOrder = createSelector(getAgeGroups, (ageGroups) => {
    if (ageGroups == null) {
        return null;
    }

    return compose(mapValues('order'), keyBy('id'))(ageGroups);
});

const createMergedDataResponsesSelector = createSelectorCreator(
    defaultMemoize,
    (state, prevState) => {
        const hashFn = (...args) => args.reduce((acc, val) => acc + '-' + JSON.stringify(val), '');
        const propsToCompare = [
            'dataResponsesByType',
            'conditions',
            'dataTypes',
            'refinementFilters',
            'granularity',
            'samplePopulationSize',
        ];

        const propsHash = hashFn(pick(propsToCompare, state));
        const prevPropsHash = hashFn(pick(propsToCompare, prevState));
        return propsHash === prevPropsHash;
    }
);

export const getMergedDataResponses = createMergedDataResponsesSelector(
    (state: RootState) => ({ dataResponsesByType: getDataResponsesByType(state) }),
    ({ dataResponsesByType }): DataResponse | null => mergeAndSortDataResponses(dataResponsesByType)
);

export const getDataRecordsAmount = (state: RootState): number => {
    const responses = getMergedDataResponses(state);
    return responses?.records?.length || 0;
};

export const getMergedChartDataResponses = createMergedDataResponsesSelector(
    (state: RootState) => ({ dataResponsesByType: getChartDataResponsesByType(state) }),
    ({ dataResponsesByType }): DataResponse | null => mergeAndSortDataResponses(dataResponsesByType)
);

export const getDataToolConfig = (state: RootState): DataToolConfig => {
    const selectedDataTool = getSelectedDataTool(state);
    const selectedDataProjectId = getSelectedDataProject(state);
    const dataType = getSelectedConditionsDataTypes(state)?.[0];
    const dataToolConfig = DATA_TOOL_CONFIG_BY_TOOL_PATH[selectedDataTool] || {};
    const dataProjectConfig = selectedDataProjectId
        ? DATA_TOOL_CONFIG_BY_TOOL_PATH[selectedDataTool]?.overwritesByDataProjectId?.[
              selectedDataProjectId
          ]
        : {};
    let mergedConfig: DataToolConfig = { ...dataToolConfig, ...dataProjectConfig };
    const dataTypeConfig = dataType ? mergedConfig.overwritesByDataType?.[dataType] : {};
    mergedConfig = { ...mergedConfig, ...dataTypeConfig };
    return omit(['overwritesByDataProjectId', 'overwritesByDataType'], mergedConfig);
};

export const getSelectedDataProject = (state: RootState): number | undefined => {
    const dataCollection = getSelectedDataCollectionForDataTool(state);
    return dataCollection?.data_project_id;
};

export const getDataToolSupportedCharts = (state: RootState): ChartType[] => {
    const dataToolConfig = getDataToolConfig(state);
    const selectedDataProject = getSelectedDataProject(state);
    const granularity = getSelectedConditionsDataTypesGranularity(state);
    const organization = getOrganization(state);
    const availableChartTypes = getAvailableChartTypes(organization);

    let supportedCharts: ChartType[] = [];
    if (dataToolConfig?.supportedCharts) {
        supportedCharts = dataToolConfig.supportedCharts;
    }

    if (selectedDataProject === DataProjectId.RACE_ETHNICITY) {
        const dataset = getSelectedDatasetsConfig(state);
        const timeUnitKey = dataset?.time_unit_field;
        if (timeUnitKey && granularity?.[timeUnitKey]?.length > 1) {
            supportedCharts = uniq([TYPE_LINE_CHART, ...supportedCharts]);
        }
    }

    if (supportedCharts.includes(TYPE_DATA_TABLE_CHART)) {
        availableChartTypes.push(TYPE_DATA_TABLE_CHART);
    }
    if (availableChartTypes.includes(TYPE_MAP_CHART)) {
        availableChartTypes.push(TYPE_SCATTER_MAP_CHART);
    }

    return intersection(availableChartTypes, supportedCharts);
};

export const getSamplePopulationSize = (state: RootState): number =>
    state.dataExplorer.samplePopulationSize;

export const getRoundId = (state: RootState) => {
    const selectedRefinementFilters =
        getSelectedRefinementFiltersWithFallbackToDefaultFilters(state);
    const roundId = selectedRefinementFilters
        ? selectedRefinementFilters[ROUND_ID_KEY]
        : removeFiltersNotInGranularity()[ROUND_ID_KEY];

    // Remove parseInt once selected filters refactored to reflect granularity option types
    return parseInt(roundId);
};

export const getSelectedChartType = (state: RootState) => state.dataExplorer.selectedChartType;

export const getDefaultChartType = (state: RootState) => {
    const config = getDataToolConfig(state);
    if (config && config.supportedCharts && config.supportedCharts.length) {
        return config.supportedCharts[0];
    }
    return TYPE_LINE_CHART;
};

export const getSelectedDataType = (state: RootState) => state.dataExplorer.selectedDataType;

export const getDataCollections = (state: RootState) => state.dataExplorer.dataCollections;

export const getInitializationKey = (state: RootState) => state.dataExplorer.initializationKey;

export const getDataToolDataCollections = (state: RootState) => {
    const allCollections = getDataCollections(state);
    const selectedDataTool = getSelectedDataTool(state);
    const projectIds = getDataProjectIdsForTool(selectedDataTool);
    if (!(allCollections && allCollections.length && projectIds && projectIds.length)) {
        return allCollections;
    }
    return allCollections.filter((collection) => projectIds.includes(collection.data_project_id));
};
export const getCurrentDataProjectCollections = (state: RootState) => {
    const { dataCollections } = state.dataExplorer;
    const { selectedDataProjectId } = state.userSettings;

    return selectedDataProjectId && dataCollections
        ? dataCollections.filter(({ data_project_id }) => data_project_id === selectedDataProjectId)
        : dataCollections;
};

export const getDataResponsesByType = (state: RootState) => state.dataExplorer.dataResponsesByType;

export const getChartDataResponsesByType = (state: RootState) =>
    state.dataExplorer.chartDataResponsesByType;

export const getChartSelectedRefinementFilters = (state: RootState) =>
    state.dataExplorer.chartFilters;

export const isDataLoading = (state: RootState) => state.dataExplorer.isDataLoading;

export const isChartDataLoading = (state: RootState) => state.dataExplorer.isChartDataLoading;

export const getMergedData = (state: RootState) => {
    // TODO
};

export const getMergedDataWithDataTypeColumn = (state: RootState) => {
    // TODO
};

export const getConditionDetailNotes = (state: RootState): ConditionDetailNotesRecord[] | null =>
    state.dataExplorer.conditionDetailNotes;

export const getSelectedConditions = (state: RootState): DataCondition[] =>
    state.dataExplorer.selectedConditions;

export const getSelectedConditionsDataTypes = (state: RootState): DataType[] | null =>
    isEmpty(state.dataExplorer.selectedConditions)
        ? null
        : getConditionsDataTypes(state.dataExplorer.selectedConditions);

export const getSelectedConditionsPrimaryEntityFilters = (state: RootState): DataGranularityKey[] =>
    (getSelectedDataCollectionForDataTool(state)?.datasets || [])
        .filter(({ data_type }) =>
            (getSelectedConditionsDataTypes(state) || []).includes(data_type)
        )
        .map(({ primary_entity_id_filter }) => primary_entity_id_filter);

export const getSelectedConditionsCompatibleDataTypes = (state: RootState): DataType[] | null => {
    const selectedConditionsDataTypes = getSelectedConditionsDataTypes(state);
    if (selectedConditionsDataTypes == null) {
        return null;
    }

    const compatibleDataTypes = COMPATIBLE_DATA_TYPE_GROUPS.find(
        (dataTypes) => !isEmpty(intersection(selectedConditionsDataTypes, dataTypes))
    ) as DataType[];

    if (compatibleDataTypes != null) {
        return compatibleDataTypes;
    }

    return selectedConditionsDataTypes;
};

export const getSelectedConditionsDataTypesDefaultFilters = (state: RootState) => {
    const selectedConditionsDataTypesGranularity = getSelectedConditionsDataTypesGranularity(state);
    const datasetConfig = getSelectedDatasetsConfig(state);
    const defaultFilters = removeFiltersNotInGranularity(
        datasetConfig.defaultFilters,
        selectedConditionsDataTypesGranularity
    );

    const filtersToSkip = getDataToolConfig(state).filtersSkipDefaultSelection || [];

    return fillMissingFiltersWithDefaults(
        defaultFilters,
        selectedConditionsDataTypesGranularity,
        filtersToSkip
    );
};

// used for initialization default selectedRefinedGranularity
export const getDefaultFiltersByConditions = (conditions: DataCondition[]) => (state: RootState) =>
    fillMissingFiltersWithDefaults(
        removeFiltersNotInGranularity(
            getSelectedDatasetsConfigByConditions(conditions)(state).defaultFilters,
            getMergedDataCollectionGranularity(
                getSelectedDataCollectionForDataTool(state)!,
                uniq(conditions.map((i) => i.data_type))
            )
        ),
        getSelectedConditionsDataTypesGranularity(state),
        getDataToolConfig(state).filtersSkipDefaultSelection || []
    );

export const getSelectedConditionsDataTypesGranularity = (state: RootState) =>
    getMergedDataCollectionGranularity(
        getSelectedDataCollectionForDataTool(state),
        getSelectedConditionsDataTypes(state)
    );

export const getSelectedConditionsRefinedGranularity = (state: RootState) =>
    state.dataExplorer.selectedConditionsRefinedGranularity ||
    getSelectedConditionsDataTypesGranularity(state);

export const getSelectedRefinementFilters = (state: RootState): DataFilters | null =>
    state.dataExplorer.selectedRefinementFilters;

export const getSingleYearAgeReportGranularity = (
    state: RootState
): Record<SingleAgeReportTypes, DataGranularity> | null => {
    const selectedRefinementFilters = getSelectedRefinementFilters(state);
    const selectedMetrics =
        (selectedRefinementFilters?.[METRIC_ID_KEY] as number[]) || [].map((id) => id.toString());
    if (isEmpty(selectedMetrics)) {
        return null;
    }

    return {
        [SingleAgeReportTypes.Normal]: pick(
            selectedMetrics,
            state.dataExplorer.singleYearAgeGranularity
        ),
        [SingleAgeReportTypes.Aggregated]: pick(
            selectedMetrics,
            state.dataExplorer.aggregatedSingleYearAgeGranularity
        ),
    };
};

export const getMergedSingleYearAgeReportGranularity = (state: RootState): DataGranularity | null =>
    mergeSingleYearAgeGranularity(getSingleYearAgeReportGranularity(state));

export const getSelectedRefinementFiltersWithFallbackToDefaultFilters = (
    state: RootState
): DataFilters => {
    const { selectedRefinementFilters } = state.dataExplorer;
    const defaultFilters = getSelectedConditionsDataTypesDefaultFilters(state);
    const { filtersSkipDefaultSelection } = getDataToolConfig(state);

    return mergeFiltersWithFallbackToDefaultFilters(
        defaultFilters,
        selectedRefinementFilters,
        filtersSkipDefaultSelection
    );
};

export const mergeFiltersWithFallbackToDefaultFilters = (
    defaultFilters,
    selectedRefinementFilters,
    filtersSkipDefaultSelection
) => {
    const filterKeys = uniq(Object.keys({ ...defaultFilters, ...selectedRefinementFilters }));

    const filters = {};
    filterKeys.forEach((key) => {
        filters[key] = (selectedRefinementFilters && selectedRefinementFilters[key]) || null;

        if (filters[key] == null && !(filtersSkipDefaultSelection || []).includes(key)) {
            filters[key] = defaultFilters[key];
        }
    });

    return filters;
};

export const getNormalizedSelectedRefinementFilters = (state: RootState): DataFilters => {
    const filters = getSelectedRefinementFiltersWithFallbackToDefaultFilters(state);
    const granularity = getSelectedConditionsRefinedGranularity(state);

    const normalizedFilters: DataFilters = {};

    Object.entries(granularity).forEach(([key, value]) => {
        normalizedFilters[key] = intersection(value as number[], filters?.[key] || []);
    });

    return normalizedFilters;
};

export const getSelectedRefinementFiltersWithoutPrimaryEntities = (
    state: RootState
): DataFilters => {
    const conditions = getSelectedConditions(state);
    const dataTypes = uniq(conditions.map(({ data_type }) => data_type));

    const primaryEntityKeys = dataTypes.map((dataType) => getDataTypeConfig()[dataType].filterKey);

    const refinementFilters = { ...getSelectedRefinementFilters(state) };
    primaryEntityKeys.forEach((key) => {
        delete refinementFilters[key];
    });

    return refinementFilters;
};

export const getVisibleRefinementFilters = (state: RootState) => {
    const visibleFilters = state.dataExplorer.visibleRefinementFilters;
    const granularityKeys = Object.keys(getSelectedConditionsDataTypesGranularity(state));

    return intersection(visibleFilters, granularityKeys);
};

export const getMultipleSelectedRefinementFilterKeys = (state: RootState) => {
    const filters = getSelectedRefinementFiltersWithFallbackToDefaultFilters(state);
    const chartFilters = getChartDataRequestFilters(state);

    const isMultiple = (value) => (value || '').toString().includes(',');

    const multipleKeys = Object.entries(filters)
        .filter(([key, value]) => isMultiple(value))
        .map(([key]) => key);

    Object.entries(chartFilters)
        .filter(([key, value]) => isMultiple(value))
        .forEach(([key]) => multipleKeys.push(key));

    return uniq(multipleKeys);
};

export const getMaxValuesSelectedFilter = (state: RootState) => {
    const refinementFilters = getSelectedRefinementFiltersWithFallbackToDefaultFilters(state);
    const chartFilters = getChartDataRequestFilters(state);
    const filters = { ...refinementFilters, ...chartFilters };
    const conditions = getSelectedConditions(state);

    const filterValuesAmountMap = {
        [PRIMARY_ENTITY_ID_LOCALIZATION_KEY]: conditions.length,
    };

    const getValuesAmount = (value) => (value || '').toString().split(',').length;

    Object.entries(filters).forEach(([key, value]) => {
        if (key !== FORECAST_SCENARIO_ID_KEY) {
            filterValuesAmountMap[key] = getValuesAmount(value);
        }
    });

    if (Object.values(filterValuesAmountMap).filter((amount) => amount > 1).length < 2) {
        return PRIMARY_ENTITY_ID_LOCALIZATION_KEY;
    }

    const maxValuesAmount = Math.max(...Object.values(filterValuesAmountMap));
    const maxValuesFilters = Object.entries(filterValuesAmountMap)
        .filter(([key]) => !isTimeUnitKey(key))
        .filter(([key, value]) => value === maxValuesAmount)
        .map(([key, value]) => key);

    if (
        isEmpty(maxValuesFilters) ||
        maxValuesFilters.includes(PRIMARY_ENTITY_ID_LOCALIZATION_KEY)
    ) {
        return PRIMARY_ENTITY_ID_LOCALIZATION_KEY;
    }

    return maxValuesFilters[0];
};

export const getStaticForecastTimeStart = (state: RootState): number => {
    const defaultValue = 0;

    const selectedDatasetConfig = getSelectedDatasetsConfig(state);
    if (!selectedDatasetConfig) {
        return defaultValue;
    }

    const { static_forecast_start_times_map } = selectedDatasetConfig;
    if (!static_forecast_start_times_map) {
        return defaultValue;
    }
    const roundId = getRoundId(state);

    return static_forecast_start_times_map[roundId];
};

export const isForecastingData = (state: RootState): boolean => {
    const selectedDatasetConfig = getSelectedDatasetsConfig(state);
    if (!selectedDatasetConfig) {
        return false;
    }

    const { rounds_with_forecast_data } = selectedDatasetConfig;
    if (!rounds_with_forecast_data) {
        return false;
    }
    const roundId = getRoundId(state);

    return rounds_with_forecast_data.includes(roundId);
};

export const isPopulationData = (state: RootState): boolean =>
    isDataIncludesDataType(state, PopulationRecords);

export const isGHTData = (state: RootState): boolean =>
    isDataIncludesDataType(state, HealthThreatsMetricsRecords);

export const isDataIncludesDataType = (state: RootState, dataType: DataType): boolean => {
    const conditions = getSelectedConditions(state);
    const dataTypes = conditions.map(({ data_type }) => data_type);
    return dataTypes.includes(dataType);
};

export const getSelectedTimeUnits = (state: RootState): number[] | string[] => {
    const granularity = getSelectedConditionsDataTypesGranularity(state);
    const refinedGranularity = getSelectedConditionsRefinedGranularity(state);
    const datasetsConfig = getSelectedDatasetsConfig(state);
    const timeUnitField = datasetsConfig.time_unit_field;
    if (!timeUnitField) {
        return [];
    }

    const selectedRefinementTimeUnits =
        getSelectedRefinementFiltersWithFallbackToDefaultFilters(state)[timeUnitField];
    const availableTimeUnits = refinedGranularity
        ? refinedGranularity[timeUnitField]
        : granularity[timeUnitField];
    const selectedTimeUnits = selectedRefinementTimeUnits
        ? selectedRefinementTimeUnits.split(',')
        : availableTimeUnits;

    return typeof availableTimeUnits[0] === 'number'
        ? selectedTimeUnits.map(Number)
        : selectedTimeUnits;
};

export const getCdnRestrictions = createSelector(getConditionDetailNotes, (cdnByCondition) => {
    if (cdnByCondition == null) {
        return null;
    }

    const restrictionsByCondition = cdnByCondition.map((condition) => {
        const result: ConditionRestrictionRecord = {} as any;

        condition.metaRecords.forEach((record) => {
            switch (record.key) {
                case 'data_type':
                    result.data_type = record.value as string;
                    break;
                case 'primary_entity_id':
                    result.primary_entity_id = record.value as number;
                    break;
                default:
                    break;
            }
        });

        condition.records.forEach((record) => {
            switch (record.key) {
                case 'non_fatal_data_source_link':
                case 'fatal_data_source_link':
                    result[record.key] = record.value as string;
                    break;

                case 'fatal_female_age_group_restriction_ids':
                case 'non_fatal_female_age_group_restriction_ids':
                case 'fatal_male_age_group_restriction_ids':
                case 'non_fatal_male_age_group_restriction_ids':
                case 'fatal_location_restriction_ids':
                case 'non_fatal_location_restriction_ids':
                    result[record.key] = record.value as number[];
                    break;

                default:
                    break;
            }
        });

        return result;
    });

    return restrictionsByCondition;
});

export const getMultipleValuesSelectedRefinementFilters = (state: RootState): DataFilters | {} => {
    const multipleValuesFilters = {};
    const conditions = getSelectedConditions(state);
    if (conditions && conditions.length > 1) {
        multipleValuesFilters[PRIMARY_ENTITY_ID_KEY] = conditions;
    }

    const refinedGranularity = getSelectedConditionsRefinedGranularity(state) || {};
    const isMulti = ([key, value]): boolean => {
        const granularityValues = refinedGranularity[key] || [];
        return intersection(value, granularityValues).length > 1;
    };

    Object.entries(getSelectedRefinementFiltersWithFallbackToDefaultFilters(state))
        .filter(isMulti)
        .forEach(([key, value]) => {
            multipleValuesFilters[key] = value;
        });

    return multipleValuesFilters;
};

export const hasErrorInRefinements = (state: RootState): boolean => {
    const refinedGranularity = getSelectedConditionsRefinedGranularity(state);
    const selectedConditions = getSelectedConditions(state);
    const selectedRefinementFilters =
        getSelectedRefinementFiltersWithFallbackToDefaultFilters(state);

    return hasErrorInRefinementFilters({
        granularity: refinedGranularity,
        selectedConditions,
        selectedRefinementFilters,
    });
};

export const hasFiltersChangedSinceLastRequest = (state: RootState): boolean => {
    return state.dataExplorer.filtersChangedSinceLastRequest;
};

export const getChartSelectedFiltersGranularity = (state: RootState): DataFilters | {} => {
    const multipleValuesFilters = {};
    const conditions = getSelectedConditions(state);
    if (conditions && conditions.length > 1) {
        multipleValuesFilters[PRIMARY_ENTITY_ID_KEY] = conditions;
    }
    const isMulti = ([key, value]): boolean => (value || []).length > 1;

    Object.entries(getSelectedRefinementFiltersWithFallbackToDefaultFilters(state))
        .map(([key, value]) => [key, value])
        .filter(isMulti)
        .forEach(([key, value]) => {
            multipleValuesFilters[key] = value;
        });

    return multipleValuesFilters;
};

export const hasCdnRestrictions = createSelector(
    [getSelectedRefinementFiltersWithFallbackToDefaultFilters, getCdnRestrictions],
    (selectedRefinementFilters, cdnRestrictions) => {
        const FATAL_MEASURES = [Deaths, YLLs];
        const NON_FATAL_MEASURES = [YLDs, Incidence, Prevalence];
        const GENDER = {
            Male: 1,
            Female: 2,
            Both: 3,
        };

        /**
         * null checks
         */
        if (selectedRefinementFilters == null || cdnRestrictions == null) {
            return false;
        }

        /**
         * destructuring data
         */
        const { measure_id, gender_id, age_group_id, location_id } = selectedRefinementFilters;

        const selectedMeasureIds = measure_id || [];
        const selectedGenderIds = gender_id || [];
        const selectedAgeGroupIds = age_group_id || [];
        const selectedLocationIds = location_id || [];

        /**
         * conditions helpers
         */
        const hasFatalMeasures =
            selectedMeasureIds.length > 0 &&
            selectedMeasureIds.some((id) => FATAL_MEASURES.includes(id));
        const hasNonFatalMeasures =
            selectedMeasureIds.length > 0 &&
            selectedMeasureIds.some((id) => NON_FATAL_MEASURES.includes(id));
        const hasDalyMeasure =
            selectedMeasureIds.length > 0 && selectedMeasureIds.some((id) => [DALYs].includes(id));

        const hasMaleGender =
            selectedGenderIds.length > 0 &&
            selectedGenderIds.some((id) => [GENDER.Male].includes(id));
        const hasFemaleGender =
            selectedGenderIds.length > 0 &&
            selectedGenderIds.some((id) => [GENDER.Female].includes(id));
        const hasBothGender =
            selectedGenderIds.length > 0 &&
            selectedGenderIds.some((id) => [GENDER.Both].includes(id));

        /**
         * generic helpers
         */
        const somePresentIn = (selectedFilterIds) => (restrictedIds) =>
            selectedFilterIds.some((id) => restrictedIds.includes(id));

        /**
         * concrete helpers
         */
        const anySelectedLocationsPresentIn = somePresentIn(selectedLocationIds);
        const anySelectedAgeGroupsPresentIn = somePresentIn(selectedAgeGroupIds);

        // restriction calculation => restriction is true when at least one restriction of any filter is fulfilled
        const foundAtLeastOneRestriction = cdnRestrictions.some((conditionRestriction) => {
            let {
                fatal_female_age_group_restriction_ids,
                fatal_location_restriction_ids,
                fatal_male_age_group_restriction_ids,
                non_fatal_location_restriction_ids,
                non_fatal_female_age_group_restriction_ids,
                non_fatal_male_age_group_restriction_ids,
            } = conditionRestriction;

            fatal_female_age_group_restriction_ids = fatal_female_age_group_restriction_ids || [];
            fatal_location_restriction_ids = fatal_location_restriction_ids || [];
            fatal_male_age_group_restriction_ids = fatal_male_age_group_restriction_ids || [];
            non_fatal_location_restriction_ids = non_fatal_location_restriction_ids || [];
            non_fatal_female_age_group_restriction_ids =
                non_fatal_female_age_group_restriction_ids || [];
            non_fatal_male_age_group_restriction_ids =
                non_fatal_male_age_group_restriction_ids || [];

            if (hasNonFatalMeasures) {
                if (
                    hasFemaleGender &&
                    anySelectedAgeGroupsPresentIn(non_fatal_female_age_group_restriction_ids)
                ) {
                    return true;
                } else if (
                    hasMaleGender &&
                    anySelectedAgeGroupsPresentIn(non_fatal_male_age_group_restriction_ids)
                ) {
                    return true;
                } else if (
                    hasBothGender &&
                    anySelectedAgeGroupsPresentIn(
                        intersection(
                            non_fatal_female_age_group_restriction_ids,
                            non_fatal_male_age_group_restriction_ids
                        )
                    )
                ) {
                    return true;
                }
                if (anySelectedLocationsPresentIn(non_fatal_location_restriction_ids)) {
                    return true;
                }
            }
            if (hasFatalMeasures) {
                if (
                    hasFemaleGender &&
                    anySelectedAgeGroupsPresentIn(fatal_female_age_group_restriction_ids)
                ) {
                    return true;
                } else if (
                    hasMaleGender &&
                    anySelectedAgeGroupsPresentIn(fatal_male_age_group_restriction_ids)
                ) {
                    return true;
                } else if (
                    hasBothGender &&
                    anySelectedAgeGroupsPresentIn(
                        intersection(
                            fatal_female_age_group_restriction_ids,
                            fatal_male_age_group_restriction_ids
                        )
                    )
                ) {
                    return true;
                }
                if (anySelectedLocationsPresentIn(fatal_location_restriction_ids)) {
                    return true;
                }
            }
            if (hasDalyMeasure) {
                if (
                    hasFemaleGender &&
                    anySelectedAgeGroupsPresentIn(
                        intersection(
                            fatal_female_age_group_restriction_ids,
                            non_fatal_female_age_group_restriction_ids
                        )
                    )
                ) {
                    return true;
                } else if (
                    hasMaleGender &&
                    anySelectedAgeGroupsPresentIn(
                        intersection(
                            fatal_male_age_group_restriction_ids,
                            non_fatal_male_age_group_restriction_ids
                        )
                    )
                ) {
                    return true;
                } else if (
                    hasBothGender &&
                    anySelectedAgeGroupsPresentIn(
                        intersection(
                            fatal_female_age_group_restriction_ids,
                            fatal_male_age_group_restriction_ids,
                            non_fatal_female_age_group_restriction_ids,
                            non_fatal_male_age_group_restriction_ids
                        )
                    )
                ) {
                    return true;
                }
                if (
                    anySelectedLocationsPresentIn(
                        intersection(
                            fatal_location_restriction_ids,
                            non_fatal_location_restriction_ids
                        )
                    )
                ) {
                    return true;
                }
            }
        });

        return foundAtLeastOneRestriction;
    }
);

export const getSelectedDatasetsConfigByConditions =
    (conditions: DataCondition[]) => (state: RootState) => {
        const selectedConditionsDataTypes = getConditionsDataTypes(conditions);
        const selectedDataCollection = getSelectedDataCollectionForDataTool(state);

        return getSelectedDatasetsConfiguration(
            selectedConditionsDataTypes,
            selectedDataCollection
        );
    };

export const getSelectedDatasetsConfig = (state: RootState) => {
    const selectedConditionsDataTypes = getSelectedConditionsDataTypes(state);
    const selectedDataCollection = getSelectedDataCollectionForDataTool(state);

    return getSelectedDatasetsConfiguration(selectedConditionsDataTypes, selectedDataCollection);
};

export const getChartFiltersToOmit = (state: RootState): DataGranularityKey[] =>
    state.dataExplorer.omittedChartFilters;

export const getRefinedLocationChildrenById = (
    state: RootState
): Record<number, LocationEntity[]> | null => {
    const locationChildrenById = getLocationChildrenById(state);
    const refinedGranularity = getSelectedConditionsRefinedGranularity(state);

    if (!(locationChildrenById && refinedGranularity?.[LOCATION_ID_KEY])) {
        return null;
    }

    const refinedLocationGranularity = refinedGranularity[LOCATION_ID_KEY];

    const refinedLocationChildrenById = {};
    Object.entries(locationChildrenById).forEach(([key, value]) => {
        if (value && value.length) {
            const ids = intersection(
                value.map(({ id }) => id),
                refinedLocationGranularity
            );
            refinedLocationChildrenById[key] = ids && ids.length ? ids : null;
        }
    });

    return refinedLocationChildrenById;
};

export const getSelectedChartConditions = (state: RootState): DataCondition[] => {
    const chartFilters = getChartSelectedRefinementFilters(state);
    return (chartFilters?.[PRIMARY_ENTITY_KEY] || []).map(mapValueToCondition);
};

export const getChartDataRequestFilters = (state: RootState): DataFilters => {
    const refinementFilters = getSelectedRefinementFiltersWithFallbackToDefaultFilters(state);
    const chartFilters = getChartSelectedRefinementFilters(state);
    if (!refinementFilters || !chartFilters) {
        return {};
    }

    const refinedGranularity = getSelectedConditionsRefinedGranularity(state);
    const entityHierarchies = state.entityHierarchies;
    const chartType = getSelectedChartType(state);

    const filters = { ...chartFilters };

    const datasetConfig = getSelectedDatasetsConfig(state);
    // TODO: @vova please look at this and add comment what is the requirement for this
    // @piotr for Covid-19 tool it's required to select 'observed data' forecast scenario without user action/notice
    // and these filter value are hidden from refinement filters options
    if (datasetConfig.autoSelectedChartFilters) {
        Object.entries(datasetConfig.autoSelectedChartFilters).map(([key, value]) => {
            if (
                filters[key] &&
                refinementFilters[key] &&
                refinedGranularity &&
                refinedGranularity[key]
            ) {
                const validatedValue = intersection(
                    value,
                    refinementFilters[key],
                    refinedGranularity[key]
                );
                filters[key] = uniq(filters[key].concat(validatedValue));
            }
        });
    }

    if (datasetConfig.permanentChartFilters) {
        Object.entries(datasetConfig.permanentChartFilters).map(([key, value]) => {
            if (refinementFilters[key] && refinedGranularity && refinedGranularity[key]) {
                if (isEmpty(filters[key])) {
                    filters[key] = (
                        !isEmpty(refinementFilters[key])
                            ? refinementFilters[key]
                            : refinedGranularity[key]
                    ).slice(0, 1);
                }

                filters[key] = uniq(filters[key].concat(value));
            }
        });
    }

    switch (chartType) {
        case TYPE_MAP_CHART:
            const detailLevel = chartFilters[MAP_DETAIL_LEVEL_ID_KEY];
            const mapType = chartFilters[MAP_TYPE_ID_KEY];
            const locationIds = getLocationsForLevel(
                detailLevel,
                mapType,
                refinedGranularity[LOCATION_ID_KEY],
                entityHierarchies.locations
            );
            filters[LOCATION_ID_KEY] = locationIds;
            break;

        case TYPE_TREEMAP_CHART:
            const dataType = chartFilters[DATA_TYPE_KEY];
            if (dataType) {
                const granularity = getSelectedConditionsDataTypesGranularity(state);
                const primaryEntityKey = getDataTypePrimaryEntityKey(dataType);
                filters[primaryEntityKey] = granularity[primaryEntityKey];
            }
            break;

        case TYPE_SCATTER_MAP_CHART:
            filters[LOCATION_ID_KEY] = refinedGranularity[LOCATION_ID_KEY];
            break;

        case TYPE_ARROW_CHART:
            if (isArrowChartDrillable(state) && isEmpty(chartFilters[PRIMARY_ENTITY_KEY])) {
                const dataTypes = getSelectedConditionsDataTypes(state) || [];
                const causeKey = getDataTypePrimaryEntityKey(dataTypes[0]);
                const drillIntoCondition = getDrillIntoCondition(state);

                const isDrillIntoLevelSet = !!drillIntoCondition?.level;

                const causes: DataCondition[] = isDrillIntoLevelSet
                    ? getLicensedLevel2Causes(state)
                    : getLicensedDrillToConditions(state);

                causes.forEach(({ primary_entity_id }) => {
                    if (isEmpty(filters[causeKey])) {
                        filters[causeKey] = [primary_entity_id];
                    } else {
                        filters[causeKey].push(primary_entity_id);
                    }
                });
            }

            break;

        default:
            break;
    }

    if (
        filters?.[FORECAST_SCENARIO_ID_KEY]?.length &&
        (filters?.[FORECAST_SCENARIO_ID_KEY] || []).includes(config.referenceForecastScenarioId)
    ) {
        filters[FORECAST_SCENARIO_ID_KEY] = uniq([
            ...filters[FORECAST_SCENARIO_ID_KEY],
            config.pastEstimateScenarioId,
        ]);
    }

    // translate conditions into normal granularity filters
    if (!isEmpty(filters[PRIMARY_ENTITY_KEY])) {
        filters[PRIMARY_ENTITY_KEY].map(mapValueToCondition).forEach(
            ({ data_type, primary_entity_id }) => {
                const primaryEntityKey = getDataTypePrimaryEntityKey(data_type);
                if ((refinedGranularity[primaryEntityKey] || []).includes(primary_entity_id)) {
                    if (isEmpty(filters[primaryEntityKey])) {
                        filters[primaryEntityKey] = [primary_entity_id];
                    } else {
                        filters[primaryEntityKey].push(primary_entity_id);
                    }
                }
            }
        );
    }

    const adoptedFilters = pick(
        intersection(Object.keys(filters), Object.keys(refinedGranularity)),
        filters
    );

    return adoptedFilters;
};

export const isArrowChartDrillable = (state: RootState): boolean => {
    const dataTypes = getSelectedConditionsDataTypes(state) || [];
    const isSingleDataTypeSelected = dataTypes.length === 1;
    const isSupportedDataType = dataTypes.includes(CauseOutcome);
    const isConditionDimension = getChartDimensionFilter(state) === PRIMARY_ENTITY_KEY;

    return isSingleDataTypeSelected && isSupportedDataType && isConditionDimension;
};

export const getEntityHierarchies = (state: RootState) => state.entityHierarchies;

export const getDrillIntoCondition = (state: RootState): DataCondition | null =>
    state.dataExplorer.drillIntoCondition;

export const getLicensedLevel2Causes = (state: RootState): DataCondition[] => {
    const refinedHierarchy = getRefinedCausesHierarchy(state);
    return getLicensedConditionsByLevel(CauseOutcome, refinedHierarchy, 2);
};

export const getRefinedHierarchy =
    (state: RootState) =>
    (dataType: DataType): Entity[] =>
        getRefinedHierarchyByDataType(state, dataType);

const getRefinedHierarchyByDataType = (state: RootState, dataType: DataType): Entity[] => {
    const primaryEntityKey = getDataTypePrimaryEntityKey(dataType);
    const granularity = getSelectedConditionsDataTypesGranularity(state)?.[primaryEntityKey] || [];
    const hierarchyKey = getDataTypeConfig()[dataType].entityHierarchyKey;
    const hierarchy = getEntityHierarchies(state)[hierarchyKey] || [];
    return hierarchy.filter(({ id }) => granularity.includes(id));
};

export const getRefinedCausesHierarchy = (state: RootState): CauseEntity[] =>
    getRefinedHierarchyByDataType(state, CauseOutcome);

export const getLicensedDrillToConditions = (state: RootState): DataCondition[] => {
    const condition = getDrillIntoCondition(state);
    if (!condition) return [];

    const refinedHierarchy = getRefinedCausesHierarchy(state);
    return getLicensedConditionChildren(condition, refinedHierarchy);
};

export const getAvailableToSelectConditions = (state: RootState): DataCondition[] | null => {
    const selectedDataCollection = getSelectedDataCollectionForDataTool(state);
    const selectedDataType = getSelectedDataType(state);
    const selectedConditions = getSelectedConditions(state);

    if (!selectedDataCollection) {
        return null;
    }

    const datasets = selectedDataCollection.datasets.filter(
        (i) => selectedDataType === AllDataTypes || selectedDataType === i.data_type
    );

    const selectedConditionsIterators = selectedConditions.map(conditionComparisonFunc);

    const allConditions = uniqBy(
        conditionComparisonFunc,
        flatten(datasets.map(getDatasetConditions))
    );

    return allConditions.filter(
        (condition) => !selectedConditionsIterators.includes(conditionComparisonFunc(condition))
    );
};

export const getDataTableDisplayState = (state: RootState): DataTableDisplayState =>
    state.dataExplorer.dataTableDisplayState;

export const getDataTableVisibleColumns = (state: RootState): DataKey[] | null =>
    getDataTableDisplayState(state).visibleColumns;

export const getDataTableSortedByColumn = (state: RootState): DataKey | null =>
    getDataTableDisplayState(state).sortedByColumn;

export const getDataTableSortedDescending = (state: RootState): boolean =>
    getDataTableDisplayState(state).sortedDescending;

export const getCustomChartSettings = (state: RootState): Record<string, any> =>
    state.dataExplorer.customChartSettings;

export const getCombinedFiltersAmount = (state: RootState): number =>
    state.dataExplorer.combinedFiltersAmount;

export const getCombinedChartFiltersAmount = (state: RootState): number => {
    const combinedAllFiltersAmount = getCombinedFiltersAmount(state);
    const selectedConditionsGranularity = getSelectedConditionsRefinedGranularity(state);
    const selectedRefinementFilters = getSelectedRefinementFilters(state);
    const currentFiltersSelection = {
        ...selectedConditionsGranularity,
        ...selectedRefinementFilters,
    };

    const timeUnitsAmount =
        getTimeUnitsCount(currentFiltersSelection, selectedConditionsGranularity) || 1;

    return combinedAllFiltersAmount / timeUnitsAmount;
};

export const getChartDimensionFilter = (state: RootState): DataKey | null =>
    state.dataExplorer.chartDimensionFilter;
