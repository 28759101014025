import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash/fp';

import { VALUE_KEY } from '@portal/common/models/data-key';

import useGetApiData, { GetAPiDataProps } from './use-get-api-data';

export default ({ conditions, filters }: GetAPiDataProps) => {
    const [value, setValue] = useState<string | number | null>(null);

    const { isLoading, dataResponse, error } = useGetApiData({
        conditions,
        filters,
    });

    useEffect(() => {
        if (!isLoading && dataResponse) {
            const { columns, records } = dataResponse;
            const valueIdx = columns.indexOf(VALUE_KEY);
            if (!isEmpty(records)) {
                setValue(records[0][valueIdx]);
            }
        }
    }, [isLoading, dataResponse]);

    return { isLoading, value, error };
};
