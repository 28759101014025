import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    Alert,
    BodyText2,
    FlexColumn,
    Loading,
    Modal,
    ModalBody,
    ModalButton,
    ModalFooter,
    ModalHeader,
    RadioSelect,
    SvgIcon,
    Textarea,
} from '@portal/common/components';
import { styled } from '@portal/common/theme';
import formatRichText from '@portal/common/components/RichTextEditor/formatRichText';
import { Poll, PollRespond } from '@portal/common/types';
import { parseApiError } from '@portal/common/utility/api-helpers';
import renderErrors from '@portal/common/utility/renderErrors';

import { getSession, getSessionAccount } from '../../../store/root-reducer';
import { getActivePoll } from '../../../store/polls/selectors';

import api from '../../../api';
import _ from '../../../locale';

const StyledModal = styled(Modal)({
    display: 'flex !important',
    alignItems: 'center',
});

const StyledHeader = styled(ModalHeader)({
    marginTop: 10,
    width: '100%',
    paddingRight: 20,
    paddingLeft: 20,
});

const CloseIcon = styled(SvgIcon)(({ theme }) => ({
    position: 'absolute',
    top: 30,
    right: 20,
    color: theme.color.gray40,
    ':hover': {
        color: theme.color.gray70,
    },
}));

const StyledRadioSelect = styled(RadioSelect)({
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
});

const PollPopup = () => {
    const account = useSelector(getSessionAccount);
    const { is_stealth: isStealthUser } = account || {};
    const isImpersonatedSession = useSelector(getSession)?.isImpersonated;
    const activePoll: Poll = useSelector(getActivePoll);

    const [error, setError] = useState(null);
    const [isSubmittable, setIsSubmittable] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isVisible, setVisibility] = useState(false);
    const [selectedPollOptionId, setSelectedPollOptionId] = useState<number>(-1);
    const [pollComment, setPollComment] = useState<string>('');
    const [optionComment, setOptionComment] = useState<string>('');

    const getPollRespond = (isEmpty): PollRespond => {
        const respond: PollRespond = { id: activePoll.id };
        if (!isEmpty) {
            respond.poll_option_id = selectedPollOptionId;
            if (pollComment) {
                respond.poll_comment = pollComment;
            }
            if (optionComment) {
                respond.option_comment = activePoll.options.find(
                    (option) => option.id === selectedPollOptionId
                )?.commentable
                    ? optionComment
                    : null;
            }
        }
        return respond;
    };

    const votePoll = (isEmpty?: boolean) => {
        api.polls
            .vote(getPollRespond(isEmpty))
            .then(() => {
                handleClose();
            })
            .catch((error) => {
                setError(parseApiError(error));
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    const handlePollOptionSelect = useCallback((ev) => {
        setIsSubmittable(true);
        setSelectedPollOptionId(parseInt(ev.target.value));
    }, []);

    const handleOptionCommentChange = useCallback((ev) => {
        setOptionComment(ev.target.value);
    }, []);

    const handlePollCommentChange = useCallback((ev) => {
        setPollComment(ev.target.value);
    }, []);

    const handleClose = useCallback(() => {
        setVisibility(false);
    }, []);

    const handleCloseClick = useCallback(() => {
        if (!isSubmitting) {
            votePoll(true);
            handleClose();
        }
    }, [votePoll, isSubmitting, handleClose]);

    const handleSubmitClick = useCallback(() => {
        setError(null);
        setIsSubmitting(true);
        votePoll();
    }, [votePoll]);

    useEffect(() => {
        setVisibility(!!activePoll && !isStealthUser && !isImpersonatedSession);
    }, [!!activePoll, setVisibility, isImpersonatedSession, isStealthUser]);

    if (!activePoll) {
        return null;
    }

    const { title, description, options } = activePoll;
    const formattedDescription = formatRichText(description) as string;

    return (
        <StyledModal show={isVisible} onHide={handleClose}>
            <CloseIcon src="/icons/ic-cross.svg" onClick={handleCloseClick} />
            {title && <StyledHeader>{title}</StyledHeader>}
            <ModalBody>
                {_.parse(formattedDescription)}
                <FlexColumn>
                    <StyledRadioSelect
                        name="poll"
                        onChange={handlePollOptionSelect}
                        onCommentChange={handleOptionCommentChange}
                        value={selectedPollOptionId}
                        options={options.map(({ id, answer, commentable }) => ({
                            value: id,
                            label: answer,
                            commentable,
                        }))}
                    />
                    <BodyText2>{_('share_more')}</BodyText2>
                    <Textarea onChange={handlePollCommentChange} maxLength={512} rows={3} />
                    {error && <Alert color="error">{renderErrors(error)}</Alert>}
                </FlexColumn>
            </ModalBody>
            <ModalFooter>
                <ModalButton
                    type="submit"
                    onClick={handleSubmitClick}
                    disabled={!isSubmittable || isSubmitting}
                >
                    {isSubmitting ? (
                        <Loading size="small" style={{ marginTop: -6 }} />
                    ) : (
                        _('submit_button')
                    )}
                </ModalButton>
            </ModalFooter>
        </StyledModal>
    );
};

export default PollPopup;
