import React from 'react';

import { DataCollectionResource } from '@portal/common/types';
import { CauseOutcome } from '@portal/common/models/data-type';
import {
    AGE_GROUP_ID_KEY,
    GENDER_ID_KEY,
    LOCATION_ID_KEY,
    MEASURE_ID_KEY,
    METRIC_ID_KEY,
    PRIMARY_ENTITY_ID_KEY,
    ROUND_ID_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';

import config from '../../../config';
import _ from '../../../locale';

import PlaceOfMetricBlock from '../components/PlaceOfMetricBlock';

type Props = {
    locationId: number;
};

const MostPrevalentCancerBlock = ({ locationId }: Props) => (
    <PlaceOfMetricBlock
        conditions={config.neoplasmsChildrenCauseIds.split(',').map((id) => ({
            data_type: CauseOutcome,
            primary_entity_id: parseInt(id),
        }))}
        filters={{
            [LOCATION_ID_KEY]: [locationId],
            [AGE_GROUP_ID_KEY]: [config.allAgesGroupId],
            [GENDER_ID_KEY]: [config.bothGendersId],
            [MEASURE_ID_KEY]: [config.prevalenceMeasureId],
            [METRIC_ID_KEY]: [config.numberMetricId],
            [YEAR_KEY]: [2019],
            [ROUND_ID_KEY]: [config.gbd2019RoundId],
        }}
        label={_('simulation_tool_cancer_prevalence_metric', {
            location: _(`location_${locationId}`),
        })}
        positionByKeyValuePair={[PRIMARY_ENTITY_ID_KEY, config.multipleMyelomaCauseId]}
    />
);

export default MostPrevalentCancerBlock;
