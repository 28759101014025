import * as React from 'react';

import { styled } from '@portal/common/theme';

import Navigation from '../components/Navigation';

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '100vh',
    // TODO: remove minWidth with responsive design
    minWidth: theme.minWidth.window,
}));

const Body = styled('div')(({ theme }) => ({
    flexGrow: 1,
}));

type Props = {};

class LayoutWithSidenav extends React.Component<Props> {
    render() {
        return (
            <Container>
                <Navigation />
                <Body>{this.props.children}</Body>
            </Container>
        );
    }
}

export default LayoutWithSidenav;
