import { uniq } from 'lodash/fp';

import { DataKey, DataResponse } from '@portal/common/types';

export default (dataResponse: DataResponse, dataKey: DataKey): (number | string)[] => {
    const { columns, records } = dataResponse;
    if (!columns.includes(dataKey)) {
        return [];
    }

    const dataKeyIdx = columns.indexOf(dataKey);
    return uniq(records.map((record) => record[dataKeyIdx]));
};
