/**
 *  scale = 2.66
 *  lngOffset = 479.65
 *  latOffset = 100.1
 *  lng = lng * scale + xOffset
 *  lat = lat * scale + yOffset
 */
const coordsMap = {
    675: { lng: 181.53306078199995, lat: 189.07268308599998 },
    782: { lng: 156.51625633999993, lat: 202.71153224 },
    792: { lng: 154.40884473999995, lat: 200.65960558 },
    799: { lng: 155.41454551999993, lat: 199.4123768 },
    807: { lng: 167.62561866, lat: 190.84798544 },
    810: { lng: 167.37622503999995, lat: 190.415911 },
    813: { lng: 168.00150857999995, lat: 187.12385776 },
    814: { lng: 165.12180321999995, lat: 190.67894510000002 },
    933: { lng: 166.12155623999996, lat: 189.86321886 },
    952: { lng: 266.67932642, lat: 171.14786318 },
    956: { lng: 266.48474475999996, lat: 169.58568508 },
    1041: { lng: 225.99660013999994, lat: 179.23475262 },
    1086: { lng: 258.74829701999994, lat: 212.70160113999998 },
    1104: { lng: 246.57234917999997, lat: 211.50567312 },
    1284: { lng: 266.33984391999996, lat: 168.62607146 },
    1425: { lng: 222.13834993999996, lat: 187.29258421999998 },
    2427: { lng: 282.85251744, lat: 208.50811912 },
    2428: { lng: 283.42335077999996, lat: 208.47386364 },
    2430: { lng: 282.94300797999995, lat: 208.22900532 },
    2503: { lng: 248.84722639999995, lat: 196.3240769 },
    2871: { lng: 279.7105094799999, lat: 206.37387078 },
    3138: { lng: 154.23845577999998, lat: 226.73731458 },
    3308: { lng: 217.66356227999995, lat: 178.36876834 },
    3447: { lng: 290.49591571999997, lat: 212.81380525999998 },
    3563: { lng: 173.43022543999996, lat: 196.39717636 },
};

const realCoordsMap = {
    69: { lat: 1.3521, lng: 103.8198 },
    84: { lat: 53.7798, lng: -7.3055 },
    4849: { lat: 28.7041, lng: 77.1025 },
    4775: { lat: -23.5558, lng: -46.6396 },
    354: { lat: 22.3193, lng: 114.1694 },
    570001: { lat: 47.6061, lng: -122.3328 },
    555001: { lat: 40.7128, lng: -74.006 },
    527002: { lat: 37.3875, lng: -122.0575 },
    527001: { lat: 37.8272, lng: -122.2913 },
    4624: { lat: 51.4309, lng: -0.0936 },
    3279: { lat: 30.2097, lng: -97.6982 },
    485605: { lat: 12.9716, lng: 77.5946 },
    85: { lat: 32.0853, lng: 34.7818 },
    514: { lat: 31.224361, lng: 121.46917 },
};

const SCALE = 2.66;
const LNG_OFFSET = 479.65;
const LAT_OFFSET = 100.1;

Object.entries(realCoordsMap).forEach(([key, value]) => {
    value.lat = value.lat * SCALE + LAT_OFFSET;
    value.lng = value.lng * SCALE + LNG_OFFSET;

    coordsMap[key] = value;
});

export default coordsMap;
