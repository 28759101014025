import React from 'react';
import { compose } from 'redux';

import {
    H2,
    PageBody,
    PageContainer,
    PageHeader,
    PageBodyFooter,
    FlexColumn,
} from '@portal/common/components';

import _ from '../../locale';

import DataLicenseView from './DataLicenseView';

class DataLicenseScene extends React.PureComponent {
    render() {
        return (
            <PageContainer>
                <PageHeader>
                    <H2>{_.get('data_license_title')}</H2>
                </PageHeader>
                <PageBody>
                    <FlexColumn grow={1}>
                        <DataLicenseView />
                    </FlexColumn>
                    <PageBodyFooter>{_.get('footer_note')}</PageBodyFooter>
                </PageBody>
            </PageContainer>
        );
    }
}

export default compose()(DataLicenseScene);
