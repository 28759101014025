import { RootState } from 'MyTypes';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { difference, isEmpty, isEqual, sortBy, union, uniqBy } from 'lodash/fp';

import { FlexRow, SvgIcon } from '@portal/common/components';
import { DataGranularityKey, DropdownOption, RefinementFilterConfig } from '@portal/common/types';
import {
    AGE_GROUP_ID_KEY,
    CAUSE_ID_KEY,
    COMORBID_CAUSE_ID_KEY,
    DAY_KEY,
    FORECAST_SCENARIO_ID_KEY,
    LOCATION_ID_KEY,
    MEASURE_ID_KEY,
    MONTH_COUNT_KEY,
    RESPONSE_ID_KEY,
    ROUND_ID_KEY,
    YEAR_KEY,
} from '@portal/common/models/data-key';
import {
    isLocationTypeCountryOrSubnational,
    isLocationTypeGlobal,
    isLocationTypeSubnational,
} from '@portal/common/models/location-types';
import { styled } from '@portal/common/theme';

import {
    firstAndLastFromArray,
    getLocalePrefixForGranularityKey,
} from '@portal/common/utility/filters-helpers';

import {
    changeSelectedRefinementFilters,
    removeVisibleRefinementFilters,
} from '../../store/data-explorer/actions';
import {
    getDataToolConfig,
    getRefinedLocationChildrenById,
    getSelectedConditions,
    getSelectedConditionsDataTypesDefaultFilters,
    getSelectedConditionsDataTypesGranularity,
    getSelectedConditionsRefinedGranularity,
    getSelectedDatasetsConfig,
    getSelectedRefinementFiltersWithFallbackToDefaultFilters,
} from '../../store/data-explorer/selectors';
import { getSelectedDataTool } from '../../store/root-reducer';
import { getLocationsById } from '../../store/entity-hierarchies/selectors';

import { HEALTH_THREATS_METRICS_PATH } from '../../router/paths';
import _ from '../../locale';

import AgeGroupsDropdown from '../../components/AgeGroupsDropdown';
import ChartDropdown from '../../components/ChartDropdown';
import ChartHierarchicalDropdown from '../../components/ChartHierarchicalDropdown';
import ChartRangeSlider from '../../components/ChartRangeSlider';
import SmartOptionsSelector from '../SmartFilterOptionSelector';

const SELECT_ALL_DISABLED_KEYS = [CAUSE_ID_KEY, ROUND_ID_KEY, DAY_KEY];

const SubnationalButton = styled(FlexRow)<{ disabled: boolean }>(({ theme, disabled }) => ({
    ...(disabled && {
        cursor: 'default',
        pointerEvents: 'none',
    }),
}));

const SubnationalSvgIcon = styled(SvgIcon)<{
    disabled: boolean;
    isActive: boolean;
}>(({ theme, disabled, isActive }) => ({
    color: theme.color.disabled,
    ':hover, :focus': {
        color: theme.color.green1,
    },
    ...(isActive && {
        color: theme.color.green1,
    }),
    ...(disabled && {
        color: theme.color.disabled,
        cursor: 'default',
        pointerEvents: 'none',
    }),
}));

const ListItem = styled.div<{ disabled: boolean }>(({ theme, disabled }) => ({
    ...(disabled && {
        color: theme.color.disabled,
    }),
}));

const RemoveButton = styled.button(({ theme }) => ({
    border: 'none',
    background: 'none',
    fontSize: '1.6rem',
    fontWeight: 500,
    cursor: 'pointer',
    display: 'inline-block',
    color: theme.color.primary1,
    ':hover, :focus': {
        color: theme.color.primary2,
    },
    ':active': {
        color: theme.color.primary3,
    },
}));

const mapStateToProps = (state: RootState) => ({
    entityHierarchies: state.entityHierarchies,
    locationsById: getLocationsById(state),
    refinedLocationChildrenById: getRefinedLocationChildrenById(state),
    granularity: getSelectedConditionsDataTypesGranularity(state),
    refinedGranularity: getSelectedConditionsRefinedGranularity(state),
    selectedRefinementFilters: getSelectedRefinementFiltersWithFallbackToDefaultFilters(state),
    selectedConditions: getSelectedConditions(state),
    defaultFilters: getSelectedConditionsDataTypesDefaultFilters(state),
    datasetsConfig: getSelectedDatasetsConfig(state),
    dataToolConfig: getDataToolConfig(state),
    dataTool: getSelectedDataTool(state),
});

const dispatchProps = {
    removeVisibleRefinementFilters: removeVisibleRefinementFilters,
    changeSelectedRefinementFilters: changeSelectedRefinementFilters,
};

type Props = ReturnType<typeof mapStateToProps> &
    typeof dispatchProps & {
        refinementFiltersConfig: RefinementFilterConfig;
        granularityKey: DataGranularityKey;
        selectedValue?: number[];
        availableRefinementFilters: {};
        hideDelete?: boolean;
        hideSelectAll?: boolean;
        mode?: 'slider' | 'dropdown';
        onChange?: (val: string) => void;
        options?: DropdownOption[];
        label?: string;
        onRemove?: (granularityKey: DataGranularityKey) => void;
        preprocessOptions?: (options: DropdownOption[]) => any;
    };

class RefinementFilter extends React.Component<Props> {
    handleRemoveRefinementClick = (granularityKey: DataGranularityKey) => {
        const {
            selectedRefinementFilters,
            changeSelectedRefinementFilters,
            removeVisibleRefinementFilters,
            defaultFilters,
            onRemove,
        } = this.props;

        if (onRemove) {
            onRemove(granularityKey);
            return;
        }

        removeVisibleRefinementFilters(granularityKey);

        // when removing filter the value should be set to default if it is different
        const defaultValue = defaultFilters[granularityKey];
        if (!isEqual(defaultValue, selectedRefinementFilters[granularityKey])) {
            changeSelectedRefinementFilters({
                [granularityKey]: defaultValue,
            });
        }
    };

    render() {
        const {
            entityHierarchies,
            granularity,
            refinedGranularity,
            availableRefinementFilters,
            defaultFilters,
            granularityKey,
            selectedConditions,
            hideDelete,
            hideSelectAll,
            onChange,
            selectedValue,
            locationsById,
            refinedLocationChildrenById,
            refinementFiltersConfig,
            mode,
            label: customLabel,
            preprocessOptions,
            dataToolConfig: { areRefinementFiltersSmartOptionsDisabled },
            dataTool,
        } = this.props;

        if (availableRefinementFilters == null) {
            return null;
        }

        const fallbackGranularity = refinedGranularity == null ? granularity : refinedGranularity;

        const label = customLabel || _('refinement_' + granularityKey);

        let control;
        switch (granularityKey) {
            case MEASURE_ID_KEY:
                control = (
                    <ChartDropdown
                        label={label}
                        id={granularityKey}
                        filterKey={granularityKey}
                        filterValue={selectedValue}
                        defaultValue={defaultFilters[granularityKey]}
                        {...refinementFiltersConfig[granularityKey]}
                    />
                );
                break;

            case YEAR_KEY:
            case DAY_KEY:
            case MONTH_COUNT_KEY:
                const filterGranularity = fallbackGranularity[granularityKey];

                const [startValue, endValue] = (!isEmpty(filterGranularity) &&
                    firstAndLastFromArray(filterGranularity)) || [0, 0];

                switch (mode || refinementFiltersConfig[granularityKey].mode) {
                    case 'slider':
                        control = (
                            <ChartRangeSlider
                                key={
                                    selectedValue +
                                    startValue.toString() +
                                    endValue.toString() +
                                    mode
                                }
                                id={granularityKey}
                                label={label}
                                filterKey={granularityKey}
                                filterValue={selectedValue}
                                onAfterChange={onChange}
                                {...refinementFiltersConfig[granularityKey]}
                            />
                        );
                        break;

                    case 'dropdown':
                        control = (
                            <ChartDropdown
                                isMulti
                                disableStatusCheck
                                placeholder={_.get(`refinement_filter_${granularityKey}_undefined`)}
                                id={granularityKey}
                                label={label}
                                filterKey={granularityKey}
                                filterValue={selectedValue}
                                defaultValue={defaultFilters[granularityKey]}
                                onChange={onChange}
                                {...refinementFiltersConfig[granularityKey]}
                            />
                        );
                        break;
                }
                break;

            case COMORBID_CAUSE_ID_KEY:
                control = (
                    <ChartHierarchicalDropdown
                        label={label}
                        id={granularityKey}
                        filterKey={granularityKey}
                        filterValue={selectedValue}
                        defaultValue={defaultFilters[granularityKey]}
                        onChange={onChange}
                        placeholder={_.get('search_cause_id')}
                        useLetterPrefix={false}
                        loadHierarchy={() => Promise.resolve(entityHierarchies.causes)}
                        optionLabelGetter={(o) => o.label}
                        optionValueGetter={(o) => o.value}
                        preprocessOptions={(options) => {
                            const areAllItemsAtSameLevel = uniqBy('level', options).length === 1;
                            const availableOptions = options.filter((o) =>
                                fallbackGranularity[granularityKey].includes(o.value)
                            );

                            return areAllItemsAtSameLevel
                                ? sortBy('label', availableOptions)
                                : availableOptions;
                        }}
                        {...refinementFiltersConfig[granularityKey]}
                    />
                );
                break;

            case CAUSE_ID_KEY:
                control = (
                    <ChartHierarchicalDropdown
                        label={label}
                        id={granularityKey}
                        filterKey={granularityKey}
                        filterValue={selectedValue}
                        defaultValue={defaultFilters[granularityKey]}
                        onChange={onChange}
                        placeholder={_.get('search_cause_id')}
                        useLetterPrefix={false}
                        loadHierarchy={() => Promise.resolve(entityHierarchies.causes)}
                        optionLabelGetter={(o) => o.label}
                        optionValueGetter={(o) => o.value}
                        {...refinementFiltersConfig[granularityKey]}
                    />
                );
                break;

            case LOCATION_ID_KEY:
                if (
                    !(entityHierarchies.locations && locationsById && refinedLocationChildrenById)
                ) {
                    return null;
                }

                const locationGranularity = granularity[granularityKey];
                const locationFallbackGranularity = fallbackGranularity[granularityKey];
                const { isSelectSubnationalsDisabled } = refinementFiltersConfig[granularityKey];

                const handleRenderOption = (
                    o: DropdownOption,
                    {
                        optionValueGetter,
                        optionLabelGetter,
                        optionDisabledGetter,
                        optionSearchTermGetter,
                    },
                    { value, handleChange }
                ) => {
                    // find location entity by option Id
                    const optionId = o.value;
                    const locationEntity = locationsById[optionId];
                    const children = refinedLocationChildrenById[optionId];

                    if (!locationEntity) {
                        return null;
                    }

                    // Display simple list item
                    if (
                        isSelectSubnationalsDisabled ||
                        isLocationTypeGlobal(locationEntity.location_type_id) ||
                        children == null
                    ) {
                        return (
                            <ListItem disabled={optionDisabledGetter(o)}>
                                {optionLabelGetter(o)}
                            </ListItem>
                        );
                    }

                    const selectedFilterIds = value || [];
                    const directSubnationalsIds: any[] = children.map((i) => i);

                    const areAllDirectChildrenSelected = directSubnationalsIds.every((i) =>
                        selectedFilterIds.includes(i)
                    );

                    const handleSubnationalsClick = (ev) => {
                        ev.stopPropagation();

                        // calculate new ids
                        let newValue = areAllDirectChildrenSelected
                            ? difference(selectedFilterIds, directSubnationalsIds)
                            : union(selectedFilterIds, directSubnationalsIds);

                        handleChange(newValue);
                    };

                    const subnationalIconSrc = areAllDirectChildrenSelected
                        ? '/icons/ic-multiselect-all.svg'
                        : '/icons/ic-multiselect-some.svg';

                    const isSubnationalsButtonDisabled = optionDisabledGetter(o);

                    return (
                        <FlexRow justify="space-between">
                            <div>{optionLabelGetter(o)}</div>
                            <SubnationalButton
                                align="center"
                                itemsSpacing={8}
                                disabled={isSubnationalsButtonDisabled}
                                onClick={
                                    !isSubnationalsButtonDisabled
                                        ? handleSubnationalsClick
                                        : undefined
                                }
                            >
                                <SubnationalSvgIcon
                                    data-testid="select-subnationals-icon"
                                    src={subnationalIconSrc}
                                    size="large"
                                    disabled={isSubnationalsButtonDisabled}
                                    isActive={areAllDirectChildrenSelected}
                                />
                            </SubnationalButton>
                        </FlexRow>
                    );
                };

                control = (
                    <ChartHierarchicalDropdown
                        key={(locationGranularity || []).map(({ id }) => id).join('_')}
                        label={label}
                        id={granularityKey}
                        filterKey={granularityKey}
                        filterValue={selectedValue}
                        defaultValue={defaultFilters[granularityKey]}
                        onChange={onChange}
                        placeholder={_.get('search_location_id')}
                        useLetterPrefix={false}
                        limitHierarchyDepth={8}
                        loadHierarchy={() => Promise.resolve(entityHierarchies.locations)}
                        optionLabelGetter={(o) => o.label}
                        optionValueGetter={(o) => o.value}
                        optionDisabledGetter={(o) => {
                            // find entity of option
                            const optionId = o.value;
                            const locationEntity = locationsById[optionId];
                            // when not in the refined granularity should be disabled
                            let disabled = !locationFallbackGranularity.includes(optionId);
                            // when option is a subnational of a country which is disabled
                            // it should also be disabled
                            if (
                                !disabled &&
                                isLocationTypeSubnational(locationEntity.location_type_id)
                            ) {
                                let level = locationEntity.level;
                                // traverse location parents checking if they are
                                // disabled and available
                                while (!disabled && level > 0) {
                                    level--;
                                    let parentId = locationEntity[`level${level}_parent_id`];
                                    if (locationsById[parentId]) {
                                        const parentIdLocationType =
                                            locationsById[parentId].location_type_id;

                                        if (
                                            isLocationTypeCountryOrSubnational(parentIdLocationType)
                                        ) {
                                            const isParentAvailable =
                                                locationGranularity.includes(parentId);
                                            const isParentDisabled =
                                                !locationFallbackGranularity.includes(parentId);

                                            disabled = isParentAvailable && isParentDisabled;
                                        }
                                    }
                                }
                            }
                            return disabled;
                        }}
                        optionSearchTermGetter={(o) => {
                            // find entity of option
                            const optionId = o.value;
                            const locationEntity = locationsById[optionId];

                            if (!locationEntity) {
                                return null;
                            }

                            let level = locationEntity.level;
                            let searchTerm = _(`location_${optionId}`);
                            // when using search functionality subnationals below country
                            // should have all parents visible up to a country in search results
                            // the rest should have only direct parent
                            while (
                                level > 0 &&
                                isLocationTypeCountryOrSubnational(locationEntity.location_type_id)
                            ) {
                                level--;
                                let parentId = locationEntity[`level${level}_parent_id`];
                                if (locationsById[parentId]) {
                                    const parentIdLocationType =
                                        locationsById[parentId].location_type_id;

                                    if (isLocationTypeCountryOrSubnational(parentIdLocationType)) {
                                        const parentSearchTerm = _(`location_${parentId}`);
                                        searchTerm = `${searchTerm} ${parentSearchTerm}`;
                                    }
                                }
                            }
                            return searchTerm;
                        }}
                        renderOption={handleRenderOption}
                        {...refinementFiltersConfig[granularityKey]}
                    />
                );
                break;

            case AGE_GROUP_ID_KEY:
                switch (mode || refinementFiltersConfig[granularityKey].mode) {
                    case 'slider':
                        control = (
                            <ChartRangeSlider
                                key={'' + selectedValue + mode}
                                id={granularityKey}
                                label={label}
                                filterKey={granularityKey}
                                filterValue={selectedValue}
                                onAfterChange={onChange}
                                {...refinementFiltersConfig[granularityKey]}
                            />
                        );
                        break;

                    default:
                        control = (
                            <AgeGroupsDropdown
                                label={label}
                                id={granularityKey}
                                filterKey={granularityKey}
                                filterValue={selectedValue}
                                defaultValue={defaultFilters[granularityKey]}
                                onChange={onChange}
                                {...refinementFiltersConfig[granularityKey]}
                            />
                        );
                        break;
                }

                break;

            case RESPONSE_ID_KEY:
                control = (
                    <ChartDropdown
                        label={label}
                        id={granularityKey}
                        filterKey={granularityKey}
                        filterValue={selectedValue}
                        defaultValue={defaultFilters[granularityKey]}
                        preprocessOptions={preprocessOptions}
                        onChange={onChange}
                        {...refinementFiltersConfig[granularityKey]}
                    />
                );
                break;

            case FORECAST_SCENARIO_ID_KEY:
                let localePrefix = getLocalePrefixForGranularityKey(granularityKey);
                if (dataTool === HEALTH_THREATS_METRICS_PATH) {
                    localePrefix = `ght_records_${localePrefix}`;
                }
                control = (
                    <ChartDropdown
                        label={label}
                        id={granularityKey}
                        filterKey={granularityKey}
                        filterValue={selectedValue}
                        defaultValue={defaultFilters[granularityKey]}
                        preprocessOptions={preprocessOptions}
                        onChange={onChange}
                        optionLabelGetter={(o) => _.withPrefix(localePrefix, o)}
                        {...refinementFiltersConfig[granularityKey]}
                    />
                );
                break;

            default:
                control = (
                    <ChartDropdown
                        label={label}
                        id={granularityKey}
                        filterKey={granularityKey}
                        filterValue={selectedValue}
                        defaultValue={defaultFilters[granularityKey]}
                        onChange={onChange}
                        preprocessOptions={preprocessOptions}
                        {...refinementFiltersConfig[granularityKey]}
                    />
                );
                break;
        }

        const isSelectAllEnabled = !SELECT_ALL_DISABLED_KEYS.includes(granularityKey);

        return (
            <FlexRow key={granularityKey} itemsSpacing={30} align="center" justify="space-between">
                <FlexRow align="center" grow={1}>
                    <div style={{ width: '100%' }}>{control}</div>
                </FlexRow>
                {(!hideSelectAll || !hideDelete) && (
                    <FlexRow
                        style={{ minWidth: areRefinementFiltersSmartOptionsDisabled ? 80 : 200 }}
                    >
                        {!hideSelectAll && isSelectAllEnabled && (
                            <SmartOptionsSelector
                                filterKey={granularityKey}
                                selectedConditions={selectedConditions}
                                onChange={onChange}
                                hideDelete={hideDelete}
                            />
                        )}
                        {!hideDelete && (
                            <FlexRow grow={1} justify="flex-end" align="center">
                                <RemoveButton
                                    onClick={() => {
                                        this.handleRemoveRefinementClick(granularityKey);
                                    }}
                                >
                                    {/* @todo: localize */}
                                    Remove
                                </RemoveButton>
                            </FlexRow>
                        )}
                    </FlexRow>
                )}
            </FlexRow>
        );
    }
}

export default compose(connect(mapStateToProps, dispatchProps))(RefinementFilter);
