import React from 'react';

import { SectionBody } from '@portal/common/components';
import { styled } from '@portal/common/theme';

const StyledSectionBody = styled(SectionBody)({
    paddingBottom: '30px !important',
});

const Wrapper = styled.div({
    paddingTop: 30,
});

class ToolbarSection extends React.PureComponent {
    render() {
        const { children, ...props } = this.props;

        return (
            <StyledSectionBody>
                <Wrapper {...props}>{children}</Wrapper>
            </StyledSectionBody>
        );
    }
}

export default ToolbarSection;
