import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
    mapConditionToValue,
    localizeConditionValue,
} from '@portal/common/utility/chart-data-helpers';
import { PRIMARY_ENTITY_KEY } from '@portal/common/models/data-key';
import { HealthThreatsMetricsRecords } from '@portal/common/models/data-type';
import { TYPE_SCATTER_MAP_CHART } from '@portal/common/models/chart-type';
import { DataType } from '@portal/common/types';

import {
    getChartSelectedRefinementFilters,
    getDataToolConfig,
    getSelectedChartType,
    getSelectedConditions,
    isGHTData as isGHTDataSelector,
} from '../../../store/data-explorer/selectors';

import _ from '../../../locale';
import config from '../../../config';

import ChartDropdown from '../../../components/ChartDropdown';

type Props = {
    onChange: (DataFilters) => void;
};

const ChartConditionSelector: React.FC<Props> = ({ onChange }) => {
    const selectedConditions = useSelector(getSelectedConditions);
    const chartFilters = useSelector(getChartSelectedRefinementFilters);
    const chartType = useSelector(getSelectedChartType);
    const isGHTData = useSelector(isGHTDataSelector);
    const { customConditionLabel, customSelectDataConditionLabel } = useSelector(getDataToolConfig);

    let extendedSelectedConditions = useMemo(() => {
        if (isGHTData && chartType === TYPE_SCATTER_MAP_CHART) {
            const allPathogensCondition = {
                data_type: HealthThreatsMetricsRecords as DataType,
                primary_entity_id: config.allPathogensCauseId,
            };
            return [allPathogensCondition, ...selectedConditions];
        }
        return selectedConditions;
    }, [chartType, isGHTData, selectedConditions.map(mapConditionToValue).join(',')]);

    const granularityKey = PRIMARY_ENTITY_KEY;
    const showControl = extendedSelectedConditions && extendedSelectedConditions.length > 1;
    if (!showControl) return null;

    const granularity = {
        [granularityKey]: extendedSelectedConditions.map(mapConditionToValue),
    };
    const filterValue = chartFilters?.[granularityKey] || [
        mapConditionToValue(extendedSelectedConditions[0]),
    ];

    const handleChange = (value) => {
        onChange({ [granularityKey]: value });
    };

    return (
        <>
            <ChartDropdown
                skipRefinementFiltersUpdate
                label={
                    customSelectDataConditionLabel ||
                    customConditionLabel ||
                    _('filter_label_primary_entity')
                }
                granularity={granularity}
                refinedGranularity={granularity}
                selectedConditions={extendedSelectedConditions}
                filterKey={granularityKey}
                filterValue={filterValue}
                optionLabelGetter={(o) => localizeConditionValue(o.toString())}
                optionDisabledGetter={(o) => false}
                onChange={handleChange}
            />
        </>
    );
};

export default ChartConditionSelector;
