import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { BodyText3, H2, PageTile, PageTileBody } from '@portal/common/components';
import formatRichText from '@portal/common/components/RichTextEditor/formatRichText';
import { styled } from '@portal/common/theme';
import { getMemberFullname } from '@portal/common/models/models-helpers';
import _ from '../../locale';
import { getOrganization, getSessionAccount } from '../../store/root-reducer';
import * as organizationActions from '../../store/organization/actions';

const mapStateToProps = (store) => ({
    organization: getOrganization(store),
    sessionAccount: getSessionAccount(store),
});
const dispatchProps = {
    loadOrganization: organizationActions.loadOrganization,
};

const StyledPageTile = styled(PageTile)(({ theme }) => ({
    background: theme.color.warning,
    color: theme.color.white,
    paddingTop: 20,
    '& a': {
        color: theme.color.white,
        fontWeight: 'bold',
    },
}));

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const LicenseWarningTile = ({ organization, sessionAccount }: Props) => {
    const licenseExpirationWarning = organization?.license_expiration_warning;
    const isLicenseActive = organization.licensed_until !== '0000-00-00 00:00:00';

    const expireDaysLeft = moment(organization.licensed_until).diff(moment(), 'days');
    const isVisibleExpiredWarning = expireDaysLeft < 30;
    const replacements = {
        organizationName: organization.name,
        userName: getMemberFullname(sessionAccount),
    };
    if (!isVisibleExpiredWarning || !isLicenseActive) return null;
    return (
        <StyledPageTile>
            <H2>{`License expires in ${expireDaysLeft} days`}</H2>
            <PageTileBody>
                <BodyText3>
                    {_.parse(formatRichText(licenseExpirationWarning, replacements))}
                </BodyText3>
            </PageTileBody>
        </StyledPageTile>
    );
};
export default connect(mapStateToProps, dispatchProps)(LicenseWarningTile);
