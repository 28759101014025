import React from 'react';

import {
    DataCollectionResource,
    DataExportFormat,
    DataFilters,
    DataGranularity,
    DataResponse,
    DataType,
    Entity,
    TimeUnitKey,
} from '@portal/common/types';
import {
    AllDataTypes,
    AntimicrobialResistanceRecords,
    SequelaOutcome,
} from '@portal/common/models/data-type';
import { getDataTypeConfig } from '@portal/common/models/data-type-config';

import TreemapChartContainer from './TreemapChartContainer';

type Props = {
    initialTimeUnitValue: number | string;
    selectedConditionsDataTypes: DataType[];
    selectedConditionsDataTypesGranularity: DataGranularity;
    selectedDataCollection: DataCollectionResource;
    dataType: DataType;

    dataResponses: null | Record<string, DataResponse>;
    isLoadingData: boolean;
    filtersValues: DataFilters; // @todo: rename to 'selectedRefinementFilters', for example
    entityHierarchies: Partial<Record<string, null | Entity[]>>;
    timeUnitKey: TimeUnitKey;
    onExportData?: (format: DataExportFormat) => void;
    enableExportPNG: boolean;

    isChartPoppedOut: boolean;
    toggleChartPoppedOut: () => void;

    detailLevel: number;
    setDetailLevel?: (value: number) => void;

    selectedConditionEntity: Entity | null;
    setConditionEntity: (value: Entity) => void;
    numberFormatter: (value: number | string) => string;
};

type State = {
    selectedDataType: DataType | '';
    dataTypeConfig?: {
        groupLabel: string;
        filterKey: string;
        localePrefix: string;
        entityHierarchyKey: string;
    };
    hierarchy: null | Entity[];

    renderTitle?: (props) => string;
    renderSubtitle?: (props) => string;
};

export default class Container extends React.PureComponent<Props, State> {
    state: State = {
        selectedDataType: '',
        hierarchy: null,
    };

    componentDidMount() {
        this.loadHierarchyIfDataTypesUpdated(this.props.selectedConditionsDataTypes, []);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.loadHierarchyIfDataTypesUpdated(
            this.props.selectedConditionsDataTypes,
            prevProps.selectedConditionsDataTypes
        );
    }

    loadHierarchyIfDataTypesUpdated = (dataTypes, prevDataTypes) => {
        const selectedDataType = this.pickDataType(dataTypes);
        if (selectedDataType !== this.pickDataType(prevDataTypes)) {
            const dataTypeConfig = getDataTypeConfig()[selectedDataType];

            this.setState(
                {
                    selectedDataType,
                    dataTypeConfig,
                },
                () => this.loadHierarchy()
            );
        }
    };

    pickDataType = (dataTypes: DataType[]): DataType | '' => {
        const filteredDataTypes = dataTypes.filter((dataType) => dataType !== SequelaOutcome);

        if (!(filteredDataTypes && filteredDataTypes.length)) {
            return '';
        }

        return filteredDataTypes[0];
    };

    loadHierarchy = () => {
        const { entityHierarchies } = this.props;
        const { dataTypeConfig } = this.state;

        this.setState({
            hierarchy:
                entityHierarchies?.[dataTypeConfig?.entityHierarchyKey] || this.simulateHierarchy(),
        });
    };

    simulateHierarchy = () => {
        const { selectedDataCollection, dataType } = this.props;

        const dataset = selectedDataCollection.datasets.filter(
            (i) => dataType === AllDataTypes || dataType === i.data_type
        )?.[0];

        if (!dataset) {
            return null;
        }

        const { granularity, primary_entity_id_filter, data_type } = dataset;

        const ids = granularity[primary_entity_id_filter];

        const isAMR = data_type === AntimicrobialResistanceRecords;
        const AMRRootConditionId = 47;

        return (ids || []).map((id) => ({
            id,
            level: isAMR && id === AMRRootConditionId ? 0 : 1,
            level0_parent_id: isAMR ? AMRRootConditionId : 0,
            level1_parent_id: 0,
            level2_parent_id: 0,
            level3_parent_id: 0,
        }));
    };

    render() {
        const { hierarchy, selectedDataType, dataTypeConfig } = this.state;

        return (
            <TreemapChartContainer
                {...this.props}
                dataType={selectedDataType}
                dataTypeConfig={dataTypeConfig}
                hierarchy={hierarchy}
            />
        );
    }
}
