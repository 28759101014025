import React, { useCallback } from 'react';
import { compose } from 'redux';

import { withStorage } from '@ihme/common/packages/storage';
import withQueryParams from '@ihme/common/utility/hoc/with-query-params';
import {
    DataTableWithQueryParams,
    FlexColumn,
    H2,
    Image,
    PageBody,
    PageBodyFooter,
    PageContainer,
    PageHeader,
    TableCell,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
} from '@portal/common/components';
import { MethodologyResource } from '@portal/common/types';

import api from '../../api';
import _ from '../../locale';

const MethodologyScene = ({ queryParams, setQueryParams }) => {
    const handleItemClick = useCallback((url: string) => window.open(url), []);

    return (
        <PageContainer>
            <PageHeader>
                <H2>{_('methodology_scene_title')}</H2>
            </PageHeader>
            <PageBody>
                <FlexColumn>
                    <DataTableWithQueryParams
                        queryParams={queryParams}
                        setQueryParams={setQueryParams}
                        defaultQueryParams={{
                            per_page: '10',
                        }}
                        loadItems={api.data.getMethodologies}
                        renderHeaders={(renderProps) => (
                            <TableHeaderRow>
                                <TableHeaderCell></TableHeaderCell>
                                <TableHeaderCell>{_('methodology_column_title')}</TableHeaderCell>
                                <TableHeaderCell>
                                    {_('methodology_column_description')}
                                </TableHeaderCell>
                            </TableHeaderRow>
                        )}
                        renderItem={({
                            id,
                            image_url,
                            title,
                            description,
                            url,
                        }: MethodologyResource) => (
                            <TableRow
                                key={id}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleItemClick(url)}
                            >
                                <TableCell data-private>
                                    <Image src={image_url} size="small" />
                                </TableCell>
                                <TableCell>{title}</TableCell>
                                <TableCell>{description}</TableCell>
                            </TableRow>
                        )}
                    />
                </FlexColumn>
                <PageBodyFooter>{_.get('footer_note')}</PageBodyFooter>
            </PageBody>
        </PageContainer>
    );
};

export default compose(withStorage, withQueryParams)(MethodologyScene);
