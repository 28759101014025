import { combineEpics } from 'redux-observable';

import * as entityHierarchies from './entity-hierarchies/epics';
import * as dataExplorer from './data-explorer/epics';
import * as dataExplorerValidation from './data-explorer/validation-epics';
import * as triggers from './triggers';
import * as resourceLists from './resource-lists/epics';
import * as resources from './resources/epics';
import * as organization from './organization/epics';
import * as polls from './polls/epics';

export default combineEpics(
    ...Object.values(entityHierarchies),
    ...Object.values(dataExplorer),
    ...Object.values(dataExplorerValidation),
    ...Object.values(triggers),
    ...Object.values(resourceLists),
    ...Object.values(resources),
    ...Object.values(organization),
    ...Object.values(polls)
);
