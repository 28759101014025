import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';

import { AGE_GROUP_ID_KEY, PRIMARY_ENTITY_ID_KEY, VALUE_KEY } from '@portal/common/models/data-key';
import { BodyText2 } from '@portal/common/components';
import { styled } from '@portal/common/theme';
import { CauseOutcome } from '@portal/common/models/data-type';
import { DataCondition } from '@portal/common/types';
import formatPdfValue from '@portal/common/utility/formatting/formatPdfValue';

import { getSelectedRefinementFilters } from '../../../store/data-explorer/selectors';
import useGetApiDataSingleValue from '../hooks/use-get-api-data-single-value';
import useGetApiDataMaxValueRecord from '../hooks/use-get-api-data-max-value-record';
import useGetApiDataPlaceOf from '../hooks/use-get-api-data-place-of';

import config from '../../../config';
import _ from '../../../locale';

const StyledBodyText = styled(BodyText2)(({ theme }) => ({
    paddingTop: 10,
    paddingBottom: 30,
}));

const mapStateToProps = (store) => ({
    selectedRefinementFilters: getSelectedRefinementFilters(store),
});

type Props = ReturnType<typeof mapStateToProps> & {
    locationId: number;
};

const ConditionDescription = ({ locationId }: Props) => {
    const sharedRequestProps = {
        conditions: [
            {
                data_type: CauseOutcome,
                [PRIMARY_ENTITY_ID_KEY]: config.multipleMyelomaCauseId,
            } as DataCondition,
        ],
        filters: {
            metric_id: [config.numberMetricId],
            age_group_id: [config.allAgesGroupId],
            gender_id: [config.bothGendersId],
            year: [2019],
            round_id: [config.gbd2019RoundId],
            location_id: [locationId],
        },
    };

    const { isLoading: isLoadingPrevalence, value: prevalence } = useGetApiDataSingleValue({
        ...sharedRequestProps,
        filters: {
            ...sharedRequestProps.filters,
            measure_id: [config.prevalenceMeasureId],
        },
    });

    const { isLoading: isLoadingDeaths, value: deaths } = useGetApiDataSingleValue({
        ...sharedRequestProps,
        filters: {
            ...sharedRequestProps.filters,
            measure_id: [config.deathMeasureId],
        },
    });

    const { value: dalyNumber2019 } = useGetApiDataSingleValue({
        ...sharedRequestProps,
        filters: {
            ...sharedRequestProps.filters,
            measure_id: [config.dalyMeasureId],
        },
    });

    const { value: dalyNumber1990 } = useGetApiDataSingleValue({
        ...sharedRequestProps,
        filters: {
            ...sharedRequestProps.filters,
            measure_id: [config.dalyMeasureId],
            year: [1990],
        },
    });

    const { isLoading: isLoadingYLLs, value: ylls } = useGetApiDataSingleValue({
        ...sharedRequestProps,
        filters: {
            ...sharedRequestProps.filters,
            measure_id: [config.yllMeasureId],
        },
    });

    const { isLoading: isLoadingDalyRank2019, place: dalyRank2019 } = useGetApiDataPlaceOf({
        ...sharedRequestProps,
        conditions: config.neoplasmsChildrenCauseIds.split(',').map((id) => ({
            data_type: CauseOutcome,
            primary_entity_id: Number(id),
        })),
        filters: {
            ...sharedRequestProps.filters,
            measure_id: [config.dalyMeasureId],
        },
        positionByKeyValuePair: [PRIMARY_ENTITY_ID_KEY, config.multipleMyelomaCauseId],
    });

    const { isLoading: isLoadingDalyRank1990, place: dalyRank1990 } = useGetApiDataPlaceOf({
        ...sharedRequestProps,
        conditions: config.neoplasmsChildrenCauseIds.split(',').map((id) => ({
            data_type: CauseOutcome,
            primary_entity_id: Number(id),
        })),
        filters: {
            ...sharedRequestProps.filters,
            measure_id: [config.dalyMeasureId],
            year: [1990],
        },
        positionByKeyValuePair: [PRIMARY_ENTITY_ID_KEY, config.multipleMyelomaCauseId],
    });

    const [highestRateAgeGroupId, setHighestRateAgeGroupId] = useState<number | null>(null);
    const [highestRateValue, setHighestRateValue] = useState<number | null>(null);

    const { value: highestRateResponse } = useGetApiDataMaxValueRecord({
        ...sharedRequestProps,
        filters: {
            ...sharedRequestProps.filters,
            age_group_id: config.fiveYearAgeBands.split(',').map(Number),
            measure_id: [config.deathMeasureId],
            metric_id: [config.rateMetricId],
        },
    });

    useEffect(() => {
        if (highestRateResponse) {
            const { columns, records } = highestRateResponse;
            const ageGroupIdx = columns.indexOf(AGE_GROUP_ID_KEY);
            const valueIdx = columns.indexOf(VALUE_KEY);
            setHighestRateAgeGroupId(records[0][ageGroupIdx]);
            setHighestRateValue(records[0][valueIdx]);
        }
    }, [JSON.stringify(highestRateResponse)]);

    return (
        <StyledBodyText>
            {_('simulation_tool_2_description')({
                location: _(`location_${locationId}`),
                prevalence: isLoadingPrevalence ? '-' : formatPdfValue(prevalence),
                deaths: isLoadingDeaths ? '-' : formatPdfValue(deaths),
                ylls: isLoadingYLLs ? '-' : formatPdfValue(ylls),
                rank2019: isLoadingDalyRank2019 ? '-' : numeral(dalyRank2019).format('0o'),
                rank1990: isLoadingDalyRank1990 ? '-' : numeral(dalyRank1990).format('0o'),
                dalyNumber2019: formatPdfValue(dalyNumber2019),
                dalyRankChange: _(
                    dalyRank2019 === dalyRank1990
                        ? 'not_changed'
                        : (dalyRank2019 || 0) > (dalyRank1990 || 0)
                        ? 'increased'
                        : 'decreased'
                ),
                dalyNumberChange: _(
                    dalyNumber2019 === dalyNumber1990
                        ? 'not_changed'
                        : (dalyNumber2019 || 0) > (dalyNumber1990 || 0)
                        ? 'increased'
                        : 'decreased'
                ),
                highestRateAgeGroup: _(`age_group_${highestRateAgeGroupId}`),
                highestRateValue: formatPdfValue(highestRateValue),
            })}
        </StyledBodyText>
    );
};

export default connect(mapStateToProps, null)(ConditionDescription);
