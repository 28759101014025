import React, { useEffect, useState } from 'react';
import { RootState } from 'MyTypes';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { TabsContainer, TabsMenu, TabsMenuItem, TabsPanel } from '@portal/common/components';
import { TYPE_BAR_CHART } from '@portal/common/models/chart-type';
import { styled } from '@portal/common/theme';

import {
    changeSelectedDataType,
    initializeSelectedChartType,
    resetDataTool,
} from '../../store/data-explorer/actions';
import { getDataToolConfig, getSelectedDataType } from '../../store/data-explorer/selectors';

import _ from '../../locale';

import {
    getOppositeDataType,
    getTabIndexByDataType,
    getTabsConfig,
} from './comorbidity-type-group';

const Container = styled.div(({ theme }) => ({
    zIndex: theme.zIndex.pageHeader,
    position: 'absolute',
    top: 100,
    left: 0,
    width: '100%',
    paddingLeft: 30,
    paddingRight: 30,
    background: theme.color.white,
}));

const mapStateToProps = (state: RootState) => ({
    dataType: getSelectedDataType(state),
    dataToolConfig: getDataToolConfig(state),
});

const dispatchProps = {
    setDataType: changeSelectedDataType,
    resetDataTool: resetDataTool,
    initializeSelectedChartType: initializeSelectedChartType,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps & {};

const ComorbidityTabSelector = ({
    dataType,
    setDataType,
    resetDataTool,
    dataToolConfig,
    initializeSelectedChartType,
}: Props) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(-1);

    useEffect(() => {
        setSelectedTabIndex(getTabIndexByDataType(dataType));
    }, [dataType]);

    const handleTabChange = (index: number, last: number, event: Event) => {
        resetDataTool(dataToolConfig);
        const newDataType = getOppositeDataType(index, dataType);
        setDataType(newDataType);
        initializeSelectedChartType(TYPE_BAR_CHART);
    };

    return (
        <Container>
            <TabsContainer selectedIndex={selectedTabIndex} onSelect={handleTabChange}>
                <TabsMenu>
                    {getTabsConfig().map((item) => (
                        <TabsMenuItem key={item.key}>
                            {_(`comorbidity_${item.key}_tab`)}
                        </TabsMenuItem>
                    ))}
                </TabsMenu>
                <TabsPanel />
                <TabsPanel />
            </TabsContainer>
        </Container>
    );
};

export default compose(connect(mapStateToProps, dispatchProps))(ComorbidityTabSelector);
