import { omit, range } from 'lodash/fp';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import { TYPE_LINE_CHART } from '@portal/common/models/chart-type';
import { AllDataTypes, CauseOutcome } from '@portal/common/models/data-type';
import {
    AgeGroupEntity,
    ChartType,
    ConditionDetailNotesRecord,
    CountryLocationSdi,
    DataCollectionResource,
    DataCondition,
    DataFilters,
    DataGranularity,
    DataKey,
    DataResponse,
    DataTableDisplayState,
    DataType,
} from '@portal/common/types';
import { mergeAndSortDataResponses } from '@portal/common/utility/chart-data-helpers';
import { INFECTIOUS_SYNDROME_ID_KEY, MEASURE_ID_KEY } from '@portal/common/models/data-key';

import sortRefinementsOptions from '../../utility/sort-refinements-options';
import { changeDataCollection } from '../user-settings/actions';
import {
    addVisibleRefinementFilters,
    calculateChartDataResponsesFromDataQuery,
    cancelSelectionCountModal,
    changeChartFilters,
    changeConditions,
    changeDataTypeSelection,
    changeDataTypeSelectionForSingleConditionTool,
    changeSelectedChartType,
    changeSelectedDataType,
    changeSelectedRefinementFilters,
    clearConditionDetailNotes,
    confirmSelectionCountModal,
    dataSelectionValidation,
    emulateDataIsLoadingToMakeChartsRefresh,
    fixMissingLoadedRefinementFilters,
    getAgeGroupsAsync,
    getConditionDetailNotes,
    getCountryLocationsSdi,
    getDataCollectionsAsync,
    getRefinedGranularityAsync,
    getSingleYearAgeGranularitiesAsync,
    initializeChartFilters,
    initializeDataTableDisplayState,
    initializeDataTypeSelection,
    initializeDefaultRefinementFilters,
    initializeSelectedChartType,
    initializeSelectedDataType,
    initializeVisibleRefinementFilters,
    openSelectionCountModal,
    queryChartDataAsync,
    queryDataAsync,
    changeChartDimension,
    removeVisibleRefinementFilters,
    resetDataTool,
    setCombinedFiltersAmount,
    setCustomChartSettings,
    setDrillIntoCondition,
    setSamplePopulationSize,
    toggleDayRefinementFilterMode,
    toggleYearRefinementFilterMode,
    updateDataTableDisplayState,
    validateChartFilters,
    validateVisibleRefinementFilters,
    validateYearRefinementFilters,
} from './actions';
import { LimitModal } from '../../models/data-tool';
import { SingleAgeReportTypes } from '../../types';

export type State = {
    initializationKey: string | null;
    ageGroups: null | AgeGroupEntity[];
    countryLocationSdi: null | CountryLocationSdi[];
    dataCollections: null | DataCollectionResource[];

    selectedDataType: DataType;
    selectedChartType: ChartType;
    selectedConditions: DataCondition[];
    selectedConditionsRefinedGranularity: null | DataGranularity;
    selectedRefinementFilters: DataFilters | null;
    filtersChangedSinceLastRequest: boolean;
    visibleRefinementFilters: string[];
    yearRefinementFilterMode: 'slider' | 'dropdown';
    dayRefinementFilterMode: 'slider' | 'dropdown';

    isDataLoading: boolean;
    dataResponsesByType: null | Record<DataType, DataResponse>;

    dataTableDisplayState: DataTableDisplayState;

    combinedFiltersAmount: number;
    activeSelectionCountModal: LimitModal | null;

    singleYearAgeGranularity: null | Record<string, DataGranularity>;
    aggregatedSingleYearAgeGranularity: null | Record<string, DataGranularity>;

    conditionDetailNotes: null | ConditionDetailNotesRecord[];

    isChartDataLoading: boolean;
    chartDataResponsesByType: null | Record<string, DataResponse>;
    mergedChartDataResponses: null | DataResponse;
    chartFilters: DataFilters | null;
    omittedChartFilters: DataKey[];
    customChartSettings: Record<string, any>;

    chartDimensionFilter: DataKey | null;
    drillIntoCondition: DataCondition | null;

    samplePopulationSize: number;
};

const initialState: State = {
    initializationKey: null,

    ageGroups: null,
    countryLocationSdi: null,
    dataCollections: null,

    selectedDataType: AllDataTypes,
    selectedChartType: TYPE_LINE_CHART,
    selectedConditions: [],
    selectedConditionsRefinedGranularity: null,
    selectedRefinementFilters: null,
    filtersChangedSinceLastRequest: true,
    visibleRefinementFilters: [INFECTIOUS_SYNDROME_ID_KEY, MEASURE_ID_KEY],
    yearRefinementFilterMode: 'slider',
    dayRefinementFilterMode: 'slider',

    singleYearAgeGranularity: null,
    aggregatedSingleYearAgeGranularity: null,

    combinedFiltersAmount: 0,
    activeSelectionCountModal: null,

    dataTableDisplayState: {
        sortedDescending: true,
        sortedByColumn: null,
        visibleColumns: null,
    },

    isDataLoading: false,
    dataResponsesByType: null,

    conditionDetailNotes: null,

    isChartDataLoading: false,
    chartDataResponsesByType: null,
    mergedChartDataResponses: null,
    chartFilters: null,
    omittedChartFilters: [],
    customChartSettings: {},

    chartDimensionFilter: null,
    drillIntoCondition: {
        data_type: CauseOutcome,
        level: 2,
    },

    samplePopulationSize: 100000,
};

export default combineReducers<State>({
    initializationKey: createReducer(initialState.initializationKey).handleAction(
        resetDataTool,
        (state, action) => uuidv4()
    ),
    ageGroups: createReducer(initialState.ageGroups).handleAction(
        getAgeGroupsAsync.success,
        (state, action) => action.payload
    ),
    countryLocationSdi: createReducer(initialState.countryLocationSdi).handleAction(
        getCountryLocationsSdi.success,
        (state, action) => action.payload
    ),
    dataCollections: createReducer(initialState.dataCollections).handleAction(
        getDataCollectionsAsync.success,
        (state, action) => action.payload
    ),

    selectedDataType: createReducer(initialState.selectedDataType)
        .handleAction(
            resetDataTool,
            (state, action) => action.payload.defaultDataType || initialState.selectedDataType
        )
        .handleAction(
            [initializeSelectedDataType, changeSelectedDataType],
            (state, action) => action.payload
        ),
    selectedChartType: createReducer(initialState.selectedChartType)
        .handleAction([resetDataTool], (state, action) => initialState.selectedChartType)
        .handleAction(
            [initializeSelectedChartType, changeSelectedChartType],
            (state, action) => action.payload
        ),

    selectedConditions: createReducer(initialState.selectedConditions)
        .handleAction(
            [resetDataTool, changeDataCollection],
            (state, _) => initialState.selectedConditions
        )
        .handleAction(changeConditions, (state, action) => action.payload)
        .handleAction(
            [
                initializeDataTypeSelection,
                changeDataTypeSelection,
                changeDataTypeSelectionForSingleConditionTool,
            ],
            (state, action) => action.payload.conditions
        ),
    selectedConditionsRefinedGranularity: createReducer(
        initialState.selectedConditionsRefinedGranularity
    )
        .handleAction(
            [resetDataTool, changeDataCollection, changeSelectedDataType],
            (state, _) => initialState.selectedConditionsRefinedGranularity
        )
        .handleAction(getRefinedGranularityAsync.success, (state, action) => action.payload),
    selectedRefinementFilters: createReducer(initialState.selectedRefinementFilters)
        .handleAction(
            [resetDataTool, changeDataCollection],
            (state, _) => initialState.selectedRefinementFilters
        )
        .handleAction(
            [
                initializeDataTypeSelection,
                changeDataTypeSelection,
                changeDataTypeSelectionForSingleConditionTool,
            ],
            (state, action) => action.payload.refinementFilters
        )
        .handleAction(
            [initializeDefaultRefinementFilters, fixMissingLoadedRefinementFilters],
            (state, action) => action.payload
        )
        .handleAction(
            [changeSelectedRefinementFilters, validateYearRefinementFilters],
            (state, action) => ({
                ...state,
                ...action.payload,
            })
        )
        .handleAction(toggleYearRefinementFilterMode, (state, action) => {
            const { year } = state;
            if (year == null) {
                return state;
            }

            const min = Math.min(...year);
            const max = Math.max(...year);
            const yearRange = range(min, max + 1);
            return {
                ...state,
                year: yearRange,
            };
        }),
    filtersChangedSinceLastRequest: createReducer(initialState.filtersChangedSinceLastRequest)
        .handleAction(dataSelectionValidation.success, (state, action) => false)
        .handleAction(
            [
                resetDataTool,
                changeDataCollection,
                changeConditions,
                initializeDataTypeSelection,
                changeDataTypeSelection,
                changeDataTypeSelectionForSingleConditionTool,
                initializeDefaultRefinementFilters,
                fixMissingLoadedRefinementFilters,
                changeSelectedRefinementFilters,
                validateYearRefinementFilters,
                setSamplePopulationSize,
            ],
            (state, action) => true
        ),
    visibleRefinementFilters: createReducer(initialState.visibleRefinementFilters)
        .handleAction(
            [resetDataTool, changeDataCollection, changeSelectedDataType],
            (state, action) => initialState.visibleRefinementFilters
        )
        .handleAction(
            [initializeVisibleRefinementFilters, validateVisibleRefinementFilters],
            (state, action) => sortRefinementsOptions(action.payload)
        )
        .handleAction(addVisibleRefinementFilters, (state, action) => {
            if (state.includes(action.payload)) {
                return state;
            }
            return sortRefinementsOptions(state.concat(action.payload));
        })
        .handleAction(removeVisibleRefinementFilters, (state, action) =>
            state.filter((item) => item !== action.payload)
        ),
    chartFilters: createReducer(initialState.chartFilters)
        .handleAction(
            [changeSelectedChartType, resetDataTool, changeDataCollection, changeSelectedDataType],
            (state, _) => initialState.chartFilters
        )
        .handleAction(
            [initializeChartFilters, changeChartFilters, validateChartFilters],
            (state, action) => ({
                ...state,
                ...action.payload,
            })
        )
        .handleAction(changeChartDimension, (state, action) => omit([action.payload], state)),
    omittedChartFilters: createReducer(initialState.omittedChartFilters)
        .handleAction(
            [resetDataTool, changeDataCollection, changeSelectedChartType],
            (state, _) => initialState.omittedChartFilters
        )
        .handleAction(changeChartDimension, (state, action) => [action.payload]),
    chartDimensionFilter: createReducer(initialState.chartDimensionFilter)
        .handleAction(
            [resetDataTool, changeSelectedChartType],
            (state, _) => initialState.chartDimensionFilter
        )
        .handleAction(changeChartDimension, (state, action) => action.payload),
    isDataLoading: createReducer(initialState.isDataLoading)
        .handleAction(queryDataAsync.request, () => true)
        .handleAction(
            [queryDataAsync.success, queryDataAsync.failure, resetDataTool, changeDataCollection],
            () => false
        ),
    dataResponsesByType: createReducer(initialState.dataResponsesByType)
        .handleAction(
            [resetDataTool, changeDataCollection, queryDataAsync.request, changeSelectedDataType],
            (state, _) => initialState.dataResponsesByType
        )
        .handleAction(queryDataAsync.success, (state, action) => action.payload),
    conditionDetailNotes: createReducer(initialState.conditionDetailNotes)
        .handleAction(
            [
                resetDataTool,
                changeDataCollection,
                changeSelectedDataType,
                clearConditionDetailNotes,
            ],
            (state, _) => initialState.conditionDetailNotes
        )
        .handleAction(getConditionDetailNotes.success, (state, action) => action.payload),

    isChartDataLoading: createReducer(initialState.isChartDataLoading)
        .handleAction(
            [queryChartDataAsync.request, emulateDataIsLoadingToMakeChartsRefresh],
            () => true
        )
        .handleAction(
            [
                queryChartDataAsync.success,
                queryChartDataAsync.failure,
                resetDataTool,
                changeDataCollection,
                calculateChartDataResponsesFromDataQuery,
                changeSelectedDataType,
            ],
            () => false
        ),
    chartDataResponsesByType: createReducer(initialState.chartDataResponsesByType)
        .handleAction(
            [resetDataTool, changeDataCollection, changeSelectedChartType, changeSelectedDataType],
            (state, _) => initialState.chartDataResponsesByType
        )
        .handleAction(
            [queryChartDataAsync.success, calculateChartDataResponsesFromDataQuery],
            (state, action) => action.payload
        ),
    mergedChartDataResponses: createReducer(initialState.mergedChartDataResponses)
        .handleAction(
            [resetDataTool, changeDataCollection, changeSelectedChartType, changeSelectedDataType],
            (state, _) => initialState.mergedChartDataResponses
        )
        .handleAction(
            [queryChartDataAsync.success, calculateChartDataResponsesFromDataQuery],
            (state, action) => mergeAndSortDataResponses(action.payload)
        ),

    samplePopulationSize: createReducer(initialState.samplePopulationSize)
        .handleAction(
            [resetDataTool, changeDataCollection],
            (state, _) => initialState.samplePopulationSize
        )
        .handleAction(setSamplePopulationSize, (state, action) => action.payload),
    yearRefinementFilterMode: createReducer(initialState.yearRefinementFilterMode).handleAction(
        toggleYearRefinementFilterMode,
        (state, action) => (state === 'slider' ? 'dropdown' : 'slider')
    ),
    dayRefinementFilterMode: createReducer(initialState.dayRefinementFilterMode).handleAction(
        toggleDayRefinementFilterMode,
        (state, action) => (state === 'slider' ? 'dropdown' : 'slider')
    ),
    activeSelectionCountModal: createReducer(initialState.activeSelectionCountModal)
        .handleAction(
            [
                cancelSelectionCountModal,
                confirmSelectionCountModal,
                resetDataTool,
                changeDataCollection,
            ],
            (state, _) => initialState.activeSelectionCountModal
        )
        .handleAction(openSelectionCountModal, (state, action) => action.payload),
    dataTableDisplayState: createReducer(initialState.dataTableDisplayState)
        .handleAction(initializeDataTableDisplayState, (state, action) => ({
            ...initialState.dataTableDisplayState,
            ...action.payload,
        }))
        .handleAction(changeSelectedDataType, (state, action) => initialState.dataTableDisplayState)
        .handleAction(updateDataTableDisplayState, (state, action) => ({
            ...state,
            ...action.payload,
        })),
    customChartSettings: createReducer(initialState.customChartSettings)
        .handleAction(setCustomChartSettings, (state, action) => ({
            ...state,
            ...action.payload,
        }))
        .handleAction(
            [resetDataTool, changeSelectedDataType, changeSelectedChartType],
            (state, action) => initialState.customChartSettings
        ),
    combinedFiltersAmount: createReducer(initialState.combinedFiltersAmount)
        .handleAction(setCombinedFiltersAmount, (state, action) => action.payload)
        .handleAction(
            [resetDataTool, changeSelectedDataType],
            (state, action) => initialState.combinedFiltersAmount
        ),
    singleYearAgeGranularity: createReducer(initialState.singleYearAgeGranularity)
        .handleAction(
            [resetDataTool, changeSelectedDataType, getSingleYearAgeGranularitiesAsync.request],
            (state, action) => initialState.singleYearAgeGranularity
        )
        .handleAction(getSingleYearAgeGranularitiesAsync.success, (state, action) => ({
            ...state,
            ...action.payload[SingleAgeReportTypes.Normal],
        })),
    aggregatedSingleYearAgeGranularity: createReducer(
        initialState.aggregatedSingleYearAgeGranularity
    )
        .handleAction(
            [resetDataTool, changeSelectedDataType, getSingleYearAgeGranularitiesAsync.request],
            (state, action) => initialState.aggregatedSingleYearAgeGranularity
        )
        .handleAction(getSingleYearAgeGranularitiesAsync.success, (state, action) => ({
            ...state,
            ...action.payload[SingleAgeReportTypes.Aggregated],
        })),
    drillIntoCondition: createReducer(initialState.drillIntoCondition)
        .handleAction(setDrillIntoCondition, (state, action) => action.payload)
        .handleAction(resetDataTool, (state, action) => initialState.drillIntoCondition),
});
