import { DataCondition, DataGranularityKey, DataRecord, DataType } from '@portal/common/types';
import { DATA_TYPE_KEY, PRIMARY_ENTITY_ID_KEY } from '@portal/common/models/data-key';

export const getConditionFromDataKeyValue = (
    record: DataRecord,
    columns: DataGranularityKey[]
): DataCondition => {
    const dataTypeIdx = columns.indexOf(DATA_TYPE_KEY);
    const primaryEntityIdIdx = columns.indexOf(PRIMARY_ENTITY_ID_KEY);
    const dataType = record[dataTypeIdx] as DataType;
    const primaryEntityId = Number(record[primaryEntityIdIdx]);
    return {
        data_type: dataType,
        primary_entity_id: primaryEntityId,
    };
};
